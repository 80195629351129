<template>
  <div class="container is-max-desktop wrap">
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1026.1 500.7"
      style="enable-background: new 0 0 1026.1 500.7"
      xml:space="preserve"
    >
      <g id="pilotage"
          @mouseover="hilight('pilotage', true)"
          @mouseleave="hilight('pilotage', false)"
          @click="activate('pilotage')">
        <g id="pilotage-socle">
          <path
            id="XMLID_15620_"
            style="fill: #e5ecf4"
            :class="{ active: active_pilotage, hilighted: hilighted_pilotage }"
            d="M163.7,272.1l143.9,82.3c5.9,3.4,13.1,3.4,18.9,0l135.2-79.2
      c4.9-2.8,4.9-9.9,0-12.8l-143.9-82.3c-5.9-3.4-13.1-3.4-18.9,0l-135.2,79.2C158.8,262.1,158.8,269.2,163.7,272.1z"
          />
        </g>
        <g id="pilotage-illus">
          <g id="XMLID_15329_">
            <g id="XMLID_15342_">
              <g id="XMLID_15356_">
                <g id="XMLID_15402_">
                  <g id="XMLID_15410_">
                    <g id="XMLID_15415_">
                      <path
                        id="XMLID_15416_"
                        style="fill: #ffffff"
                        d="M437.7,208c0,0,0-2.7,0-3.6c0-0.2-0.1-0.3-0.3-0.4l-8.5-2l-43-24.8l-3.3-2
                  l-6.9,0.9c-0.2,0-0.3,0.2-0.3,0.4l0,3.5c-0.1,0.5,0.1,1.1,0.7,1.4l52.7,30.4c1.1,0.6,2.4,0.6,3.5,0l4.7-2.7
                  C437.4,209,437.6,208.5,437.7,208L437.7,208z"
                      />
                    </g>
                    <g id="XMLID_15411_">
                      <path
                        id="XMLID_15412_"
                        d="M430.5,212.9c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.7-0.4-1.1-1.2-0.9-2l0-3.4
                  c0-0.5,0.3-0.9,0.8-0.9l7.1-0.9l3.4,2.1l42.9,24.7l8.4,2c0.4,0.1,0.7,0.5,0.7,0.9c0,0,0,3.5,0,3.6l0,0.1
                  c0,0.6-0.4,1.2-1,1.5l-4.7,2.7C431.9,212.7,431.2,212.9,430.5,212.9z M375.9,176.7l0,3.4l0,0c0,0.3,0,0.7,0.4,0.9l52.7,30.4
                  c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.5,0.4-0.7l0-0.1c0-0.3,0-2.5,0-3.4l-8.5-2.1l-43-24.8l-3.1-1.9L375.9,176.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_15403_">
                    <g id="XMLID_15408_">
                      <path
                        id="XMLID_15409_"
                        style="fill: #ffffff"
                        d="M428.8,208.1l-52.7-30.4c-0.9-0.5-0.9-1.9,0-2.4l4.7-2.7
                  c1.1-0.6,2.4-0.6,3.5,0L437,203c0.9,0.5,0.9,1.9,0,2.4l-4.7,2.7C431.2,208.7,429.9,208.7,428.8,208.1z"
                      />
                    </g>
                    <g id="XMLID_15404_">
                      <path
                        id="XMLID_15405_"
                        d="M430.5,209.1c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.6-0.4-1-1-1-1.7s0.4-1.3,1-1.7l4.7-2.7
                  c1.2-0.7,2.8-0.7,4,0l52.7,30.4c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-4.7,2.7C431.9,208.9,431.2,209.1,430.5,209.1z
                   M429.1,207.6c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7L384,173c-0.9-0.5-2-0.5-3,0
                  l-4.7,2.7c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7L429.1,207.6z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_15387_">
                  <g id="XMLID_15395_">
                    <g id="XMLID_15400_">
                      <path
                        id="XMLID_15401_"
                        style="fill: #ffffff"
                        d="M428.3,220.4c0,0,0-2.7,0-3.6c0-0.2-0.1-0.3-0.3-0.4l-8.5-2l-43-24.8
                  l-3.3-2l-6.9,0.9c-0.2,0-0.3,0.2-0.3,0.4l0,3.5c-0.1,0.5,0.1,1.1,0.7,1.4l52.7,30.4c1.1,0.6,2.4,0.6,3.5,0l4.7-2.7
                  C428,221.3,428.2,220.9,428.3,220.4L428.3,220.4z"
                      />
                    </g>
                    <g id="XMLID_15396_">
                      <path
                        id="XMLID_15397_"
                        d="M421.1,225.2c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.7-0.4-1.1-1.2-0.9-2l0-3.4
                  c0-0.5,0.3-0.9,0.8-0.9l7.1-0.9l3.4,2.1l42.9,24.7l8.4,2c0.4,0.1,0.7,0.5,0.7,0.9c0,0.8,0,3.5,0,3.6l0,0.1
                  c0,0.6-0.4,1.2-1,1.5l-4.7,2.7C422.5,225.1,421.8,225.2,421.1,225.2z M366.5,189l0,3.4l0,0c0,0.3,0,0.7,0.4,0.9l52.7,30.4
                  c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.5,0.4-0.7l0,0c0-0.3,0-2.5,0-3.4l-8.5-2.1l-43-24.8l-3.1-1.9L366.5,189z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_15388_">
                    <g id="XMLID_15393_">
                      <path
                        id="XMLID_15394_"
                        style="fill: #ffffff"
                        d="M419.4,220.4L366.7,190c-0.9-0.5-0.9-1.9,0-2.4l4.7-2.7
                  c1.1-0.6,2.4-0.6,3.5,0l52.7,30.4c0.9,0.5,0.9,1.9,0,2.4l-4.7,2.7C421.8,221.1,420.5,221.1,419.4,220.4z"
                      />
                    </g>
                    <g id="XMLID_15389_">
                      <path
                        id="XMLID_15390_"
                        d="M421.1,221.4c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l4.7-2.7
                  c1.2-0.7,2.8-0.7,4,0l52.7,30.4c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-4.7,2.7C422.5,221.3,421.8,221.4,421.1,221.4z
                   M373.1,184.9c-0.5,0-1,0.1-1.5,0.4L367,188c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l52.7,30.4c0.9,0.5,2,0.5,3,0
                  l4.7-2.7c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-52.7-30.4C374.1,185.1,373.6,184.9,373.1,184.9z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_15372_">
                  <g id="XMLID_15380_">
                    <g id="XMLID_15385_">
                      <path
                        id="XMLID_15386_"
                        style="fill: #ffffff"
                        d="M418.9,232.7c0,0,0-2.7,0-3.6c0-0.2-0.1-0.3-0.3-0.4l-8.5-2l-43-24.8
                  l-3.3-2l-6.9,0.9c-0.2,0-0.3,0.2-0.3,0.4l0,3.5c-0.1,0.5,0.1,1.1,0.7,1.5l52.7,30.4c1.1,0.6,2.4,0.6,3.5,0l4.7-2.7
                  C418.6,233.6,418.8,233.2,418.9,232.7L418.9,232.7z"
                      />
                    </g>
                    <g id="XMLID_15381_">
                      <path
                        id="XMLID_15382_"
                        d="M411.8,237.6c-0.7,0-1.4-0.2-2-0.5L357,206.6c-0.7-0.4-1.1-1.2-0.9-2l0-3.4
                  c0-0.5,0.3-0.9,0.8-0.9l7.1-0.9l3.4,2.1l42.9,24.7l8.4,2c0.4,0.1,0.7,0.5,0.7,0.9c0,3.5,0,3.6,0,3.6l0,0.1
                  c0,0.6-0.4,1.2-1,1.5l-4.7,2.7C413.1,237.4,412.4,237.6,411.8,237.6z M357.1,201.3l0,3.4l0,0c0,0.3,0,0.7,0.4,0.9l52.7,30.4
                  c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.5,0.4-0.7l0-0.1c0-0.3,0-2.5,0-3.4l-8.5-2.1l-43-24.8l-3.1-1.9L357.1,201.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_15373_">
                    <g id="XMLID_15378_">
                      <path
                        id="XMLID_15379_"
                        style="fill: #ffffff"
                        d="M410,232.8l-52.7-30.4c-0.9-0.5-0.9-1.9,0-2.4l4.7-2.7
                  c1.1-0.6,2.4-0.6,3.5,0l52.7,30.4c0.9,0.5,0.9,1.9,0,2.4l-4.7,2.7C412.4,233.4,411.1,233.4,410,232.8z"
                      />
                    </g>
                    <g id="XMLID_15374_">
                      <path
                        id="XMLID_15375_"
                        d="M411.8,233.8c-0.7,0-1.4-0.2-2-0.5L357,202.8c-0.6-0.4-1-1-1-1.7s0.4-1.3,1-1.7l4.7-2.7
                  c1.2-0.7,2.8-0.7,4,0l52.7,30.4c0.6,0.4,1,1,1,1.7s-0.4,1.3-1,1.7l-4.7,2.7C413.1,233.6,412.4,233.8,411.8,233.8z
                   M363.7,197.3c-0.5,0-1,0.1-1.5,0.4l-4.7,2.7c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l52.7,30.4c0.9,0.5,2,0.5,3,0
                  l4.7-2.7c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-52.7-30.4C364.7,197.4,364.2,197.3,363.7,197.3z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_15357_">
                  <g id="XMLID_15365_">
                    <g id="XMLID_15370_">
                      <path
                        id="XMLID_15371_"
                        style="fill: #ffffff"
                        d="M409.4,245c0,0,0-2.7,0-3.6c0-0.2-0.1-0.3-0.3-0.4l-8.5-2l-43-24.8l-3.3-2
                  l-6.9,0.9c-0.2,0-0.3,0.2-0.3,0.4l0,3.5c-0.1,0.5,0.1,1.1,0.7,1.4l52.7,30.4c1.1,0.6,2.4,0.6,3.5,0l4.7-2.7
                  C409.2,245.9,409.4,245.5,409.4,245L409.4,245z"
                      />
                    </g>
                    <g id="XMLID_15366_">
                      <path
                        id="XMLID_15367_"
                        d="M402.3,249.9c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.7-0.4-1.1-1.2-0.9-2l0-3.4
                  c0-0.5,0.3-0.9,0.8-0.9l7.1-0.9l3.4,2.1l42.9,24.7l8.4,2c0.4,0.1,0.7,0.5,0.7,0.9c0,0,0,3.5,0,3.6l0,0.1
                  c0,0.6-0.4,1.2-1,1.5l-4.7,2.7C403.7,249.7,403,249.9,402.3,249.9z M347.7,213.7l0,3.4l0,0c0,0.3,0,0.7,0.4,0.9l52.7,30.4
                  c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.5,0.4-0.7l0-0.1c0-0.3,0-2.5,0-3.4l-8.5-2.1l-43-24.8l-3.1-1.9L347.7,213.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_15358_">
                    <g id="XMLID_15363_">
                      <path
                        id="XMLID_15364_"
                        style="fill: #ffffff"
                        d="M400.6,245.1l-52.7-30.4c-0.9-0.5-0.9-1.9,0-2.4l4.7-2.7
                  c1.1-0.6,2.4-0.6,3.5,0l52.7,30.4c0.9,0.5,0.9,1.9,0,2.4l-4.7,2.7C403,245.7,401.7,245.7,400.6,245.1z"
                      />
                    </g>
                    <g id="XMLID_15359_">
                      <path
                        id="XMLID_15360_"
                        d="M402.3,246.1c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l4.7-2.7
                  c1.2-0.7,2.8-0.7,4,0l52.7,30.4c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-4.7,2.7C403.7,245.9,403,246.1,402.3,246.1z
                   M354.3,209.6c-0.5,0-1,0.1-1.5,0.4l-4.7,2.7c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l52.7,30.4c0.9,0.5,2,0.5,3,0
                  l4.7-2.7c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7L355.8,210C355.3,209.7,354.8,209.6,354.3,209.6z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_15343_">
                <g id="XMLID_15353_">
                  <g id="XMLID_15354_">
                    <rect
                      id="XMLID_15355_"
                      x="401.9"
                      y="245.5"
                      width="1.1"
                      height="3.8"
                    />
                  </g>
                </g>
                <g id="XMLID_15350_">
                  <g id="XMLID_15351_">
                    <rect
                      id="XMLID_15352_"
                      x="411.2"
                      y="233.2"
                      width="1.1"
                      height="3.8"
                    />
                  </g>
                </g>
                <g id="XMLID_15347_">
                  <g id="XMLID_15348_">
                    <rect
                      id="XMLID_15349_"
                      x="420.6"
                      y="220.9"
                      width="1.1"
                      height="3.8"
                    />
                  </g>
                </g>
                <g id="XMLID_15344_">
                  <g id="XMLID_15345_">
                    <rect
                      id="XMLID_15346_"
                      x="430.3"
                      y="208.6"
                      width="1.1"
                      height="3.8"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="XMLID_15339_">
              <g id="XMLID_15340_">
                <polygon
                  id="XMLID_15341_"
                  points="404.1,245.1 404.1,248.9 409.4,245.6 409.3,242.1           "
                />
              </g>
            </g>
            <g id="XMLID_15336_">
              <g id="XMLID_15337_">
                <polygon
                  id="XMLID_15338_"
                  points="413.5,232.6 413.5,236.4 418.9,233.1 418.8,229.6           "
                />
              </g>
            </g>
            <g id="XMLID_15333_">
              <g id="XMLID_15334_">
                <polygon
                  id="XMLID_15335_"
                  points="422.9,220.5 422.9,224.3 428.3,221 428.2,217.5           "
                />
              </g>
            </g>
            <g id="XMLID_15330_">
              <g id="XMLID_15331_">
                <polygon
                  id="XMLID_15332_"
                  points="432.3,208.2 432.3,212 437.7,208.7 437.5,205.2           "
                />
              </g>
            </g>
          </g>
          <g id="XMLID_13120_">
            <g id="XMLID_13178_">
              <g id="XMLID_13179_">
                <g id="XMLID_15149_">
                  <path
                    id="XMLID_15150_"
                    style="fill: #ffffff"
                    d="M212.9,319.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C213,319.6,213,319.8,212.9,319.9z"
                  />
                </g>
                <g id="XMLID_15147_">
                  <path
                    id="XMLID_15148_"
                    style="fill: #ffffff"
                    d="M216.9,322.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C217,321.9,217.1,322.1,216.9,322.2z"
                  />
                </g>
                <g id="XMLID_15145_">
                  <path
                    id="XMLID_15146_"
                    style="fill: #ffffff"
                    d="M212.7,324.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C212.8,324.4,212.8,324.6,212.7,324.7z"
                  />
                </g>
                <g id="XMLID_15143_">
                  <path
                    id="XMLID_15144_"
                    style="fill: #ffffff"
                    d="M220.9,324.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C221.1,324.3,221.1,324.4,220.9,324.5z"
                  />
                </g>
                <g id="XMLID_15141_">
                  <path
                    id="XMLID_15142_"
                    style="fill: #ffffff"
                    d="M216.7,327l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C216.8,326.7,216.9,326.9,216.7,327z"
                  />
                </g>
                <g id="XMLID_15122_">
                  <path
                    id="XMLID_15140_"
                    style="fill: #ffffff"
                    d="M225.2,327l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C225.3,326.7,225.3,326.9,225.2,327z"
                  />
                </g>
                <g id="XMLID_15119_">
                  <path
                    id="XMLID_15121_"
                    style="fill: #ffffff"
                    d="M220.9,329.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C221.1,329.2,221.1,329.3,220.9,329.4z"
                  />
                </g>
                <g id="XMLID_15116_">
                  <path
                    id="XMLID_15117_"
                    style="fill: #ffffff"
                    d="M229.4,324.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C229.6,324.3,229.6,324.4,229.4,324.5z"
                  />
                </g>
                <g id="XMLID_13894_">
                  <path
                    id="XMLID_14060_"
                    style="fill: #ffffff"
                    d="M233.6,322.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C233.8,321.8,233.8,322,233.6,322.1z"
                  />
                </g>
                <g id="XMLID_13694_">
                  <path
                    id="XMLID_13762_"
                    style="fill: #ffffff"
                    d="M237.9,319.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C238,319.4,238,319.6,237.9,319.7z"
                  />
                </g>
                <g id="XMLID_13558_">
                  <path
                    id="XMLID_13626_"
                    style="fill: #ffffff"
                    d="M229.2,329.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C229.3,329,229.4,329.2,229.2,329.3z"
                  />
                </g>
                <g id="XMLID_13490_">
                  <path
                    id="XMLID_13536_"
                    style="fill: #ffffff"
                    d="M233.4,326.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C233.6,326.6,233.6,326.8,233.4,326.9z"
                  />
                </g>
                <g id="XMLID_13469_">
                  <path
                    id="XMLID_13471_"
                    style="fill: #ffffff"
                    d="M237.7,324.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C237.8,324.1,237.8,324.3,237.7,324.4z"
                  />
                </g>
                <g id="XMLID_13290_">
                  <path
                    id="XMLID_13358_"
                    style="fill: #ffffff"
                    d="M242.1,317.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C242.3,317,242.3,317.1,242.1,317.2z"
                  />
                </g>
                <g id="XMLID_13180_">
                  <path
                    id="XMLID_13222_"
                    style="fill: #ffffff"
                    d="M241.9,322l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C242,321.7,242.1,321.9,241.9,322z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_13121_">
              <g id="XMLID_13149_">
                <g id="XMLID_13171_">
                  <g id="XMLID_13176_">
                    <path
                      id="XMLID_13177_"
                      style="fill: #ffffff"
                      d="M239.6,312.2c0,0,0-1.9,0-2.3c0,0,0-0.1-0.1-0.1l-15.6,4c0,0,0,0,0,0
                l4.5-8.8c0,0-0.1,0-0.1,0l-14.8,8.5c0,0,0,0-0.1,0l-1.8-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.5,0,2.2,0,2.2l0,0
                c0,0.3,0.2,0.6,0.5,0.8l9.5,5.5c0.8,0.5,1.8,0.5,2.6,0l15-8.7C239.5,312.9,239.6,312.5,239.6,312.2L239.6,312.2z"
                    />
                  </g>
                  <g id="XMLID_13172_">
                    <path
                      id="XMLID_13173_"
                      d="M222.8,322.5c-0.5,0-1.1-0.1-1.5-0.4l-9.5-5.5c-0.4-0.2-0.6-0.6-0.7-1.1c0-0.1,0-0.1,0-2.1l0-0.2
                c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.7,0.5l14.7-8.5c0.2-0.1,0.3-0.1,0.5,0l0.3,0.2l-4.2,8.3l14.9-3.8l0.1,0
                c0.2,0.1,0.4,0.3,0.4,0.5v0.5c0,0.7,0,1.8,0,1.8l0,0.1c0,0.5-0.3,0.9-0.7,1.1l-15,8.7C223.9,322.3,223.3,322.5,222.8,322.5z
                 M211.9,313.6c0,0.7,0,1.7,0,1.8c0,0.1,0.1,0.3,0.3,0.4l9.5,5.5c0.7,0.4,1.5,0.4,2.2,0l15-8.7c0.2-0.1,0.3-0.3,0.3-0.5h0.4
                l-0.4,0c0,0,0-1.1,0-1.8v-0.1l-15.3,4l-0.2-0.1l-0.3-0.2l4-7.9l-13.7,7.9c-0.1,0.1-0.3,0.1-0.4,0L211.9,313.6z"
                    />
                  </g>
                </g>
                <g id="XMLID_13164_">
                  <g id="XMLID_13169_">
                    <path
                      id="XMLID_13170_"
                      d="M239.6,309.8l-15.6,4c0,0,0,0,0,0l-1.2,3.5v4.7c0.5,0,1-0.1,1.4-0.3l15-8.7
                c0.3-0.2,0.5-0.5,0.5-0.8l0,0C239.6,312.2,239.6,310.3,239.6,309.8C239.6,309.8,239.6,309.8,239.6,309.8z"
                    />
                  </g>
                  <g id="XMLID_13165_">
                    <path
                      id="XMLID_13166_"
                      d="M222.8,322.5C222.8,322.5,222.8,322.5,222.8,322.5l-0.5,0l0-5.3l1.4-4l0.3,0.2l15.6-4l0.1,0
                c0.2,0.1,0.4,0.3,0.4,0.5v0.5c0,0.7,0,1.8,0,1.8c0,0.5-0.3,1-0.7,1.2l-15,8.7C223.9,322.3,223.4,322.5,222.8,322.5z
                 M223.2,317.4v4.2c0.3,0,0.5-0.1,0.8-0.3l15-8.7c0.2-0.1,0.3-0.3,0.3-0.5c0,0,0-1.1,0-1.8v-0.1l-15,3.9L223.2,317.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_13157_">
                  <g id="XMLID_13162_">
                    <path
                      id="XMLID_13163_"
                      d="M213,314.4c0,0,0.1-0.4,0.3-0.9l-1.7-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.5,0,2.2,0,2.2l0,0
                c0,0.3,0.2,0.6,0.5,0.8l1,0.6l0,0V314.4z"
                    />
                  </g>
                  <g id="XMLID_13158_">
                    <path
                      id="XMLID_13159_"
                      d="M213.4,317.4l-1.6-0.9c-0.4-0.2-0.6-0.6-0.7-1.1c0-0.1,0-0.1,0-2.1l0-0.2c0-0.2,0.1-0.3,0.2-0.4
                c0.1-0.1,0.3-0.1,0.4-0.1l2.1,0.6l-0.1,0.4c-0.1,0.4-0.2,0.7-0.3,0.8V317.4z M211.9,313.6c0,0.7,0,1.7,0,1.8
                c0,0.1,0.1,0.3,0.3,0.4l0.3,0.2l0-1.7c0,0,0.1-0.2,0.2-0.4L211.9,313.6z"
                    />
                  </g>
                </g>
                <g id="XMLID_13150_">
                  <g id="XMLID_13155_">
                    <path
                      id="XMLID_13156_"
                      style="fill: #ffffff"
                      d="M221.6,319.4l-9.5-5.5c-0.7-0.4-0.7-1.4,0-1.7l15.1-8.7
                c0.8-0.5,1.7-0.5,2.5,0l9.5,5.5c0.7,0.4,0.7,1.4,0,1.7l-15.1,8.7C223.3,319.9,222.3,319.9,221.6,319.4z"
                    />
                  </g>
                  <g id="XMLID_13151_">
                    <path
                      id="XMLID_13152_"
                      d="M222.8,320.2c-0.5,0-1-0.1-1.5-0.4l-9.5-5.5c-0.4-0.3-0.7-0.7-0.7-1.2c0-0.5,0.3-1,0.7-1.2
                l15.1-8.7c0.9-0.5,2-0.5,2.9,0l9.5,5.5c0.4,0.3,0.7,0.7,0.7,1.2c0,0.5-0.3,1-0.7,1.2l-15.1,8.7
                C223.8,320,223.3,320.2,222.8,320.2z M228.3,303.5c-0.4,0-0.7,0.1-1.1,0.3l-15.1,8.7c-0.2,0.1-0.3,0.3-0.3,0.5
                c0,0.2,0.1,0.4,0.3,0.5l9.5,5.5c0.7,0.4,1.5,0.4,2.1,0l15.1-8.7c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5l-9.5-5.5
                C229.1,303.6,228.7,303.5,228.3,303.5z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_13122_">
                <g id="XMLID_13126_">
                  <g id="XMLID_13142_">
                    <g id="XMLID_13147_">
                      <path
                        id="XMLID_13148_"
                        style="fill: #ffffff"
                        d="M231.6,302.8l-2.6-1.5c-0.1-0.1-0.2-0.1-0.3,0l-3.2,2
                  c-0.1,0-0.1,0.1,0,0.2l3.1,3.3c0,0,0,0,0,0.1v1.1c0,0.3,1.2-0.6,1.5-0.4l0,0c0,0,0,0,0,0l-1.6,1c0,0.1,0.1,0.1,0.2,0l3.2-2
                  c0,0,0.1-0.2,0.1-0.3v-2.5C232.1,303.3,231.9,303,231.6,302.8z"
                      />
                    </g>
                    <g id="XMLID_13143_">
                      <path
                        id="XMLID_13144_"
                        d="M228.7,308.9c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.3l-0.2-0.3l0.2-0.1l0,0l0-0.3v-0.9l-3-3.2
                  c-0.2-0.3-0.1-0.6,0.2-0.7l3.2-2c0.2-0.1,0.5-0.1,0.7,0l2.6,1.5c0.4,0.2,0.6,0.7,0.6,1.2v2.5c0,0.3-0.2,0.5-0.2,0.6
                  l-0.1,0.1l-3.2,2C228.9,308.9,228.8,308.9,228.7,308.9z M226.1,303.4l2.9,3.1c0,0.1,0.1,0.2,0.1,0.3v0.6c0,0,0.1,0,0.1-0.1
                  c0.5-0.2,0.8-0.4,1.1-0.3l1.4-0.9c0,0,0,0,0,0l0-2.5c0-0.2-0.1-0.4-0.2-0.4l0,0l-2.5-1.5L226.1,303.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_13135_">
                    <g id="XMLID_13140_">
                      <path
                        id="XMLID_13141_"
                        d="M226.4,306.3c0,0,0,0.1,0,0.2l2.2,1.9c0.1,0.1,0.2,0.1,0.3,0.1l3.1-1.9c0,0,0.1-0.2,0.1-0.3v-2.3
                  c0-0.1-0.1-0.1-0.1-0.1l-3.1,2c0,0,0,0,0,0L226.4,306.3z"
                      />
                    </g>
                    <g id="XMLID_13136_">
                      <path
                        id="XMLID_13137_"
                        d="M228.7,308.9c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.2l-2.3-2l0.1-0.7l2.6-0.6l3.1-2
                  c0.2-0.1,0.3-0.1,0.5,0c0.2,0.1,0.3,0.3,0.3,0.4v2.3c0,0.3-0.2,0.5-0.2,0.6l-0.1,0.1l-3.1,1.9
                  C229,308.8,228.8,308.9,228.7,308.9z M227,306.5l1.7,1.4l2.9-1.8c0,0,0,0,0,0l0-1.7l-2.6,1.6l0,0.1L227,306.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_13127_">
                    <g id="XMLID_13133_">
                      <path
                        id="XMLID_13134_"
                        style="fill: #ffffff"
                        d="M228.4,308.5l-2.6-1.5c-0.2-0.1-0.4-0.5-0.4-0.8v-2.5
                  c0-0.3,0.2-0.5,0.4-0.3l2.6,1.5c0.2,0.1,0.4,0.5,0.4,0.8v2.5C228.8,308.5,228.6,308.6,228.4,308.5z"
                      />
                    </g>
                    <g id="XMLID_13128_">
                      <path
                        id="XMLID_13129_"
                        d="M228.5,309c-0.1,0-0.3,0-0.4-0.1l0,0l-2.6-1.5c-0.4-0.2-0.6-0.7-0.6-1.2v-2.5
                  c0-0.3,0.1-0.6,0.4-0.7c0.2-0.1,0.5-0.1,0.7,0l2.6,1.5c0.4,0.2,0.6,0.7,0.6,1.2v2.5c0,0.3-0.1,0.6-0.4,0.7
                  C228.7,308.9,228.6,309,228.5,309z M228.6,308.1l-0.2,0.4L228.6,308.1L228.6,308.1L228.6,308.1z M225.8,303.8l0,2.3
                  c0,0.2,0.1,0.4,0.2,0.4l2.4,1.4l0-2.3c0-0.2-0.1-0.4-0.2-0.4L225.8,303.8z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_13123_">
                  <g id="XMLID_13124_">
                    <rect
                      id="XMLID_13125_"
                      x="228.8"
                      y="305.8"
                      transform="matrix(0.8567 -0.5159 0.5159 0.8567 -124.9064 162.7667)"
                      style="fill: #ffffff"
                      width="3.3"
                      height="0.6"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_9226_">
            <g id="XMLID_9266_">
              <g id="XMLID_9479_">
                <path
                  id="XMLID_9480_"
                  style="fill: #ffffff"
                  d="M227.5,282.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C227.6,281.9,227.7,282.1,227.5,282.2z"
                />
              </g>
              <g id="XMLID_9476_">
                <path
                  id="XMLID_9477_"
                  style="fill: #ffffff"
                  d="M223.2,284.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C223.4,284.4,223.4,284.5,223.2,284.6z"
                />
              </g>
              <g id="XMLID_9473_">
                <path
                  id="XMLID_9475_"
                  style="fill: #ffffff"
                  d="M231.5,284.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C231.6,284.2,231.7,284.4,231.5,284.5z"
                />
              </g>
              <g id="XMLID_9470_">
                <path
                  id="XMLID_9472_"
                  style="fill: #ffffff"
                  d="M223.5,279.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C223.6,279.6,223.6,279.8,223.5,279.9z"
                />
              </g>
              <g id="XMLID_9468_">
                <path
                  id="XMLID_9469_"
                  style="fill: #ffffff"
                  d="M227.5,282.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C227.6,281.9,227.7,282.1,227.5,282.2z"
                />
              </g>
              <g id="XMLID_9465_">
                <path
                  id="XMLID_9466_"
                  style="fill: #ffffff"
                  d="M227.3,287l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C227.4,286.7,227.4,286.9,227.3,287z"
                />
              </g>
              <g id="XMLID_9462_">
                <path
                  id="XMLID_9463_"
                  style="fill: #ffffff"
                  d="M235.7,287l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C235.9,286.7,235.9,286.9,235.7,287z"
                />
              </g>
              <g id="XMLID_9459_">
                <path
                  id="XMLID_9461_"
                  style="fill: #ffffff"
                  d="M231.5,289.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C231.7,289.1,231.7,289.3,231.5,289.4z"
                />
              </g>
              <g id="XMLID_9457_">
                <path
                  id="XMLID_9458_"
                  style="fill: #ffffff"
                  d="M248,294.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C248.2,293.8,248.2,294,248,294.1z"
                />
              </g>
              <g id="XMLID_9454_">
                <path
                  id="XMLID_9455_"
                  style="fill: #ffffff"
                  d="M252.1,296.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C252.2,296.1,252.2,296.3,252.1,296.4z"
                />
              </g>
              <g id="XMLID_9452_">
                <path
                  id="XMLID_9453_"
                  style="fill: #ffffff"
                  d="M256.1,298.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C256.2,298.4,256.3,298.6,256.1,298.7z"
                />
              </g>
              <g id="XMLID_9449_">
                <path
                  id="XMLID_9450_"
                  style="fill: #ffffff"
                  d="M260.3,301.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C260.5,300.9,260.5,301.1,260.3,301.2z"
                />
              </g>
              <g id="XMLID_9446_">
                <path
                  id="XMLID_9448_"
                  style="fill: #ffffff"
                  d="M264.4,303.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C264.5,303.2,264.5,303.4,264.4,303.5z"
                />
              </g>
              <g id="XMLID_9443_">
                <path
                  id="XMLID_9445_"
                  style="fill: #ffffff"
                  d="M239.8,289.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C239.9,289,239.9,289.2,239.8,289.3z"
                />
              </g>
              <g id="XMLID_9441_">
                <path
                  id="XMLID_9442_"
                  style="fill: #ffffff"
                  d="M244,291.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C244.2,291.5,244.2,291.7,244,291.8z"
                />
              </g>
              <g id="XMLID_9438_">
                <path
                  id="XMLID_9439_"
                  style="fill: #ffffff"
                  d="M268.4,305.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C268.5,305.5,268.6,305.7,268.4,305.8z"
                />
              </g>
              <g id="XMLID_9435_">
                <path
                  id="XMLID_9436_"
                  style="fill: #ffffff"
                  d="M235.8,291.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C235.9,291.6,235.9,291.8,235.8,291.9z"
                />
              </g>
              <g id="XMLID_9432_">
                <path
                  id="XMLID_9434_"
                  style="fill: #ffffff"
                  d="M248.1,299l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0L244,299c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C248.2,298.7,248.2,298.9,248.1,299z"
                />
              </g>
              <g id="XMLID_9430_">
                <path
                  id="XMLID_9431_"
                  style="fill: #ffffff"
                  d="M252.1,301.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C252.2,301,252.3,301.2,252.1,301.3z"
                />
              </g>
              <g id="XMLID_9427_">
                <path
                  id="XMLID_9428_"
                  style="fill: #ffffff"
                  d="M256.1,303.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C256.3,303.3,256.3,303.5,256.1,303.6z"
                />
              </g>
              <g id="XMLID_9424_">
                <path
                  id="XMLID_9425_"
                  style="fill: #ffffff"
                  d="M260.4,306.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C260.5,305.8,260.5,306,260.4,306.1z"
                />
              </g>
              <g id="XMLID_9422_">
                <path
                  id="XMLID_9423_"
                  style="fill: #ffffff"
                  d="M264.4,308.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C264.5,308.1,264.5,308.3,264.4,308.4z"
                />
              </g>
              <g id="XMLID_9419_">
                <path
                  id="XMLID_9420_"
                  style="fill: #ffffff"
                  d="M239.8,294.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C239.9,293.9,240,294.1,239.8,294.2z"
                />
              </g>
              <g id="XMLID_9416_">
                <path
                  id="XMLID_9418_"
                  style="fill: #ffffff"
                  d="M244,296.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C244.2,296.4,244.2,296.6,244,296.7z"
                />
              </g>
              <g id="XMLID_9413_">
                <path
                  id="XMLID_9415_"
                  style="fill: #ffffff"
                  d="M268.4,310.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C268.5,310.4,268.6,310.6,268.4,310.7z"
                />
              </g>
              <g id="XMLID_9411_">
                <path
                  id="XMLID_9412_"
                  style="fill: #ffffff"
                  d="M280.7,317.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C280.8,317.5,280.9,317.7,280.7,317.8z"
                />
              </g>
              <g id="XMLID_9408_">
                <path
                  id="XMLID_9409_"
                  style="fill: #ffffff"
                  d="M284.7,320.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C284.9,319.9,284.9,320,284.7,320.1z"
                />
              </g>
              <g id="XMLID_9405_">
                <path
                  id="XMLID_9406_"
                  style="fill: #ffffff"
                  d="M288.7,322.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C288.9,322.2,288.9,322.4,288.7,322.5z"
                />
              </g>
              <g id="XMLID_9402_">
                <path
                  id="XMLID_9404_"
                  style="fill: #ffffff"
                  d="M293,324.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C293.1,324.6,293.2,324.8,293,324.9z"
                />
              </g>
              <g id="XMLID_9400_">
                <path
                  id="XMLID_9401_"
                  style="fill: #ffffff"
                  d="M272.4,313l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C272.6,312.8,272.6,312.9,272.4,313z"
                />
              </g>
              <g id="XMLID_9397_">
                <path
                  id="XMLID_9398_"
                  style="fill: #ffffff"
                  d="M276.7,315.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C276.8,315.2,276.8,315.4,276.7,315.5z"
                />
              </g>
              <g id="XMLID_9394_">
                <path
                  id="XMLID_9395_"
                  style="fill: #ffffff"
                  d="M272.6,308.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C272.8,308,272.8,308.2,272.6,308.3z"
                />
              </g>
              <g id="XMLID_9392_">
                <path
                  id="XMLID_9393_"
                  style="fill: #ffffff"
                  d="M276.6,310.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C276.8,310.3,276.8,310.5,276.6,310.6z"
                />
              </g>
              <g id="XMLID_9389_">
                <path
                  id="XMLID_9391_"
                  style="fill: #ffffff"
                  d="M280.7,312.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C280.8,312.6,280.8,312.8,280.7,312.9z"
                />
              </g>
              <g id="XMLID_9387_">
                <path
                  id="XMLID_9388_"
                  style="fill: #ffffff"
                  d="M284.9,315.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C285.1,315.1,285.1,315.3,284.9,315.4z"
                />
              </g>
              <g id="XMLID_9384_">
                <path
                  id="XMLID_9385_"
                  style="fill: #ffffff"
                  d="M288.9,317.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C289.1,317.4,289.1,317.6,288.9,317.7z"
                />
              </g>
              <g id="XMLID_9381_">
                <path
                  id="XMLID_9382_"
                  style="fill: #ffffff"
                  d="M293,320l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C293.1,319.7,293.1,319.9,293,320z"
                />
              </g>
              <g id="XMLID_9378_">
                <path
                  id="XMLID_9380_"
                  style="fill: #ffffff"
                  d="M297.2,322.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C297.4,322.2,297.4,322.4,297.2,322.5z"
                />
              </g>
              <g id="XMLID_9375_">
                <path
                  id="XMLID_9377_"
                  style="fill: #ffffff"
                  d="M301.2,324.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C301.4,324.5,301.4,324.7,301.2,324.8z"
                />
              </g>
              <g id="XMLID_9373_">
                <path
                  id="XMLID_9374_"
                  style="fill: #ffffff"
                  d="M297,327.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C297.2,327,297.2,327.1,297,327.2z"
                />
              </g>
              <g id="XMLID_9370_">
                <path
                  id="XMLID_9371_"
                  style="fill: #ffffff"
                  d="M305.3,327.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C305.4,326.8,305.4,327,305.3,327.1z"
                />
              </g>
              <g id="XMLID_9367_">
                <path
                  id="XMLID_9368_"
                  style="fill: #ffffff"
                  d="M301,329.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C301.2,329.3,301.2,329.5,301,329.6z"
                />
              </g>
              <g id="XMLID_9364_">
                <path
                  id="XMLID_9366_"
                  style="fill: #ffffff"
                  d="M309.5,329.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C309.7,329.3,309.7,329.5,309.5,329.6z"
                />
              </g>
              <g id="XMLID_9362_">
                <path
                  id="XMLID_9363_"
                  style="fill: #ffffff"
                  d="M305.3,332l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C305.4,331.7,305.4,331.9,305.3,332z"
                />
              </g>
              <g id="XMLID_9359_">
                <path
                  id="XMLID_9360_"
                  style="fill: #ffffff"
                  d="M313.5,331.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C313.7,331.6,313.7,331.8,313.5,331.9z"
                />
              </g>
              <g id="XMLID_9356_">
                <path
                  id="XMLID_9358_"
                  style="fill: #ffffff"
                  d="M309.3,334.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C309.4,334,309.5,334.2,309.3,334.3z"
                />
              </g>
              <g id="XMLID_9353_">
                <path
                  id="XMLID_9355_"
                  style="fill: #ffffff"
                  d="M317.5,334.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C317.7,333.9,317.7,334.1,317.5,334.2z"
                />
              </g>
              <g id="XMLID_9351_">
                <path
                  id="XMLID_9352_"
                  style="fill: #ffffff"
                  d="M313.3,336.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C313.5,336.4,313.5,336.5,313.3,336.7z"
                />
              </g>
              <g id="XMLID_9337_">
                <path
                  id="XMLID_9338_"
                  style="fill: #ffffff"
                  d="M321.8,336.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C322,336.4,322,336.6,321.8,336.7z"
                />
              </g>
              <g id="XMLID_9334_">
                <path
                  id="XMLID_9335_"
                  style="fill: #ffffff"
                  d="M317.6,339.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C317.7,338.8,317.7,339,317.6,339.1z"
                />
              </g>
              <g id="XMLID_9331_">
                <path
                  id="XMLID_9333_"
                  style="fill: #ffffff"
                  d="M326,334.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C326.2,333.9,326.2,334.1,326,334.2z"
                />
              </g>
              <g id="XMLID_9328_">
                <path
                  id="XMLID_9330_"
                  style="fill: #ffffff"
                  d="M330.3,331.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C330.4,331.5,330.4,331.7,330.3,331.8z"
                />
              </g>
              <g id="XMLID_9326_">
                <path
                  id="XMLID_9327_"
                  style="fill: #ffffff"
                  d="M334.5,329.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C334.7,329,334.7,329.2,334.5,329.3z"
                />
              </g>
              <g id="XMLID_9323_">
                <path
                  id="XMLID_9324_"
                  style="fill: #ffffff"
                  d="M338.7,326.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C338.9,326.6,338.9,326.8,338.7,326.9z"
                />
              </g>
              <g id="XMLID_9320_">
                <path
                  id="XMLID_9322_"
                  style="fill: #ffffff"
                  d="M343,324.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C343.1,324.2,343.2,324.3,343,324.4z"
                />
              </g>
              <g id="XMLID_9318_">
                <path
                  id="XMLID_9319_"
                  style="fill: #ffffff"
                  d="M347.2,322l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C347.4,321.7,347.4,321.9,347.2,322z"
                />
              </g>
              <g id="XMLID_9315_">
                <path
                  id="XMLID_9316_"
                  style="fill: #ffffff"
                  d="M351.4,319.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C351.6,319.3,351.6,319.4,351.4,319.5z"
                />
              </g>
              <g id="XMLID_9312_">
                <path
                  id="XMLID_9313_"
                  style="fill: #ffffff"
                  d="M355.7,317.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C355.8,316.8,355.9,317,355.7,317.1z"
                />
              </g>
              <g id="XMLID_9309_">
                <path
                  id="XMLID_9311_"
                  style="fill: #ffffff"
                  d="M359.9,314.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C360.1,314.4,360.1,314.6,359.9,314.7z"
                />
              </g>
              <g id="XMLID_9306_">
                <path
                  id="XMLID_9308_"
                  style="fill: #ffffff"
                  d="M364.2,312.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C364.3,311.9,364.3,312.1,364.2,312.2z"
                />
              </g>
              <g id="XMLID_9304_">
                <path
                  id="XMLID_9305_"
                  style="fill: #ffffff"
                  d="M368.4,309.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C368.5,309.5,368.6,309.7,368.4,309.8z"
                />
              </g>
              <g id="XMLID_9301_">
                <path
                  id="XMLID_9302_"
                  style="fill: #ffffff"
                  d="M372.6,307.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C372.8,307,372.8,307.2,372.6,307.3z"
                />
              </g>
              <g id="XMLID_9299_">
                <path
                  id="XMLID_9300_"
                  style="fill: #ffffff"
                  d="M376.9,304.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C377,304.6,377,304.8,376.9,304.9z"
                />
              </g>
              <g id="XMLID_9296_">
                <path
                  id="XMLID_9298_"
                  style="fill: #ffffff"
                  d="M381.1,302.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C381.2,302.1,381.3,302.3,381.1,302.4z"
                />
              </g>
              <g id="XMLID_9292_">
                <path
                  id="XMLID_9294_"
                  style="fill: #ffffff"
                  d="M385.3,300l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C385.5,299.7,385.5,299.9,385.3,300z"
                />
              </g>
              <g id="XMLID_9288_">
                <path
                  id="XMLID_9290_"
                  style="fill: #ffffff"
                  d="M325.8,339l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C326,338.7,326,338.9,325.8,339z"
                />
              </g>
              <g id="XMLID_9284_">
                <path
                  id="XMLID_9286_"
                  style="fill: #ffffff"
                  d="M330.1,336.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C330.2,336.3,330.2,336.4,330.1,336.5z"
                />
              </g>
              <g id="XMLID_9280_">
                <path
                  id="XMLID_9282_"
                  style="fill: #ffffff"
                  d="M334.3,334.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C334.4,333.8,334.5,334,334.3,334.1z"
                />
              </g>
              <g id="XMLID_9276_">
                <path
                  id="XMLID_9278_"
                  style="fill: #ffffff"
                  d="M338.5,331.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C338.7,331.4,338.7,331.5,338.5,331.6z"
                />
              </g>
              <g id="XMLID_9272_">
                <path
                  id="XMLID_9274_"
                  style="fill: #ffffff"
                  d="M342.8,329.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C342.9,328.9,342.9,329.1,342.8,329.2z"
                />
              </g>
              <g id="XMLID_9268_">
                <path
                  id="XMLID_9270_"
                  style="fill: #ffffff"
                  d="M347,326.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C347.2,326.5,347.2,326.7,347,326.8z"
                />
              </g>
            </g>
            <g id="XMLID_9228_">
              <g id="XMLID_9262_">
                <path
                  id="XMLID_9264_"
                  style="fill: #ffffff"
                  d="M393.8,295.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C393.9,294.8,394,295,393.8,295.1z"
                />
              </g>
              <g id="XMLID_9258_">
                <path
                  id="XMLID_9260_"
                  style="fill: #ffffff"
                  d="M389.5,297.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C389.7,297.3,389.7,297.4,389.5,297.5z"
                />
              </g>
              <g id="XMLID_9254_">
                <path
                  id="XMLID_9256_"
                  style="fill: #ffffff"
                  d="M398,292.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C398.2,292.4,398.2,292.6,398,292.7z"
                />
              </g>
              <g id="XMLID_9250_">
                <path
                  id="XMLID_9252_"
                  style="fill: #ffffff"
                  d="M402.3,290.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C402.4,289.9,402.4,290.1,402.3,290.2z"
                />
              </g>
              <g id="XMLID_9246_">
                <path
                  id="XMLID_9248_"
                  style="fill: #ffffff"
                  d="M406.5,287.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C406.6,287.5,406.7,287.7,406.5,287.8z"
                />
              </g>
              <g id="XMLID_9242_">
                <path
                  id="XMLID_9244_"
                  style="fill: #ffffff"
                  d="M410.7,285.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C410.9,285,410.9,285.2,410.7,285.3z"
                />
              </g>
              <g id="XMLID_9238_">
                <path
                  id="XMLID_9240_"
                  style="fill: #ffffff"
                  d="M415,282.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C415.1,282.6,415.1,282.8,415,282.9z"
                />
              </g>
              <g id="XMLID_9234_">
                <path
                  id="XMLID_9236_"
                  style="fill: #ffffff"
                  d="M410.5,290.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C410.7,289.8,410.7,290,410.5,290.1z"
                />
              </g>
              <g id="XMLID_9230_">
                <path
                  id="XMLID_9232_"
                  style="fill: #ffffff"
                  d="M414.7,287.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
            l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C414.9,287.4,414.9,287.5,414.7,287.6z"
                />
              </g>
            </g>
          </g>
          <g id="XMLID_9160_">
            <g id="XMLID_9168_">
              <g id="XMLID_9196_">
                <g id="XMLID_9212_">
                  <g id="XMLID_9222_">
                    <path
                      id="XMLID_9224_"
                      style="fill: #ffffff"
                      d="M414.7,273.8C414.7,273.8,414.7,273.7,414.7,273.8v-8.4l-9.3,0.2l-85.5-48.9
                c-3.8-2.2-8.4-2.2-12.1,0L234.3,260l-15.6,2.9v8.4c-0.1,1.7,0.6,3.5,2.3,4.5l92.4,52.9c3.8,2.2,8.4,2.2,12.1,0l86.8-50.9
                C413.9,277,414.7,275.4,414.7,273.8L414.7,273.8L414.7,273.8z"
                    />
                  </g>
                  <g id="XMLID_9214_">
                    <path
                      id="XMLID_9216_"
                      d="M319.5,330.9c-2.2,0-4.4-0.6-6.3-1.7l-92.4-52.9c-1.8-1-2.8-3-2.6-5l0-8.9l16-3l73.4-43.1
                c3.9-2.3,8.8-2.3,12.7,0l85.4,48.8l9.7-0.2v9.5l0,0c-0.2,1.7-1.1,3.1-2.6,4l-86.8,50.9C323.9,330.3,321.7,330.9,319.5,330.9z
                 M219.2,263.3v8c-0.1,1.7,0.7,3.2,2.1,4l92.4,52.9c3.6,2.1,8,2.1,11.6,0l86.8-50.9c1.3-0.7,2.1-2.1,2.1-3.5l0-0.2V266
                l-8.9,0.2l-85.6-48.9c-3.6-2.1-8-2.1-11.6,0l-73.6,43.2L219.2,263.3z"
                    />
                  </g>
                </g>
                <g id="XMLID_9198_">
                  <g id="XMLID_9208_">
                    <path
                      id="XMLID_9210_"
                      style="fill: #ffffff"
                      d="M221,267.4l92.4,52.9c3.8,2.2,8.4,2.2,12.1,0l86.8-50.9
                c3.2-1.8,3.2-6.4,0-8.2l-92.4-52.9c-3.8-2.2-8.4-2.2-12.1,0L221,259.2C217.8,261,217.8,265.6,221,267.4z"
                    />
                  </g>
                  <g id="XMLID_9200_">
                    <path
                      id="XMLID_9202_"
                      d="M319.5,322.4c-2.2,0-4.4-0.6-6.3-1.7l-92.4-52.9c-1.7-1-2.6-2.7-2.6-4.6c0-1.9,1-3.6,2.6-4.6
                l86.8-50.9c3.9-2.3,8.8-2.3,12.7,0l92.4,52.9c1.7,1,2.6,2.7,2.6,4.6c0,1.9-1,3.6-2.6,4.6l-86.8,50.9
                C323.9,321.9,321.7,322.4,319.5,322.4z M221.2,266.9l92.4,52.9c3.6,2.1,8,2.1,11.6,0l86.8-50.9c1.3-0.8,2.1-2.1,2.1-3.6
                c0-1.5-0.8-2.9-2.1-3.6l-92.4-52.9c-3.6-2.1-8-2.1-11.6,0l-86.8,50.9c-1.3,0.8-2.1,2.1-2.1,3.6
                C219.2,264.8,219.9,266.2,221.2,266.9L221.2,266.9z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_9170_">
                <g id="XMLID_9190_">
                  <g id="XMLID_9192_">
                    <rect
                      id="XMLID_9194_"
                      x="222.8"
                      y="268.7"
                      width="1.1"
                      height="8.8"
                    />
                  </g>
                </g>
                <g id="XMLID_9184_">
                  <g id="XMLID_9186_">
                    <rect
                      id="XMLID_9188_"
                      x="318.7"
                      y="321.7"
                      width="1.1"
                      height="8.8"
                    />
                  </g>
                </g>
                <g id="XMLID_9178_">
                  <g id="XMLID_9180_">
                    <rect
                      id="XMLID_9182_"
                      x="324.1"
                      y="320.5"
                      width="1.1"
                      height="8.8"
                    />
                  </g>
                </g>
                <g id="XMLID_9172_">
                  <g id="XMLID_9174_">
                    <rect
                      id="XMLID_9176_"
                      x="409.9"
                      y="270.2"
                      width="1.1"
                      height="8.8"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="XMLID_9162_">
              <g id="XMLID_9164_">
                <polygon
                  id="XMLID_9166_"
                  points="326.6,319.7 326.6,328.1 408.7,280 408.7,271.5           "
                />
              </g>
            </g>
          </g>
          <g id="XMLID_9097_">
            <g id="XMLID_9105_">
              <g id="XMLID_9131_">
                <g id="XMLID_9146_">
                  <g id="XMLID_9156_">
                    <path
                      id="XMLID_9158_"
                      style="fill: #ffffff"
                      d="M318.2,224.8C318.2,224.8,318.2,224.8,318.2,224.8v-8.4l-9.3,0.2l-14.1-8
                c-3.8-2.2-8.4-2.2-12.1,0L250,227.9l-15.6,2.9v8.4c-0.1,1.7,0.6,3.5,2.3,4.5l21,12c3.8,2.2,8.4,2.2,12.1,0l46-26.8
                C317.3,228,318.1,226.5,318.2,224.8L318.2,224.8L318.2,224.8z"
                    />
                  </g>
                  <g id="XMLID_9148_">
                    <path
                      id="XMLID_9150_"
                      d="M263.8,257.9c-2.2,0-4.4-0.6-6.3-1.7l-21-12c-1.8-1-2.8-3-2.6-5l0-8.9l16-3l32.6-19.1
                c3.9-2.3,8.8-2.3,12.7,0l13.9,7.9l9.7-0.2v9.5l0,0c-0.2,1.7-1.1,3.1-2.6,4l-46,26.8C268.2,257.3,266,257.9,263.8,257.9z
                 M234.9,231.3v8c-0.1,1.7,0.7,3.2,2.1,4l21,12c3.6,2.1,8,2.1,11.6,0l46-26.8c1.3-0.7,2.1-2.1,2.1-3.5l0-0.2V217l-8.9,0.2
                l-14.2-8c-3.6-2.1-8-2.1-11.6,0l-32.7,19.2L234.9,231.3z"
                    />
                  </g>
                </g>
                <g id="XMLID_9133_">
                  <g id="XMLID_9143_">
                    <path
                      id="XMLID_9144_"
                      style="fill: #ffffff"
                      d="M236.7,235.3l21,12c3.8,2.2,8.4,2.2,12.1,0l46-26.8c3.2-1.8,3.2-6.4,0-8.2
                l-21-12c-3.8-2.2-8.4-2.2-12.1,0l-46,26.8C233.6,228.9,233.6,233.5,236.7,235.3z"
                    />
                  </g>
                  <g id="XMLID_9135_">
                    <path
                      id="XMLID_9137_"
                      d="M263.8,249.5c-2.2,0-4.4-0.6-6.3-1.7l-21-12c-1.7-1-2.6-2.7-2.6-4.6c0-1.9,1-3.6,2.6-4.6l46-26.8
                c3.9-2.3,8.8-2.3,12.7,0l21,12c1.7,1,2.6,2.7,2.6,4.6c0,1.9-1,3.6-2.6,4.6l-46,26.8C268.2,248.9,266,249.5,263.8,249.5z
                 M237,234.9l21,12c3.6,2.1,8,2.1,11.6,0l46-26.8c1.3-0.8,2.1-2.1,2.1-3.6c0-1.5-0.8-2.9-2.1-3.6l-21-12
                c-3.6-2.1-8-2.1-11.6,0l-46,26.8c-1.3,0.8-2.1,2.1-2.1,3.6C234.9,232.7,235.7,234.1,237,234.9z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_9107_">
                <g id="XMLID_9125_">
                  <g id="XMLID_9127_">
                    <rect
                      id="XMLID_9129_"
                      x="238.5"
                      y="236.6"
                      width="1.1"
                      height="8.8"
                    />
                  </g>
                </g>
                <g id="XMLID_9119_">
                  <g id="XMLID_9121_">
                    <rect
                      id="XMLID_9123_"
                      x="263"
                      y="248.7"
                      width="1.1"
                      height="8.8"
                    />
                  </g>
                </g>
                <g id="XMLID_9115_">
                  <g id="XMLID_9116_">
                    <rect
                      id="XMLID_9117_"
                      x="268.4"
                      y="247.5"
                      width="1.1"
                      height="8.8"
                    />
                  </g>
                </g>
                <g id="XMLID_9109_">
                  <g id="XMLID_9111_">
                    <rect
                      id="XMLID_9113_"
                      x="313.4"
                      y="221.3"
                      width="1.1"
                      height="8.8"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="XMLID_9099_">
              <g id="XMLID_9101_">
                <polygon
                  id="XMLID_9103_"
                  points="270.9,246.7 270.9,255.1 312.2,231 312.2,222.6           "
                />
              </g>
            </g>
          </g>
          <g id="XMLID_9048_">
            <g id="XMLID_9083_">
              <g id="XMLID_9093_">
                <path
                  id="XMLID_9095_"
                  style="fill: #ffffff"
                  d="M305.4,216.9c0,0,0-2.9,0-3.1c0,0,0,0,0-0.1l-6.5,0.6c0,0,0,0,0,0l-0.6-3.6
            c0,0,0,0-0.1,0l-6,3.4c0,0,0,0,0,0l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0c0,0.2,0.1,0.4,0.3,0.5l6,3.5
            c0.5,0.3,1.1,0.3,1.7,0l6.1-3.5C305.3,217.3,305.4,217.1,305.4,216.9L305.4,216.9z"
                />
              </g>
              <g id="XMLID_9085_">
                <path
                  id="XMLID_9087_"
                  d="M298.2,221.6c-0.4,0-0.7-0.1-1-0.3l-6-3.5c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.2l0-1.1l0.2-0.2
            c0.1-0.1,0.3-0.1,0.4-0.1l1,0.3l6.1-3.5l0.4,0.2l0.6,3.4l6.2-0.6l0.1,0c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8
            l-6.1,3.5C298.9,221.5,298.5,221.6,298.2,221.6z M291.4,214.3l0,0.4c0,0.9,0,2.1,0,2.2c0,0,0,0.1,0.1,0.2l6,3.5
            c0.4,0.2,0.9,0.2,1.2,0l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2h0l0-2.7l-6.2,0.6l-0.2-0.1l-0.2-0.1l-0.5-3.2l-5.7,3.3l-0.2,0
            L291.4,214.3z"
                />
              </g>
            </g>
            <g id="XMLID_9069_">
              <g id="XMLID_9079_">
                <path
                  id="XMLID_9081_"
                  d="M305.4,213.7l-6.5,0.6c0,0,0,0,0,0l-0.8,2.2l0,4.7c0.3,0,0.6-0.1,0.9-0.2l6.1-3.5
            c0.2-0.1,0.3-0.3,0.3-0.5l0,0C305.4,216.9,305.4,214,305.4,213.7C305.4,213.7,305.4,213.7,305.4,213.7z"
                />
              </g>
              <g id="XMLID_9071_">
                <path
                  id="XMLID_9073_"
                  d="M298.2,221.6C298.1,221.6,298.1,221.6,298.2,221.6l-0.5,0l0-5.2l0.9-2.7l0.3,0.2l6.5-0.6l0.1,0
            c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8l-6.1,3.5C298.9,221.5,298.5,221.6,298.2,221.6z M298.5,216.6l0,4.1
            c0.1,0,0.2-0.1,0.3-0.1l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2l0-2.7l-5.8,0.5L298.5,216.6z"
                />
              </g>
            </g>
            <g id="XMLID_9059_">
              <g id="XMLID_9066_">
                <path
                  id="XMLID_9067_"
                  d="M291.9,214.6c0,0,0.1-0.2,0.2-0.6l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0
            c0,0.2,0.1,0.4,0.3,0.5l0.6,0.4l0-1.7V214.6z"
                />
              </g>
              <g id="XMLID_9061_">
                <path
                  id="XMLID_9062_"
                  d="M292.3,218.5l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.2l0-1.1l0.2-0.2
            c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l-0.3,0.9L292.3,218.5z M291.4,214.3l0,0.4c0,0.9,0,2.1,0,2.2c0,0,0,0.1,0.1,0.1l0-2.6l0-0.1
            L291.4,214.3z"
                />
              </g>
            </g>
            <g id="XMLID_9050_">
              <g id="XMLID_9056_">
                <path
                  id="XMLID_9058_"
                  style="fill: #ffffff"
                  d="M297.4,217.8l-6.1-3.5c-0.4-0.2-0.4-0.9,0-1.1l6.2-3.5c0.5-0.3,1.1-0.3,1.6,0
            l6.1,3.5c0.4,0.2,0.4,0.9,0,1.1l-6.2,3.5C298.5,218.1,297.9,218.1,297.4,217.8z"
                />
              </g>
              <g id="XMLID_9051_">
                <path
                  id="XMLID_9052_"
                  d="M298.2,218.4c-0.4,0-0.7-0.1-1-0.3l-6.1-3.5c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9l6.2-3.5
            c0.6-0.4,1.4-0.4,2,0l6.1,3.5c0.3,0.2,0.5,0.5,0.5,0.9c0,0.4-0.2,0.7-0.5,0.9l-6.2,3.5C298.9,218.4,298.5,218.4,298.2,218.4z
             M298.2,209.8c-0.2,0-0.4,0.1-0.6,0.2l-6.2,3.5c-0.1,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0.1,0.2l6.1,3.5c0.4,0.2,0.8,0.2,1.2,0
            l6.2-3.5c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-6.1-3.5C298.7,209.9,298.5,209.8,298.2,209.8z"
                />
              </g>
            </g>
          </g>
          <g id="XMLID_9009_">
            <g id="XMLID_9039_">
              <g id="XMLID_9046_">
                <path
                  id="XMLID_9047_"
                  style="fill: #ffffff"
                  d="M251.1,265.1c0,0,0-2.9,0-3.1c0,0,0,0,0-0.1l-6.5,0.6c0,0,0,0,0,0l-0.6-3.6
            c0,0,0,0-0.1,0l-6,3.4c0,0,0,0,0,0l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0c0,0.2,0.1,0.4,0.3,0.5l6,3.5
            c0.5,0.3,1.1,0.3,1.7,0l6.1-3.5C250.9,265.5,251,265.3,251.1,265.1L251.1,265.1z"
                />
              </g>
              <g id="XMLID_9040_">
                <path
                  id="XMLID_9042_"
                  d="M243.8,269.8c-0.4,0-0.7-0.1-1-0.3l-6-3.5c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.2l0-1.1l0.2-0.2
            c0.1-0.1,0.3-0.1,0.4-0.1l1,0.3l6.1-3.5l0.4,0.2l0.6,3.4l6.2-0.6l0.1,0c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8
            l-6.1,3.5C244.5,269.7,244.1,269.8,243.8,269.8z M237,262.5l0,0.4c0,0.9,0,2.1,0,2.2c0,0,0,0.1,0.1,0.2l6,3.5
            c0.4,0.2,0.9,0.2,1.2,0l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2h0l0-2.7l-6.2,0.6l-0.2-0.1l-0.2-0.1l-0.5-3.2l-5.7,3.3l-0.2,0
            L237,262.5z"
                />
              </g>
            </g>
            <g id="XMLID_9030_">
              <g id="XMLID_9036_">
                <path
                  id="XMLID_9037_"
                  d="M251,261.9l-6.5,0.6c0,0,0,0,0,0l-0.8,2.2l0,4.7c0.3,0,0.6-0.1,0.9-0.2l6.1-3.5
            c0.2-0.1,0.3-0.3,0.3-0.5l0,0C251.1,265.1,251.1,262.2,251,261.9C251.1,261.9,251,261.9,251,261.9z"
                />
              </g>
              <g id="XMLID_9031_">
                <path
                  id="XMLID_9032_"
                  d="M243.8,269.8C243.8,269.8,243.7,269.8,243.8,269.8l-0.5,0l0-5.2l0.9-2.7l0.3,0.2l6.5-0.6l0.1,0
            c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8l-6.1,3.5C244.5,269.7,244.1,269.8,243.8,269.8z M244.1,264.7l0,4.1
            c0.1,0,0.2-0.1,0.3-0.1l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2l0-2.7l-5.8,0.5L244.1,264.7z"
                />
              </g>
            </g>
            <g id="XMLID_9020_">
              <g id="XMLID_9027_">
                <path
                  id="XMLID_9028_"
                  d="M237.5,262.8c0,0,0.1-0.2,0.2-0.6l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0
            c0,0.2,0.1,0.4,0.3,0.5l0.6,0.4l0-1.7V262.8z"
                />
              </g>
              <g id="XMLID_9021_">
                <path
                  id="XMLID_9023_"
                  d="M237.9,266.7l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.2l0-1.1l0.2-0.2
            c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l-0.3,0.9L237.9,266.7z M237,262.5l0,0.4c0,0.9,0,2.1,0,2.2c0,0,0,0.1,0.1,0.1l0-2.6l0-0.1
            L237,262.5z"
                />
              </g>
            </g>
            <g id="XMLID_9010_">
              <g id="XMLID_9017_">
                <path
                  id="XMLID_9019_"
                  style="fill: #ffffff"
                  d="M243,266l-6.1-3.5c-0.4-0.2-0.4-0.9,0-1.1l6.2-3.5c0.5-0.3,1.1-0.3,1.6,0
            l6.1,3.5c0.4,0.2,0.4,0.9,0,1.1l-6.2,3.5C244.1,266.3,243.5,266.3,243,266z"
                />
              </g>
              <g id="XMLID_9012_">
                <path
                  id="XMLID_9013_"
                  d="M243.8,266.6c-0.4,0-0.7-0.1-1-0.3l-6.1-3.5c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9l6.2-3.5
            c0.6-0.4,1.4-0.4,2,0l6.1,3.5c0.3,0.2,0.5,0.5,0.5,0.9c0,0.4-0.2,0.7-0.5,0.9l-6.2,3.5C244.5,266.5,244.1,266.6,243.8,266.6z
             M243.9,258c-0.2,0-0.4,0.1-0.6,0.2l-6.2,3.5c-0.1,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0.1,0.2l6.1,3.5c0.4,0.2,0.8,0.2,1.2,0
            l6.2-3.5c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-6.1-3.5C244.3,258.1,244.1,258,243.9,258z"
                />
              </g>
            </g>
          </g>
          <g id="XMLID_8999_">
            <g id="XMLID_9006_">
              <path
                id="XMLID_9007_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M250.2,207.8c0,0-1.5,4.5-2.4,6.5c-0.9,2-5.5,2.2-4.3-4.1c0.5-2.4,1-4.2,1.5-5.5c0.7-1.8,2.6-2.9,4.5-2.6l0,0l0.7,3.5V207.8z"
              />
            </g>
            <g id="XMLID_9001_">
              <path
                id="XMLID_9002_"
                d="M245,215.8c-0.7-0.2-2.7-1.2-1.9-5.7c0.4-2.1,0.9-4,1.5-5.6c0.8-2,2.9-3.2,5-2.8l0.3,0.1l0.7,3.8
          l0,2.3l0,0.1c-0.1,0.2-1.5,4.5-2.4,6.5c-0.4,0.9-1.4,1.5-2.4,1.5C245.5,216,245.2,215.9,245,215.8z M249.8,205.7l-0.6-3.2
          c-1.6-0.2-3.2,0.8-3.8,2.3c-0.6,1.6-1.1,3.4-1.5,5.5c-0.5,2.6,0,4.3,1.3,4.8c0.9,0.3,1.9-0.2,2.2-0.9c0.8-1.8,2.2-5.8,2.4-6.4
          V205.7z"
              />
            </g>
          </g>
          <g id="XMLID_8992_">
            <g id="XMLID_8997_">
              <path
                id="XMLID_8998_"
                d="M245.1,204.7c0.7-1.8,2.6-2.9,4.5-2.6c0,0,0.1,0,0.1,0.1l0.4,2.2c0,0,0,0.1-0.1,0.1
          c-0.8,0.1-3.2,0.6-5.5,2.3c-0.1,0-0.1,0-0.1-0.1C244.6,205.9,244.9,205.3,245.1,204.7z"
              />
            </g>
            <g id="XMLID_8993_">
              <path
                id="XMLID_8994_"
                d="M244.2,207.1c-0.2-0.1-0.3-0.3-0.2-0.5c0.2-0.7,0.4-1.4,0.7-2.1l0,0c0.8-2,2.8-3.2,4.9-2.8
          c0.2,0,0.4,0.2,0.4,0.4l0.4,2.2c0,0.1,0,0.3-0.1,0.4l-0.2,0.2l-0.2,0c-0.7,0.1-3,0.5-5.3,2.2c-0.1,0.1-0.2,0.1-0.3,0.1
          C244.4,207.2,244.3,207.2,244.2,207.1z M249.3,202.5c-1.6-0.2-3.2,0.8-3.8,2.3c-0.1,0.3-0.3,0.7-0.4,1.1
          c1.9-1.2,3.6-1.6,4.5-1.8L249.3,202.5z"
              />
            </g>
          </g>
          <g id="XMLID_8985_">
            <g id="XMLID_8990_">
              <path
                id="XMLID_8991_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M247.4,204.2c0.2-0.5,0.6-0.9,1-1.2c1.2-0.9,4-3.1,5.9-5.2c2.3-2.5,4.4-6.6,9.2-7.4c0,0,0,0,0,0c0.7-0.9,0.9-4.7,0.9-4.7
          l2.6-3.1l5.8,0.7c0,0,0,5.2-1.6,8.7c-1.6,3.5-4.4,4.6-4.4,4.6s-12.8,5-15.3,9c-2.6,4-2.6,6.3-5,5.5
          C244.4,210.6,246.5,206,247.4,204.2z"
              />
            </g>
          </g>
          <g id="XMLID_8963_">
            <g id="XMLID_8978_">
              <g id="XMLID_8983_">
                <path
                  id="XMLID_8984_"
                  style="
                    fill: #ffffff;
                    stroke: #000000;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 10;
                  "
                  d="
            M282.5,215.9c0,0,4.2,3.1,5.6,6s-1.5,3.4-4.6,1.1c-3.1-2.2-4.7-1.4-6.1-3.4c-1.4-2,0.9-5.9,0.9-5.9L282.5,215.9z"
                />
              </g>
              <g id="XMLID_8979_">
                <path
                  id="XMLID_8980_"
                  d="M283.2,223.3c-1.4-1-2.5-1.4-3.4-1.7c-1.1-0.4-2-0.7-2.8-1.9c-1.5-2.2,0.8-6.1,0.9-6.3l0.2-0.3
            l4.6,2.4l0.1,0c0.2,0.1,4.3,3.2,5.7,6.1c0.8,1.6,0.2,2.4-0.2,2.7c-0.3,0.3-0.8,0.4-1.4,0.4
            C285.9,224.8,284.5,224.3,283.2,223.3z M278.4,214.2c-0.6,1.2-1.6,3.7-0.7,5.1c0.6,1,1.3,1.2,2.3,1.5c0.9,0.3,2.1,0.7,3.6,1.8
            c1.9,1.4,3.5,1.6,4.1,1.1c0.5-0.4,0.2-1.2-0.1-1.7c-1.2-2.6-5-5.5-5.4-5.8L278.4,214.2z"
                />
              </g>
            </g>
            <g id="XMLID_8971_">
              <g id="XMLID_8976_">
                <path
                  id="XMLID_8977_"
                  d="M279.7,221.1C279.7,221.1,279.7,221.2,279.7,221.1c-0.9-0.2-1.6-0.6-2.3-1.5
            c-1.3-1.9,0.7-5.5,0.9-5.8c0,0,0,0,0,0l0.8,0.4c0,0,0,0,0,0l0.7,2.1c0,0,0,0,0,0l-0.7,1.5C279.1,217.8,278.1,219.9,279.7,221.1
            z"
                />
              </g>
              <g id="XMLID_8972_">
                <path
                  id="XMLID_8973_"
                  d="M279.5,221.5c-0.9-0.3-1.7-0.7-2.5-1.8c-1.4-2.1,0.5-5.6,0.9-6.3l0.2-0.3l1.2,0.6l0.2,0.1l0.8,2.3
            l0,0.2l-0.8,1.6c0,0.1-0.8,1.8,0.4,2.8l0,0c0.2,0.1,0.2,0.4,0.1,0.6l-0.2,0.3L279.5,221.5z M278.8,214.4l-0.4-0.2
            c-0.7,1.4-1.6,3.8-0.7,5.1c0.3,0.4,0.5,0.7,0.8,0.9c-0.3-1-0.1-2,0.2-2.6l0.7-1.3L278.8,214.4z"
                />
              </g>
            </g>
            <g id="XMLID_8964_">
              <g id="XMLID_8969_">
                <path
                  id="XMLID_8970_"
                  style="
                    fill: #ffffff;
                    stroke: #000000;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 10;
                  "
                  d="
            M283.2,191.1c0,0-0.5,5.3-1.1,11c-0.4,4.1-0.3,8.1-0.1,10.9c0,1.1,0.5,3.4,1.1,4.2c0.7,1,1.6,2.2,1.3,2.6
            c-0.4,0.7-3.3-0.5-5.3-2c-0.4-0.3-0.8-0.8-1-1.3l0,0c-0.3-0.6-0.4-1.4-0.4-2.1c0.2-2.1-0.2-7.5-1.5-12.2
            c-1.7-6.2-1.5-11-0.1-13.2C277.5,186.8,283.2,191.1,283.2,191.1z"
                />
              </g>
            </g>
          </g>
          <g id="XMLID_8925_">
            <g id="XMLID_8932_">
              <path
                id="XMLID_8933_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M283.3,150.5c0,0,1.3,5,1.4,8.1c0,0.1,0,0.1,0.1,0.1c0.6-0.1,3.5-0.7,6.6,0.3c2.5,0.8,6.7,0.6,6.7,0.6s1.5-1.4,2.1-1.5
          c0.6-0.1,0.8,0.9,0.1,1.8c-0.1,0.1,0,0.2,0.1,0.2l2.8-0.4c0.4-0.1,0.9,0.2,1.1,0.6l0,0c0.2,0.4,0.1,0.8-0.2,1.1
          c-0.8,0.9-2.8,2.5-5.2,1.6l-1.6-0.2c0,0-7,0.3-13.1,0.5c-0.7,0-1.5-0.1-2.2-0.3l0,0c-0.4-0.1-0.7-0.5-0.9-0.9
          c-0.5-1.6-1.8-5.6-2.7-9.2c-1.2-4.5,3.1-3.6,3.1-3.6L283.3,150.5z"
              />
            </g>
          </g>
          <g id="XMLID_8905_">
            <g id="XMLID_8912_">
              <path
                id="XMLID_8914_"
                d="M282.3,145.9c0,0,3,3.1,0.9,6.4l-1.5,0.4c0,0-1,11.7,2.3,16c5.1,6.7-1.3,45-1.3,45s-1.2,0.1-5.9-0.9
          c0,0-1.9-12.4-2.2-15.2c-0.1-0.7-0.4-4.4,0-13c-2.2,16.2-15,14.3-21.9,21.5l-2.3-6c0,0,1.7-4.8,6.9-8.2
          c6.7-4.4,8.1-8.9,7.9-10.2c-0.6-3.9,1.9-8.4,3.7-12.6c1.8-4.2-2.4-13.1-2.4-13.1l-3.5-9l3.9-4.4l7.4-1.9l5.5,0.9L282.3,145.9z"
              />
            </g>
          </g>
          <g id="XMLID_8901_">
            <g id="XMLID_8902_">
              <path
                id="XMLID_8904_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M281.8,152.2c0-0.1,0.2-2.3-0.3-3.9l-0.6,0.2c0.5,1.5,0.3,3.6,0.3,3.6L281.8,152.2z"
              />
            </g>
          </g>
          <g id="XMLID_8893_">
            <g id="XMLID_8894_">
              <path
                id="XMLID_8895_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M269.2,187.8l0.5-0.4c-0.2-0.2-0.4-0.9-0.4-1.9c0-0.9,0.4-4.1,3.4-6.8c3.9-3.6,3.3-6.8,3.3-7l-0.6,0.1l0.3-0.1l-0.3,0.1
          c0,0,0.6,3.1-3.1,6.3c-3.2,2.9-3.5,6.3-3.6,7.2C268.6,186.5,268.8,187.4,269.2,187.8z"
              />
            </g>
          </g>
          <g id="XMLID_8885_">
            <g id="XMLID_8886_">
              <path
                id="XMLID_8887_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M275.3,163.5v-0.6c0,0-3.4,0-5.9-1.6l-0.3,0.5C271.8,163.5,275.2,163.5,275.3,163.5z"
              />
            </g>
          </g>
          <g id="XMLID_8872_">
            <g id="XMLID_8873_">
              <path
                id="XMLID_8875_"
                d="M263.5,192c0,0,0.2-1,0.4-1.6l-0.8-0.4c-0.3,0.7-0.5,1.7-0.5,1.8L263.5,192z"
              />
            </g>
          </g>
          <g id="XMLID_8868_">
            <g id="XMLID_8869_">
              <circle
                id="XMLID_8870_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                cx="275.5"
                cy="152.2"
                r="0.7"
              />
            </g>
          </g>
          <g id="XMLID_8863_">
            <g id="XMLID_8865_">
              <path
                id="XMLID_8866_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M272.6,151.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7C272.9,150.5,272.6,150.8,272.6,151.2z"
              />
            </g>
          </g>
          <g id="XMLID_8821_">
            <g id="XMLID_8822_">
              <g id="XMLID_8854_">
                <g id="XMLID_8861_">
                  <path
                    id="XMLID_8862_"
                    style="
                      fill: #ffffff;
                      stroke: #000000;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-miterlimit: 10;
                    "
                    d="
              M282.4,170.2c0,0-0.9-4.2-0.9-4.5c0,0,0-0.1,0-0.1l9.5-1.1c0,0,0,0,0,0l-0.2-5.3c0,0,0.1,0,0.1,0l9.6,3.1c0,0,0,0,0.1,0
              l1.5-0.8c0,0,0.1,0,0.1,0.1c0.1,0.4,0.9,4.4,0.9,4.4l0,0c0,0.3-0.1,0.6-0.3,0.8l-7.6,6.8c-0.6,0.6-1.6,0.8-2.4,0.5l-9.8-3.2
              C282.7,170.8,282.5,170.5,282.4,170.2L282.4,170.2z"
                  />
                </g>
                <g id="XMLID_8855_">
                  <path
                    id="XMLID_8856_"
                    d="M292.8,174.5l-9.8-3.2c-0.4-0.1-0.8-0.5-0.9-0.9l0-0.1c0,0-0.9-4.5-0.9-4.5c0-0.2,0.1-0.4,0.3-0.5
              l9.3-1.1l-0.2-5.1l0.1-0.1c0.1-0.1,0.3-0.2,0.5-0.1l9.5,3.1l1.4-0.7c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.2,0.2,0.3,0.3l0.3,1.3
              c0.7,3.1,0.7,3.1,0.7,3.2c0,0.4-0.1,0.9-0.4,1.1l-7.6,6.8c-0.5,0.5-1.2,0.7-1.9,0.7C293.3,174.6,293,174.6,292.8,174.5z
               M300.8,162.6l-0.2,0.1l-9.3-3l0.2,5l-0.2,0.2L282,166c0.2,1.1,0.9,4.1,0.9,4.1l-0.4,0.1l0.4-0.1c0,0.1,0.1,0.3,0.3,0.4
              l9.8,3.2c0.7,0.2,1.4,0.1,2-0.4l7.6-6.8c0.2-0.2,0.2-0.3,0.2-0.4c0-0.1-0.4-1.9-0.6-3.1l-0.2-0.8L300.8,162.6z"
                  />
                </g>
              </g>
              <g id="XMLID_8844_">
                <g id="XMLID_8846_">
                  <path
                    id="XMLID_8847_"
                    d="M292.8,174.5l-9.8-3.2c-0.4-0.1-0.8-0.5-0.9-0.9l0-0.1c0,0-0.2-1.1-0.4-2.1l-0.5-2.4
              c0-0.2,0.1-0.4,0.3-0.5l9.5-1.2l0.3-0.3l2,3.4l1.5,7.2l-0.4,0.1c-0.2,0.1-0.5,0.1-0.7,0.1C293.3,174.6,293,174.6,292.8,174.5z
               M292.4,167.6l-1.6-2.7l-8.9,1l0.4,2c0.2,1,0.4,1.9,0.4,2.1l0,0c0,0.2,0.2,0.3,0.3,0.4l9.8,3.2c0.2,0.1,0.5,0.1,0.7,0.1
              L292.4,167.6z"
                  />
                </g>
              </g>
              <g id="XMLID_8834_">
                <g id="XMLID_8841_">
                  <path
                    id="XMLID_8843_"
                    d="M301.2,162.9c0,0-0.2-0.3-0.5-0.7l1.4-0.7c0,0,0.1,0,0.1,0.1c0.1,0.4,0.9,4.4,0.9,4.4l0,0
              c0,0.3-0.1,0.6-0.3,0.8l-0.8,0.7l-0.5-2.4L301.2,162.9z"
                  />
                </g>
              </g>
              <g id="XMLID_8824_">
                <g id="XMLID_8831_">
                  <path
                    id="XMLID_8833_"
                    style="
                      fill: #ffffff;
                      stroke: #000000;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-miterlimit: 10;
                    "
                    d="
              M294.3,169.1l7.6-6.9c0.5-0.5,0.4-1.4-0.3-1.6l-9.9-3.2c-0.8-0.3-1.7-0.1-2.3,0.5l-7.6,6.9c-0.5,0.5-0.4,1.4,0.3,1.6l9.9,3.2
              C292.8,169.9,293.7,169.7,294.3,169.1z"
                  />
                </g>
                <g id="XMLID_8825_">
                  <path
                    id="XMLID_8827_"
                    d="M291.9,170l-9.9-3.2c-0.5-0.2-0.8-0.5-0.9-1c-0.1-0.5,0.1-1,0.4-1.3l7.6-6.9
              c0.7-0.7,1.8-0.9,2.7-0.6l9.9,3.2c0.5,0.2,0.8,0.5,0.9,1c0.1,0.5-0.1,1-0.4,1.3l-7.6,6.9c-0.5,0.5-1.2,0.7-1.9,0.7
              C292.4,170.1,292.1,170.1,291.9,170z M289.7,158.2l-7.6,6.9c-0.2,0.2-0.2,0.4-0.2,0.5c0,0.1,0.1,0.3,0.3,0.4l9.9,3.2
              c0.7,0.2,1.4,0.1,1.9-0.4l7.6-6.9c0.2-0.2,0.2-0.4,0.2-0.5c0-0.1-0.1-0.3-0.3-0.4l-9.9-3.2c-0.2-0.1-0.4-0.1-0.6-0.1
              C290.5,157.7,290,157.9,289.7,158.2z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_8809_">
            <g id="XMLID_8810_">
              <g id="XMLID_8818_">
                <polygon
                  id="XMLID_8819_"
                  style="
                    fill: #ffffff;
                    stroke: #000000;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 10;
                  "
                  points="
            302.4,157.7 297.3,162.2 290.8,160.2 295.9,155.6           "
                />
              </g>
            </g>
          </g>
          <g id="XMLID_8800_">
            <g id="XMLID_8806_">
              <path
                id="XMLID_8807_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M273.8,142.1c2.1,1.5,3.9,1.3,3.9,1.3c3.7-4.5,1.7-9.8,1.7-9.8s-5.2-2-7.7,0.9l-2.9,2c0,0,2.9,5.6,0.4,7.5
          c-2.5,1.9,6.9,6.6,7.7,6.6s1.4-3.8-0.2-7.1L273.8,142.1z"
              />
            </g>
            <g id="XMLID_8801_">
              <path
                id="XMLID_8802_"
                d="M268.4,145.3c-0.2-0.6,0-1.2,0.5-1.6c1.8-1.4,0.2-5.5-0.5-7l-0.2-0.3l3.2-2.2c2.7-3,7.9-1,8.2-0.9
          l0.2,0.1l0.1,0.2c0.1,0.2,2,5.6-1.8,10.2l-0.1,0.1l-0.2,0c0,0-0.2,0-0.5,0c1.2,2.6,1,5.6,0.4,6.7c-0.2,0.4-0.5,0.5-0.7,0.5
           M273.5,142.5l0.5-0.7c1.6,1.1,3,1.3,3.4,1.3c2.9-3.8,1.8-8.2,1.6-9.1c-0.9-0.3-5-1.5-7.1,0.8l-2.7,1.9c0.6,1.3,2.5,5.8,0.1,7.7
          c-0.2,0.2-0.3,0.4-0.2,0.6c0.5,1.9,6.8,5.1,7.7,5.2c0.4-0.4,0.9-3.6-0.6-6.5l0,0C275.5,143.6,274.6,143.2,273.5,142.5z"
              />
            </g>
          </g>
          <g id="XMLID_8790_">
            <g id="XMLID_8797_">
              <path
                id="XMLID_8798_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M266.2,153.6c0,0-0.4,5.2-1.3,8.2c0,0.1,0,0.1,0.1,0.1c0.6,0.1,3.5,0.5,6.2,2.4c2.1,1.5,7.9,3.2,7.9,3.2s1.8-0.8,2.5-0.7
          c0.6,0.1,0.5,1.1-0.5,1.7c-0.1,0.1-0.1,0.2,0,0.2l2.8,0.5c0.4,0.1,0.8,0.4,0.8,0.9l0,0c0,0.4-0.2,0.8-0.5,1
          c-1.1,0.6-3.4,1.5-5.4-0.2l-1.5-0.7c0,0-8.4-2.4-14.3-4.2c-0.7-0.2-1.4-0.6-2-1l0,0c-0.4-0.3-0.6-0.7-0.6-1.1
          c0-1.6,0.1-5.9,0.4-9.5c0.3-4.7,4.1-2.5,4.1-2.5L266.2,153.6z"
              />
            </g>
          </g>
          <g id="XMLID_8780_">
            <g id="XMLID_8787_">
              <path
                id="XMLID_8789_"
                d="M267,150c0,0,0.4,1.2-0.4,5.3c-0.1,0.3-0.3,0.5-0.5,0.5c-1.3,0-4.9,0-5.2-1.4
          c-0.4-1.7,1.4-7.3,3.2-7.5C266.8,146.5,267,150,267,150z"
              />
            </g>
            <g id="XMLID_8782_">
              <path
                id="XMLID_8783_"
                d="M260.4,154.4c-0.3-1.5,0.7-5.2,2-6.8c0.5-0.7,1-1.1,1.6-1.1c0.9-0.1,1.6,0.1,2.2,0.7
          c1.1,0.9,1.2,2.5,1.2,2.9c0.1,0.3,0.3,1.7-0.4,5.4c-0.1,0.5-0.5,0.8-0.9,0.8c-0.2,0-0.4,0-0.7,0
          C263.1,156.1,260.7,155.8,260.4,154.4z M266.6,150c0,0-0.1-1.5-1-2.3c-0.4-0.4-0.9-0.5-1.6-0.4c-0.3,0-0.6,0.3-1,0.8
          c-1.2,1.6-2.1,5-1.8,6.2c0.2,0.7,2.1,1.2,4.8,1.1c0.1,0,0.1-0.1,0.1-0.1C266.9,151.4,266.6,150.1,266.6,150L266.6,150z"
              />
            </g>
          </g>
          <g id="XMLID_8776_">
            <g id="XMLID_8778_">
              <path
                id="XMLID_8779_"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                d="
          M267,154.6c0-0.2,1-4.2-0.3-6.8c0,0-0.5-1.2-1.7-1.3l0,0.6c0.8,0.1,1.2,0.9,1.2,0.9c1.2,2.4,0.3,6.3,0.3,6.3L267,154.6z"
              />
            </g>
          </g>
          <g id="XMLID_8774_">
            <path
              id="XMLID_8699_"
              style="fill: #ffffff; stroke: #000000; stroke-miterlimit: 10"
              d="M269.8,148.7c-0.5-2.8,0.3-2.5,0.2-5.7
        c0,0,2.2-1,1.5-3.3c-0.6-2,1-2.1,1-2.1c0.2,0.5,0.7,0.8,1.2,0.6c0.8-0.3,1.9-1,2.3-2.5l3-1.1c0.2-0.1,0.5,0,0.6,0.3
        c0.4,1,0.6,2.8,0.1,4.3c-0.6,1.7-1.1,2.8-1.1,2.8s-0.2,2.1,1.1,3.2c0.8,0.6,1.2,1.7,1.4,2.5c0.1,0.3,0.5,0.3,0.6,0
        c0.6-1.5,1.1-3.7-0.4-6c-1.9-2.8-0.3-5.4-0.3-5.4c1.8-5.5-3.2-6.7-3.2-6.7s-2.4-2-7.4,0.4C262.3,133.8,264.4,146.9,269.8,148.7z"
            />
          </g>
          <g id="XMLID_8745_">
            <g id="XMLID_8746_">
              <path
                id="XMLID_8748_"
                d="M273.2,135.2l0.8-0.2c0-0.1-0.3-1.5-2.1-1.8l-0.1,0.8C272.9,134.3,273.2,135.2,273.2,135.2z"
              />
            </g>
          </g>
          <g id="XMLID_8741_">
            <g id="XMLID_8742_">
              <path
                id="XMLID_8743_"
                d="M279.1,134.9c0-0.1,0.2-1.4-0.4-2.7l-0.8,0.3c0.5,1.1,0.3,2.3,0.3,2.3L279.1,134.9z"
              />
            </g>
          </g>
          <g id="XMLID_8736_">
            <g id="XMLID_8738_">
              <path
                id="XMLID_8739_"
                d="M277.7,140.8c0.2,0,0.3,0,0.3,0l0.2-0.1l0.3-1.6l-0.6-0.1l-0.2,1.2c-0.1,0-0.3-0.1-0.5-0.2l-0.3,0.6
          C277.2,140.8,277.5,140.8,277.7,140.8z"
              />
            </g>
          </g>
          <g id="XMLID_8732_">
            <g id="XMLID_8734_">
              <path
                id="XMLID_8735_"
                d="M277.3,142c0.1,0,0.2,0,0.4,0l-0.1-0.6c-0.6,0.1-0.9-0.3-0.9-0.3l-0.5,0.4
          C276.1,141.5,276.6,142,277.3,142z"
              />
            </g>
          </g>
          <g id="XMLID_8728_">
            <g id="XMLID_8729_">
              <path
                id="XMLID_8731_"
                d="M277.3,138.3c0,0.4-0.3,0.7-0.5,0.7c-0.3,0-0.4-0.4-0.4-0.8c0-0.4,0.3-0.7,0.5-0.7
          C277.1,137.6,277.3,137.9,277.3,138.3z"
              />
            </g>
          </g>
          <g id="XMLID_8724_">
            <g id="XMLID_8725_">
              <path
                id="XMLID_8727_"
                d="M279.7,138.7c0,0.4-0.3,0.7-0.5,0.7c-0.3,0-0.4-0.4-0.4-0.8c0-0.4,0.3-0.7,0.5-0.7
          C279.6,137.9,279.7,138.3,279.7,138.7z"
              />
            </g>
          </g>
          <g id="XMLID_8720_">
            <g id="XMLID_8721_">
              <rect
                id="XMLID_8722_"
                x="286.3"
                y="170.1"
                transform="matrix(0.3341 -0.9425 0.9425 0.3341 29.3486 384.2914)"
                style="
                  fill: #ffffff;
                  stroke: #000000;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                "
                width="0.6"
                height="2.5"
              />
            </g>
          </g>
          <path
            style="
              fill: #ffffff;
              stroke: #000000;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-miterlimit: 10;
            "
            d="M260.7,187.4"
          />
          <g id="XMLID_7954_">
            <g id="XMLID_8676_">
              <g id="XMLID_8687_">
                <g id="XMLID_8694_">
                  <path
                    id="XMLID_8695_"
                    style="fill: #ffffff"
                    d="M332.8,193.4L325,189c-0.6-0.3-1,0-1,0.8v2.6c0,0.8,0.5,1.7,1,2l1.8,1.1
              c0.1,0.7,0.6,2.4,2.3,3.3c0.1,0,0.1,0,0.1-0.1c-0.1-0.4-0.4-1.2-0.2-1.9l3.7,2.1c0.6,0.3,1,0,1-0.8v-2.6
              C333.8,194.6,333.3,193.7,332.8,193.4z"
                  />
                </g>
                <g id="XMLID_8688_">
                  <path
                    id="XMLID_8690_"
                    d="M333.2,199.2c-0.2,0-0.4-0.1-0.6-0.2l-3.3-1.8c0,0.5,0.1,1.1,0.2,1.3c0.1,0.2,0,0.4-0.1,0.5
              c-0.1,0.1-0.3,0.1-0.5,0c-1.6-0.9-2.2-2.6-2.4-3.4l-1.7-1c-0.7-0.4-1.2-1.4-1.2-2.3v-2.6c0-0.5,0.2-0.9,0.5-1.1
              c0.3-0.2,0.6-0.1,1,0.1l7.7,4.4c0.7,0.4,1.2,1.4,1.2,2.3v2.6c0,0.5-0.2,0.9-0.5,1.1C333.5,199.1,333.3,199.2,333.2,199.2z
               M328.8,196.2l4.1,2.3c0.1,0.1,0.3,0.1,0.4,0.1c0.1-0.1,0.2-0.3,0.2-0.6v-2.6c0-0.7-0.4-1.4-0.9-1.7l-7.7-4.4
              c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0.1-0.2,0.3-0.2,0.6v2.6c0,0.7,0.4,1.4,0.9,1.7l2,1.1l0,0.1c0.1,0.6,0.5,1.8,1.6,2.7
              c-0.1-0.4-0.1-1,0-1.5L328.8,196.2z"
                  />
                </g>
              </g>
              <g id="XMLID_8677_">
                <g id="XMLID_8684_">
                  <path
                    id="XMLID_8685_"
                    style="fill: #ffffff"
                    d="M340.4,190.9l-4.7-2.7c-0.4-0.2-0.6,0-0.6,0.5v1.6c0,0.5,0.3,1,0.6,1.2
              l1.1,0.6c0.1,0.4,0.4,1.4,1.4,2c0.1,0,0.1,0,0.1-0.1c-0.1-0.2-0.2-0.7-0.1-1.2l2.3,1.3c0.4,0.2,0.6,0,0.6-0.5v-1.6
              C341,191.6,340.8,191.1,340.4,190.9z"
                  />
                </g>
                <g id="XMLID_8678_">
                  <path
                    id="XMLID_8680_"
                    d="M340.7,194.5c-0.1,0-0.3,0-0.4-0.1l-1.8-1c0,0.2,0.1,0.4,0.1,0.5c0.1,0.2,0,0.3-0.1,0.4l-0.2,0.1
              l-0.2-0.1c-1-0.6-1.4-1.6-1.5-2.1l-1-0.6c-0.5-0.3-0.8-0.9-0.8-1.5v-1.6c0-0.4,0.1-0.7,0.4-0.8c0.2-0.1,0.5-0.1,0.7,0l4.7,2.7
              c0.5,0.3,0.8,0.9,0.8,1.5v1.6c0,0.4-0.1,0.7-0.4,0.8C340.9,194.5,340.8,194.5,340.7,194.5z M337.9,192.4l2.6,1.5
              c0.1,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.2v-1.6c0-0.4-0.2-0.8-0.5-0.9l-4.7-2.7c-0.1,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.2v1.6
              c0,0.4,0.2,0.8,0.5,0.9l1.2,0.7l0,0.1c0.1,0.3,0.2,0.9,0.7,1.4c0-0.2,0-0.4,0-0.6L337.9,192.4z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_7955_">
              <g id="XMLID_8537_">
                <g id="XMLID_8672_">
                  <g id="XMLID_8673_">
                    <path
                      id="XMLID_8674_"
                      d="M343.7,225.5h-1.9v-7.7c0-1.5,0.7-3,1.8-4l8.3-7.1c0.8-0.7,1.8-0.8,2.7-0.4s1.5,1.3,1.5,2.3v6.1
                h-1.9v-6.1c0-0.4-0.3-0.5-0.4-0.6c-0.1,0-0.4-0.1-0.7,0.1l-8.3,7.1c-0.7,0.6-1.2,1.5-1.2,2.5V225.5z"
                    />
                  </g>
                </g>
                <g id="XMLID_8667_">
                  <g id="XMLID_8669_">
                    <path
                      id="XMLID_8670_"
                      d="M374.2,275l-1-1.7l14.7-8.6c1.2-0.7,1.9-2,1.9-3.3l0-30.6h1.9l0,30.6c0,2-1.1,3.9-2.8,5L374.2,275z
                "
                    />
                  </g>
                </g>
                <g id="XMLID_8664_">
                  <g id="XMLID_8665_">
                    <path
                      id="XMLID_8666_"
                      d="M341.5,255.7l-1-1.7l14.7-8.6c1.2-0.7,1.9-2,1.9-3.3l0-30.6h1.9l0,30.6c0,2-1.1,3.9-2.8,5
                L341.5,255.7z"
                    />
                  </g>
                </g>
                <g id="XMLID_8588_">
                  <g id="XMLID_8654_">
                    <g id="XMLID_8661_">
                      <path
                        id="XMLID_8662_"
                        style="fill: #ffffff"
                        d="M393.7,244.2c0,0,0-1.9,0-2.3c0,0,0-0.1-0.1-0.1l-5.8-1.6c0,0,0,0,0,0
                  l-14.2-8.3c0,0-0.1,0-0.1,0l-5,2.9c0,0,0,0-0.1,0l-1.8-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.5,0,2.2,0,2.2l0,0
                  c0,0.3,0.2,0.6,0.5,0.8l18.3,10.7c0.8,0.5,1.8,0.5,2.6,0l5.2-3C393.5,244.9,393.7,244.6,393.7,244.2L393.7,244.2z"
                      />
                    </g>
                    <g id="XMLID_8655_">
                      <path
                        id="XMLID_8657_"
                        d="M386.7,248.9c-0.5,0-1.1-0.1-1.5-0.4l-18.3-10.7c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0-0.1,0-2.3
                  c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.7,0.5l4.9-2.8c0.2-0.1,0.3-0.1,0.5,0l14.2,8.2l5.7,1.6
                  c0.2,0.1,0.4,0.3,0.4,0.5v0.5c0,0.7,0,1.8,0,1.8l0,0.1c0,0.5-0.3,0.9-0.7,1.1l-5.2,3C387.8,248.7,387.2,248.9,386.7,248.9z
                   M367,234.9c0,0.7,0,1.7,0,1.8c0,0.1,0.1,0.3,0.3,0.4l18.3,10.7c0.7,0.4,1.5,0.4,2.2,0l5.2-3c0.2-0.1,0.3-0.3,0.3-0.5l0.4,0
                  l-0.4,0c0,0,0-1.1,0-1.8v-0.3l-5.7-1.6l-14.1-8.2l-4.8,2.8c-0.1,0.1-0.3,0.1-0.4,0L367,234.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8645_">
                    <g id="XMLID_8652_">
                      <path
                        id="XMLID_8653_"
                        d="M393.6,241.8l-5.8-1.6c0,0,0,0,0,0l-1.2-0.7v8.9c0.5,0,1-0.1,1.4-0.3l5.2-3
                  c0.3-0.2,0.5-0.5,0.5-0.8l0,0C393.7,244.2,393.7,242.3,393.6,241.8C393.7,241.9,393.7,241.9,393.6,241.8z"
                      />
                    </g>
                    <g id="XMLID_8646_">
                      <path
                        id="XMLID_8647_"
                        d="M386.7,248.9C386.7,248.9,386.7,248.9,386.7,248.9l-0.5,0v-10l1.8,1l5.7,1.6
                  c0.2,0.1,0.4,0.3,0.4,0.5v0.5c0,0.7,0,1.8,0,1.8c0,0.5-0.3,1-0.7,1.2l-5.2,3C387.8,248.7,387.3,248.9,386.7,248.9z
                   M387.1,240.3v7.7c0.3,0,0.5-0.1,0.8-0.3l5.2-3c0.2-0.1,0.3-0.3,0.3-0.5c0,0,0-1.1,0-1.8v-0.3l-5.7-1.6L387.1,240.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8635_">
                    <g id="XMLID_8642_">
                      <path
                        id="XMLID_8643_"
                        style="fill: #ffffff"
                        d="M385.5,245.8l-18.4-10.7c-0.7-0.4-0.7-1.4,0-1.7l5.2-3
                  c0.8-0.5,1.7-0.5,2.5,0l18.4,10.7c0.7,0.4,0.7,1.4,0,1.7l-5.2,3C387.2,246.3,386.2,246.3,385.5,245.8z"
                      />
                    </g>
                    <g id="XMLID_8636_">
                      <path
                        id="XMLID_8638_"
                        d="M386.7,246.6c-0.5,0-1-0.1-1.5-0.4l-18.4-10.7c-0.4-0.3-0.7-0.7-0.7-1.2c0-0.5,0.3-1,0.7-1.2
                  l5.2-3c0.9-0.5,2-0.5,2.9,0l18.4,10.7c0.4,0.3,0.7,0.7,0.7,1.2c0,0.5-0.3,1-0.7,1.2l-5.2,3
                  C387.7,246.4,387.2,246.6,386.7,246.6z M385.7,245.4c0.7,0.4,1.5,0.4,2.1,0l5.2-3c0.2-0.1,0.3-0.3,0.3-0.5
                  c0-0.2-0.1-0.4-0.3-0.5l-18.4-10.7c-0.7-0.4-1.5-0.4-2.1,0l-5.2,3c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.1,0.4,0.3,0.5
                  L385.7,245.4z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_8539_">
                  <g id="XMLID_8578_">
                    <g id="XMLID_8585_">
                      <path
                        id="XMLID_8587_"
                        style="fill: #ffffff"
                        d="M396.7,226.4l-3,0L361.7,208l-22.2,18.3l-1.7,0l0.1,2.4l0,0
                  c0,0.7,0.4,1.3,1,1.7l32.2,18.6c0.9,0.5,2.1,0.4,2.9-0.2l22-18.1c0.4-0.3,0.6-0.8,0.6-1.3L396.7,226.4
                  C396.7,226.4,396.7,226.4,396.7,226.4z"
                      />
                    </g>
                    <g id="XMLID_8580_">
                      <path
                        id="XMLID_8581_"
                        d="M372.6,249.7c-0.5,0-1-0.1-1.5-0.4l-32.2-18.6c-0.7-0.4-1.1-1-1.2-1.8l0,0l0-0.2l-0.2-2.8l2,0
                  l22.3-18.4l32,18.5l3.3,0l0,0.4l0.2,2.9c0,0.7-0.3,1.2-0.8,1.6l-22,18.1C374,249.5,373.3,249.7,372.6,249.7z M338.4,228.4
                  L338.4,228.4l0,0.2c0,0.6,0.3,1.1,0.8,1.3l32.2,18.6c0.8,0.5,1.8,0.4,2.5-0.2l22-18.1c0.3-0.2,0.4-0.6,0.5-1l-0.1-2.5
                  l-2.8,0l-0.1-0.1l-31.6-18.3l-22.1,18.2l-1.4,0L338.4,228.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8569_">
                    <g id="XMLID_8576_">
                      <path
                        id="XMLID_8577_"
                        d="M374.2,248.7l22-18.1c0.4-0.3,0.6-0.8,0.6-1.3l-0.2-2.9c0,0,0,0,0,0l-3,0l-1.6-0.9l-16.3,10.9
                  l-3.2,6.5v6.5C373.2,249.3,373.8,249.1,374.2,248.7z"
                      />
                    </g>
                    <g id="XMLID_8570_">
                      <path
                        id="XMLID_8571_"
                        d="M372.2,249.7v-7l3.2-6.7L392,225l1.7,1l3.3,0l0,0.4l0.2,2.9c0,0.7-0.3,1.2-0.8,1.6l-22,18.1
                  c-0.5,0.4-1.2,0.7-1.9,0.7L372.2,249.7z M373,242.9v5.9c0.3-0.1,0.7-0.2,0.9-0.4l22-18.1c0.3-0.2,0.4-0.6,0.5-1l-0.1-2.5
                  l-2.8,0l-1.5-0.8l-15.9,10.7L373,242.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8559_">
                    <g id="XMLID_8566_">
                      <path
                        id="XMLID_8567_"
                        d="M339.2,230.2l0-2.8l0.8-0.6l-0.1-0.8l-0.3,0.2l-1.7,0l0.1,2.4l0,0c0,0.7,0.4,1.3,1,1.7
                  L339.2,230.2L339.2,230.2z"
                      />
                    </g>
                    <g id="XMLID_8560_">
                      <path
                        id="XMLID_8562_"
                        d="M339.3,230.9l-0.4-0.2c-0.7-0.4-1.1-1-1.2-1.8l0,0l0-0.2l-0.2-2.8l2,0l0.8-0.7l0.1,1.8l-0.8,0.6
                  l0,2.7L339.3,230.9z M338.5,228.3L338.5,228.3l0,0.2c0,0.3,0.1,0.6,0.3,0.8l0-2.2l0.7-0.6l-1.1,0L338.5,228.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8550_">
                    <g id="XMLID_8556_">
                      <path
                        id="XMLID_8558_"
                        style="fill: #ffffff"
                        d="M371.2,246.4l-32.2-18.6c-1.3-0.7-1.4-2.5-0.3-3.4l21.7-17.9
                  c0.8-0.6,1.8-0.7,2.7-0.2l32.8,19c1,0.6,1.2,2,0.2,2.8l-22,18.1C373.3,246.8,372.1,246.9,371.2,246.4z"
                      />
                    </g>
                    <g id="XMLID_8551_">
                      <path
                        id="XMLID_8552_"
                        d="M372.5,247.1c-0.5,0-1-0.1-1.5-0.4l-32.2-18.6c-0.7-0.4-1.2-1.1-1.2-1.9c-0.1-0.8,0.3-1.6,0.9-2.1
                  l21.7-17.9c0.9-0.7,2.1-0.8,3.1-0.3l32.8,19c0.6,0.3,1,1,1.1,1.7c0.1,0.7-0.2,1.4-0.8,1.8l-22,18.1
                  C373.8,246.9,373.1,247.1,372.5,247.1z M361.8,206.3c-0.4,0-0.9,0.1-1.2,0.4l-21.7,17.9c-0.4,0.4-0.6,0.9-0.6,1.4
                  c0,0.5,0.3,1,0.8,1.3l32.2,18.6c0.8,0.5,1.8,0.4,2.5-0.2l22-18.1c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.3-0.8-0.6-1l-32.8-19
                  C362.5,206.4,362.2,206.3,361.8,206.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8540_">
                    <g id="XMLID_8547_">
                      <path
                        id="XMLID_8548_"
                        style="fill: #ffffff"
                        d="M371.6,244.2l-30.7-17.8c-0.3-0.2-0.3-0.5-0.1-0.7l20.7-17.2
                  c0.3-0.2,0.7-0.3,1-0.1l31,17.9c0.2,0.1,0.3,0.5,0.1,0.7l-20.8,17.3C372.4,244.4,372,244.4,371.6,244.2z"
                      />
                    </g>
                    <g id="XMLID_8541_">
                      <path
                        id="XMLID_8543_"
                        d="M372.1,244.8c-0.3,0-0.5-0.1-0.7-0.2l-30.7-17.8c-0.2-0.1-0.4-0.4-0.4-0.7c0-0.3,0.1-0.6,0.3-0.7
                  l20.7-17.2c0.4-0.4,1-0.4,1.5-0.1l31,17.9c0.2,0.1,0.4,0.4,0.4,0.6c0,0.3-0.1,0.5-0.3,0.7l-20.8,17.3
                  C372.8,244.6,372.4,244.8,372.1,244.8z M371.8,243.8c0.2,0.1,0.5,0.1,0.7-0.1l20.8-17.3l-31-17.8c-0.2-0.1-0.4-0.1-0.6,0
                  L341,225.9l0,0.1L371.8,243.8z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_8472_">
                <g id="XMLID_8527_">
                  <g id="XMLID_8534_">
                    <path
                      id="XMLID_8536_"
                      style="fill: #ffffff"
                      d="M401,254c0,0-1-2.4-4.8-2.8l0,0c-0.3-0.1-0.6,0-1,0.2l-15.7,9.1
                c-1,0.6-1.9,2.2-1.9,3.5v1.1v2.2v8.8c0,0.4,0.1,0.7,0.3,0.8l0,0c0,0,1.3,1.8,4.4,2.6c1.5,0.4,5.1-4.4,8.2-9.4l5.5-3.2
                c0.6-0.4,1.1-1.3,1.1-2.2v-5.6l2.9-3.8L401,254z"
                    />
                  </g>
                  <g id="XMLID_8529_">
                    <path
                      id="XMLID_8530_"
                      d="M382.4,279.9c-0.1,0-0.2,0-0.3,0c-3-0.8-4.4-2.5-4.6-2.8c-0.2-0.3-0.4-0.6-0.4-1.1V264
                c0-1.5,0.9-3.2,2.1-3.9l15.7-9.1c0.4-0.3,0.9-0.3,1.3-0.2c4,0.5,5.1,3,5.2,3.1l0.1,0.2l-4.1,5.3v5.4c0,1-0.6,2.1-1.3,2.5
                l-5.4,3.1C385.5,278.6,383.5,279.9,382.4,279.9z M395.9,251.5c-0.2,0-0.3,0.1-0.5,0.2l-15.7,9.1c-0.9,0.5-1.6,2-1.6,3.2V276
                c0,0.2,0.1,0.4,0.2,0.5l0,0.1c0,0,1.3,1.7,4.1,2.5c0.4,0.1,2.1-0.2,7.7-9.2l0.1-0.1l5.6-3.2c0.5-0.3,0.9-1.1,0.9-1.8v-5.7
                l3.9-5.1c-0.3-0.5-1.4-2-4.2-2.4l0,0l-0.2-0.1C396,251.5,395.9,251.5,395.9,251.5z"
                    />
                  </g>
                </g>
                <g id="XMLID_8518_">
                  <g id="XMLID_8525_">
                    <path
                      id="XMLID_8526_"
                      style="fill: #ffffff"
                      d="M382.9,264.3c2.3,0.5,10.6-3.6,10.6-3.6l3.5-1.4v-0.2l2.9-3.8l1.1-1.5
                c0,0-0.8-2.3-4.7-2.8l-0.2,0c-0.3-0.1-0.6,0-1,0.2l-15.7,9.1c-0.6,0.3-1.1,0.9-1.4,1.7C378,262.1,380.6,263.9,382.9,264.3z"
                    />
                  </g>
                  <g id="XMLID_8519_">
                    <path
                      id="XMLID_8521_"
                      d="M383.3,264.8c-0.2,0-0.4,0-0.5,0l0,0c-2.4-0.5-5-2.3-5.1-2.4l-0.2-0.2l0.1-0.3
                c0.4-0.8,0.9-1.5,1.6-1.8l15.7-9.1c0.4-0.3,0.9-0.3,1.3-0.2l0.1,0c4.1,0.5,5,3,5,3.1l0.1,0.2l-4.1,5.3v0.4l-3.7,1.5
                C392.9,261.5,386.1,264.8,383.3,264.8z M383,263.9c1.8,0.4,8.1-2.4,10.4-3.5l3.2-1.3v-0.1l3.9-5.1c-0.3-0.5-1.3-2-4.3-2.4
                l-0.2,0c-0.2-0.1-0.4,0-0.7,0.1l-15.7,9.1c-0.4,0.2-0.8,0.6-1.1,1.2C379.2,262.3,381.2,263.6,383,263.9L383,263.9z"
                    />
                  </g>
                </g>
                <g id="XMLID_8508_">
                  <g id="XMLID_8515_">
                    <path
                      id="XMLID_8517_"
                      d="M399.5,253.8l-15.7,9.1c-1,0.6-1.9,2.2-1.9,3.5v1.1v2.2v8.8c0,0.8,0.5,1.2,1.1,0.9l17.2-9.9
                c0.6-0.4,1.1-1.3,1.1-2.2v-8.8v-2.2v-1.1C401.3,253.8,400.5,253.2,399.5,253.8z"
                    />
                  </g>
                  <g id="XMLID_8510_">
                    <path
                      id="XMLID_8511_"
                      d="M382.6,279.9c-0.2,0-0.3,0-0.5-0.1c-0.4-0.2-0.6-0.7-0.6-1.3v-12.1c0-1.5,0.9-3.2,2.1-3.9l15.7-9.1
                c0.6-0.3,1.2-0.4,1.6-0.1c0.5,0.3,0.8,1,0.8,1.9v12.1c0,1-0.6,2.1-1.3,2.5l-17.2,9.9C383,279.9,382.8,279.9,382.6,279.9z
                 M400.2,254c-0.2,0-0.4,0.1-0.5,0.2l-15.7,9.1c-0.9,0.5-1.6,2-1.6,3.2v12.1c0,0.3,0.1,0.5,0.2,0.6c0.1,0.1,0.3,0,0.3-0.1
                l17.2-9.9c0.5-0.3,0.9-1.1,0.9-1.8v-12.1c0-0.6-0.2-1-0.4-1.1C400.4,254,400.3,254,400.2,254z"
                    />
                  </g>
                </g>
                <g id="XMLID_8504_">
                  <g id="XMLID_8505_">
                    <path
                      id="XMLID_8507_"
                      style="fill: #ffffff"
                      d="M382.7,271.4c-0.2,0-0.3,0-0.4-0.1c-0.3-0.1-0.6-0.4-0.7-0.8l0.8-0.3
                c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.3,0,0.5-0.1l16.6-9.6c0.5-0.3,0.9-0.9,1.1-1.7l0.8,0.2c-0.3,0.9-0.8,1.8-1.5,2.2l-16.6,9.6
                C383.2,271.4,383,271.4,382.7,271.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_8494_">
                  <g id="XMLID_8501_">
                    <path
                      id="XMLID_8503_"
                      d="M392.9,268.1l-2,1.2c-0.2,0.1-0.4,0-0.4-0.3v-4.5c0-0.3,0.2-0.7,0.4-0.8l2-1.2
                c0.2-0.1,0.4,0,0.4,0.3v4.5C393.3,267.6,393.1,267.9,392.9,268.1z"
                    />
                  </g>
                  <g id="XMLID_8496_">
                    <path
                      id="XMLID_8497_"
                      style="fill: #ffffff"
                      d="M390.7,269.7c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.4-0.4-0.4-0.7v-4.5
                c0-0.5,0.3-0.9,0.6-1.2l2-1.2c0.2-0.1,0.5-0.1,0.7,0c0.2,0.1,0.4,0.4,0.4,0.7v4.5c0,0.5-0.3,0.9-0.6,1.2l-2,1.2
                C390.9,269.7,390.8,269.7,390.7,269.7z M392.9,263l-1.8,1c-0.1,0-0.2,0.3-0.2,0.4v4.3l1.8-1c0.1,0,0.2-0.3,0.2-0.4V263z"
                    />
                  </g>
                </g>
                <g id="XMLID_8491_">
                  <g id="XMLID_8492_">
                    <path
                      id="XMLID_8493_"
                      style="fill: #ffffff"
                      d="M391.7,265.9c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.3-0.5V265
                c0-0.3,0.2-0.6,0.4-0.8c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.3,0.3,0.3,0.5v0.3c0,0.3-0.2,0.6-0.4,0.8l0,0
                C391.9,265.9,391.8,265.9,391.7,265.9z"
                    />
                  </g>
                </g>
                <g id="XMLID_8488_">
                  <g id="XMLID_8489_">
                    <rect
                      id="XMLID_8490_"
                      x="382.5"
                      y="259.8"
                      transform="matrix(0.8654 -0.501 0.501 0.8654 -77.6503 231.2687)"
                      style="fill: #ffffff"
                      width="18.5"
                      height="0.8"
                    />
                  </g>
                </g>
                <g id="XMLID_8473_">
                  <g id="XMLID_8480_">
                    <g id="XMLID_8486_">
                      <path
                        id="XMLID_8487_"
                        style="fill: #ffffff"
                        d="M396.2,253.6l-1.7,1l-1.1-1c-0.3-0.3-0.7-0.3-1.1-0.1l-4.1,2.4
                  c-0.5,0.3-0.8,0.7-1,1.2l-1,2.4l-1.5,0.8c-0.2,0.1-0.5,0.1-0.7,0l-1.3-0.9c-0.1-0.1-0.1-0.2,0-0.3l1.6-0.9l1-2.4
                  c0.2-0.5,0.6-0.9,1-1.2l4.2-2.4c0.3-0.2,0.6-0.2,0.9,0l1.5,0.9l1.3-0.7c0.2-0.1,0.5-0.1,0.7,0l1.3,0.9
                  C396.4,253.3,396.4,253.5,396.2,253.6z"
                      />
                    </g>
                    <g id="XMLID_8481_">
                      <path
                        id="XMLID_8482_"
                        d="M384.5,260.7c-0.2,0-0.4-0.1-0.6-0.2l-1.3-0.8c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5
                  l1.4-0.8l0.9-2.2c0.2-0.6,0.7-1.1,1.2-1.4l4.2-2.4c0.4-0.2,0.9-0.2,1.4,0l1.3,0.8l1.1-0.6c0.3-0.2,0.8-0.2,1.1,0l1.3,0.9
                  c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.4-0.3,0.5l0,0l-2,1.2l-1.3-1.2c-0.2-0.1-0.4-0.2-0.6-0.1l-4.1,2.4
                  c-0.4,0.2-0.7,0.6-0.8,1l-1,2.5l-1.6,0.9C384.8,260.6,384.6,260.7,384.5,260.7z M383.3,259.2l1,0.6c0.1,0,0.2,0.1,0.3,0
                  l1.4-0.8l0.9-2.2c0.2-0.6,0.7-1.1,1.2-1.4l4.1-2.3l-0.9-0.6c-0.2-0.1-0.3-0.1-0.5,0l-4.2,2.4c-0.4,0.2-0.7,0.6-0.8,1l-1,2.5
                  L383.3,259.2z M393.6,253.2C393.7,253.2,393.7,253.2,393.6,253.2l0.9,0.9l1.1-0.7l-1-0.6c-0.1,0-0.2-0.1-0.3,0L393.6,253.2z
                  "
                      />
                    </g>
                  </g>
                  <g id="XMLID_8477_">
                    <g id="XMLID_8478_">
                      <rect
                        id="XMLID_8479_"
                        x="384.9"
                        y="257.6"
                        transform="matrix(0.5348 -0.845 0.845 0.5348 -39.3792 445.9366)"
                        width="0.8"
                        height="2.3"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8474_">
                    <g id="XMLID_8475_">
                      <rect
                        id="XMLID_8476_"
                        x="386.2"
                        y="254.4"
                        transform="matrix(0.5348 -0.845 0.845 0.5348 -36.0794 445.5854)"
                        width="0.8"
                        height="2.3"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_8441_">
                <g id="XMLID_8445_">
                  <g id="XMLID_8464_">
                    <g id="XMLID_8465_">
                      <g id="XMLID_8470_">
                        <polygon
                          id="XMLID_8471_"
                          style="fill: #ffffff"
                          points="350.3,226.1 368.8,236.7 368.8,236.7 379.1,228.1 379.1,228.1 
                    360.7,217.4 360.7,217.4 350.3,226.1                   "
                        />
                      </g>
                      <g id="XMLID_8466_">
                        <path
                          id="XMLID_8467_"
                          d="M368.8,237.2l-0.3-0.1l-18.7-10.7l0-0.4l0.2-0.1l10.7-8.8l0.2,0.1l18.6,10.8l0,0.4l-0.2,0.1
                    L368.8,237.2z M351,226l17.7,10.2l9.7-8l-17.6-10.2L351,226z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_8456_">
                    <g id="XMLID_8457_">
                      <g id="XMLID_8462_">
                        <path
                          id="XMLID_8463_"
                          style="fill: #ffffff"
                          d="M360.7,217.4l18.4,10.7c0,0,0,0,0,0l0.9-14.5c0,0,0,0,0,0l-18.4-10.7
                    c0,0,0,0,0,0L360.7,217.4C360.7,217.4,360.7,217.4,360.7,217.4z"
                        />
                      </g>
                      <g id="XMLID_8458_">
                        <path
                          id="XMLID_8459_"
                          d="M379.1,228.6l-0.2-0.1l-18.6-10.8l0-0.3l0.9-14.8l0.4-0.2l0.2,0.1l18.6,10.8l0,0.3l-0.9,14.8
                    L379.1,228.6z M361.2,217.2l17.6,10.2l0.8-13.6L362,203.6L361.2,217.2z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_8452_">
                    <g id="XMLID_8453_">
                      <g id="XMLID_8454_">
                        <polygon
                          id="XMLID_8455_"
                          points="362.6,216.3 377.6,225 378.2,214.6 363.1,205.9                   "
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_8449_">
                    <g id="XMLID_8450_">
                      <rect
                        id="XMLID_8451_"
                        x="368"
                        y="215.5"
                        transform="matrix(0.502 -0.8649 0.8649 0.502 -10.5074 430.2584)"
                        width="0.8"
                        height="17.5"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8446_">
                    <g id="XMLID_8447_">
                      <rect
                        id="XMLID_8448_"
                        x="360.9"
                        y="221.3"
                        transform="matrix(0.502 -0.8648 0.8648 0.502 -19.0964 427.0464)"
                        width="0.8"
                        height="17.5"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_8442_">
                  <g id="XMLID_8443_">
                    <polygon
                      id="XMLID_8444_"
                      style="fill: #ffffff"
                      points="370.5,216.8 369.2,215.2 364.8,216.2 364.6,215.4 369.6,214.3 
                370.9,215.9 376.1,215.1 376.3,216               "
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_7981_">
                <g id="XMLID_8416_">
                  <g id="XMLID_8434_">
                    <g id="XMLID_8439_">
                      <path
                        id="XMLID_8440_"
                        style="fill: #ffffff"
                        d="M348.8,274.4l2.9,0.7l3.2-1.2l-0.4-0.8l-1-0.3c0,0-1.3-0.2-1.5-0.9
                  c-0.2-0.7-2.1,0-2.1,0l-1.3,1.1L348.8,274.4z"
                      />
                    </g>
                    <g id="XMLID_8435_">
                      <path
                        id="XMLID_8436_"
                        d="M351.7,275.5l-3.3-0.8l-0.4-1.9l1.6-1.4c0.4-0.1,1.6-0.5,2.3-0.2c0.2,0.1,0.3,0.3,0.4,0.5
                  c0.1,0.3,0.8,0.5,1.2,0.6l0.1,0l1.2,0.4l0.7,1.3L351.7,275.5z M349.1,274l2.6,0.6l2.6-1l-0.1-0.2l-0.8-0.3
                  c-0.3,0-1.6-0.3-1.8-1.2c-0.1-0.1-0.9,0-1.5,0.3l-1.1,0.9L349.1,274z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8427_">
                    <g id="XMLID_8432_">
                      <path
                        id="XMLID_8433_"
                        style="fill: #ffffff"
                        d="M338.5,257.7c0,0,5.9-1.9,7.5-3.4c0,0-1,2.1,0.2,5.7
                  c1,2.8,1.5,9.3,1.8,11.7c0.1,0.6,0.5,1.1,1.1,1.1l0.5,0.1c0.7,0,2-0.5,2-1.2c0.1-4.8,0.5-21-0.6-22.5
                  c-1.3-1.7-5.6-3.4-7.7-3.1c-2.1,0.4-8.5,1.4-8.5,1.4L338.5,257.7z"
                      />
                    </g>
                    <g id="XMLID_8428_">
                      <path
                        id="XMLID_8429_"
                        d="M349.7,273.2C349.7,273.2,349.7,273.2,349.7,273.2l-0.6-0.1c-0.7,0-1.3-0.7-1.4-1.4
                  c-0.1-0.6-0.2-1.4-0.3-2.3c-0.3-2.9-0.8-7.2-1.5-9.3c-0.8-2.1-0.7-3.8-0.5-4.8c-2.3,1.3-6.5,2.7-6.7,2.8l-0.4,0.1l-4-11.2
                  l0.5-0.1c0.1,0,6.4-1,8.5-1.4c2.2-0.4,6.7,1.4,8.1,3.2c1.1,1.5,0.9,14.9,0.7,22.8c0,0.3-0.2,0.6-0.5,0.9
                  C351.1,272.9,350.3,273.2,349.7,273.2z M347.4,252.5l-0.9,1.9c0,0-0.9,2,0.2,5.4c0.8,2.2,1.2,6.4,1.5,9.5
                  c0.1,0.9,0.2,1.7,0.3,2.3c0.1,0.4,0.3,0.7,0.7,0.7l0.6,0.1c0.3,0,1-0.2,1.3-0.5c0.1-0.1,0.2-0.2,0.2-0.3
                  c0.4-12.6,0.2-21.4-0.5-22.3c-1.2-1.6-5.3-3.2-7.3-2.9c-1.8,0.3-6.5,1.1-8,1.3l3.4,9.5c1.3-0.5,5.7-2,7-3.2L347.4,252.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8420_">
                    <g id="XMLID_8425_">
                      <path
                        id="XMLID_8426_"
                        d="M355.4,273.2c-0.4-0.7-1.3-0.6-2-0.4c0,0,0.1,0.1,0.1,0.1c0.4,0.3,0,0.8-0.7,1.1
                  c-1.3,0.7-2.9,0.5-3.6-0.6l-0.1-0.2l0-0.2c0,0,0,0,0,0c-0.2-0.3-0.6-0.6-0.9-0.9c-0.2-0.2-0.6-0.2-0.8,0.2
                  c-0.2,0.5-0.4,1.5,0.1,3l0.2,2.3c0,0.2,0.1,0.3,0.2,0.4c0,0,0.1,0,0.1,0.1c0.1,0.1,0.5,0,0.6,0c0.3,0,0.5-0.1,0.8-0.2
                  c0.1,0,0.2-0.2,0.2-0.3l-0.1-1.1c0-0.2,0.1-0.3,0.3-0.2l0.3,0.2c0.3,0.2,0.7,0.3,1,0.3l0,0c0.4,0,0.8,0,1.1-0.2
                  C354.1,275.6,356.1,274.4,355.4,273.2z"
                      />
                    </g>
                    <g id="XMLID_8421_">
                      <path
                        id="XMLID_8422_"
                        d="M348.4,278.3c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.3-0.3-0.6l-0.2-2.3
                  c-0.5-1.6-0.4-2.6-0.1-3.2c0.1-0.3,0.3-0.4,0.6-0.5c0.3-0.1,0.5,0,0.7,0.2c0.3,0.3,0.6,0.6,0.9,0.9l0.1,0.1l0,0.3l0.1,0.2
                  c0.6,0.9,2,1.1,3.2,0.5c0.5-0.3,0.6-0.5,0.6-0.6c-0.1-0.1-0.1-0.1-0.1-0.1l-0.6-0.4l0.7-0.2c1.4-0.4,2.1,0.1,2.3,0.5
                  c0.2,0.3,0.2,0.7,0.1,1c-0.2,0.8-1.2,1.6-3.2,2.6c-0.4,0.2-0.8,0.3-1.3,0.3c-0.4,0-0.8-0.2-1.2-0.4l-0.1-0.1l0,0.9
                  c0,0.3-0.2,0.6-0.4,0.6c-0.3,0.1-0.5,0.2-0.8,0.2C348.8,278.2,348.6,278.3,348.4,278.3z M348.3,277.6c0.1,0,0.3,0,0.3,0
                  l0.1,0c0.2,0,0.5-0.1,0.7-0.2l-0.1-1.1c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.3-0.1,0.5,0l0.4,0.2c0.3,0.2,0.6,0.3,0.9,0.3
                  c0.3,0,0.7,0,0.9-0.2c0.7-0.3,2.6-1.3,2.9-2.3c0.1-0.2,0-0.4-0.1-0.5l0,0c-0.2-0.4-0.7-0.4-1.1-0.4c0,0.1,0,0.2,0,0.2
                  c0,0.5-0.6,0.9-1,1.1c-1.5,0.8-3.2,0.5-4.1-0.7l0-0.1l-0.1-0.4c-0.2-0.3-0.6-0.6-0.8-0.8c-0.1-0.1-0.1-0.1-0.1,0
                  c0,0-0.1,0-0.1,0.1c-0.2,0.5-0.3,1.4,0.1,2.8l0,0.1l0.2,2.3C348.2,277.5,348.3,277.6,348.3,277.6L348.3,277.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8417_">
                    <g id="XMLID_8418_">
                      <rect
                        id="XMLID_8419_"
                        x="349.5"
                        y="274.4"
                        transform="matrix(0.9909 -0.1346 0.1346 0.9909 -33.8463 49.5991)"
                        style="fill: #ffffff"
                        width="0.6"
                        height="1.4"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_8380_">
                  <g id="XMLID_8413_">
                    <g id="XMLID_8414_">
                      <path
                        id="XMLID_8415_"
                        d="M336.1,288.2l-1-1.6l10.8-6.5c1.3-0.8,2-2.3,1.8-3.8l-1.6-10.6l1.9-0.3l1.6,10.6
                  c0.3,2.3-0.7,4.5-2.7,5.7L336.1,288.2z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8410_">
                    <g id="XMLID_8411_">
                      <path
                        id="XMLID_8412_"
                        d="M320.7,279l-1-1.6l11.2-6.7c1.2-0.7,1.9-2,2-3.3l0.4-10.3l1.9,0.1l-0.4,10.3
                  c-0.1,2-1.2,3.9-2.9,4.9L320.7,279z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8381_">
                    <g id="XMLID_8403_">
                      <g id="XMLID_8408_">
                        <path
                          id="XMLID_8409_"
                          style="fill: #ffffff"
                          d="M353.1,261.9c0,0,0-1.7,0-2.1c0,0,0-0.1-0.1-0.1l-5.2-1.4c0,0,0,0,0,0
                    l-12.7-7.4c0,0-0.1,0-0.1,0l-12.2,7c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,1.9,0,2l0,0c0,0.3,0.2,0.5,0.4,0.7
                    l16.4,9.5c0.7,0.4,1.6,0.4,2.3,0l12.4-7.1C353,262.5,353.1,262.2,353.1,261.9L353.1,261.9z"
                        />
                      </g>
                      <g id="XMLID_8404_">
                        <path
                          id="XMLID_8405_"
                          d="M339.1,270.5c-0.5,0-1-0.1-1.4-0.4l-16.4-9.5c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-2.1
                    c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l12.1-6.9c0.2-0.1,0.3-0.1,0.5,0l12.7,7.4l5.1,1.4
                    c0.2,0.1,0.4,0.3,0.4,0.5v0.5c0,0.6,0,1.6,0,1.6l0,0.1c0,0.4-0.3,0.8-0.7,1.1l-12.4,7.1
                    C340.1,270.3,339.6,270.5,339.1,270.5z M321.5,257.9c0,0.6,0,1.4,0,1.5c0,0.1,0.1,0.3,0.2,0.4l16.4,9.5
                    c0.6,0.3,1.3,0.3,1.9,0l12.4-7.1c0.2-0.1,0.2-0.3,0.2-0.4l0.4,0l-0.4,0c0,0,0-1,0-1.6v-0.2l-5.1-1.4l-12.6-7.3l-12.2,7
                    l-0.2,0L321.5,257.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_8396_">
                      <g id="XMLID_8401_">
                        <path
                          id="XMLID_8402_"
                          d="M322.4,257.8l-1.3-0.3c0,0-0.1,0-0.1,0.1c0,0.4,0,1.9,0,2l0,0c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5
                    V257.8z"
                        />
                      </g>
                      <g id="XMLID_8397_">
                        <path
                          id="XMLID_8398_"
                          d="M322.8,261.4l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-2c0-0.2,0.1-0.3,0.2-0.4
                    c0.1-0.1,0.3-0.1,0.4-0.1l1.6,0.4V261.4z M321.5,257.9c0,0.6,0,1.4,0,1.5c0,0.1,0.1,0.3,0.2,0.4l0.3,0.2v-1.9L321.5,257.9z
                    "
                        />
                      </g>
                    </g>
                    <g id="XMLID_8389_">
                      <g id="XMLID_8394_">
                        <path
                          id="XMLID_8395_"
                          d="M353.1,259.8l-5.2-1.4c0,0,0,0,0,0l-8.8,6.5v5.3c0.4,0,0.9-0.1,1.2-0.3l12.4-7.1
                    c0.3-0.2,0.4-0.5,0.4-0.7l0,0C353.1,261.9,353.1,260.2,353.1,259.8C353.1,259.8,353.1,259.8,353.1,259.8z"
                        />
                      </g>
                      <g id="XMLID_8390_">
                        <path
                          id="XMLID_8391_"
                          d="M339.1,270.5C339.1,270.5,339.1,270.5,339.1,270.5l-0.5,0v-5.9l9.2-6.8l0.2,0.1l5.1,1.4
                    c0.2,0.1,0.4,0.3,0.4,0.5v0.5c0,0.6,0,1.6,0,1.6c0,0.5-0.3,0.9-0.7,1.1l-12.4,7.1C340.1,270.3,339.6,270.5,339.1,270.5z
                     M339.5,265v4.6c0.2,0,0.4-0.1,0.6-0.2l12.4-7.1c0.2-0.1,0.2-0.3,0.2-0.4c0,0,0-1,0-1.6v-0.2l-4.8-1.3L339.5,265z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_8382_">
                      <g id="XMLID_8387_">
                        <path
                          id="XMLID_8388_"
                          style="fill: #ffffff"
                          d="M338,267.7l-16.4-9.6c-0.6-0.3-0.6-1.2,0-1.6l12.5-7.1
                    c0.7-0.4,1.6-0.4,2.3,0l16.4,9.6c0.6,0.3,0.6,1.2,0,1.6l-12.5,7.1C339.5,268.1,338.7,268.1,338,267.7z"
                        />
                      </g>
                      <g id="XMLID_8383_">
                        <path
                          id="XMLID_8384_"
                          d="M339.1,268.4c-0.5,0-0.9-0.1-1.3-0.4l-16.4-9.6c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                    l12.5-7.1c0.8-0.5,1.9-0.5,2.7,0l16.4,9.6c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-12.5,7.1
                    C340,268.3,339.6,268.4,339.1,268.4z M335.1,249.6c-0.3,0-0.6,0.1-0.9,0.2l-12.5,7.1c-0.2,0.1-0.2,0.3-0.2,0.4
                    c0,0.1,0,0.3,0.2,0.4l16.4,9.6c0.6,0.3,1.3,0.3,1.8,0l12.5-7.1c0.2-0.1,0.2-0.3,0.2-0.4s0-0.3-0.2-0.4l-16.4-9.6
                    C335.8,249.7,335.4,249.6,335.1,249.6z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_8358_">
                  <g id="XMLID_8373_">
                    <g id="XMLID_8378_">
                      <path
                        id="XMLID_8379_"
                        style="fill: #ffffff"
                        d="M352.7,229.9l1.7-1.1c0.6-0.4,1.4-0.6,2.1-0.6l0,0c1,0.1,1.6,0.3,2.1,1.2
                  l0.1,0.4l-1.7,0.6l-0.1,0.3l0.6,0.3c0,0-0.5,1.8-3.6,1.4C351.2,232,352.7,229.9,352.7,229.9z"
                      />
                    </g>
                    <g id="XMLID_8374_">
                      <path
                        id="XMLID_8375_"
                        d="M354.9,232.9c-0.3,0-0.6,0-1-0.1c-1-0.1-1.7-0.5-2-1.1c-0.4-0.9,0.3-1.9,0.4-2l0.1-0.1l1.7-1.1
                  c0.7-0.5,1.6-0.7,2.4-0.6c1.1,0.1,1.9,0.3,2.5,1.4l0,0.2l0.1,0.8l-1.4,0.5l0.2,0.1l-0.1,0.3
                  C358,231.2,357.5,232.9,354.9,232.9z M353,230.3c-0.2,0.2-0.4,0.7-0.3,1.1c0.2,0.4,0.8,0.6,1.3,0.6c1.9,0.3,2.7-0.4,3-0.8
                  l-0.5-0.2l0.2-0.8l1.6-0.6l0,0c-0.4-0.7-0.9-0.9-1.8-0.9c-0.7,0-1.3,0.1-1.9,0.5L353,230.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8366_">
                    <g id="XMLID_8371_">
                      <path
                        id="XMLID_8372_"
                        d="M351.1,230.6l1-0.6c0.2-0.1,0.3-0.1,0.5,0c0.3,0.3,0.9,0.9,1.2,1.8c0.1,0.2,0,0.5-0.2,0.6
                  l-1.1,0.5c-0.2,0.1-0.4,0-0.5-0.2l-1-1.5C350.7,231,350.8,230.7,351.1,230.6z"
                      />
                    </g>
                    <g id="XMLID_8367_">
                      <path
                        id="XMLID_8368_"
                        d="M352.2,233.4c-0.3,0-0.6-0.1-0.7-0.4l-1-1.5c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.6h0
                  l1-0.6c0.3-0.2,0.7-0.2,1,0.1c0.4,0.3,1,1,1.4,2c0.2,0.4,0,0.9-0.5,1.1l-1.1,0.5C352.5,233.4,352.3,233.4,352.2,233.4z
                   M351.3,230.9C351.3,230.9,351.2,230.9,351.3,230.9c0,0.1,0,0.1,0,0.1l1,1.5l1.1-0.5c0,0,0-0.1,0-0.1
                  c-0.3-0.9-0.8-1.4-1.1-1.6L351.3,230.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8359_">
                    <g id="XMLID_8364_">
                      <path
                        id="XMLID_8365_"
                        style="fill: #ffffff"
                        d="M339.5,229.4C339.7,230.2,339.1,228.5,339.5,229.4c1,2.7,2.2,5.6,2.2,5.6
                  s2.9-1.4,5-2.2c1.9-0.7,3.5-1.8,3.8-2.1c0,0,0.1,0,0.1,0c0.3,0.1,1.5,0.6,1.6,2.2c0,0.1,0,0.1-0.1,0.2
                  c-0.7,0.7-7.4,6.7-12.3,7.7c0,0-2.8-2.6-4.2-7.4C334.3,228.6,338.9,227.6,339.5,229.4z"
                      />
                    </g>
                    <g id="XMLID_8360_">
                      <path
                        id="XMLID_8361_"
                        d="M339.8,241.3l-0.2-0.1c-0.1-0.1-2.9-2.7-4.4-7.6c-0.9-3,0.4-4.8,2-5.3c0.9-0.3,1.8-0.1,2.3,0.5
                  c0.1,0.1,0.2,0.2,0.2,0.4c0,0,0,0.1,0.1,0.1c0.8,2.1,1.7,4.3,2,5.2c0.9-0.4,3-1.4,4.7-2c1.8-0.7,3.4-1.8,3.7-2
                  c0.2-0.1,0.3-0.1,0.5-0.1c0.9,0.3,1.8,1.1,1.9,2.5c0,0.2-0.1,0.4-0.2,0.5c-0.7,0.7-7.4,6.7-12.5,7.8L339.8,241.3z
                   M338.1,229c-0.2,0-0.4,0-0.6,0.1c-1.1,0.3-2.2,1.7-1.4,4.2c1.2,4,3.4,6.5,4,7.1c4.6-1.2,10.8-6.6,11.8-7.5
                  c-0.1-1.1-0.8-1.5-1.2-1.7c-0.5,0.4-2,1.4-3.8,2c-2.1,0.7-4.9,2.1-5,2.2l-0.4,0.2l-0.2-0.4c0,0-1.1-2.8-2.1-5.3
                  c-0.1-0.1-0.1-0.2-0.2-0.4c0,0,0,0,0,0C338.8,229.1,338.5,229,338.1,229z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_8330_">
                  <g id="XMLID_8351_">
                    <g id="XMLID_8356_">
                      <path
                        id="XMLID_8357_"
                        style="fill: #ffffff"
                        d="M361.4,271.8c0,0,0.8,0.9,2,0.9c1.1,0,1.8,1,1.8,1l-1.6,1.9l-3.1-0.1
                  l-1.7-1.2l-0.5-2.1l1.9-0.9L361.4,271.8z"
                      />
                    </g>
                    <g id="XMLID_8352_">
                      <path
                        id="XMLID_8353_"
                        d="M363.8,276l-3.5-0.1l-1.9-1.4l-0.6-2.6l2.4-1.1l1.4,0.6l0.1,0.1c0,0,0.7,0.7,1.6,0.7
                  c0,0,0,0,0.1,0c1.3,0,2.1,1.1,2.1,1.2l0.2,0.3L363.8,276z M360.6,275l2.8,0.1l1.2-1.5c-0.2-0.2-0.7-0.6-1.2-0.6
                  c-1.1,0-2-0.7-2.2-0.9l-0.9-0.4l-1.5,0.7l0.4,1.6L360.6,275z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8344_">
                    <g id="XMLID_8349_">
                      <path
                        id="XMLID_8350_"
                        style="fill: #ffffff"
                        d="M328.3,256.4c0,0,7.6,12.8,26.5-1.5c0.1-0.1,0.2,0,0.2,0.1
                  c-0.2,0.8-0.8,3.3,0.4,5.9c1.2,2.7,2.3,8.5,2.8,10.8c0.1,0.6,0.6,1,1.2,1l0.5,0c0.7,0,1.5-0.6,1.5-1.3
                  c-0.2-4.8-0.9-21-2-22.4c-1.8-2.1-6-1.9-8.1-1.4c-2.1,0.6-14.2,3.9-14.2,3.9L328.3,256.4z"
                      />
                    </g>
                    <g id="XMLID_8345_">
                      <path
                        id="XMLID_8346_"
                        d="M359.8,273.1l-0.6,0c0,0,0,0,0,0c-0.7,0-1.4-0.6-1.5-1.3l-0.1-0.5c-0.5-2.5-1.6-7.8-2.7-10.2
                  c-0.9-2.1-0.8-4.1-0.6-5.3c-6.9,5.1-13.1,7.1-18.4,6.1c-5.5-1.1-7.9-5-8-5.2l-0.2-0.4l9-5.1c0.1,0,12.1-3.4,14.2-3.9
                  c2.3-0.6,6.7-0.8,8.6,1.5c0.9,1,1.5,7.9,2.1,22.3l0,0.3c0,0.4-0.1,0.8-0.4,1.1C360.9,272.8,360.3,273.1,359.8,273.1z
                   M359.3,272.3l0.6,0c0.3,0,0.6-0.2,0.9-0.4c0.1-0.1,0.2-0.3,0.2-0.5l0-0.3c-0.7-17-1.5-21.3-1.9-21.8
                  c-1.6-1.9-5.5-1.9-7.7-1.2c-2,0.5-13.3,3.7-14.2,3.9l-8.1,4.6c0.7,1,3,3.7,7.2,4.5c5.2,1,11.4-1.1,18.4-6.4
                  c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.3,0.3,0.2,0.6c-0.2,0.8-0.8,3.2,0.3,5.6c1.1,2.5,2.2,7.8,2.7,10.4l0.1,0.5
                  C358.6,272,358.9,272.3,359.3,272.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8337_">
                    <g id="XMLID_8342_">
                      <path
                        id="XMLID_8343_"
                        d="M366.1,273.8c-0.3-1.1-1.4-1.3-2.7-1.2c0.4,0.1,0.6,0.3,0.9,0.6c0.4,0.4,0,0.8-0.8,1.1
                  c-1.4,0.6-3,0.2-3.7-1c-0.1-0.1-0.2-0.2-0.2-0.2l-0.2-0.3c0,0,0-0.1-0.1-0.1c-0.2-0.3-0.5-0.7-0.8-1c-0.3-0.3-0.8-0.3-1,0.2
                  c-0.3,0.6-0.5,1.5-0.1,3.1l0,2.5c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.1,0.3,0.1v0c0.2,0.1,0.6,0,1.1-0.1
                  c0.1,0,0.3-0.2,0.3-0.3l0.1-1.3c0-0.1,0.1-0.2,0.2-0.1l0.2,0.2c0.2,0.2,0.5,0.4,0.7,0.5l0,0c0.6,0.2,1.2,0.3,1.8,0
                  C364.1,276.4,366.5,275.4,366.1,273.8z"
                      />
                    </g>
                    <g id="XMLID_8338_">
                      <path
                        id="XMLID_8339_"
                        d="M358.2,278.4c-0.2,0-0.3,0-0.4-0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.3-0.4-0.3-0.7l0-2.4
                  c-0.4-1.7-0.1-2.7,0.1-3.3c0.1-0.3,0.4-0.5,0.7-0.5c0.3,0,0.6,0.1,0.8,0.3c0.2,0.3,0.6,0.7,0.8,1l-0.2,0.3l0.3-0.2l0.2,0.3
                  c0.1,0.1,0.1,0.1,0.2,0.2l0,0.1c0.6,1,2,1.4,3.3,0.9c0.5-0.2,0.7-0.4,0.7-0.5c0,0,0,0,0-0.1c-0.3-0.3-0.5-0.4-0.8-0.5
                  l-1.5-0.5l1.6-0.2c0.7-0.1,2.7-0.3,3.1,1.4l0,0c0.5,2-2.7,3.1-4,3.6c-0.7,0.2-1.4,0.2-2-0.1c-0.3-0.1-0.5-0.3-0.7-0.5
                  l-0.1,0.9c0,0.3-0.2,0.6-0.5,0.6C358.6,278.4,358.4,278.4,358.2,278.4z M358,271.9C358,271.9,358,271.9,358,271.9
                  c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.5-0.4,1.4-0.1,2.9l0,0.1l0,2.5c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0,0.1,0.1l0.1,0
                  c0,0,0.2,0,0.9-0.1l0.1-1.3c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.3,0,0.5,0.1l0.2,0.2c0.2,0.2,0.4,0.3,0.6,0.4
                  c0.5,0.2,1,0.2,1.5,0c1.7-0.6,4-1.6,3.6-2.8c-0.1-0.4-0.4-0.9-1.4-1c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.3,0.5,0.3,0.7
                  c-0.1,0.5-0.8,0.8-1.1,1c-1.6,0.6-3.3,0.2-4.1-1.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.3-0.4c-0.2-0.3-0.5-0.7-0.8-1
                  C358.2,271.9,358.1,271.9,358,271.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8334_">
                    <g id="XMLID_8335_">
                      <rect
                        id="XMLID_8336_"
                        x="359"
                        y="274.2"
                        style="fill: #ffffff"
                        width="0.6"
                        height="1.6"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8331_">
                    <g id="XMLID_8332_">
                      <path
                        id="XMLID_8333_"
                        d="M355.4,255l-0.8-0.1c0-0.1,0.1-1.4,1.2-2.3l0.5,0.6C355.5,253.9,355.4,255,355.4,255z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_8319_">
                  <g id="XMLID_8323_">
                    <g id="XMLID_8328_">
                      <path
                        id="XMLID_8329_"
                        d="M327.3,235.5c0,0-1.6,10.4-2.5,16.1c-0.9,5.7,6.4,8.7,11.1,9.2c4.7,0.6,9.6-2,11.1-4.4
                  c1.5-2.4,3.7-3.6,3.7-3.6s-6-4.2-7.7-14.9c-1.7-10.7-7.7-12.4-8-12.4C334.8,225.5,327.3,235.5,327.3,235.5z"
                      />
                    </g>
                    <g id="XMLID_8324_">
                      <path
                        id="XMLID_8325_"
                        d="M337.3,261.3c-0.5,0-0.9,0-1.4-0.1c-3.6-0.4-8.1-2.1-10.3-5c-1.1-1.4-1.5-3-1.2-4.7
                  c0.9-5.7,2.5-16.1,2.5-16.1l0-0.1l0.1-0.1c7.6-10.1,7.9-10.1,8.2-10.1l0,0c0.1,0,6.6,1.3,8.4,12.7
                  c1.7,10.4,7.5,14.5,7.5,14.6l0.6,0.4l-0.6,0.3c0,0-2.2,1.2-3.6,3.4C346,259,341.7,261.3,337.3,261.3z M327.7,235.6
                  c-0.2,1.1-1.6,10.6-2.4,16c-0.2,1.5,0.1,2.9,1.1,4.1c2,2.6,6.3,4.2,9.7,4.6c4.7,0.6,9.4-2.1,10.7-4.2
                  c1.1-1.8,2.6-2.9,3.4-3.4c-1.5-1.3-5.9-5.7-7.4-14.7c-1.6-9.8-6.6-11.7-7.5-12C334.4,226.8,330.3,232.2,327.7,235.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8320_">
                    <g id="XMLID_8321_">
                      <path
                        id="XMLID_8322_"
                        style="fill: #ffffff"
                        d="M348.7,253.6c-5-3.3-7.3-10.6-7.4-10.9l0.6-0.2c0,0.1,2.3,7.4,7.1,10.6
                  L348.7,253.6z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_8290_">
                  <g id="XMLID_8291_">
                    <g id="XMLID_8296_">
                      <g id="XMLID_8312_">
                        <g id="XMLID_8317_">
                          <path
                            id="XMLID_8318_"
                            style="fill: #ffffff"
                            d="M359.3,232.9c0,0,0.6-1.7,1.4-2.3c0.6-0.5,1.1,0,1.3,0.3
                      c0.1,0.1,0.2,0.1,0.3,0.1l1.8-1c0.2-0.1,0.5-0.1,0.6,0.1c0.3,0.4,0.6,1.1,0.1,2.3c-0.7,1.8-3.7,2.5-3.7,2.5L359.3,232.9z"
                          />
                        </g>
                        <g id="XMLID_8313_">
                          <path
                            id="XMLID_8314_"
                            d="M360.8,235.3l-2-2.4l0.1-0.2c0.1-0.2,0.7-1.8,1.5-2.5c0.4-0.3,0.7-0.3,0.9-0.3
                      c0.4,0.1,0.7,0.3,0.9,0.5l1.6-0.9c0.4-0.2,0.9-0.1,1.1,0.3c0.3,0.4,0.7,1.3,0.2,2.7c-0.8,2-3.9,2.7-4,2.7L360.8,235.3z
                       M359.8,232.8l1.4,1.6c0.7-0.2,2.7-0.9,3.2-2.2c0.4-1,0.2-1.6-0.1-1.9l-1.8,1c-0.3,0.2-0.6,0.1-0.8-0.2
                      c-0.1-0.2-0.3-0.3-0.4-0.3c0,0-0.1,0-0.3,0.1C360.5,231.3,360,232.2,359.8,232.8z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_8305_">
                        <g id="XMLID_8310_">
                          <path
                            id="XMLID_8311_"
                            d="M358.1,233.6l0.9-1c0.1-0.1,0.3-0.2,0.5-0.1c0.4,0.2,1.2,0.6,1.8,1.5c0.2,0.2,0.1,0.6-0.1,0.7
                      l-1.1,0.9c-0.2,0.2-0.4,0.2-0.6,0l-1.4-1.3C357.9,234.2,357.9,233.9,358.1,233.6z"
                          />
                        </g>
                        <g id="XMLID_8306_">
                          <path
                            id="XMLID_8307_"
                            d="M359.9,236.3c-0.2,0-0.4-0.1-0.6-0.2l-1.4-1.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
                      l0.9-1c0.3-0.3,0.7-0.4,1-0.2c0.5,0.2,1.3,0.7,2,1.7c0.3,0.4,0.2,1-0.2,1.3l-1.1,0.9C360.3,236.2,360.1,236.3,359.9,236.3
                      z M359.4,232.9l-1,1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l1.4,1.3c0,0,0,0,0.1,0l1.1-0.9c0,0,0.1-0.1,0-0.2
                      C360.5,233.5,359.8,233.1,359.4,232.9z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_8297_">
                        <g id="XMLID_8303_">
                          <path
                            id="XMLID_8304_"
                            style="fill: #ffffff"
                            d="M340,230.8c0.3-0.5,0.9-0.7,1.4-0.6c0.3,0.1,0.5,0.2,0.7,0.4
                      c0.6,0.6,1.7,1.7,2.7,3.3c1.6,2.5,5.2,7.2,5.2,7.2s2.5-2.3,4.4-3.7c1.7-1.2,3-2.8,3.2-3.2c0,0,0.1-0.1,0.1-0.1
                      c0.3,0,1.7,0.1,2.2,1.7c0,0.1,0,0.1,0,0.2c-0.5,0.9-5.2,8.2-9.7,11.1c-0.3,0.2-0.7,0.1-0.9-0.1c-1.4-1.2-5.7-5.2-7.9-8.8
                      C339,233.9,339.4,231.9,340,230.8C340,230.9,340,230.8,340,230.8z"
                          />
                        </g>
                        <g id="XMLID_8299_">
                          <path
                            id="XMLID_8300_"
                            d="M350,247.6c-0.3,0-0.6-0.1-0.8-0.3c-1.3-1.1-5.7-5.2-7.9-8.9c-2.1-3.5-2.6-6-1.5-7.8l0,0
                      c0.4-0.6,1.1-1,1.9-0.8c0.4,0.1,0.7,0.2,0.9,0.5c0.7,0.7,1.8,1.8,2.8,3.4c1.3,2.1,4,5.7,4.9,6.8c0.8-0.7,2.6-2.3,4.1-3.4
                      c1.7-1.2,2.9-2.8,3.1-3.1c0.1-0.2,0.3-0.2,0.5-0.2c0.9,0,2.1,0.5,2.5,2c0.1,0.2,0,0.4-0.1,0.5c-0.5,0.8-5.3,8.3-9.8,11.2
                      C350.4,247.6,350.2,247.6,350,247.6z M340,230.8l0.3,0.2c-0.9,1.5-0.3,3.8,1.5,6.9c2.1,3.6,6.5,7.6,7.8,8.7
                      c0.1,0.1,0.3,0.1,0.5,0.1c4.3-2.7,8.8-9.7,9.5-10.9c-0.4-1-1.2-1.2-1.6-1.3c-0.4,0.5-1.6,2-3.2,3.2
                      c-1.9,1.4-4.4,3.6-4.4,3.6l-0.3,0.3l-0.3-0.4c0,0-3.6-4.7-5.2-7.2c-1-1.6-2.1-2.7-2.7-3.2c-0.2-0.1-0.3-0.2-0.5-0.3
                      c-0.4-0.1-0.8,0.1-1,0.4L340,230.8l0.4,0.2L340,230.8z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_8292_">
                      <g id="XMLID_8293_">
                        <path
                          id="XMLID_8295_"
                          d="M350.7,243.2l-0.8-0.2c0.1-0.6-0.1-1.5-0.1-1.8l0.8-0.2C350.5,241,350.9,242.2,350.7,243.2z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_8047_">
                  <g id="XMLID_8282_">
                    <g id="XMLID_8288_">
                      <path
                        id="XMLID_8289_"
                        style="fill: #ffffff"
                        d="M339.2,224.1c0,0-0.8,2.6-0.1,3.8c0.7,1.2-2.5,0.9-2.5,0.9l-3.2-2.3
                  c0,0,0.9-1.5,0.9-2.9c-0.1-1.3-0.2-1.5,0.1-1.4C334.7,222.4,339.2,224.1,339.2,224.1z"
                      />
                    </g>
                    <g id="XMLID_8283_">
                      <path
                        id="XMLID_8285_"
                        d="M337.5,229.2c-0.4,0-0.7,0-0.9-0.1l-0.1,0l-3.6-2.6l0.2-0.3c0,0,0.9-1.5,0.8-2.6
                  c0-0.4,0-0.7-0.1-0.9c0-0.4-0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.1,0.5-0.1c0.3,0.1,4.3,1.7,4.8,1.8l0.4,0.1l-0.1,0.4
                  c-0.2,0.7-0.6,2.6-0.1,3.5c0.2,0.5,0.1,0.8,0,0.9C339.2,229.1,338.3,229.2,337.5,229.2z M336.8,228.4c0.9,0.1,1.9,0,2-0.2
                  c0,0,0,0,0-0.1c-0.6-1.1-0.2-2.9,0-3.7c-1.4-0.5-3.2-1.2-4-1.5c0,0.2,0,0.5,0.1,0.8c0,1.1-0.5,2.2-0.8,2.8L336.8,228.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8272_">
                    <g id="XMLID_8279_">
                      <path
                        id="XMLID_8281_"
                        style="fill: #ffffff"
                        d="M339,211.6c0,0,3.9-0.2,5.2,3.2c1.3,3.4-1.3,5.9-1.3,5.9s-3.3-1-3.9-2.6
                  C338.4,216.5,339,211.6,339,211.6z"
                      />
                    </g>
                    <g id="XMLID_8274_">
                      <path
                        id="XMLID_8275_"
                        d="M342.9,221.1l-0.2-0.1c-0.4-0.1-3.5-1.1-4.2-2.9c-0.6-1.6,0-6.5,0-6.7l0-0.4l0.4,0
                  c0,0,4.2-0.1,5.6,3.5c1.4,3.6-1.3,6.2-1.4,6.4L342.9,221.1z M339.3,212c-0.2,1.6-0.4,4.8,0,6c0.4,1,2.4,1.9,3.4,2.3
                  c0.5-0.6,2-2.7,1.1-5.3C342.8,212.4,340.3,212,339.3,212z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8265_">
                    <g id="XMLID_8270_">
                      <path
                        id="XMLID_8271_"
                        style="fill: #ffffff"
                        d="M336.7,212c0,0-2.7-2.3-3.8,3.4c-0.7,4,0.8,6.3,3.8,8.6
                  c0.9,0.7,2,0.8,2.9,0.5c1.2-0.4,2.1-1.5,2.5-2.9c0.7-2.4,0.8-4.6,0.4-6.8c0.6-2.4-1-2-3.8-2.8L336.7,212z"
                      />
                    </g>
                    <g id="XMLID_8266_">
                      <path
                        id="XMLID_8267_"
                        d="M338.6,225.1c-0.8,0-1.5-0.3-2.2-0.8c-3.5-2.7-4.6-5.3-3.9-9c0.4-2.3,1.2-3.7,2.3-4.1
                  c0.9-0.3,1.7,0.1,2,0.4l1.9,0.1c0.6,0.2,1.1,0.3,1.6,0.4c1.1,0.2,2,0.4,2.4,1.1c0.3,0.4,0.3,1,0.1,1.8
                  c0.4,2.2,0.3,4.5-0.4,6.9c-0.5,1.6-1.5,2.8-2.8,3.1C339.3,225.1,339,225.1,338.6,225.1z M335.5,212c-0.1,0-0.2,0-0.4,0.1
                  c-0.5,0.2-1.3,1-1.8,3.5c-0.7,4,0.9,6.1,3.6,8.2c0.7,0.6,1.6,0.7,2.5,0.5c1-0.3,1.8-1.3,2.2-2.6c0.7-2.3,0.8-4.5,0.4-6.6
                  l0-0.1l0-0.1c0.1-0.6,0.1-1,0-1.2c-0.2-0.3-0.9-0.5-1.8-0.7c-0.5-0.1-1-0.2-1.6-0.4l-2-0.1l-0.1-0.1
                  C336.4,212.3,336,212,335.5,212z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8257_">
                    <g id="XMLID_8263_">
                      <path
                        id="XMLID_8264_"
                        style="fill: #ffffff"
                        d="M338.5,230.7c1.2,0.2,3.3-1.4,2.3-2.3c0,0-0.1-0.1-0.1-0.1
                  c-0.8-0.5-3.3,0.2-4.9-0.8c-1.2-0.8-1.2-1.6-1.1-1.9c0.1-0.3,0-0.5-0.2-0.7c-0.1-0.1-0.4-0.2-0.7-0.2
                  c-0.9,0.1-1.5,1.2-1.2,2.3c0.4,1.1,1.7,2.5,3.2,3C337,230.4,337.3,230.5,338.5,230.7z"
                      />
                    </g>
                    <g id="XMLID_8258_">
                      <path
                        id="XMLID_8259_"
                        d="M338.7,231.1c-0.1,0-0.2,0-0.2,0c-1.3-0.2-1.6-0.3-2.8-0.7c-1.7-0.6-3.1-2.1-3.5-3.3
                  c-0.2-0.7-0.1-1.4,0.2-2c0.3-0.5,0.8-0.8,1.3-0.8c0.5,0,0.8,0.1,1,0.3c0.3,0.3,0.4,0.7,0.4,1.1c-0.1,0.2-0.1,0.8,0.9,1.5
                  c0.8,0.5,1.9,0.5,2.9,0.5c0.8,0,1.5,0,2,0.3c0.1,0,0.1,0.1,0.2,0.1c0.4,0.4,0.5,0.9,0.3,1.4
                  C340.9,230.4,339.7,231.1,338.7,231.1z M333.9,225.1C333.8,225.1,333.8,225.1,333.9,225.1c-0.3,0-0.6,0.2-0.7,0.4
                  c-0.2,0.4-0.3,0.8-0.2,1.3c0.3,1,1.5,2.3,3,2.8c1.1,0.4,1.4,0.5,2.6,0.7l0,0c0.7,0.1,1.7-0.5,2-1.1c0.1-0.2,0-0.4-0.1-0.4
                  c0,0-0.1,0-0.1-0.1c-0.3-0.2-0.9-0.2-1.5-0.2c-1.1,0-2.4,0-3.4-0.6c-1.4-0.9-1.4-2-1.3-2.4c0-0.1,0-0.2-0.1-0.3
                  C334.1,225.1,334,225.1,333.9,225.1z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8064_">
                    <g id="XMLID_8075_">
                      <g id="XMLID_8076_">
                        <defs>
                          <path
                            id="XMLID_8079_"
                            d="M333.8,223.9C333.8,223.9,333.8,223.9,333.8,223.9C333.8,223.9,333.8,223.9,333.8,223.9z"
                          />
                        </defs>
                        <clipPath id="XMLID_2_">
                          <use
                            xlink:href="#XMLID_8079_"
                            style="overflow: visible"
                          />
                        </clipPath>
                        <g id="XMLID_8254_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8255_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1802 15.9779)"
                            style="fill: #393243"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8251_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8252_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1801 15.9779)"
                            style="fill: #393243"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8248_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8250_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1214 19.2237)"
                            style="fill: #393344"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8245_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8247_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1801 15.9779)"
                            style="fill: #3a3445"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8243_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8244_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1801 15.9779)"
                            style="fill: #3c3546"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8240_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8242_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1801 15.9779)"
                            style="fill: #3d3647"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8238_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8239_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1801 15.9779)"
                            style="fill: #3e3748"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8234_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8236_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1801 15.9779)"
                            style="fill: #3f3849"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8231_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8233_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1213 19.2237)"
                            style="fill: #40394a"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8228_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8229_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1801 15.9779)"
                            style="fill: #423a4b"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8225_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8227_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1801 15.9779)"
                            style="fill: #423b4c"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8223_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8224_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1801 15.9779)"
                            style="fill: #433c4d"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8220_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8222_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.18 15.9779)"
                            style="fill: #443d4e"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8217_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8219_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1213 19.2236)"
                            style="fill: #453e4f"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8215_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8216_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1213 19.2236)"
                            style="fill: #463f50"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8212_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8213_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.18 15.9779)"
                            style="fill: #474051"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8209_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8210_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.18 15.9779)"
                            style="fill: #484152"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8207_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8208_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.18 15.9779)"
                            style="fill: #494253"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8205_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8206_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.18 15.9779)"
                            style="fill: #4b4355"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8203_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8204_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1212 19.2236)"
                            style="fill: #4c4455"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8201_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8202_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.18 15.9779)"
                            style="fill: #4d4457"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8199_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8200_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.18 15.9779)"
                            style="fill: #4e4558"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8196_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8198_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.18 15.9779)"
                            style="fill: #4f4759"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8193_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8195_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.18 15.9779)"
                            style="fill: #50485a"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8191_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8192_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1212 19.2236)"
                            style="fill: #50495b"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8188_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8189_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1212 19.2236)"
                            style="fill: #524a5c"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8185_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8186_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1799 15.9779)"
                            style="fill: #524b5d"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8182_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8184_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1799 15.9779)"
                            style="fill: #544c5e"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8179_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8181_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1799 15.9779)"
                            style="fill: #554c5e"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8177_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8178_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1799 15.9779)"
                            style="fill: #554d5f"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8175_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8176_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1211 19.2236)"
                            style="fill: #564e60"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8172_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8173_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1799 15.9779)"
                            style="fill: #574f62"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8169_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8171_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1799 15.9779)"
                            style="fill: #585062"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8167_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8168_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1799 15.9779)"
                            style="fill: #595163"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8164_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8165_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1799 15.9779)"
                            style="fill: #5b5264"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8161_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8162_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1211 19.2236)"
                            style="fill: #5c5365"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8158_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8159_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1211 19.2236)"
                            style="fill: #5d5467"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8155_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8157_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1799 15.9779)"
                            style="fill: #5f5668"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8151_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8153_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #605769"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8147_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8149_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #605769"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8145_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8146_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #61586b"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8143_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8144_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.121 19.2236)"
                            style="fill: #625a6c"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8141_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8142_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.121 19.2236)"
                            style="fill: #645b6d"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8138_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8139_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #655b6d"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8136_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8137_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #655c6e"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8132_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8134_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #665d6f"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8130_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8131_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #675e70"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8128_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8129_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.121 19.2236)"
                            style="fill: #685f71"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8125_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8126_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #6a6072"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8123_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8124_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #6b6173"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8121_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8122_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1798 15.9779)"
                            style="fill: #6c6274"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8119_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8120_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1797 15.9779)"
                            style="fill: #6d6375"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8116_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8118_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1209 19.2236)"
                            style="fill: #6e6476"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8113_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8114_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1209 19.2236)"
                            style="fill: #6e6577"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8111_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8112_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1797 15.9779)"
                            style="fill: #6f6678"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8109_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8110_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1797 15.9779)"
                            style="fill: #716779"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8105_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8107_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1797 15.9779)"
                            style="fill: #72677b"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8103_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8104_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9989 -4.711742e-02 4.711742e-02 0.9989 -10.1797 15.9779)"
                            style="fill: #73697c"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8100_" style="clip-path: url(#XMLID_2_)">
                          <polygon
                            id="XMLID_8101_"
                            style="fill: #746a7d"
                            points="333.8,223.9 333.8,223.9 333.8,223.9 333.8,223.9 333.8,223.9 
                                          "
                          />
                        </g>
                        <g id="XMLID_8097_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8099_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9983 -5.804152e-02 5.804152e-02 0.9983 -12.4338 19.7534)"
                            style="fill: #746b7e"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8094_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8096_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.704979e-02 5.704979e-02 0.9984 -12.2308 19.4094)"
                            style="fill: #756c7f"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8092_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8093_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9984 -5.651331e-02 5.651331e-02 0.9984 -12.1208 19.2234)"
                            style="fill: #766c80"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8089_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8090_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9985 -5.397526e-02 5.397526e-02 0.9985 -11.5994 18.3446)"
                            style="fill: #776d80"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8086_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8087_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9955 -9.480909e-02 9.480909e-02 0.9955 -19.7257 32.6579)"
                            style="fill: #786e80"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8083_" style="clip-path: url(#XMLID_2_)">
                          <rect
                            id="XMLID_8085_"
                            x="333.8"
                            y="223.9"
                            transform="matrix(0.9701 -0.2425 0.2425 0.9701 -44.341 87.6488)"
                            style="fill: #796f81"
                            width="0"
                            height="0"
                          />
                        </g>
                        <g id="XMLID_8081_" style="clip-path: url(#XMLID_2_)">
                          <polygon
                            id="XMLID_8082_"
                            style="fill: #7a7082"
                            points="333.8,223.9 333.8,223.9 333.8,223.9                     "
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_8066_">
                      <g id="XMLID_8073_">
                        <path
                          id="XMLID_8074_"
                          style="fill: #ffffff"
                          d="M337.7,209.8c2.2,0.3,4.7,2,5.1,3.5c0.6,1.8,0.3,2.8-1.5,2.6
                    c-0.7-0.1-1.3,1.9-1.6,2.6c-0.2,0.4-0.8,0.7-1,0.7c-0.6-0.1-1.3,0.7-1.4,1.6c-0.1,0.7,0.2,1.6,0.6,1.8
                    c-1.2,2.9,4.5,6-1.1,8.2c-0.1,0.1,3.9,5.6-1.3,8.7c-4.3,2.5-13.1,1.6-15.1-3.3c-3.4-8.7,8.5-10.3,9.1-17.7
                    C329.9,212.6,334.2,209.3,337.7,209.8z"
                        />
                      </g>
                      <g id="XMLID_8067_">
                        <path
                          id="XMLID_8069_"
                          d="M329.6,241.2c-1.6,0-3.2-0.3-4.7-0.8c-2.5-0.9-4.2-2.3-4.9-4.2c-2-5.2,1.1-8,4.2-10.7
                    c2.3-2,4.6-4.1,4.8-7.2c0.2-3,1.5-5.6,3.6-7.3c1.6-1.3,3.4-1.9,5.1-1.7l0,0c2.3,0.3,5,2.1,5.5,3.8c0.4,1.3,0.4,2.1-0.1,2.6
                    c-0.4,0.4-1,0.6-1.9,0.5c-0.1,0-0.4,0.4-1,1.9c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.6-1,1-1.5,0.9c-0.1,0-0.2,0-0.4,0.2
                    c-0.3,0.2-0.5,0.6-0.6,1c-0.1,0.6,0.2,1.3,0.4,1.4l0.3,0.2l-0.1,0.3c-0.4,0.9,0.1,1.9,0.6,3c0.5,1.1,1.1,2.2,0.6,3.3
                    c-0.3,0.8-1,1.4-2.2,1.9c2.6,5,0.5,7.5-1.7,8.8C334,240.7,331.9,241.2,329.6,241.2z M337.1,210.1c-1.3,0-2.7,0.5-3.9,1.5
                    c-1.9,1.5-3,3.9-3.3,6.7c-0.3,3.4-2.7,5.6-5.1,7.7c-3,2.7-5.8,5.2-4,9.8c0.8,2.1,2.9,3.2,4.4,3.7c3.3,1.1,7.5,0.9,10-0.6
                    c2.8-1.6,3.2-4.2,1.3-7.8c-0.2-0.3-0.2-0.5-0.2-0.7l0.1-0.2l0.2-0.1c1.2-0.5,1.9-1,2.2-1.6c0.3-0.8-0.1-1.7-0.6-2.7
                    c-0.5-1-1.1-2.2-0.7-3.4c-0.4-0.5-0.6-1.3-0.5-2c0.1-0.6,0.4-1.2,0.9-1.6c0.3-0.3,0.7-0.4,1-0.4c0.1,0,0.5-0.2,0.6-0.4
                    c0-0.1,0.1-0.2,0.1-0.4c0.4-1.1,0.9-2.6,1.9-2.5c0.4,0,0.9,0,1.1-0.2c0.2-0.2,0.3-0.7-0.1-1.8c-0.4-1.3-2.8-3-4.8-3.2
                    C337.5,210.2,337.3,210.1,337.1,210.1z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_8061_">
                    <g id="XMLID_8062_">
                      <path
                        id="XMLID_8063_"
                        d="M341.8,215.9l-0.8-0.1c0.3-2.2-1.1-2.9-1.2-2.9l0.3-0.8C340.8,212.5,342.1,213.7,341.8,215.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8056_">
                    <g id="XMLID_8058_">
                      <path
                        id="XMLID_8059_"
                        d="M336.4,230.8c-0.3-0.9-0.7-1.6-1.2-2.2l0.6-0.6c0.6,0.6,1.1,1.5,1.4,2.5L336.4,230.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8052_">
                    <g id="XMLID_8054_">
                      <path
                        id="XMLID_8055_"
                        d="M331.9,218.2l-0.8-0.1c0.3-3.2,2.1-4.8,2.1-4.9l0.6,0.6l-0.3-0.3l0.3,0.3
                  C333.7,213.8,332.2,215.3,331.9,218.2z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_8048_">
                    <g id="XMLID_8050_">
                      <path
                        id="XMLID_8051_"
                        d="M325,237.9c-2.6-1.5-2.7-4-2.7-4.1l0.8,0c0,0.1,0.1,2.2,2.3,3.4L325,237.9z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_7982_">
                  <g id="XMLID_7995_">
                    <g id="XMLID_8037_">
                      <g id="XMLID_8044_">
                        <path
                          id="XMLID_8045_"
                          style="fill: #ffffff"
                          d="M339.2,257.7l-2.5-12.5c-0.2-0.8-0.7-1.5-1.4-1.9l-15.3-8.8
                    c-0.2-0.1-0.4-0.2-0.6-0.1l0,0c-1.3,0.2-2.1,1.4-2.2,1.6c0,0,0,0,0,0.1l1.8,1.1l0.1-0.1l2,10.6c0.2,0.8,0.6,1.5,1.3,1.9
                    c0,0,0,0,0,0L334,254c0,0,0,0,0,0l1.1,1.4c0,0,0,0,0,0l1.5,4.6c0,0,0,0,0,0c0.3,0,2.4-0.1,2.5-1.8l0-0.1
                    C339.2,258.1,339.2,257.8,339.2,257.7z"
                        />
                      </g>
                      <g id="XMLID_8039_">
                        <path
                          id="XMLID_8041_"
                          d="M336.4,260.5l-0.1-0.3l-1.5-4.5l-1-1.3l-11.6-4.5c-0.8-0.5-1.4-1.3-1.5-2.2l-2-10.2l-1.8-1.1
                    c-0.2-0.1-0.3-0.4-0.2-0.6c0.2-0.3,1-1.6,2.6-1.8c0.2-0.1,0.6,0,0.9,0.2l15.3,8.8c0.8,0.5,1.4,1.3,1.6,2.2l2.5,12.5
                    c0,0.1,0.1,0.5,0,0.7l0,0.1c-0.1,1.6-1.6,2.1-2.9,2.1L336.4,260.5z M322.6,249.1l11.7,4.5l0.1,0.1l1.2,1.6l1.4,4.3
                    c0.6,0,1.8-0.3,1.8-1.4l0-0.1c0-0.1,0-0.3,0-0.4l-2.5-12.5c-0.1-0.7-0.6-1.3-1.2-1.6l-15.3-8.8c-0.1-0.1-0.2-0.1-0.3-0.1
                    c-0.9,0.1-1.5,0.7-1.8,1.1l1.3,0.7l0.4-0.2l2.1,11.1C321.6,248.2,322,248.8,322.6,249.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_8023_">
                      <g id="XMLID_8033_">
                        <path
                          id="XMLID_8035_"
                          d="M339.2,258.3l0-0.1c0-0.1,0-0.4,0-0.5l-2.5-12.5c-0.1-0.4-0.3-0.8-0.5-1.1
                    c-0.7,0.7-1.8,1.1-1.8,1.1s-2.2,1.3-2.4,1.4c-0.2,0.1-3.7,3.6-3.8,4c0,0.1,0,0.5,0.1,1.2l5.7,2.2c0,0,0,0,0,0l1.1,1.4
                    c0,0,0,0,0,0l1.5,4.6c0,0,0,0,0,0C337,260.1,339.1,260,339.2,258.3z"
                        />
                      </g>
                      <g id="XMLID_8025_">
                        <path
                          id="XMLID_8027_"
                          d="M336.4,260.5l-0.1-0.3l-1.5-4.5l-1-1.3l-5.8-2.3l0-0.2c-0.2-1.2-0.2-1.3-0.1-1.5
                    c0.2-0.5,3.8-4.1,4-4.2c0.1-0.1,1.3-0.7,2.3-1.3l0.1,0c0,0,1-0.4,1.6-1l0.3-0.3l0.3,0.4c0.3,0.4,0.5,0.8,0.6,1.3l2.5,12.5
                    c0,0.1,0.1,0.5,0,0.7l0,0.1c-0.1,1.6-1.6,2.1-2.9,2.1L336.4,260.5z M328.7,251.5l5.6,2.2l0.1,0.1l1.2,1.5l1.4,4.4
                    c0.6,0,1.8-0.3,1.8-1.4l0,0l0-0.1c0-0.1,0-0.3,0-0.4l-2.5-12.5c0-0.2-0.1-0.4-0.2-0.6c-0.6,0.5-1.3,0.8-1.5,0.9
                    c-0.6,0.4-2,1.2-2.4,1.4c-0.4,0.3-3.2,3.2-3.6,3.7C328.6,250.8,328.6,251,328.7,251.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_8009_">
                      <g id="XMLID_8019_">
                        <path
                          id="XMLID_8021_"
                          d="M320.6,234.9l-0.7-0.4c-0.2-0.1-0.4-0.2-0.6-0.1c-1.3,0.2-2.1,1.4-2.2,1.6c0,0,0,0,0,0.1l1,0.6
                    c0.4-0.3,0.4-0.4,0.4-0.4S319.2,235.5,320.6,234.9L320.6,234.9z"
                        />
                      </g>
                      <g id="XMLID_8011_">
                        <path
                          id="XMLID_8013_"
                          d="M318.1,237.1l-1.2-0.7c-0.2-0.1-0.3-0.4-0.2-0.6c0.2-0.3,1-1.6,2.6-1.8c0.2-0.1,0.6,0,0.9,0.2
                    l1.4,0.8l-0.8,0.3c-1.1,0.5-1.7,1-1.9,1.2c-0.1,0.1-0.2,0.3-0.5,0.5L318.1,237.1z M317.7,235.9l0.4,0.2
                    c0.1-0.1,0.1-0.1,0.1-0.1l0-0.1l0,0c0,0,0.5-0.5,1.5-1c-0.1,0-0.2-0.1-0.3-0.1C318.6,234.9,318,235.5,317.7,235.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7997_">
                      <g id="XMLID_8007_">
                        <path
                          id="XMLID_8008_"
                          style="fill: #ffffff"
                          d="M337.5,259.1l-2.6-12.7c-0.2-0.8-0.7-1.5-1.4-1.9l-15.4-8.8
                    c-0.6-0.4-1.4,0.2-1.2,0.9l2.3,12.2c0.2,0.8,0.6,1.5,1.3,1.9l15.6,9.2C336.9,260.3,337.6,259.8,337.5,259.1z"
                        />
                      </g>
                      <g id="XMLID_7999_">
                        <path
                          id="XMLID_8001_"
                          d="M336.7,260.5c-0.2,0-0.4-0.1-0.6-0.2l-15.6-9.2c-0.8-0.5-1.4-1.3-1.5-2.2l-2.3-12.2
                    c-0.1-0.5,0.1-1,0.5-1.2c0.4-0.3,0.9-0.3,1.3-0.1l15.4,8.8c0.8,0.5,1.4,1.3,1.6,2.2l2.6,12.7c0.1,0.5-0.1,1-0.5,1.3
                    C337.2,260.4,336.9,260.5,336.7,260.5z M317.8,236c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0.2-0.2,0.4l2.3,12.2
                    c0.1,0.7,0.5,1.3,1.1,1.6l15.6,9.2c0.2,0.1,0.4,0,0.4,0c0.1,0,0.2-0.2,0.2-0.4l-2.6-12.7c-0.1-0.7-0.6-1.3-1.2-1.6
                    l-15.4-8.8C317.9,236,317.9,236,317.8,236z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_7989_">
                    <g id="XMLID_7991_">
                      <path
                        id="XMLID_7993_"
                        d="M335.7,270.1c-0.4,0-0.9-0.1-1.3-0.3c-0.8-0.5-1.4-1.3-1.4-2.3c0-1.3-0.1-2.7-0.4-4l-2.5-11.4
                  c-0.5-2.4-2.1-4.5-4.2-5.7l-0.7-0.4c-0.4-0.2-0.9-0.2-1.3,0.1c-0.4,0.3-0.6,0.7-0.5,1.2l2.3,10.9c0.3,1.4,0.4,2.8,0.4,4.2
                  v0.1c0,0.4,0.3,0.6,0.4,0.6c0.1,0.1,0.4,0.2,0.7,0l1,1.6c-0.8,0.5-1.8,0.5-2.7,0.1c-0.8-0.5-1.4-1.3-1.4-2.3v-0.1
                  c0-1.3-0.1-2.5-0.4-3.8l-2.3-10.9c-0.3-1.2,0.2-2.4,1.2-3.2c1-0.7,2.3-0.8,3.4-0.2l0.7,0.4c2.6,1.5,4.5,4,5.2,6.9l2.5,11.4
                  c0.3,1.4,0.5,2.9,0.5,4.4c0,0.4,0.3,0.6,0.4,0.6c0.1,0.1,0.4,0.2,0.7,0l1,1.6C336.6,270,336.2,270.1,335.7,270.1z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_7983_">
                    <g id="XMLID_7985_">
                      <path
                        id="XMLID_7987_"
                        style="fill: #ffffff"
                        d="M337.9,260.2l-0.1-0.6c1.3-0.3,1.2-1.2,1.2-1.3l-2.5-12.9l0.6-0.1l2.5,13
                  C339.6,258.7,339.4,259.9,337.9,260.2z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_7956_">
                <g id="XMLID_7967_">
                  <g id="XMLID_7977_">
                    <path
                      id="XMLID_7979_"
                      style="fill: #ffffff"
                      d="M383,225.4l2.9,1.7c0.5,0.3,1,0.2,1.4-0.1l5.1-4.4c0.4-0.3,0.3-0.9-0.1-1.1
                l-2.8-1.8c-0.5-0.3-1-0.2-1.5,0.1l-5.2,4.4C382.6,224.6,382.6,225.2,383,225.4z"
                    />
                  </g>
                  <g id="XMLID_7969_">
                    <path
                      id="XMLID_7971_"
                      d="M386.6,227.7c-0.3,0-0.6-0.1-0.8-0.2l-2.9-1.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.1-0.7,0.4-0.9
                l5.2-4.4c0.5-0.5,1.3-0.5,1.9-0.1l2.8,1.8c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.1,0.7-0.4,0.9l-5.1,4.4
                C387.4,227.6,387,227.7,386.6,227.7z M383.3,225.1l2.9,1.7c0.3,0.2,0.7,0.1,1-0.1l5.1-4.4c0.1-0.1,0.1-0.2,0.1-0.2
                c0,0,0-0.1-0.1-0.2l-2.8-1.8c-0.3-0.2-0.7-0.2-1,0.1l-5.2,4.4c-0.1,0.1-0.1,0.2-0.1,0.2C383.1,224.9,383.2,225,383.3,225.1
                L383.3,225.1z"
                    />
                  </g>
                </g>
                <g id="XMLID_7958_">
                  <g id="XMLID_7965_">
                    <path
                      id="XMLID_7966_"
                      style="fill: #ffffff"
                      d="M383,224.6l2.9,1.7c0.5,0.3,1,0.2,1.4-0.1l5.1-4.4c0.4-0.3,0.3-0.9-0.1-1.1
                l-2.8-1.8c-0.5-0.3-1-0.2-1.5,0.1l-5.2,4.4C382.6,223.8,382.6,224.4,383,224.6z"
                    />
                  </g>
                  <g id="XMLID_7959_">
                    <path
                      id="XMLID_7961_"
                      d="M386.6,226.9c-0.3,0-0.6-0.1-0.8-0.2l-2.9-1.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.1-0.7,0.4-0.9
                l5.2-4.4c0.5-0.5,1.3-0.5,1.9-0.1l2.8,1.8c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.1,0.7-0.4,0.9l-5.1,4.4
                C387.4,226.8,387,226.9,386.6,226.9z M383.3,224.3l2.9,1.7c0.3,0.2,0.7,0.1,1-0.1l5.1-4.4c0.1-0.1,0.1-0.2,0.1-0.2
                c0,0,0-0.1-0.1-0.2l-2.8-1.8c-0.3-0.2-0.7-0.2-1,0.1l-5.2,4.4c-0.1,0.1-0.1,0.2-0.1,0.2C383.1,224.1,383.2,224.2,383.3,224.3
                L383.3,224.3z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_7914_">
            <g id="XMLID_7944_">
              <g id="XMLID_7951_">
                <path
                  id="XMLID_7952_"
                  style="fill: #ffffff"
                  d="M335.9,297.9c0,0,0-2.9,0-3.1c0,0,0,0,0-0.1l-6.5,0.6c0,0,0,0,0,0l-0.6-3.6
            c0,0,0,0-0.1,0l-6,3.4c0,0,0,0,0,0l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0c0,0.2,0.1,0.4,0.3,0.5l6,3.5
            c0.5,0.3,1.1,0.3,1.7,0l6.1-3.5C335.8,298.3,335.9,298.1,335.9,297.9L335.9,297.9z"
                />
              </g>
              <g id="XMLID_7945_">
                <path
                  id="XMLID_7947_"
                  d="M328.6,302.6c-0.4,0-0.7-0.1-1-0.3l-6-3.5c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.2l0-1.1l0.2-0.2
            c0.1-0.1,0.3-0.1,0.4-0.1l1,0.3l6.1-3.5l0.4,0.2l0.6,3.4l6.2-0.6l0.1,0c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8
            l-6.1,3.5C329.3,302.5,329,302.6,328.6,302.6z M321.8,295.3l0,0.4c0,0.9,0,2.1,0,2.2c0,0,0,0.1,0.1,0.2l6,3.5
            c0.4,0.2,0.9,0.2,1.2,0l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2h0l0-2.7l-6.2,0.6l-0.2-0.1l-0.2-0.1l-0.5-3.2l-5.7,3.3l-0.2,0
            L321.8,295.3z"
                />
              </g>
            </g>
            <g id="XMLID_7935_">
              <g id="XMLID_7941_">
                <path
                  id="XMLID_7943_"
                  d="M335.9,294.7l-6.5,0.6c0,0,0,0,0,0l-0.8,2.2l0,4.7c0.3,0,0.6-0.1,0.9-0.2l6.1-3.5
            c0.2-0.1,0.3-0.3,0.3-0.5l0,0C335.9,297.9,335.9,295,335.9,294.7C335.9,294.7,335.9,294.7,335.9,294.7z"
                />
              </g>
              <g id="XMLID_7936_">
                <path
                  id="XMLID_7938_"
                  d="M328.6,302.6C328.6,302.6,328.6,302.6,328.6,302.6l-0.5,0l0-5.2l0.9-2.7l0.3,0.2l6.5-0.6l0.1,0
            c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8l-6.1,3.5C329.3,302.5,329,302.6,328.6,302.6z M329,297.6l0,4.1
            c0.1,0,0.2-0.1,0.3-0.1l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2l0-2.7l-5.8,0.5L329,297.6z"
                />
              </g>
            </g>
            <g id="XMLID_7925_">
              <g id="XMLID_7932_">
                <path
                  id="XMLID_7934_"
                  d="M322.4,295.6c0,0,0.1-0.2,0.2-0.6l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0
            c0,0.2,0.1,0.4,0.3,0.5l0.6,0.4l0-1.7V295.6z"
                />
              </g>
              <g id="XMLID_7927_">
                <path
                  id="XMLID_7928_"
                  d="M322.8,299.5l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.2l0-1.1l0.2-0.2
            c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l-0.3,0.9L322.8,299.5z M321.8,295.3l0,0.4c0,0.9,0,2.1,0,2.2c0,0,0,0.1,0.1,0.1l0-2.6l0-0.1
            L321.8,295.3z"
                />
              </g>
            </g>
            <g id="XMLID_7916_">
              <g id="XMLID_7922_">
                <path
                  id="XMLID_7924_"
                  style="fill: #ffffff"
                  d="M327.8,298.8l-6.1-3.5c-0.4-0.2-0.4-0.9,0-1.1l6.2-3.5c0.5-0.3,1.1-0.3,1.6,0
            l6.1,3.5c0.4,0.2,0.4,0.9,0,1.1l-6.2,3.5C328.9,299.1,328.3,299.1,327.8,298.8z"
                />
              </g>
              <g id="XMLID_7917_">
                <path
                  id="XMLID_7918_"
                  d="M328.6,299.4c-0.4,0-0.7-0.1-1-0.3l-6.1-3.5c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9l6.2-3.5
            c0.6-0.4,1.4-0.4,2,0l6.1,3.5c0.3,0.2,0.5,0.5,0.5,0.9c0,0.4-0.2,0.7-0.5,0.9l-6.2,3.5C329.3,299.4,329,299.4,328.6,299.4z
             M328.7,290.9c-0.2,0-0.4,0.1-0.6,0.2l-6.2,3.5c-0.1,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0.1,0.2l6.1,3.5c0.4,0.2,0.8,0.2,1.2,0
            l6.2-3.5c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-6.1-3.5C329.1,290.9,328.9,290.9,328.7,290.9z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="conseil"
          @mouseover="hilight('conseil', true)"
          @mouseleave="hilight('conseil', false)"
          @click="activate('conseil')">
        <g id="conseil-socle">
          <path
            id="XMLID_15624_"
            style="fill: #e5ecf4"
            :class="{ active: active_conseil, hilighted: hilighted_conseil  }"
            d="M342.6,146.9l143.9,82.3c5.9,3.4,13.1,3.4,18.9,0l135.2-79.2
      c4.9-2.8,4.9-9.9,0-12.8L496.7,54.9c-5.9-3.4-13.1-3.4-18.9,0l-135.2,79.2C337.7,137,337.7,144.1,342.6,146.9z"
          />
        </g>
        <g id="conseil-illus">
          <g id="XMLID_15417_">
            <g id="XMLID_15493_">
              <g id="XMLID_15503_">
                <g id="XMLID_15511_">
                  <g id="XMLID_15516_">
                    <path
                      id="XMLID_15517_"
                      style="fill: #ffffff"
                      d="M501.3,205.4c0,0,0-2.7,0-3.6c0-0.2,0.1-0.3,0.3-0.4l8.5-2l43-24.8l3.3-2
                l6.9,0.9c0.2,0,0.3,0.2,0.3,0.4l0,3.5c0.1,0.5-0.1,1.1-0.7,1.4l-52.7,30.4c-1.1,0.6-2.4,0.6-3.5,0l-4.7-2.7
                C501.6,206.3,501.4,205.8,501.3,205.4L501.3,205.4z"
                    />
                  </g>
                  <g id="XMLID_15512_">
                    <path
                      id="XMLID_15513_"
                      d="M508.4,210.2c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.3-0.9-0.9-1-1.5l0-0.1c0,0,0,0,0-3.6
                c0-0.4,0.3-0.8,0.7-0.9l8.4-2l42.9-24.8l3.4-2.1l7.1,0.9c0.5,0.1,0.8,0.5,0.8,0.9l0,3.4c0.1,0.8-0.3,1.6-0.9,2l-52.7,30.4
                C509.8,210,509.1,210.2,508.4,210.2z M501.9,205.3L501.9,205.3c0,0.2,0.1,0.5,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0l52.7-30.4
                c0.4-0.2,0.5-0.6,0.4-0.9l0,0l0,0l0-3.3l-6.6-0.9l-3.1,1.9l-43,24.8l-8.5,2C501.9,202.9,501.9,205,501.9,205.3z"
                    />
                  </g>
                </g>
                <g id="XMLID_15504_">
                  <g id="XMLID_15509_">
                    <path
                      id="XMLID_15510_"
                      style="fill: #ffffff"
                      d="M510.2,205.4l52.7-30.4c0.9-0.5,0.9-1.9,0-2.4l-4.7-2.7
                c-1.1-0.6-2.4-0.6-3.5,0L502,200.3c-0.9,0.5-0.9,1.9,0,2.4l4.7,2.7C507.8,206,509.1,206,510.2,205.4z"
                    />
                  </g>
                  <g id="XMLID_15505_">
                    <path
                      id="XMLID_15506_"
                      d="M508.4,206.4c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.4-1-1-1-1.7s0.4-1.3,1-1.7l52.7-30.4
                c1.2-0.7,2.8-0.7,4,0l4.7,2.7c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-52.7,30.4C509.8,206.2,509.1,206.4,508.4,206.4z
                 M556.5,169.9c-0.5,0-1,0.1-1.5,0.4l-52.7,30.4c-0.3,0.2-0.4,0.4-0.4,0.7s0.2,0.6,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0
                l52.7-30.4c0.3-0.2,0.4-0.4,0.4-0.7s-0.2-0.6-0.4-0.7l-4.7-2.7C557.5,170,557,169.9,556.5,169.9z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_15500_">
                <g id="XMLID_15501_">
                  <rect
                    id="XMLID_15502_"
                    x="507.6"
                    y="205.9"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15497_">
                <g id="XMLID_15498_">
                  <rect
                    id="XMLID_15499_"
                    x="559.8"
                    y="176.4"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15494_">
                <g id="XMLID_15495_">
                  <polygon
                    id="XMLID_15496_"
                    points="511,204.9 511,208.4 557.3,182 557.3,178.3             "
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_15468_">
              <g id="XMLID_15478_">
                <g id="XMLID_15486_">
                  <g id="XMLID_15491_">
                    <path
                      id="XMLID_15492_"
                      style="fill: #ffffff"
                      d="M510.7,217.7c0,0,0-2.7,0-3.6c0-0.2,0.1-0.3,0.3-0.4l8.5-2l43-24.8l3.3-2
                l6.9,0.9c0.2,0,0.3,0.2,0.3,0.4l0,3.5c0.1,0.5-0.1,1.1-0.7,1.5l-52.7,30.4c-1.1,0.6-2.4,0.6-3.5,0l-4.7-2.7
                C511,218.6,510.7,218.2,510.7,217.7L510.7,217.7z"
                    />
                  </g>
                  <g id="XMLID_15487_">
                    <path
                      id="XMLID_15488_"
                      d="M517.8,222.5c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.3-0.9-0.9-1-1.5l0-0.1c0,0,0,0,0-3.6
                c0-0.4,0.3-0.8,0.7-0.9l8.4-2l42.9-24.8l3.4-2.1l7.1,0.9c0.5,0.1,0.8,0.5,0.8,0.9l0,3.4c0.1,0.8-0.3,1.6-0.9,2L519.8,222
                C519.2,222.4,518.5,222.5,517.8,222.5z M511.2,217.6L511.2,217.6c0,0.2,0.1,0.5,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0l52.7-30.4
                c0.4-0.2,0.5-0.6,0.4-0.9l0,0l0,0l0-3.3l-6.6-0.9l-3.1,1.9l-43,24.8l-8.5,2C511.2,215.2,511.2,217.4,511.2,217.6z"
                    />
                  </g>
                </g>
                <g id="XMLID_15479_">
                  <g id="XMLID_15484_">
                    <path
                      id="XMLID_15485_"
                      style="fill: #ffffff"
                      d="M519.6,217.7l52.7-30.4c0.9-0.5,0.9-1.9,0-2.4l-4.7-2.7
                c-1.1-0.6-2.4-0.6-3.5,0l-52.7,30.4c-0.9,0.5-0.9,1.9,0,2.4l4.7,2.7C517.2,218.4,518.5,218.4,519.6,217.7z"
                    />
                  </g>
                  <g id="XMLID_15480_">
                    <path
                      id="XMLID_15481_"
                      d="M517.8,218.7c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l52.7-30.4
                c1.2-0.7,2.8-0.7,4,0l4.7,2.7c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-52.7,30.4C519.2,218.6,518.5,218.7,517.8,218.7z
                 M565.9,182.2c-0.5,0-1,0.1-1.5,0.4l-52.7,30.4c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0
                l52.7-30.4c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-4.7-2.7C566.9,182.4,566.4,182.2,565.9,182.2z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_15475_">
                <g id="XMLID_15476_">
                  <rect
                    id="XMLID_15477_"
                    x="517"
                    y="218.2"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15472_">
                <g id="XMLID_15473_">
                  <rect
                    id="XMLID_15474_"
                    x="569.2"
                    y="188.7"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15469_">
                <g id="XMLID_15470_">
                  <polygon
                    id="XMLID_15471_"
                    points="520.4,217.3 520.4,220.8 566.7,194.3 566.7,190.6             "
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_15443_">
              <g id="XMLID_15453_">
                <g id="XMLID_15461_">
                  <g id="XMLID_15466_">
                    <path
                      id="XMLID_15467_"
                      style="fill: #ffffff"
                      d="M520.1,230c0,0,0-2.7,0-3.6c0-0.2,0.1-0.3,0.3-0.4l8.5-2l43-24.8l3.3-2
                l6.9,0.9c0.2,0,0.3,0.2,0.3,0.4l0,3.5c0.1,0.5-0.1,1.1-0.7,1.4L529,233.9c-1.1,0.6-2.4,0.6-3.5,0l-4.7-2.7
                C520.4,230.9,520.1,230.5,520.1,230L520.1,230z"
                    />
                  </g>
                  <g id="XMLID_15462_">
                    <path
                      id="XMLID_15463_"
                      d="M527.2,234.9c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.3-0.9-0.9-1-1.5l0-0.1c0,0,0,0,0-3.6
                c0-0.4,0.3-0.8,0.7-0.9l8.4-2l42.9-24.8l3.4-2.1l7.1,0.9c0.5,0.1,0.8,0.5,0.8,0.9l0,3.4c0.1,0.8-0.3,1.6-0.9,2l-52.7,30.4
                C528.6,234.7,527.9,234.9,527.2,234.9z M520.6,230L520.6,230c0,0.2,0.1,0.5,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0l52.7-30.4
                c0.4-0.2,0.5-0.6,0.4-0.9l0,0l0,0l0-3.3l-6.6-0.9l-3.1,1.9l-43,24.8l-8.5,2C520.6,227.5,520.6,229.7,520.6,230z"
                    />
                  </g>
                </g>
                <g id="XMLID_15454_">
                  <g id="XMLID_15459_">
                    <path
                      id="XMLID_15460_"
                      style="fill: #ffffff"
                      d="M529,230.1l52.7-30.4c0.9-0.5,0.9-1.9,0-2.4l-4.7-2.7
                c-1.1-0.6-2.4-0.6-3.5,0l-52.7,30.4c-0.9,0.5-0.9,1.9,0,2.4l4.7,2.7C526.6,230.7,527.9,230.7,529,230.1z"
                    />
                  </g>
                  <g id="XMLID_15455_">
                    <path
                      id="XMLID_15456_"
                      d="M527.2,231.1c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l52.7-30.4
                c1.2-0.7,2.8-0.7,4,0l4.7,2.7c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-52.7,30.4v0C528.6,230.9,527.9,231.1,527.2,231.1z
                 M575.3,194.6c-0.5,0-1,0.1-1.5,0.4l-52.7,30.4c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0v0
                l52.7-30.4c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-4.7-2.7C576.3,194.7,575.8,194.6,575.3,194.6z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_15450_">
                <g id="XMLID_15451_">
                  <rect
                    id="XMLID_15452_"
                    x="526.4"
                    y="230.5"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15447_">
                <g id="XMLID_15448_">
                  <rect
                    id="XMLID_15449_"
                    x="578.6"
                    y="201"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15444_">
                <g id="XMLID_15445_">
                  <polygon
                    id="XMLID_15446_"
                    points="529.8,229.6 529.8,233.1 576.1,206.6 576.1,202.9             "
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_15418_">
              <g id="XMLID_15428_">
                <g id="XMLID_15436_">
                  <g id="XMLID_15441_">
                    <path
                      id="XMLID_15442_"
                      style="fill: #ffffff"
                      d="M529.5,242.3c0,0,0-2.7,0-3.6c0-0.2,0.1-0.3,0.3-0.4l8.5-2l43-24.8l3.3-2
                l6.9,0.9c0.2,0,0.3,0.2,0.3,0.4l0,3.5c0.1,0.5-0.1,1.1-0.7,1.5l-52.7,30.4c-1.1,0.6-2.4,0.6-3.5,0l-4.7-2.7
                C529.8,243.3,529.6,242.8,529.5,242.3L529.5,242.3z"
                    />
                  </g>
                  <g id="XMLID_15437_">
                    <path
                      id="XMLID_15438_"
                      d="M536.6,247.2c-0.7,0-1.4-0.2-2-0.5L530,244c-0.6-0.3-0.9-0.9-1-1.5l0-0.1c0,0,0,0,0-3.6
                c0-0.4,0.3-0.8,0.7-0.9l8.4-2l42.9-24.8l3.4-2.1l7.1,0.9c0.5,0.1,0.8,0.5,0.8,0.9l0,3.4c0.1,0.8-0.3,1.6-0.9,2l-52.7,30.4
                C538,247,537.3,247.2,536.6,247.2z M530.1,242.3L530.1,242.3c0,0.2,0.1,0.5,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0l52.7-30.4
                c0.4-0.2,0.5-0.6,0.4-0.9l0,0l0,0l0-3.3l-6.6-0.9l-3.1,1.9l-43,24.8l-8.5,2C530.1,239.9,530.1,242,530.1,242.3z"
                    />
                  </g>
                </g>
                <g id="XMLID_15429_">
                  <g id="XMLID_15434_">
                    <path
                      id="XMLID_15435_"
                      style="fill: #ffffff"
                      d="M538.4,242.4l52.7-30.4c0.9-0.5,0.9-1.9,0-2.4l-4.7-2.7
                c-1.1-0.6-2.4-0.6-3.5,0l-52.7,30.4c-0.9,0.5-0.9,1.9,0,2.4l4.7,2.7C536,243,537.3,243,538.4,242.4z"
                    />
                  </g>
                  <g id="XMLID_15430_">
                    <path
                      id="XMLID_15431_"
                      d="M536.6,243.4c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l52.7-30.4
                c1.2-0.7,2.8-0.7,4,0l4.7,2.7c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-52.7,30.4v0C538,243.2,537.3,243.4,536.6,243.4z
                 M584.7,206.9c-0.5,0-1,0.1-1.5,0.4l-52.7,30.4c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0v0
                l52.7-30.4c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-4.7-2.7C585.7,207,585.2,206.9,584.7,206.9z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_15425_">
                <g id="XMLID_15426_">
                  <rect
                    id="XMLID_15427_"
                    x="535.8"
                    y="242.9"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15422_">
                <g id="XMLID_15423_">
                  <rect
                    id="XMLID_15424_"
                    x="588"
                    y="213.4"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15419_">
                <g id="XMLID_15420_">
                  <polygon
                    id="XMLID_15421_"
                    points="539.2,241.9 539.2,245.4 585.6,219 585.6,215.3             "
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_15151_">
            <g id="XMLID_15279_">
              <g id="XMLID_15280_">
                <g id="XMLID_15327_">
                  <path
                    id="XMLID_15328_"
                    style="fill: #ffffff"
                    d="M582.9,145.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C583.1,145.5,583.1,145.7,582.9,145.8z"
                  />
                </g>
                <g id="XMLID_15325_">
                  <path
                    id="XMLID_15326_"
                    style="fill: #ffffff"
                    d="M586.9,148.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C587.1,147.8,587.1,148,586.9,148.1z"
                  />
                </g>
                <g id="XMLID_15323_">
                  <path
                    id="XMLID_15324_"
                    style="fill: #ffffff"
                    d="M582.7,150.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C582.8,150.3,582.9,150.4,582.7,150.5z"
                  />
                </g>
                <g id="XMLID_15321_">
                  <path
                    id="XMLID_15322_"
                    style="fill: #ffffff"
                    d="M591.2,150.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C591.3,150.3,591.4,150.4,591.2,150.5z"
                  />
                </g>
                <g id="XMLID_15319_">
                  <path
                    id="XMLID_15320_"
                    style="fill: #ffffff"
                    d="M586.9,153l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C587.1,152.7,587.1,152.9,586.9,153z"
                  />
                </g>
                <g id="XMLID_15317_">
                  <path
                    id="XMLID_15318_"
                    style="fill: #ffffff"
                    d="M595.2,152.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C595.4,152.6,595.4,152.8,595.2,152.9z"
                  />
                </g>
                <g id="XMLID_15315_">
                  <path
                    id="XMLID_15316_"
                    style="fill: #ffffff"
                    d="M591,155.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C591.1,155,591.1,155.2,591,155.3z"
                  />
                </g>
                <g id="XMLID_15313_">
                  <path
                    id="XMLID_15314_"
                    style="fill: #ffffff"
                    d="M599.2,155.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C599.4,154.9,599.4,155.1,599.2,155.2z"
                  />
                </g>
                <g id="XMLID_15311_">
                  <path
                    id="XMLID_15312_"
                    style="fill: #ffffff"
                    d="M595,157.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C595.1,157.3,595.2,157.5,595,157.6z"
                  />
                </g>
                <g id="XMLID_15309_">
                  <path
                    id="XMLID_15310_"
                    style="fill: #ffffff"
                    d="M603.5,157.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C603.6,157.4,603.6,157.5,603.5,157.6z"
                  />
                </g>
                <g id="XMLID_15307_">
                  <path
                    id="XMLID_15308_"
                    style="fill: #ffffff"
                    d="M599.2,160.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C599.4,159.8,599.4,160,599.2,160.1z"
                  />
                </g>
                <g id="XMLID_15305_">
                  <path
                    id="XMLID_15306_"
                    style="fill: #ffffff"
                    d="M607.7,155.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C607.9,154.9,607.9,155.1,607.7,155.2z"
                  />
                </g>
                <g id="XMLID_15303_">
                  <path
                    id="XMLID_15304_"
                    style="fill: #ffffff"
                    d="M611.9,152.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C612.1,152.5,612.1,152.6,611.9,152.7z"
                  />
                </g>
                <g id="XMLID_15301_">
                  <path
                    id="XMLID_15302_"
                    style="fill: #ffffff"
                    d="M616.2,150.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C616.3,150,616.4,150.2,616.2,150.3z"
                  />
                </g>
                <g id="XMLID_15299_">
                  <path
                    id="XMLID_15300_"
                    style="fill: #ffffff"
                    d="M620.4,147.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C620.6,147.6,620.6,147.8,620.4,147.9z"
                  />
                </g>
                <g id="XMLID_15297_">
                  <path
                    id="XMLID_15298_"
                    style="fill: #ffffff"
                    d="M624.6,145.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C624.8,145.1,624.8,145.3,624.6,145.4z"
                  />
                </g>
                <g id="XMLID_15295_">
                  <path
                    id="XMLID_15296_"
                    style="fill: #ffffff"
                    d="M607.5,160l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C607.6,159.7,607.7,159.9,607.5,160z"
                  />
                </g>
                <g id="XMLID_15293_">
                  <path
                    id="XMLID_15294_"
                    style="fill: #ffffff"
                    d="M611.7,157.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C611.9,157.2,611.9,157.4,611.7,157.5z"
                  />
                </g>
                <g id="XMLID_15291_">
                  <path
                    id="XMLID_15292_"
                    style="fill: #ffffff"
                    d="M616,155.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C616.1,154.8,616.1,155,616,155.1z"
                  />
                </g>
                <g id="XMLID_15289_">
                  <path
                    id="XMLID_15290_"
                    style="fill: #ffffff"
                    d="M620.2,152.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C620.4,152.3,620.4,152.5,620.2,152.6z"
                  />
                </g>
                <g id="XMLID_15287_">
                  <path
                    id="XMLID_15288_"
                    style="fill: #ffffff"
                    d="M624.4,150.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C624.6,149.9,624.6,150.1,624.4,150.2z"
                  />
                </g>
                <g id="XMLID_15285_">
                  <path
                    id="XMLID_15286_"
                    style="fill: #ffffff"
                    d="M621.2,132l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C621.4,131.7,621.4,131.9,621.2,132z"
                  />
                </g>
                <g id="XMLID_15283_">
                  <path
                    id="XMLID_15284_"
                    style="fill: #ffffff"
                    d="M625.5,129.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C625.6,129.3,625.6,129.5,625.5,129.6z"
                  />
                </g>
                <g id="XMLID_15281_">
                  <path
                    id="XMLID_15282_"
                    style="fill: #ffffff"
                    d="M625.2,134.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C625.4,134.1,625.4,134.2,625.2,134.3z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_15152_">
              <g id="XMLID_15191_">
                <g id="XMLID_15249_">
                  <g id="XMLID_15250_">
                    <g id="XMLID_15257_">
                      <g id="XMLID_15272_">
                        <g id="XMLID_15277_">
                          <path
                            id="XMLID_15278_"
                            style="fill: #ffffff"
                            d="M600.9,152.3l-18.3-10.6c-0.2-0.1-0.3-0.3-0.3-0.4l0,0v0c0,0,0,0,0,0
                      l0-3.6c0-0.1,0.1-0.2,0.2-0.2l12.3-3.7c0,0,0.1,0,0.1,0l5.8-3.3c0.5-0.3,1.1-0.3,1.5-0.1l12.4,7.3c0,0,0.1,0,0.1,0l5.9,0
                      c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-18,10.3C602,152.5,601.3,152.5,600.9,152.3z"
                          />
                        </g>
                        <g id="XMLID_15273_">
                          <path
                            id="XMLID_15274_"
                            d="M601.6,152.8c-0.3,0-0.6-0.1-0.9-0.2l0,0L582.5,142c-0.3-0.2-0.5-0.5-0.5-0.8l0-3.6
                      c0-0.3,0.2-0.5,0.5-0.6l12.3-3.7l5.7-3.2c0.6-0.3,1.4-0.4,1.9-0.1l12.4,7.2l5.8,0c0,0,0,0,0,0c0.2,0,0.3,0.1,0.4,0.2
                      c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8l-18,10.3C602.4,152.7,602,152.8,601.6,152.8z M601.1,151.9
                      c0.2,0.1,0.8,0.1,1.1-0.1l18-10.3c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3l-5.6,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1l-12.4-7.3
                      c-0.2-0.1-0.8-0.1-1.1,0.1l-5.8,3.3c-0.1,0-0.1,0.1-0.2,0.1l-12.1,3.7l0,3.4c0,0,0,0,0.1,0.1L601.1,151.9z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_15265_">
                        <g id="XMLID_15270_">
                          <path
                            id="XMLID_15271_"
                            d="M615.5,137.6c0,0,5.2,0,5.2,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-17.9,10.3
                      c0,0-0.1,0-0.1,0l0-3.7c0,0,0,0,0,0l0,0C602,147.2,615.5,137.6,615.5,137.6z"
                          />
                        </g>
                        <g id="XMLID_15266_">
                          <path
                            id="XMLID_15267_"
                            d="M602.5,152.6l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-3.7c-0.1-0.4,0.1-1.3,6.5-6.2
                      c3.3-2.5,6.6-4.9,6.7-4.9l0.1-0.1c0.1,0,0.1,0,5.3,0c0,0,0,0,0,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6
                      c0,0.3-0.2,0.6-0.6,0.8L602.5,152.6z M602.9,148.5l0,3l17.4-10c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3c-1.4,0-4.2,0-4.8,0
                      c-6,4.3-12.3,9.2-12.7,10.3L602.9,148.5L602.9,148.5z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_15258_">
                        <g id="XMLID_15263_">
                          <path
                            id="XMLID_15264_"
                            style="fill: #ffffff"
                            d="M620.6,137.2l-18.3-10.6c-0.4-0.2-1.1-0.2-1.5,0.1l-18,10.3
                      c-0.5,0.3-0.5,0.7-0.1,0.9l18.3,10.6c0.4,0.2,1.1,0.2,1.5-0.1l18-10.3C621,137.8,621,137.4,620.6,137.2z"
                          />
                        </g>
                        <g id="XMLID_15259_">
                          <path
                            id="XMLID_15260_"
                            d="M601.6,149c-0.3,0-0.6-0.1-0.9-0.2l-18.3-10.6c-0.3-0.2-0.5-0.4-0.5-0.7c0-0.3,0.2-0.6,0.6-0.9
                      l18-10.3c0.6-0.3,1.4-0.4,1.9-0.1l18.3,10.6c0.3,0.2,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.9l-18,10.3
                      C602.4,148.9,602,149,601.6,149z M582.9,137.4C582.9,137.4,582.9,137.4,582.9,137.4l18.3,10.7c0.2,0.1,0.8,0.1,1.1-0.1
                      l18-10.3c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0l0,0l-18.3-10.6c-0.2-0.1-0.8-0.1-1.1,0.1l-18,10.3
                      C582.9,137.4,582.9,137.4,582.9,137.4z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_15254_">
                      <g id="XMLID_15255_">
                        <rect
                          id="XMLID_15256_"
                          x="583"
                          y="140.6"
                          transform="matrix(3.012133e-02 -0.9995 0.9995 3.012133e-02 426.3152 721.4128)"
                          width="3.7"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_15251_">
                      <g id="XMLID_15252_">
                        <rect
                          id="XMLID_15253_"
                          x="615.9"
                          y="141.4"
                          transform="matrix(1.440051e-02 -0.9999 0.9999 1.440051e-02 466.9728 757.1464)"
                          style="fill: #ffffff"
                          width="3.3"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_15222_">
                  <g id="XMLID_15226_">
                    <g id="XMLID_15242_">
                      <g id="XMLID_15247_">
                        <path
                          id="XMLID_15248_"
                          style="fill: #ffffff"
                          d="M594.4,146.4l-2.6-1.5c-0.1-0.1-0.2-0.1-0.3,0l-3.2,2
                    c-0.1,0-0.1,0.1,0,0.2l3.1,3.3c0,0,0,0,0,0.1v1.1c0,0.3,1.2-0.6,1.5-0.4l0,0c0,0,0,0,0,0l-1.6,1c0,0.1,0.1,0.1,0.2,0l3.2-2
                    c0,0,0.1-0.2,0.1-0.3v-2.5C594.8,146.9,594.6,146.6,594.4,146.4z"
                        />
                      </g>
                      <g id="XMLID_15243_">
                        <path
                          id="XMLID_15244_"
                          d="M591.4,152.5c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.2l-0.2-0.3l0.2-0.1l0,0l0-0.3v-0.9l-3-3.2
                    c-0.2-0.3-0.1-0.6,0.2-0.7l3.2-2c0.2-0.1,0.5-0.1,0.7,0l2.6,1.5c0.4,0.2,0.6,0.7,0.6,1.2v2.5c0,0.3-0.2,0.5-0.2,0.6
                    l-0.1,0.1l-3.2,2C591.6,152.5,591.5,152.5,591.4,152.5z M588.8,147l2.9,3.1c0,0.1,0.1,0.2,0.1,0.3v0.6c0,0,0.1,0,0.1-0.1
                    c0.5-0.2,0.8-0.4,1.1-0.3l1.4-0.9c0,0,0,0,0,0l0-2.5c0-0.2-0.1-0.4-0.2-0.4l0,0l-2.5-1.5L588.8,147z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_15235_">
                      <g id="XMLID_15240_">
                        <path
                          id="XMLID_15241_"
                          d="M589.1,149.9c0,0,0,0.1,0,0.2l2.2,1.9c0.1,0.1,0.2,0.1,0.3,0.1l3.1-1.9c0,0,0.1-0.2,0.1-0.3
                    v-2.3c0-0.1-0.1-0.1-0.1-0.1l-3.1,2c0,0,0,0,0,0L589.1,149.9z"
                        />
                      </g>
                      <g id="XMLID_15236_">
                        <path
                          id="XMLID_15237_"
                          d="M591.5,152.5c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.2l-2.3-2l0.1-0.7l2.6-0.6l3.1-2
                    c0.2-0.1,0.4-0.1,0.5,0c0.2,0.1,0.3,0.3,0.3,0.4v2.3c0,0.3-0.2,0.5-0.2,0.6l-0.1,0.1l-3.1,1.9
                    C591.7,152.4,591.6,152.5,591.5,152.5z M589.8,150.1l1.7,1.4l2.9-1.8c0,0,0,0,0,0l0-1.7l-2.6,1.6l-0.1,0.1L589.8,150.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_15227_">
                      <g id="XMLID_15233_">
                        <path
                          id="XMLID_15234_"
                          style="fill: #ffffff"
                          d="M591.1,152.1l-2.6-1.5c-0.2-0.1-0.4-0.5-0.4-0.8v-2.5
                    c0-0.3,0.2-0.5,0.4-0.3l2.6,1.5c0.2,0.1,0.4,0.5,0.4,0.8v2.5C591.5,152.1,591.3,152.2,591.1,152.1z"
                        />
                      </g>
                      <g id="XMLID_15228_">
                        <path
                          id="XMLID_15229_"
                          d="M591.3,152.6c-0.1,0-0.3,0-0.4-0.1l0,0l-2.6-1.5c-0.4-0.2-0.6-0.7-0.6-1.2v-2.5
                    c0-0.3,0.1-0.6,0.4-0.7c0.2-0.1,0.5-0.1,0.7,0l2.6,1.5c0.4,0.2,0.6,0.7,0.6,1.2v2.5c0,0.3-0.1,0.6-0.4,0.7
                    C591.5,152.5,591.4,152.6,591.3,152.6z M591.3,151.8l-0.2,0.4L591.3,151.8C591.3,151.8,591.3,151.8,591.3,151.8z
                     M588.5,147.4l0,2.3c0,0.2,0.1,0.4,0.2,0.4l2.4,1.4l0-2.3c0-0.2-0.1-0.4-0.2-0.4L588.5,147.4z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_15223_">
                    <g id="XMLID_15224_">
                      <rect
                        id="XMLID_15225_"
                        x="591.5"
                        y="149.4"
                        transform="matrix(0.8567 -0.5159 0.5159 0.8567 7.7656 327.4721)"
                        style="fill: #ffffff"
                        width="3.3"
                        height="0.6"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_15192_">
                  <g id="XMLID_15193_">
                    <g id="XMLID_15200_">
                      <g id="XMLID_15215_">
                        <g id="XMLID_15220_">
                          <path
                            id="XMLID_15221_"
                            style="fill: #ffffff"
                            d="M603.9,135.9l-11.7-6.8c-0.2-0.1-0.3-0.3-0.3-0.4l0,0v0c0,0,0,0,0,0
                      l0-3.6c0-0.1,0.1-0.2,0.2-0.2l5.9,0c0,0,0.1,0,0.1,0l5.8-3.3c0.5-0.3,1.1-0.3,1.5-0.1l5.9,3.5c0,0,0.1,0,0.1,0l5.9,0
                      c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.6,6.7C604.9,136.1,604.3,136.1,603.9,135.9z"
                          />
                        </g>
                        <g id="XMLID_15216_">
                          <path
                            id="XMLID_15217_"
                            d="M604.5,136.4c-0.3,0-0.6-0.1-0.9-0.2l0,0l-11.7-6.8c-0.3-0.2-0.5-0.5-0.5-0.8l0-3.6
                      c0-0.3,0.3-0.6,0.6-0.6l5.9,0l5.7-3.2c0.6-0.3,1.4-0.4,1.9-0.1l5.8,3.4l5.8,0c0,0,0,0,0,0c0.2,0,0.3,0.1,0.4,0.2
                      c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8l-11.6,6.7C605.3,136.3,604.9,136.4,604.5,136.4z M604.1,135.5
                      c0.2,0.1,0.8,0.1,1.1-0.1l11.6-6.7c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3l-5.6,0c-0.1,0-0.2,0-0.3-0.1l-5.9-3.5
                      c-0.2-0.1-0.8-0.1-1.1,0.1l-5.8,3.3c-0.1,0-0.2,0.1-0.3,0.1l-5.7,0l0,3.4c0,0,0,0,0.1,0.1L604.1,135.5z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_15208_">
                        <g id="XMLID_15213_">
                          <path
                            id="XMLID_15214_"
                            d="M612,124.9c0,0,5.2,0,5.2,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.5,6.6
                      c0,0-0.1,0-0.1,0l0-3.7c0,0,0,0,0,0l0,0C605,130.8,612,124.9,612,124.9z"
                          />
                        </g>
                        <g id="XMLID_15209_">
                          <path
                            id="XMLID_15210_"
                            d="M605.5,136.2l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-3.7c-0.2-1.4,4.1-5.2,6.7-7.4l0.1-0.1
                      c0.1,0,0.1,0,5.3,0c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8L605.5,136.2z
                       M605.8,132.1l0,3l11-6.3c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3c-1.4,0-4.1,0-4.8,0c-3.1,2.6-6.4,5.8-6.3,6.5L605.8,132.1
                      L605.8,132.1z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_15201_">
                        <g id="XMLID_15206_">
                          <path
                            id="XMLID_15207_"
                            style="fill: #ffffff"
                            d="M617.1,124.4l-11.7-6.8c-0.4-0.2-1.1-0.2-1.5,0.1l-11.6,6.7
                      c-0.5,0.3-0.5,0.7-0.1,0.9l11.7,6.8c0.4,0.2,1.1,0.2,1.5-0.1l11.6-6.7C617.5,125.1,617.5,124.7,617.1,124.4z"
                          />
                        </g>
                        <g id="XMLID_15202_">
                          <path
                            id="XMLID_15203_"
                            d="M604.6,132.6c-0.3,0-0.6-0.1-0.9-0.2l-11.7-6.8c-0.3-0.2-0.5-0.4-0.5-0.7
                      c0-0.3,0.2-0.6,0.6-0.9l11.6-6.7c0.6-0.3,1.4-0.4,1.9-0.1l11.7,6.8c0.3,0.2,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.9
                      l-11.6,6.7C605.3,132.5,604.9,132.6,604.6,132.6z M592.3,124.8C592.3,124.8,592.3,124.8,592.3,124.8l11.8,6.8
                      c0.2,0.1,0.8,0.1,1.1-0.1l11.6-6.7c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0l0,0l-11.7-6.8c-0.2-0.1-0.8-0.1-1.1,0.1
                      l-11.6,6.7C592.4,124.8,592.3,124.8,592.3,124.8z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_15197_">
                      <g id="XMLID_15198_">
                        <rect
                          id="XMLID_15199_"
                          x="592.5"
                          y="128"
                          transform="matrix(3.012133e-02 -0.9995 0.9995 3.012133e-02 448.0655 718.6391)"
                          width="3.7"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_15194_">
                      <g id="XMLID_15195_">
                        <rect
                          id="XMLID_15196_"
                          x="612.4"
                          y="128.7"
                          transform="matrix(1.471811e-02 -0.9999 0.9999 1.471811e-02 476.0693 741.0933)"
                          style="fill: #ffffff"
                          width="3.3"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_15153_">
                <g id="XMLID_15162_">
                  <g id="XMLID_15184_">
                    <g id="XMLID_15189_">
                      <path
                        id="XMLID_15190_"
                        style="fill: #ffffff"
                        d="M608,120.2l-6.9-0.1l0.9,4.1c0,0,0.3,0.6,0.7,0.9c1,0.6,2.7,0.6,3.8,0
                  c0.4-0.2,0.7-0.9,0.7-0.9L608,120.2z"
                      />
                    </g>
                    <g id="XMLID_15185_">
                      <path
                        id="XMLID_15186_"
                        d="M604.6,125.9c-0.8,0-1.5-0.2-2.1-0.5c-0.5-0.3-0.9-1-0.9-1l0-0.1l-1-4.6l8,0.1l-0.9,4.6
                  c0,0.1-0.4,0.7-0.9,1C606.1,125.7,605.4,125.9,604.6,125.9z M602.4,124c0.1,0.2,0.3,0.5,0.6,0.7c0.9,0.5,2.4,0.5,3.3,0
                  c0.2-0.1,0.5-0.5,0.6-0.6l0.7-3.4l-5.9-0.1L602.4,124z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_15177_">
                    <g id="XMLID_15182_">
                      <path
                        id="XMLID_15183_"
                        style="fill: #ffffff"
                        d="M608.7,118.8l0-2.1c0-0.1-0.1-0.1-0.1-0.1h-3c-0.6-0.1-1.3-0.1-2,0h-3
                  c-0.1,0-0.1,0.1-0.1,0.1l0,2.1c0,0.8,0.7,1.7,2.3,2.2c1.2,0.4,2.5,0.4,3.6,0C608,120.5,608.8,119.7,608.7,118.8z"
                      />
                    </g>
                    <g id="XMLID_15178_">
                      <path
                        id="XMLID_15179_"
                        d="M604.6,121.7c-0.7,0-1.3-0.1-1.9-0.3c-1.6-0.5-2.6-1.5-2.6-2.6l0-2.1c0-0.1,0.1-0.3,0.2-0.4
                  c0.1-0.1,0.2-0.2,0.4-0.2h3c0.6-0.1,1.3-0.1,2,0l3,0c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4l0,2.1
                  c0,1.1-0.9,2.1-2.6,2.6C606,121.6,605.3,121.7,604.6,121.7z M601,117l0,1.8c0,0.7,0.7,1.4,2,1.8c1.1,0.3,2.3,0.3,3.4,0
                  c1.2-0.4,2-1,2-1.8l0,0l0-1.8h-2.7c-0.7-0.1-1.3-0.1-1.9,0L601,117z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_15170_">
                    <g id="XMLID_15175_">
                      <path
                        id="XMLID_15176_"
                        style="fill: #ffffff"
                        d="M607.6,114.8c1.6,0.9,1.6,2.4,0,3.4c-1.6,0.9-4.2,0.9-5.8,0
                  s-1.6-2.4,0-3.4C603.3,113.9,605.9,113.9,607.6,114.8z"
                      />
                    </g>
                    <g id="XMLID_15171_">
                      <path
                        id="XMLID_15172_"
                        d="M604.6,119.3c-1.1,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.4-1.2-1.4-2c0-0.8,0.5-1.5,1.4-2
                  c1.7-1,4.5-1,6.2,0l0,0c0.9,0.5,1.4,1.2,1.4,2c0,0.8-0.5,1.5-1.4,2C606.9,119,605.8,119.3,604.6,119.3z M604.6,114.5
                  c-1,0-2,0.2-2.7,0.6c-0.6,0.4-1,0.8-1,1.3c0,0.5,0.4,0.9,1,1.3c1.5,0.9,3.9,0.9,5.4,0c0.6-0.4,1-0.8,1-1.3
                  c0-0.5-0.4-0.9-1-1.3l0,0C606.6,114.7,605.6,114.5,604.6,114.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_15163_">
                    <g id="XMLID_15168_">
                      <path
                        id="XMLID_15169_"
                        d="M607.6,116.8c-1.6-0.9-4.2-0.9-5.8,0c-0.3,0.2-0.6,0.4-0.8,0.7c0.2,0.3,0.5,0.5,0.9,0.7
                  c1.6,0.9,4.2,0.9,5.8,0c0.3-0.2,0.6-0.4,0.8-0.7C608.2,117.2,607.9,117,607.6,116.8z"
                      />
                    </g>
                    <g id="XMLID_15164_">
                      <path
                        id="XMLID_15165_"
                        d="M604.7,119.3c-1.1,0-2.2-0.3-3.1-0.8c-0.4-0.2-0.7-0.5-1-0.8l-0.2-0.3l0.2-0.3
                  c0.2-0.3,0.5-0.5,0.9-0.8c1.7-1,4.5-1,6.2,0l0,0c0.4,0.2,0.7,0.5,1,0.8l0.2,0.3l-0.2,0.3c-0.2,0.3-0.5,0.5-0.9,0.8
                  C607,119,605.8,119.3,604.7,119.3z M601.5,117.4c0.1,0.1,0.3,0.3,0.5,0.4c1.5,0.9,3.9,0.9,5.4,0c0.2-0.1,0.3-0.2,0.4-0.3
                  c-0.1-0.1-0.3-0.3-0.5-0.4l0,0c-1.5-0.9-3.9-0.9-5.4,0C601.8,117.2,601.6,117.3,601.5,117.4z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_15154_">
                  <g id="XMLID_15155_">
                    <g id="XMLID_15160_">
                      <path
                        id="XMLID_15161_"
                        style="fill: #ffffff"
                        d="M604.7,108.8c0,0-2.2,0.5-2.2,4c0,3.4,2.2,4,2.2,4s2.2-0.5,2.2-4
                  C606.9,109.3,604.7,108.8,604.7,108.8z"
                      />
                    </g>
                    <g id="XMLID_15156_">
                      <path
                        id="XMLID_15157_"
                        d="M604.7,117.2L604.7,117.2c-0.2,0-2.6-0.7-2.6-4.4c0-3.7,2.4-4.4,2.5-4.4l0.1,0l0.1,0
                  c0.1,0,2.5,0.7,2.5,4.4C607.3,116.5,604.9,117.2,604.7,117.2L604.7,117.2z M604.7,109.2c-0.4,0.2-1.8,0.9-1.8,3.6
                  c0,2.7,1.4,3.4,1.8,3.6c0.4-0.1,1.8-0.9,1.8-3.6C606.5,110.1,605.1,109.4,604.7,109.2z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_5255_">
            <g id="XMLID_7728_">
              <g id="XMLID_7758_">
                <g id="XMLID_7909_">
                  <path
                    id="XMLID_7910_"
                    style="fill: #ffffff"
                    d="M379.4,158.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C379.5,158.5,379.5,158.7,379.4,158.8z"
                  />
                </g>
                <g id="XMLID_7906_">
                  <path
                    id="XMLID_7908_"
                    style="fill: #ffffff"
                    d="M375.1,161.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C375.3,161,375.3,161.2,375.1,161.3z"
                  />
                </g>
                <g id="XMLID_7904_">
                  <path
                    id="XMLID_7905_"
                    style="fill: #ffffff"
                    d="M383.4,161.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C383.5,160.9,383.6,161,383.4,161.1z"
                  />
                </g>
                <g id="XMLID_7901_">
                  <path
                    id="XMLID_7902_"
                    style="fill: #ffffff"
                    d="M379.1,163.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C379.3,163.3,379.3,163.5,379.1,163.6z"
                  />
                </g>
                <g id="XMLID_7898_">
                  <path
                    id="XMLID_7899_"
                    style="fill: #ffffff"
                    d="M387.6,163.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C387.8,163.3,387.8,163.5,387.6,163.6z"
                  />
                </g>
                <g id="XMLID_7895_">
                  <path
                    id="XMLID_7897_"
                    style="fill: #ffffff"
                    d="M383.4,166l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C383.6,165.8,383.6,165.9,383.4,166z"
                  />
                </g>
                <g id="XMLID_7892_">
                  <path
                    id="XMLID_7894_"
                    style="fill: #ffffff"
                    d="M375.1,156.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C375.3,156.1,375.3,156.3,375.1,156.4z"
                  />
                </g>
                <g id="XMLID_7890_">
                  <path
                    id="XMLID_7891_"
                    style="fill: #ffffff"
                    d="M391.7,165.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C391.8,165.6,391.8,165.8,391.7,165.9z"
                  />
                </g>
                <g id="XMLID_7887_">
                  <path
                    id="XMLID_7888_"
                    style="fill: #ffffff"
                    d="M395.7,168.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C395.8,168,395.9,168.1,395.7,168.2z"
                  />
                </g>
                <g id="XMLID_7884_">
                  <path
                    id="XMLID_7886_"
                    style="fill: #ffffff"
                    d="M399.9,170.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C400.1,170.4,400.1,170.6,399.9,170.7z"
                  />
                </g>
                <g id="XMLID_7881_">
                  <path
                    id="XMLID_7883_"
                    style="fill: #ffffff"
                    d="M403.9,173l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C404.1,172.7,404.1,172.9,403.9,173z"
                  />
                </g>
                <g id="XMLID_7879_">
                  <path
                    id="XMLID_7880_"
                    style="fill: #ffffff"
                    d="M408,175.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C408.1,175.1,408.1,175.2,408,175.3z"
                  />
                </g>
                <g id="XMLID_7877_">
                  <path
                    id="XMLID_7878_"
                    style="fill: #ffffff"
                    d="M412.2,177.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C412.4,177.5,412.4,177.7,412.2,177.8z"
                  />
                </g>
                <g id="XMLID_7874_">
                  <path
                    id="XMLID_7875_"
                    style="fill: #ffffff"
                    d="M416.2,180.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C416.4,179.8,416.4,180,416.2,180.1z"
                  />
                </g>
                <g id="XMLID_7871_">
                  <path
                    id="XMLID_7873_"
                    style="fill: #ffffff"
                    d="M420.3,182.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C420.4,182.2,420.4,182.3,420.3,182.4z"
                  />
                </g>
                <g id="XMLID_7868_">
                  <path
                    id="XMLID_7870_"
                    style="fill: #ffffff"
                    d="M424.5,184.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C424.7,184.6,424.7,184.8,424.5,184.9z"
                  />
                </g>
                <g id="XMLID_7866_">
                  <path
                    id="XMLID_7867_"
                    style="fill: #ffffff"
                    d="M428.5,187.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C428.7,186.9,428.7,187.1,428.5,187.2z"
                  />
                </g>
                <g id="XMLID_7863_">
                  <path
                    id="XMLID_7864_"
                    style="fill: #ffffff"
                    d="M432.6,189.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C432.7,189.3,432.7,189.4,432.6,189.5z"
                  />
                </g>
                <g id="XMLID_7860_">
                  <path
                    id="XMLID_7861_"
                    style="fill: #ffffff"
                    d="M436.8,192l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C437,191.7,437,191.9,436.8,192z"
                  />
                </g>
                <g id="XMLID_7857_">
                  <path
                    id="XMLID_7859_"
                    style="fill: #ffffff"
                    d="M440.8,194.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C441,194,441,194.2,440.8,194.3z"
                  />
                </g>
                <g id="XMLID_7855_">
                  <path
                    id="XMLID_7856_"
                    style="fill: #ffffff"
                    d="M444.8,196.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C445,196.4,445,196.5,444.8,196.6z"
                  />
                </g>
                <g id="XMLID_7852_">
                  <path
                    id="XMLID_7853_"
                    style="fill: #ffffff"
                    d="M449.1,199.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C449.3,198.8,449.3,199,449.1,199.1z"
                  />
                </g>
                <g id="XMLID_7849_">
                  <path
                    id="XMLID_7850_"
                    style="fill: #ffffff"
                    d="M444.9,201.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C445,201.3,445,201.4,444.9,201.5z"
                  />
                </g>
                <g id="XMLID_7847_">
                  <path
                    id="XMLID_7848_"
                    style="fill: #ffffff"
                    d="M453.1,201.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C453.3,201.1,453.3,201.3,453.1,201.4z"
                  />
                </g>
                <g id="XMLID_7844_">
                  <path
                    id="XMLID_7845_"
                    style="fill: #ffffff"
                    d="M448.9,203.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C449,203.6,449.1,203.8,448.9,203.9z"
                  />
                </g>
                <g id="XMLID_7841_">
                  <path
                    id="XMLID_7843_"
                    style="fill: #ffffff"
                    d="M457.1,203.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C457.3,203.4,457.3,203.6,457.1,203.7z"
                  />
                </g>
                <g id="XMLID_7838_">
                  <path
                    id="XMLID_7840_"
                    style="fill: #ffffff"
                    d="M452.9,206.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C453.1,205.9,453.1,206.1,452.9,206.2z"
                  />
                </g>
                <g id="XMLID_7836_">
                  <path
                    id="XMLID_7837_"
                    style="fill: #ffffff"
                    d="M461.4,206.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C461.6,205.9,461.6,206.1,461.4,206.2z"
                  />
                </g>
                <g id="XMLID_7833_">
                  <path
                    id="XMLID_7835_"
                    style="fill: #ffffff"
                    d="M457.2,208.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C457.3,208.4,457.3,208.5,457.2,208.6z"
                  />
                </g>
                <g id="XMLID_7830_">
                  <path
                    id="XMLID_7832_"
                    style="fill: #ffffff"
                    d="M465.4,208.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C465.6,208.2,465.6,208.4,465.4,208.5z"
                  />
                </g>
                <g id="XMLID_7828_">
                  <path
                    id="XMLID_7829_"
                    style="fill: #ffffff"
                    d="M461.2,211l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C461.3,210.7,461.4,210.9,461.2,211z"
                  />
                </g>
                <g id="XMLID_7825_">
                  <path
                    id="XMLID_7826_"
                    style="fill: #ffffff"
                    d="M469.4,210.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C469.6,210.5,469.6,210.7,469.4,210.8z"
                  />
                </g>
                <g id="XMLID_7822_">
                  <path
                    id="XMLID_7824_"
                    style="fill: #ffffff"
                    d="M465.2,213.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C465.4,213,465.4,213.2,465.2,213.3z"
                  />
                </g>
                <g id="XMLID_7819_">
                  <path
                    id="XMLID_7821_"
                    style="fill: #ffffff"
                    d="M473.7,213.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C473.8,213,473.9,213.2,473.7,213.3z"
                  />
                </g>
                <g id="XMLID_7817_">
                  <path
                    id="XMLID_7818_"
                    style="fill: #ffffff"
                    d="M469.5,215.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C469.6,215.4,469.6,215.6,469.5,215.7z"
                  />
                </g>
                <g id="XMLID_7814_">
                  <path
                    id="XMLID_7816_"
                    style="fill: #ffffff"
                    d="M477.9,210.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C478.1,210.6,478.1,210.7,477.9,210.8z"
                  />
                </g>
                <g id="XMLID_7812_">
                  <path
                    id="XMLID_7813_"
                    style="fill: #ffffff"
                    d="M482.2,208.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C482.3,208.1,482.3,208.3,482.2,208.4z"
                  />
                </g>
                <g id="XMLID_7809_">
                  <path
                    id="XMLID_7810_"
                    style="fill: #ffffff"
                    d="M486.4,205.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C486.5,205.7,486.6,205.8,486.4,205.9z"
                  />
                </g>
                <g id="XMLID_7806_">
                  <path
                    id="XMLID_7807_"
                    style="fill: #ffffff"
                    d="M490.6,203.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C490.8,203.2,490.8,203.4,490.6,203.5z"
                  />
                </g>
                <g id="XMLID_7803_">
                  <path
                    id="XMLID_7805_"
                    style="fill: #ffffff"
                    d="M494.9,201.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C495,200.8,495,201,494.9,201.1z"
                  />
                </g>
                <g id="XMLID_7800_">
                  <path
                    id="XMLID_7802_"
                    style="fill: #ffffff"
                    d="M499.1,198.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C499.3,198.3,499.3,198.5,499.1,198.6z"
                  />
                </g>
                <g id="XMLID_7798_">
                  <path
                    id="XMLID_7799_"
                    style="fill: #ffffff"
                    d="M503.3,196.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C503.5,195.9,503.5,196.1,503.3,196.2z"
                  />
                </g>
                <g id="XMLID_7795_">
                  <path
                    id="XMLID_7796_"
                    style="fill: #ffffff"
                    d="M507.6,193.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C507.7,193.4,507.7,193.6,507.6,193.7z"
                  />
                </g>
                <g id="XMLID_7792_">
                  <path
                    id="XMLID_7793_"
                    style="fill: #ffffff"
                    d="M511.8,191.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C512,191,512,191.2,511.8,191.3z"
                  />
                </g>
                <g id="XMLID_7789_">
                  <path
                    id="XMLID_7791_"
                    style="fill: #ffffff"
                    d="M516,188.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C516.2,188.6,516.2,188.7,516,188.8z"
                  />
                </g>
                <g id="XMLID_7787_">
                  <path
                    id="XMLID_7788_"
                    style="fill: #ffffff"
                    d="M520.3,186.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C520.4,186.1,520.5,186.3,520.3,186.4z"
                  />
                </g>
                <g id="XMLID_7784_">
                  <path
                    id="XMLID_7785_"
                    style="fill: #ffffff"
                    d="M524.5,183.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C524.7,183.7,524.7,183.8,524.5,183.9z"
                  />
                </g>
                <g id="XMLID_7781_">
                  <path
                    id="XMLID_7783_"
                    style="fill: #ffffff"
                    d="M528.7,181.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C528.9,181.2,528.9,181.4,528.7,181.5z"
                  />
                </g>
                <g id="XMLID_7778_">
                  <path
                    id="XMLID_7780_"
                    style="fill: #ffffff"
                    d="M533,179.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C533.1,178.8,533.2,178.9,533,179.1z"
                  />
                </g>
                <g id="XMLID_7776_">
                  <path
                    id="XMLID_7777_"
                    style="fill: #ffffff"
                    d="M537.2,176.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C537.4,176.3,537.4,176.5,537.2,176.6z"
                  />
                </g>
                <g id="XMLID_7773_">
                  <path
                    id="XMLID_7774_"
                    style="fill: #ffffff"
                    d="M477.7,215.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C477.9,215.3,477.9,215.5,477.7,215.6z"
                  />
                </g>
                <g id="XMLID_7770_">
                  <path
                    id="XMLID_7771_"
                    style="fill: #ffffff"
                    d="M481.9,213.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C482.1,212.9,482.1,213.1,481.9,213.2z"
                  />
                </g>
                <g id="XMLID_7767_">
                  <path
                    id="XMLID_7769_"
                    style="fill: #ffffff"
                    d="M486.2,210.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C486.3,210.4,486.4,210.6,486.2,210.7z"
                  />
                </g>
                <g id="XMLID_7764_">
                  <path
                    id="XMLID_7766_"
                    style="fill: #ffffff"
                    d="M490.4,208.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C490.6,208,490.6,208.2,490.4,208.3z"
                  />
                </g>
                <g id="XMLID_7762_">
                  <path
                    id="XMLID_7763_"
                    style="fill: #ffffff"
                    d="M494.6,205.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C494.8,205.5,494.8,205.7,494.6,205.8z"
                  />
                </g>
                <g id="XMLID_7759_">
                  <path
                    id="XMLID_7760_"
                    style="fill: #ffffff"
                    d="M498.9,203.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C499,203.1,499.1,203.3,498.9,203.4z"
                  />
                </g>
              </g>
              <g id="XMLID_7730_">
                <g id="XMLID_7756_">
                  <path
                    id="XMLID_7757_"
                    style="fill: #ffffff"
                    d="M545.7,171.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C545.8,171.4,545.8,171.6,545.7,171.7z"
                  />
                </g>
                <g id="XMLID_7753_">
                  <path
                    id="XMLID_7754_"
                    style="fill: #ffffff"
                    d="M541.4,174.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C541.6,173.9,541.6,174.1,541.4,174.2z"
                  />
                </g>
                <g id="XMLID_7750_">
                  <path
                    id="XMLID_7752_"
                    style="fill: #ffffff"
                    d="M549.9,169.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C550.1,169,550.1,169.2,549.9,169.3z"
                  />
                </g>
                <g id="XMLID_7747_">
                  <path
                    id="XMLID_7749_"
                    style="fill: #ffffff"
                    d="M554.1,166.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C554.3,166.6,554.3,166.7,554.1,166.8z"
                  />
                </g>
                <g id="XMLID_7745_">
                  <path
                    id="XMLID_7746_"
                    style="fill: #ffffff"
                    d="M558.4,164.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C558.5,164.1,558.6,164.3,558.4,164.4z"
                  />
                </g>
                <g id="XMLID_7742_">
                  <path
                    id="XMLID_7743_"
                    style="fill: #ffffff"
                    d="M562.6,161.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C562.8,161.7,562.8,161.8,562.6,161.9z"
                  />
                </g>
                <g id="XMLID_7740_">
                  <path
                    id="XMLID_7741_"
                    style="fill: #ffffff"
                    d="M566.8,159.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C567,159.2,567,159.4,566.8,159.5z"
                  />
                </g>
                <g id="XMLID_7736_">
                  <path
                    id="XMLID_7738_"
                    style="fill: #ffffff"
                    d="M562.4,166.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C562.5,166.4,562.6,166.6,562.4,166.7z"
                  />
                </g>
                <g id="XMLID_7732_">
                  <path
                    id="XMLID_7734_"
                    style="fill: #ffffff"
                    d="M566.6,164.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C566.8,164,566.8,164.2,566.6,164.3z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_5257_">
              <g id="XMLID_7680_">
                <g id="XMLID_7682_">
                  <g id="XMLID_7698_">
                    <g id="XMLID_7714_">
                      <g id="XMLID_7724_">
                        <path
                          id="XMLID_7726_"
                          d="M443.7,22.7l-12.8-7.4c-0.6-0.3-1,0-1,0.8V22c0,0.8,0.5,1.7,1,2l1.5,0.9c0.1,0.6,0.6,2.4,2.3,3.3
                    c0.1,0,0.1,0,0.1-0.1c-0.1-0.4-0.4-1.2-0.2-1.9l9.2,5.3c0.6,0.3,1,0,1-0.8v-5.9C444.8,24,444.3,23.1,443.7,22.7z"
                        />
                      </g>
                      <g id="XMLID_7716_">
                        <path
                          id="XMLID_7718_"
                          d="M444.2,31.8c-0.2,0-0.4-0.1-0.6-0.2l-8.7-5.1c0,0.5,0.1,1.1,0.2,1.3c0.1,0.2,0,0.4-0.1,0.5
                    c-0.1,0.1-0.3,0.1-0.5,0c-1.6-0.9-2.2-2.6-2.4-3.4l-1.4-0.8c-0.7-0.4-1.2-1.4-1.2-2.3v-5.9c0-0.5,0.2-0.9,0.5-1.1
                    c0.3-0.2,0.6-0.1,1,0.1l12.8,7.4c0.7,0.4,1.2,1.4,1.2,2.3v5.9c0,0.5-0.2,0.9-0.5,1.1C444.5,31.8,444.3,31.8,444.2,31.8z
                     M434.4,25.6l9.5,5.5c0.1,0.1,0.3,0.1,0.4,0.1c0.1-0.1,0.2-0.3,0.2-0.6v-5.9c0-0.7-0.4-1.4-0.9-1.7l-12.8-7.4
                    c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0.1-0.2,0.3-0.2,0.6V22c0,0.7,0.4,1.4,0.9,1.7l1.6,0.9l0,0.1c0.1,0.6,0.5,1.9,1.6,2.7
                    c-0.1-0.4-0.1-1,0-1.5L434.4,25.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7700_">
                      <g id="XMLID_7708_">
                        <g id="XMLID_7710_">
                          <rect
                            id="XMLID_7712_"
                            x="437"
                            y="17.4"
                            transform="matrix(0.5 -0.866 0.866 0.5 199.2145 389.968)"
                            width="0.6"
                            height="10.1"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7702_">
                        <g id="XMLID_7704_">
                          <rect
                            id="XMLID_7706_"
                            x="437"
                            y="19.4"
                            transform="matrix(0.5 -0.866 0.866 0.5 197.4778 390.9707)"
                            width="0.6"
                            height="10.1"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_7684_">
                    <g id="XMLID_7694_">
                      <path
                        id="XMLID_7696_"
                        d="M433,6.9l-7.7-4.4c-0.6-0.3-1,0-1,0.8v2.6c0,0.8,0.5,1.7,1,2l1.8,1.1c0.1,0.7,0.6,2.4,2.3,3.3
                  c0.1,0,0.1,0,0.1-0.1c-0.1-0.4-0.4-1.2-0.2-1.9l3.7,2.1c0.6,0.3,1,0,1-0.8V8.9C434,8.1,433.6,7.2,433,6.9z"
                      />
                    </g>
                    <g id="XMLID_7686_">
                      <path
                        id="XMLID_7688_"
                        d="M433.4,12.7c-0.2,0-0.4-0.1-0.6-0.2l-3.3-1.8c0,0.5,0.1,1.1,0.2,1.3c0.1,0.2,0,0.4-0.1,0.5
                  c-0.1,0.1-0.3,0.1-0.5,0c-1.6-0.9-2.2-2.6-2.4-3.4l-1.7-1c-0.7-0.4-1.2-1.4-1.2-2.3V3.3c0-0.5,0.2-0.9,0.5-1.1
                  c0.3-0.2,0.6-0.1,1,0.1l7.7,4.4c0.7,0.4,1.2,1.4,1.2,2.3v2.6c0,0.5-0.2,0.9-0.5,1.1C433.7,12.6,433.5,12.7,433.4,12.7z
                   M429,9.7l4.1,2.3c0.1,0.1,0.3,0.1,0.4,0.1c0.1-0.1,0.2-0.3,0.2-0.6V8.9c0-0.7-0.4-1.4-0.9-1.7l-7.7-4.4
                  c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0.1-0.2,0.3-0.2,0.6v2.6c0,0.7,0.4,1.4,0.9,1.7l2,1.1l0,0.1c0.1,0.6,0.5,1.8,1.6,2.7
                  c-0.1-0.4-0.1-1,0-1.5L429,9.7z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_7557_">
                <g id="XMLID_7614_">
                  <g id="XMLID_7622_">
                    <g id="XMLID_7650_">
                      <g id="XMLID_7666_">
                        <g id="XMLID_7676_">
                          <path
                            id="XMLID_7678_"
                            style="fill: #ffffff"
                            d="M508,89.9C508,89.9,508,89.8,508,89.9v-8.4l-9.3,0.2l-14.1-8
                      c-3.8-2.2-8.4-2.2-12.1,0l-7.9,4.7l-15.6,2.9v8.4c-0.1,1.7,0.6,3.5,2.3,4.5l21,12c3.8,2.2,8.4,2.2,12.1,0L505.6,94
                      C507.1,93.1,507.9,91.5,508,89.9L508,89.9L508,89.9z"
                          />
                        </g>
                        <g id="XMLID_7668_">
                          <path
                            id="XMLID_7670_"
                            d="M478.3,108.5c-2.2,0-4.4-0.6-6.3-1.7l-21-12c-1.8-1-2.8-3-2.6-5l0-8.9l16-3l7.8-4.7
                      c3.9-2.3,8.8-2.3,12.7,0l13.9,7.9l9.7-0.2v9.5l0,0c-0.2,1.7-1.1,3.1-2.6,4l-21.2,12.4
                      C482.7,107.9,480.5,108.5,478.3,108.5z M449.5,81.9v8c-0.1,1.7,0.7,3.2,2.1,4l21,12c3.6,2.1,8,2.1,11.6,0l21.2-12.4
                      c1.3-0.7,2.1-2.1,2.1-3.5l0-0.2v-7.7l-8.9,0.2l-14.2-8c-3.6-2.1-8-2.1-11.6,0l-8,4.8L449.5,81.9z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7652_">
                        <g id="XMLID_7662_">
                          <path
                            id="XMLID_7664_"
                            style="fill: #ffffff"
                            d="M451.3,85.9l21,12c3.8,2.2,8.4,2.2,12.1,0l21.2-12.4
                      c3.2-1.8,3.2-6.4,0-8.2l-21-12c-3.8-2.2-8.4-2.2-12.1,0l-21.2,12.4C448.1,79.6,448.1,84.1,451.3,85.9z"
                          />
                        </g>
                        <g id="XMLID_7654_">
                          <path
                            id="XMLID_7656_"
                            d="M478.3,100.1c-2.2,0-4.4-0.6-6.3-1.7l-21-12c-1.7-1-2.6-2.7-2.6-4.6c0-1.9,1-3.6,2.6-4.6
                      l21.2-12.4c3.9-2.3,8.8-2.3,12.7,0l21,12c1.7,1,2.6,2.7,2.6,4.6c0,1.9-1,3.6-2.6,4.6l-21.2,12.4
                      C482.7,99.5,480.5,100.1,478.3,100.1z M451.5,85.5l21,12c3.6,2.1,8,2.1,11.6,0l21.2-12.4c1.3-0.8,2.1-2.1,2.1-3.6
                      c0-1.5-0.8-2.9-2.1-3.6l-21-12c-3.6-2.1-8-2.1-11.6,0l-21.2,12.4c-1.3,0.8-2.1,2.1-2.1,3.6
                      C449.4,83.4,450.2,84.7,451.5,85.5z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_7624_">
                      <g id="XMLID_7644_">
                        <g id="XMLID_7646_">
                          <rect
                            id="XMLID_7648_"
                            x="453.1"
                            y="87.2"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7638_">
                        <g id="XMLID_7640_">
                          <rect
                            id="XMLID_7642_"
                            x="477.5"
                            y="99.3"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7632_">
                        <g id="XMLID_7634_">
                          <rect
                            id="XMLID_7636_"
                            x="482.9"
                            y="98.1"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7626_">
                        <g id="XMLID_7628_">
                          <rect
                            id="XMLID_7630_"
                            x="503.2"
                            y="86.3"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_7616_">
                    <g id="XMLID_7618_">
                      <polygon
                        id="XMLID_7620_"
                        points="485.4,97.3 485.4,105.7 502,96.1 502,87.6                "
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_7559_">
                  <g id="XMLID_7564_">
                    <g id="XMLID_7585_">
                      <g id="XMLID_7600_">
                        <g id="XMLID_7610_">
                          <path
                            id="XMLID_7612_"
                            style="fill: #ffffff"
                            d="M567.7,150.5C567.7,150.5,567.7,150.5,567.7,150.5v-8.4l-9.3,0.2
                      l-85.5-48.9c-3.8-2.2-8.4-2.2-12.1,0l-73.5,43.2l-15.6,2.9v8.4c-0.1,1.7,0.6,3.5,2.3,4.5l92.4,52.9
                      c3.8,2.2,8.4,2.2,12.1,0l86.8-50.9C566.9,153.7,567.7,152.2,567.7,150.5L567.7,150.5L567.7,150.5z"
                          />
                        </g>
                        <g id="XMLID_7602_">
                          <path
                            id="XMLID_7604_"
                            d="M472.5,207.6c-2.2,0-4.4-0.6-6.3-1.7L373.7,153c-1.8-1-2.8-3-2.6-5l0-8.9l16-3L460.5,93
                      c3.9-2.3,8.8-2.3,12.7,0l85.4,48.8l9.7-0.2v9.5l0,0c-0.2,1.7-1.1,3.1-2.6,4l-86.8,50.9C476.9,207,474.7,207.6,472.5,207.6
                      z M372.2,140.1v8c-0.1,1.7,0.7,3.2,2.1,4l92.4,52.9c3.6,2.1,8,2.1,11.6,0l86.8-50.9c1.3-0.7,2.1-2.1,2.1-3.5l0-0.2v-7.7
                      l-8.9,0.2L472.7,94c-3.6-2.1-8-2.1-11.6,0l-73.6,43.2L372.2,140.1z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7587_">
                        <g id="XMLID_7597_">
                          <path
                            id="XMLID_7598_"
                            style="fill: #ffffff"
                            d="M374,144.1l92.4,52.9c3.8,2.2,8.4,2.2,12.1,0l86.8-50.9
                      c3.2-1.8,3.2-6.4,0-8.2l-92.4-52.9c-3.8-2.2-8.4-2.2-12.1,0L374,135.9C370.8,137.8,370.8,142.3,374,144.1z"
                          />
                        </g>
                        <g id="XMLID_7589_">
                          <path
                            id="XMLID_7591_"
                            d="M472.5,199.2c-2.2,0-4.4-0.6-6.3-1.7l-92.4-52.9c-1.7-1-2.6-2.7-2.6-4.6c0-1.9,1-3.6,2.6-4.6
                      l86.8-50.9c3.9-2.3,8.8-2.3,12.7,0l92.4,52.9c1.7,1,2.6,2.7,2.6,4.6c0,1.9-1,3.6-2.6,4.6l-86.8,50.9
                      C476.9,198.6,474.7,199.2,472.5,199.2z M374.2,143.7l92.4,52.9c3.6,2.1,8,2.1,11.6,0l86.8-50.9c1.3-0.8,2.1-2.1,2.1-3.6
                      c0-1.5-0.8-2.9-2.1-3.6l-92.4-52.9c-3.6-2.1-8-2.1-11.6,0l-86.8,50.9c-1.3,0.8-2.1,2.1-2.1,3.6
                      C372.2,141.5,372.9,142.9,374.2,143.7L374.2,143.7z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_7566_">
                      <g id="XMLID_7579_">
                        <g id="XMLID_7581_">
                          <rect
                            id="XMLID_7583_"
                            x="375.8"
                            y="145.4"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7575_">
                        <g id="XMLID_7577_">
                          <rect
                            id="XMLID_7578_"
                            x="471.7"
                            y="198.4"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7571_">
                        <g id="XMLID_7573_">
                          <rect
                            id="XMLID_7574_"
                            x="477.1"
                            y="197.2"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7567_">
                        <g id="XMLID_7568_">
                          <rect
                            id="XMLID_7570_"
                            x="562.9"
                            y="147"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_7560_">
                    <g id="XMLID_7562_">
                      <polygon
                        id="XMLID_7563_"
                        points="479.6,196.4 479.6,204.8 561.7,156.7 561.7,148.3                 "
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_7347_">
                <g id="XMLID_7475_">
                  <g id="XMLID_7482_">
                    <g id="XMLID_7517_">
                      <g id="XMLID_7548_">
                        <g id="XMLID_7554_">
                          <path
                            id="XMLID_7556_"
                            style="fill: #ffffff"
                            d="M400.2,135.8c0,0,0-8,0-8.4c0,0,0-0.1-0.1-0.1l-9.1,0.8c0,0,0,0,0,0
                      l-0.8-5c0,0-0.1,0-0.1,0l-8.4,4.8c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,8.2,0,8.3l0,0c0,0.3,0.2,0.5,0.4,0.7
                      l8.4,4.9c0.7,0.4,1.6,0.4,2.3,0l8.6-4.9C400.1,136.4,400.2,136.1,400.2,135.8L400.2,135.8z"
                          />
                        </g>
                        <g id="XMLID_7549_">
                          <path
                            id="XMLID_7550_"
                            d="M390,142.2c-0.5,0-1-0.1-1.4-0.4l-8.4-4.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-5.9l0-2.5
                      c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l8.3-4.7c0.2-0.1,0.3-0.1,0.5,0l0.2,0.1l0.8,4.8l8.8-0.8l0.1,0
                      c0.2,0.1,0.4,0.2,0.4,0.5c0,0.1,0,8.4,0,8.4l0,0c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C391,142.1,390.5,142.2,390,142.2z
                       M380.4,128.1l0,2c0,2.3,0,5.7,0,5.8c0,0.1,0.1,0.2,0.2,0.3l8.4,4.9c0.6,0.3,1.3,0.3,1.9,0l8.6-4.9
                      c0.2-0.1,0.2-0.3,0.2-0.4l0.4,0l-0.4,0c0-0.1,0-6.4,0-8l-8.8,0.8l-0.2-0.1l-0.2-0.1l-0.8-4.7l-8.1,4.6l-0.2,0L380.4,128.1
                      z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7538_">
                        <g id="XMLID_7545_">
                          <path
                            id="XMLID_7546_"
                            d="M400.2,127.4l-9.1,0.8c0,0,0,0,0,0l-1.1,3.1l0,10.5c0.4,0,0.8-0.1,1.2-0.3l8.6-4.9
                      c0.3-0.2,0.4-0.5,0.4-0.7l0,0C400.2,135.8,400.2,127.8,400.2,127.4C400.2,127.4,400.2,127.4,400.2,127.4z"
                          />
                        </g>
                        <g id="XMLID_7540_">
                          <path
                            id="XMLID_7541_"
                            d="M390.1,142.2C390,142.2,390,142.2,390.1,142.2l-0.5,0l0-11l1.2-3.6l0.3,0.2l9-0.8l0.1,0
                      c0.2,0.1,0.4,0.2,0.4,0.5c0,0.1,0,8.4,0,8.4c0,0.5-0.3,0.9-0.7,1.1l-8.6,4.9C391,142.1,390.5,142.2,390.1,142.2z
                       M390.4,131.4l0,10c0.2,0,0.4-0.1,0.6-0.2l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-6.4,0-8l-8.5,0.8L390.4,131.4z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7529_">
                        <g id="XMLID_7535_">
                          <path
                            id="XMLID_7537_"
                            d="M381.3,128.7c0,0,0.1-0.3,0.3-0.8l-1.5-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,8.2,0,8.3l0,0
                      c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5l0-6.3V128.7z"
                          />
                        </g>
                        <g id="XMLID_7530_">
                          <path
                            id="XMLID_7531_"
                            d="M381.7,137.8l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-5.9l0-2.5c0-0.1,0.1-0.3,0.2-0.4
                      c0.1-0.1,0.3-0.1,0.4-0.1l1.9,0.5L382,128c-0.1,0.3-0.2,0.6-0.3,0.7L381.7,137.8z M380.4,128l0,2c0,2.3,0,5.7,0,5.8
                      c0,0.1,0.1,0.2,0.2,0.3l0.2,0.1l0-7.8c0,0,0.1-0.1,0.1-0.4L380.4,128z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7518_">
                        <g id="XMLID_7526_">
                          <path
                            id="XMLID_7527_"
                            style="fill: #ffffff"
                            d="M388.9,133.1l-8.5-4.9c-0.6-0.3-0.6-1.2,0-1.6l8.6-4.9
                      c0.7-0.4,1.6-0.4,2.2,0l8.5,4.9c0.6,0.3,0.6,1.2,0,1.6l-8.6,4.9C390.5,133.6,389.6,133.6,388.9,133.1z"
                          />
                        </g>
                        <g id="XMLID_7520_">
                          <path
                            id="XMLID_7521_"
                            d="M390.1,133.9c-0.5,0-0.9-0.1-1.3-0.4l-8.5-4.9c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                      l8.6-4.9c0.8-0.5,1.8-0.5,2.7,0l8.5,4.9c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-8.6,4.9
                      C391,133.7,390.5,133.9,390.1,133.9z M390.2,121.9c-0.3,0-0.6,0.1-0.9,0.2l-8.6,4.9c-0.2,0.1-0.2,0.3-0.2,0.4
                      c0,0.1,0,0.3,0.2,0.4l8.5,4.9c0.6,0.3,1.3,0.3,1.8,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.4l-8.5-4.9
                      C390.8,121.9,390.5,121.9,390.2,121.9z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_7483_">
                      <g id="XMLID_7508_">
                        <g id="XMLID_7515_">
                          <path
                            id="XMLID_7516_"
                            style="fill: #ffffff"
                            d="M389.3,130.6l-4.7-2.7c-0.3-0.2-0.3-0.7,0-0.9l4.8-2.7
                      c0.4-0.2,0.9-0.2,1.3,0l4.7,2.7c0.3,0.2,0.3,0.7,0,0.9l-4.8,2.7C390.2,130.8,389.7,130.8,389.3,130.6z"
                          />
                        </g>
                        <g id="XMLID_7509_">
                          <path
                            id="XMLID_7511_"
                            d="M389.9,131.1c-0.3,0-0.6-0.1-0.8-0.2l-4.7-2.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8
                      l4.8-2.8c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-4.8,2.7
                      C390.5,131.1,390.2,131.1,389.9,131.1z M390,124.5c-0.1,0-0.3,0-0.4,0.1l-4.8,2.8c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1l4.7,2.7
                      c0.3,0.1,0.6,0.2,0.8,0l4.8-2.7c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l-4.7-2.7C390.3,124.5,390.2,124.5,390,124.5z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7498_">
                        <g id="XMLID_7505_">
                          <path
                            id="XMLID_7507_"
                            style="fill: #ffffff"
                            d="M384.6,127.8l1,0.6l3.8-2.2c0.4-0.2,0.9-0.2,1.3,0l3.7,2.1l1-0.6
                      c0.3-0.2,0.3-0.7,0-0.9l-4.7-2.7c-0.4-0.2-0.9-0.2-1.3,0l-4.8,2.7C384.3,127.1,384.3,127.6,384.6,127.8z"
                          />
                        </g>
                        <g id="XMLID_7500_">
                          <path
                            id="XMLID_7501_"
                            d="M385.6,128.9l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.8
                      c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-1.2,0.7l-3.9-2.3c-0.3-0.2-0.6-0.2-0.8,0
                      L385.6,128.9z M384.8,127.5l0.8,0.5l3.6-2c0.5-0.3,1.2-0.3,1.7,0l3.5,2l0.8-0.5c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l-4.7-2.7
                      c-0.3-0.2-0.6-0.2-0.8,0l-4.8,2.8C384.8,127.3,384.8,127.4,384.8,127.5C384.8,127.4,384.8,127.4,384.8,127.5L384.8,127.5z
                      "
                          />
                        </g>
                      </g>
                      <g id="XMLID_7488_">
                        <g id="XMLID_7495_">
                          <path
                            id="XMLID_7497_"
                            d="M390,124c-0.2,0-0.4,0.1-0.6,0.2l-4.8,2.7c-0.3,0.2-0.3,0.7,0,0.9l1,0.6l3.8-2.2
                      c0.2-0.1,0.4-0.2,0.6-0.2V124z"
                          />
                        </g>
                        <g id="XMLID_7490_">
                          <path
                            id="XMLID_7491_"
                            d="M385.6,128.9l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.7
                      c0.2-0.1,0.5-0.2,0.8-0.2l0.4,0v2.9l-0.4,0c-0.1,0-0.3,0-0.4,0.1L385.6,128.9z M389.6,124.6l-4.8,2.7c0,0,0,0,0,0.1
                      c0,0,0,0.1,0,0.1l0.8,0.5l3.6-2c0.1-0.1,0.2-0.1,0.4-0.2V124.6z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7484_">
                        <g id="XMLID_7486_">
                          <rect
                            id="XMLID_7487_"
                            x="393.5"
                            y="126.1"
                            width="0.8"
                            height="1.9"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_7476_">
                    <g id="XMLID_7478_">
                      <g id="XMLID_7479_">
                        <rect
                          id="XMLID_7480_"
                          x="395.7"
                          y="133.4"
                          transform="matrix(0.5577 -0.8301 0.8301 0.5577 64.8795 389.2579)"
                          style="fill: #ffffff"
                          width="3.9"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_7348_">
                  <g id="XMLID_7465_">
                    <g id="XMLID_7471_">
                      <g id="XMLID_7472_">
                        <path
                          id="XMLID_7473_"
                          d="M391.1,128.1l-1.9,0l0.1-4.7c0-3.4,2.8-6.1,6.2-6.1v1.9c-2.3,0-4.3,1.9-4.3,4.2L391.1,128.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7466_">
                      <g id="XMLID_7468_">
                        <rect
                          id="XMLID_7469_"
                          x="388.5"
                          y="116.8"
                          transform="matrix(0.6924 -0.7215 0.7215 0.6924 33.8839 317.5958)"
                          width="1.9"
                          height="4.5"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_7349_">
                    <g id="XMLID_7455_">
                      <g id="XMLID_7462_">
                        <path
                          id="XMLID_7464_"
                          style="fill: #ffffff"
                          d="M396.7,110.5c0,0,1.3-1.1,1-3.2c-0.2-1.6-1.6-3.2-2.2-3.9
                    c-0.1-0.1-0.4-0.1-0.4,0.1c-0.3,0.9-1,3-0.7,4.5C394.6,110,396.3,110.9,396.7,110.5z"
                        />
                      </g>
                      <g id="XMLID_7457_">
                        <path
                          id="XMLID_7458_"
                          d="M396.4,110.9c-0.1,0-0.2,0-0.3,0c-0.7-0.2-1.9-1.2-2.2-2.8c-0.3-1.6,0.4-3.8,0.7-4.7
                    c0.1-0.2,0.2-0.3,0.4-0.4c0.2,0,0.4,0,0.5,0.2c0.6,0.7,2.1,2.4,2.3,4.1c0.3,2.1-0.9,3.3-1.1,3.5
                    C396.8,110.8,396.7,110.9,396.4,110.9z M395.2,103.7c-0.3,0.9-1,2.9-0.7,4.2c0.2,1.1,0.9,1.8,1.4,2.1
                    c0.3,0.2,0.4,0.2,0.5,0.2l0,0c0,0,1.2-1,0.9-2.9C397.1,105.9,395.8,104.4,395.2,103.7z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7448_">
                      <g id="XMLID_7453_">
                        <path
                          id="XMLID_7454_"
                          style="fill: #ffffff"
                          d="M387.8,117.1c0,0,1.5-0.8,1.6-2.9c0.1-1.6-0.9-3.5-1.4-4.3
                    c-0.1-0.2-0.3-0.2-0.4,0c-0.5,0.8-1.6,2.7-1.6,4.3C385.9,116.1,387.4,117.4,387.8,117.1z"
                        />
                      </g>
                      <g id="XMLID_7449_">
                        <path
                          id="XMLID_7450_"
                          d="M387.7,117.5c-0.1,0-0.2,0-0.4-0.1c-0.7-0.3-1.6-1.5-1.6-3.2c0-1.6,1.2-3.6,1.7-4.4
                    c0.1-0.2,0.3-0.2,0.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.5,0.8,1.5,2.7,1.4,4.4c-0.1,2.1-1.6,3.1-1.8,3.2
                    C387.9,117.4,387.8,117.5,387.7,117.5z M387.8,110.1c-0.5,0.8-1.5,2.6-1.5,4c0,1.1,0.5,1.9,0.9,2.4
                    c0.2,0.2,0.4,0.3,0.4,0.3l0,0c0.1,0,1.3-0.8,1.5-2.7C389.2,112.7,388.3,110.9,387.8,110.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7441_">
                      <g id="XMLID_7446_">
                        <path
                          id="XMLID_7447_"
                          style="fill: #ffffff"
                          d="M387,114.8c0,0,0.6-1.6-0.7-3.3c-1-1.3-3-2-3.9-2.3
                    c-0.2-0.1-0.4,0.1-0.3,0.3c0.1,0.9,0.6,3.1,1.6,4.3C385,115.3,386.9,115.3,387,114.8z"
                        />
                      </g>
                      <g id="XMLID_7442_">
                        <path
                          id="XMLID_7443_"
                          d="M386.3,115.4c-0.8,0-2-0.4-2.8-1.4c-1.1-1.2-1.6-3.5-1.7-4.4c0-0.2,0-0.4,0.2-0.5
                    c0.2-0.1,0.4-0.2,0.6-0.1c0.9,0.3,3,1,4,2.4c1.3,1.7,0.9,3.3,0.8,3.5c0,0.1-0.2,0.4-0.6,0.5
                    C386.6,115.4,386.5,115.4,386.3,115.4z M382.4,109.6c0.2,0.9,0.6,3,1.5,4c0.7,0.8,1.6,1.1,2.3,1.1c0.3,0,0.5-0.1,0.5-0.1
                    l0,0c0-0.1,0.5-1.5-0.7-3C385.1,110.6,383.2,109.9,382.4,109.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7434_">
                      <g id="XMLID_7439_">
                        <path
                          id="XMLID_7440_"
                          style="fill: #ffffff"
                          d="M394.8,114.9c0,0,1.3-1.1,1-3.2c-0.2-1.6-1.6-3.2-2.2-3.9
                    c-0.1-0.1-0.4-0.1-0.4,0.1c-0.3,0.9-1,3-0.7,4.5C392.8,114.3,394.4,115.3,394.8,114.9z"
                        />
                      </g>
                      <g id="XMLID_7435_">
                        <path
                          id="XMLID_7436_"
                          d="M394.5,115.3c-0.1,0-0.2,0-0.3,0c-0.7-0.2-1.9-1.2-2.2-2.8c-0.3-1.6,0.4-3.8,0.7-4.7
                    c0.1-0.2,0.2-0.3,0.4-0.4c0.2,0,0.4,0,0.5,0.2c0.6,0.7,2.1,2.4,2.3,4.1c0.3,2.1-0.9,3.3-1.1,3.5
                    C394.9,115.2,394.8,115.3,394.5,115.3z M393.3,108.1c-0.3,0.9-1,2.9-0.7,4.2c0.2,1.1,0.9,1.8,1.4,2.1
                    c0.3,0.2,0.4,0.2,0.5,0.2l0,0c0,0,1.2-1,0.9-2.9C395.2,110.3,393.9,108.7,393.3,108.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7427_">
                      <g id="XMLID_7432_">
                        <path
                          id="XMLID_7433_"
                          style="fill: #ffffff"
                          d="M395.5,116.6c0,0,0.6-1.6-0.7-3.3c-1-1.3-3-2-3.9-2.3
                    c-0.2-0.1-0.4,0.1-0.3,0.3c0.1,0.9,0.6,3.1,1.6,4.3C393.5,117.1,395.4,117.1,395.5,116.6z"
                        />
                      </g>
                      <g id="XMLID_7428_">
                        <path
                          id="XMLID_7429_"
                          d="M394.7,117.2c-0.8,0-2-0.4-2.8-1.4c-1.1-1.2-1.6-3.5-1.7-4.4c0-0.2,0-0.4,0.2-0.5
                    c0.2-0.1,0.4-0.2,0.6-0.1c0.9,0.3,3,1,4,2.4c1.3,1.7,0.9,3.3,0.8,3.5c0,0.1-0.2,0.4-0.6,0.5
                    C395.1,117.2,394.9,117.2,394.7,117.2z M390.8,111.4c0.2,0.9,0.6,3,1.5,4c0.7,0.8,1.6,1.1,2.3,1.1c0.3,0,0.5-0.1,0.5-0.1
                    l0,0c0-0.1,0.5-1.5-0.7-3C393.6,112.4,391.7,111.7,390.8,111.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7420_">
                      <g id="XMLID_7425_">
                        <path
                          id="XMLID_7426_"
                          style="fill: #ffffff"
                          d="M396.8,112.7c0,0-1.2-1.2-0.8-3.3c0.4-1.6,1.9-3.1,2.5-3.7
                    c0.1-0.1,0.4-0.1,0.4,0.1c0.3,0.9,0.8,3.1,0.4,4.6C398.8,112.3,397.1,113.1,396.8,112.7z"
                        />
                      </g>
                      <g id="XMLID_7421_">
                        <path
                          id="XMLID_7422_"
                          d="M397,113.2c-0.3,0-0.4-0.1-0.5-0.2c-0.1-0.2-1.3-1.4-0.8-3.5c0.4-1.7,1.9-3.3,2.6-3.9
                    c0.1-0.1,0.4-0.2,0.5-0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.3,0.9,0.9,3.2,0.4,4.7c-0.4,1.6-1.7,2.5-2.4,2.6
                    C397.1,113.2,397.1,113.2,397,113.2z M397,112.5c0.1,0,0.2,0,0.5-0.2c0.6-0.3,1.2-1,1.5-2c0.4-1.4-0.1-3.4-0.4-4.3
                    c-0.6,0.6-2,2-2.3,3.5C395.9,111.4,397,112.5,397,112.5L397,112.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7413_">
                      <g id="XMLID_7418_">
                        <path
                          id="XMLID_7419_"
                          style="fill: #ffffff"
                          d="M397.8,114.8c0,0-0.6-1.6,0.7-3.3c1-1.3,3-2,3.9-2.3
                    c0.2-0.1,0.4,0.1,0.3,0.3c-0.1,0.9-0.6,3.1-1.6,4.3C399.9,115.3,397.9,115.3,397.8,114.8z"
                        />
                      </g>
                      <g id="XMLID_7414_">
                        <path
                          id="XMLID_7415_"
                          d="M398.5,115.5c-0.2,0-0.3,0-0.5-0.1c-0.4-0.1-0.5-0.3-0.6-0.5c-0.1-0.2-0.6-1.9,0.8-3.6
                    c1.1-1.4,3.2-2.1,4-2.4c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.2,0.3,0.2,0.5c-0.1,0.9-0.6,3.2-1.7,4.4
                    C400.5,115.1,399.3,115.5,398.5,115.5z M398.1,114.7c0,0,0.2,0.1,0.5,0.1c0.6,0,1.5-0.4,2.3-1.2c0.9-1.1,1.3-3.1,1.5-4
                    c-0.8,0.3-2.7,1-3.6,2.2C397.6,113.3,398.1,114.7,398.1,114.7L398.1,114.7z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7406_">
                      <g id="XMLID_7411_">
                        <path
                          id="XMLID_7412_"
                          style="fill: #ffffff"
                          d="M387.4,117.3c0,0-0.8-1.5-2.9-1.6c-1.6-0.1-3.5,0.9-4.3,1.4
                    c-0.2,0.1-0.2,0.3,0,0.4c0.8,0.5,2.7,1.6,4.3,1.6C386.5,119.1,387.7,117.6,387.4,117.3z"
                        />
                      </g>
                      <g id="XMLID_7407_">
                        <path
                          id="XMLID_7408_"
                          d="M384.5,119.4L384.5,119.4c-1.6,0-3.6-1.2-4.4-1.7c-0.2-0.1-0.3-0.3-0.3-0.5
                    c0-0.2,0.1-0.4,0.3-0.5c0.8-0.5,2.7-1.5,4.5-1.4c2.1,0.1,3.1,1.6,3.2,1.8c0.1,0.1,0.2,0.4,0,0.7
                    C387.3,118.5,386.1,119.4,384.5,119.4z M380.5,117.3c0.8,0.5,2.6,1.5,4,1.5h0c1.1,0,1.9-0.5,2.4-0.9
                    c0.2-0.2,0.3-0.4,0.3-0.4v0c0-0.1-0.8-1.3-2.7-1.5C383,115.9,381.2,116.8,380.5,117.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7399_">
                      <g id="XMLID_7404_">
                        <path
                          id="XMLID_7405_"
                          style="fill: #ffffff"
                          d="M394.7,115.6c0,0-0.8-1.5-2.9-1.6c-1.6-0.1-3.5,0.9-4.3,1.4
                    c-0.2,0.1-0.2,0.3,0,0.4c0.8,0.5,2.7,1.6,4.3,1.6C393.7,117.4,395,116,394.7,115.6z"
                        />
                      </g>
                      <g id="XMLID_7400_">
                        <path
                          id="XMLID_7401_"
                          d="M391.7,117.7C391.7,117.7,391.7,117.7,391.7,117.7c-1.6,0-3.7-1.2-4.4-1.7
                    c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5c0.8-0.5,2.7-1.5,4.5-1.4c2.1,0.1,3.1,1.6,3.2,1.8c0.1,0.1,0.2,0.4,0,0.7
                    C394.6,116.8,393.4,117.7,391.7,117.7z M387.7,115.6c0.8,0.5,2.6,1.5,4,1.5c0,0,0,0,0,0c1.1,0,1.9-0.5,2.4-0.9
                    c0.2-0.2,0.3-0.4,0.3-0.4l0,0c0-0.1-0.8-1.3-2.7-1.5C390.2,114.2,388.5,115.1,387.7,115.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7392_">
                      <g id="XMLID_7397_">
                        <path
                          id="XMLID_7398_"
                          style="fill: #ffffff"
                          d="M388,116.7c0,0-0.3-1.7-2.2-2.5c-1.5-0.7-3.6-0.4-4.5-0.2
                    c-0.2,0-0.3,0.3-0.2,0.4c0.6,0.7,2,2.5,3.4,3C386.5,118.1,388.2,117.1,388,116.7z"
                        />
                      </g>
                      <g id="XMLID_7393_">
                        <path
                          id="XMLID_7394_"
                          d="M386,117.9c-0.5,0-1-0.1-1.5-0.3c-1.5-0.6-3-2.4-3.6-3.1c-0.1-0.2-0.2-0.4-0.1-0.6
                    c0.1-0.2,0.2-0.3,0.4-0.4c0.9-0.2,3.1-0.5,4.7,0.2c2,0.9,2.3,2.6,2.4,2.8c0,0.1,0,0.4-0.3,0.7
                    C387.7,117.6,386.9,117.9,386,117.9z M381.5,114.2c0.6,0.7,1.9,2.3,3.2,2.8c1,0.4,2,0.2,2.5,0c0.3-0.1,0.4-0.3,0.4-0.3h0
                    c0-0.1-0.3-1.5-2-2.3C384.4,113.8,382.4,114.1,381.5,114.2z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7385_">
                      <g id="XMLID_7390_">
                        <path
                          id="XMLID_7391_"
                          style="fill: #ffffff"
                          d="M388.8,117c0,0,0.6,1.6-0.7,3.3c-1,1.3-3,2-3.9,2.3
                    c-0.2,0.1-0.4-0.1-0.3-0.3c0.1-0.9,0.6-3.1,1.6-4.3C386.7,116.5,388.7,116.5,388.8,117z"
                        />
                      </g>
                      <g id="XMLID_7386_">
                        <path
                          id="XMLID_7387_"
                          d="M384.1,122.9c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.2-0.3-0.2-0.5c0.2-0.9,0.6-3.2,1.7-4.4
                    c1.1-1.2,2.6-1.5,3.3-1.3c0.4,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.5,1.9-0.8,3.5c-1.1,1.4-3.2,2.1-4,2.4
                    C384.2,122.9,384.1,122.9,384.1,122.9z M388,117C388,117,388,117,388,117c-0.7,0-1.6,0.3-2.3,1.1c-0.9,1.1-1.4,3.1-1.5,4
                    c0.8-0.3,2.7-1,3.6-2.1c1.2-1.5,0.7-2.9,0.7-3l0,0C388.4,117.1,388.3,117,388,117z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7378_">
                      <g id="XMLID_7383_">
                        <path
                          id="XMLID_7384_"
                          style="fill: #ffffff"
                          d="M396.3,115.6c0,0,0.6,1.6-0.7,3.3c-1,1.3-3,2-3.9,2.3
                    c-0.2,0.1-0.4-0.1-0.3-0.3c0.1-0.9,0.6-3.1,1.6-4.3C394.3,115.1,396.2,115.1,396.3,115.6z"
                        />
                      </g>
                      <g id="XMLID_7379_">
                        <path
                          id="XMLID_7380_"
                          d="M391.6,121.5c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.2-0.3-0.2-0.5c0.1-0.9,0.6-3.2,1.7-4.4
                    c1.1-1.2,2.6-1.5,3.3-1.3c0.4,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.5,1.9-0.8,3.5c-1.1,1.4-3.2,2.1-4,2.4
                    C391.7,121.4,391.7,121.5,391.6,121.5z M395.6,115.6C395.5,115.6,395.5,115.6,395.6,115.6c-0.7,0-1.6,0.3-2.3,1.1
                    c-0.9,1.1-1.4,3.1-1.5,4c0.8-0.3,2.7-1,3.6-2.1c1.2-1.5,0.7-2.9,0.7-3v0C396,115.7,395.8,115.6,395.6,115.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7371_">
                      <g id="XMLID_7376_">
                        <path
                          id="XMLID_7377_"
                          style="fill: #ffffff"
                          d="M397,117c0,0,0.1,1.7,1.9,2.8c1.4,0.8,3.5,0.8,4.4,0.7
                    c0.2,0,0.3-0.2,0.2-0.4c-0.5-0.8-1.7-2.7-3-3.4C398.7,115.8,396.9,116.5,397,117z"
                        />
                      </g>
                      <g id="XMLID_7372_">
                        <path
                          id="XMLID_7373_"
                          d="M402.4,120.8c-1.1,0-2.6-0.1-3.7-0.8c-1.9-1.1-2-2.8-2-3c0-0.1,0-0.4,0.3-0.6
                    c0.6-0.4,2.1-0.7,3.6,0c1.4,0.7,2.7,2.7,3.2,3.5c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.3-0.5,0.3
                    C403.1,120.8,402.8,120.8,402.4,120.8z M397.3,117c0,0.1,0.1,1.6,1.7,2.5c1.3,0.8,3.3,0.7,4.2,0.7
                    c-0.5-0.8-1.6-2.6-2.9-3.2c-1-0.5-1.9-0.5-2.5-0.3C397.5,116.8,397.4,116.9,397.3,117L397.3,117z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7364_">
                      <g id="XMLID_7369_">
                        <path
                          id="XMLID_7370_"
                          style="fill: #ffffff"
                          d="M394.1,117c0,0-0.6,1.6,0.7,3.3c1,1.3,3,2,3.9,2.3
                    c0.2,0.1,0.4-0.1,0.3-0.3c-0.1-0.9-0.6-3.1-1.6-4.3C396.2,116.5,394.2,116.5,394.1,117z"
                        />
                      </g>
                      <g id="XMLID_7365_">
                        <path
                          id="XMLID_7366_"
                          d="M398.8,122.9c-0.1,0-0.1,0-0.2,0c-0.9-0.3-3-1-4-2.4c-1.3-1.7-0.8-3.4-0.8-3.6
                    c0-0.1,0.2-0.4,0.6-0.5c0.7-0.2,2.2,0.1,3.3,1.3c1.1,1.2,1.5,3.5,1.7,4.4c0,0.2,0,0.4-0.2,0.5
                    C399.1,122.8,398.9,122.9,398.8,122.9z M394.4,117.1L394.4,117.1c0,0.1-0.5,1.5,0.7,3c0.9,1.2,2.8,1.9,3.6,2.2
                    c-0.1-0.9-0.6-3-1.5-4c-0.7-0.8-1.6-1.1-2.3-1.2C394.6,117,394.5,117.1,394.4,117.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7357_">
                      <g id="XMLID_7362_">
                        <path
                          id="XMLID_7363_"
                          style="fill: #ffffff"
                          d="M396.8,117.1c0,0,0.3-1.7,2.2-2.5c1.5-0.7,3.6-0.4,4.5-0.2
                    c0.2,0,0.3,0.3,0.2,0.4c-0.6,0.7-2,2.5-3.4,3C398.3,118.5,396.6,117.6,396.8,117.1z"
                        />
                      </g>
                      <g id="XMLID_7358_">
                        <path
                          id="XMLID_7359_"
                          d="M398.8,118.4c-0.9,0-1.7-0.3-2.1-0.7c-0.3-0.3-0.3-0.5-0.3-0.7c0-0.2,0.4-1.9,2.4-2.8
                    c1.6-0.7,3.8-0.4,4.7-0.2c0.2,0,0.4,0.2,0.4,0.4c0.1,0.2,0,0.4-0.1,0.6c-0.6,0.8-2,2.6-3.6,3.1
                    C399.8,118.3,399.3,118.4,398.8,118.4z M397.1,117.2c0,0,0.1,0.2,0.4,0.3c0.6,0.3,1.5,0.4,2.5,0c1.3-0.5,2.7-2.1,3.2-2.8
                    c-0.9-0.2-2.8-0.4-4.2,0.2C397.4,115.7,397.1,117.1,397.1,117.2L397.1,117.2z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7350_">
                      <g id="XMLID_7355_">
                        <path
                          id="XMLID_7356_"
                          style="fill: #ffffff"
                          d="M396.6,117.3c0,0,1.5-0.8,1.6-2.9c0.1-1.6-0.9-3.5-1.4-4.3
                    c-0.1-0.2-0.3-0.2-0.4,0c-0.5,0.8-1.6,2.7-1.6,4.3C394.8,116.4,396.2,117.6,396.6,117.3z"
                        />
                      </g>
                      <g id="XMLID_7351_">
                        <path
                          id="XMLID_7352_"
                          d="M396.5,117.7c-0.1,0-0.2,0-0.4-0.1c-0.7-0.3-1.6-1.5-1.6-3.2c0-1.6,1.2-3.6,1.7-4.4
                    c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.5,0.8,1.5,2.7,1.4,4.4c-0.1,2.1-1.6,3.1-1.8,3.2
                    C396.7,117.6,396.6,117.7,396.5,117.7z M396.6,110.4c-0.5,0.8-1.5,2.6-1.5,4c0,1.1,0.5,1.9,0.9,2.4
                    c0.2,0.2,0.4,0.3,0.4,0.3l0,0c0.1,0,1.3-0.8,1.5-2.7C398,112.9,397.1,111.1,396.6,110.4z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_6821_">
                <g id="XMLID_7243_">
                  <g id="XMLID_7287_">
                    <g id="XMLID_7292_">
                      <g id="XMLID_7318_">
                        <g id="XMLID_7340_">
                          <g id="XMLID_7345_">
                            <path
                              id="XMLID_7346_"
                              style="fill: #ffffff"
                              d="M455,167.1c0,0,0-5.5,0-5.8c0,0,0-0.1-0.1-0.1l-9.1,0.8c0,0,0,0,0,0
                        l-0.8-5c0,0-0.1,0-0.1,0l-8.4,4.8c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,5.7,0,5.7l0,0
                        c0,0.3,0.2,0.5,0.4,0.7l8.4,4.9c0.7,0.4,1.6,0.4,2.3,0l8.6-4.9C454.9,167.6,455,167.4,455,167.1L455,167.1z"
                            />
                          </g>
                          <g id="XMLID_7341_">
                            <path
                              id="XMLID_7342_"
                              d="M444.8,173.4c-0.5,0-1-0.1-1.4-0.4l-8.4-4.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-4.2l0-1.5
                        c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l8.3-4.7c0.2-0.1,0.3-0.1,0.5,0l0.2,0.1l0.8,4.8l8.8-0.8l0.1,0
                        c0.2,0.1,0.4,0.3,0.4,0.5l0,5.9c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C445.8,173.3,445.3,173.4,444.8,173.4z M435.2,161.8l0,1.1
                        c0,1.5,0,4.1,0,4.2c0,0.1,0.1,0.2,0.2,0.4l8.4,4.9c0.6,0.3,1.3,0.3,1.9,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4h0l0-5.4
                        l-8.8,0.8l-0.2-0.1l-0.2-0.1l-0.8-4.7l-8.1,4.6l-0.2,0L435.2,161.8z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7333_">
                          <g id="XMLID_7338_">
                            <path
                              id="XMLID_7339_"
                              d="M455,161.2l-9.1,0.8c0,0,0,0,0,0l-1.1,3.1l0,8c0.4,0,0.8-0.1,1.2-0.3l8.6-4.9
                        c0.3-0.2,0.4-0.5,0.4-0.7l0,0C455,167,455,161.6,455,161.2C455,161.2,455,161.2,455,161.2z"
                            />
                          </g>
                          <g id="XMLID_7334_">
                            <path
                              id="XMLID_7335_"
                              d="M444.8,173.4C444.8,173.4,444.8,173.4,444.8,173.4l-0.5,0l0-8.5l1.2-3.5l0.3,0.2l9.1-0.8l0.1,0
                        c0.2,0.1,0.4,0.2,0.4,0.5l0,5.9c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C445.8,173.3,445.3,173.4,444.8,173.4z M445.2,165.1l0,7.4
                        c0.2,0,0.4-0.1,0.6-0.2l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4l0-5.4l-8.5,0.8L445.2,165.1z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7326_">
                          <g id="XMLID_7331_">
                            <path
                              id="XMLID_7332_"
                              d="M436.1,162.5c0,0,0.1-0.3,0.3-0.8l-1.5-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,5.7,0,5.7l0,0
                        c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5l0-3.8V162.5z"
                            />
                          </g>
                          <g id="XMLID_7327_">
                            <path
                              id="XMLID_7328_"
                              d="M436.5,169l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-4.2l0-1.5c0-0.2,0.1-0.3,0.2-0.4
                        c0.1-0.1,0.3-0.1,0.4-0.1l1.9,0.5l-0.1,0.4c-0.1,0.3-0.2,0.6-0.3,0.7L436.5,169z M435.2,161.8l0,1.1c0,1.5,0,4.1,0,4.2
                        c0,0.1,0.1,0.2,0.2,0.4l0.2,0.1l0-5.2c0,0,0.1-0.1,0.1-0.4L435.2,161.8z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7319_">
                          <g id="XMLID_7324_">
                            <path
                              id="XMLID_7325_"
                              style="fill: #ffffff"
                              d="M443.7,166.9l-8.5-4.9c-0.6-0.3-0.6-1.2,0-1.6l8.6-4.9
                        c0.7-0.4,1.6-0.4,2.2,0l8.5,4.9c0.6,0.3,0.6,1.2,0,1.6l-8.6,4.9C445.3,167.3,444.4,167.3,443.7,166.9z"
                            />
                          </g>
                          <g id="XMLID_7320_">
                            <path
                              id="XMLID_7321_"
                              d="M444.9,167.6c-0.5,0-0.9-0.1-1.3-0.4l-8.5-4.9c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                        l8.6-4.9c0.8-0.5,1.8-0.5,2.7,0l8.5,4.9c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-8.6,4.9
                        C445.8,167.5,445.3,167.6,444.9,167.6z M445,155.6c-0.3,0-0.6,0.1-0.9,0.2l-8.6,4.9c-0.2,0.1-0.2,0.3-0.2,0.4
                        c0,0.1,0,0.3,0.2,0.4l8.5,4.9c0.6,0.3,1.3,0.3,1.8,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.4l-8.5-4.9
                        C445.6,155.7,445.3,155.6,445,155.6z"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_7293_">
                        <g id="XMLID_7311_">
                          <g id="XMLID_7316_">
                            <path
                              id="XMLID_7317_"
                              style="fill: #ffffff"
                              d="M444.2,164.3l-4.7-2.7c-0.3-0.2-0.3-0.7,0-0.9l4.8-2.7
                        c0.4-0.2,0.9-0.2,1.3,0l4.7,2.7c0.3,0.2,0.3,0.7,0,0.9l-4.8,2.7C445.1,164.6,444.6,164.6,444.2,164.3z"
                            />
                          </g>
                          <g id="XMLID_7312_">
                            <path
                              id="XMLID_7313_"
                              d="M444.9,164.9c-0.3,0-0.6-0.1-0.8-0.2l-4.7-2.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8
                        l4.8-2.7c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-4.8,2.8
                        C445.5,164.9,445.2,164.9,444.9,164.9z M444.9,158.2c-0.1,0-0.3,0-0.4,0.1l-4.8,2.7c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
                        l4.7,2.7c0.3,0.1,0.6,0.1,0.8,0l4.8-2.7c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l-4.7-2.7C445.2,158.3,445.1,158.2,444.9,158.2z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7304_">
                          <g id="XMLID_7309_">
                            <path
                              id="XMLID_7310_"
                              style="fill: #ffffff"
                              d="M439.5,161.6l1,0.6l3.8-2.2c0.4-0.2,0.9-0.2,1.3,0l3.7,2.1l1-0.6
                        c0.3-0.2,0.3-0.7,0-0.9l-4.7-2.7c-0.4-0.2-0.9-0.2-1.3,0l-4.8,2.7C439.2,160.9,439.2,161.4,439.5,161.6z"
                            />
                          </g>
                          <g id="XMLID_7305_">
                            <path
                              id="XMLID_7306_"
                              d="M440.5,162.7l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.7
                        c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-1.2,0.7l-3.9-2.3
                        c-0.3-0.2-0.6-0.2-0.8,0L440.5,162.7z M439.7,161.2l0.8,0.5l3.6-2c0.5-0.3,1.2-0.3,1.7,0l3.5,2l0.8-0.5c0,0,0,0,0-0.1
                        c0,0,0-0.1,0-0.1l-4.7-2.7c-0.3-0.2-0.6-0.2-0.8,0l-4.8,2.7C439.7,161.1,439.7,161.1,439.7,161.2
                        C439.7,161.2,439.7,161.2,439.7,161.2L439.7,161.2z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7297_">
                          <g id="XMLID_7302_">
                            <path
                              id="XMLID_7303_"
                              d="M444.9,157.8c-0.2,0-0.4,0.1-0.6,0.2l-4.8,2.7c-0.3,0.2-0.3,0.7,0,0.9l1,0.6l3.8-2.2
                        c0.2-0.1,0.4-0.2,0.6-0.2V157.8z"
                            />
                          </g>
                          <g id="XMLID_7298_">
                            <path
                              id="XMLID_7299_"
                              d="M440.5,162.7l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.8
                        c0.2-0.1,0.5-0.2,0.8-0.2l0.4,0v2.9l-0.4,0c-0.1,0-0.3,0-0.4,0.1L440.5,162.7z M444.5,158.4l-4.8,2.7c0,0,0,0,0,0.1
                        c0,0,0,0.1,0,0.1l0.8,0.5l3.6-2c0.1-0.1,0.2-0.1,0.4-0.2V158.4z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7294_">
                          <g id="XMLID_7295_">
                            <rect
                              id="XMLID_7296_"
                              x="446.4"
                              y="158.8"
                              width="0.8"
                              height="1.9"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_7288_">
                      <g id="XMLID_7289_">
                        <g id="XMLID_7290_">
                          <rect
                            id="XMLID_7291_"
                            x="450.4"
                            y="165.7"
                            transform="matrix(0.5575 -0.8302 0.8302 0.5575 62.3932 448.998)"
                            style="fill: #ffffff"
                            width="3.9"
                            height="0.6"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_7244_">
                    <g id="XMLID_7280_">
                      <g id="XMLID_7285_">
                        <path
                          id="XMLID_7286_"
                          style="fill: #ffffff"
                          d="M445.7,160.2c0,0-1,1.4-3.1,1.3c-1.6-0.1-3.4-1.3-4.1-1.8
                    c-0.2-0.1-0.1-0.4,0-0.4c0.8-0.4,2.9-1.4,4.4-1.2C444.9,158.2,446,159.8,445.7,160.2z"
                        />
                      </g>
                      <g id="XMLID_7281_">
                        <path
                          id="XMLID_7282_"
                          d="M442.8,161.8c-0.1,0-0.1,0-0.2,0c-1.7-0.1-3.6-1.3-4.3-1.9c-0.2-0.1-0.2-0.3-0.2-0.5
                    c0-0.2,0.1-0.4,0.3-0.5c0.8-0.4,3-1.4,4.6-1.2c1.6,0.2,2.7,1.2,3,1.9c0.1,0.4,0,0.6-0.1,0.7
                    C445.8,160.5,444.8,161.8,442.8,161.8z M438.7,159.5c0.7,0.5,2.4,1.6,3.9,1.7c1.9,0.1,2.8-1.1,2.8-1.2c0-0.1,0-0.2-0.2-0.5
                    c-0.4-0.5-1.2-1.1-2.3-1.2C441.5,158.2,439.6,159.1,438.7,159.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7273_">
                      <g id="XMLID_7278_">
                        <path
                          id="XMLID_7279_"
                          style="fill: #ffffff"
                          d="M443.5,159.2c0,0-1.7,0.4-3.2-1.1c-1.2-1.1-1.7-3.2-1.9-4.1
                    c0-0.2,0.1-0.4,0.3-0.3c0.9,0.2,3,0.9,4.1,2C444.2,157.2,444,159.1,443.5,159.2z"
                        />
                      </g>
                      <g id="XMLID_7274_">
                        <path
                          id="XMLID_7275_"
                          d="M442.9,159.6c-0.7,0-1.8-0.2-2.8-1.2c-1.2-1.2-1.8-3.4-2-4.3c0-0.2,0-0.4,0.2-0.5
                    c0.1-0.1,0.4-0.2,0.5-0.1c0.9,0.2,3.1,0.9,4.2,2.1c1.1,1.2,1.2,2.7,1,3.4c-0.1,0.4-0.4,0.5-0.5,0.5
                    C443.5,159.5,443.2,159.6,442.9,159.6z M438.8,154.1c0.2,0.9,0.7,2.8,1.8,3.8c1.4,1.3,2.9,1,2.9,1l0,0c0,0,0.1-0.2,0.1-0.5
                    c0-0.6-0.2-1.6-0.9-2.4C441.6,154.9,439.7,154.3,438.8,154.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7266_">
                      <g id="XMLID_7271_">
                        <path
                          id="XMLID_7272_"
                          style="fill: #ffffff"
                          d="M445.8,159.8c0,0-1.4-1-1.3-3.1c0.1-1.6,1.3-3.4,1.8-4.1
                    c0.1-0.2,0.4-0.1,0.4,0c0.4,0.8,1.4,2.9,1.2,4.4C447.8,159,446.2,160.2,445.8,159.8z"
                        />
                      </g>
                      <g id="XMLID_7267_">
                        <path
                          id="XMLID_7268_"
                          d="M446,160.2c-0.2,0-0.3-0.1-0.4-0.1c-0.2-0.1-1.5-1.2-1.4-3.3c0.1-1.7,1.3-3.6,1.9-4.3
                    c0.1-0.2,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.3c0.4,0.8,1.4,3,1.2,4.6c-0.2,1.6-1.2,2.7-1.9,3
                    C446.2,160.2,446.1,160.2,446,160.2z M446.5,152.9c-0.5,0.7-1.6,2.4-1.7,3.9c-0.1,1.9,1.1,2.8,1.2,2.8l0,0
                    c0.1,0,0.2,0,0.5-0.2c0.5-0.4,1.1-1.2,1.2-2.3C447.8,155.6,446.9,153.7,446.5,152.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7259_">
                      <g id="XMLID_7264_">
                        <path
                          id="XMLID_7265_"
                          style="fill: #ffffff"
                          d="M442.4,156.3c0,0-1.5-0.9-1.5-3c0-1.6,1.1-3.5,1.6-4.2
                    c0.1-0.2,0.3-0.2,0.4,0c0.5,0.8,1.5,2.8,1.5,4.3C444.3,155.4,442.8,156.6,442.4,156.3z"
                        />
                      </g>
                      <g id="XMLID_7260_">
                        <path
                          id="XMLID_7261_"
                          d="M442.5,156.6c-0.2,0-0.3-0.1-0.3-0.1c-0.2-0.1-1.6-1.1-1.6-3.2c0-1.7,1.1-3.6,1.6-4.4
                    c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.5,0.8,1.6,2.9,1.5,4.5c-0.1,1.6-1.1,2.8-1.7,3.1
                    C442.8,156.6,442.6,156.6,442.5,156.6z M442.6,149.3c-0.5,0.7-1.5,2.5-1.5,3.9c0,1.9,1.3,2.7,1.3,2.7l0,0
                    c0.1,0,0.2-0.1,0.5-0.3c0.5-0.4,1-1.2,1-2.3C444.1,152,443.1,150.1,442.6,149.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7252_">
                      <g id="XMLID_7257_">
                        <path
                          id="XMLID_7258_"
                          style="fill: #ffffff"
                          d="M445.2,160.4c0,0-1.7-0.4-2.3-2.4c-0.5-1.5,0-3.6,0.3-4.5
                    c0.1-0.2,0.3-0.2,0.4-0.1c0.7,0.6,2.3,2.2,2.7,3.7C446.8,159,445.7,160.6,445.2,160.4z"
                        />
                      </g>
                      <g id="XMLID_7253_">
                        <path
                          id="XMLID_7254_"
                          d="M445.3,160.7c-0.1,0-0.1,0-0.2,0c-0.2-0.1-1.9-0.6-2.5-2.6c-0.5-1.6,0-3.8,0.2-4.7
                    c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4,0,0.5,0.1c0.7,0.6,2.3,2.3,2.8,3.9c0.4,1.6-0.2,3-0.7,3.5
                    C445.6,160.7,445.4,160.7,445.3,160.7z M443.4,153.6c-0.2,0.9-0.7,2.8-0.2,4.2c0.6,1.8,2.1,2.2,2.1,2.2l0,0
                    c0,0,0.2-0.1,0.4-0.4c0.3-0.5,0.6-1.5,0.3-2.5C445.6,155.8,444.1,154.3,443.4,153.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7245_">
                      <g id="XMLID_7250_">
                        <path
                          id="XMLID_7251_"
                          style="fill: #ffffff"
                          d="M445.5,161.1c0,0,1.6,0.7,3.3-0.4c1.4-0.9,2.3-2.8,2.6-3.6
                    c0.1-0.2-0.1-0.4-0.3-0.4c-0.9,0.1-3.2,0.3-4.4,1.2C445.2,159.1,445,161,445.5,161.1z"
                        />
                      </g>
                      <g id="XMLID_7246_">
                        <path
                          id="XMLID_7247_"
                          d="M446.7,161.7c-0.7,0-1.2-0.2-1.3-0.2c-0.1,0-0.3-0.2-0.4-0.6c-0.1-0.7,0.3-2.2,1.6-3.2
                    c1.3-1,3.6-1.2,4.6-1.3c0.2,0,0.4,0.1,0.5,0.2c0.1,0.2,0.1,0.4,0.1,0.6c-0.3,0.8-1.3,2.9-2.7,3.8
                    C448.1,161.5,447.3,161.7,446.7,161.7z M445.6,160.8c0.1,0,1.4,0.6,3-0.4c1.3-0.8,2.1-2.6,2.5-3.4c-0.9,0.1-3,0.3-4.1,1.1
                    c-0.9,0.6-1.3,1.5-1.4,2.1C445.5,160.6,445.6,160.8,445.6,160.8L445.6,160.8z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_6977_">
                  <g id="XMLID_7174_">
                    <g id="XMLID_7188_">
                      <g id="XMLID_7214_">
                        <g id="XMLID_7236_">
                          <g id="XMLID_7241_">
                            <path
                              id="XMLID_7242_"
                              style="fill: #ffffff"
                              d="M468.9,174.9c0,0,0-23.4,0-23.7c0,0,0-0.1-0.1-0.1l-9.1,0.8c0,0,0,0,0,0
                        l-0.8-5c0,0-0.1,0-0.1,0l-8.4,4.8c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,23.6,0,23.6l0,0
                        c0,0.3,0.2,0.5,0.4,0.7l8.4,4.9c0.7,0.4,1.6,0.4,2.3,0l8.6-4.9C468.7,175.5,468.9,175.2,468.9,174.9L468.9,174.9z"
                            />
                          </g>
                          <g id="XMLID_7237_">
                            <path
                              id="XMLID_7238_"
                              d="M458.7,181.3c-0.5,0-1-0.1-1.4-0.4l-8.4-4.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-17.1
                        c0-3.6,0-6.4,0-6.6c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l8.3-4.7c0.1-0.1,0.3-0.1,0.5,0l0.2,0.1
                        l0.8,4.8l8.8-0.8l0.1,0c0.2,0.1,0.4,0.2,0.4,0.5l0,23.8c0,0.4-0.3,0.8-0.6,1l-8.6,4.9
                        C459.7,181.2,459.2,181.3,458.7,181.3z M449.1,151.8c0,0.9,0,3.3,0,6.1c0,6.7,0,16.9,0,17.1c0,0.1,0.1,0.2,0.2,0.3
                        l8.4,4.9c0.6,0.3,1.3,0.3,1.9,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4h0l0-23.3l-8.8,0.8l-0.2-0.1l-0.2-0.1l-0.8-4.7l-8.1,4.6
                        l-0.2,0L449.1,151.8z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7229_">
                          <g id="XMLID_7234_">
                            <path
                              id="XMLID_7235_"
                              d="M468.9,151.1l-9.1,0.8c0,0,0,0,0,0l-1.1,3.1l0,25.8c0.4,0,0.8-0.1,1.2-0.3l8.6-4.9
                        c0.3-0.2,0.4-0.5,0.4-0.7l0,0C468.9,174.9,468.9,151.6,468.9,151.1C468.9,151.2,468.9,151.2,468.9,151.1z"
                            />
                          </g>
                          <g id="XMLID_7230_">
                            <path
                              id="XMLID_7231_"
                              d="M458.7,181.3C458.7,181.3,458.7,181.3,458.7,181.3l-0.5,0l0-26.4l1.2-3.6l0.3,0.2l9-0.8l0.1,0
                        c0.2,0.1,0.4,0.2,0.4,0.5l0,23.8c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C459.7,181.2,459.2,181.3,458.7,181.3z M459.1,155.1
                        l0,25.3c0.2,0,0.4-0.1,0.6-0.2l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4l0-23.3l-8.5,0.8L459.1,155.1z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7222_">
                          <g id="XMLID_7227_">
                            <path
                              id="XMLID_7228_"
                              d="M450,152.4c0,0,0.1-0.3,0.3-0.8l-1.5-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,23.6,0,23.6l0,0
                        c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5l0-21.6V152.4z"
                            />
                          </g>
                          <g id="XMLID_7223_">
                            <path
                              id="XMLID_7224_"
                              d="M450.4,176.9l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-17.1c0-3.6,0-6.4,0-6.6
                        c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.9,0.5l-0.1,0.4c-0.1,0.3-0.2,0.6-0.3,0.7L450.4,176.9z M449.1,151.8
                        c0,0.9,0,3.3,0,6.1c0,6.7,0,16.9,0,17.1c0,0.1,0.1,0.2,0.2,0.3l0.2,0.1l0-23.1l0-0.1c0,0,0.1-0.1,0.1-0.4L449.1,151.8z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7215_">
                          <g id="XMLID_7220_">
                            <path
                              id="XMLID_7221_"
                              style="fill: #ffffff"
                              d="M457.6,156.9l-8.5-4.9c-0.6-0.3-0.6-1.2,0-1.6l8.6-4.9
                        c0.7-0.4,1.6-0.4,2.2,0l8.5,4.9c0.6,0.3,0.6,1.2,0,1.6l-8.6,4.9C459.2,157.3,458.3,157.3,457.6,156.9z"
                            />
                          </g>
                          <g id="XMLID_7216_">
                            <path
                              id="XMLID_7217_"
                              d="M458.7,157.6c-0.5,0-0.9-0.1-1.3-0.4l-8.5-4.9c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                        l8.6-4.9c0.8-0.5,1.8-0.5,2.7,0l8.5,4.9c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-8.6,4.9
                        C459.7,157.5,459.2,157.6,458.7,157.6z M458.9,145.6c-0.3,0-0.6,0.1-0.9,0.2l-8.6,4.9c-0.2,0.1-0.2,0.3-0.2,0.4
                        c0,0.1,0,0.3,0.2,0.4l8.5,4.9c0.6,0.3,1.3,0.3,1.8,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.4l-8.5-4.9
                        C459.5,145.7,459.2,145.6,458.9,145.6z"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_7189_">
                        <g id="XMLID_7207_">
                          <g id="XMLID_7212_">
                            <path
                              id="XMLID_7213_"
                              style="fill: #ffffff"
                              d="M458,154.3l-4.7-2.7c-0.3-0.2-0.3-0.7,0-0.9l4.8-2.7
                        c0.4-0.2,0.9-0.2,1.3,0l4.7,2.7c0.3,0.2,0.3,0.7,0,0.9l-4.8,2.7C458.9,154.5,458.4,154.5,458,154.3z"
                            />
                          </g>
                          <g id="XMLID_7208_">
                            <path
                              id="XMLID_7209_"
                              d="M458.6,154.9c-0.3,0-0.6-0.1-0.8-0.2l-4.7-2.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8
                        l4.8-2.8c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-4.8,2.7
                        C459.2,154.8,458.9,154.9,458.6,154.9z M458.7,148.2c-0.1,0-0.3,0-0.4,0.1l-4.8,2.8c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
                        l4.7,2.7c0.3,0.1,0.6,0.1,0.8,0l4.8-2.8c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l-4.7-2.7C459,148.3,458.8,148.2,458.7,148.2z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7200_">
                          <g id="XMLID_7205_">
                            <path
                              id="XMLID_7206_"
                              style="fill: #ffffff"
                              d="M453.3,151.6l1,0.6l3.8-2.2c0.4-0.2,0.9-0.2,1.3,0l3.7,2.1l1-0.6
                        c0.3-0.2,0.3-0.7,0-0.9l-4.7-2.7c-0.4-0.2-0.9-0.2-1.3,0l-4.8,2.7C453,150.9,453,151.4,453.3,151.6z"
                            />
                          </g>
                          <g id="XMLID_7201_">
                            <path
                              id="XMLID_7202_"
                              d="M454.3,152.7l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.8
                        c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-1.2,0.7l-3.9-2.3
                        c-0.3-0.1-0.6-0.2-0.8,0L454.3,152.7z M453.5,151.2l0.8,0.5l3.6-2c0.5-0.3,1.2-0.3,1.7,0l3.5,2l0.8-0.5c0,0,0,0,0-0.1
                        c0,0,0-0.1,0-0.1l-4.7-2.7c-0.3-0.2-0.6-0.2-0.8,0l-4.8,2.8C453.5,151.1,453.5,151.1,453.5,151.2
                        C453.5,151.2,453.5,151.2,453.5,151.2L453.5,151.2z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7193_">
                          <g id="XMLID_7198_">
                            <path
                              id="XMLID_7199_"
                              d="M458.7,147.8c-0.2,0-0.4,0.1-0.6,0.2l-4.8,2.7c-0.3,0.2-0.3,0.7,0,0.9l1,0.6l3.8-2.2
                        c0.2-0.1,0.4-0.2,0.6-0.2V147.8z"
                            />
                          </g>
                          <g id="XMLID_7194_">
                            <path
                              id="XMLID_7195_"
                              d="M454.3,152.7l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.7
                        c0.2-0.1,0.5-0.2,0.8-0.2l0.4,0v2.9l-0.4,0c-0.1,0-0.3,0-0.4,0.1L454.3,152.7z M458.3,148.3l-4.8,2.7c0,0,0,0,0,0.1
                        c0,0,0,0.1,0,0.1l0.8,0.5l3.6-2c0.1-0.1,0.2-0.1,0.4-0.2V148.3z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_7190_">
                          <g id="XMLID_7191_">
                            <rect
                              id="XMLID_7192_"
                              x="462.2"
                              y="149.9"
                              width="0.8"
                              height="1.9"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_7185_">
                      <g id="XMLID_7186_">
                        <rect
                          id="XMLID_7187_"
                          x="448.4"
                          y="166"
                          style="fill: #ffffff"
                          width="0.6"
                          height="4.9"
                        />
                      </g>
                    </g>
                    <g id="XMLID_7175_">
                      <g id="XMLID_7182_">
                        <g id="XMLID_7183_">
                          <rect
                            id="XMLID_7184_"
                            x="464.4"
                            y="157.2"
                            transform="matrix(0.5575 -0.8302 0.8302 0.5575 75.5956 456.86)"
                            style="fill: #ffffff"
                            width="3.9"
                            height="0.6"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7179_">
                        <g id="XMLID_7180_">
                          <rect
                            id="XMLID_7181_"
                            x="464.4"
                            y="159.7"
                            transform="matrix(0.5575 -0.8302 0.8302 0.5575 73.5409 457.9552)"
                            style="fill: #ffffff"
                            width="3.9"
                            height="0.6"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7176_">
                        <g id="XMLID_7177_">
                          <rect
                            id="XMLID_7178_"
                            x="464.4"
                            y="162.2"
                            transform="matrix(0.5575 -0.8302 0.8302 0.5575 71.4558 459.0667)"
                            style="fill: #ffffff"
                            width="3.9"
                            height="0.6"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_6978_">
                    <g id="XMLID_7167_">
                      <g id="XMLID_7171_">
                        <g id="XMLID_7172_">
                          <path
                            id="XMLID_7173_"
                            d="M457.8,152.6l-0.1-7.9c0-2.3-1.9-4.2-4.3-4.2v-1.9c3.4,0,6.2,2.8,6.2,6.1l0.1,7.9L457.8,152.6z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7168_">
                        <g id="XMLID_7169_">
                          <rect
                            id="XMLID_7170_"
                            x="457.2"
                            y="139.4"
                            transform="matrix(0.7212 -0.6927 0.6927 0.7212 30.8694 357.4208)"
                            width="4.5"
                            height="1.9"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_6979_">
                      <g id="XMLID_7160_">
                        <g id="XMLID_7165_">
                          <path
                            id="XMLID_7166_"
                            style="fill: #ffffff"
                            d="M452.3,131.8c0,0-1.3-1.1-1-3.2c0.2-1.6,1.6-3.2,2.2-3.9
                      c0.1-0.1,0.4-0.1,0.4,0.1c0.3,0.9,1,3,0.7,4.5C454.3,131.3,452.6,132.2,452.3,131.8z"
                          />
                        </g>
                        <g id="XMLID_7161_">
                          <path
                            id="XMLID_7162_"
                            d="M452.5,132.2c-0.2,0-0.4-0.1-0.4-0.2c-0.2-0.1-1.4-1.3-1.1-3.5c0.3-1.7,1.7-3.4,2.3-4.1
                      c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.2,0.4,0.4c0.3,0.9,1.1,3.1,0.7,4.7c-0.3,1.6-1.5,2.6-2.2,2.8
                      C452.6,132.2,452.6,132.2,452.5,132.2z M452.5,131.6c0.1,0,0.2,0,0.5-0.2c0.5-0.3,1.2-1.1,1.4-2.1
                      c0.3-1.4-0.4-3.4-0.7-4.2c-0.6,0.7-1.9,2.2-2.1,3.7C451.3,130.5,452.5,131.6,452.5,131.6L452.5,131.6z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7153_">
                        <g id="XMLID_7158_">
                          <path
                            id="XMLID_7159_"
                            style="fill: #ffffff"
                            d="M461.1,138.4c0,0-1.5-0.8-1.6-2.9c-0.1-1.6,0.9-3.5,1.4-4.3
                      c0.1-0.2,0.3-0.2,0.4,0c0.5,0.8,1.6,2.7,1.6,4.3C463,137.4,461.5,138.7,461.1,138.4z"
                          />
                        </g>
                        <g id="XMLID_7154_">
                          <path
                            id="XMLID_7155_"
                            d="M461.3,138.8c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-1.6-1-1.8-3.2c-0.1-1.7,1-3.7,1.4-4.4
                      c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.5,0.8,1.7,2.8,1.7,4.4c0,1.6-0.9,2.8-1.6,3.2
                      C461.5,138.7,461.4,138.8,461.3,138.8z M461.1,131.5c-0.5,0.8-1.4,2.5-1.3,4c0.1,1.9,1.4,2.7,1.5,2.7l0,0
                      c0.1,0,0.2-0.1,0.4-0.3c0.5-0.4,0.9-1.3,0.9-2.4C462.6,134.1,461.6,132.2,461.1,131.5z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7146_">
                        <g id="XMLID_7151_">
                          <path
                            id="XMLID_7152_"
                            style="fill: #ffffff"
                            d="M461.9,136.1c0,0-0.6-1.6,0.7-3.3c1-1.3,3-2,3.9-2.3
                      c0.2-0.1,0.4,0.1,0.3,0.3c-0.1,0.9-0.6,3.1-1.6,4.3C463.9,136.6,462,136.6,461.9,136.1z"
                          />
                        </g>
                        <g id="XMLID_7147_">
                          <path
                            id="XMLID_7148_"
                            d="M462.6,136.7c-0.2,0-0.4,0-0.5-0.1c-0.4-0.1-0.5-0.3-0.6-0.5c-0.1-0.2-0.5-1.9,0.8-3.5
                      c1.1-1.4,3.2-2.1,4-2.4c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.2,0.3,0.2,0.5c-0.2,0.9-0.6,3.2-1.7,4.4
                      C464.6,136.4,463.4,136.7,462.6,136.7z M462.2,136c0,0,0.2,0.1,0.5,0.1c0.6,0,1.6-0.3,2.3-1.1c0.9-1.1,1.4-3.1,1.5-4
                      c-0.8,0.3-2.7,1-3.6,2.1C461.7,134.5,462.2,136,462.2,136L462.2,136z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7138_">
                        <g id="XMLID_7144_">
                          <path
                            id="XMLID_7145_"
                            style="fill: #ffffff"
                            d="M454.1,136.2c0,0-1.3-1.1-1-3.2c0.2-1.6,1.6-3.2,2.2-3.9
                      c0.1-0.1,0.4-0.1,0.4,0.1c0.3,0.9,1,3,0.7,4.5C456.2,135.6,454.5,136.6,454.1,136.2z"
                          />
                        </g>
                        <g id="XMLID_7139_">
                          <path
                            id="XMLID_7141_"
                            d="M454.4,136.6c-0.2,0-0.4-0.1-0.4-0.2c-0.2-0.1-1.4-1.3-1.1-3.5c0.3-1.7,1.7-3.4,2.3-4.1
                      c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.2,0.4,0.4c0.3,0.9,1.1,3.1,0.7,4.7c-0.3,1.6-1.5,2.6-2.2,2.8
                      C454.5,136.6,454.4,136.6,454.4,136.6z M454.4,136c0,0,0.2,0,0.5-0.2c0.5-0.3,1.2-1.1,1.4-2.1c0.3-1.4-0.4-3.4-0.7-4.2
                      c-0.6,0.7-1.9,2.2-2.1,3.7C453.2,134.9,454.3,135.9,454.4,136L454.4,136z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7128_">
                        <g id="XMLID_7135_">
                          <path
                            id="XMLID_7137_"
                            style="fill: #ffffff"
                            d="M453.4,137.9c0,0-0.6-1.6,0.7-3.3c1-1.3,3-2,3.9-2.3
                      c0.2-0.1,0.4,0.1,0.3,0.3c-0.1,0.9-0.6,3.1-1.6,4.3C455.5,138.4,453.5,138.4,453.4,137.9z"
                          />
                        </g>
                        <g id="XMLID_7130_">
                          <path
                            id="XMLID_7131_"
                            d="M454.2,138.5c-0.2,0-0.4,0-0.5-0.1c-0.4-0.1-0.5-0.3-0.6-0.5c-0.1-0.2-0.5-1.9,0.8-3.5
                      c1.1-1.4,3.2-2.1,4-2.4c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.2,0.3,0.2,0.5c-0.1,0.9-0.6,3.2-1.7,4.4
                      C456.1,138.2,455,138.5,454.2,138.5z M453.7,137.8c0,0,0.2,0.1,0.5,0.1c0.6,0,1.6-0.3,2.3-1.1c0.9-1.1,1.4-3.1,1.5-4
                      c-0.8,0.3-2.7,1-3.6,2.1C453.2,136.3,453.7,137.8,453.7,137.8L453.7,137.8z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7118_">
                        <g id="XMLID_7125_">
                          <path
                            id="XMLID_7127_"
                            style="fill: #ffffff"
                            d="M452.1,134.1c0,0,1.2-1.2,0.8-3.3c-0.4-1.6-1.9-3.1-2.5-3.7
                      c-0.1-0.1-0.4-0.1-0.4,0.1c-0.3,0.9-0.8,3.1-0.4,4.6C450.1,133.6,451.8,134.4,452.1,134.1z"
                          />
                        </g>
                        <g id="XMLID_7120_">
                          <path
                            id="XMLID_7121_"
                            d="M451.9,134.5c-0.1,0-0.1,0-0.2,0c-0.7-0.1-2-1-2.4-2.6c-0.4-1.6,0.1-3.8,0.4-4.7
                      c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4,0,0.5,0.1c0.7,0.6,2.2,2.2,2.6,3.9c0.5,2.1-0.7,3.4-0.8,3.5
                      C452.3,134.4,452.2,134.5,451.9,134.5z M450.3,127.4c-0.3,0.9-0.8,2.9-0.4,4.3c0.3,1,1,1.7,1.5,2c0.3,0.1,0.4,0.2,0.5,0.2
                      h0c0,0,1.1-1.1,0.7-3C452.3,129.4,450.9,128,450.3,127.4z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7109_">
                        <g id="XMLID_7116_">
                          <path
                            id="XMLID_7117_"
                            style="fill: #ffffff"
                            d="M451.1,136.1c0,0,0.6-1.6-0.7-3.3c-1-1.3-3-2-3.9-2.3
                      c-0.2-0.1-0.4,0.1-0.3,0.3c0.1,0.9,0.6,3.1,1.6,4.3C449.1,136.6,451,136.6,451.1,136.1z"
                          />
                        </g>
                        <g id="XMLID_7110_">
                          <path
                            id="XMLID_7111_"
                            d="M450.4,136.8c-0.8,0-2-0.4-2.8-1.4c-1.1-1.2-1.5-3.5-1.7-4.4c0-0.2,0-0.4,0.2-0.5
                      c0.2-0.1,0.4-0.2,0.6-0.1c0.9,0.3,3,1,4,2.4c1.3,1.7,0.8,3.4,0.8,3.6c0,0.1-0.2,0.4-0.6,0.5
                      C450.7,136.7,450.5,136.8,450.4,136.8z M446.5,130.9c0.1,0.9,0.6,3,1.5,4c0.7,0.8,1.6,1.1,2.3,1.2c0.3,0,0.5,0,0.5-0.1
                      l0,0c0-0.1,0.5-1.5-0.7-3C449.2,131.9,447.3,131.2,446.5,130.9z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7099_">
                        <g id="XMLID_7106_">
                          <path
                            id="XMLID_7107_"
                            style="fill: #ffffff"
                            d="M447.9,135.4c0,0-0.8-1.5-2.9-1.6c-1.6-0.1-3.5,0.9-4.3,1.4
                      c-0.2,0.1-0.2,0.3,0,0.4c0.8,0.5,2.7,1.6,4.3,1.6C447,137.2,448.2,135.8,447.9,135.4z"
                          />
                        </g>
                        <g id="XMLID_7100_">
                          <path
                            id="XMLID_7102_"
                            d="M445,137.6L445,137.6c-1.6,0-3.7-1.2-4.4-1.7c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5
                      c0.8-0.5,2.7-1.5,4.5-1.4c2.1,0.1,3.1,1.6,3.2,1.8c0.1,0.1,0.2,0.4,0,0.7C447.9,136.6,446.6,137.6,445,137.6z M441,135.4
                      c0.8,0.5,2.6,1.5,4,1.5h0c1.1,0,1.9-0.5,2.4-0.9c0.2-0.2,0.3-0.4,0.3-0.4c0-0.1-0.8-1.3-2.7-1.5
                      C443.5,134,441.7,134.9,441,135.4z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7089_">
                        <g id="XMLID_7096_">
                          <path
                            id="XMLID_7098_"
                            style="fill: #ffffff"
                            d="M461.5,138.6c0,0,0.8-1.5,2.9-1.6c1.6-0.1,3.5,0.9,4.3,1.4
                      c0.2,0.1,0.2,0.3,0,0.4c-0.8,0.5-2.7,1.6-4.3,1.6C462.4,140.4,461.2,138.9,461.5,138.6z"
                          />
                        </g>
                        <g id="XMLID_7091_">
                          <path
                            id="XMLID_7092_"
                            d="M464.4,140.7c-1.6,0-2.8-0.9-3.2-1.6c-0.2-0.4-0.1-0.6,0-0.7c0.1-0.2,1-1.6,3.2-1.8
                      c1.7-0.1,3.7,1,4.5,1.4c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.4-0.3,0.5C468.1,139.5,466,140.7,464.4,140.7
                      C464.4,140.7,464.4,140.7,464.4,140.7z M461.8,138.7c0,0.1,0.1,0.2,0.3,0.4c0.4,0.5,1.3,0.9,2.4,0.9c0,0,0,0,0,0
                      c1.4,0,3.2-1,4-1.5c-0.8-0.5-2.5-1.4-4-1.3C462.5,137.4,461.8,138.7,461.8,138.7L461.8,138.7z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7080_">
                        <g id="XMLID_7087_">
                          <path
                            id="XMLID_7088_"
                            style="fill: #ffffff"
                            d="M454.2,136.9c0,0,0.8-1.5,2.9-1.6c1.6-0.1,3.5,0.9,4.3,1.4
                      c0.2,0.1,0.2,0.3,0,0.4c-0.8,0.5-2.7,1.6-4.3,1.6C455.2,138.7,453.9,137.3,454.2,136.9z"
                          />
                        </g>
                        <g id="XMLID_7081_">
                          <path
                            id="XMLID_7082_"
                            d="M457.2,139c-1.6,0-2.8-0.9-3.2-1.6c-0.2-0.4-0.1-0.6,0-0.7c0.1-0.2,1-1.6,3.2-1.8
                      c1.7-0.1,3.7,1,4.5,1.4c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.4-0.3,0.5C460.8,137.9,458.8,139,457.2,139L457.2,139z
                       M454.5,137c0,0.1,0.1,0.2,0.3,0.4c0.4,0.5,1.3,0.9,2.4,0.9c0,0,0,0,0,0c1.4,0,3.2-1,4-1.5c-0.8-0.5-2.5-1.4-4-1.3
                      C455.3,135.7,454.5,137,454.5,137L454.5,137z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7070_">
                        <g id="XMLID_7077_">
                          <path
                            id="XMLID_7078_"
                            style="fill: #ffffff"
                            d="M464.7,134.7c0,0,0.7-1.5,2.8-1.8c1.6-0.2,3.6,0.7,4.3,1.1
                      c0.2,0.1,0.2,0.3,0,0.4c-0.7,0.5-2.6,1.8-4.2,1.9C465.7,136.5,464.4,135.2,464.7,134.7z"
                          />
                        </g>
                        <g id="XMLID_7071_">
                          <path
                            id="XMLID_7073_"
                            d="M467.5,136.8c-1.5,0-2.7-0.8-3-1.4c-0.2-0.4-0.1-0.6-0.1-0.7c0.1-0.2,0.9-1.7,3.1-1.9
                      c1.7-0.2,3.7,0.8,4.5,1.2c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.8,0.6-2.7,1.8-4.3,1.9
                      C467.6,136.7,467.6,136.8,467.5,136.8z M465,134.9c0,0.1,0.1,0.2,0.3,0.4c0.5,0.4,1.3,0.9,2.4,0.8
                      c1.4-0.1,3.2-1.2,3.9-1.8c-0.8-0.4-2.6-1.2-4.1-1.1C465.7,133.5,465,134.9,465,134.9L465,134.9z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7060_">
                        <g id="XMLID_7067_">
                          <path
                            id="XMLID_7068_"
                            style="fill: #ffffff"
                            d="M460.9,138c0,0,0.3-1.7,2.2-2.5c1.5-0.7,3.6-0.4,4.5-0.2
                      c0.2,0,0.3,0.3,0.2,0.4c-0.6,0.7-2,2.5-3.4,3C462.4,139.4,460.7,138.4,460.9,138z"
                          />
                        </g>
                        <g id="XMLID_7061_">
                          <path
                            id="XMLID_7063_"
                            d="M462.9,139.2c-0.9,0-1.7-0.3-2.1-0.7c-0.3-0.3-0.3-0.5-0.3-0.7c0-0.2,0.4-1.9,2.4-2.8
                      c1.6-0.7,3.8-0.4,4.7-0.2c0.2,0,0.4,0.2,0.4,0.4c0.1,0.2,0,0.4-0.1,0.6c-0.6,0.8-2,2.6-3.6,3.1
                      C463.9,139.2,463.4,139.2,462.9,139.2z M461.2,138c0,0,0.1,0.2,0.4,0.3c0.6,0.3,1.5,0.4,2.5,0c1.3-0.5,2.7-2.1,3.2-2.8
                      c-0.9-0.2-2.8-0.4-4.2,0.2C461.4,136.5,461.2,138,461.2,138L461.2,138z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7050_">
                        <g id="XMLID_7057_">
                          <path
                            id="XMLID_7059_"
                            style="fill: #ffffff"
                            d="M460.2,138.3c0,0-0.6,1.6,0.7,3.3c1,1.3,3,2,3.9,2.3
                      c0.2,0.1,0.4-0.1,0.3-0.3c-0.1-0.9-0.6-3.1-1.6-4.3C462.2,137.8,460.2,137.8,460.2,138.3z"
                          />
                        </g>
                        <g id="XMLID_7051_">
                          <path
                            id="XMLID_7053_"
                            d="M464.9,144.2c-0.1,0-0.1,0-0.2,0c-0.9-0.3-3-1-4-2.4c-1.3-1.7-0.9-3.3-0.8-3.5
                      c0-0.1,0.2-0.4,0.6-0.5c0.7-0.2,2.2,0.1,3.3,1.3c1.1,1.2,1.6,3.5,1.7,4.4c0,0.2,0,0.4-0.2,0.5
                      C465.1,144.1,465,144.2,464.9,144.2z M460.5,138.4c0,0.1-0.5,1.5,0.7,3c0.9,1.2,2.8,1.9,3.6,2.1c-0.2-0.9-0.6-3-1.5-4
                      c-0.7-0.8-1.6-1.1-2.3-1.1C460.7,138.3,460.5,138.4,460.5,138.4L460.5,138.4z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7039_">
                        <g id="XMLID_7047_">
                          <path
                            id="XMLID_7048_"
                            style="fill: #ffffff"
                            d="M452.6,136.9c0,0-0.6,1.6,0.7,3.3c1,1.3,3,2,3.9,2.3
                      c0.2,0.1,0.4-0.1,0.3-0.3c-0.1-0.9-0.6-3.1-1.6-4.3C454.6,136.4,452.7,136.4,452.6,136.9z"
                          />
                        </g>
                        <g id="XMLID_7041_">
                          <path
                            id="XMLID_7042_"
                            d="M457.3,142.8c-0.1,0-0.1,0-0.2,0c-0.9-0.3-3-1-4-2.4c-1.3-1.7-0.9-3.3-0.8-3.5
                      c0-0.1,0.2-0.4,0.6-0.5c0.7-0.2,2.2,0.1,3.3,1.3c1.1,1.2,1.6,3.5,1.7,4.4c0,0.2,0,0.4-0.2,0.5
                      C457.6,142.7,457.4,142.8,457.3,142.8z M452.9,137L452.9,137c0,0.1-0.5,1.5,0.7,3c0.9,1.2,2.8,1.9,3.6,2.1
                      c-0.2-0.9-0.6-3-1.5-4c-0.7-0.8-1.6-1.1-2.3-1.1C453.1,136.9,452.9,137,452.9,137z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7029_">
                        <g id="XMLID_7036_">
                          <path
                            id="XMLID_7038_"
                            style="fill: #ffffff"
                            d="M458.2,142.6c0,0,0.6,1.6-0.7,3.3c-1,1.3-3,2-3.9,2.3
                      c-0.2,0.1-0.4-0.1-0.3-0.3c0.1-0.9,0.6-3.1,1.6-4.3C456.2,142.1,458.1,142.1,458.2,142.6z"
                          />
                        </g>
                        <g id="XMLID_7030_">
                          <path
                            id="XMLID_7032_"
                            d="M453.6,148.5c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.2-0.3-0.2-0.5c0.1-0.9,0.6-3.2,1.7-4.4
                      c1.1-1.2,2.6-1.5,3.3-1.3c0.4,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.6,1.9-0.8,3.6c-1.1,1.4-3.2,2.1-4,2.4
                      C453.7,148.4,453.6,148.5,453.6,148.5z M457.5,142.6C457.5,142.6,457.5,142.6,457.5,142.6c-0.7,0-1.6,0.4-2.4,1.2
                      c-0.9,1.1-1.3,3.1-1.5,4c0.8-0.3,2.7-1,3.6-2.2c1.2-1.5,0.7-2.9,0.7-3l0,0C457.9,142.6,457.8,142.6,457.5,142.6z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7019_">
                        <g id="XMLID_7026_">
                          <path
                            id="XMLID_7027_"
                            style="fill: #ffffff"
                            d="M451.9,138.3c0,0-0.1,1.7-1.9,2.8c-1.4,0.8-3.5,0.8-4.4,0.7
                      c-0.2,0-0.3-0.2-0.2-0.4c0.5-0.8,1.7-2.7,3-3.4C450.2,137.1,452,137.8,451.9,138.3z"
                          />
                        </g>
                        <g id="XMLID_7020_">
                          <path
                            id="XMLID_7022_"
                            d="M446.5,142.1c-0.4,0-0.7,0-0.9,0c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4,0-0.6
                      c0.5-0.8,1.7-2.8,3.2-3.5c1.5-0.8,3-0.5,3.6,0c0.3,0.2,0.4,0.5,0.3,0.6c0,0.2-0.2,1.9-2,3
                      C449.1,142,447.5,142.1,446.5,142.1z M445.7,141.5c0.9,0.1,2.9,0.1,4.2-0.7c1.6-1,1.7-2.5,1.7-2.5l0,0
                      c0,0-0.2-0.2-0.5-0.3c-0.6-0.2-1.6-0.2-2.5,0.3C447.3,138.9,446.2,140.7,445.7,141.5z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_7009_">
                        <g id="XMLID_7016_">
                          <path
                            id="XMLID_7017_"
                            style="fill: #ffffff"
                            d="M454.8,138.3c0,0,0.6,1.6-0.7,3.3c-1,1.3-3,2-3.9,2.3
                      c-0.2,0.1-0.4-0.1-0.3-0.3c0.1-0.9,0.6-3.1,1.6-4.3C452.8,137.8,454.7,137.8,454.8,138.3z"
                          />
                        </g>
                        <g id="XMLID_7010_">
                          <path
                            id="XMLID_7012_"
                            d="M450.1,144.2c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.2-0.3-0.2-0.5c0.1-0.9,0.6-3.2,1.7-4.4
                      c1.1-1.2,2.6-1.5,3.3-1.3c0.4,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.6,1.9-0.8,3.6c-1.1,1.4-3.2,2.1-4,2.4
                      C450.2,144.2,450.2,144.2,450.1,144.2z M454.1,138.3C454,138.3,454,138.3,454.1,138.3c-0.7,0-1.6,0.4-2.4,1.2
                      c-0.9,1.1-1.3,3.1-1.5,4c0.8-0.3,2.7-1,3.6-2.2c1.2-1.5,0.7-2.9,0.7-3l0,0C454.4,138.4,454.3,138.3,454.1,138.3z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_6999_">
                        <g id="XMLID_7006_">
                          <path
                            id="XMLID_7008_"
                            style="fill: #ffffff"
                            d="M452.1,138.4c0,0-0.3-1.7-2.2-2.5c-1.5-0.7-3.6-0.4-4.5-0.2
                      c-0.2,0-0.3,0.3-0.2,0.4c0.6,0.7,2,2.5,3.4,3C450.6,139.8,452.3,138.9,452.1,138.4z"
                          />
                        </g>
                        <g id="XMLID_7001_">
                          <path
                            id="XMLID_7002_"
                            d="M450.1,139.7c-0.5,0-1-0.1-1.5-0.3c-1.5-0.6-3-2.4-3.6-3.1c-0.1-0.2-0.2-0.4-0.1-0.6
                      c0.1-0.2,0.2-0.3,0.4-0.4c0.9-0.2,3.1-0.5,4.7,0.2c2,0.9,2.3,2.6,2.4,2.8c0,0.1,0,0.4-0.3,0.7
                      C451.8,139.4,451,139.7,450.1,139.7z M445.6,136c0.6,0.7,1.9,2.3,3.2,2.8c1,0.4,2,0.2,2.5,0c0.3-0.1,0.4-0.3,0.4-0.3h0
                      c0-0.1-0.3-1.5-2-2.3C448.5,135.6,446.5,135.8,445.6,136z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_6990_">
                        <g id="XMLID_6997_">
                          <path
                            id="XMLID_6998_"
                            style="fill: #ffffff"
                            d="M452.3,138.6c0,0-1.5-0.8-1.6-2.9c-0.1-1.6,0.9-3.5,1.4-4.3
                      c0.1-0.2,0.3-0.2,0.4,0c0.5,0.8,1.6,2.7,1.6,4.3C454.1,137.7,452.7,138.9,452.3,138.6z"
                          />
                        </g>
                        <g id="XMLID_6992_">
                          <path
                            id="XMLID_6993_"
                            d="M452.4,139c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-1.6-1-1.8-3.2c-0.1-1.7,1-3.7,1.4-4.4
                      c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.5,0.8,1.7,2.8,1.7,4.4c0,1.6-0.9,2.8-1.6,3.2
                      C452.7,139,452.5,139,452.4,139z M452.3,131.7c-0.5,0.8-1.4,2.5-1.3,4c0.1,1.9,1.4,2.6,1.5,2.7l0,0c0.1,0,0.2-0.1,0.4-0.3
                      c0.5-0.4,0.9-1.3,0.9-2.4C453.8,134.3,452.8,132.5,452.3,131.7z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_6981_">
                        <g id="XMLID_6988_">
                          <path
                            id="XMLID_6989_"
                            style="fill: #ffffff"
                            d="M448,134.8c0,0-1.7,0.1-2.9-1.7c-0.9-1.3-1-3.5-1-4.4
                      c0-0.2,0.2-0.3,0.4-0.2c0.8,0.4,2.8,1.5,3.6,2.8C449.1,133,448.5,134.8,448,134.8z"
                          />
                        </g>
                        <g id="XMLID_6982_">
                          <path
                            id="XMLID_6984_"
                            d="M448,135.1C448,135.1,448,135.1,448,135.1c-0.3,0-2-0.1-3.2-1.8c-1-1.4-1.1-3.7-1.1-4.5
                      c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4-0.1,0.6,0c0.8,0.4,2.9,1.5,3.8,2.9c0.9,1.4,0.7,2.9,0.3,3.6
                      C448.4,135,448.1,135.1,448,135.1z M444.3,128.9c0,0.9,0.1,2.9,1,4.1c1.1,1.6,2.6,1.5,2.6,1.5h0c0,0,0.1-0.2,0.2-0.5
                      c0.2-0.6,0.1-1.6-0.4-2.5C447,130.3,445.2,129.3,444.3,128.9z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_6823_">
                  <g id="XMLID_6883_">
                    <g id="XMLID_6899_">
                      <g id="XMLID_6935_">
                        <g id="XMLID_6967_">
                          <g id="XMLID_6974_">
                            <path
                              id="XMLID_6975_"
                              style="fill: #ffffff"
                              d="M482.8,182.9c0,0,0-11.9,0-12.2c0,0,0-0.1-0.1-0.1l-9.1,0.8c0,0,0,0,0,0
                        l-0.8-5c0,0-0.1,0-0.1,0l-8.4,4.8c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,12.1,0,12.1l0,0
                        c0,0.3,0.2,0.5,0.4,0.7l8.4,4.9c0.7,0.4,1.6,0.4,2.3,0l8.6-4.9C482.6,183.5,482.8,183.2,482.8,182.9L482.8,182.9z"
                            />
                          </g>
                          <g id="XMLID_6968_">
                            <path
                              id="XMLID_6970_"
                              d="M472.6,189.3c-0.5,0-1-0.1-1.4-0.4l-8.4-4.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-8.8
                        c0-1.8,0-3.2,0-3.3c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l8.3-4.7c0.1-0.1,0.3-0.1,0.5,0l0.2,0.1
                        l0.8,4.8l8.8-0.8l0.1,0c0.2,0.1,0.4,0.2,0.4,0.5l0,12.3c0,0.4-0.3,0.8-0.6,1l-8.6,4.9
                        C473.6,189.1,473.1,189.3,472.6,189.3z M463,171.3c0,0.6,0,1.6,0,2.9c0,3.4,0,8.6,0,8.8c0,0.1,0.1,0.2,0.2,0.3l8.4,4.9
                        c0.6,0.3,1.3,0.3,1.9,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4h0l0-11.8l-8.8,0.8l-0.2-0.1l-0.2-0.1l-0.8-4.7l-8.1,4.6l-0.2,0
                        L463,171.3z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_6957_">
                          <g id="XMLID_6964_">
                            <path
                              id="XMLID_6965_"
                              d="M482.7,170.6l-9.1,0.8c0,0,0,0,0,0l-1.1,3.1l0,14.3c0.4,0,0.8-0.1,1.2-0.3l8.6-4.9
                        c0.3-0.2,0.4-0.5,0.4-0.7l0,0C482.8,182.9,482.8,171,482.7,170.6C482.8,170.6,482.8,170.6,482.7,170.6z"
                            />
                          </g>
                          <g id="XMLID_6959_">
                            <path
                              id="XMLID_6960_"
                              d="M472.6,189.3C472.6,189.3,472.6,189.3,472.6,189.3l-0.5,0l0-14.9l1.2-3.6l0.3,0.2l9-0.8l0.1,0
                        c0.2,0.1,0.4,0.2,0.4,0.5l0,12.3c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C473.6,189.1,473.1,189.3,472.6,189.3z M473,174.6l0,13.8
                        c0.2,0,0.4-0.1,0.6-0.2l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4l0-11.8l-8.5,0.8L473,174.6z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_6947_">
                          <g id="XMLID_6954_">
                            <path
                              id="XMLID_6956_"
                              d="M463.9,171.9c0,0,0.1-0.3,0.3-0.8l-1.5-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,12.1,0,12.1l0,0
                        c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5l0-10.1V171.9z"
                            />
                          </g>
                          <g id="XMLID_6948_">
                            <path
                              id="XMLID_6950_"
                              d="M464.3,184.8l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-8.8c0-1.8,0-3.2,0-3.3
                        c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.9,0.5l-0.1,0.4c-0.1,0.3-0.2,0.6-0.3,0.7L464.3,184.8z M463,171.2
                        c0,0.6,0,1.6,0,2.9c0,3.4,0,8.6,0,8.8c0,0.1,0.1,0.2,0.2,0.3l0.2,0.1l0-11.6c0,0,0.1-0.1,0.1-0.4L463,171.2z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_6936_">
                          <g id="XMLID_6944_">
                            <path
                              id="XMLID_6945_"
                              style="fill: #ffffff"
                              d="M471.5,176.4l-8.5-4.9c-0.6-0.3-0.6-1.2,0-1.6l8.6-4.9
                        c0.7-0.4,1.6-0.4,2.2,0l8.5,4.9c0.6,0.3,0.6,1.2,0,1.6l-8.6,4.9C473.1,176.8,472.2,176.8,471.5,176.4z"
                            />
                          </g>
                          <g id="XMLID_6938_">
                            <path
                              id="XMLID_6939_"
                              d="M472.6,177.1c-0.5,0-0.9-0.1-1.3-0.4l-8.5-4.9c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                        l8.6-4.9c0.8-0.5,1.8-0.5,2.7,0l8.5,4.9c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-8.6,4.9
                        C473.5,177,473.1,177.1,472.6,177.1z M472.7,165.1c-0.3,0-0.6,0.1-0.9,0.2l-8.6,4.9c-0.2,0.1-0.2,0.3-0.2,0.4
                        c0,0.1,0,0.3,0.2,0.4l8.5,4.9c0.6,0.3,1.3,0.3,1.8,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.4l-8.5-4.9
                        C473.4,165.2,473.1,165.1,472.7,165.1z"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_6900_">
                        <g id="XMLID_6924_">
                          <g id="XMLID_6932_">
                            <path
                              id="XMLID_6933_"
                              style="fill: #ffffff"
                              d="M472,173.8l-4.7-2.7c-0.3-0.2-0.3-0.7,0-0.9l4.8-2.7
                        c0.4-0.2,0.9-0.2,1.3,0l4.7,2.7c0.3,0.2,0.3,0.7,0,0.9l-4.8,2.7C472.9,174,472.4,174,472,173.8z"
                            />
                          </g>
                          <g id="XMLID_6926_">
                            <path
                              id="XMLID_6927_"
                              d="M472.7,174.4c-0.3,0-0.6-0.1-0.8-0.2l-4.7-2.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8
                        l4.8-2.8c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-4.8,2.7
                        C473.2,174.3,472.9,174.4,472.7,174.4z M472.7,167.7c-0.1,0-0.3,0-0.4,0.1l-4.8,2.7c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
                        l4.7,2.7c0.3,0.1,0.6,0.2,0.8,0l4.8-2.7c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l-4.7-2.7C473,167.7,472.9,167.7,472.7,167.7z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_6915_">
                          <g id="XMLID_6922_">
                            <path
                              id="XMLID_6923_"
                              style="fill: #ffffff"
                              d="M467.3,171l1,0.6l3.8-2.2c0.4-0.2,0.9-0.2,1.3,0l3.7,2.1l1-0.6
                        c0.3-0.2,0.3-0.7,0-0.9l-4.7-2.7c-0.4-0.2-0.9-0.2-1.3,0l-4.8,2.7C467,170.4,467,170.8,467.3,171z"
                            />
                          </g>
                          <g id="XMLID_6916_">
                            <path
                              id="XMLID_6918_"
                              d="M468.3,172.1l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.8
                        c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-1.2,0.7l-3.9-2.3
                        c-0.3-0.2-0.6-0.2-0.8,0L468.3,172.1z M467.5,170.7l0.8,0.5l3.6-2c0.5-0.3,1.2-0.3,1.7,0l3.5,2l0.8-0.5c0,0,0,0,0-0.1
                        c0,0,0-0.1,0-0.1l-4.7-2.7c-0.3-0.2-0.6-0.2-0.8,0l-4.8,2.7C467.5,170.5,467.5,170.6,467.5,170.7
                        C467.5,170.6,467.5,170.7,467.5,170.7L467.5,170.7z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_6906_">
                          <g id="XMLID_6912_">
                            <path
                              id="XMLID_6913_"
                              d="M472.7,167.3c-0.2,0-0.4,0.1-0.6,0.2l-4.8,2.7c-0.3,0.2-0.3,0.7,0,0.9l1,0.6l3.8-2.2
                        c0.2-0.1,0.4-0.2,0.6-0.2V167.3z"
                            />
                          </g>
                          <g id="XMLID_6907_">
                            <path
                              id="XMLID_6908_"
                              d="M468.3,172.1l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.7
                        c0.2-0.1,0.5-0.2,0.8-0.2l0.4,0v2.9l-0.4,0c-0.1,0-0.3,0-0.4,0.1L468.3,172.1z M472.3,167.8l-4.8,2.7c0,0,0,0,0,0.1
                        c0,0,0,0.1,0,0.1l0.8,0.5l3.6-2c0.1-0.1,0.2-0.1,0.4-0.2V167.8z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_6902_">
                          <g id="XMLID_6903_">
                            <rect
                              id="XMLID_6904_"
                              x="474.2"
                              y="168.2"
                              width="0.8"
                              height="1.9"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_6895_">
                      <g id="XMLID_6896_">
                        <rect
                          id="XMLID_6897_"
                          x="462.3"
                          y="171.8"
                          style="fill: #ffffff"
                          width="0.6"
                          height="6.7"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6885_">
                      <g id="XMLID_6891_">
                        <g id="XMLID_6892_">
                          <rect
                            id="XMLID_6893_"
                            x="477.9"
                            y="175.9"
                            transform="matrix(0.5577 -0.8301 0.8301 0.5577 65.9457 476.2629)"
                            style="fill: #ffffff"
                            width="3.9"
                            height="0.6"
                          />
                        </g>
                      </g>
                      <g id="XMLID_6886_">
                        <g id="XMLID_6888_">
                          <rect
                            id="XMLID_6889_"
                            x="477.9"
                            y="178.4"
                            transform="matrix(0.5577 -0.8301 0.8301 0.5577 63.8914 477.3577)"
                            style="fill: #ffffff"
                            width="3.9"
                            height="0.6"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_6824_">
                    <g id="XMLID_6873_">
                      <g id="XMLID_6880_">
                        <path
                          id="XMLID_6882_"
                          style="fill: #ffffff"
                          d="M471.8,170.1c0,0,1,1.4,3.1,1.3c1.6-0.1,3.4-1.3,4.1-1.8
                    c0.2-0.1,0.1-0.4,0-0.4c-0.8-0.4-2.9-1.4-4.4-1.2C472.5,168.2,471.4,169.8,471.8,170.1z"
                        />
                      </g>
                      <g id="XMLID_6875_">
                        <path
                          id="XMLID_6876_"
                          d="M474.7,171.8c-2,0-3-1.3-3.1-1.4c-0.1-0.1-0.2-0.3-0.1-0.7c0.3-0.7,1.4-1.8,3-1.9
                    c1.6-0.2,3.7,0.8,4.6,1.2c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.7,0.5-2.6,1.8-4.3,1.9
                    C474.8,171.8,474.7,171.8,474.7,171.8z M472,169.9c0,0.1,0.9,1.3,2.8,1.2c1.5-0.1,3.1-1.2,3.9-1.7
                    c-0.8-0.4-2.7-1.3-4.1-1.1c-1.1,0.1-1.9,0.7-2.3,1.2C472.1,169.7,472,169.9,472,169.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6864_">
                      <g id="XMLID_6871_">
                        <path
                          id="XMLID_6872_"
                          style="fill: #ffffff"
                          d="M474,169.1c0,0,1.7,0.4,3.2-1.1c1.2-1.1,1.7-3.2,1.9-4.1
                    c0-0.2-0.1-0.4-0.3-0.3c-0.9,0.2-3,0.9-4.1,2C473.2,167.2,473.5,169.1,474,169.1z"
                        />
                      </g>
                      <g id="XMLID_6865_">
                        <path
                          id="XMLID_6866_"
                          d="M474.5,169.5c-0.3,0-0.5,0-0.6-0.1c-0.1,0-0.4-0.1-0.5-0.5c-0.3-0.7-0.1-2.2,1-3.4
                    c1.1-1.2,3.3-1.9,4.2-2.1c0.2-0.1,0.4,0,0.5,0.1c0.1,0.1,0.2,0.3,0.2,0.5c-0.2,0.9-0.7,3-2,4.3
                    C476.3,169.3,475.2,169.5,474.5,169.5z M474,168.8c0.1,0,1.5,0.3,2.9-1c1.1-1,1.6-3,1.8-3.8c-0.9,0.2-2.9,0.9-3.8,1.9
                    c-0.7,0.8-1,1.7-0.9,2.4C473.9,168.6,474,168.8,474,168.8L474,168.8z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6854_">
                      <g id="XMLID_6861_">
                        <path
                          id="XMLID_6862_"
                          style="fill: #ffffff"
                          d="M471.6,169.8c0,0,1.4-1,1.3-3.1c-0.1-1.6-1.3-3.4-1.8-4.1
                    c-0.1-0.2-0.4-0.1-0.4,0c-0.4,0.8-1.4,2.9-1.2,4.4C469.6,169,471.2,170.1,471.6,169.8z"
                        />
                      </g>
                      <g id="XMLID_6856_">
                        <path
                          id="XMLID_6857_"
                          d="M471.4,170.1c-0.1,0-0.2,0-0.4-0.1c-0.7-0.3-1.8-1.4-1.9-3c-0.2-1.6,0.8-3.7,1.2-4.6
                    c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.2c0.5,0.7,1.8,2.6,1.9,4.3c0.1,2.2-1.3,3.2-1.4,3.3
                    C471.7,170.1,471.6,170.1,471.4,170.1z M470.9,162.8c-0.4,0.8-1.3,2.7-1.1,4.1c0.1,1.1,0.7,1.9,1.2,2.3
                    c0.3,0.2,0.4,0.2,0.5,0.2l0,0c0.1,0,1.3-0.9,1.2-2.8C472.5,165.2,471.4,163.6,470.9,162.8z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6844_">
                      <g id="XMLID_6851_">
                        <path
                          id="XMLID_6853_"
                          style="fill: #ffffff"
                          d="M475.1,166.2c0,0,1.5-0.9,1.5-3c0-1.6-1.1-3.5-1.6-4.2
                    c-0.1-0.2-0.3-0.2-0.4,0c-0.5,0.8-1.5,2.8-1.5,4.3C473.2,165.3,474.7,166.5,475.1,166.2z"
                        />
                      </g>
                      <g id="XMLID_6846_">
                        <path
                          id="XMLID_6847_"
                          d="M474.9,166.6c-0.1,0-0.2,0-0.4-0.1c-0.7-0.3-1.7-1.5-1.7-3.1c-0.1-1.6,1-3.7,1.5-4.5
                    c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.5,0.7,1.6,2.7,1.6,4.4c0,2.2-1.5,3.1-1.6,3.2
                    C475.2,166.5,475.1,166.6,474.9,166.6z M474.8,159.3c-0.5,0.8-1.4,2.7-1.4,4.1c0,1.1,0.6,1.9,1,2.3
                    c0.2,0.2,0.4,0.3,0.5,0.3l0,0c0.1,0,1.3-0.8,1.3-2.7C476.3,161.7,475.3,160,474.8,159.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6835_">
                      <g id="XMLID_6842_">
                        <path
                          id="XMLID_6843_"
                          style="fill: #ffffff"
                          d="M472.2,170.3c0,0,1.7-0.4,2.3-2.4c0.5-1.5,0-3.6-0.3-4.5
                    c-0.1-0.2-0.3-0.2-0.4-0.1c-0.7,0.6-2.3,2.2-2.7,3.7C470.7,168.9,471.8,170.5,472.2,170.3z"
                        />
                      </g>
                      <g id="XMLID_6836_">
                        <path
                          id="XMLID_6837_"
                          d="M472.1,170.6c-0.1,0-0.3-0.1-0.5-0.2c-0.6-0.5-1.2-1.9-0.7-3.5c0.4-1.6,2.1-3.2,2.8-3.9
                    c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.3,0.9,0.8,3,0.2,4.7c-0.7,2-2.3,2.6-2.5,2.6
                    C472.3,170.6,472.2,170.6,472.1,170.6z M474,163.6c-0.7,0.6-2.2,2.1-2.5,3.5c-0.3,1,0,2,0.3,2.5c0.2,0.3,0.3,0.4,0.4,0.4v0
                    c0.1,0,1.5-0.4,2.1-2.2C474.7,166.4,474.2,164.4,474,163.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6825_">
                      <g id="XMLID_6832_">
                        <path
                          id="XMLID_6833_"
                          style="fill: #ffffff"
                          d="M471.9,171.1c0,0-1.6,0.7-3.3-0.4c-1.4-0.9-2.3-2.8-2.6-3.6
                    c-0.1-0.2,0.1-0.4,0.3-0.4c0.9,0.1,3.2,0.3,4.4,1.2C472.3,169,472.4,170.9,471.9,171.1z"
                        />
                      </g>
                      <g id="XMLID_6826_">
                        <path
                          id="XMLID_6828_"
                          d="M470.7,171.6c-0.6,0-1.4-0.2-2.3-0.7c-1.5-0.9-2.4-3-2.7-3.8c-0.1-0.2-0.1-0.4,0.1-0.6
                    c0.1-0.2,0.3-0.3,0.5-0.2c0.9,0.1,3.3,0.3,4.6,1.3c1.3,1,1.7,2.4,1.6,3.2c-0.1,0.4-0.3,0.5-0.4,0.6
                    C472,171.4,471.4,171.6,470.7,171.6z M466.3,167c0.3,0.8,1.2,2.6,2.5,3.4c1.6,1,3,0.4,3,0.4l0,0c0,0,0.1-0.2,0-0.5
                    c-0.1-0.6-0.5-1.5-1.4-2.1C469.3,167.3,467.3,167,466.3,167z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_6320_">
                <g id="XMLID_6739_">
                  <g id="XMLID_6745_">
                    <g id="XMLID_6781_">
                      <g id="XMLID_6812_">
                        <g id="XMLID_6819_">
                          <path
                            id="XMLID_6820_"
                            style="fill: #ffffff"
                            d="M469.6,107.1c0,0,0-5.5,0-5.8c0,0,0-0.1-0.1-0.1l-9.1,0.8c0,0,0,0,0,0
                      l-0.8-5c0,0-0.1,0-0.1,0l-8.4,4.8c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,5.7,0,5.7l0,0c0,0.3,0.2,0.5,0.4,0.7
                      l8.4,4.9c0.7,0.4,1.6,0.4,2.3,0l8.6-4.9C469.5,107.6,469.6,107.4,469.6,107.1L469.6,107.1z"
                          />
                        </g>
                        <g id="XMLID_6813_">
                          <path
                            id="XMLID_6814_"
                            d="M459.4,113.4c-0.5,0-1-0.1-1.4-0.4l-8.4-4.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-4.9l0-0.9
                      c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l8.3-4.7c0.2-0.1,0.3-0.1,0.5,0l0.2,0.1l0.8,4.8l8.8-0.8l0.1,0
                      c0.2,0.1,0.4,0.3,0.4,0.5l0,5.9c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C460.4,113.3,459.9,113.4,459.4,113.4z M449.8,101.8l0,0.5
                      c0,1.5,0,4.7,0,4.8c0,0.1,0.1,0.2,0.2,0.4l8.4,4.9c0.6,0.3,1.3,0.3,1.9,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4h0l0-5.4
                      l-8.8,0.8l-0.2-0.1l-0.2-0.1l-0.8-4.7l-8.1,4.6l-0.2,0L449.8,101.8z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_6802_">
                        <g id="XMLID_6809_">
                          <path
                            id="XMLID_6810_"
                            d="M469.6,101.2l-9.1,0.8c0,0,0,0,0,0l-1.1,3.1l0,8c0.4,0,0.8-0.1,1.2-0.3l8.6-4.9
                      c0.3-0.2,0.4-0.5,0.4-0.7l0,0C469.6,107,469.6,101.6,469.6,101.2C469.6,101.2,469.6,101.2,469.6,101.2z"
                          />
                        </g>
                        <g id="XMLID_6803_">
                          <path
                            id="XMLID_6805_"
                            d="M459.4,113.4C459.4,113.4,459.4,113.4,459.4,113.4l-0.5,0l0-8.5l1.2-3.6l0.3,0.2l9.1-0.8l0.1,0
                      c0.2,0.1,0.4,0.2,0.4,0.5l0,5.9c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C460.4,113.3,459.9,113.4,459.4,113.4z M459.8,105.1l0,7.4
                      c0.2,0,0.4-0.1,0.6-0.2l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4l0-5.4l-8.5,0.8L459.8,105.1z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_6792_">
                        <g id="XMLID_6799_">
                          <path
                            id="XMLID_6800_"
                            d="M450.7,102.5c0,0,0.1-0.3,0.3-0.8l-1.5-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,5.7,0,5.7l0,0
                      c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5l0-3.8V102.5z"
                          />
                        </g>
                        <g id="XMLID_6793_">
                          <path
                            id="XMLID_6795_"
                            d="M451.1,109l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-4.9l0-0.9c0-0.1,0.1-0.3,0.2-0.4
                      c0.1-0.1,0.3-0.1,0.4-0.1l1.9,0.5l-0.2,0.4c-0.1,0.3-0.2,0.6-0.3,0.7L451.1,109z M449.8,101.8l0,0.5c0,1.5,0,4.7,0,4.8
                      c0,0.1,0.1,0.2,0.2,0.4l0.2,0.1l0-5.2c0,0,0.1-0.1,0.1-0.4L449.8,101.8z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_6782_">
                        <g id="XMLID_6789_">
                          <path
                            id="XMLID_6790_"
                            style="fill: #ffffff"
                            d="M458.3,106.9l-8.5-4.9c-0.6-0.3-0.6-1.2,0-1.6l8.6-4.9
                      c0.7-0.4,1.6-0.4,2.2,0l8.5,4.9c0.6,0.3,0.6,1.2,0,1.6l-8.6,4.9C459.9,107.3,459,107.3,458.3,106.9z"
                          />
                        </g>
                        <g id="XMLID_6783_">
                          <path
                            id="XMLID_6784_"
                            d="M459.4,107.7c-0.5,0-0.9-0.1-1.3-0.4l-8.5-4.9c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                      l8.6-4.9c0.8-0.5,1.8-0.5,2.7,0l8.5,4.9c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-8.6,4.9
                      C460.4,107.5,459.9,107.7,459.4,107.7z M459.6,95.6c-0.3,0-0.6,0.1-0.9,0.2l-8.6,4.9c-0.2,0.1-0.2,0.3-0.2,0.4
                      c0,0.1,0,0.3,0.2,0.4l8.5,4.9c0.6,0.3,1.3,0.3,1.8,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.4l-8.5-4.9
                      C460.2,95.7,459.9,95.6,459.6,95.6z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_6747_">
                      <g id="XMLID_6771_">
                        <g id="XMLID_6778_">
                          <path
                            id="XMLID_6779_"
                            style="fill: #ffffff"
                            d="M458.8,104.3l-4.7-2.7c-0.3-0.2-0.3-0.7,0-0.9l4.8-2.7
                      c0.4-0.2,0.9-0.2,1.3,0l4.7,2.7c0.3,0.2,0.3,0.7,0,0.9l-4.8,2.7C459.7,104.6,459.2,104.6,458.8,104.3z"
                          />
                        </g>
                        <g id="XMLID_6772_">
                          <path
                            id="XMLID_6773_"
                            d="M459.5,104.9c-0.3,0-0.6-0.1-0.8-0.2l-4.7-2.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8
                      l4.8-2.7c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-4.8,2.8
                      C460,104.9,459.8,104.9,459.5,104.9z M459.5,98.2c-0.1,0-0.3,0-0.4,0.1l-4.8,2.8c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1l4.7,2.7
                      c0.3,0.1,0.6,0.1,0.8,0l4.8-2.7c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l-4.7-2.7C459.8,98.3,459.7,98.2,459.5,98.2z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_6762_">
                        <g id="XMLID_6768_">
                          <path
                            id="XMLID_6770_"
                            style="fill: #ffffff"
                            d="M454.1,101.6l1,0.6l3.8-2.2c0.4-0.2,0.9-0.2,1.3,0l3.7,2.1l1-0.6
                      c0.3-0.2,0.3-0.7,0-0.9l-4.7-2.7c-0.4-0.2-0.9-0.2-1.3,0l-4.8,2.7C453.8,100.9,453.8,101.4,454.1,101.6z"
                          />
                        </g>
                        <g id="XMLID_6763_">
                          <path
                            id="XMLID_6764_"
                            d="M455.1,102.7l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.7
                      c0.5-0.3,1.2-0.3,1.7,0l4.7,2.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-1.2,0.7l-3.9-2.3c-0.3-0.2-0.6-0.2-0.8,0
                      L455.1,102.7z M454.3,101.2l0.8,0.5l3.6-2c0.5-0.3,1.2-0.3,1.7,0l3.5,2l0.8-0.5c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l-4.7-2.7
                      c-0.3-0.2-0.6-0.2-0.8,0l-4.8,2.8C454.3,101.1,454.3,101.1,454.3,101.2C454.3,101.2,454.3,101.2,454.3,101.2L454.3,101.2z
                      "
                          />
                        </g>
                      </g>
                      <g id="XMLID_6752_">
                        <g id="XMLID_6759_">
                          <path
                            id="XMLID_6760_"
                            d="M459.5,97.8c-0.2,0-0.4,0.1-0.6,0.2l-4.8,2.7c-0.3,0.2-0.3,0.7,0,0.9l1,0.6l3.8-2.2
                      c0.2-0.1,0.4-0.2,0.6-0.2V97.8z"
                          />
                        </g>
                        <g id="XMLID_6754_">
                          <path
                            id="XMLID_6755_"
                            d="M455.1,102.7l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.3,0.2-0.6,0.5-0.8l4.8-2.8
                      c0.2-0.1,0.5-0.2,0.8-0.2l0.4,0v2.9l-0.4,0c-0.1,0-0.3,0-0.4,0.1L455.1,102.7z M459.1,98.4l-4.8,2.7c0,0,0,0,0,0.1
                      c0,0,0,0.1,0,0.1l0.8,0.5l3.6-2c0.1-0.1,0.2-0.1,0.4-0.2V98.4z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_6748_">
                        <g id="XMLID_6750_">
                          <rect
                            id="XMLID_6751_"
                            x="461"
                            y="98.8"
                            width="0.8"
                            height="1.9"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_6740_">
                    <g id="XMLID_6741_">
                      <g id="XMLID_6743_">
                        <rect
                          id="XMLID_6744_"
                          x="465"
                          y="105.7"
                          transform="matrix(0.5575 -0.8302 0.8302 0.5575 118.6576 434.5645)"
                          style="fill: #ffffff"
                          width="3.9"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_6324_">
                  <g id="XMLID_6735_">
                    <g id="XMLID_6736_">
                      <path
                        id="XMLID_6737_"
                        d="M458.2,100.5c-0.3,0-0.5,0-0.5,0l-1.1-0.1l1-3.6c0.8-5.2-3.4-8.4-3.6-8.5l1.1-1.5
                  c0.2,0.2,5.3,4,4.3,10.4l0,0.1l-0.4,1.3c1.5-0.2,4.1-1.2,6-5.1l1.7,0.9C464,100,459.7,100.5,458.2,100.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6326_">
                    <g id="XMLID_6725_">
                      <g id="XMLID_6732_">
                        <path
                          id="XMLID_6733_"
                          style="fill: #ffffff"
                          d="M457.4,75.1c0,0,1.5-0.9,1.5-3c0-1.6-1.1-3.5-1.6-4.2
                    c-0.1-0.2-0.3-0.2-0.4,0c-0.5,0.8-1.5,2.8-1.4,4.3C455.5,74.2,457,75.4,457.4,75.1z"
                        />
                      </g>
                      <g id="XMLID_6726_">
                        <path
                          id="XMLID_6728_"
                          d="M457.2,75.5c-0.1,0-0.2,0-0.4-0.1c-0.7-0.3-1.7-1.5-1.8-3.1c-0.1-1.6,1-3.7,1.5-4.5
                    c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.5,0.7,1.7,2.6,1.7,4.4c0,2.2-1.4,3.1-1.6,3.3
                    C457.5,75.4,457.4,75.5,457.2,75.5z M457,68.2c-0.5,0.8-1.4,2.7-1.3,4.1c0.1,1.1,0.6,1.9,1,2.3c0.2,0.2,0.4,0.3,0.5,0.3
                    l0,0c0.1,0,1.3-0.9,1.3-2.7C458.5,70.6,457.5,68.9,457,68.2z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6715_">
                      <g id="XMLID_6722_">
                        <path
                          id="XMLID_6723_"
                          style="fill: #ffffff"
                          d="M455.1,79.1c0,0,1.5-0.9,1.5-3c0-1.6-1.1-3.5-1.6-4.2
                    c-0.1-0.2-0.3-0.2-0.4,0c-0.5,0.8-1.5,2.8-1.4,4.3C453.2,78.3,454.8,79.4,455.1,79.1z"
                        />
                      </g>
                      <g id="XMLID_6716_">
                        <path
                          id="XMLID_6718_"
                          d="M455,79.5c-0.1,0-0.2,0-0.4-0.1c-0.7-0.3-1.7-1.5-1.8-3.1c-0.1-1.6,1-3.7,1.5-4.5
                    c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.5,0.7,1.7,2.6,1.7,4.4c0,2.2-1.4,3.2-1.6,3.3
                    C455.2,79.4,455.1,79.5,455,79.5z M454.8,72.2c-0.5,0.8-1.4,2.7-1.3,4.1c0.1,1.1,0.6,1.9,1.1,2.3c0.2,0.2,0.4,0.3,0.5,0.3
                    l0,0c0.1,0,1.3-0.9,1.3-2.7C456.3,74.6,455.3,72.9,454.8,72.2z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6705_">
                      <g id="XMLID_6712_">
                        <path
                          id="XMLID_6714_"
                          style="fill: #ffffff"
                          d="M453.7,79.8c0,0,0.8-1.5-0.2-3.3c-0.8-1.4-2.7-2.4-3.5-2.8
                    c-0.2-0.1-0.4,0-0.4,0.2c0,0.9,0.1,3.2,0.9,4.5C451.6,80,453.5,80.3,453.7,79.8z"
                        />
                      </g>
                      <g id="XMLID_6706_">
                        <path
                          id="XMLID_6708_"
                          d="M453.2,80.4c-0.8,0-2.1-0.5-2.9-1.8c-0.9-1.4-1-3.7-1-4.6c0-0.2,0.1-0.4,0.3-0.5
                    c0.2-0.1,0.4-0.1,0.6,0c0.8,0.4,2.8,1.5,3.6,3c1.1,1.9,0.3,3.4,0.2,3.6c-0.1,0.1-0.2,0.3-0.6,0.4
                    C453.3,80.3,453.3,80.4,453.2,80.4z M449.9,74c0,0.9,0.1,3,0.9,4.2c0.6,0.9,1.5,1.4,2.1,1.5c0.3,0.1,0.5,0,0.5,0l0,0
                    c0-0.1,0.7-1.4-0.2-3C452.4,75.4,450.7,74.4,449.9,74z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6695_">
                      <g id="XMLID_6702_">
                        <path
                          id="XMLID_6704_"
                          style="fill: #ffffff"
                          d="M457.1,77.3c0,0-1-1.4-0.2-3.3c0.6-1.5,2.3-2.8,3-3.3
                    c0.2-0.1,0.4,0,0.4,0.2c0.1,0.9,0.3,3.2-0.3,4.6C459.2,77.2,457.4,77.8,457.1,77.3z"
                        />
                      </g>
                      <g id="XMLID_6697_">
                        <path
                          id="XMLID_6698_"
                          d="M457.5,77.8c-0.4,0-0.6-0.2-0.6-0.3c-0.1-0.2-1.1-1.6-0.3-3.6c0.6-1.6,2.4-2.9,3.2-3.4
                    c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.3,0.3,0.3,0.5c0.1,0.9,0.4,3.3-0.3,4.7C459.6,77.1,458.3,77.8,457.5,77.8
                    C457.5,77.8,457.5,77.8,457.5,77.8z M457.4,77.1c0.1,0,0.2,0,0.5-0.1c0.6-0.2,1.4-0.8,1.8-1.8c0.6-1.3,0.4-3.4,0.3-4.3
                    c-0.7,0.5-2.3,1.7-2.9,3.1C456.5,75.9,457.4,77.1,457.4,77.1L457.4,77.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6685_">
                      <g id="XMLID_6692_">
                        <path
                          id="XMLID_6694_"
                          style="fill: #ffffff"
                          d="M457.9,79.6c0,0-0.3-1.7,1.2-3.1c1.2-1.1,3.3-1.5,4.2-1.7
                    c0.2,0,0.3,0.2,0.3,0.3c-0.3,0.9-1,3-2.2,4C459.8,80.4,457.9,80,457.9,79.6z"
                        />
                      </g>
                      <g id="XMLID_6687_">
                        <path
                          id="XMLID_6688_"
                          d="M458.9,80.3c-0.4,0-0.7-0.1-0.9-0.2c-0.4-0.2-0.5-0.4-0.5-0.5c0-0.2-0.3-1.9,1.3-3.4
                    c1.3-1.2,3.5-1.6,4.3-1.7c0.2,0,0.4,0,0.5,0.2c0.1,0.2,0.2,0.4,0.1,0.6c-0.3,0.9-1.1,3.1-2.3,4.1
                    C460.7,80,459.7,80.3,458.9,80.3z M458.2,79.5c0,0,0.2,0.1,0.5,0.2c0.6,0.1,1.6-0.1,2.4-0.8c1.1-0.9,1.8-2.9,2.1-3.8
                    c-0.9,0.1-2.8,0.5-3.9,1.6C457.9,78,458.2,79.5,458.2,79.5L458.2,79.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6676_">
                      <g id="XMLID_6683_">
                        <path
                          id="XMLID_6684_"
                          style="fill: #ffffff"
                          d="M460.9,79.9c0,0,1.1-1.3,3.1-1.1c1.6,0.2,3.3,1.5,4,2
                    c0.1,0.1,0.1,0.4-0.1,0.4c-0.8,0.4-2.9,1.2-4.5,1C461.5,81.9,460.5,80.2,460.9,79.9z"
                        />
                      </g>
                      <g id="XMLID_6678_">
                        <path
                          id="XMLID_6679_"
                          d="M464,82.5c-0.2,0-0.4,0-0.6,0c-1.6-0.3-2.7-1.4-2.9-2.1c-0.1-0.4,0-0.6,0.1-0.7
                    c0.1-0.2,1.3-1.5,3.4-1.2c1.7,0.2,3.5,1.5,4.2,2.1c0.2,0.1,0.2,0.3,0.2,0.5c0,0.2-0.2,0.4-0.3,0.4
                    C467.3,81.8,465.5,82.5,464,82.5z M461.1,80.1c0,0.1,0,0.2,0.2,0.5c0.4,0.5,1.1,1.1,2.2,1.3c1.4,0.2,3.3-0.5,4.2-0.9
                    c-0.7-0.6-2.3-1.8-3.8-1.9C462.1,78.9,461.1,80,461.1,80.1L461.1,80.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6667_">
                      <g id="XMLID_6674_">
                        <path
                          id="XMLID_6675_"
                          style="fill: #ffffff"
                          d="M460.3,82.1c0,0,1.6-0.6,3.3,0.7c1.3,1,2,3,2.3,3.9
                    c0.1,0.2-0.1,0.4-0.3,0.3c-0.9-0.1-3.1-0.6-4.3-1.6C459.8,84.1,459.9,82.2,460.3,82.1z"
                        />
                      </g>
                      <g id="XMLID_6668_">
                        <path
                          id="XMLID_6669_"
                          d="M465.6,87.3C465.6,87.3,465.5,87.3,465.6,87.3c-1-0.2-3.3-0.6-4.5-1.7c-1.2-1.1-1.5-2.6-1.3-3.3
                    c0.1-0.4,0.3-0.5,0.5-0.6c0.2-0.1,1.9-0.5,3.5,0.8c1.4,1.1,2.1,3.2,2.4,4c0.1,0.2,0,0.4-0.1,0.6
                    C465.9,87.3,465.8,87.3,465.6,87.3z M460.4,82.4c0,0-0.1,0.2-0.1,0.5c0,0.6,0.3,1.6,1.1,2.3c1.1,0.9,3.1,1.4,4,1.5
                    c-0.3-0.8-1-2.7-2.1-3.6C461.9,81.9,460.5,82.4,460.4,82.4L460.4,82.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6657_">
                      <g id="XMLID_6664_">
                        <path
                          id="XMLID_6665_"
                          style="fill: #ffffff"
                          d="M452.5,80.4c0,0-0.6-1.6-2.6-2.1c-1.6-0.3-3.6,0.4-4.4,0.7
                    c-0.2,0.1-0.2,0.3-0.1,0.4c0.7,0.6,2.4,2,4,2.3C451.3,82.1,452.7,80.9,452.5,80.4z"
                        />
                      </g>
                      <g id="XMLID_6658_">
                        <path
                          id="XMLID_6660_"
                          d="M449.9,82.2c-0.2,0-0.4,0-0.7-0.1c-1.6-0.3-3.4-1.7-4.1-2.4c-0.2-0.1-0.2-0.3-0.2-0.5
                    c0-0.2,0.2-0.4,0.4-0.4c0.8-0.3,2.9-1.1,4.6-0.7c2.1,0.5,2.8,2,2.9,2.2c0.1,0.1,0.1,0.4-0.1,0.7
                    C452.3,81.5,451.2,82.2,449.9,82.2z M445.6,79.3c0.7,0.6,2.3,1.9,3.7,2.1c1.1,0.2,2-0.2,2.5-0.6c0.3-0.2,0.3-0.3,0.4-0.4
                    l0,0c0-0.1-0.6-1.5-2.4-1.9C448.3,78.4,446.5,79,445.6,79.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6647_">
                      <g id="XMLID_6654_">
                        <path
                          id="XMLID_6656_"
                          style="fill: #ffffff"
                          d="M456.2,80.1c0,0,0.3,1.7-1.2,3.1c-1.2,1.1-3.3,1.5-4.2,1.6
                    c-0.2,0-0.3-0.2-0.3-0.3c0.3-0.9,1.1-3,2.3-4C454.3,79.2,456.2,79.6,456.2,80.1z"
                        />
                      </g>
                      <g id="XMLID_6649_">
                        <path
                          id="XMLID_6650_"
                          d="M450.8,85.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.2-0.4-0.1-0.6c0.3-0.9,1.1-3.1,2.4-4.1
                    c1.3-1,2.8-1.1,3.5-0.8c0.4,0.2,0.5,0.4,0.5,0.6c0,0.2,0.2,1.9-1.3,3.4C453.9,84.6,451.7,85,450.8,85.1
                    C450.8,85.1,450.8,85.1,450.8,85.1z M455.1,79.9c-0.6,0-1.4,0.2-2.1,0.8c-1.1,0.9-1.8,2.8-2.1,3.7c0.9-0.1,2.8-0.5,3.9-1.5
                    c1.4-1.3,1.1-2.8,1.1-2.8v0c0,0-0.2-0.1-0.5-0.2C455.3,80,455.2,79.9,455.1,79.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6638_">
                      <g id="XMLID_6645_">
                        <path
                          id="XMLID_6646_"
                          style="fill: #ffffff"
                          d="M456.7,81.5c0,0-0.2,1.7,1.4,3c1.3,1,3.4,1.3,4.3,1.4
                    c0.2,0,0.3-0.2,0.3-0.4c-0.3-0.9-1.2-2.9-2.5-3.8C458.6,80.6,456.7,81,456.7,81.5z"
                        />
                      </g>
                      <g id="XMLID_6639_">
                        <path
                          id="XMLID_6640_"
                          d="M462.4,86.3C462.4,86.3,462.4,86.3,462.4,86.3c-0.9-0.1-3.2-0.4-4.5-1.5
                    c-1.7-1.4-1.5-3.1-1.5-3.3c0-0.1,0.1-0.4,0.4-0.6c0.7-0.3,2.2-0.4,3.5,0.6c1.3,1,2.2,3.1,2.6,4c0.1,0.2,0,0.4-0.1,0.6
                    C462.8,86.2,462.6,86.3,462.4,86.3z M457,81.6L457,81.6c0,0.1-0.2,1.6,1.3,2.8c1.1,0.9,3.1,1.2,4,1.3
                    c-0.3-0.9-1.2-2.8-2.3-3.6c-0.9-0.6-1.8-0.8-2.5-0.6C457.2,81.4,457.1,81.5,457,81.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6628_">
                      <g id="XMLID_6635_">
                        <path
                          id="XMLID_6636_"
                          style="fill: #ffffff"
                          d="M453.9,81.1c0,0-0.8,1.5,0.2,3.3c0.8,1.4,2.7,2.5,3.5,2.9
                    c0.2,0.1,0.4,0,0.4-0.2c0-0.9-0.1-3.2-0.9-4.5C455.9,80.9,454,80.6,453.9,81.1z"
                        />
                      </g>
                      <g id="XMLID_6629_">
                        <path
                          id="XMLID_6630_"
                          d="M457.6,87.7c-0.1,0-0.2,0-0.3-0.1c-0.8-0.4-2.8-1.5-3.6-3c-1-1.9-0.3-3.4-0.2-3.6
                    c0.1-0.1,0.2-0.3,0.6-0.4c0.8-0.1,2.2,0.4,3.1,1.8c0.9,1.4,1,3.7,1,4.7c0,0.2-0.1,0.4-0.3,0.5
                    C457.8,87.6,457.7,87.7,457.6,87.7z M454.1,81.3c0,0.1-0.7,1.4,0.2,3c0.7,1.3,2.4,2.3,3.2,2.7c0-0.9-0.1-3-0.9-4.2
                    c-0.6-0.9-1.4-1.4-2.1-1.5C454.4,81.2,454.2,81.2,454.1,81.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6618_">
                      <g id="XMLID_6625_">
                        <path
                          id="XMLID_6626_"
                          style="fill: #ffffff"
                          d="M457.4,82.5c0,0,0.5-1.6,2.5-2.2c1.6-0.4,3.6,0.2,4.5,0.5
                    c0.2,0.1,0.2,0.3,0.1,0.4c-0.7,0.6-2.3,2.1-3.9,2.5C458.7,84.2,457.2,83,457.4,82.5z"
                        />
                      </g>
                      <g id="XMLID_6619_">
                        <path
                          id="XMLID_6621_"
                          d="M459.9,84.2c-1.2,0-2.2-0.5-2.6-1c-0.3-0.3-0.2-0.6-0.2-0.7c0.1-0.2,0.7-1.8,2.8-2.4
                    c1.7-0.4,3.8,0.2,4.6,0.5c0.2,0.1,0.3,0.2,0.4,0.4c0,0.2,0,0.4-0.2,0.5c-0.7,0.7-2.4,2.2-4,2.5
                    C460.4,84.1,460.1,84.2,459.9,84.2z M457.7,82.6c0,0.1,0.1,0.2,0.4,0.4c0.5,0.4,1.4,0.7,2.5,0.4c1.4-0.3,3-1.7,3.6-2.3
                    c-0.8-0.3-2.7-0.9-4.2-0.5C458.2,81.2,457.7,82.6,457.7,82.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6608_">
                      <g id="XMLID_6615_">
                        <path
                          id="XMLID_6617_"
                          style="fill: #ffffff"
                          d="M456.4,81.1c0,0,1.6-0.6,2.1-2.6c0.3-1.6-0.4-3.6-0.7-4.4
                    c-0.1-0.2-0.3-0.2-0.4-0.1c-0.6,0.7-2,2.4-2.3,4C454.7,79.8,455.9,81.3,456.4,81.1z"
                        />
                      </g>
                      <g id="XMLID_6610_">
                        <path
                          id="XMLID_6611_"
                          d="M456.3,81.4c-0.1,0-0.3,0-0.5-0.2c-0.6-0.4-1.4-1.8-1.1-3.4c0.3-1.6,1.7-3.4,2.3-4.1
                    c0.1-0.2,0.3-0.2,0.5-0.2c0.2,0,0.4,0.2,0.4,0.4c0.3,0.8,1.1,2.9,0.7,4.6c-0.5,2.1-2,2.8-2.2,2.9
                    C456.4,81.4,456.4,81.4,456.3,81.4z M457.4,74.2c-0.6,0.7-1.9,2.3-2.1,3.7c-0.2,1.1,0.2,2,0.6,2.5c0.2,0.3,0.3,0.3,0.4,0.4
                    v0c0.1,0,1.5-0.6,1.9-2.4C458.4,76.9,457.8,75,457.4,74.2z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6599_">
                      <g id="XMLID_6606_">
                        <path
                          id="XMLID_6607_"
                          style="fill: #ffffff"
                          d="M461.5,79.2c0,0,1.7,0.3,3.1-1.2c1.1-1.2,1.6-3.3,1.7-4.2
                    c0-0.2-0.1-0.3-0.3-0.3c-0.9,0.3-3,1-4,2.2C460.7,77.2,461,79.1,461.5,79.2z"
                        />
                      </g>
                      <g id="XMLID_6600_">
                        <path
                          id="XMLID_6601_"
                          d="M461.9,79.5c-0.2,0-0.4,0-0.5,0c-0.1,0-0.4-0.1-0.5-0.5c-0.3-0.7-0.2-2.2,0.8-3.5
                    c1.1-1.2,3.3-2,4.2-2.3c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.2,0.3,0.2,0.5c-0.1,0.9-0.6,3.1-1.8,4.3
                    C463.8,79.3,462.6,79.5,461.9,79.5z M461.6,78.9c0.1,0,1.5,0.3,2.8-1.1c1-1.1,1.5-3,1.6-3.9c-0.9,0.3-2.9,1-3.8,2.1
                    c-0.7,0.8-0.9,1.8-0.8,2.4C461.4,78.7,461.5,78.8,461.6,78.9L461.6,78.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6590_">
                      <g id="XMLID_6596_">
                        <path
                          id="XMLID_6597_"
                          style="fill: #ffffff"
                          d="M451.6,83.8c0,0-1.4-1-3.3-0.1c-1.5,0.7-2.7,2.4-3.2,3.2
                    c-0.1,0.2,0,0.4,0.2,0.4c0.9,0.1,3.2,0.2,4.5-0.5C451.6,85.9,452.1,84,451.6,83.8z"
                        />
                      </g>
                      <g id="XMLID_6591_">
                        <path
                          id="XMLID_6592_"
                          d="M446.8,87.7c-0.6,0-1.1,0-1.5-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4,0-0.6
                    c0.5-0.8,1.7-2.6,3.3-3.3c2-0.9,3.5,0,3.6,0.1c0.1,0.1,0.3,0.2,0.3,0.7c0,0.8-0.6,2.1-2.1,2.9
                    C449.1,87.5,447.8,87.7,446.8,87.7z M445.4,87c0.9,0.1,3,0.2,4.3-0.5c1-0.5,1.5-1.3,1.7-1.9c0.1-0.3,0.1-0.5,0.1-0.5v0
                    c-0.1,0-1.3-0.8-3-0.1C447.1,84.6,445.9,86.2,445.4,87z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6580_">
                      <g id="XMLID_6587_">
                        <path
                          id="XMLID_6588_"
                          style="fill: #ffffff"
                          d="M465,94.9c0,0-1.7,0.2-3.1-1.4c-1.1-1.2-1.4-3.4-1.4-4.2
                    c0-0.2,0.2-0.3,0.4-0.3c0.9,0.3,2.9,1.2,3.9,2.4C465.9,93.1,465.4,94.9,465,94.9z"
                        />
                      </g>
                      <g id="XMLID_6581_">
                        <path
                          id="XMLID_6583_"
                          d="M464.7,95.3c-0.6,0-1.9-0.2-3.1-1.5c-1.1-1.3-1.4-3.5-1.5-4.4c0-0.2,0.1-0.4,0.2-0.5
                    c0.2-0.1,0.4-0.2,0.6-0.1c0.9,0.3,3,1.2,4,2.5c1,1.3,1,2.8,0.6,3.5c-0.2,0.4-0.4,0.4-0.6,0.4
                    C464.9,95.3,464.8,95.3,464.7,95.3z M460.8,89.4c0.1,0.9,0.4,2.8,1.4,4c1.2,1.5,2.8,1.3,2.8,1.3l0,0c0,0,0.1-0.2,0.2-0.5
                    c0.1-0.6,0-1.6-0.7-2.5C463.6,90.5,461.7,89.7,460.8,89.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6570_">
                      <g id="XMLID_6577_">
                        <path
                          id="XMLID_6579_"
                          style="fill: #ffffff"
                          d="M464.2,92.7c0,0-1.4-1-1.4-3.1c0-1.6,1.2-3.4,1.8-4.1
                    c0.1-0.2,0.4-0.1,0.4,0c0.4,0.8,1.4,2.9,1.3,4.4C466.1,91.9,464.6,93,464.2,92.7z"
                        />
                      </g>
                      <g id="XMLID_6572_">
                        <path
                          id="XMLID_6573_"
                          d="M464.4,93c-0.2,0-0.3-0.1-0.4-0.1c-0.2-0.1-1.5-1.2-1.5-3.3c0-1.7,1.3-3.6,1.8-4.3
                    c0.1-0.2,0.3-0.3,0.5-0.2c0.2,0,0.4,0.1,0.5,0.3c0.4,0.8,1.4,2.9,1.3,4.6c-0.1,1.6-1.2,2.8-1.9,3
                    C464.6,93,464.5,93,464.4,93z M464.8,85.7c-0.5,0.7-1.6,2.4-1.6,3.9c-0.1,1.9,1.2,2.8,1.2,2.8v0c0.1,0,0.2-0.1,0.5-0.3
                    c0.5-0.4,1-1.2,1.1-2.3C466.1,88.5,465.2,86.6,464.8,85.7z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6560_">
                      <g id="XMLID_6567_">
                        <path
                          id="XMLID_6569_"
                          style="fill: #ffffff"
                          d="M456.4,84.5c0,0-1.4-1-3.3-0.1c-1.5,0.7-2.7,2.4-3.2,3.2
                    c-0.1,0.2,0,0.4,0.2,0.4c0.9,0.1,3.2,0.2,4.5-0.5C456.3,86.6,456.8,84.7,456.4,84.5z"
                        />
                      </g>
                      <g id="XMLID_6562_">
                        <path
                          id="XMLID_6563_"
                          d="M451.5,88.3c-0.6,0-1.1,0-1.5-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4,0-0.6
                    c0.5-0.8,1.7-2.6,3.3-3.3c2-0.9,3.5,0,3.6,0.1c0.1,0.1,0.3,0.2,0.3,0.7c0,0.8-0.6,2.1-2.1,2.9
                    C453.8,88.2,452.5,88.3,451.5,88.3z M450.2,87.6c0.9,0.1,3,0.2,4.3-0.5c1-0.5,1.5-1.3,1.7-1.9c0.1-0.3,0.1-0.5,0.1-0.5l0,0
                    c-0.1,0-1.3-0.8-3-0.1C451.8,85.3,450.6,86.9,450.2,87.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6546_">
                      <g id="XMLID_6556_">
                        <path
                          id="XMLID_6558_"
                          style="fill: #ffffff"
                          d="M457.8,83.3c0,0-1.7-0.1-3,1.6c-1,1.3-1.1,3.4-1.2,4.3
                    c0,0.2,0.2,0.3,0.4,0.2c0.8-0.4,2.9-1.4,3.7-2.7C458.9,85.2,458.3,83.3,457.8,83.3z"
                        />
                      </g>
                      <g id="XMLID_6548_">
                        <path
                          id="XMLID_6550_"
                          d="M454,89.8c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.9,0.2-3.1,1.2-4.5
                    c1.3-1.7,3-1.7,3.2-1.7c0.2,0,0.4,0.1,0.6,0.4c0.4,0.6,0.5,2.2-0.4,3.6c-0.9,1.4-3,2.4-3.8,2.8
                    C454.1,89.8,454.1,89.8,454,89.8z M457.7,83.7c-0.3,0-1.6,0.1-2.6,1.4c-0.9,1.2-1.1,3.2-1.1,4.1c0.8-0.4,2.7-1.3,3.5-2.5
                    c0.6-0.9,0.6-1.9,0.5-2.5C458,83.8,457.9,83.7,457.7,83.7L457.7,83.7C457.8,83.7,457.8,83.7,457.7,83.7z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6532_">
                      <g id="XMLID_6542_">
                        <path
                          id="XMLID_6544_"
                          style="fill: #ffffff"
                          d="M453.8,83.1c0,0-0.9,1.5-2.9,1.6c-1.6,0.1-3.5-1-4.3-1.4
                    c-0.2-0.1-0.2-0.3,0-0.4c0.8-0.5,2.7-1.6,4.3-1.6C452.8,81.3,454.1,82.7,453.8,83.1z"
                        />
                      </g>
                      <g id="XMLID_6534_">
                        <path
                          id="XMLID_6536_"
                          d="M450.6,85c-1.7,0-3.5-1-4.3-1.5c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5
                    c0.8-0.5,2.8-1.6,4.5-1.6c1.6,0,2.8,1,3.2,1.7c0.2,0.4,0.1,0.6,0,0.7c-0.1,0.2-1.1,1.6-3.2,1.7C450.8,85,450.7,85,450.6,85
                    z M446.8,83c0.8,0.5,2.5,1.4,4,1.3c1.9-0.1,2.7-1.4,2.7-1.4c0-0.1-0.1-0.2-0.3-0.4c-0.4-0.5-1.3-0.9-2.4-1
                    C449.5,81.5,447.6,82.6,446.8,83z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6518_">
                      <g id="XMLID_6528_">
                        <path
                          id="XMLID_6530_"
                          style="fill: #ffffff"
                          d="M455.5,81.5c0,0-1.4,1-3.3,0.2c-1.5-0.6-2.7-2.4-3.2-3.1
                    c-0.1-0.2,0-0.4,0.2-0.4c0.9-0.1,3.2-0.3,4.6,0.4C455.5,79.5,455.9,81.3,455.5,81.5z"
                        />
                      </g>
                      <g id="XMLID_6520_">
                        <path
                          id="XMLID_6522_"
                          d="M453.7,82.4c-0.5,0-1.1-0.1-1.7-0.3c-1.6-0.7-2.9-2.5-3.4-3.2c-0.1-0.2-0.1-0.4,0-0.6
                    c0.1-0.2,0.3-0.3,0.5-0.3c0.9-0.1,3.3-0.3,4.7,0.4c1.5,0.7,2.1,2.1,2.2,2.9c0,0.4-0.2,0.6-0.3,0.7
                    C455.5,81.9,454.8,82.4,453.7,82.4z M449.3,78.5c0.5,0.7,1.6,2.3,3,2.9c1.7,0.7,3-0.1,3-0.1l0,0c0-0.1,0-0.2-0.1-0.5
                    c-0.2-0.6-0.7-1.4-1.7-1.9C452.3,78.3,450.2,78.4,449.3,78.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6504_">
                      <g id="XMLID_6514_">
                        <path
                          id="XMLID_6516_"
                          style="fill: #ffffff"
                          d="M453.9,78.7c0,0-1.7-0.4-2.3-2.4c-0.5-1.5,0-3.6,0.2-4.5
                    c0.1-0.2,0.3-0.3,0.4-0.1c0.7,0.6,2.3,2.2,2.7,3.7C455.5,77.3,454.4,78.9,453.9,78.7z"
                        />
                      </g>
                      <g id="XMLID_6506_">
                        <path
                          id="XMLID_6508_"
                          d="M454,79c-0.1,0-0.1,0-0.2,0c-0.2-0.1-1.9-0.6-2.5-2.6c-0.5-1.6,0-3.8,0.2-4.7
                    c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4,0,0.5,0.1c0.7,0.6,2.4,2.3,2.8,3.8c0.4,1.6-0.2,3-0.7,3.5C454.3,79,454.2,79,454,79z
                     M452.1,72c-0.2,0.9-0.7,2.8-0.2,4.2c0.6,1.8,2.1,2.2,2.1,2.2l0,0c0,0,0.2-0.1,0.4-0.4c0.3-0.5,0.6-1.5,0.3-2.5
                    C454.3,74.1,452.8,72.6,452.1,72z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6490_">
                      <g id="XMLID_6500_">
                        <path
                          id="XMLID_6502_"
                          style="fill: #ffffff"
                          d="M465.3,94.8c0,0-0.2-1.7,1.4-3.1c1.2-1.1,3.4-1.4,4.2-1.4
                    c0.2,0,0.3,0.2,0.3,0.4c-0.3,0.9-1.2,2.9-2.4,3.9C467.2,95.8,465.3,95.3,465.3,94.8z"
                        />
                      </g>
                      <g id="XMLID_6492_">
                        <path
                          id="XMLID_6494_"
                          d="M466.6,95.6c-0.4,0-0.8-0.1-1.1-0.2c-0.4-0.2-0.4-0.4-0.4-0.6c0-0.2-0.2-1.9,1.5-3.3
                    c1.3-1.1,3.5-1.4,4.4-1.5c0.2,0,0.4,0.1,0.5,0.2c0.1,0.2,0.2,0.4,0.1,0.6c-0.3,0.9-1.2,3-2.5,4
                    C468.2,95.4,467.3,95.6,466.6,95.6z M465.7,94.8c0,0,0.2,0.1,0.5,0.2c0.6,0.1,1.6,0,2.5-0.7c1.1-0.8,2-2.8,2.3-3.6
                    c-0.9,0.1-2.8,0.4-4,1.4C465.5,93.3,465.7,94.8,465.7,94.8L465.7,94.8z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6476_">
                      <g id="XMLID_6486_">
                        <path
                          id="XMLID_6488_"
                          style="fill: #ffffff"
                          d="M457,84.4c0,0-1.2,1.2-0.8,3.3c0.3,1.6,1.8,3.1,2.5,3.8
                    c0.1,0.1,0.4,0.1,0.4-0.1c0.3-0.9,0.9-3.1,0.5-4.5C459.1,84.8,457.3,84,457,84.4z"
                        />
                      </g>
                      <g id="XMLID_6478_">
                        <path
                          id="XMLID_6480_"
                          d="M458.9,91.8c-0.1,0-0.3-0.1-0.4-0.2c-0.7-0.6-2.2-2.2-2.6-3.9c-0.4-2.1,0.7-3.4,0.9-3.5
                    c0.1-0.1,0.3-0.3,0.7-0.2c0.7,0.1,2,1.1,2.4,2.7c0.4,1.6-0.2,3.8-0.5,4.7c-0.1,0.2-0.2,0.3-0.4,0.4
                    C459,91.8,458.9,91.8,458.9,91.8z M457.2,84.6L457.2,84.6c0,0-1.1,1.1-0.7,3c0.3,1.5,1.7,2.9,2.3,3.5
                    c0.3-0.9,0.8-2.9,0.4-4.3c-0.3-1-1-1.7-1.5-2C457.5,84.6,457.3,84.6,457.2,84.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6462_">
                      <g id="XMLID_6472_">
                        <path
                          id="XMLID_6474_"
                          style="fill: #ffffff"
                          d="M465.6,89.9c0,0-0.3-1.7,1.2-3.1c1.2-1.1,3.3-1.6,4.2-1.7
                    c0.2,0,0.3,0.2,0.3,0.3c-0.3,0.9-1,3-2.2,4C467.5,90.7,465.6,90.4,465.6,89.9z"
                        />
                      </g>
                      <g id="XMLID_6464_">
                        <path
                          id="XMLID_6466_"
                          d="M466.7,90.6c-0.4,0-0.7-0.1-0.9-0.2c-0.4-0.2-0.5-0.4-0.5-0.5c0-0.2-0.3-1.9,1.3-3.4
                    c1.3-1.2,3.4-1.6,4.3-1.8c0.2,0,0.4,0,0.5,0.2c0.1,0.2,0.2,0.4,0.1,0.6c-0.3,0.9-1.1,3.1-2.3,4.2
                    C468.4,90.4,467.4,90.6,466.7,90.6z M465.9,89.8c0,0,0.2,0.1,0.5,0.2c0.6,0.1,1.6-0.1,2.4-0.8c1.1-0.9,1.8-2.9,2.1-3.8
                    c-0.9,0.1-2.8,0.6-3.9,1.6C465.6,88.3,465.9,89.8,465.9,89.8L465.9,89.8z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6448_">
                      <g id="XMLID_6458_">
                        <path
                          id="XMLID_6460_"
                          style="fill: #ffffff"
                          d="M464.5,94.7c0,0-0.8-1.5,0.2-3.3c0.8-1.4,2.7-2.5,3.5-2.8
                    c0.2-0.1,0.4,0,0.4,0.2c0,0.9-0.1,3.2-0.9,4.5C466.6,94.9,464.7,95.2,464.5,94.7z"
                        />
                      </g>
                      <g id="XMLID_6450_">
                        <path
                          id="XMLID_6452_"
                          d="M465,95.3c-0.1,0-0.1,0-0.2,0c-0.4,0-0.6-0.2-0.6-0.4c-0.1-0.2-0.8-1.8,0.2-3.6
                    c0.8-1.5,2.8-2.6,3.6-3c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.3,0.3,0.3,0.5c0,0.9-0.1,3.3-1,4.6
                    C467.1,94.7,465.8,95.3,465,95.3z M464.8,94.6c0.1,0,0.2,0.1,0.5,0c0.6-0.1,1.5-0.6,2.1-1.5c0.8-1.2,0.9-3.3,0.9-4.2
                    c-0.8,0.4-2.5,1.4-3.3,2.7C464.1,93.2,464.8,94.6,464.8,94.6L464.8,94.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6434_">
                      <g id="XMLID_6444_">
                        <path
                          id="XMLID_6446_"
                          style="fill: #ffffff"
                          d="M464.1,95.5c0,0,0.5,1.6,2.6,2.2c1.6,0.4,3.6-0.2,4.5-0.5
                    c0.2-0.1,0.2-0.3,0.1-0.4c-0.7-0.6-2.3-2.1-3.9-2.4C465.4,93.8,463.9,95,464.1,95.5z"
                        />
                      </g>
                      <g id="XMLID_6436_">
                        <path
                          id="XMLID_6438_"
                          d="M467.8,98.1c-0.4,0-0.8,0-1.2-0.1c-2.1-0.5-2.7-2.2-2.8-2.4c-0.1-0.1-0.1-0.4,0.2-0.7
                    c0.5-0.6,1.8-1.3,3.4-1c1.6,0.3,3.3,1.9,4,2.5c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.2,0.4-0.4,0.4
                    C470.6,97.6,469.2,98.1,467.8,98.1z M464.4,95.4c0,0.1,0.5,1.5,2.3,2c1.4,0.4,3.3-0.2,4.2-0.5c-0.7-0.6-2.3-2-3.6-2.3
                    c-1.1-0.2-2,0.1-2.5,0.4C464.5,95.2,464.5,95.3,464.4,95.4L464.4,95.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6423_">
                      <g id="XMLID_6430_">
                        <path
                          id="XMLID_6432_"
                          style="fill: #ffffff"
                          d="M464.8,96.6c0,0-0.8,1.5,0.3,3.3c0.8,1.4,2.8,2.4,3.6,2.7
                    c0.2,0.1,0.4-0.1,0.4-0.2c0-0.9-0.2-3.2-1.1-4.4C466.9,96.3,465,96.1,464.8,96.6z"
                        />
                      </g>
                      <g id="XMLID_6424_">
                        <path
                          id="XMLID_6426_"
                          d="M468.8,103c-0.1,0-0.2,0-0.2-0.1c-0.8-0.4-2.8-1.4-3.7-2.8c-1.1-1.8-0.4-3.4-0.4-3.6
                    c0.1-0.1,0.2-0.3,0.6-0.4c0.7-0.1,2.2,0.4,3.1,1.7c0.9,1.3,1.1,3.7,1.1,4.6c0,0.2-0.1,0.4-0.3,0.5
                    C469.1,103,469,103,468.8,103z M465.1,96.7c0,0.1-0.7,1.4,0.3,3c0.8,1.3,2.5,2.2,3.3,2.6c0-0.9-0.2-3-1-4.2
                    c-0.6-0.9-1.5-1.3-2.1-1.4C465.3,96.7,465.2,96.7,465.1,96.7L465.1,96.7z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6413_">
                      <g id="XMLID_6420_">
                        <path
                          id="XMLID_6422_"
                          style="fill: #ffffff"
                          d="M456.6,82.8c0,0,1.4-1,3.3-0.1c1.5,0.6,2.7,2.4,3.2,3.2
                    c0.1,0.2,0,0.4-0.2,0.4c-0.9,0.1-3.2,0.2-4.5-0.4C456.7,84.9,456.2,83,456.6,82.8z"
                        />
                      </g>
                      <g id="XMLID_6415_">
                        <path
                          id="XMLID_6416_"
                          d="M461.4,86.6c-1,0-2.3-0.1-3.2-0.6c-1.5-0.7-2.1-2.1-2.1-2.9c0-0.4,0.2-0.6,0.3-0.7
                    c0.2-0.1,1.7-1,3.6-0.2c1.6,0.7,2.9,2.5,3.3,3.3c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.3-0.5,0.3
                    C462.6,86.6,462.1,86.6,461.4,86.6z M456.8,83.1c0,0.1,0,0.2,0.1,0.5c0.2,0.6,0.7,1.4,1.7,1.9c1.3,0.6,3.4,0.5,4.3,0.4
                    c-0.5-0.7-1.6-2.4-3-3C458.1,82.2,456.8,83.1,456.8,83.1L456.8,83.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6403_">
                      <g id="XMLID_6410_">
                        <path
                          id="XMLID_6412_"
                          style="fill: #ffffff"
                          d="M457.8,81.8c0,0,1.6-0.5,2.2-2.5c0.4-1.6-0.2-3.6-0.5-4.5
                    c-0.1-0.2-0.3-0.2-0.4-0.1c-0.6,0.7-2.2,2.3-2.5,3.8C456.2,80.4,457.3,82,457.8,81.8z"
                        />
                      </g>
                      <g id="XMLID_6405_">
                        <path
                          id="XMLID_6406_"
                          d="M457.7,82.1c-0.1,0-0.3,0-0.5-0.2c-0.6-0.5-1.3-1.9-0.9-3.5c0.3-1.6,1.9-3.3,2.6-4
                    c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.2,0.4,0.4c0.3,0.8,0.9,3,0.5,4.7c-0.6,2.1-2.2,2.7-2.4,2.7
                    C457.8,82.1,457.8,82.1,457.7,82.1z M459.2,75c-0.7,0.7-2,2.2-2.3,3.6c-0.2,1.1,0.1,2,0.4,2.5c0.2,0.3,0.3,0.4,0.4,0.4l0,0
                    c0.1,0,1.5-0.5,2-2.3C460,77.7,459.5,75.8,459.2,75z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6394_">
                      <g id="XMLID_6401_">
                        <path
                          id="XMLID_6402_"
                          style="fill: #ffffff"
                          d="M458.5,84.6c0,0,1.7,0.1,3-1.5c1-1.3,1.2-3.4,1.2-4.3
                    c0-0.2-0.2-0.3-0.4-0.2c-0.8,0.4-2.9,1.4-3.7,2.7C457.5,82.7,458.1,84.6,458.5,84.6z"
                        />
                      </g>
                      <g id="XMLID_6395_">
                        <path
                          id="XMLID_6396_"
                          d="M458.6,84.9C458.6,84.9,458.6,84.9,458.6,84.9c-0.1,0-0.1,0-0.1,0c-0.1,0-0.4-0.1-0.6-0.4
                    c-0.4-0.7-0.5-2.2,0.4-3.5c0.9-1.4,3-2.4,3.9-2.8c0.2-0.1,0.4-0.1,0.6,0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0.9-0.2,3.1-1.3,4.5
                    C460.6,84.8,459.1,84.9,458.6,84.9z M458.6,84.2c0.1,0,1.6,0.1,2.7-1.4c0.9-1.2,1.1-3.2,1.1-4.1c-0.9,0.4-2.7,1.3-3.5,2.5
                    c-0.6,0.9-0.7,1.9-0.5,2.5C458.4,84.1,458.5,84.2,458.6,84.2z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6384_">
                      <g id="XMLID_6391_">
                        <path
                          id="XMLID_6392_"
                          style="fill: #ffffff"
                          d="M458,81.9c0,0-1.7,0.2-3-1.4c-1-1.3-1.3-3.4-1.4-4.3
                    c0-0.2,0.2-0.3,0.4-0.3c0.9,0.3,2.9,1.3,3.8,2.5C458.9,80.1,458.5,82,458,81.9z"
                        />
                      </g>
                      <g id="XMLID_6385_">
                        <path
                          id="XMLID_6387_"
                          d="M457.8,82.3c-0.5,0-1.9-0.1-3.1-1.6c-1.1-1.3-1.4-3.6-1.4-4.5c0-0.2,0.1-0.4,0.2-0.5
                    c0.2-0.1,0.4-0.1,0.6-0.1c0.9,0.3,3,1.3,4,2.6c1,1.3,0.9,2.9,0.5,3.5c-0.2,0.4-0.4,0.4-0.6,0.4
                    C458,82.3,457.9,82.3,457.8,82.3z M453.9,76.3c0.1,0.9,0.3,2.8,1.3,4c1.2,1.5,2.7,1.3,2.7,1.3h0c0,0,0.1-0.2,0.2-0.5
                    c0.1-0.6,0-1.6-0.6-2.5C456.7,77.5,454.8,76.7,453.9,76.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6374_">
                      <g id="XMLID_6381_">
                        <path
                          id="XMLID_6382_"
                          style="fill: #ffffff"
                          d="M458.2,82c0,0-1.2-1.2-3.3-0.8c-1.6,0.3-3.1,1.8-3.8,2.5
                    c-0.1,0.1-0.1,0.4,0.1,0.4c0.9,0.3,3.1,0.9,4.5,0.5C457.8,84.1,458.6,82.3,458.2,82z"
                        />
                      </g>
                      <g id="XMLID_6376_">
                        <path
                          id="XMLID_6377_"
                          d="M454.7,85.1c-1.3,0-2.8-0.4-3.5-0.6c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2,0-0.4,0.1-0.5
                    c0.6-0.7,2.2-2.2,3.9-2.6c2.1-0.4,3.4,0.7,3.5,0.9c0.1,0.1,0.3,0.3,0.2,0.7c-0.1,0.7-1.1,2-2.6,2.4
                    C455.6,85,455.1,85.1,454.7,85.1z M451.5,83.9c0.9,0.3,2.9,0.8,4.3,0.4c1-0.3,1.7-1,2-1.5c0.2-0.3,0.2-0.5,0.2-0.5v0
                    c0,0-1.1-1.1-3-0.7C453.6,81.9,452.1,83.2,451.5,83.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6365_">
                      <g id="XMLID_6371_">
                        <path
                          id="XMLID_6373_"
                          style="fill: #ffffff"
                          d="M457.2,97.5c0,0-0.6-1.6-2.7-2c-1.6-0.3-3.6,0.5-4.4,0.9
                    c-0.2,0.1-0.2,0.3-0.1,0.4c0.7,0.6,2.5,2,4,2.1C456,99.2,457.5,97.9,457.2,97.5z"
                        />
                      </g>
                      <g id="XMLID_6366_">
                        <path
                          id="XMLID_6368_"
                          d="M454.5,99.3c-0.2,0-0.3,0-0.5,0c-1.6-0.2-3.5-1.6-4.2-2.2c-0.2-0.1-0.2-0.3-0.2-0.5
                    c0-0.2,0.2-0.4,0.3-0.5c0.8-0.4,2.9-1.2,4.6-0.9c2.1,0.4,2.9,1.9,2.9,2.1c0.1,0.1,0.1,0.4-0.1,0.7
                    C457,98.6,455.9,99.3,454.5,99.3z M450.3,96.6c0.7,0.6,2.4,1.8,3.8,2c1.1,0.1,2-0.2,2.5-0.6c0.2-0.2,0.3-0.4,0.3-0.4v0
                    c0-0.1-0.6-1.4-2.5-1.8C453,95.6,451.1,96.3,450.3,96.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6355_">
                      <g id="XMLID_6362_">
                        <path
                          id="XMLID_6364_"
                          style="fill: #ffffff"
                          d="M457.9,97.5c0,0-1.4-1.1-3.3-0.3c-1.5,0.5-2.9,2.2-3.4,2.9
                    c-0.1,0.2,0,0.4,0.2,0.4c0.9,0.2,3.1,0.4,4.6-0.2C457.7,99.6,458.3,97.8,457.9,97.5z"
                        />
                      </g>
                      <g id="XMLID_6357_">
                        <path
                          id="XMLID_6358_"
                          d="M453.6,101.1c-1,0-1.8-0.1-2.3-0.2c-0.2,0-0.4-0.2-0.4-0.4c-0.1-0.2-0.1-0.4,0.1-0.6
                    c0.5-0.7,1.9-2.5,3.5-3.1c2-0.7,3.4,0.3,3.6,0.4c0.1,0.1,0.3,0.3,0.3,0.7c0,0.8-0.8,2.1-2.3,2.7
                    C455.3,101,454.4,101.1,453.6,101.1z M451.5,100.3c0.9,0.1,3,0.4,4.3-0.2c1-0.4,1.6-1.2,1.8-1.8c0.1-0.3,0.1-0.5,0.1-0.5
                    c-0.1,0-1.3-0.9-3-0.3C453.2,98,452,99.5,451.5,100.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6347_">
                      <g id="XMLID_6353_">
                        <path
                          id="XMLID_6354_"
                          style="fill: #ffffff"
                          d="M457.8,97.8c0,0-1.7,0.3-2.5,2.3c-0.6,1.5-0.2,3.6-0.1,4.5
                    c0,0.2,0.3,0.3,0.4,0.1c0.7-0.6,2.4-2.1,2.9-3.5C459.3,99.3,458.3,97.6,457.8,97.8z"
                        />
                      </g>
                      <g id="XMLID_6348_">
                        <path
                          id="XMLID_6349_"
                          d="M455.5,105.1c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.4-0.4c-0.2-0.9-0.6-3.1,0.1-4.7
                    c0.8-2,2.5-2.4,2.7-2.4c0.1,0,0.4,0,0.7,0.2c0.5,0.5,1,2,0.5,3.5c-0.5,1.5-2.3,3.1-3,3.7
                    C455.8,105,455.7,105.1,455.5,105.1z M457.9,98.1L457.9,98.1c-0.1,0-1.5,0.3-2.2,2.1c-0.6,1.4-0.2,3.3-0.1,4.2
                    c0.7-0.6,2.3-2,2.7-3.3c0.3-1,0.2-2-0.1-2.5C458.1,98.2,457.9,98.1,457.9,98.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6337_">
                      <g id="XMLID_6344_">
                        <path
                          id="XMLID_6345_"
                          style="fill: #ffffff"
                          d="M459.1,97.5c0,0,0.2-1.7-1.4-3.1c-1.2-1.1-3.4-1.4-4.2-1.5
                    c-0.2,0-0.3,0.2-0.3,0.4c0.3,0.9,1.2,2.9,2.4,3.9C457.3,98.4,459.1,98,459.1,97.5z"
                        />
                      </g>
                      <g id="XMLID_6338_">
                        <path
                          id="XMLID_6340_"
                          d="M457.9,98.3c-0.7,0-1.6-0.2-2.4-0.8c-1.3-1-2.2-3.1-2.5-4c-0.1-0.2,0-0.4,0.1-0.6
                    c0.1-0.2,0.3-0.2,0.5-0.2c0.9,0.1,3.1,0.4,4.4,1.5c1.6,1.4,1.5,3.1,1.5,3.3c0,0.1-0.1,0.4-0.5,0.6
                    C458.8,98.3,458.4,98.3,457.9,98.3z M453.6,93.4c0.3,0.9,1.2,2.8,2.3,3.6c0.9,0.7,1.8,0.8,2.4,0.7c0.3,0,0.5-0.1,0.5-0.2h0
                    c0-0.1,0.2-1.6-1.3-2.8C456.4,93.8,454.5,93.5,453.6,93.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6327_">
                      <g id="XMLID_6334_">
                        <path
                          id="XMLID_6335_"
                          style="fill: #ffffff"
                          d="M453.4,78.9c0,0-0.4-1.7-2.4-2.4c-1.5-0.5-3.6-0.1-4.5,0.1
                    c-0.2,0.1-0.3,0.3-0.1,0.4c0.6,0.7,2.2,2.3,3.6,2.8C451.9,80.4,453.5,79.4,453.4,78.9z"
                        />
                      </g>
                      <g id="XMLID_6328_">
                        <path
                          id="XMLID_6330_"
                          d="M451.1,80.3c-0.4,0-0.8-0.1-1.2-0.2c-1.6-0.5-3.2-2.2-3.8-2.9c-0.1-0.2-0.2-0.4-0.1-0.6
                    c0.1-0.2,0.2-0.3,0.4-0.4c0.9-0.2,3.1-0.7,4.7-0.1c2,0.7,2.5,2.4,2.6,2.6c0,0.1,0.1,0.4-0.2,0.7
                    C453.1,79.9,452.2,80.3,451.1,80.3z M446.7,76.9c0.6,0.7,2.1,2.2,3.4,2.6c1,0.3,2,0.1,2.5-0.2c0.3-0.2,0.4-0.3,0.4-0.3h0
                    c0-0.1-0.4-1.5-2.2-2.1C449.5,76.3,447.6,76.7,446.7,76.9z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_6321_">
                  <g id="XMLID_6322_">
                    <path
                      id="XMLID_6323_"
                      style="fill: #ffffff"
                      d="M458,94.7c-0.1-1.3-0.7-2.6-0.7-2.6l1.2-0.5c0,0.1,0.6,1.5,0.8,3L458,94.7z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_5900_">
                <g id="XMLID_6306_">
                  <g id="XMLID_6316_">
                    <path
                      id="XMLID_6318_"
                      style="fill: #ffffff"
                      d="M433.2,78.5c0.9,3.1,2.8,8.7,5.9,12.2c0.4,0.4,0.3,1.1-0.2,1.4
                c-1.9,1.2-6.3,3.5-10.2,1.1c-3.8-2.4,0.4-11,2.7-14.9C431.9,77.5,433,77.7,433.2,78.5z"
                    />
                  </g>
                  <g id="XMLID_6308_">
                    <path
                      id="XMLID_6310_"
                      d="M432.6,94.7c-1.3,0-2.7-0.3-4-1.1c-4-2.5-0.1-10.8,2.5-15.5c0.3-0.5,0.8-0.8,1.4-0.7
                c0.6,0.1,1,0.4,1.2,1l0,0c0.9,3,2.8,8.6,5.8,12.1c0.3,0.3,0.4,0.7,0.3,1.1c-0.1,0.4-0.3,0.7-0.6,1
                C438,93.3,435.4,94.7,432.6,94.7z M432.3,78.2c-0.1,0-0.3,0-0.5,0.3c-2,3.6-6.4,12.1-2.8,14.4c3.7,2.3,8,0,9.7-1.1
                c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2,0-0.3-0.1-0.4c-3.2-3.6-5.1-9.3-6-12.4l0,0C432.8,78.3,432.5,78.2,432.3,78.2
                C432.4,78.2,432.3,78.2,432.3,78.2z"
                    />
                  </g>
                </g>
                <g id="XMLID_6268_">
                  <g id="XMLID_6276_">
                    <g id="XMLID_6292_">
                      <g id="XMLID_6302_">
                        <path
                          id="XMLID_6304_"
                          d="M457.8,45l-5.4-3.6c-0.4-0.2-1.7,0.1-1.9,0.7l1.8,10.7c-0.1,0.6,0.1,1.2,0.4,1.4l2.7,1.6
                    c0.3,0.2,0.7-0.1,0.9-0.7l2-8.8C458.3,45.9,458.1,45.3,457.8,45z"
                        />
                      </g>
                      <g id="XMLID_6294_">
                        <path
                          id="XMLID_6296_"
                          d="M455.6,56.4c-0.2,0-0.3,0-0.4-0.1l-2.7-1.6c-0.5-0.3-0.7-1-0.6-1.8l-1.8-10.7l0-0.1
                    c0.1-0.4,0.4-0.7,0.9-0.9c0.4-0.2,1.2-0.4,1.6-0.1l5.4,3.6c0.5,0.3,0.7,1.1,0.6,1.8l-2,8.8c-0.1,0.5-0.4,0.8-0.7,1
                    C455.8,56.4,455.7,56.4,455.6,56.4z M450.9,42.3l1.8,10.7l0,0.1c-0.1,0.4,0.1,0.9,0.2,0.9l2.7,1.6c0,0,0.2-0.1,0.2-0.4
                    l2-8.8c0.1-0.4-0.1-0.9-0.2-1l0,0l-5.4-3.6c-0.1,0-0.6,0-1,0.2C451,42.2,450.9,42.2,450.9,42.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6278_">
                      <g id="XMLID_6288_">
                        <path
                          id="XMLID_6290_"
                          style="fill: #ffffff"
                          d="M456.7,45.4l-5.4-3.6c-0.4-0.2-0.7,0.1-0.9,0.7l-1.9,8.8
                    c-0.1,0.6,0.1,1.2,0.4,1.4l5.3,3.5c0.3,0.2,0.7-0.1,0.9-0.7l2-8.8C457.2,46.2,457,45.6,456.7,45.4z"
                        />
                      </g>
                      <g id="XMLID_6280_">
                        <path
                          id="XMLID_6282_"
                          d="M454.5,56.8c-0.2,0-0.3,0-0.4-0.1l-5.4-3.5c-0.5-0.3-0.7-1.1-0.6-1.8l1.9-8.8
                    c0.1-0.5,0.4-0.8,0.7-1c0.3-0.1,0.5-0.1,0.8,0.1l5.4,3.6c0.5,0.3,0.7,1.1,0.6,1.8l-2,8.8c-0.1,0.5-0.4,0.8-0.7,1
                    C454.7,56.7,454.6,56.8,454.5,56.8z M451,42.2c0,0-0.2,0.1-0.2,0.4l-1.9,8.8c-0.1,0.4,0.1,0.9,0.2,0.9l5.4,3.5
                    c0,0,0.2-0.1,0.2-0.4l2-8.8c0.1-0.4-0.1-0.9-0.2-1l0,0L451,42.2z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_6270_">
                    <g id="XMLID_6272_">
                      <path
                        id="XMLID_6274_"
                        d="M451.9,53.1l-1.3-0.8c-0.5-0.3-0.7-0.9-0.6-1.4l1.5-6.8l0.8,0.2l-1.5,6.8c0,0.2,0,0.4,0.2,0.6
                  l1.3,0.8L451.9,53.1z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_6254_">
                  <g id="XMLID_6264_">
                    <path
                      id="XMLID_6266_"
                      style="fill: #ffffff"
                      d="M413.4,125.9c0,0-1-0.6-1.2,3.5c0,0,2.1,2,4,1c2.6-1.3,5.5-5.6,5.5-5.6
                s0.7-0.9-0.7-1.5c-1.4-0.6-5.7,1.3-5.7,1.3L413.4,125.9z"
                    />
                  </g>
                  <g id="XMLID_6256_">
                    <path
                      id="XMLID_6258_"
                      d="M415.1,131.1c-1.7,0-3.1-1.3-3.2-1.4l-0.1-0.1l0-0.2c0.1-3,0.7-3.6,1.1-3.8
                c0.1-0.1,0.3-0.1,0.4-0.1l1.8-1.2c0.5-0.2,4.5-2,6-1.3c0.6,0.3,1,0.7,1.1,1.1c0.1,0.6-0.2,1-0.2,1c-0.1,0.2-2.9,4.3-5.6,5.7
                C416,131,415.6,131.1,415.1,131.1z M412.7,129.2c0.5,0.4,2,1.5,3.4,0.8c2.5-1.3,5.3-5.4,5.3-5.4c0,0,0.1-0.2,0.1-0.3
                c0-0.2-0.3-0.4-0.6-0.5c-0.9-0.4-3.7,0.6-5.3,1.3l-2.1,1.4l-0.1-0.1C413.2,126.4,412.8,127,412.7,129.2z"
                    />
                  </g>
                </g>
                <g id="XMLID_6240_">
                  <g id="XMLID_6250_">
                    <path
                      id="XMLID_6252_"
                      style="fill: #ffffff"
                      d="M426.8,134.8c0,0-1,1.5-0.9,3.9c0,0,1.3,2.5,5.3,0.3c0.3-0.2,0.7-0.3,1-0.4
                c1.1-0.4,3.4-1.4,3.9-3.7c0.8-3-5.4-0.3-5.4-0.3L426.8,134.8z"
                    />
                  </g>
                  <g id="XMLID_6242_">
                    <path
                      id="XMLID_6244_"
                      d="M428.2,140.2c-0.4,0-0.7,0-1-0.1c-1.2-0.4-1.7-1.2-1.7-1.3l0-0.1l0-0.1c0-2.5,0.9-4,1-4.1l0.1-0.2
                l4.1-0.3c0.9-0.4,4.4-1.8,5.6-0.8c0.3,0.2,0.6,0.7,0.3,1.6c-0.6,2.5-3.2,3.6-4.2,4c-0.4,0.1-0.7,0.3-0.9,0.4
                C430.3,139.9,429.2,140.2,428.2,140.2z M426.3,138.5c0.3,0.4,1.5,1.8,4.7,0c0.3-0.2,0.6-0.3,1.1-0.5c0.9-0.3,3.1-1.3,3.7-3.4
                c0.1-0.4,0.1-0.6-0.1-0.7c-0.6-0.5-3.2,0.2-4.8,1l-0.1,0l-3.8,0.2C426.8,135.6,426.3,136.8,426.3,138.5z"
                    />
                  </g>
                </g>
                <g id="XMLID_6226_">
                  <g id="XMLID_6236_">
                    <path
                      id="XMLID_6238_"
                      d="M424.2,102.3c0,0-0.7,7.2-3.4,12.1c0,0-1.6,6.3-2,11.1c0,0.3-0.1,0.5-0.3,0.8
                c-0.6,0.7-2.1,1.9-5,0.7c-0.6-0.2-1-0.7-1.2-1.3l0,0c-0.1-0.4-0.1-0.8,0-1.2l2.2-7c0,0,0.6-5.2,1.4-8.6
                c0.8-3.4-1.6-10.4,0.2-17.2s3.4-8,3.4-8l5.9,11.5L424.2,102.3z"
                    />
                  </g>
                  <g id="XMLID_6228_">
                    <path
                      id="XMLID_6230_"
                      d="M415.8,127.9c-0.7,0-1.5-0.2-2.4-0.6c-0.7-0.3-1.2-0.9-1.4-1.6c-0.1-0.5-0.2-1,0-1.4l2.2-7
                c0.1-0.5,0.7-5.3,1.5-8.6c0.3-1.3,0.1-3.3-0.1-5.5c-0.3-3.4-0.8-7.6,0.3-11.7c1.8-6.9,3.5-8.2,3.5-8.3l0.4-0.3l6.2,12.1
                l-1.2,7.2c0,0.5-0.8,7.3-3.4,12.2c-0.1,0.6-1.6,6.5-2,11c0,0.4-0.2,0.7-0.4,1C418.4,127,417.4,127.9,415.8,127.9z
                 M419.5,84.4c-0.5,0.8-1.7,2.8-2.9,7.4c-1,4-0.6,8.1-0.3,11.4c0.2,2.3,0.4,4.4,0.1,5.8c-0.8,3.3-1.4,8.5-1.4,8.5l0,0.1
                l-2.2,7c-0.1,0.3-0.1,0.6,0,1c0.2,0.5,0.5,0.9,0.9,1.1c2.5,1.1,3.9,0.1,4.5-0.6c0.1-0.1,0.2-0.3,0.2-0.5
                c0.5-4.8,2-11.1,2-11.2l0-0.1c2.6-4.8,3.3-11.9,3.3-12l0,0l1.2-7L419.5,84.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_6212_">
                  <g id="XMLID_6222_">
                    <path
                      id="XMLID_6224_"
                      d="M432,90.4c0.3,0.1,0.5,0.4,0.5,0.7c0.3,2.6,1.4,15,0.2,23.1c-1.1,8.2-0.6,17.8-0.5,19.8
                c0,0.2-0.1,0.5-0.2,0.6c-0.7,0.6-2.6,1.9-5.8,0.1c-0.2-0.1-0.4-0.4-0.4-0.7v-1.4l0-0.1c0.5-4.5-0.1-8.9-0.3-13.4
                c-0.1-2.1,0-3.8,0.7-5.8c0.1-0.2,0.1-0.3,0-0.5c-0.5-1.5-3-9.3-3.6-15.1c-0.4-4.6-0.3-7.8-0.1-9.3c0-0.5,0.5-0.8,1-0.7
                L432,90.4z"
                    />
                  </g>
                  <g id="XMLID_6214_">
                    <path
                      id="XMLID_6216_"
                      d="M429.4,136c-0.9,0-2-0.3-3.3-1c-0.4-0.2-0.6-0.6-0.6-1l0-1.6c0.3-3.3,0.1-6.6-0.1-9.9
                c-0.1-1.2-0.1-2.3-0.2-3.5c-0.1-2.1,0-3.8,0.7-5.9c0-0.1,0-0.1,0-0.2c-0.5-1.5-3-9.3-3.6-15.2c-0.4-4.6-0.3-7.9-0.1-9.4
                c0-0.4,0.2-0.7,0.5-0.9c0.3-0.2,0.7-0.3,1-0.1l8.4,2.6c0.5,0.1,0.8,0.5,0.8,1c0.2,2.4,1.4,15,0.2,23.2
                c-1,7.5-0.7,16.3-0.5,19.7c0,0.4-0.1,0.7-0.4,0.9C431.9,135.3,430.9,136,429.4,136z M423.4,88.1c-0.1,0-0.1,0-0.2,0.1
                c-0.1,0-0.1,0.1-0.2,0.3c-0.1,1.5-0.3,4.7,0.1,9.2c0.6,5.8,3.1,13.5,3.5,15c0.1,0.2,0.1,0.5,0,0.7c-0.7,2-0.8,3.6-0.7,5.6
                c0.1,1.2,0.1,2.3,0.2,3.5c0.2,3.3,0.4,6.7,0.1,10l0,0.1l0,1.4c0,0.1,0.1,0.3,0.2,0.3c2.9,1.7,4.7,0.6,5.3,0
                c0.1-0.1,0.1-0.2,0.1-0.3c-0.2-3.4-0.6-12.3,0.5-19.9c1.1-8.1,0-20.6-0.2-23c0-0.1-0.1-0.3-0.3-0.3l-8.4-2.6
                C423.5,88.1,423.5,88.1,423.4,88.1z"
                    />
                  </g>
                </g>
                <g id="XMLID_6199_">
                  <g id="XMLID_6208_">
                    <path
                      id="XMLID_6210_"
                      style="fill: #ffffff"
                      d="M454.3,57.8l3.5-2.5c0.3-0.2,0.6-0.6,0.6-1l0.2-1.2c0.1-0.7-0.3-1.3-1-1.6
                l-0.5-0.2c-0.4-0.2-0.9-0.1-1.2,0.2l-1,0.9c0,0-0.1,0-0.1,0l-0.5-1l-0.4-0.1c-0.4,0.7-0.6,1.4-0.6,2.2c0,0.8-0.3,2-1.2,2.7
                c-1.6,1.1,0.7,1.7,0.7,1.7L454.3,57.8C454.2,57.8,454.2,57.8,454.3,57.8z"
                    />
                  </g>
                  <g id="XMLID_6201_">
                    <path
                      id="XMLID_6203_"
                      d="M454.2,58.2l-1.5,0c-0.2-0.1-1.4-0.4-1.5-1.2c-0.1-0.4,0.2-0.8,0.7-1.2c0.8-0.5,1-1.6,1.1-2.3
                c0-0.8,0.3-1.6,0.7-2.4l0.1-0.3l0.9,0.2l0.4,0.8l0.6-0.6c0.4-0.4,1-0.5,1.6-0.3l0.5,0.2c0.8,0.3,1.3,1.1,1.2,2l-0.2,1.2
                c-0.1,0.5-0.4,1-0.8,1.3l-3.7,2.6L454.2,58.2z M452.9,57.4h1.2l3.5-2.5c0.2-0.2,0.4-0.4,0.4-0.8l0.2-1.2
                c0.1-0.5-0.2-1-0.7-1.1l-0.5-0.2c-0.2-0.1-0.5,0-0.7,0.1l-1,0.9c-0.1,0.1-0.3,0.2-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.3l-0.3-0.6
                c-0.2,0.5-0.3,1-0.4,1.6c-0.1,1-0.3,2.3-1.4,3c-0.3,0.2-0.4,0.4-0.4,0.4C452.1,57,452.5,57.3,452.9,57.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_6187_">
                  <g id="XMLID_6195_">
                    <path
                      id="XMLID_6197_"
                      style="fill: #ffffff"
                      d="M451.7,60l2.1-1.6c0.4-0.3,0.6-0.8,0.5-1.3l-0.2-1.4c-0.1-0.6-0.6-1-1.2-1
                l0,0c-0.3,0-0.6,0.1-0.8,0.3l-3,2.8c0,0,0,0.1,0,0.1l2.6,2C451.6,60,451.6,60,451.7,60z"
                    />
                  </g>
                  <g id="XMLID_6188_">
                    <path
                      id="XMLID_6189_"
                      d="M451.6,60.4c-0.1,0-0.2,0-0.3-0.1l-2.6-2c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0.2-0.4l3-2.8
                c0.3-0.3,0.7-0.4,1.1-0.4c0.8,0,1.5,0.6,1.6,1.4l0.2,1.4c0.1,0.6-0.1,1.3-0.6,1.6l-2.1,1.6C451.8,60.4,451.7,60.4,451.6,60.4
                z M449.6,57.8l2.1,1.6l1.9-1.5c0.3-0.2,0.4-0.5,0.3-0.9l-0.2-1.4c-0.1-0.4-0.4-0.7-0.8-0.7c-0.2,0-0.4,0.1-0.6,0.2
                L449.6,57.8z"
                    />
                  </g>
                </g>
                <g id="XMLID_6169_">
                  <g id="XMLID_6183_">
                    <g id="XMLID_6184_">
                      <path
                        id="XMLID_6185_"
                        style="fill: #ffffff"
                        d="M431.9,100.5l-0.1-0.8c0.3-0.1,0.6-0.1,0.6-0.1l0.2,0.8
                  C432.6,100.4,432.3,100.4,431.9,100.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6174_">
                    <g id="XMLID_6175_">
                      <path
                        id="XMLID_6177_"
                        style="fill: #ffffff"
                        d="M430.9,100.6c-0.4,0-0.8,0-1.2-0.1l0.2-0.8c0.4,0.1,0.8,0.1,1.2,0.1l0,0.8
                  C431.1,100.6,431,100.6,430.9,100.6z M429,100.2c-0.5-0.2-0.9-0.5-1.3-0.8l0.6-0.6c0.3,0.3,0.6,0.5,1,0.6L429,100.2z
                   M427.2,98.8c-0.3-0.4-0.5-0.8-0.7-1.3l0.8-0.3c0.1,0.4,0.3,0.8,0.6,1.1L427.2,98.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6170_">
                    <g id="XMLID_6172_">
                      <path
                        id="XMLID_6173_"
                        style="fill: #ffffff"
                        d="M426.4,96.8c0-0.2-0.1-0.4-0.1-0.7l0.8-0.1c0,0.2,0.1,0.4,0.1,0.6
                  L426.4,96.8z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_6156_">
                  <g id="XMLID_6157_">
                    <path
                      id="XMLID_6159_"
                      style="fill: #ffffff"
                      d="M418.1,102.1l0-0.8l0,0c0.5,0,0.9-0.1,1.1-0.1l0.2,0.8
                C419.2,102,418.7,102.1,418.1,102.1z M417.4,102.1c-0.5-0.1-1-0.3-1.3-0.5l0.4-0.7c0.3,0.2,0.6,0.3,1,0.4L417.4,102.1z
                 M420,101.1l-0.8-0.1l0.2-1.3l0.8,0.1L420,101.1z M420.3,99.2l-0.8-0.1l0.2-1.3l0.8,0.1L420.3,99.2z M420.6,97.3l-0.8-0.1
                l0.1-0.5c0,0,0,0,0-0.1c0,0,0,0-0.1,0l0.1-0.2c0-0.2,0.1-0.3,0.2-0.4l0.1-0.2c0.2,0.1,0.3,0.1,0.3,0.1l1.9,0.8l-1.7,0
                L420.6,97.3z M419.2,96.3c-0.4-0.2-0.8-0.4-1.1-0.6l0.4-0.7c0.4,0.2,0.7,0.4,1.1,0.6L419.2,96.3z M417.5,95.3
                c-0.3-0.2-0.6-0.4-0.9-0.7l0.5-0.7c0.3,0.2,0.6,0.4,0.9,0.6L417.5,95.3z"
                    />
                  </g>
                </g>
                <g id="XMLID_6146_">
                  <g id="XMLID_6148_">
                    <path
                      id="XMLID_6149_"
                      style="fill: #ffffff"
                      d="M429.4,132.4C429.4,132.4,429.4,132.4,429.4,132.4l-0.2,0l0-0.8l0.1,0
                c0,0,0,0,0,0c0.4,0,0.7,0,1-0.1l0.2,0.8C430.2,132.4,429.8,132.4,429.4,132.4z M428.6,132.4c-0.4-0.1-0.9-0.2-1.3-0.3
                l0.3-0.8c0.4,0.1,0.8,0.2,1.2,0.3L428.6,132.4z M431.3,132l-0.3-0.8c0.6-0.3,0.9-0.6,0.9-0.6l0.6,0.6
                C432.5,131.3,432.1,131.7,431.3,132z M426.6,131.8c-0.3-0.1-0.5-0.3-0.8-0.4l0.4-0.7c0.2,0.1,0.5,0.3,0.7,0.4L426.6,131.8z"
                    />
                  </g>
                </g>
                <g id="XMLID_6136_">
                  <g id="XMLID_6137_">
                    <path
                      id="XMLID_6139_"
                      style="fill: #ffffff"
                      d="M416.5,125c-0.2,0-0.5,0-0.7-0.1l0.1-0.8c0.4,0.1,0.8,0.1,1.2,0l0.1,0.8
                C417,125,416.8,125,416.5,125z M417.9,124.9l-0.2-0.8c0.7-0.2,1.1-0.4,1.1-0.4l0.4,0.7C419.1,124.4,418.6,124.7,417.9,124.9z
                 M415.1,124.8c-0.4-0.1-0.9-0.4-1.2-0.7l0.5-0.7c0.3,0.2,0.6,0.4,1,0.5L415.1,124.8z M413.4,123.7c-0.3-0.3-0.5-0.6-0.8-1
                l0.7-0.4c0.2,0.3,0.4,0.6,0.6,0.9L413.4,123.7z"
                    />
                  </g>
                </g>
                <g id="XMLID_6130_">
                  <g id="XMLID_6134_">
                    <path
                      id="XMLID_6135_"
                      d="M434.7,135c0,0-0.7-0.9-2.1-1.7L434.7,135z"
                    />
                  </g>
                  <g id="XMLID_6131_">
                    <path
                      id="XMLID_6132_"
                      d="M434.4,135.3c0,0-0.7-0.8-2-1.5l0.4-0.7c1.5,0.8,2.2,1.7,2.2,1.8L434.4,135.3z"
                    />
                  </g>
                </g>
                <g id="XMLID_6123_">
                  <g id="XMLID_6127_">
                    <path
                      id="XMLID_6128_"
                      d="M420.8,124.8c0,0-0.4-0.4-1.6-0.7L420.8,124.8z"
                    />
                  </g>
                  <g id="XMLID_6124_">
                    <path
                      id="XMLID_6126_"
                      d="M420.6,125.1c0,0-0.4-0.3-1.4-0.7l0.2-0.8c1.2,0.4,1.7,0.8,1.8,0.8L420.6,125.1z"
                    />
                  </g>
                </g>
                <g id="XMLID_6099_">
                  <g id="XMLID_6113_">
                    <g id="XMLID_6120_">
                      <path
                        id="XMLID_6121_"
                        style="fill: #ffffff"
                        d="M412.3,91.2c0,0,1.9-13,1.9-21.9c0-0.5,0-1,0-1.5c0,0,0,0,0,0
                  c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.4c0-2.1,0.3-4.1,0.8-5.5c2-4.7,4.6-2.8,4.6-2.8l1,0.8l2.7,3l7.5-0.6
                  c3.1-0.5,9.1-0.5,12.3-0.9c0.3,0,0.7-0.1,1-0.3l6.8-4.6c0,0,1.5-0.6,1.9,1.2c0.2,1.2-0.3,2.5-1.4,3.2l-7.3,5.8
                  c-0.1,0.1-0.3,0.2-0.4,0.2l-7.7,3.1c-0.5,0.1-1,0.4-1.2,0.9c-0.3,0.7-0.7,1.7-1.1,3.1c0,0.2-1.4,11.4,3.4,18.1
                  c0,0-6.3,8.1-20.1,1.4C416.8,93,414.4,91.5,412.3,91.2z"
                      />
                    </g>
                    <g id="XMLID_6115_">
                      <path
                        id="XMLID_6116_"
                        d="M427,96c-2.8,0-6.3-0.7-10.3-2.7c-0.1,0-2.4-1.4-4.5-1.7l-0.4-0.1l0.1-0.4
                  c0-0.1,1.9-13.1,1.9-21.9c0-0.5,0-1,0-1.5l0-0.5c0-0.1,0-0.3,0-0.4c0-2.5,0.3-4.3,0.9-5.6c0.8-1.9,1.8-3,2.9-3.3
                  c1.3-0.4,2.3,0.3,2.3,0.4l1,0.8l2.5,2.8l7.3-0.6c1.6-0.3,4.1-0.4,6.5-0.5c2.1-0.1,4.3-0.2,5.9-0.4c0.4,0,0.6-0.1,0.9-0.2
                  l6.8-4.6c0,0,0.8-0.3,1.5,0.1c0.5,0.3,0.8,0.7,0.9,1.4c0.3,1.4-0.3,2.8-1.5,3.6l-7.3,5.8c-0.2,0.1-0.3,0.2-0.5,0.3l-7.8,3.1
                  c-0.4,0.1-0.7,0.3-0.9,0.7c-0.3,0.6-0.7,1.6-1.1,3c0,0,0,0.1,0,0.1c-0.3,2.7-0.8,11.9,3.3,17.7l0.2,0.3l-0.2,0.2
                  C437.3,91.8,433.9,96,427,96z M412.7,90.9c2.1,0.4,4.2,1.7,4.3,1.7c12.1,5.8,18.2,0.1,19.4-1.1c-4.1-6-3.5-15.2-3.3-18
                  l0-0.2c0.4-1.4,0.8-2.5,1.1-3.2c0.3-0.6,0.8-1.1,1.5-1.2l7.7-3c0.1,0,0.2-0.1,0.3-0.2l7.3-5.8c0.9-0.6,1.4-1.7,1.2-2.8
                  c-0.1-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.7-0.1-0.8,0l-6.7,4.6c-0.4,0.2-0.8,0.3-1.2,0.3c-1.6,0.2-3.8,0.3-5.9,0.4
                  c-2.4,0.1-4.8,0.2-6.4,0.5l-7.8,0.7l-2.8-3.1l-0.9-0.7c0,0-0.7-0.5-1.6-0.2c-0.9,0.3-1.7,1.2-2.4,2.8
                  c-0.5,1.2-0.8,3-0.8,5.3c0,0.1,0,0.2,0,0.3l0,0.5c0,0.5,0,1,0,1.5C414.5,77.3,413,88.6,412.7,90.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6109_">
                    <g id="XMLID_6110_">
                      <path
                        id="XMLID_6112_"
                        d="M430.9,74.1l-0.7-0.4c1.4-2.8,4.9-4.4,5.1-4.5l0.3,0.8C435.5,69.9,432.1,71.5,430.9,74.1z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6104_">
                    <g id="XMLID_6106_">
                      <path
                        id="XMLID_6107_"
                        d="M442.1,63.7l-0.8-0.3c1-2.4,2.4-3.1,2.4-3.2l0.4,0.7l-0.2-0.4l0.2,0.4
                  C444.1,61,443,61.6,442.1,63.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6101_">
                    <g id="XMLID_6102_">
                      <path
                        id="XMLID_6103_"
                        d="M430.2,94.7c-1.4,0-3.1-0.2-4.9-0.9l0.3-0.8c5,2,9.1,0,9.1,0l0.4,0.7
                  C435,93.8,433.1,94.7,430.2,94.7z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_6058_">
                  <g id="XMLID_6090_">
                    <g id="XMLID_6097_">
                      <path
                        id="XMLID_6098_"
                        style="fill: #ffffff"
                        d="M431.4,50.4c0,2.1-0.2,5-2,6.9l-2.1,0.8c0,0,0.2,1.6,1.7,2.3l-4.4,1.7
                  l-3.8-0.9l0.6-2.4c0,0,1.7-2.1,0.7-5.6c-1.1-3.5,4-7.6,4-7.6l4,0.8h0C430.9,47.7,431.4,49,431.4,50.4z"
                      />
                    </g>
                    <g id="XMLID_6092_">
                      <path
                        id="XMLID_6093_"
                        d="M424.6,62.6l-4.3-1.1l0.7-2.9l0.1-0.1c0.1-0.1,1.6-2,0.6-5.2c-1.1-3.7,3.9-7.8,4.2-8l0.2-0.1
                  l4.3,0.9l0.1,0.1c0.9,1.2,1.4,2.6,1.4,4.1l0,0c0.1,2.2-0.3,5.3-2.1,7.2l-0.1,0.1l-1.9,0.7c0.1,0.4,0.5,1.2,1.4,1.7l0.8,0.4
                  L424.6,62.6z M421.3,61l3.2,0.8l3.5-1.4c-1-0.9-1.2-2.1-1.2-2.2l0-0.3l2.3-0.9c1.6-1.8,1.9-4.5,1.8-6.6l0,0
                  c0-1.2-0.4-2.4-1.2-3.5l-3.6-0.7c-0.8,0.7-4.6,4.2-3.7,7c1,3.3-0.4,5.4-0.7,5.9L421.3,61z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6086_">
                    <g id="XMLID_6087_">
                      <path
                        id="XMLID_6089_"
                        d="M430.5,44.6c0,0-1.1-2.1-3.7-2.2c-2.6-0.1-4.2,1.9-4.2,2.9s6.1,1.2,6.1,1.2L430.5,44.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6076_">
                    <g id="XMLID_6083_">
                      <path
                        id="XMLID_6085_"
                        d="M432.6,47.2c0,0.3-0.1,0.6-0.2,0.8c-0.5,1.2-2.2,4.2-6.3,3.5l-1.3,0.1c0,0-0.2,1.3,0.3,2.6
                  c0.2,0.4,0.1,1-0.4,1.2c-0.7,0.4-1.8,0.7-3.2,0c0,0-4.5-6.6,0.6-10.6c1-0.8,2.4-1.1,3.6-0.7c1.1,0.3,2.7,0.7,4.4,0.5
                  c0.4,0,0.8,0,1.2,0.2l0,0c0.7,0.4,1.2,1.1,1.2,1.9V47.2z"
                      />
                    </g>
                    <g id="XMLID_6078_">
                      <path
                        id="XMLID_6079_"
                        d="M423.2,56.2c-0.5,0-1.2-0.1-1.9-0.5l-0.1,0l-0.1-0.1c0-0.1-4.6-6.9,0.7-11.2
                  c1.1-0.9,2.6-1.2,4-0.8c1,0.3,2.6,0.6,4.3,0.5c0.5,0,1,0,1.4,0.2c0.9,0.4,1.4,1.3,1.4,2.3v0.6c0,0.3-0.1,0.7-0.2,1
                  c-0.5,1.1-2.3,4.5-6.7,3.7l-0.9,0.1c0,0.4,0,1.3,0.3,2c0.3,0.6,0.1,1.4-0.5,1.7C424.5,56,424,56.2,423.2,56.2z M421.9,55
                  c1.2,0.6,2.1,0.3,2.7,0c0.3-0.1,0.3-0.5,0.2-0.7c-0.6-1.3-0.4-2.7-0.3-2.8l0.1-0.3l1.7-0.1l0.1,0c3.7,0.7,5.3-2,5.8-3.2
                  c0.1-0.2,0.1-0.4,0.1-0.7v-0.6c0-0.6-0.4-1.2-0.9-1.5c-0.3-0.1-0.6-0.2-0.9-0.2c-1.8,0.2-3.5-0.2-4.6-0.5
                  c-1.1-0.3-2.3-0.1-3.2,0.6C418,48.6,421.4,54.3,421.9,55z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6072_">
                    <g id="XMLID_6073_">
                      <path
                        id="XMLID_6075_"
                        d="M426.7,54.7c-0.4-0.3-0.7-0.7-0.8-1.1c-0.1-0.7,0.3-1.2,0.3-1.2l0.7,0.5l-0.3-0.3l0.3,0.2
                  c0,0-0.2,0.3-0.2,0.6c0,0.2,0.2,0.4,0.4,0.5L426.7,54.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6068_">
                    <g id="XMLID_6069_">
                      <path
                        id="XMLID_6071_"
                        style="fill: #ffffff"
                        d="M429,45c-0.9,0-3-0.1-4.5-0.8l0.3-0.8c1.8,0.8,4.5,0.7,4.6,0.7l0,0.8
                  C429.3,45,429.2,45,429,45z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6064_">
                    <g id="XMLID_6065_">
                      <path
                        id="XMLID_6067_"
                        style="fill: #ffffff"
                        d="M422,54.4c-1.4-1.8-0.9-4.7-0.8-4.8l0.8,0.1c0,0-0.5,2.7,0.7,4.2L422,54.4z
                  "
                      />
                    </g>
                  </g>
                  <g id="XMLID_6060_">
                    <g id="XMLID_6061_">
                      <path
                        id="XMLID_6063_"
                        style="fill: #ffffff"
                        d="M424.6,51.9c0,0-1.1-0.8-0.8-2.2l0.8,0.2c-0.2,0.8,0.5,1.3,0.5,1.4
                  L424.6,51.9z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_6049_">
                  <g id="XMLID_6056_">
                    <path
                      id="XMLID_6057_"
                      style="fill: #ffffff"
                      d="M430.7,61.8c0,0,0.1-1.3-2.7-1.2s-5.8,0.3-6.4-2.5l0.2-0.5
                c0,0-2.3-0.3-2.2,1.7c0.1,2,1.6,3.6,4.9,3.7C427.7,63.1,430.7,61.8,430.7,61.8z"
                    />
                  </g>
                  <g id="XMLID_6050_">
                    <path
                      id="XMLID_6051_"
                      d="M424.7,63.5c-0.1,0-0.2,0-0.3,0c-3.2-0.1-5.2-1.6-5.3-4.1c0-0.7,0.2-1.2,0.6-1.6
                c0.8-0.7,2-0.6,2.1-0.6l0.5,0.1l-0.3,0.8c0.6,2.2,3.1,2.1,5.6,2l0.4,0c1.3,0,2.2,0.2,2.7,0.7c0.4,0.4,0.4,0.9,0.4,0.9l0,0.3
                l-0.2,0.1C430.8,62.2,427.9,63.5,424.7,63.5z M421.2,58c-0.3,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.5-0.3,1
                c0.1,2,1.7,3.2,4.5,3.3c2.5,0.1,4.9-0.8,5.8-1.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.7-0.5-2-0.5l-0.4,0
                c-2.7,0.1-5.8,0.2-6.5-2.8L421.2,58L421.2,58z"
                    />
                  </g>
                </g>
                <g id="XMLID_5910_">
                  <g id="XMLID_6039_">
                    <g id="XMLID_6046_">
                      <path
                        id="XMLID_6047_"
                        style="fill: #ffffff"
                        d="M431,61.8c0,0-5.2,0.1-6.9,5.3l-2.7-2.5c0,0,3-4.4,6.5-5
                  c3.5-0.6,4,2.1,4,2.1L431,61.8z"
                      />
                    </g>
                    <g id="XMLID_6041_">
                      <path
                        id="XMLID_6042_"
                        d="M424.4,67.8l-3.5-3.1l0.2-0.3c0.1-0.2,3.1-4.6,6.7-5.2c3.9-0.7,4.4,2.4,4.5,2.4l0.1,0.4l-1.3,0.2
                  c-0.2,0-5,0.2-6.5,5L424.4,67.8z M422,64.5l2,1.8c1.8-4.6,6.6-4.9,7-5l0.3,0c-0.3-0.6-1.1-1.7-3.4-1.3
                  C425.2,60.4,422.7,63.5,422,64.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6030_">
                    <g id="XMLID_6037_">
                      <path
                        id="XMLID_6038_"
                        style="fill: #ffffff"
                        d="M433.8,79.1c0,0,0,0.6-0.8,1.9c-1.6,2.6-4.3,5.5-8.7,6l-0.5,2.1
                  c0,0,4.8,1,10-6.3C433.8,82.9,436.3,79.9,433.8,79.1z"
                      />
                    </g>
                    <g id="XMLID_6031_">
                      <path
                        id="XMLID_6033_"
                        d="M424.4,89.7c-0.4,0-0.7,0-0.7-0.1l-0.4-0.1l0.7-2.8l0.3,0c3.4-0.4,6.2-2.4,8.4-5.8
                  c0.7-1.1,0.8-1.7,0.8-1.7l0-0.5l0.5,0.1c0.9,0.3,1.2,0.8,1.3,1.2c0.4,1.3-1,3.1-1.1,3.3C430,89,426,89.7,424.4,89.7z
                   M424.3,88.9c1.3,0,5.1-0.5,9.2-6.2c0.3-0.4,1.2-1.7,1-2.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1,0.4-0.3,0.9-0.8,1.6
                  c-2.2,3.6-5.2,5.7-8.7,6.2L424.3,88.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_6020_">
                    <g id="XMLID_6027_">
                      <path
                        id="XMLID_6029_"
                        style="fill: #ffffff"
                        d="M419.2,63.8c0,0-0.1-4.3,1.6-5.5c0.9-0.6-0.8-1.6-2.5,0.1
                  c-1.7,1.7-1.6,4.8-1.6,4.8L419.2,63.8z"
                      />
                    </g>
                    <g id="XMLID_6022_">
                      <path
                        id="XMLID_6023_"
                        d="M419.6,64.3l-3.3-0.7l0-0.3c0-0.1-0.1-3.3,1.7-5.2c1.1-1.1,2.1-1.2,2.8-1c0.4,0.1,0.6,0.4,0.7,0.7
                  c0,0.3-0.1,0.6-0.4,0.8c-1.2,0.8-1.4,4-1.4,5.1L419.6,64.3z M417.1,62.9l1.7,0.4c0-1.2,0.3-4.3,1.8-5.3c0,0,0,0,0,0
                  c0,0-0.1-0.1-0.3-0.1c-0.2,0-0.9,0-1.7,0.8C417.3,60,417.1,62.1,417.1,62.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_5911_">
                    <g id="XMLID_6011_">
                      <g id="XMLID_6018_">
                        <path
                          id="XMLID_6019_"
                          d="M424.5,67.8c0.2,0.8,0.2,1.7,0,2.6c-0.5,2.4-1.6,9.1-0.4,14.6c1.4,6.2-4.2,9.8-12.1,6.9
                    c-2.3-0.9-4.2-2.6-5-5c-0.4-1.2-0.6-2.6-0.1-4.2c1-3.7,3.5-11.6,4.6-14.9c0.5-1.7,1.6-3.1,3.1-4c1.2-0.7,2.7-1,4.4-0.3
                    C422.3,64.8,423.9,65.8,424.5,67.8z"
                        />
                      </g>
                      <g id="XMLID_6012_">
                        <path
                          id="XMLID_6013_"
                          d="M417.3,93.3c-1.7,0-3.5-0.4-5.4-1.1c-2.5-1-4.4-2.9-5.2-5.2c-0.5-1.5-0.5-3-0.1-4.5
                    c1-3.7,3.6-11.7,4.6-14.9c0.6-1.8,1.8-3.3,3.3-4.2c1.6-0.9,3.1-1,4.7-0.3c3.4,1.4,5,2.4,5.6,4.6c0.2,0.8,0.2,1.8,0,2.8
                    c-0.5,2.5-1.6,9.1-0.4,14.4c0.6,2.7,0,5.1-1.8,6.6C421.3,92.7,419.5,93.3,417.3,93.3z M417.2,63.5c-0.8,0-1.5,0.2-2.3,0.7
                    c-1.4,0.8-2.4,2.1-2.9,3.7c-1,3.2-3.6,11.1-4.6,14.8c-0.4,1.3-0.3,2.7,0.1,4c0.7,2.1,2.4,3.8,4.7,4.7
                    c4.1,1.5,7.8,1.3,9.9-0.6c1.6-1.4,2.1-3.4,1.6-5.8c-1.3-5.5-0.2-12.2,0.4-14.8c0.2-0.9,0.2-1.7,0-2.4v0
                    c-0.5-1.8-1.9-2.7-5.2-4C418.3,63.6,417.8,63.5,417.2,63.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_6001_">
                      <g id="XMLID_6008_">
                        <path
                          id="XMLID_6009_"
                          style="fill: #ffffff"
                          d="M413.2,92.2c6.5,1.1,5.2-7.2,5.2-11.3c-0.1-5.4,2.9-10.3,1.9-12.9
                    c-1-2.6-5.9-4-6-4c0.2-0.1,0.2-0.1,0.3-0.2c1.2-0.7,2.7-1,4.4-0.3c3.2,1.4,4.8,2.3,5.4,4.3c0.2,0.8,0.2,1.7,0,2.6
                    c-0.5,2.4-1.6,9.1-0.4,14.6C425.5,90.8,420.4,94.4,413.2,92.2z"
                        />
                      </g>
                      <g id="XMLID_6002_">
                        <path
                          id="XMLID_6004_"
                          d="M417.3,93.3c-1.3,0-2.7-0.2-4.2-0.7l0.2-0.8c1.4,0.2,2.5,0,3.2-0.7c1.8-1.6,1.7-5.6,1.5-8.6
                    c0-0.6,0-1.1-0.1-1.6c0-2.7,0.7-5.3,1.3-7.5c0.6-2.2,1.1-4.1,0.7-5.3c-0.9-2.4-5.6-3.7-5.7-3.8l-0.9-0.2l0.7-0.5
                    c0.1-0.1,0.2-0.1,0.3-0.2l0.1,0c1.6-0.9,3.2-1,4.7-0.3c3.4,1.4,5,2.4,5.6,4.6c0.2,0.8,0.2,1.8,0,2.8
                    c-0.5,2.5-1.6,9.1-0.4,14.4c0.6,2.6,0,4.9-1.7,6.5C421.5,92.6,419.5,93.3,417.3,93.3z M416.1,92.4c2.6,0.3,4.8-0.3,6.2-1.6
                    c1.5-1.4,2-3.4,1.4-5.7c-1.3-5.5-0.2-12.2,0.4-14.8c0.2-0.9,0.2-1.7,0-2.4c-0.5-1.8-1.9-2.7-5.2-4c-1.2-0.5-2.3-0.5-3.5,0
                    c1.6,0.6,4.5,1.9,5.3,4c0.5,1.5,0,3.5-0.7,5.8c-0.6,2.2-1.3,4.7-1.2,7.3c0,0.5,0,1,0.1,1.6c0.1,3.1,0.3,7.4-1.8,9.2
                    C416.8,92,416.5,92.2,416.1,92.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_5991_">
                      <g id="XMLID_5998_">
                        <path
                          id="XMLID_5999_"
                          style="fill: #ffffff"
                          d="M419.1,63.5c3.2,1.4,4.8,2.3,5.4,4.3c0.1,0.4,0.2,0.9,0.2,1.3
                    c0,0-1.2,1.3-4.3,1.9c0.2-1.2,0.3-2.2,0-3c-1-2.6-5.9-4-6-4c0.2-0.1,0.2-0.1,0.3-0.2C415.9,63.1,417.4,62.8,419.1,63.5z"
                        />
                      </g>
                      <g id="XMLID_5992_">
                        <path
                          id="XMLID_5994_"
                          d="M419.8,71.5l0.1-0.6c0.3-1.3,0.3-2.1,0-2.7c-0.9-2.4-5.6-3.7-5.7-3.8l-0.9-0.2l0.7-0.5
                    c0.1-0.1,0.2-0.1,0.3-0.2l0.1,0c1.6-0.9,3.1-1,4.7-0.3c3.4,1.4,5,2.4,5.6,4.6c0.1,0.4,0.2,0.9,0.2,1.4l0,0.2l-0.1,0.1
                    c-0.1,0.1-1.3,1.4-4.5,2L419.8,71.5z M415.4,63.9c1.6,0.6,4.5,1.9,5.3,4c0.2,0.7,0.3,1.5,0.1,2.6c2-0.5,3-1.3,3.4-1.5
                    c0-0.4-0.1-0.7-0.2-1c-0.5-1.8-1.9-2.7-5.2-4l0,0C417.8,63.4,416.6,63.4,415.4,63.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_5981_">
                      <g id="XMLID_5988_">
                        <path
                          id="XMLID_5990_"
                          style="fill: #ffffff"
                          d="M417.7,73.2c-0.2,0.7-0.5,1.7-0.8,2.8c-0.1,0.6-0.5,1.1-1,1.4l0,0
                    c-0.6,0.3-1.2,0.4-1.8,0.2l-5-1.9c-0.9-0.4-1.4-1.3-1.3-2.3c0.3-1.4,0.8-3.3,1.5-4.3c1.2-1.6,3.8-0.5,3.8-0.5
                    s2.2,0.7,3.6,1.7C417.7,71,418,72.2,417.7,73.2z"
                        />
                      </g>
                      <g id="XMLID_5983_">
                        <path
                          id="XMLID_5984_"
                          d="M414.9,78.1c-0.3,0-0.6-0.1-0.9-0.2l-5-1.9c-1.1-0.4-1.7-1.6-1.5-2.7c0.3-1.5,0.8-3.4,1.5-4.4
                    c1.4-1.9,4.2-0.7,4.3-0.6c0,0,2.2,0.7,3.7,1.8c1.1,0.7,1.5,2.1,1,3.4v0c-0.2,0.6-0.5,1.6-0.8,2.7c-0.1,0.7-0.6,1.3-1.2,1.7
                    C415.7,78,415.3,78.1,414.9,78.1z M411.3,68.6c-0.6,0-1.2,0.2-1.6,0.7c-0.5,0.7-1,2.2-1.4,4.1c-0.2,0.8,0.3,1.5,1,1.8
                    l5,1.9c0.5,0.2,1,0.1,1.5-0.1c0.4-0.2,0.7-0.6,0.8-1.1c0.2-1.1,0.6-2.2,0.8-2.8c0.3-0.9,0-1.9-0.7-2.4
                    c-1.4-1-3.5-1.7-3.5-1.7C413,69,412.2,68.6,411.3,68.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_5971_">
                      <g id="XMLID_5978_">
                        <path
                          id="XMLID_5980_"
                          style="fill: #ffffff"
                          d="M407.9,73.3c0.3-1.4,0.8-3.3,1.5-4.3c0.5-0.7,1.3-0.9,2-0.9
                    c0.8,0.3,2,0.8,2.9,1.5c0.9,0.6,1.3,1.8,0.9,2.9c-0.2,0.7-0.5,1.7-0.8,2.8c-0.1,0.6-0.5,1.1-1,1.4
                    c-0.2,0.1-0.5,0.2-0.8,0.3l-3.5-1.3C408.2,75.3,407.7,74.3,407.9,73.3z"
                        />
                      </g>
                      <g id="XMLID_5973_">
                        <path
                          id="XMLID_5974_"
                          d="M412.6,77.4L409,76c-1.1-0.4-1.7-1.6-1.5-2.7c0.3-1.5,0.8-3.4,1.5-4.4c0.5-0.7,1.3-1.1,2.3-1
                    l0.1,0c0.8,0.3,2,0.9,3,1.5c1.1,0.7,1.5,2.1,1,3.4c-0.2,0.6-0.5,1.6-0.8,2.7c-0.1,0.7-0.6,1.3-1.2,1.7
                    c-0.3,0.2-0.6,0.3-0.9,0.3L412.6,77.4z M411.3,68.6c-0.7,0-1.2,0.2-1.6,0.7c-0.5,0.7-1,2.2-1.4,4.1l0,0
                    c-0.2,0.8,0.3,1.5,1,1.8l3.4,1.3c0.2,0,0.4-0.1,0.5-0.2c0.4-0.2,0.7-0.6,0.8-1.1c0.2-1.1,0.6-2.2,0.8-2.8
                    c0.3-0.9,0-1.9-0.7-2.4C413.1,69.4,412.1,68.9,411.3,68.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_5967_">
                      <g id="XMLID_5969_">
                        <path
                          id="XMLID_5970_"
                          style="fill: #ffffff"
                          d="M414.7,87c-0.1,0-0.2,0-0.2,0c-1.6-0.3-4.6-1.1-6.8-2.9l0.5-0.6
                    c2.1,1.7,5.2,2.5,6.5,2.7c0.2,0,0.4-0.1,0.5-0.3c0.3-1.3,0.2-2.3,0.1-2.9c-0.1-0.4-0.3-0.7-0.7-0.8
                    c-2.3-0.9-5.4-2.4-5.4-2.4l0.4-0.7c0,0,3.1,1.5,5.4,2.4c0.6,0.2,1.1,0.8,1.2,1.4c0.1,0.7,0.2,1.8-0.1,3.2
                    C415.7,86.6,415.2,87,414.7,87z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_5963_">
                      <g id="XMLID_5964_">
                        <path
                          id="XMLID_5966_"
                          style="fill: #ffffff"
                          d="M415.3,89.6c-0.1,0-3.3-0.1-7.3-2.2l0.4-0.7c3.8,2,6.9,2.1,6.9,2.1
                    L415.3,89.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_5959_">
                      <g id="XMLID_5960_">
                        <path
                          id="XMLID_5962_"
                          d="M420.4,90.6c-1.1,0-2.4-0.2-3.8-1l0.4-0.7c3.7,1.9,6.5,0.1,6.7,0l0.5,0.7
                    C424,89.7,422.5,90.6,420.4,90.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_5938_">
                      <g id="XMLID_5949_">
                        <g id="XMLID_5956_">
                          <path
                            id="XMLID_5957_"
                            style="fill: #ffffff"
                            d="M408.7,69.1c-0.2,0.5,0.1,1,0.5,1.2c0.5,0.2,1-0.1,1.2-0.5
                      c0.2-0.5-0.1-1-0.5-1.2C409.4,68.4,408.8,68.6,408.7,69.1z"
                          />
                        </g>
                        <g id="XMLID_5950_">
                          <path
                            id="XMLID_5952_"
                            d="M409.5,70.8c-0.2,0-0.3,0-0.5-0.1c-0.7-0.3-1-1-0.8-1.7l0,0l0,0c0.1-0.3,0.4-0.6,0.7-0.7
                      c0.3-0.1,0.7-0.2,1,0c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.3,0.2,0.7,0,1C410.5,70.4,410,70.8,409.5,70.8z M409.5,68.9
                      c-0.2,0-0.4,0.1-0.5,0.3l0,0c-0.1,0.2,0,0.5,0.3,0.6c0.2,0.1,0.5,0,0.6-0.3c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.1-0.2-0.3-0.3
                      C409.6,69,409.6,68.9,409.5,68.9z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_5939_">
                        <g id="XMLID_5946_">
                          <path
                            id="XMLID_5948_"
                            style="fill: #ffffff"
                            d="M407,74.5l0.9,0.4c0.2,0.1,0.4,0,0.5-0.2l1.4-3.6c0.1-0.2,0-0.4-0.2-0.5
                      l-0.9-0.4c-0.2-0.1-0.4,0-0.5,0.2l-1.4,3.6C406.7,74.1,406.8,74.4,407,74.5z"
                          />
                        </g>
                        <g id="XMLID_5941_">
                          <path
                            id="XMLID_5942_"
                            d="M407.9,75.1c-0.2,0-0.3,0-0.4-0.1l-0.1-0.1c-0.6-0.2-1-1-0.7-1.6l1.1-2.8
                      c0.1-0.3,0.3-0.6,0.7-0.7c0.3-0.1,0.6-0.1,0.9,0l0.1,0.1c0.3,0.1,0.6,0.3,0.7,0.7c0.1,0.3,0.1,0.6,0,0.9l-1.1,2.8
                      c-0.1,0.3-0.3,0.6-0.7,0.7C408.2,75.1,408,75.1,407.9,75.1z M407.6,74.2l0.1,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2
                      l1.1-2.8c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.2l-0.1-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.2,0.2l-1.1,2.8
                      C407.2,73.9,407.4,74.1,407.6,74.2L407.6,74.2z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_5916_">
                      <g id="XMLID_5928_">
                        <g id="XMLID_5935_">
                          <path
                            id="XMLID_5936_"
                            style="fill: #ffffff"
                            d="M422.7,76c0,0.5,0.5,0.9,1,0.8c0.5,0,0.9-0.5,0.8-1c0-0.5-0.5-0.9-1-0.8
                      C423,75.1,422.6,75.5,422.7,76z"
                          />
                        </g>
                        <g id="XMLID_5929_">
                          <path
                            id="XMLID_5931_"
                            d="M423.6,77.2c-0.7,0-1.3-0.5-1.3-1.2l0,0c-0.1-0.7,0.5-1.4,1.2-1.4c0.4,0,0.7,0.1,1,0.3
                      c0.3,0.2,0.4,0.5,0.5,0.9c0.1,0.7-0.5,1.4-1.2,1.4C423.7,77.2,423.6,77.2,423.6,77.2z M423.1,76c0,0.3,0.3,0.5,0.5,0.4
                      c0.3,0,0.5-0.3,0.4-0.5c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.4-0.1C423.3,75.5,423.1,75.7,423.1,76L423.1,76z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_5917_">
                        <g id="XMLID_5924_">
                          <path
                            id="XMLID_5927_"
                            style="fill: #ffffff"
                            d="M423.6,81.5l1-0.1c0.2,0,0.4-0.2,0.4-0.4l-0.3-3.8c0-0.2-0.2-0.4-0.4-0.4
                      l-1,0.1c-0.2,0-0.4,0.2-0.4,0.4l0.3,3.8C423.1,81.4,423.3,81.5,423.6,81.5z"
                          />
                        </g>
                        <g id="XMLID_5918_">
                          <path
                            id="XMLID_5920_"
                            d="M423.9,81.9c-0.6,0-1.2-0.5-1.2-1.1l-0.3-3c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.5-0.4,0.8-0.4
                      l0.2,0c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.4,0.5,0.4,0.8l0.3,3c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.4-0.8,0.4l-0.2,0
                      C424,81.9,423.9,81.9,423.9,81.9z M423.8,77.2C423.8,77.2,423.8,77.2,423.8,77.2l-0.2,0c-0.1,0-0.2,0.1-0.3,0.1
                      c-0.1,0.1-0.1,0.2-0.1,0.3l0.3,3c0,0.2,0.2,0.4,0.4,0.4l0.2,0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3l-0.3-3
                      c0-0.1-0.1-0.2-0.1-0.3C424,77.3,423.9,77.2,423.8,77.2z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_5912_">
                      <g id="XMLID_5914_">
                        <path
                          id="XMLID_5915_"
                          d="M414.7,73.3c0,0-1-0.7-3.3-1.5l0.3-0.8c2.4,0.8,3.5,1.6,3.5,1.6L414.7,73.3z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_5905_">
                  <g id="XMLID_5907_">
                    <path
                      id="XMLID_5908_"
                      style="fill: #ffffff"
                      d="M428.5,115.5c-0.1,0-1.3-1.1-2.1-2.6l0.6-0.3c0.8,1.4,2,2.4,2,2.4
                L428.5,115.5z"
                    />
                  </g>
                </g>
                <g id="XMLID_5901_">
                  <g id="XMLID_5903_">
                    <path
                      id="XMLID_5904_"
                      style="fill: #ffffff"
                      d="M418.1,112.3c-0.1-0.1-1.6-1.9-1.8-3.9l0.6,0c0.1,1.7,1.6,3.5,1.6,3.5
                L418.1,112.3z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_5860_">
                <g id="XMLID_5890_">
                  <g id="XMLID_5897_">
                    <path
                      id="XMLID_5898_"
                      style="fill: #ffffff"
                      d="M418.5,150.6c0,0,0-2.9,0-3.1c0,0,0,0,0-0.1l-6.5,0.6c0,0,0,0,0,0l-0.6-3.6
                c0,0,0,0-0.1,0l-6,3.4c0,0,0,0,0,0l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0c0,0.2,0.1,0.4,0.3,0.5l6,3.5
                c0.5,0.3,1.1,0.3,1.7,0l6.1-3.5C418.3,151,418.5,150.8,418.5,150.6L418.5,150.6z"
                    />
                  </g>
                  <g id="XMLID_5891_">
                    <path
                      id="XMLID_5893_"
                      d="M411.2,155.3c-0.4,0-0.7-0.1-1-0.3l-6-3.5c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.5l0-0.8l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1,0.3l6.1-3.5l0.4,0.2l0.6,3.4l6.2-0.6l0.1,0c0.2,0.1,0.3,0.2,0.3,0.5c0,0,0,3.1,0,3.2l0,0
                c0,0.3-0.2,0.7-0.5,0.8l-6.1,3.5C411.9,155.2,411.5,155.3,411.2,155.3z M404.4,148l0,0.2c0,0.9,0,2.3,0,2.4
                c0,0,0,0.1,0.1,0.2l6,3.5c0.4,0.2,0.9,0.2,1.2,0l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2l0,0c0-0.2,0-1.9,0-2.7l-6.2,0.6l-0.2-0.1
                l-0.2-0.1L411,145l-5.7,3.3l-0.2,0L404.4,148z"
                    />
                  </g>
                </g>
                <g id="XMLID_5881_">
                  <g id="XMLID_5887_">
                    <path
                      id="XMLID_5889_"
                      d="M418.4,147.4l-6.5,0.6c0,0,0,0,0,0l-0.8,2.2l0,4.7c0.3,0,0.6-0.1,0.9-0.2l6.1-3.5
                c0.2-0.1,0.3-0.3,0.3-0.5l0,0C418.5,150.6,418.5,147.7,418.4,147.4C418.5,147.4,418.4,147.4,418.4,147.4z"
                    />
                  </g>
                  <g id="XMLID_5882_">
                    <path
                      id="XMLID_5883_"
                      d="M411.2,155.3C411.2,155.3,411.1,155.3,411.2,155.3l-0.5,0l0-5.2l0.9-2.7l0.3,0.2l6.5-0.6l0.1,0
                c0.2,0.1,0.3,0.2,0.3,0.5c0,0,0,3.1,0,3.2c0,0.4-0.2,0.7-0.5,0.9l-6.1,3.5C411.9,155.2,411.6,155.3,411.2,155.3z
                 M411.6,150.3l0,4.1c0.1,0,0.2-0.1,0.3-0.1l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-1.9,0-2.7l-5.8,0.5L411.6,150.3z"
                    />
                  </g>
                </g>
                <g id="XMLID_5871_">
                  <g id="XMLID_5878_">
                    <path
                      id="XMLID_5879_"
                      d="M404.9,148.3c0,0,0.1-0.2,0.2-0.6l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0
                c0,0.2,0.1,0.4,0.3,0.5l0.6,0.4l0-1.7V148.3z"
                    />
                  </g>
                  <g id="XMLID_5872_">
                    <path
                      id="XMLID_5874_"
                      d="M405.3,152.2l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.5l0-0.8l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l-0.1,0.4c-0.1,0.2-0.1,0.4-0.2,0.5L405.3,152.2z M404.4,148l0,0.2c0,0.9,0,2.3,0,2.4
                c0,0,0,0.1,0.1,0.1l0-2.6c0,0,0,0,0-0.1L404.4,148z"
                    />
                  </g>
                </g>
                <g id="XMLID_5861_">
                  <g id="XMLID_5868_">
                    <path
                      id="XMLID_5869_"
                      style="fill: #ffffff"
                      d="M410.4,151.5l-6.1-3.5c-0.4-0.2-0.4-0.9,0-1.1l6.2-3.5
                c0.5-0.3,1.1-0.3,1.6,0l6.1,3.5c0.4,0.2,0.4,0.9,0,1.1l-6.2,3.5C411.5,151.8,410.9,151.8,410.4,151.5z"
                    />
                  </g>
                  <g id="XMLID_5862_">
                    <path
                      id="XMLID_5864_"
                      d="M411.2,152.1c-0.4,0-0.7-0.1-1-0.3l-6.1-3.5c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9
                l6.2-3.5c0.6-0.4,1.4-0.4,2,0l6.1,3.5c0.3,0.2,0.5,0.5,0.5,0.9c0,0.4-0.2,0.7-0.5,0.9l-6.2,3.5
                C411.9,152.1,411.5,152.1,411.2,152.1z M411.3,143.5c-0.2,0-0.4,0.1-0.6,0.2l-6.2,3.5c-0.1,0.1-0.1,0.2-0.1,0.2
                c0,0,0,0.1,0.1,0.2l6.1,3.5c0.4,0.2,0.8,0.2,1.2,0l6.2-3.5c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-6.1-3.5
                C411.7,143.6,411.5,143.5,411.3,143.5z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_5820_">
                <g id="XMLID_5851_">
                  <g id="XMLID_5857_">
                    <path
                      id="XMLID_5859_"
                      style="fill: #ffffff"
                      d="M486.5,74.5c0,0,0-2.9,0-3.1c0,0,0,0,0-0.1l-6.5,0.6c0,0,0,0,0,0l-0.6-3.6
                c0,0,0,0-0.1,0l-6,3.4c0,0,0,0,0,0l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0c0,0.2,0.1,0.4,0.3,0.5l6,3.5
                c0.5,0.3,1.1,0.3,1.7,0l6.1-3.5C486.4,74.9,486.5,74.7,486.5,74.5L486.5,74.5z"
                    />
                  </g>
                  <g id="XMLID_5852_">
                    <path
                      id="XMLID_5854_"
                      d="M479.2,79.2c-0.4,0-0.7-0.1-1-0.3l-6-3.5c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.1l0-1.2l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1,0.3l6.1-3.5l0.4,0.2l0.6,3.4l6.2-0.6l0.1,0c0.2,0.1,0.3,0.2,0.3,0.4l0,3.2c0,0.3-0.2,0.7-0.5,0.8
                l-6.1,3.5C479.9,79.1,479.6,79.2,479.2,79.2z M472.4,72l0,0.5c0,0.9,0,2,0,2.1c0,0,0,0.1,0.1,0.2l6,3.5
                c0.4,0.2,0.9,0.2,1.2,0l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2h0l0-2.7l-6.2,0.6l-0.2-0.1l-0.2-0.1l-0.5-3.2l-5.7,3.3l-0.2,0
                L472.4,72z"
                    />
                  </g>
                </g>
                <g id="XMLID_5841_">
                  <g id="XMLID_5848_">
                    <path
                      id="XMLID_5850_"
                      d="M486.5,71.3l-6.5,0.6c0,0,0,0,0,0l-0.8,2.2l0,4.7c0.3,0,0.6-0.1,0.9-0.2l6.1-3.5
                c0.2-0.1,0.3-0.3,0.3-0.5l0,0C486.5,74.5,486.5,71.6,486.5,71.3C486.5,71.3,486.5,71.3,486.5,71.3z"
                    />
                  </g>
                  <g id="XMLID_5843_">
                    <path
                      id="XMLID_5844_"
                      d="M479.2,79.2C479.2,79.2,479.2,79.2,479.2,79.2l-0.5,0l0-5.2l0.9-2.7l0.3,0.2l6.5-0.6l0.1,0
                c0.2,0.1,0.3,0.2,0.3,0.5l0,3.1c0,0.4-0.2,0.7-0.5,0.9l-6.1,3.5C480,79.1,479.6,79.2,479.2,79.2z M479.6,74.2l0,4.1
                c0.1,0,0.2-0.1,0.3-0.1l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2l0-2.7l-5.8,0.5L479.6,74.2z"
                    />
                  </g>
                </g>
                <g id="XMLID_5831_">
                  <g id="XMLID_5838_">
                    <path
                      id="XMLID_5840_"
                      d="M473,72.2c0,0,0.1-0.2,0.2-0.6l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0
                c0,0.2,0.1,0.4,0.3,0.5l0.6,0.4l0-1.7V72.2z"
                    />
                  </g>
                  <g id="XMLID_5833_">
                    <path
                      id="XMLID_5834_"
                      d="M473.4,76.1l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.1l0-1.2l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l-0.1,0.4c-0.1,0.2-0.1,0.4-0.2,0.5L473.4,76.1z M472.4,71.9l0,0.5c0,0.9,0,2,0,2.1
                c0,0,0,0.1,0.1,0.1l0-2.6c0,0,0,0,0-0.1L472.4,71.9z"
                    />
                  </g>
                </g>
                <g id="XMLID_5822_">
                  <g id="XMLID_5829_">
                    <path
                      id="XMLID_5830_"
                      style="fill: #ffffff"
                      d="M478.4,75.4l-6.1-3.5c-0.4-0.2-0.4-0.9,0-1.1l6.2-3.5c0.5-0.3,1.1-0.3,1.6,0
                l6.1,3.5c0.4,0.2,0.4,0.9,0,1.1l-6.2,3.5C479.5,75.7,478.9,75.7,478.4,75.4z"
                    />
                  </g>
                  <g id="XMLID_5823_">
                    <path
                      id="XMLID_5824_"
                      d="M479.2,76.1c-0.4,0-0.7-0.1-1-0.3l-6.1-3.5c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9
                l6.2-3.5c0.6-0.4,1.4-0.4,2,0l6.1,3.5c0.3,0.2,0.5,0.5,0.5,0.9c0,0.4-0.2,0.7-0.5,0.9l-6.2,3.5
                C479.9,76,479.6,76.1,479.2,76.1z M479.3,67.5c-0.2,0-0.4,0.1-0.6,0.2l-6.2,3.5c-0.1,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0.1,0.2
                l6.1,3.5c0.4,0.2,0.8,0.2,1.2,0l6.2-3.5c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-6.1-3.5
                C479.7,67.5,479.5,67.5,479.3,67.5z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_5781_">
                <g id="XMLID_5811_">
                  <g id="XMLID_5817_">
                    <path
                      id="XMLID_5819_"
                      style="fill: #ffffff"
                      d="M508.8,167c0,0,0-2.9,0-3.1c0,0,0,0,0-0.1l-6.5,0.6c0,0,0,0,0,0l-0.6-3.6
                c0,0,0,0-0.1,0l-6,3.4c0,0,0,0,0,0l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0c0,0.2,0.1,0.4,0.3,0.5l6,3.5
                c0.5,0.3,1.1,0.3,1.7,0l6.1-3.5C508.7,167.4,508.8,167.2,508.8,167L508.8,167z"
                    />
                  </g>
                  <g id="XMLID_5812_">
                    <path
                      id="XMLID_5813_"
                      d="M501.5,171.7c-0.4,0-0.7-0.1-1-0.3l-6-3.5c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.2l0-1.1l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1,0.3l6.1-3.5l0.4,0.2l0.6,3.4l6.2-0.6l0.1,0c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8
                l-6.1,3.5C502.2,171.6,501.9,171.7,501.5,171.7z M494.7,164.4l0,0.4c0,0.9,0,2.1,0,2.2c0,0,0,0.1,0.1,0.2l6,3.5
                c0.4,0.2,0.9,0.2,1.2,0l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2h0l0-2.7l-6.2,0.6l-0.2-0.1l-0.2-0.1l-0.5-3.2l-5.7,3.3l-0.2,0
                L494.7,164.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_5801_">
                  <g id="XMLID_5808_">
                    <path
                      id="XMLID_5810_"
                      d="M508.7,163.8l-6.5,0.6c0,0,0,0,0,0l-0.8,2.2l0,4.7c0.3,0,0.6-0.1,0.9-0.2l6.1-3.5
                c0.2-0.1,0.3-0.3,0.3-0.5l0,0C508.8,167,508.8,164.1,508.7,163.8C508.8,163.8,508.8,163.8,508.7,163.8z"
                    />
                  </g>
                  <g id="XMLID_5803_">
                    <path
                      id="XMLID_5804_"
                      d="M501.5,171.7C501.5,171.7,501.5,171.7,501.5,171.7l-0.5,0l0-5.2l0.9-2.7l0.3,0.2l6.5-0.6l0.1,0
                c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8l-6.1,3.5C502.2,171.6,501.9,171.7,501.5,171.7z M501.9,166.7l0,4.1
                c0.1,0,0.2-0.1,0.3-0.1l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2l0-2.7l-5.8,0.5L501.9,166.7z"
                    />
                  </g>
                </g>
                <g id="XMLID_5792_">
                  <g id="XMLID_5799_">
                    <path
                      id="XMLID_5800_"
                      d="M495.2,164.7c0,0,0.1-0.2,0.2-0.6l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0
                c0,0.2,0.1,0.4,0.3,0.5l0.6,0.4l0-1.7V164.7z"
                    />
                  </g>
                  <g id="XMLID_5793_">
                    <path
                      id="XMLID_5794_"
                      d="M495.6,168.6l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.2l0-1.1l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l-0.3,0.9L495.6,168.6z M494.7,164.4l0,0.4c0,0.9,0,2.1,0,2.2c0,0,0,0.1,0.1,0.1l0-2.6
                l0-0.1L494.7,164.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_5782_">
                  <g id="XMLID_5789_">
                    <path
                      id="XMLID_5790_"
                      style="fill: #ffffff"
                      d="M500.7,167.9l-6.1-3.5c-0.4-0.2-0.4-0.9,0-1.1l6.2-3.5
                c0.5-0.3,1.1-0.3,1.6,0l6.1,3.5c0.4,0.2,0.4,0.9,0,1.1l-6.2,3.5C501.8,168.2,501.2,168.2,500.7,167.9z"
                    />
                  </g>
                  <g id="XMLID_5783_">
                    <path
                      id="XMLID_5785_"
                      d="M501.5,168.5c-0.4,0-0.7-0.1-1-0.3l-6.1-3.5c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9
                l6.2-3.5c0.6-0.4,1.4-0.4,2,0l6.1,3.5c0.3,0.2,0.5,0.5,0.5,0.9c0,0.4-0.2,0.7-0.5,0.9l-6.2,3.5
                C502.2,168.5,501.9,168.5,501.5,168.5z M501.6,160c-0.2,0-0.4,0.1-0.6,0.2l-6.2,3.5c-0.1,0.1-0.1,0.2-0.1,0.2
                c0,0,0,0.1,0.1,0.2l6.1,3.5c0.4,0.2,0.8,0.2,1.2,0l6.2-3.5c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-6.1-3.5
                C502,160,501.8,160,501.6,160z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_5258_">
                <g id="XMLID_5774_">
                  <path
                    id="XMLID_5779_"
                    style="fill: #ffffff"
                    d="M529.5,82.2c0,0,4,1.6,4.1,6.2c0,3.8-3.8,7.4-3.8,7.4l-2-8.9L529.5,82.2z"
                  />
                  <path
                    id="XMLID_5775_"
                    d="M527.4,86.9l1.9-5.2l0.4,0.2c0,0,4.3,1.7,4.3,6.6c0,3.9-3.7,7.5-3.9,7.7l-0.5,0.5L527.4,86.9z
               M528.3,86.9l1.8,8.1c1-1.1,3.2-3.8,3.1-6.5c0-3.5-2.5-5.1-3.4-5.6L528.3,86.9z"
                  />
                </g>
                <g id="XMLID_5767_">
                  <path
                    id="XMLID_5772_"
                    style="fill: #ffffff"
                    d="M524.8,71.1c0,0,0.1,0,0.2,0.1c0.8,0.6,3.7,2.7,3.5,5.9
              c-0.2,3.8,0.6,6.5,0.6,6.5l-0.5,5c0,0,3,1.4,2.1,5.7c-0.9,4.3-3.4,3.7-3.4,8.1c0,4.4-2.8-10.7-2.8-10.9c0-0.2-3.1-9.2-3.1-9.9
              c0-0.7-0.5-6.2-0.5-6.2L524.8,71.1L524.8,71.1z"
                  />
                  <path
                    id="XMLID_5768_"
                    d="M527.2,103.7c-0.4,0-0.7,0-1.9-6.1c-0.5-2.5-1.1-5.8-1.1-6c0-0.2-0.4-1.3-0.8-2.5
              c-1.6-4.7-2.3-7-2.3-7.5c0-0.5-0.3-3.9-0.5-6.1l0-0.2l4.1-4.4l0.2,0.2l0.2-0.3c0,0,0.1,0.1,0.3,0.2c0.9,0.6,3.9,2.8,3.7,6.3
              c-0.2,3.7,0.6,6.3,0.6,6.3l0,0.1l-0.5,4.8c0.8,0.5,2.8,2.2,2.1,6c-0.4,2.1-1.2,3.1-1.9,4c-0.8,1-1.5,1.8-1.5,4
              C527.7,103.1,527.7,103.7,527.2,103.7C527.2,103.7,527.2,103.7,527.2,103.7z M528.1,77.2c0.2-2.9-2.2-4.8-3.2-5.5l-3.6,3.9
              c0.1,1.1,0.6,5.4,0.5,6.1c0,0.5,1.5,4.9,2.2,7.2c0.8,2.5,0.8,2.6,0.8,2.6c0,0.4,1.3,6.9,2,9.9c0.2-1.8,0.9-2.6,1.6-3.5
              c0.7-0.8,1.4-1.7,1.8-3.6c0.8-3.9-1.8-5.2-1.9-5.3l-0.3-0.1l0.5-5.2C528.6,83.2,527.9,80.7,528.1,77.2z"
                  />
                </g>
                <g id="XMLID_5760_">
                  <path
                    id="XMLID_5765_"
                    style="fill: #ffffff"
                    d="M511.9,65.9c0,0-2.4,1.9-3.3,5.5c-0.9,3.6,1.7,6.3,1.7,6.3s4-3.9,4-4.2
              c0-0.4-0.9-4.8-0.9-4.8L511.9,65.9z"
                  />
                  <path
                    id="XMLID_5761_"
                    d="M510,78c-0.1-0.1-2.7-2.9-1.8-6.7c0.9-3.7,3.3-5.7,3.4-5.8l0.4-0.3l1.6,3.3l0,0.1
              c0.3,1.3,1,4.5,0.9,4.9c0,0.2,0,0.5-4.1,4.5l-0.3,0.3L510,78z M512.9,68.9l-1.1-2.3c-0.7,0.7-2.2,2.4-2.8,5
              c-0.6,2.6,0.7,4.8,1.3,5.6c1.6-1.6,3.3-3.3,3.5-3.7C513.8,73,513.3,70.8,512.9,68.9z"
                  />
                </g>
                <g id="XMLID_5753_">
                  <path
                    id="XMLID_5758_"
                    style="fill: #ffffff"
                    d="M508.2,78.4c0,0-2.5,1.9-1.9,5.6c0.5,3.7,4.8,6.4,4.8,6.4l0.8-8.8L508.2,78.4
              z"
                  />
                  <path
                    id="XMLID_5755_"
                    d="M510.9,90.8c-0.2-0.1-4.4-2.8-5-6.7c-0.6-3.9,2-5.9,2.1-6l0.3-0.2l4.1,3.7l-0.9,9.6L510.9,90.8z
               M508.2,78.9c-0.6,0.6-1.9,2.2-1.5,5c0.4,2.7,2.9,4.9,4,5.7l0.7-7.8L508.2,78.9z"
                  />
                </g>
                <g id="XMLID_5746_">
                  <path
                    id="XMLID_5752_"
                    style="fill: #ffffff"
                    d="M524.7,66.4c0,0,0.1,0,0.2,0.1c0.8,0.6,3.7,2.7,3.5,5.9
              c-0.2,3.8,0.6,6.5,0.6,6.5l-0.3,3.1c0,0,3.9,0.8,3,5.2c-0.9,4.3-4.5,6.2-4.5,10.6c0,4.4-2.8-10.7-2.8-10.9
              c0-0.2-3.1-9.2-3.1-9.9c0-0.7-0.5-6.2-0.5-6.2L524.7,66.4L524.7,66.4z"
                  />
                  <path
                    id="XMLID_5747_"
                    d="M527.1,99c-0.4,0-0.7,0-1.9-6.1c-0.5-2.5-1.1-5.8-1.1-6c0-0.2-0.4-1.3-0.8-2.5
              c-1.6-4.7-2.3-7-2.3-7.5c0-0.5-0.3-3.9-0.5-6.1l0-0.2l4.1-4.4l0.2,0.2l0.2-0.3c0,0,0.1,0.1,0.3,0.2c0.9,0.6,3.9,2.8,3.7,6.3
              c-0.2,3.7,0.6,6.3,0.6,6.3l0,0.1l-0.3,2.8c1,0.4,3.8,1.7,3,5.5c-0.4,2-1.4,3.5-2.4,5c-1.1,1.7-2.2,3.3-2.2,5.5
              C527.6,98.4,527.6,99,527.1,99L527.1,99z M528,72.5c0.2-2.9-2.2-4.8-3.2-5.5l-3.6,3.9c0.1,1.1,0.6,5.4,0.5,6.1
              c0,0.5,1.5,4.9,2.2,7.2c0.8,2.5,0.8,2.6,0.8,2.6c0,0.4,1.3,7,2.1,10c0.3-2,1.3-3.5,2.2-5c0.9-1.4,1.9-2.8,2.2-4.7
              c0.8-3.9-2.6-4.6-2.7-4.7l-0.4-0.1l0.3-3.4C528.5,78.5,527.8,76,528,72.5z"
                  />
                </g>
                <g id="XMLID_5717_">
                  <g id="XMLID_5739_">
                    <path
                      id="XMLID_5744_"
                      d="M535.9,149.6c0,0,0-29.7,0-30.1c0,0,0-0.1-0.1-0.1l-9.6,0.9c0,0,0,0,0,0l-0.9-5.3c0,0-0.1,0-0.1,0
                l-32.3-8.4c0,0,0,0-0.1,0l-1.7-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.4,0,29.9,0,30l0,0c0,0.3,0.2,0.6,0.5,0.7l32.3,18.6
                c0.8,0.4,1.7,0.4,2.5,0l9-5.2C535.8,150.2,535.9,149.9,535.9,149.6L535.9,149.6z"
                    />
                    <path
                      id="XMLID_5741_"
                      d="M523.8,155.9l-32.3-18.6c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0-0.1,0-21.7c0-4.6,0-8.2,0-8.3
                c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.6,0.4l0,0l32.3,8.4c0.1,0,0.2,0,0.3,0.1l0.2,0.1l0.9,5.1l9.3-0.8l0.1,0
                c0.2,0.1,0.4,0.2,0.4,0.5c0,0.3,0,30.1,0,30.1l0,0c0,0.5-0.3,0.9-0.7,1.1l-9,5.2c-0.4,0.3-0.9,0.4-1.4,0.4
                C524.7,156.3,524.2,156.2,523.8,155.9z M535.5,119.9l-9.3,0.8l-0.4-0.2l-0.9-5.2l-32-8.3c-0.1,0-0.1,0-0.2,0l-1.3-0.4
                c0,1.1,0,4.2,0,7.9c0,8.5,0,21.4,0,21.6c0,0.1,0.1,0.3,0.3,0.4l32.3,18.6c0.6,0.4,1.4,0.4,2,0l9-5.2c0.2-0.1,0.2-0.2,0.3-0.5
                C535.5,148.9,535.5,123.3,535.5,119.9z"
                    />
                  </g>
                  <g id="XMLID_5732_">
                    <path
                      id="XMLID_5738_"
                      style="fill: #ffffff"
                      d="M535.9,119.4l-9.6,0.9c0,0,0,0,0,0l-1.1,3.3l0,32.3c0.4,0,0.9-0.1,1.3-0.3
                l9-5.2c0.3-0.2,0.5-0.5,0.5-0.8l0,0C535.9,149.6,535.9,119.9,535.9,119.4C535.9,119.5,535.9,119.5,535.9,119.4z"
                    />
                    <path
                      id="XMLID_5734_"
                      d="M525.1,156.3l-0.4,0l0-32.7l1.3-3.9l0.3,0.2l9.6-0.9l0.1,0c0.2,0.1,0.4,0.3,0.4,0.5
                c0,0.3,0,30.1,0,30.1c0,0.5-0.3,0.9-0.7,1.1l-9,5.2C526.2,156.2,525.7,156.3,525.1,156.3
                C525.2,156.3,525.2,156.3,525.1,156.3z M526.6,120.7l-1,2.9l0,31.8c0.2,0,0.5-0.1,0.7-0.2l9-5.2c0.2-0.1,0.3-0.3,0.3-0.4
                c0-0.1,0-26.3,0-29.7L526.6,120.7z"
                    />
                  </g>
                  <g id="XMLID_5725_">
                    <path
                      id="XMLID_5731_"
                      style="fill: #ffffff"
                      d="M494.4,108.5c0,0-1.7-1.5-1.5-1.9l-1.6-0.4c-0.1,0-0.1,0-0.1,0.1
                c0,0.4,0,29.9,0,30l0,0c0,0.3,0.2,0.6,0.5,0.7l2.7,1.6l0-27.9V108.5z"
                    />
                    <path
                      id="XMLID_5727_"
                      d="M491.4,137.3c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0,0,0-21.7c0-4.6,0-8.2,0-8.3c0-0.2,0.1-0.3,0.2-0.4
                c0.1-0.1,0.3-0.1,0.4-0.1l2,0.6l-0.1,0.4c0.1,0.3,0.7,1,1.4,1.5l0.1,0.1l0,31L491.4,137.3z M492.5,106.9l-0.9-0.2
                c0,1.1,0,4.2,0,7.9c0,8.5,0,21.4,0,21.6c0,0.1,0.1,0.3,0.3,0.4l2.1,1.3l0-29.2C493,107.8,492.6,107.2,492.5,106.9z"
                    />
                  </g>
                  <g id="XMLID_5718_">
                    <path
                      id="XMLID_5724_"
                      style="fill: #ffffff"
                      d="M524,125.5l-32.4-18.6c-0.6-0.4-0.6-1.3,0-1.6l9.1-5.2
                c0.7-0.4,1.6-0.4,2.4,0l32.4,18.6c0.6,0.4,0.6,1.3,0,1.6l-9.1,5.2C525.7,126,524.8,125.9,524,125.5z"
                    />
                    <path
                      id="XMLID_5720_"
                      d="M523.8,125.9l-32.4-18.6c-0.4-0.2-0.7-0.7-0.7-1.2c0-0.5,0.3-0.9,0.7-1.2l9.1-5.2
                c0.9-0.5,1.9-0.5,2.8,0l32.4,18.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.5-0.3,0.9-0.7,1.2l-9.1,5.2c-0.4,0.2-0.9,0.4-1.4,0.4
                C524.7,126.3,524.3,126.1,523.8,125.9z M500.9,100.4l-9.1,5.2c-0.2,0.1-0.3,0.4-0.3,0.5c0,0.1,0,0.3,0.3,0.5l32.4,18.6l0,0
                c0.6,0.4,1.4,0.4,2,0l9.1-5.2c0.2-0.1,0.3-0.4,0.3-0.5c0-0.1,0-0.3-0.3-0.5l-32.4-18.6c-0.3-0.2-0.6-0.3-1-0.3
                C501.6,100.1,501.2,100.2,500.9,100.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_5675_">
                  <g id="XMLID_5702_">
                    <path
                      id="XMLID_5716_"
                      d="M533.8,115.1c0,0,0-4.3,0-4.6c0,0,0-0.1-0.1-0.1l-33-12.6c0,0,0,0,0,0l-0.9-5.3c0,0-0.1,0-0.1,0
                l-8.8,5.1c0,0,0,0-0.1,0l-1.7-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.4,0,4.5,0,4.5l0,0c0,0.3,0.2,0.6,0.5,0.7l32.3,18.6
                c0.8,0.4,1.7,0.4,2.5,0l9-5.2C533.7,115.7,533.8,115.4,533.8,115.1L533.8,115.1z"
                    />
                    <path
                      id="XMLID_5711_"
                      d="M521.7,121.5l-32.3-18.6c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0-0.1,0-3.4l0-1.2c0-0.2,0.1-0.3,0.2-0.4
                c0.1-0.1,0.3-0.1,0.4-0.1l1.6,0.4l8.7-5c0.2-0.1,0.3-0.1,0.5,0l0.2,0.1l0.9,5.3l32.8,12.5c0.2,0.1,0.4,0.2,0.4,0.5
                c0,0,0,4.6,0,4.6l0,0c0,0.5-0.3,0.9-0.7,1.1l-9,5.2c-0.4,0.3-0.9,0.4-1.4,0.4C522.6,121.8,522.1,121.7,521.7,121.5z
                 M533.4,110.7l-32.8-12.5l-0.3-0.1l-0.8-5l-8.4,4.8c-0.1,0.1-0.2,0.1-0.4,0l-1.3-0.4l0,0.7c0,1.3,0,3.3,0,3.4
                c0,0.1,0.1,0.3,0.3,0.4l32.3,18.6c0.6,0.4,1.4,0.4,2,0l9-5.2c0.2-0.1,0.2-0.2,0.3-0.5C533.4,114.9,533.4,111.7,533.4,110.7z"
                    />
                  </g>
                  <g id="XMLID_5695_">
                    <path
                      id="XMLID_5700_"
                      style="fill: #ffffff"
                      d="M533.8,110.4l-33-12.6c0,0,0,0,0,0l22.3,16.7l0,6.9c0.4,0,0.9-0.1,1.3-0.3
                l9-5.2c0.3-0.2,0.5-0.5,0.5-0.8l0,0C533.8,115.1,533.9,110.8,533.8,110.4C533.9,110.5,533.8,110.4,533.8,110.4z"
                    />
                    <path
                      id="XMLID_5696_"
                      d="M523,121.8l-0.4,0l0-7.1l-22.1-16.6l0.4-0.7l33,12.6c0.2,0.1,0.3,0.3,0.3,0.5c0,0,0,4.6,0,4.7
                c0,0.5-0.3,0.9-0.7,1.1l-9,5.2C524.1,121.7,523.6,121.8,523,121.8C523.1,121.8,523.1,121.8,523,121.8z M503.3,99.2l20.1,15.1
                l0,6.7c0.2,0,0.5-0.1,0.7-0.2l9-5.2c0.2-0.1,0.3-0.3,0.3-0.4c0-0.1,0-3.4,0-4.4L503.3,99.2z"
                    />
                  </g>
                  <g id="XMLID_5688_">
                    <path
                      id="XMLID_5693_"
                      style="fill: #ffffff"
                      d="M492.3,99.4c0,0-1.7-1.5-1.5-1.9l-1.6-0.4c-0.1,0-0.1,0-0.1,0.1
                c0,0.4,0,4.5,0,4.5l0,0c0,0.3,0.2,0.6,0.5,0.7l2.7,1.6l0-2.5V99.4z"
                    />
                    <path
                      id="XMLID_5689_"
                      d="M489.4,102.8c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0-0.1,0-3.4l0-1.2c0-0.2,0.1-0.3,0.2-0.4
                c0.1-0.1,0.3-0.1,0.4-0.1l2,0.6l-0.1,0.4c0.1,0.3,0.7,1,1.4,1.5l0.1,0.1l0,5.5L489.4,102.8z M490.4,97.8l-0.9-0.2l0,0.7
                c0,1.3,0,3.3,0,3.4c0,0.1,0.1,0.3,0.3,0.4l2.1,1.3l0-3.7C490.9,98.7,490.5,98.2,490.4,97.8z"
                    />
                  </g>
                  <g id="XMLID_5676_">
                    <path
                      id="XMLID_5686_"
                      style="fill: #ffffff"
                      d="M521.9,116.5l-32.4-18.6c-0.6-0.4-0.6-1.3,0-1.6l9.1-5.2
                c0.7-0.4,1.6-0.4,2.4,0l32.4,18.6c0.6,0.4,0.6,1.3,0,1.6l-9.1,5.2C523.6,116.9,522.7,116.9,521.9,116.5z"
                    />
                    <path
                      id="XMLID_5678_"
                      d="M521.7,116.9l-32.4-18.6c-0.4-0.2-0.7-0.7-0.7-1.2c0-0.5,0.3-0.9,0.7-1.2l9.1-5.2
                c0.9-0.5,1.9-0.5,2.8,0l32.4,18.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.5-0.3,0.9-0.7,1.2l-9.1,5.2c-0.4,0.2-0.9,0.4-1.4,0.4
                C522.6,117.2,522.2,117.1,521.7,116.9z M498.8,91.4l-9.1,5.2c-0.2,0.1-0.3,0.4-0.3,0.5c0,0.1,0,0.3,0.3,0.5l32.4,18.6l0,0
                c0.6,0.4,1.4,0.4,2,0l9.1-5.2c0.2-0.1,0.3-0.4,0.3-0.5c0-0.1,0-0.3-0.3-0.5l-32.4-18.6c-0.3-0.2-0.6-0.3-1-0.3
                C499.5,91.1,499.1,91.2,498.8,91.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_5585_">
                  <g id="XMLID_5589_">
                    <path
                      id="XMLID_5674_"
                      style="fill: #ffffff"
                      d="M526.8,147.6l-5-2.8c-0.2-0.1-0.4-0.4-0.4-0.7v-9.6c0-0.3,0.2-0.4,0.4-0.3
                l5,2.8c0.2,0.1,0.4,0.4,0.4,0.7v9.6C527.2,147.6,527,147.7,526.8,147.6z"
                    />
                    <path
                      id="XMLID_5591_"
                      d="M526.6,148l-5-2.8c-0.3-0.2-0.6-0.6-0.6-1.1v-9.6c0-0.3,0.1-0.5,0.3-0.7c0.2-0.1,0.4-0.1,0.7,0
                l5,2.8c0.3,0.2,0.6,0.6,0.6,1.1v9.6c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.2,0.1-0.3,0.1C526.8,148.1,526.7,148,526.6,148z
                 M521.8,144.1c0,0.1,0.1,0.3,0.2,0.4l4.8,2.7v-9.4c0-0.1-0.1-0.3-0.2-0.4l-4.8-2.7V144.1z"
                    />
                  </g>
                  <g id="XMLID_5587_">
                    <path
                      id="XMLID_5588_"
                      d="M525.1,139.1c0,0.3-0.2,0.5-0.5,0.3c-0.3-0.2-0.5-0.6-0.5-0.9s0.2-0.5,0.5-0.3
                C524.9,138.4,525.1,138.8,525.1,139.1z"
                    />
                  </g>
                </g>
                <g id="XMLID_5556_">
                  <g id="XMLID_5563_">
                    <g id="XMLID_5578_">
                      <path
                        id="XMLID_5584_"
                        style="fill: #ffffff"
                        d="M518.5,144.3l-3.2-2c-0.2-0.1-0.4-0.1-0.5,0l0,0l0,0c0,0,0,0,0,0l-3.3,1.9
                  c-0.1,0-0.1,0.1-0.1,0.2l3.1,3.5c0,0,0-1.3,0-1.2v3.2c0,0.5,0.3,1.2,0.7,1.4l-2.9-1.6c0,0,0,0,0.1,0.1l3.1,5.4
                  c0,0.1,0.1,0.1,0.2,0.1l3.3-1.9c0.1-0.1,0.2-0.3,0.2-0.6v-7C519.3,145.1,518.9,144.5,518.5,144.3z"
                      />
                      <path
                        id="XMLID_5580_"
                        d="M515.5,155.7c-0.2,0-0.3-0.1-0.4-0.3l-3.1-5.4c0,0,0.1,0.1,0.1,0.1l0.4-0.7l1.6,0.9
                  c0-0.1,0-0.2,0-0.4v-1.8l-3-3.4l-0.1-0.1c-0.2-0.3-0.1-0.6,0.2-0.8l3.3-2c0.3-0.2,0.6-0.2,0.9,0l3.2,2
                  c0.5,0.3,0.9,1.1,0.9,1.8v7c0,0.4-0.1,0.7-0.4,0.9l-3.3,1.9c-0.1,0.1-0.2,0.1-0.3,0.1C515.6,155.7,515.5,155.7,515.5,155.7z
                   M515,142.6l-3,1.8l2.2,2.4c0,0,0-0.1,0-0.1l0-0.4l0.8,0v3.6c0,0.4,0.3,0.9,0.5,1.1l-0.4,0.7l-1.6-0.9l2.3,4l3.1-1.8
                  c0,0,0.1-0.1,0.1-0.2v-7c0-0.4-0.3-0.9-0.5-1.1l-3.2-2C515,142.6,515,142.6,515,142.6C515,142.6,515,142.6,515,142.6z"
                      />
                    </g>
                    <g id="XMLID_5571_">
                      <path
                        id="XMLID_5577_"
                        style="fill: #ffffff"
                        d="M512.3,149.7C512.4,149.8,512.4,149.8,512.3,149.7l3.1,5.4
                  c0.1,0.1,0.2,0.1,0.3,0.1l3.3-1.9c0.1-0.1,0.2-0.3,0.2-0.6v-7c0,0,0-0.1-0.1,0l-3.4,2c0,0,0,0,0,0L512.3,149.7z"
                      />
                      <path
                        id="XMLID_5573_"
                        d="M515.5,155.7c-0.2,0-0.3-0.1-0.4-0.3l-3-5.3l0.3-0.3l-0.3,0.3l-0.4-0.4l3.9-2.3l3.4-2
                  c0.1-0.1,0.3-0.1,0.5,0l0.2,0.2v7.2c0,0.4-0.1,0.7-0.4,0.9l-3.3,1.9c-0.1,0.1-0.2,0.1-0.3,0.1
                  C515.6,155.7,515.6,155.7,515.5,155.7z M516,148.1l-3.1,1.8l2.8,4.9l3.1-1.8c0,0,0.1-0.1,0.1-0.2v-6.3L516,148.1z"
                      />
                    </g>
                    <g id="XMLID_5564_">
                      <path
                        id="XMLID_5570_"
                        d="M515,155.4l-3.2-2c-0.4-0.2-0.7-0.9-0.7-1.4v-7c0-0.5,0.3-0.8,0.7-0.6l3.2,2
                  c0.4,0.2,0.7,0.9,0.7,1.4v7C515.8,155.4,515.4,155.6,515,155.4z"
                      />
                      <path
                        id="XMLID_5566_"
                        d="M514.8,155.7l-3.2-2c-0.5-0.3-1-1.1-1-1.8v-7c0-0.4,0.2-0.8,0.5-1c0.3-0.2,0.6-0.1,0.9,0l3.2,2
                  c0.5,0.3,1,1.1,1,1.8v7c0,0.4-0.2,0.8-0.5,1c-0.1,0.1-0.3,0.1-0.4,0.1C515.2,155.9,515,155.8,514.8,155.7z M511.5,144.9v7
                  c0,0.4,0.3,0.9,0.5,1.1l3.2,2c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.2v-7c0-0.4-0.3-0.9-0.5-1.1l-3.2-2c0,0-0.1,0-0.1,0
                  C511.5,144.7,511.5,144.8,511.5,144.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_5560_">
                    <rect
                      id="XMLID_5562_"
                      x="513.9"
                      y="144.2"
                      transform="matrix(0.8488 -0.5288 0.5288 0.8488 1.5401 294.6618)"
                      width="3.9"
                      height="0.8"
                    />
                  </g>
                  <g id="XMLID_5558_">
                    <rect
                      id="XMLID_5559_"
                      x="514.7"
                      y="150.1"
                      transform="matrix(0.8564 -0.5163 0.5163 0.8564 -3.451 288.5355)"
                      width="4.6"
                      height="0.8"
                    />
                  </g>
                </g>
                <g id="XMLID_5549_">
                  <path
                    id="XMLID_5555_"
                    style="fill: #ffffff"
                    d="M512.4,68.6c-1.6,0.5-2.8,1.8-3.2,3.4c-0.3,1-0.8,2.3-1.8,3.5
              c-2.2,2.9-0.4,6.3,2.1,6.5c2.5,0.2,4.2-7.8,4.2-7.8s-0.7-5.7-1-5.7C512.6,68.5,512.5,68.5,512.4,68.6z"
                  />
                  <path
                    id="XMLID_5551_"
                    d="M509.4,82.5c-1.3-0.1-2.5-1-3.1-2.3c-0.7-1.6-0.4-3.4,0.7-4.8c0.8-1,1.4-2.2,1.7-3.4
              c0.5-1.7,1.8-3.1,3.5-3.7l0,0c0.2-0.1,0.4-0.1,0.4-0.1c0.4,0,0.7,0,1.4,6.1l0,0.1l0,0.1c-0.3,1.4-1.9,8.1-4.5,8.1
              C509.5,82.5,509.5,82.5,509.4,82.5z M512.5,69c-1.4,0.5-2.5,1.7-2.9,3.1c-0.4,1.3-1,2.6-1.9,3.7c-1,1.3-1.2,2.7-0.6,4
              c0.5,1.1,1.4,1.8,2.4,1.8c0,0,0,0,0.1,0c1.4,0,3-4.3,3.7-7.4C513,72.1,512.7,69.8,512.5,69z"
                  />
                </g>
                <g id="XMLID_5463_">
                  <path
                    id="XMLID_5548_"
                    style="fill: #ffffff"
                    d="M514.1,75.3c0,0-3.3-0.6-4.1,2.4c-0.8,3-1.8,10.1-1.8,15.4
              c0,3.9-1.4,9.7-1.4,9.7l10.4-1.4l2.3-7.6L514.1,75.3z"
                  />
                  <path
                    id="XMLID_5464_"
                    d="M506.4,102.8c0-0.1,1.4-5.9,1.4-9.6c0-5.5,1-12.7,1.8-15.5c0.7-2.7,3.3-3,4.6-2.7l0.3,0l5.5,19
              l-2.4,7.9l-11.2,1.5L506.4,102.8z M519.1,93.9l-5.3-18.2c-0.7-0.1-2.8,0-3.4,2.2c-0.7,2.8-1.7,9.8-1.7,15.3
              c0,3.2-0.9,7.7-1.3,9.2l9.5-1.3L519.1,93.9z"
                  />
                </g>
                <g id="XMLID_5456_">
                  <path
                    id="XMLID_5462_"
                    d="M493.4,148.1c0,0,0.3-2.2,2.4-4.1s4.8-5.8,4.8-5.8l0.8-0.8l0.7,0.2c0,0-0.3,4.9,0,7.2l-1.6,0.9
              l-0.2-0.6c0,0-2,2.1-3.9,2.8C494.4,148.6,493.4,148.1,493.4,148.1z"
                  />
                  <path
                    id="XMLID_5457_"
                    d="M493.2,148.4l-0.3-0.1l0-0.3c0-0.1,0.3-2.4,2.5-4.3c2-1.8,4.7-5.7,4.7-5.7l1-1.1l1.2,0.4l0,0.3
              c0,0-0.3,4.9,0,7.1l0,0.3l-2.2,1.3l-0.2-0.5c-0.7,0.7-2.1,2-3.6,2.5c-0.8,0.3-1.5,0.4-2,0.4
              C493.7,148.6,493.2,148.5,493.2,148.4z M500.6,145.1l0.9-0.5c-0.2-2-0.1-5.6,0-6.7l-0.1,0l-0.6,0.6c-0.3,0.4-2.8,4-4.8,5.9
              c-1.5,1.3-2,2.8-2.2,3.5c0.4,0.1,1.2,0.1,2.3-0.3c1.8-0.6,3.7-2.7,3.7-2.7l0.5-0.5L500.6,145.1z"
                  />
                </g>
                <g id="XMLID_5449_">
                  <path
                    id="XMLID_5455_"
                    style="fill: #ffffff"
                    d="M498,137.8c0,0-0.3,3.9-2.2,6.2c0,0,0.8,1.7,3.1,0c2.3-1.7,2.4-7.3,2.4-7.3
              L498,137.8z"
                  />
                  <path
                    id="XMLID_5451_"
                    d="M496.6,145.1c-0.8-0.2-1.2-0.9-1.2-0.9l-0.1-0.2l0.2-0.2c1.8-2.1,2.1-5.9,2.1-5.9l0-0.3l4.1-1.4
              l0,0.6c0,0.2-0.2,5.8-2.6,7.6c-0.7,0.5-1.4,0.8-2.1,0.8C496.9,145.1,496.7,145.1,496.6,145.1z M498.3,138.1
              c-0.1,0.9-0.6,3.8-2.1,5.9c0.1,0.1,0.3,0.2,0.5,0.3c0.5,0.1,1.1-0.1,1.8-0.6c1.7-1.2,2.1-4.9,2.2-6.4L498.3,138.1z"
                  />
                </g>
                <g id="XMLID_5442_">
                  <path
                    id="XMLID_5448_"
                    d="M497.7,149.9c0,0,0.3-2.2,2.4-4.1c2.1-1.9,4.8-5.8,4.8-5.8l0.8-0.8l0,0c0,0,0.4,5.2,0.7,7.4
              l-1.6,0.9l-0.2-0.6c0,0-2,2.1-3.9,2.8C498.7,150.4,497.7,149.9,497.7,149.9z"
                  />
                  <path
                    id="XMLID_5444_"
                    d="M497.5,150.3l-0.3-0.1l0-0.3c0-0.1,0.3-2.4,2.5-4.3c2-1.8,4.7-5.7,4.7-5.7l1.5-1.5l0.1,0.9
              c0,0.1,0.4,5.2,0.7,7.4l0,0.3l-2.2,1.3l-0.2-0.5c-0.7,0.7-2.1,2-3.6,2.5c-0.8,0.3-1.5,0.4-2,0.4
              C498,150.5,497.6,150.3,497.5,150.3z M505,146.9l0.9-0.5c-0.2-1.7-0.5-4.7-0.6-6.2l-0.1,0.1c-0.3,0.4-2.8,4-4.8,5.9
              c-1.5,1.3-2,2.8-2.2,3.5c0.4,0.1,1.2,0.1,2.3-0.3c1.8-0.6,3.7-2.7,3.7-2.7l0.5-0.5L505,146.9z"
                  />
                </g>
                <g id="XMLID_5435_">
                  <path
                    id="XMLID_5441_"
                    style="fill: #ffffff"
                    d="M502.3,139.7c0,0-0.3,3.9-2.2,6.2c0,0,0.8,1.7,3.1,0c2.3-1.7,2.4-7.3,2.4-7.3
              L502.3,139.7z"
                  />
                  <path
                    id="XMLID_5437_"
                    d="M500.9,146.9c-0.8-0.2-1.2-0.9-1.2-0.9l-0.1-0.2l0.2-0.2c1.8-2.1,2.1-5.9,2.1-5.9l0-0.3l4.1-1.4
              l0,0.6c0,0.2-0.2,5.8-2.6,7.6c-0.7,0.5-1.4,0.8-2.1,0.8C501.2,147,501.1,147,500.9,146.9z M502.7,140
              c-0.1,0.9-0.6,3.8-2.1,5.9c0.1,0.1,0.3,0.2,0.5,0.3c0.5,0.1,1.1-0.1,1.8-0.6c1.7-1.2,2.1-4.9,2.2-6.4L502.7,140z"
                  />
                </g>
                <g id="XMLID_5428_">
                  <path
                    id="XMLID_5434_"
                    d="M501,117.6c-3.8-2-5.5-3.4-4.7-6l2.2-1.9l7.8-1l4.9,4.5l0.7,3.2l-4.6,2.8
              C507.3,119.1,504,119.3,501,117.6z"
                  />
                  <path
                    id="XMLID_5430_"
                    d="M500.8,118c-3.5-1.9-5.8-3.4-4.9-6.5l0-0.1l2.4-2.1l8.1-1.1l5.1,4.7l0.7,3.6l-4.9,3.1l-0.1,0
              c0,0-0.1,0-0.2,0C506.4,119.6,503.5,119.5,500.8,118z M511.4,116.1l-0.6-2.8l-4.6-4.3l-7.5,1l-2.1,1.7
              c-0.6,2.2,0.7,3.4,4.5,5.5l0,0c2.6,1.4,5.4,1.5,6,1.5c0,0,0,0,0,0L511.4,116.1z"
                  />
                </g>
                <g id="XMLID_5422_">
                  <path
                    id="XMLID_5427_"
                    style="fill: #ffffff"
                    d="M500,110.3c0,0-3.6-0.4-3.6,6.5c0,6.9,1.2,16.1,1.1,21.8c0,0,2.4,0.9,3.9-1.3
              c0,0-0.3-4.1,0.4-9.3c0.7-5.3,0.4-10.4,0-11.6c0,0,5.6,0.1,9.5-3.5c3.9-3.7-6-6.4-6-6.4L500,110.3z"
                  />
                  <path
                    id="XMLID_5423_"
                    d="M497.3,139l-0.3-0.1l0-0.3c0-2.8-0.2-6.4-0.5-10.3c-0.3-3.9-0.6-8-0.6-11.5c0-2.9,0.6-5,1.9-6.1
              c0.8-0.7,1.7-0.8,2-0.8l5.3-3.9l0.2,0.1c0.7,0.2,6.5,1.8,7.2,4.5c0.2,0.9-0.1,1.7-1,2.5c-3.4,3.1-7.8,3.6-9.3,3.7
              c0.3,1.9,0.5,6.7-0.1,11.3c-0.6,5.2-0.4,9.2-0.4,9.3l0,0.1l-0.1,0.1c-0.9,1.3-2.1,1.7-3.1,1.7
              C498,139.2,497.6,139.1,497.3,139z M501.3,128c0.7-5.3,0.4-10.4,0-11.5l-0.2-0.5l0.6,0c0,0,5.5,0.1,9.2-3.4
              c0.6-0.6,0.9-1.2,0.8-1.7c-0.5-1.8-4.6-3.4-6.4-3.9l-5.1,3.8l-0.1,0.1l-0.2,0c0,0-0.8-0.1-1.6,0.6c-0.7,0.7-1.6,2.1-1.6,5.4
              c0,3.5,0.3,7.5,0.6,11.5c0.3,3.7,0.5,7.3,0.5,10.1c0.6,0.1,2,0.2,3-1.1C500.9,136.4,500.7,132.6,501.3,128z"
                  />
                </g>
                <g id="XMLID_5415_">
                  <path
                    id="XMLID_5421_"
                    style="fill: #ffffff"
                    d="M505.4,111.8c0,0-4.6-0.1-4.6,6.8c0,6.9,1.3,15.7,1.3,21.4
              c0,0,2.6,1.4,4.2-0.8c0,0-0.3-4.1,0.4-9.3c0.6-4.7,0.4-9.3,0.1-11.1c0-0.3,0.2-0.5,0.4-0.5c1.5-0.1,5.8-0.6,9-3.5
              c3.9-3.7-6-6.4-6-6.4L505.4,111.8z"
                  />
                  <path
                    id="XMLID_5417_"
                    d="M501.9,140.3l-0.2-0.1l0-0.3c0-2.8-0.3-6.3-0.6-10c-0.3-3.9-0.7-7.8-0.7-11.4c0-2.6,0.7-4.6,1.9-5.9
              c1.2-1.2,2.5-1.3,3-1.4l4.7-3.5l0.2,0.1c0.7,0.2,6.5,1.8,7.2,4.5c0.2,0.9-0.1,1.7-1,2.5c-3.3,3-7.5,3.6-9.2,3.6l0-0.4l0,0.5
              c0.3,1.8,0.5,6.4-0.1,11.2c-0.6,5.2-0.4,9.2-0.4,9.3l0,0.1l-0.1,0.1c-0.7,1.1-1.8,1.4-2.7,1.4
              C503.1,140.8,502.3,140.6,501.9,140.3z M506.2,129.8c0.6-4.7,0.4-9.3,0.1-11c0-0.2,0-0.5,0.2-0.7c0.2-0.2,0.4-0.3,0.6-0.3
              c1.6-0.1,5.7-0.6,8.7-3.4c0.6-0.6,0.9-1.2,0.8-1.7c-0.5-1.8-4.6-3.4-6.4-3.9l-4.6,3.4l-0.1,0c0,0-1.4,0-2.5,1.1
              c-1.1,1.1-1.7,2.9-1.7,5.3c0,3.5,0.3,7.5,0.7,11.3c0.3,3.6,0.6,7.1,0.6,9.8c0.6,0.2,2.3,0.7,3.3-0.7
              C505.8,138.3,505.6,134.5,506.2,129.8z"
                  />
                </g>
                <g id="XMLID_5408_">
                  <path
                    id="XMLID_5414_"
                    style="fill: #ffffff"
                    d="M511.8,98.5c0,0-5.9,2.8-10.2,7.4c-4.3,4.5-5.3,5.7-5.3,5.7s1.6-2.5,6.7-0.6
              c5.1,1.9,5.4,4.1,5.6,6.2c0.1,1.1-1,1.7-1.2,1.9c0,0,0,0.1,0,0.1c0.9-0.1,5.8-0.9,12.4-5.5c7.2-5.1,5.5-10,3.4-13.6
              C521.4,97,511.8,98.5,511.8,98.5z"
                  />
                  <path
                    id="XMLID_5410_"
                    d="M507,119.3l-0.1-0.3l0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.3,0.9-0.7,0.8-1.4
              c-0.1-1.7-0.2-3.9-5.3-5.8c-3.9-1.5-5.6-0.2-6.1,0.3c-0.1,0.1-0.2,0.2-0.2,0.2l-0.7-0.5c0,0,0.1-0.1,0.2-0.2
              c0.4-0.5,1.7-2,5.1-5.6c4.3-4.5,10.2-7.4,10.3-7.4l0.1,0c0.4-0.1,9.9-1.5,11.8,1.8c2.1,3.5,4,8.9-3.5,14.1
              c-6.6,4.6-11.6,5.5-12.5,5.6c0,0,0,0-0.1,0C507.3,119.5,507.1,119.4,507,119.3z M511.9,98.9c-0.6,0.3-6,3.1-10,7.2
              c-1.7,1.7-2.8,3-3.6,3.8c1.1-0.3,2.7-0.2,4.9,0.6c5.1,1.9,5.7,4.2,5.8,6.5c0,0.6-0.2,1-0.4,1.4c2-0.5,6-1.7,11-5.2
              c6.8-4.8,5.5-9.3,3.3-13c-0.8-1.4-3.5-1.8-6.2-1.8C514.8,98.5,513,98.7,511.9,98.9z"
                  />
                </g>
                <g id="XMLID_5401_">
                  <path
                    id="XMLID_5407_"
                    d="M515.1,75.2c0,0-4-1-4.4,1.6c-0.4,2.6-1,5.7-1.9,8.8c-0.9,3.2,2.8,4.5,2.8,4.5s0.1,5.1-1.7,9.4
              c0,0,6.1,7,14.3,3.5l-0.3-1.8c0,0-1.4-8.3-0.6-11.9c0.8-3.6,3.2-5.1,1.9-8.6c-1.3-3.5-6.5-5-6.5-5L515.1,75.2z"
                  />
                  <path
                    id="XMLID_5403_"
                    d="M509.5,99.8l-0.2-0.2l0.1-0.2c1.5-3.6,1.7-7.9,1.7-9c-1.4-0.6-3.5-2.2-2.7-4.9
              c0.8-2.7,1.4-5.7,1.9-8.8c0.1-0.7,0.4-1.3,1-1.6c1.4-0.9,3.7-0.4,3.9-0.4l3.5,0.6c0.2,0.1,5.4,1.6,6.8,5.2
              c0.9,2.4,0.2,4-0.7,5.7c-0.5,0.9-0.9,1.9-1.2,3.2c-0.8,3.5,0.6,11.7,0.6,11.7l0.4,2.1l-0.3,0.1c-1.7,0.7-3.3,1-4.8,1
              C513.6,104.4,509.5,99.9,509.5,99.8z M523.4,101.3c-0.1-0.3-1.4-8.4-0.6-12.1c0.3-1.3,0.8-2.4,1.3-3.3c0.8-1.6,1.4-2.9,0.6-5
              c-1.2-3.3-6.2-4.7-6.2-4.7l-3.5-0.6l0,0c0,0-2.1-0.5-3.3,0.2c-0.4,0.2-0.6,0.6-0.6,1.1c-0.5,3.1-1.1,6.1-1.9,8.9
              c-0.8,2.7,2.4,3.9,2.5,4l0.3,0.1l0,0.3c0,0.2,0,5.1-1.6,9.3c1,1,6.4,6.1,13.4,3.3L523.4,101.3z"
                  />
                </g>
                <g id="XMLID_5394_">
                  <path
                    id="XMLID_5400_"
                    style="fill: #ffffff"
                    d="M513.8,75.6c0,0-1-0.8-1,0.6s-0.1,3.2-0.1,6.1c0,2.6,3.5,4.3,6.1-0.5
              c2.6-4.9,4.4-3.4,4.4-3.4l-1.6-3l-4.2-2.3L513.8,75.6z"
                  />
                  <path
                    id="XMLID_5396_"
                    d="M514.4,85.1c-1.3-0.3-2.1-1.5-2.1-2.9c0-1.7,0-3,0.1-4.1c0-0.8,0-1.4,0-2c0-0.2,0-0.9,0.5-1.2
              c0.3-0.1,0.6,0,0.8,0.1l3.6-2.5l4.5,2.5l2.4,4.7l-1.4-1.1c0,0-0.3-0.2-0.8-0.1c-0.7,0.1-1.7,0.8-3,3.3
              c-1.1,2.1-2.6,3.3-4.1,3.3C514.9,85.2,514.6,85.2,514.4,85.1z M521.3,75.7l-3.9-2.1L514,76l-0.3,0.2l-0.2-0.2
              c-0.1-0.1-0.2-0.1-0.2-0.1c0,0.1-0.1,0.2-0.1,0.4c0,0.6,0,1.2,0,2c0,1.1-0.1,2.4-0.1,4.1c0,1,0.6,1.9,1.5,2.1
              c1.3,0.3,2.7-0.7,3.8-2.8c1.2-2.2,2.4-3.5,3.6-3.8c0.1,0,0.3,0,0.4,0L521.3,75.7z"
                  />
                </g>
                <g id="XMLID_5392_">
                  <path
                    id="XMLID_5393_"
                    style="fill: #ffffff"
                    d="M517.6,92.6c1.1,0,2.3-0.3,3.5-1.2l-0.5-0.7c-3.1,2.3-6.1,0.1-6.2,0l-0.5,0.7
              C513.9,91.4,515.5,92.6,517.6,92.6z"
                  />
                </g>
                <g id="XMLID_5389_">
                  <path
                    id="XMLID_5390_"
                    d="M507.1,113.5c0.4-0.1,9.8-3,11.7-7.3l-0.8-0.3c-1.7,3.9-11.1,6.8-11.2,6.8L507.1,113.5z"
                  />
                </g>
                <g id="XMLID_5386_">
                  <path
                    id="XMLID_5387_"
                    d="M519.8,105.5c0.5-1.8-0.5-2.7-0.5-2.8l-0.6,0.6l0.3-0.3l-0.3,0.3c0,0,0.7,0.7,0.3,1.9L519.8,105.5z"
                  />
                </g>
                <g id="XMLID_5383_">
                  <path
                    id="XMLID_5385_"
                    d="M515.6,82.9c0.1,0,0.3,0,0.5-0.1c1.7-0.6,3.5-4.2,3.9-5.3l-0.8-0.3c-0.4,1.2-2.1,4.4-3.4,4.8
              c-0.2,0.1-0.3,0-0.4,0c-0.9-0.5-0.8-3.2-0.5-4.8l-0.8-0.1c-0.1,0.5-0.7,4.6,0.9,5.6C515.1,82.8,515.3,82.9,515.6,82.9z"
                  />
                </g>
                <g id="XMLID_5357_">
                  <g id="XMLID_5376_">
                    <path
                      id="XMLID_5382_"
                      style="fill: #ffffff"
                      d="M513,67.9c0,0-2.2,7.5,1.3,9.7c0.1,0,0.1,0.1,0.2,0.1c0.6,0,3.1,0,5.2-3.1
                c0.1-0.1,0.1-0.1,0.2-0.2c0.5-0.1,2-0.7,2.1-2.1c0.1-1-0.5-1.3-1-1.4c-0.3,0-0.4-0.3-0.3-0.6l1.2-2.6c0.1-0.2,0-0.3-0.1-0.5
                l-2.5-2.7c-0.1-0.1-0.3-0.2-0.4-0.1l-5,1.1c-0.1,0-0.3,0.1-0.3,0.3L513,67.9z"
                    />
                    <path
                      id="XMLID_5378_"
                      d="M514.5,78.1c-0.1,0-0.3-0.1-0.4-0.1c-3.7-2.4-1.6-9.9-1.5-10.2l0.7-2c0.1-0.3,0.3-0.5,0.6-0.5
                l5-1.1c0.3-0.1,0.6,0,0.8,0.3l2.5,2.7c0.2,0.2,0.3,0.6,0.1,0.9l-1.2,2.6c0.9,0.2,1.4,0.8,1.3,1.8c-0.1,1.7-1.9,2.3-2.4,2.4
                c-2,2.9-4.3,3.2-5.3,3.2C514.7,78.2,514.6,78.2,514.5,78.1z M514.1,66l-0.7,2c-0.1,0.3-2,7.2,1.2,9.3c0.4,0,2.8,0.1,4.8-2.9
                c0.1-0.2,0.3-0.3,0.5-0.3c0.4-0.1,1.7-0.6,1.8-1.7c0.1-0.7-0.3-0.9-0.6-1c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.2-0.1-0.5,0-0.7
                l1.2-2.6l-2.5-2.7L514.1,66z"
                    />
                  </g>
                  <g id="XMLID_5369_">
                    <path
                      id="XMLID_5375_"
                      style="fill: #ffffff"
                      d="M525.8,75.3c-2.7-1.8-2-3.6-1.9-3.8c0.1-0.2,0.3-0.5,0.4-0.7
                c1.1-1.6,1.3-5.4-3.1-7.8c-0.9-0.5-1.8-0.8-2.7-0.8c-4.3-0.3-7.9,3.5-7.5,7.9c0.1,0.8,0.3,1.6,0.6,2.5
                c0.9,1.9,0.6-2.4,4.7-3.6l0.3,0.7c0,0.1,0.1,0.1,0.2,0.1l1.9-1.2c0,0,0.1,0,0.1,0c0.3,0.2,2.3,0.9,1.1,2.8
                c0,0.1,0,0.1,0.1,0.2c0.5,0.1,1.4,0.5,1,1.4c-0.5,1.1-1.2,1.5-1.2,1.5c0,0-0.1,0.2-0.1,0.2l-0.7,1.2c0,0,0,0,0,0
                c-0.1,0.2-0.7,1.9,2.4,3.1c0,0,0.1,0.1,0.2,0.1c0.6,0.3,1.2,0.7,1.8,1.1l1.3,0.9c0,0,2.8,13.8,2.7,10.9
                c-0.1-2.9,0.2-5.3,1.5-8.7S528.8,77.2,525.8,75.3z"
                    />
                    <path
                      id="XMLID_5371_"
                      d="M524.3,81.1l-1.1-0.8c-0.6-0.4-1.2-0.8-1.7-1.1c-0.1,0-0.1-0.1-0.1-0.1c-1.4-0.5-2.2-1.2-2.6-2.1
                c-0.3-0.8-0.1-1.4-0.1-1.6l0.8-1.3l0-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0.6-0.3,1-1.2c0.1-0.3,0-0.4,0-0.4
                c-0.1-0.2-0.5-0.4-0.7-0.4c-0.2,0-0.3-0.1-0.4-0.3c-0.1-0.2-0.1-0.3,0-0.5c0.3-0.4,0.4-0.8,0.3-1.1c-0.1-0.5-0.7-0.8-1-1
                c0,0,0,0-0.1,0L517,70c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1-0.2-0.2-0.3-0.3l-0.2-0.4c-1.9,0.7-2.7,2.1-3.1,3
                c-0.3,0.5-0.5,0.9-0.9,0.9c0,0,0,0,0,0c-0.4,0-0.6-0.3-0.8-0.7c-0.3-0.9-0.5-1.7-0.6-2.6c-0.2-2.2,0.5-4.4,2.1-6.1
                c1.5-1.6,3.6-2.4,5.8-2.3c1,0.1,2,0.4,2.9,0.9c2.7,1.5,3.6,3.3,3.9,4.6c0.4,1.6-0.1,3-0.6,3.8c-0.1,0.2-0.3,0.4-0.4,0.7
                c-0.1,0.2-0.7,1.7,1.7,3.3c3.3,2.2,4.4,5,3.2,8.3c-1.3,3.5-1.5,5.9-1.5,8.6c0,0.3,0,0.8-0.4,0.8c0,0-0.1,0-0.1,0
                C526.9,92.7,526.5,92,524.3,81.1z M513.4,64.5c-1.4,1.5-2.1,3.4-1.9,5.4c0.1,0.8,0.3,1.6,0.5,2.3c0,0,0,0,0,0
                c0.1-0.1,0.1-0.2,0.2-0.3c0.5-1,1.5-2.8,4-3.5l0.3-0.1l0.3,0.7l1.6-1.1c0.2-0.1,0.4-0.1,0.5,0c0,0,0.1,0.1,0.2,0.1
                c0.4,0.2,1.2,0.7,1.5,1.5c0.1,0.4,0,0.9-0.2,1.4c0.3,0.1,0.7,0.3,0.9,0.7c0.1,0.2,0.2,0.6,0,1.1c-0.4,1-1.1,1.5-1.3,1.6l0,0
                c0,0,0,0,0,0l-0.7,1.2c0,0.1-0.1,0.5,0.1,0.9c0.3,0.6,1,1.2,2.1,1.6c0.1,0,0.1,0.1,0.3,0.1c0.6,0.3,1.2,0.7,1.8,1.1l1.4,1
                l0,0.2c0.5,2.7,1.4,6.6,2,9c0.1-2,0.5-4.1,1.5-6.8c1.1-3,0.2-5.4-2.8-7.3l0,0c-3-2-2.1-4.1-2-4.4c0.1-0.3,0.3-0.5,0.5-0.8
                c0.4-0.6,0.8-1.8,0.5-3.2c-0.3-1.1-1.1-2.7-3.5-4.1c-0.8-0.4-1.7-0.7-2.5-0.8c-0.1,0-0.3,0-0.4,0
                C516.4,62.5,514.7,63.2,513.4,64.5z"
                    />
                  </g>
                  <g id="XMLID_5366_">
                    <path
                      id="XMLID_5368_"
                      d="M519.3,73.5c0.1,0,0.5-0.1,1-0.7l-0.6-0.5c-0.3,0.3-0.5,0.4-0.5,0.4L519.3,73.5z"
                    />
                  </g>
                  <g id="XMLID_5364_">
                    <path
                      id="XMLID_5365_"
                      d="M511.7,71.2c0-0.3,0.7-6.6,6.4-6.2l0-0.8c-6.4-0.4-7.2,6.9-7.2,7L511.7,71.2z"
                    />
                  </g>
                  <g id="XMLID_5361_">
                    <path
                      id="XMLID_5362_"
                      d="M524.2,72.6c0.6-4.8-1.8-6.6-1.9-6.7l-0.5,0.7c0.1,0.1,2.1,1.6,1.6,5.9L524.2,72.6z"
                    />
                  </g>
                  <g id="XMLID_5358_">
                    <path
                      id="XMLID_5359_"
                      d="M525.2,82.3h0.8c0-2.2-1.7-4.3-3.3-5.1c-1.1-0.5-0.9-1.5-0.9-1.5l-0.8-0.1
                c-0.1,0.5,0.1,1.7,1.4,2.4C523.5,78.5,525.2,80.3,525.2,82.3z"
                    />
                  </g>
                </g>
                <g id="XMLID_5350_">
                  <path
                    id="XMLID_5355_"
                    style="fill: #ffffff"
                    d="M523.7,80.8c1.3-0.7,2.7,2.1,3.4,7.6s0,6.1,0.1,11.7c0,3.2-0.1,8.2,0,9.5
              c0.1,1,0.5,1.9,1,2.7l2.1,2.4c0.6,0.8,0.5,1.7-0.3,2l0,0c-1,0.4-2.1,0.1-2.8-0.7l-2-2.2l-0.4,0.3c0,0.5,0.3,1.1,0.5,1.5l0,0
              l0,0c0.1,1.2-0.9,0.6-1.8-1l0,0c-0.2-0.5-0.2-1.1,0-1.6l0.3-0.8c0.5-1.2,0.7-2.6,0.6-3.9c-0.2-2.4-0.4-6.5-0.8-10.2
              C523.1,92.1,520.9,82.4,523.7,80.8z"
                  />
                  <path
                    id="XMLID_5351_"
                    d="M526.9,116.1l-1.6-1.8c0.1,0.3,0.3,0.7,0.4,1l0.1,0.1l0,0.1c0,0.7-0.3,1-0.5,1.1
              c-0.8,0.2-1.6-1.1-2.1-1.9c-0.2-0.6-0.3-1.3,0-1.9l0.3-0.8c0.5-1.2,0.7-2.5,0.6-3.8c-0.2-2.7-0.5-6.6-0.8-10.2
              c-0.1-1.3-0.3-2.8-0.5-4.3c-0.8-5.8-1.6-11.8,0.8-13.1l0,0c0.4-0.2,0.8-0.2,1.1,0c1.3,0.6,2.3,3.6,2.9,7.9
              c0.4,3.4,0.3,4.9,0.2,6.9c-0.1,1.2-0.1,2.6-0.1,4.8c0,1.4,0,3.2,0,4.9c0,2,0,3.9,0,4.6c0.1,0.8,0.4,1.7,0.9,2.4l2.1,2.4
              c0.4,0.6,0.6,1.2,0.4,1.7c-0.1,0.3-0.3,0.7-0.9,0.9c-0.3,0.1-0.7,0.2-1,0.2C528.3,117.2,527.5,116.8,526.9,116.1z M523.9,81.2
              c-1.9,1.1-1.1,7.3-0.4,12.3c0.2,1.6,0.4,3.1,0.5,4.4c0.3,3.6,0.6,7.6,0.8,10.2c0.1,1.4-0.1,2.8-0.6,4.1l-0.3,0.8
              c-0.2,0.4-0.2,0.9,0,1.2c0.4,0.8,0.8,1.2,1,1.4c0,0,0,0,0,0c-0.3-0.4-0.5-1.1-0.5-1.6v-0.2l0.9-0.7l2.3,2.5
              c0.6,0.7,1.5,0.9,2.3,0.6c0.2-0.1,0.4-0.2,0.4-0.4c0.1-0.3,0-0.6-0.3-0.9l-2.1-2.4c-0.6-0.9-1-1.9-1.1-2.9
              c-0.1-0.8,0-2.6,0-4.7c0-1.7,0-3.5,0-4.9c0-2.2,0-3.6,0.1-4.8c0.1-1.9,0.2-3.5-0.2-6.8c-0.6-4.5-1.6-6.9-2.4-7.3
              c-0.1,0-0.1,0-0.2,0C524,81.1,523.9,81.1,523.9,81.2z"
                  />
                </g>
                <g id="XMLID_5347_">
                  <path
                    id="XMLID_5348_"
                    style="fill: #ffffff"
                    d="M505,147.1c0-1.8-0.3-2.3-0.5-2.4l-0.6,0.6l0.3-0.3l-0.3,0.3
              c0,0,0.3,0.4,0.3,1.9L505,147.1z"
                  />
                </g>
                <g id="XMLID_5344_">
                  <path
                    id="XMLID_5345_"
                    style="fill: #ffffff"
                    d="M506.7,145c0.3-3.1,0-5.1,0-5.2l-0.8,0.1c0,0,0.3,2,0,5L506.7,145z"
                  />
                </g>
                <g id="XMLID_5341_">
                  <path
                    id="XMLID_5343_"
                    style="fill: #ffffff"
                    d="M499.5,149.5c0.3,0,0.7-0.1,1.2-0.3l-0.3-0.7c-0.9,0.4-1.5,0-1.6,0l-0.5,0.7
              C498.3,149.2,498.8,149.5,499.5,149.5z"
                  />
                </g>
                <g id="XMLID_5338_">
                  <path
                    id="XMLID_5340_"
                    style="fill: #ffffff"
                    d="M495.3,147.6c0.3,0,0.7-0.1,1.2-0.3l-0.3-0.7c-0.9,0.4-1.5,0-1.6,0l-0.5,0.7
              C494.2,147.3,494.6,147.6,495.3,147.6z"
                  />
                </g>
                <g id="XMLID_5336_">
                  <rect
                    id="XMLID_5337_"
                    x="512.1"
                    y="89.5"
                    transform="matrix(0.3982 -0.9173 0.9173 0.3982 225.7272 524.4034)"
                    style="fill: #ffffff"
                    width="0.8"
                    height="1.4"
                  />
                </g>
                <g id="XMLID_5333_">
                  <path
                    id="XMLID_5334_"
                    style="fill: #ffffff"
                    d="M518.9,90.6c0.1,0,2.1-0.4,2.7-2.4l-0.8-0.2c-0.4,1.5-2,1.8-2,1.9L518.9,90.6
              z"
                  />
                </g>
                <g id="XMLID_5330_">
                  <path
                    id="XMLID_5331_"
                    style="fill: #ffffff"
                    d="M520,103.1c0,0,0.2-1.2,0-4.4l-0.8,0.1c0.3,3.1,0,4.1,0,4.2L520,103.1z"
                  />
                </g>
                <g id="XMLID_5327_">
                  <rect
                    id="XMLID_5329_"
                    x="519"
                    y="96.4"
                    transform="matrix(0.9957 -9.237923e-02 9.237923e-02 0.9957 -6.7564 48.4024)"
                    style="fill: #ffffff"
                    width="0.8"
                    height="1.5"
                  />
                </g>
                <g id="XMLID_5325_">
                  <path
                    id="XMLID_5326_"
                    d="M526.9,85.3c2.1-5.5,0.1-6.6-0.1-6.7l-0.4,0.7l0.2-0.4l-0.2,0.4c0,0,1.5,0.9-0.3,5.7L526.9,85.3z"
                  />
                </g>
                <g id="XMLID_5323_">
                  <path
                    id="XMLID_5324_"
                    d="M509.5,78.5l0.4-0.7c-2.1-1.1-0.6-4.8-0.6-4.9l-0.8-0.3C508.5,72.8,506.7,77.1,509.5,78.5z"
                  />
                </g>
                <g id="XMLID_5321_">
                  <path
                    id="XMLID_5322_"
                    d="M526.3,76.1l0.7-0.4c-1-1.9,0.3-3.6,0.3-3.6l-0.7-0.5C526.6,71.7,525.1,73.8,526.3,76.1z"
                  />
                </g>
                <g id="XMLID_5316_">
                  <path
                    id="XMLID_5320_"
                    style="fill: #ffffff"
                    d="M520,69.4l2.7-1.6c0.5-0.3,0.6-0.9,0.2-1.3l0,0c-0.3-0.4-1-0.4-1.3,0
              l-2.1,2.4L520,69.4z"
                  />
                  <path
                    id="XMLID_5317_"
                    d="M519,68.9l2.3-2.6c0.2-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.2,1,0.5c0.2,0.3,0.3,0.7,0.3,1
              c-0.1,0.4-0.3,0.7-0.6,0.9l-3,1.8L519,68.9z M521.9,66.8l-1.8,2.1l0,0l2.4-1.4c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.3-0.1-0.4
              c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0C522.1,66.6,522,66.7,521.9,66.8z"
                  />
                </g>
                <g id="XMLID_5311_">
                  <path
                    id="XMLID_5315_"
                    style="fill: #ffffff"
                    d="M510,86.3c0,1,0.6,1.8,1.2,1.8c0.7,0,1.2-0.8,1.2-1.8c0-1-0.6-1.8-1.2-1.8
              C510.6,84.5,510,85.3,510,86.3z"
                  />
                  <path
                    id="XMLID_5312_"
                    d="M509.6,86.3c0-1.2,0.7-2.2,1.7-2.2c0.9,0,1.7,1,1.7,2.2c0,1.2-0.7,2.2-1.7,2.2
              C510.3,88.5,509.6,87.5,509.6,86.3z M510.4,86.3c0,0.7,0.4,1.4,0.8,1.4c0.4,0,0.8-0.6,0.8-1.4c0-0.7-0.4-1.4-0.8-1.4
              C510.8,84.9,510.4,85.6,510.4,86.3z"
                  />
                </g>
                <g id="XMLID_5306_">
                  <path
                    id="XMLID_5310_"
                    style="fill: #ffffff"
                    d="M509.6,84.5c0,0.8,0.5,1.5,1,1.5c0.6,0,1-0.7,1-1.5s-0.5-1.5-1-1.5
              C510.1,83,509.6,83.7,509.6,84.5z"
                  />
                  <path
                    id="XMLID_5307_"
                    d="M509.2,84.5c0-1.1,0.6-1.9,1.4-1.9c0.8,0,1.4,0.8,1.4,1.9c0,1.1-0.6,1.9-1.4,1.9
              C509.9,86.4,509.2,85.6,509.2,84.5z M510,84.5c0,0.6,0.3,1.1,0.6,1.1c0.3,0,0.6-0.4,0.6-1.1s-0.3-1.1-0.6-1.1
              C510.4,83.4,510,83.9,510,84.5z"
                  />
                </g>
                <g id="XMLID_5304_">
                  <path
                    id="XMLID_5305_"
                    d="M502,118.4l0.8,0c-0.1-2.2,0.9-3,0.9-3l-0.5-0.7C503.1,114.8,501.9,115.7,502,118.4z"
                  />
                </g>
                <g id="XMLID_5302_">
                  <path
                    id="XMLID_5303_"
                    d="M497.4,116.3l0.8,0c-0.1-2.2,0.9-3,0.9-3l-0.5-0.7C498.6,112.7,497.3,113.6,497.4,116.3z"
                  />
                </g>
                <g id="XMLID_5276_">
                  <g id="XMLID_5285_">
                    <g id="XMLID_5296_">
                      <path
                        id="XMLID_5301_"
                        style="fill: #ffffff"
                        d="M496.5,103.7l14.8,8.6c0,0,0.1,0,0.1,0l10.2-6.1c0.1,0,0.1-0.1,0-0.2
                  l-14.7-8.7c0,0-0.1,0-0.1,0l-10.3,6.2C496.4,103.5,496.4,103.6,496.5,103.7z"
                      />
                      <path
                        id="XMLID_5297_"
                        d="M511.1,112.6l-14.8-8.6c-0.2-0.1-0.3-0.3-0.3-0.4c0-0.2,0.1-0.3,0.2-0.4l10.3-6.2
                  c0.2-0.1,0.4-0.1,0.5,0l14.7,8.7c0.2,0.1,0.3,0.3,0.3,0.4c0,0.2-0.1,0.3-0.2,0.4l-10.2,6.1c-0.1,0-0.2,0.1-0.3,0.1
                  C511.3,112.6,511.2,112.6,511.1,112.6z M506.8,97.8l-9.7,5.8l14.2,8.2l9.6-5.7L506.8,97.8z"
                      />
                    </g>
                    <g id="XMLID_5289_">
                      <path
                        id="XMLID_5295_"
                        style="fill: #ffffff"
                        d="M511.3,112.1l-5-11.5c0,0,0,0,0,0l-15.2-9c0,0-0.1,0-0.1,0.1l5.4,12
                  c0,0,0,0,0,0L511.3,112.1C511.3,112.2,511.3,112.2,511.3,112.1z"
                      />
                      <path
                        id="XMLID_5290_"
                        d="M496.1,103.9l-0.1-0.1l-5.4-12.1c-0.1-0.2,0-0.4,0.1-0.5L491,91l15.5,9.2l0.1,0.1l5.1,11.6
                  c0.1,0.2,0,0.4-0.1,0.5l-0.3,0.2L496.1,103.9z M491.8,92.5l4.9,10.8l13.8,8l-4.5-10.4L491.8,92.5z"
                      />
                    </g>
                    <g id="XMLID_5286_">
                      <path
                        id="XMLID_5288_"
                        d="M512.4,109.6l5.3-3.2c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5l-3.4-2l-0.4,0.7l3.1,1.8
                  l-4.9,2.9L512.4,109.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_5278_">
                    <path
                      id="XMLID_5283_"
                      style="fill: #ffffff"
                      d="M500.3,102.6c0.6,0.8,1.5,1.2,1.8,0.9c0.4-0.3,0.1-1.2-0.5-2
                c-0.6-0.8-1.5-1.2-1.8-0.9S499.6,101.8,500.3,102.6z"
                    />
                    <path
                      id="XMLID_5279_"
                      d="M501.1,103.9c-0.4-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.6-0.9-0.7-1.3c-0.2-0.7,0.1-1.1,0.3-1.2
                c0.1-0.1,0.3-0.2,0.7-0.2c0.5,0.1,1.2,0.5,1.7,1.2c0.3,0.4,0.6,0.9,0.7,1.3c0.2,0.7-0.1,1.1-0.3,1.2
                c-0.1,0.1-0.3,0.2-0.5,0.2C501.6,104.1,501.4,104,501.1,103.9z M500,101c-0.1,0.1,0,0.7,0.6,1.4l0,0c0.6,0.7,1.1,0.9,1.2,0.9
                c0.1-0.1,0-0.7-0.6-1.4C500.8,101.2,500.3,101,500,101C500.1,101,500.1,101,500,101z"
                    />
                  </g>
                </g>
                <g id="XMLID_5274_">
                  <path
                    id="XMLID_5275_"
                    d="M515,74.4c0.1,0,0.2,0,0.3,0l-0.2-0.6c-0.1,0-0.3,0-0.5,0l0.6-1.4l-0.6-0.3l-0.9,2l0.2,0.1
              C514.1,74.2,514.5,74.4,515,74.4z"
                  />
                </g>
                <g id="XMLID_5271_">
                  <path
                    id="XMLID_5272_"
                    d="M514.9,76c0.5,0,0.8-0.2,0.8-0.2l-0.4-0.5l0.2,0.3l-0.2-0.3c0,0-0.3,0.2-0.7,0.1l-0.2,0.6
              C514.6,76,514.8,76,514.9,76z"
                  />
                </g>
                <g id="XMLID_5268_">
                  <path
                    id="XMLID_5269_"
                    d="M517.8,72.5l0.2-0.6c-0.1,0-1-0.3-1.6-0.2l0.2,0.6C516.9,72.2,517.5,72.4,517.8,72.5z"
                  />
                </g>
                <g id="XMLID_5265_">
                  <path
                    id="XMLID_5267_"
                    d="M513.8,71.8l0.3-0.5c0,0-0.5-0.3-1-0.4l-0.1,0.6C513.4,71.6,513.8,71.8,513.8,71.8z"
                  />
                </g>
                <g id="XMLID_5262_">
                  <path
                    id="XMLID_5264_"
                    d="M516,72.3c-0.1,0.4,0.1,0.7,0.3,0.8c0.2,0,0.5-0.3,0.5-0.6c0.1-0.4-0.1-0.7-0.3-0.8
              C516.3,71.6,516.1,71.9,516,72.3z"
                  />
                </g>
                <g id="XMLID_5260_">
                  <path
                    id="XMLID_5261_"
                    d="M513.4,71.8c-0.1,0.4,0.1,0.7,0.3,0.8c0.2,0,0.5-0.3,0.5-0.6c0.1-0.4-0.1-0.7-0.3-0.8
              C513.7,71.1,513.5,71.4,513.4,71.8z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="transverses"
          @mouseover="hilight('transverse', true)"
          @mouseleave="hilight('transverse', false)"
          @click="activate('transverse')">
        <g id="transverses-socle">
          <path
            id="XMLID_15622_"
            style="fill: #e5ecf4"
            :class="{ active: active_transverse, hilighted: hilighted_transverse }"
            d="M503.1,284.5L647,366.8c5.9,3.4,13.1,3.4,18.9,0l135.2-79.2
      c4.9-2.8,4.9-9.9,0-12.8l-143.9-82.3c-5.9-3.4-13.1-3.4-18.9,0l-135.2,79.2C498.2,274.5,498.2,281.6,503.1,284.5z"
          />
        </g>
        <g id="transverses-illus">
          <g id="XMLID_12529_">
            <g id="XMLID_12980_">
              <g id="XMLID_12981_">
                <g id="XMLID_13028_">
                  <path
                    id="XMLID_13029_"
                    style="fill: #ffffff"
                    d="M742.6,285.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C742.8,285,742.8,285.2,742.6,285.3z"
                  />
                </g>
                <g id="XMLID_13026_">
                  <path
                    id="XMLID_13027_"
                    style="fill: #ffffff"
                    d="M746.7,287.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C746.8,287.4,746.8,287.5,746.7,287.6z"
                  />
                </g>
                <g id="XMLID_13024_">
                  <path
                    id="XMLID_13025_"
                    style="fill: #ffffff"
                    d="M742.4,290.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C742.6,289.8,742.6,290,742.4,290.1z"
                  />
                </g>
                <g id="XMLID_13022_">
                  <path
                    id="XMLID_13023_"
                    style="fill: #ffffff"
                    d="M750.9,290.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C751.1,289.8,751.1,290,750.9,290.1z"
                  />
                </g>
                <g id="XMLID_13020_">
                  <path
                    id="XMLID_13021_"
                    style="fill: #ffffff"
                    d="M746.7,292.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C746.8,292.3,746.8,292.4,746.7,292.5z"
                  />
                </g>
                <g id="XMLID_13018_">
                  <path
                    id="XMLID_13019_"
                    style="fill: #ffffff"
                    d="M754.9,292.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C755.1,292.1,755.1,292.3,754.9,292.4z"
                  />
                </g>
                <g id="XMLID_13016_">
                  <path
                    id="XMLID_13017_"
                    style="fill: #ffffff"
                    d="M750.7,294.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C750.8,294.6,750.9,294.8,750.7,294.9z"
                  />
                </g>
                <g id="XMLID_13014_">
                  <path
                    id="XMLID_13015_"
                    style="fill: #ffffff"
                    d="M758.9,294.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C759.1,294.5,759.1,294.6,758.9,294.7z"
                  />
                </g>
                <g id="XMLID_13012_">
                  <path
                    id="XMLID_13013_"
                    style="fill: #ffffff"
                    d="M754.7,297.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C754.9,296.9,754.9,297.1,754.7,297.2z"
                  />
                </g>
                <g id="XMLID_13010_">
                  <path
                    id="XMLID_13011_"
                    style="fill: #ffffff"
                    d="M763.2,297.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C763.4,296.9,763.4,297.1,763.2,297.2z"
                  />
                </g>
                <g id="XMLID_13008_">
                  <path
                    id="XMLID_13009_"
                    style="fill: #ffffff"
                    d="M759,299.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C759.1,299.4,759.1,299.5,759,299.6z"
                  />
                </g>
                <g id="XMLID_13006_">
                  <path
                    id="XMLID_13007_"
                    style="fill: #ffffff"
                    d="M767.4,294.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C767.6,294.5,767.6,294.6,767.4,294.7z"
                  />
                </g>
                <g id="XMLID_13004_">
                  <path
                    id="XMLID_13005_"
                    style="fill: #ffffff"
                    d="M771.7,292.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C771.8,292,771.8,292.2,771.7,292.3z"
                  />
                </g>
                <g id="XMLID_13002_">
                  <path
                    id="XMLID_13003_"
                    style="fill: #ffffff"
                    d="M775.9,289.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C776.1,289.6,776.1,289.8,775.9,289.9z"
                  />
                </g>
                <g id="XMLID_13000_">
                  <path
                    id="XMLID_13001_"
                    style="fill: #ffffff"
                    d="M780.1,287.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C780.3,287.1,780.3,287.3,780.1,287.4z"
                  />
                </g>
                <g id="XMLID_12998_">
                  <path
                    id="XMLID_12999_"
                    style="fill: #ffffff"
                    d="M784.4,285l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C784.5,284.7,784.6,284.9,784.4,285z"
                  />
                </g>
                <g id="XMLID_12996_">
                  <path
                    id="XMLID_12997_"
                    style="fill: #ffffff"
                    d="M767.2,299.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C767.4,299.2,767.4,299.4,767.2,299.5z"
                  />
                </g>
                <g id="XMLID_12994_">
                  <path
                    id="XMLID_12995_"
                    style="fill: #ffffff"
                    d="M771.5,297.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C771.6,296.8,771.6,297,771.5,297.1z"
                  />
                </g>
                <g id="XMLID_12992_">
                  <path
                    id="XMLID_12993_"
                    style="fill: #ffffff"
                    d="M775.7,294.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C775.8,294.3,775.9,294.5,775.7,294.6z"
                  />
                </g>
                <g id="XMLID_12990_">
                  <path
                    id="XMLID_12991_"
                    style="fill: #ffffff"
                    d="M779.9,292.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C780.1,291.9,780.1,292.1,779.9,292.2z"
                  />
                </g>
                <g id="XMLID_12988_">
                  <path
                    id="XMLID_12989_"
                    style="fill: #ffffff"
                    d="M784.2,289.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C784.3,289.5,784.3,289.6,784.2,289.7z"
                  />
                </g>
                <g id="XMLID_12986_">
                  <path
                    id="XMLID_12987_"
                    style="fill: #ffffff"
                    d="M781.3,269.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C781.4,269.2,781.5,269.4,781.3,269.5z"
                  />
                </g>
                <g id="XMLID_12984_">
                  <path
                    id="XMLID_12985_"
                    style="fill: #ffffff"
                    d="M785.5,267l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C785.7,266.8,785.7,266.9,785.5,267z"
                  />
                </g>
                <g id="XMLID_12982_">
                  <path
                    id="XMLID_12983_"
                    style="fill: #ffffff"
                    d="M785.3,271.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C785.5,271.5,785.5,271.7,785.3,271.8z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_12530_">
              <g id="XMLID_12808_">
                <g id="XMLID_12948_">
                  <g id="XMLID_12949_">
                    <g id="XMLID_12957_">
                      <g id="XMLID_12973_">
                        <g id="XMLID_12978_">
                          <path
                            id="XMLID_12979_"
                            style="fill: #ffffff"
                            d="M760.2,291.9l-20.6-12c-0.2-0.1-0.3-0.3-0.3-0.5l0,0l0,0c0,0,0,0,0,0
                      l0-4.1c0-0.1,0.1-0.2,0.2-0.2l13.9-4.2c0,0,0.1,0,0.1,0l6.5-3.7c0.5-0.3,1.3-0.3,1.7-0.1l14,8.2c0,0,0.1,0,0.1,0l6.6-0.1
                      c0.1,0,0.2,0.1,0.2,0.2l0,4.1c0,0.2-0.2,0.4-0.4,0.5l-20.3,11.7C761.4,292.1,760.7,292.1,760.2,291.9z"
                          />
                        </g>
                        <g id="XMLID_12974_">
                          <path
                            id="XMLID_12975_"
                            d="M761,292.5c-0.3,0-0.7-0.1-1-0.2l0,0l-20.6-12c-0.3-0.2-0.5-0.5-0.5-0.8l0-4.1
                      c0-0.3,0.2-0.5,0.5-0.6l13.9-4.2l6.5-3.7c0.6-0.4,1.6-0.4,2.1-0.1l14,8.2l6.6-0.1c0.2,0,0.3,0.1,0.4,0.2
                      c0.1,0.1,0.2,0.3,0.2,0.4l0,4.1c0,0.4-0.2,0.7-0.6,0.9l-20.3,11.7C761.8,292.4,761.4,292.5,761,292.5z M760.4,291.5
                      c0.3,0.2,0.9,0.1,1.3-0.1l20.3-11.7c0.2-0.1,0.2-0.2,0.2-0.2l0-3.8l-6.4,0.1c-0.1,0-0.2,0-0.3-0.1l-14-8.2
                      c-0.3-0.2-0.9-0.1-1.3,0.1l-6.5,3.7c-0.1,0-0.1,0.1-0.2,0.1l-13.7,4.2l0,3.9c0,0.1,0,0.1,0.1,0.1L760.4,291.5z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_12966_">
                        <g id="XMLID_12971_">
                          <path
                            id="XMLID_12972_"
                            d="M776.6,275.3c0,0,5.8,0,5.8,0c0.1,0,0.2,0.1,0.2,0.2l0,4c0,0.2-0.2,0.4-0.4,0.5L762,291.8
                      c0,0-0.1,0-0.1,0l0-4.2c0,0,0,0,0,0l0,0C761.5,286.1,776.6,275.3,776.6,275.3z"
                          />
                        </g>
                        <g id="XMLID_12967_">
                          <path
                            id="XMLID_12968_"
                            d="M762,292.2l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-4.2c-0.1-0.5,0.1-1.4,7.3-7
                      c3.7-2.8,7.5-5.5,7.5-5.6l0.1-0.1c0.1,0,0.1,0,6,0c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5l0,4
                      c0,0.4-0.2,0.7-0.6,0.9L762,292.2z M762.4,287.6l0,3.5l19.7-11.3c0.2-0.1,0.2-0.2,0.2-0.2l0-3.8c-1.5,0-4.8,0-5.5,0
                      c-6.9,4.9-14,10.5-14.4,11.6L762.4,287.6L762.4,287.6z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_12958_">
                        <g id="XMLID_12964_">
                          <path
                            id="XMLID_12965_"
                            style="fill: #ffffff"
                            d="M782.4,274.8l-20.6-12c-0.4-0.3-1.2-0.2-1.7,0.1l-20.3,11.7
                      c-0.5,0.3-0.6,0.7-0.1,1l20.6,12c0.4,0.3,1.2,0.2,1.7-0.1l20.3-11.7C782.8,275.5,782.9,275.1,782.4,274.8z"
                          />
                        </g>
                        <g id="XMLID_12959_">
                          <path
                            id="XMLID_12961_"
                            d="M761,288.1c-0.3,0-0.7-0.1-1-0.2l-20.6-12c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.2-0.7,0.6-0.9
                      l20.3-11.7c0.6-0.4,1.6-0.4,2.1-0.1l20.6,12c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.2,0.7-0.6,0.9l-20.3,11.7
                      C761.8,288,761.4,288.1,761,288.1z M761.1,263.1c-0.3,0-0.6,0.1-0.8,0.2L740,274.9c-0.2,0.1-0.2,0.2-0.2,0.2
                      c0,0,0,0,0.1,0.1l20.6,12c0.3,0.2,0.9,0.1,1.3-0.1l20.3-11.7c0.2-0.1,0.2-0.2,0.2-0.2c0,0,0,0-0.1-0.1l0,0l-20.6-12
                      C761.5,263.1,761.3,263.1,761.1,263.1z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_12954_">
                      <g id="XMLID_12955_">
                        <rect
                          id="XMLID_12956_"
                          x="740"
                          y="278.8"
                          transform="matrix(3.011408e-02 -0.9995 0.9995 3.011408e-02 440.7079 1012.5846)"
                          width="4.2"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_12951_">
                      <g id="XMLID_12952_">
                        <rect
                          id="XMLID_12953_"
                          x="777.1"
                          y="279.6"
                          transform="matrix(1.471350e-02 -0.9999 0.9999 1.471350e-02 487.576 1054.7174)"
                          style="fill: #ffffff"
                          width="3.7"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_12912_">
                  <g id="XMLID_12917_">
                    <g id="XMLID_12938_">
                      <g id="XMLID_12945_">
                        <path
                          id="XMLID_12947_"
                          style="fill: #ffffff"
                          d="M752.8,285.3l-2.9-1.7c-0.1-0.1-0.2-0.1-0.3,0l-3.6,2.2
                    c-0.1,0-0.1,0.1,0,0.2l3.4,3.7c0,0,0,0,0,0.1v1.2c0,0.3,1.4-0.7,1.7-0.5l0,0c0,0,0,0,0.1,0.1l-1.8,1.1c0,0.1,0.1,0.1,0.2,0
                    l3.6-2.2c0,0,0.2-0.2,0.2-0.4v-2.8C753.3,285.9,753.1,285.5,752.8,285.3z"
                        />
                      </g>
                      <g id="XMLID_12940_">
                        <path
                          id="XMLID_12941_"
                          d="M749.5,292.1c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.3l-0.2-0.4l0.3-0.2
                    c-0.1-0.1-0.1-0.2-0.1-0.3v-1.1l-3.4-3.7c-0.2-0.3-0.1-0.6,0.2-0.8l3.6-2.2c0.2-0.1,0.5-0.1,0.7,0l2.9,1.7
                    c0.4,0.2,0.7,0.8,0.7,1.3v2.8c0,0.3-0.2,0.6-0.2,0.6l-0.1,0.1l-3.6,2.2C749.7,292,749.6,292.1,749.5,292.1z M746.5,286
                    l3.3,3.5c0.1,0.1,0.1,0.2,0.1,0.3v0.7c0.1,0,0.1-0.1,0.2-0.1c0.5-0.3,0.9-0.5,1.3-0.3l1.6-1c0,0,0-0.1,0-0.1l0-2.8
                    c0-0.2-0.1-0.5-0.3-0.5l0,0l-2.9-1.7L746.5,286z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_12928_">
                      <g id="XMLID_12936_">
                        <path
                          id="XMLID_12937_"
                          d="M746.8,289.2c0,0,0,0.1,0,0.2l2.5,2.1c0.1,0.1,0.2,0.2,0.3,0.1l3.5-2.2c0,0,0.2-0.2,0.2-0.4
                    v-2.6c0-0.1-0.1-0.1-0.1-0.1l-3.5,2.2c0,0,0,0,0,0L746.8,289.2z"
                        />
                      </g>
                      <g id="XMLID_12930_">
                        <path
                          id="XMLID_12931_"
                          d="M749.5,292c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.4-0.2l-2.6-2.2l0.1-0.7l2.9-0.7l3.5-2.2
                    c0.2-0.1,0.4-0.1,0.5,0c0.2,0.1,0.3,0.3,0.3,0.4v2.6c0,0.3-0.2,0.6-0.2,0.6l-0.1,0.1l-3.5,2.2
                    C749.8,292,749.7,292,749.5,292z M747.5,289.4l2,1.7l3.3-2.1c0,0,0-0.1,0-0.1V287l-3,1.9l-0.1,0.1L747.5,289.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_12919_">
                      <g id="XMLID_12926_">
                        <path
                          id="XMLID_12927_"
                          style="fill: #ffffff"
                          d="M749.1,291.7l-2.9-1.7c-0.3-0.2-0.5-0.6-0.5-0.9v-2.8
                    c0-0.3,0.2-0.5,0.5-0.4l2.9,1.7c0.3,0.2,0.5,0.6,0.5,0.9v2.8C749.6,291.7,749.4,291.9,749.1,291.7z"
                        />
                      </g>
                      <g id="XMLID_12920_">
                        <path
                          id="XMLID_12922_"
                          d="M749.3,292.2c-0.1,0-0.3,0-0.4-0.1l0,0l-2.9-1.7c-0.4-0.2-0.7-0.8-0.7-1.3v-2.8
                    c0-0.3,0.1-0.6,0.4-0.7c0.2-0.1,0.5-0.1,0.7,0l2.9,1.7c0.4,0.2,0.7,0.8,0.7,1.3v2.8c0,0.3-0.1,0.6-0.4,0.7
                    C749.5,292.1,749.4,292.2,749.3,292.2z M746.1,286.3v2.7c0,0.2,0.1,0.5,0.3,0.5l2.8,1.6v-2.7c0-0.2-0.1-0.5-0.3-0.5
                    L746.1,286.3z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_12913_">
                    <g id="XMLID_12915_">
                      <rect
                        id="XMLID_12916_"
                        x="749.6"
                        y="288.7"
                        transform="matrix(0.8567 -0.5157 0.5157 0.8567 -41.424 428.9684)"
                        style="fill: #ffffff"
                        width="3.7"
                        height="0.6"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_12809_">
                  <g id="XMLID_12811_">
                    <g id="XMLID_12820_">
                      <g id="XMLID_12902_">
                        <g id="XMLID_12909_">
                          <path
                            id="XMLID_12911_"
                            style="fill: #ffffff"
                            d="M763.5,273.4l-13.2-7.7c-0.2-0.1-0.3-0.3-0.3-0.5l0,0l0,0c0,0,0,0,0,0
                      l0-4.1c0-0.1,0.1-0.2,0.2-0.2l6.7-0.1c0,0,0.1,0,0.1,0l6.5-3.7c0.5-0.3,1.3-0.3,1.7-0.1l6.7,3.9c0,0,0.1,0,0.1,0l6.6-0.1
                      c0.1,0,0.2,0.1,0.2,0.2l0,4.1c0,0.2-0.2,0.4-0.4,0.5l-13.1,7.5C764.7,273.6,764,273.6,763.5,273.4z"
                          />
                        </g>
                        <g id="XMLID_12903_">
                          <path
                            id="XMLID_12905_"
                            d="M764.3,274c-0.3,0-0.7-0.1-1-0.2l0,0l-13.2-7.7c-0.3-0.2-0.5-0.5-0.5-0.8l0,0h0l0-4.1
                      c0-0.3,0.3-0.6,0.6-0.6l6.6-0.1l6.5-3.7c0.6-0.4,1.6-0.4,2.1-0.1l6.6,3.9l6.6-0.1c0,0,0,0,0,0c0.2,0,0.3,0.1,0.4,0.2
                      c0.1,0.1,0.2,0.3,0.2,0.4l0,4.1c0,0.4-0.2,0.7-0.6,0.9l-13.1,7.5C765.1,273.9,764.7,274,764.3,274z M763.7,273
                      c0.3,0.2,0.9,0.1,1.3-0.1l13.1-7.5c0.2-0.1,0.2-0.2,0.2-0.2l0-3.8l-6.4,0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1l-6.7-3.9
                      c-0.3-0.2-0.9-0.1-1.3,0.1l-6.5,3.7c-0.1,0-0.2,0.1-0.3,0.1l-6.4,0.1l0,3.9c0,0,0,0.1,0.1,0.1L763.7,273z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_12831_">
                        <g id="XMLID_12838_">
                          <path
                            id="XMLID_12901_"
                            d="M772.7,261c0,0,5.8,0,5.8,0c0.1,0,0.2,0.1,0.2,0.2l0,4c0,0.2-0.2,0.4-0.4,0.5l-13,7.5
                      c0,0-0.1,0-0.1,0l0-4.2c0,0,0,0,0,0l0,0C764.8,267.6,772.7,261,772.7,261z"
                          />
                        </g>
                        <g id="XMLID_12833_">
                          <path
                            id="XMLID_12834_"
                            d="M765.3,273.7l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-4.2c-0.2-1.5,4.6-5.8,7.6-8.3l0.1-0.1
                      c0.1,0,0.1,0,6,0c0.1,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5l0,4c0,0.4-0.2,0.7-0.6,0.9L765.3,273.7z M765.7,269.1
                      l0,3.5l12.5-7.2c0.2-0.1,0.2-0.2,0.2-0.2l0-3.8c-1.5,0-4.8,0-5.5,0c-3.6,3-7.3,6.6-7.2,7.4L765.7,269.1L765.7,269.1z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_12822_">
                        <g id="XMLID_12829_">
                          <path
                            id="XMLID_12830_"
                            style="fill: #ffffff"
                            d="M778.5,260.5l-13.2-7.7c-0.4-0.3-1.2-0.2-1.7,0.1l-13.1,7.5
                      c-0.5,0.3-0.6,0.7-0.1,1l13.2,7.7c0.4,0.3,1.2,0.2,1.7-0.1l13.1-7.5C778.9,261.2,778.9,260.7,778.5,260.5z"
                          />
                        </g>
                        <g id="XMLID_12823_">
                          <path
                            id="XMLID_12825_"
                            d="M764.3,269.6c-0.3,0-0.7-0.1-1-0.2l-13.3-7.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.2-0.7,0.6-0.9
                      l13.1-7.5c0.6-0.4,1.6-0.4,2.1-0.1l13.2,7.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.2,0.7-0.6,0.9l-13.1,7.5
                      C765.1,269.5,764.7,269.6,764.3,269.6z M764.5,253c-0.3,0-0.6,0.1-0.8,0.2l-13.1,7.5c-0.2,0.1-0.2,0.2-0.2,0.2
                      c0,0,0,0,0.1,0.1l13.3,7.7c0.3,0.2,0.9,0.1,1.3-0.1l13.1-7.5c0.2-0.1,0.2-0.2,0.2-0.2c0,0,0,0-0.1-0.1l0,0l-13.2-7.7
                      C764.9,253.1,764.7,253,764.5,253z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_12816_">
                      <g id="XMLID_12817_">
                        <rect
                          id="XMLID_12819_"
                          x="750.7"
                          y="264.6"
                          transform="matrix(3.012133e-02 -0.9995 0.9995 3.012133e-02 465.2379 1009.453)"
                          width="4.2"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_12812_">
                      <g id="XMLID_12813_">
                        <rect
                          id="XMLID_12815_"
                          x="773.2"
                          y="265.3"
                          transform="matrix(1.471350e-02 -0.9999 0.9999 1.471350e-02 498.0585 1036.6628)"
                          style="fill: #ffffff"
                          width="3.7"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_12532_">
                <g id="XMLID_12572_">
                  <g id="XMLID_12798_">
                    <g id="XMLID_12805_">
                      <path
                        id="XMLID_12806_"
                        style="fill: #ffffff"
                        d="M768.6,257.6l-7.2-0.1l0.9,4.3c0,0,0.3,0.6,0.8,0.9c1.1,0.6,2.8,0.6,3.9,0
                  c0.4-0.3,0.8-0.9,0.8-0.9L768.6,257.6z"
                      />
                    </g>
                    <g id="XMLID_12800_">
                      <path
                        id="XMLID_12801_"
                        d="M765,263.5c-0.8,0-1.6-0.2-2.2-0.5c-0.5-0.3-0.9-1-0.9-1.1l0-0.1l-1.1-4.8l8.2,0.1l-1,4.8
                  c0,0.1-0.4,0.8-0.9,1.1C766.6,263.3,765.8,263.5,765,263.5z M762.7,261.6c0.1,0.2,0.3,0.5,0.6,0.7c0.9,0.5,2.5,0.5,3.5,0
                  c0.2-0.1,0.5-0.5,0.6-0.7l0.7-3.6l-6.2-0.1L762.7,261.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_12789_">
                    <g id="XMLID_12795_">
                      <path
                        id="XMLID_12797_"
                        style="fill: #ffffff"
                        d="M769.3,256.1l0-2.2c0-0.1-0.1-0.1-0.1-0.1H766c-0.7-0.1-1.4-0.1-2,0h-3.1
                  c-0.1,0-0.1,0.1-0.1,0.1l0,2.2c0,0.9,0.8,1.8,2.4,2.3c1.2,0.4,2.6,0.4,3.8,0C768.5,257.9,769.3,257,769.3,256.1z"
                      />
                    </g>
                    <g id="XMLID_12790_">
                      <path
                        id="XMLID_12791_"
                        d="M765,259.1c-0.7,0-1.4-0.1-2-0.3c-1.7-0.5-2.7-1.5-2.7-2.7l0-2.2c0-0.3,0.2-0.5,0.5-0.5h3.1
                  c0.6-0.1,1.4-0.1,2.1,0l3.1,0c0.3,0,0.5,0.2,0.5,0.5l0,2.2c0,1.2-1,2.2-2.7,2.7C766.4,259,765.7,259.1,765,259.1z
                   M761.2,254.2l0,1.9c0,0.8,0.8,1.5,2.1,1.9c1.1,0.3,2.4,0.3,3.6,0c1.3-0.4,2.1-1.1,2.1-1.9l0,0l0-1.9H766
                  c-0.7-0.1-1.4-0.1-2,0L761.2,254.2z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_12635_">
                    <g id="XMLID_12786_">
                      <ellipse
                        id="XMLID_12787_"
                        style="fill: #ffffff"
                        cx="765"
                        cy="253.7"
                        rx="4.3"
                        ry="2.5"
                      />
                    </g>
                    <g id="XMLID_12780_">
                      <path
                        id="XMLID_12782_"
                        d="M765,256.6c-1.2,0-2.3-0.3-3.2-0.8c-0.9-0.5-1.5-1.3-1.5-2.1s0.5-1.6,1.5-2.1c1.8-1,4.7-1,6.5,0
                  c0.9,0.5,1.5,1.3,1.5,2.1s-0.5,1.6-1.5,2.1C767.4,256.3,766.2,256.6,765,256.6z M765,251.6c-1,0-2,0.2-2.8,0.7
                  c-0.7,0.4-1,0.9-1,1.4c0,0.5,0.4,1,1,1.4c1.6,0.9,4.1,0.9,5.6,0c0.7-0.4,1-0.9,1-1.4c0-0.5-0.4-1-1-1.4
                  C767.1,251.9,766,251.6,765,251.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_12573_">
                    <g id="XMLID_12580_">
                      <path
                        id="XMLID_12603_"
                        d="M761.9,254.4c-0.2,0.1-0.4,0.3-0.6,0.5c0.2,0.2,0.4,0.4,0.7,0.5c1.7,1,4.4,1,6.1,0
                  c0.2-0.1,0.4-0.3,0.6-0.5c-0.2-0.2-0.4-0.4-0.7-0.5C766.2,253.5,763.5,253.5,761.9,254.4z"
                      />
                    </g>
                    <g id="XMLID_12575_">
                      <path
                        id="XMLID_12576_"
                        d="M765,256.6c-1.2,0-2.3-0.3-3.2-0.8c-0.3-0.2-0.6-0.4-0.8-0.6l-0.3-0.3l0.3-0.3
                  c0.2-0.2,0.4-0.4,0.7-0.5v0c1.8-1,4.7-1,6.5,0c0.3,0.2,0.6,0.4,0.8,0.6l0.3,0.3l-0.3,0.3c-0.2,0.2-0.4,0.4-0.7,0.5
                  C767.3,256.3,766.1,256.6,765,256.6z M761.9,254.9c0.1,0.1,0.2,0.1,0.3,0.2c1.6,0.9,4.1,0.9,5.6,0c0.1,0,0.1-0.1,0.2-0.1
                  c-0.1-0.1-0.2-0.1-0.3-0.2c-1.6-0.9-4.1-0.9-5.6,0l0,0C762,254.8,761.9,254.9,761.9,254.9z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_12562_">
                  <g id="XMLID_12569_">
                    <path
                      id="XMLID_12570_"
                      style="fill: #ffffff"
                      d="M770.1,245.9c0,4.3-2.3,7.8-5.2,7.8c-2.9,0-5.2-3.5-5.2-7.8
                c0-4.3,2.3-7.8,5.2-7.8C767.8,238.1,770.1,241.6,770.1,245.9z"
                    />
                  </g>
                  <g id="XMLID_12564_">
                    <path
                      id="XMLID_12565_"
                      d="M764.9,254.1c-3.1,0-5.6-3.7-5.6-8.2c0-4.5,2.5-8.2,5.6-8.2c3.1,0,5.6,3.7,5.6,8.2
                C770.5,250.4,768,254.1,764.9,254.1z M764.9,238.5c-2.6,0-4.8,3.3-4.8,7.4c0,4.1,2.1,7.4,4.8,7.4c2.6,0,4.8-3.3,4.8-7.4
                C769.7,241.8,767.6,238.5,764.9,238.5z"
                    />
                  </g>
                </g>
                <g id="XMLID_12553_">
                  <g id="XMLID_12559_">
                    <path
                      id="XMLID_12561_"
                      style="fill: #ffffff"
                      d="M762.8,241.3c1.2,2.3,0.9,4.8-0.6,5.6c-1.5,0.8-3.7-0.4-4.9-2.7
                c-1.2-2.3-0.9-4.8,0.6-5.6C759.4,237.8,761.6,239,762.8,241.3z"
                    />
                  </g>
                  <g id="XMLID_12554_">
                    <path
                      id="XMLID_12555_"
                      d="M761.2,247.6c-1.5,0-3.3-1.2-4.3-3.2c-0.6-1.2-0.9-2.5-0.7-3.6c0.1-1.2,0.7-2.1,1.6-2.5
                c1.7-0.9,4.2,0.4,5.5,2.9l0,0c1.3,2.5,0.9,5.3-0.8,6.1C762,247.5,761.6,247.6,761.2,247.6z M758.9,238.8
                c-0.3,0-0.6,0.1-0.8,0.2c-0.6,0.3-1,1-1.1,1.9c-0.1,1,0.1,2.1,0.7,3.1c1.1,2.1,3,3.2,4.4,2.6c1.3-0.7,1.5-2.9,0.5-5l0,0
                C761.6,239.8,760.1,238.8,758.9,238.8z"
                    />
                  </g>
                </g>
                <g id="XMLID_12543_">
                  <g id="XMLID_12550_">
                    <path
                      id="XMLID_12551_"
                      style="fill: #ffffff"
                      d="M770.7,239.7c-0.7,1.8-2.3,2.9-3.5,2.4c-1.2-0.5-1.6-2.3-0.9-4.1
                c0.7-1.8,2.3-2.9,3.5-2.4C771,236,771.4,237.9,770.7,239.7z"
                    />
                  </g>
                  <g id="XMLID_12544_">
                    <path
                      id="XMLID_12545_"
                      d="M767.8,242.6c-0.3,0-0.5,0-0.8-0.1c-0.7-0.3-1.2-0.9-1.4-1.9c-0.2-0.9-0.1-1.9,0.3-2.8
                c0.8-2,2.6-3.2,4-2.6c0.7,0.3,1.2,0.9,1.4,1.9c0.2,0.9,0.1,1.9-0.3,2.8c-0.4,0.9-1,1.7-1.7,2.2
                C768.9,242.4,768.3,242.6,767.8,242.6z M769.2,235.9c-0.9,0-2,0.9-2.5,2.3c-0.3,0.8-0.4,1.6-0.3,2.3c0.1,0.6,0.5,1.1,0.9,1.3
                c0.4,0.2,1,0.1,1.5-0.3c0.6-0.4,1.1-1.1,1.4-1.9l0,0c0.3-0.8,0.4-1.6,0.3-2.3c-0.1-0.6-0.5-1.1-0.9-1.3
                C769.5,235.9,769.4,235.9,769.2,235.9z"
                    />
                  </g>
                </g>
                <g id="XMLID_12533_">
                  <g id="XMLID_12540_">
                    <circle
                      id="XMLID_12541_"
                      style="fill: #ffffff"
                      cx="769.5"
                      cy="248.2"
                      r="2.2"
                    />
                  </g>
                  <g id="XMLID_12534_">
                    <path
                      id="XMLID_12536_"
                      d="M769.5,250.9c-1.5,0-2.6-1.2-2.6-2.6c0-1.5,1.2-2.6,2.6-2.6c1.5,0,2.6,1.2,2.6,2.6
                C772.1,249.7,770.9,250.9,769.5,250.9z M769.5,246.4c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8
                C771.3,247.2,770.5,246.4,769.5,246.4z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_10557_">
            <g id="XMLID_11240_">
              <g id="XMLID_11250_">
                <path
                  id="XMLID_11252_"
                  style="fill: #ffffff"
                  d="M689.7,167.5c0-0.2,0-0.5-0.1-0.7l2.3-2.2c0.1-0.1,0.1-0.2,0-0.2l-5.4-3.1
            c-0.1-0.1-0.4-0.2-0.4-0.2c-1.8-0.9-4.1-0.9-6.6,0.3c-7,3.2-12.9,12.9-12.9,20.8c0,3.6,1.3,6.2,3.3,7.5l0,0c0,0,5,2.9,5.8,3.3
            c0.1,0,0.2,0,0.2-0.1l1.2-2.4l-1.1-0.6c0.7-0.3,1.4-0.6,2.2-1c0.2-0.1,0.4-0.2,0.5-0.3l0,0c0.6,1.2,0.6,3.2,0.4,5.2
            c0,0.4,0.1,0.7,0.3,0.7l5.4,3.1c0.1,0,0.2,0,0.2-0.1l0.6-1.5l-0.3-6.5h-2.9l5.4-12.3l0,0C689.2,174,690,170.6,689.7,167.5z"
                />
              </g>
              <g id="XMLID_11242_">
                <path
                  id="XMLID_11244_"
                  d="M684.9,198.1c-0.1,0-0.2,0-0.3-0.1l-5.4-3.1c-0.3-0.1-0.6-0.5-0.6-1.1c0.2-2,0.1-3.6-0.2-4.6
            c0,0-0.1,0.1-0.1,0.1c-0.5,0.3-1,0.5-1.4,0.7l0.7,0.4l-1.3,2.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.3,0-0.4,0l-0.6-0.3
            c-3.7-2.1-5.2-3-5.2-3c-2.3-1.4-3.5-4.2-3.5-7.8c0-8.1,6.2-18,13.2-21.2c2.6-1.2,5.1-1.3,7-0.3c0,0,0.3,0.2,0.5,0.3l5.3,3.1
            c0.1,0.1,0.3,0.2,0.3,0.4c0,0.2,0,0.3-0.2,0.5l-2.1,2c0,0.2,0,0.3,0.1,0.5l0,0c0.3,3-0.4,6.4-1.9,9.9l-5.2,11.8h2.7l0.3,7
            l-0.6,1.6c-0.1,0.2-0.2,0.3-0.3,0.3C685.1,198,685,198.1,684.9,198.1z M678.9,188l0.2,0.4c0.5,1.1,0.7,2.9,0.4,5.4
            c0,0.2,0,0.3,0,0.3l0.1,0l5.2,3l0.4-1.1l-0.2-6h-3.2l5.7-12.9c1.4-3.3,2.1-6.6,1.8-9.5l0,0c0-0.2,0-0.5-0.1-0.7l0-0.2l2.2-2
            l-5-2.9c-0.1-0.1-0.4-0.2-0.4-0.2c-1.7-0.9-3.9-0.8-6.3,0.3c-6.8,3-12.7,12.6-12.7,20.4c0,3.4,1.1,5.9,3.1,7.1
            c0.1,0.1,3.7,2.1,5.3,3l0.3,0.2l0.9-1.8l-1.6-0.9l0.9-0.3c0.7-0.3,1.4-0.6,2.1-1c0.2-0.1,0.4-0.2,0.5-0.3L678.9,188z"
                />
              </g>
            </g>
            <g id="XMLID_11062_">
              <g id="XMLID_11236_">
                <path
                  id="XMLID_11238_"
                  style="fill: #ffffff"
                  d="M687.5,178l0.3-0.7l0,0c1.4-3.2,2.1-6.6,1.8-9.7c0-0.2,0-0.5-0.1-0.7l2.3-2.2
            c0.1-0.1,0.1-0.2,0-0.2l-5.4-3.1c-0.1-0.1-0.4-0.2-0.4-0.2c-1.8-0.9-4.1-0.9-6.6,0.3c-3,1.4-5.9,4-8.1,7.1L687.5,178z"
                />
              </g>
              <g id="XMLID_11063_">
                <path
                  id="XMLID_11064_"
                  d="M687.7,178.6l-17-9.9l0.3-0.4c2.4-3.4,5.3-5.9,8.3-7.3c2.6-1.2,5.1-1.3,7-0.3c0,0,0.3,0.2,0.5,0.3
            l5.3,3.1c0.1,0.1,0.3,0.2,0.3,0.4c0,0.2,0,0.3-0.2,0.5l-2.1,2c0,0.2,0,0.3,0.1,0.5c0.3,3-0.4,6.4-1.9,9.9L687.7,178.6z
             M671.9,168.4l15.4,9l0.1-0.3c1.4-3.3,2.1-6.6,1.8-9.5c0-0.2,0-0.5-0.1-0.7l0-0.2l2.2-2l-5-2.9c-0.1-0.1-0.4-0.2-0.4-0.2
            c-1.7-0.9-3.9-0.8-6.3,0.3C676.9,163,674.2,165.4,671.9,168.4z"
                />
              </g>
            </g>
            <g id="XMLID_11055_">
              <g id="XMLID_11060_">
                <path
                  id="XMLID_11061_"
                  d="M693.5,180.5L693.5,180.5c1.4-3.2,2.1-6.6,1.8-9.7c-0.5-6-4.8-8.6-10.3-6.1
            c-7,3.2-12.9,12.9-12.9,20.8c0,7.4,5.2,10.4,11.6,6.7c0.2-0.1,0.4-0.2,0.5-0.3l0,0c0.6,1.2,0.6,3.2,0.4,5.2
            c-0.1,1.1,1.1,0.9,1.6-0.2L693.5,180.5z"
                />
              </g>
              <g id="XMLID_11056_">
                <path
                  id="XMLID_11057_"
                  d="M685.2,198.2c-0.1,0-0.2,0-0.2,0c-0.4-0.1-0.7-0.4-0.6-1.2c0.2-2,0.1-3.6-0.2-4.6
            c0,0-0.1,0.1-0.1,0.1c-3.2,1.9-6.3,2.2-8.6,0.8c-2.3-1.3-3.6-4.1-3.6-7.9c0-8.1,6.2-18,13.2-21.2c2.7-1.2,5.3-1.3,7.3-0.1
            c2,1.2,3.3,3.5,3.5,6.6c0.3,3-0.4,6.4-1.9,9.9l0,0l-7.2,16.3C686.4,197.7,685.8,198.2,685.2,198.2z M684.5,191.2l0.2,0.4
            c0.5,1.1,0.7,2.9,0.4,5.4c0,0.2,0,0.3,0,0.3c0,0,0.1,0,0.1,0c0.2-0.1,0.4-0.3,0.6-0.7l7.2-16.3c1.4-3.3,2.1-6.6,1.8-9.5
            c-0.2-2.8-1.3-4.9-3.1-5.9c-1.7-1-4.1-1-6.5,0.1c-6.8,3-12.7,12.6-12.7,20.4c0,3.4,1.1,6,3.2,7.2c2.1,1.2,4.8,0.9,7.8-0.8
            c0.2-0.1,0.4-0.2,0.5-0.3L684.5,191.2z"
                />
              </g>
            </g>
            <g id="XMLID_10568_">
              <g id="XMLID_11048_">
                <g id="XMLID_11053_">
                  <path
                    id="XMLID_11054_"
                    d="M679.8,181.1c0,1.3-0.9,2.8-2,3.4c-1.1,0.6-2,0.1-2-1.1s0.9-2.8,2-3.4
              C678.9,179.3,679.8,179.8,679.8,181.1z"
                  />
                </g>
                <g id="XMLID_11049_">
                  <path
                    id="XMLID_11050_"
                    style="fill: #ffffff"
                    d="M676.9,185.3c-0.3,0-0.5-0.1-0.7-0.2c-0.5-0.3-0.8-0.9-0.8-1.6
              c0-1.4,1-3.1,2.2-3.8c0.7-0.4,1.3-0.4,1.8-0.1c0.5,0.3,0.8,0.9,0.8,1.6c0,1.4-1,3.1-2.2,3.8
              C677.6,185.1,677.3,185.3,676.9,185.3z M678.7,180.1c-0.2,0-0.4,0.1-0.7,0.2c-1,0.6-1.8,2-1.8,3.1c0,0.5,0.1,0.8,0.4,0.9
              c0.2,0.1,0.6,0.1,1-0.1c1-0.6,1.8-2,1.8-3.1c0-0.5-0.1-0.8-0.4-0.9C678.9,180.1,678.8,180.1,678.7,180.1z"
                  />
                </g>
              </g>
              <g id="XMLID_10576_">
                <g id="XMLID_11046_">
                  <path
                    id="XMLID_11047_"
                    d="M685.8,177.7c0,1.3-0.9,2.8-2,3.4c-1.1,0.6-2,0.1-2-1.1s0.9-2.8,2-3.4
              C684.9,175.9,685.8,176.4,685.8,177.7z"
                  />
                </g>
                <g id="XMLID_11042_">
                  <path
                    id="XMLID_11043_"
                    style="fill: #ffffff"
                    d="M682.9,181.8c-0.3,0-0.5-0.1-0.7-0.2c-0.5-0.3-0.8-0.9-0.8-1.6
              c0-1.4,1-3.1,2.2-3.8c0.7-0.4,1.3-0.4,1.8-0.1c0.5,0.3,0.8,0.9,0.8,1.6c0,1.4-1,3.1-2.2,3.8
              C683.6,181.7,683.3,181.8,682.9,181.8z M684.7,176.6c-0.2,0-0.4,0.1-0.7,0.2c-1,0.6-1.8,2-1.8,3.1c0,0.5,0.1,0.8,0.4,0.9
              c0.2,0.1,0.6,0.1,1-0.1c1-0.6,1.8-2,1.8-3.1c0-0.5-0.1-0.8-0.4-0.9C684.9,176.7,684.8,176.6,684.7,176.6z"
                  />
                </g>
              </g>
              <g id="XMLID_10569_">
                <g id="XMLID_10574_">
                  <path
                    id="XMLID_10575_"
                    d="M691.8,174.2c0,1.3-0.9,2.8-2,3.4c-1.1,0.6-2,0.1-2-1.1s0.9-2.8,2-3.4
              C690.9,172.4,691.8,172.9,691.8,174.2z"
                  />
                </g>
                <g id="XMLID_10570_">
                  <path
                    id="XMLID_10571_"
                    style="fill: #ffffff"
                    d="M688.9,178.4c-0.3,0-0.5-0.1-0.7-0.2c-0.5-0.3-0.8-0.9-0.8-1.6
              c0-1.4,1-3.1,2.2-3.8c0.7-0.4,1.3-0.4,1.8-0.1c0.5,0.3,0.8,0.9,0.8,1.6c0,1.4-1,3.1-2.2,3.8
              C689.6,178.2,689.2,178.4,688.9,178.4z M690.6,173.2c-0.2,0-0.4,0.1-0.7,0.2c-1,0.6-1.8,2-1.8,3.1c0,0.5,0.1,0.8,0.4,0.9
              c0.2,0.1,0.6,0.1,1-0.1c1-0.6,1.8-2,1.8-3.1c0-0.5-0.1-0.8-0.4-0.9C690.9,173.2,690.8,173.2,690.6,173.2z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_10563_">
              <g id="XMLID_10566_">
                <path
                  id="XMLID_10567_"
                  style="fill: #ffffff"
                  d="M666.7,183.7l5.6,3.2L666.7,183.7z"
                />
              </g>
              <g id="XMLID_10564_">
                <rect
                  id="XMLID_10565_"
                  x="669.1"
                  y="182.1"
                  transform="matrix(0.4986 -0.8668 0.8668 0.4986 175.0073 673.2842)"
                  width="0.8"
                  height="6.5"
                />
              </g>
            </g>
            <g id="XMLID_10558_">
              <g id="XMLID_10561_">
                <path
                  id="XMLID_10562_"
                  style="fill: #ffffff"
                  d="M679.9,161.3l5.6,3.2L679.9,161.3z"
                />
              </g>
              <g id="XMLID_10559_">
                <rect
                  id="XMLID_10560_"
                  x="682.3"
                  y="159.6"
                  transform="matrix(0.4986 -0.8668 0.8668 0.4986 201.0807 673.451)"
                  width="0.8"
                  height="6.5"
                />
              </g>
            </g>
          </g>
          <g id="XMLID_10463_">
            <g id="XMLID_10529_">
              <g id="XMLID_10530_">
                <g id="XMLID_10555_">
                  <path
                    id="XMLID_10556_"
                    style="fill: #ffffff"
                    d="M671.9,352l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C672,351.8,672.1,351.9,671.9,352z"
                  />
                </g>
                <g id="XMLID_10553_">
                  <path
                    id="XMLID_10554_"
                    style="fill: #ffffff"
                    d="M675.9,354.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C676.1,354.1,676.1,354.3,675.9,354.4z"
                  />
                </g>
                <g id="XMLID_10551_">
                  <path
                    id="XMLID_10552_"
                    style="fill: #ffffff"
                    d="M671.7,356.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C671.8,356.5,671.9,356.7,671.7,356.8z"
                  />
                </g>
                <g id="XMLID_10549_">
                  <path
                    id="XMLID_10550_"
                    style="fill: #ffffff"
                    d="M679.9,356.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C680.1,356.4,680.1,356.6,679.9,356.7z"
                  />
                </g>
                <g id="XMLID_10547_">
                  <path
                    id="XMLID_10548_"
                    style="fill: #ffffff"
                    d="M675.7,359.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C675.9,358.9,675.9,359,675.7,359.1z"
                  />
                </g>
                <g id="XMLID_10545_">
                  <path
                    id="XMLID_10546_"
                    style="fill: #ffffff"
                    d="M684.2,359.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C684.3,358.9,684.4,359,684.2,359.1z"
                  />
                </g>
                <g id="XMLID_10543_">
                  <path
                    id="XMLID_10544_"
                    style="fill: #ffffff"
                    d="M680,361.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C680.1,361.3,680.1,361.5,680,361.6z"
                  />
                </g>
                <g id="XMLID_10541_">
                  <path
                    id="XMLID_10542_"
                    style="fill: #ffffff"
                    d="M688.4,356.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C688.6,356.4,688.6,356.6,688.4,356.7z"
                  />
                </g>
                <g id="XMLID_10539_">
                  <path
                    id="XMLID_10540_"
                    style="fill: #ffffff"
                    d="M692.7,354.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C692.8,354,692.8,354.2,692.7,354.3z"
                  />
                </g>
                <g id="XMLID_10537_">
                  <path
                    id="XMLID_10538_"
                    style="fill: #ffffff"
                    d="M696.9,351.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C697,351.5,697.1,351.7,696.9,351.8z"
                  />
                </g>
                <g id="XMLID_10535_">
                  <path
                    id="XMLID_10536_"
                    style="fill: #ffffff"
                    d="M688.2,361.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C688.4,361.2,688.4,361.4,688.2,361.5z"
                  />
                </g>
                <g id="XMLID_10533_">
                  <path
                    id="XMLID_10534_"
                    style="fill: #ffffff"
                    d="M692.4,359l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C692.6,358.7,692.6,358.9,692.4,359z"
                  />
                </g>
                <g id="XMLID_10531_">
                  <path
                    id="XMLID_10532_"
                    style="fill: #ffffff"
                    d="M696.7,356.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C696.8,356.3,696.9,356.5,696.7,356.6z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_10464_">
              <g id="XMLID_10499_">
                <g id="XMLID_10500_">
                  <g id="XMLID_10507_">
                    <g id="XMLID_10522_">
                      <g id="XMLID_10527_">
                        <path
                          id="XMLID_10528_"
                          style="fill: #ffffff"
                          d="M681.5,353.5l-11.7-6.8c-0.2-0.1-0.3-0.3-0.3-0.4l0,0v0c0,0,0,0,0,0
                    l0-3.6c0-0.1,0.1-0.2,0.2-0.2l5.9,0c0,0,0.1,0,0.1,0l5.8-3.3c0.5-0.3,1.1-0.3,1.5-0.1l5.9,3.5c0,0,0.1,0,0.1,0l5.9,0
                    c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.6,6.7C682.6,353.7,681.9,353.7,681.5,353.5z"
                        />
                      </g>
                      <g id="XMLID_10523_">
                        <path
                          id="XMLID_10524_"
                          d="M682.1,354c-0.3,0-0.6-0.1-0.9-0.2l0,0l-11.7-6.8c-0.3-0.2-0.5-0.5-0.5-0.8l0-3.6
                    c0-0.3,0.3-0.6,0.6-0.6l5.9,0l5.7-3.2c0.6-0.3,1.4-0.4,1.9-0.1l5.8,3.4l5.8,0c0.1,0,0.3,0.1,0.4,0.2
                    c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8l-11.6,6.7C682.9,354,682.5,354,682.1,354z M681.7,353.1
                    c0.2,0.1,0.8,0.1,1.1-0.1l11.6-6.7c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3l-5.6,0c-0.1,0-0.2,0-0.3-0.1l-5.9-3.5
                    c-0.2-0.1-0.8-0.1-1.1,0.1l-5.8,3.3c-0.1,0-0.2,0.1-0.3,0.1l-5.7,0l0,3.4c0,0,0,0,0.1,0.1L681.7,353.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_10515_">
                      <g id="XMLID_10520_">
                        <path
                          id="XMLID_10521_"
                          d="M689.6,342.5c0,0,5.2,0,5.2,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.5,6.6
                    c0,0-0.1,0-0.1,0l0-3.7c0,0,0,0,0,0l0,0C682.6,348.4,689.6,342.5,689.6,342.5z"
                        />
                      </g>
                      <g id="XMLID_10516_">
                        <path
                          id="XMLID_10517_"
                          d="M683.1,353.9l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-3.7c-0.2-1.4,4.1-5.2,6.7-7.4l0.1-0.1
                    c0.1,0,0.1,0,5.3,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8L683.1,353.9z M683.5,349.7
                    l0,3l11-6.3c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3c-1.4,0-4.1,0-4.8,0c-3.1,2.6-6.4,5.8-6.3,6.5L683.5,349.7L683.5,349.7z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_10508_">
                      <g id="XMLID_10513_">
                        <path
                          id="XMLID_10514_"
                          style="fill: #ffffff"
                          d="M694.8,342l-11.7-6.8c-0.4-0.2-1.1-0.2-1.5,0.1l-11.6,6.7
                    c-0.5,0.3-0.5,0.7-0.1,0.9l11.7,6.8c0.4,0.2,1.1,0.2,1.5-0.1l11.6-6.7C695.1,342.7,695.2,342.3,694.8,342z"
                        />
                      </g>
                      <g id="XMLID_10509_">
                        <path
                          id="XMLID_10510_"
                          d="M682.2,350.2c-0.3,0-0.6-0.1-0.9-0.2l-11.7-6.8c-0.3-0.2-0.5-0.4-0.5-0.7c0-0.3,0.2-0.6,0.6-0.9
                    l11.6-6.7c0.6-0.3,1.4-0.4,1.9-0.1l11.7,6.8c0.3,0.2,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.9l-11.6,6.7
                    C682.9,350.1,682.6,350.2,682.2,350.2z M669.9,342.4C669.9,342.4,670,342.5,669.9,342.4l11.8,6.8c0.2,0.1,0.8,0.1,1.1-0.1
                    l11.6-6.7c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0l0,0l-11.7-6.8c-0.2-0.1-0.8-0.1-1.1,0.1l-11.6,6.7
                    C670,342.4,670,342.4,669.9,342.4z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_10504_">
                    <g id="XMLID_10505_">
                      <rect
                        id="XMLID_10506_"
                        x="670.1"
                        y="345.6"
                        transform="matrix(2.985182e-02 -0.9996 0.9996 2.985182e-02 306.021 1007.3792)"
                        width="3.7"
                        height="0.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_10501_">
                    <g id="XMLID_10502_">
                      <rect
                        id="XMLID_10503_"
                        x="690.1"
                        y="346.3"
                        transform="matrix(1.471350e-02 -0.9999 0.9999 1.471350e-02 334.9706 1033.1154)"
                        style="fill: #ffffff"
                        width="3.3"
                        height="0.6"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_10465_">
                <g id="XMLID_10473_">
                  <g id="XMLID_10492_">
                    <g id="XMLID_10497_">
                      <path
                        id="XMLID_10498_"
                        style="fill: #ffffff"
                        d="M688.5,332.2l-3.1-1.9c-0.2-0.1-0.4-0.1-0.5,0l0,0l0,0c0,0,0,0,0,0
                  l-3.1,1.8c-0.1,0-0.1,0.1-0.1,0.2l2.9,5.1c0,0,0,0.1,0,0.1v0.6c0,0.5,0.3,1.1,0.7,1.4l-2.8-1.5c0,0,0,0,0.1,0.1l2.9,5.1
                  c0,0.1,0.1,0.1,0.2,0.1l3.1-1.8c0.1-0.1,0.2-0.3,0.2-0.6v-7.3C689.2,333,688.9,332.4,688.5,332.2z"
                      />
                    </g>
                    <g id="XMLID_10493_">
                      <path
                        id="XMLID_10494_"
                        d="M685.8,343.6c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.3l-2.9-5.1c0,0,0.1,0.1,0.1,0.1l0.4-0.7
                  l1.5,0.8c0-0.1,0-0.2,0-0.3v-0.5l-2.9-5.1c-0.2-0.3-0.1-0.6,0.2-0.8l3.1-1.8c0.3-0.2,0.6-0.2,0.9,0l3.1,1.9
                  c0.5,0.3,0.9,1,0.9,1.7v7.3c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.9C686,343.6,685.9,343.6,685.8,343.6z M683.8,339l2.1,3.7l2.9-1.7
                  c0,0,0-0.1,0-0.2v-7.3c0-0.4-0.3-0.9-0.5-1l0,0l-3.1-1.9c0,0-0.1,0-0.1,0l-2.9,1.7l2.8,4.9c0,0.1,0.1,0.2,0.1,0.3v0.6
                  c0,0.4,0.3,0.9,0.5,1l-0.4,0.7L683.8,339z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_10484_">
                    <g id="XMLID_10490_">
                      <path
                        id="XMLID_10491_"
                        d="M682.5,336.9c0,0,1.1-6.7,2.3-1l-2.1,2.1c0,0,0.1,0.1,0.1,0.1l2.9,5.1c0.1,0.1,0.2,0.1,0.3,0.1
                  l3.1-1.8c0.1-0.1,0.2-0.3,0.2-0.6v-7.2c0,0,0-0.1-0.1,0l-3.2,1.9c0,0,0,0,0,0L682.5,336.9z"
                      />
                    </g>
                    <g id="XMLID_10485_">
                      <path
                        id="XMLID_10486_"
                        d="M685.8,343.6c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.3l-2.9-5.1L682,338l0.8-0.7l-0.8,0.3
                  l0.1-0.7c0.4-2.4,1-3.6,1.7-3.6c0,0,0,0,0,0c0.6,0,0.9,0.6,1.3,2.2l0.6-0.3v0l3.4-2l0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.4v7.2
                  c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.8C686,343.6,685.9,343.6,685.8,343.6z M683.2,338l2.7,4.7l2.9-1.7c0,0,0-0.1,0-0.2v-6.6
                  l-2.7,1.6l-1.2,0.5L683.2,338z M683.8,334.1c-0.2,0.3-0.5,1.1-0.7,2.1l1.2-0.5C684.1,334.6,683.9,334.2,683.8,334.1z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_10475_">
                    <g id="XMLID_10482_">
                      <path
                        id="XMLID_10483_"
                        style="fill: #ffffff"
                        d="M685.2,343.3l-3.1-1.9c-0.4-0.2-0.7-0.8-0.7-1.4v-7.3
                  c0-0.5,0.3-0.8,0.7-0.5l3.1,1.9c0.4,0.2,0.7,0.8,0.7,1.4v7.3C685.9,343.3,685.6,343.5,685.2,343.3z"
                      />
                    </g>
                    <g id="XMLID_10477_">
                      <path
                        id="XMLID_10478_"
                        d="M685.5,343.8c-0.2,0-0.3,0-0.5-0.1l0,0l-3.1-1.9c-0.5-0.3-0.9-1-0.9-1.7v-7.3
                  c0-0.4,0.2-0.8,0.5-0.9c0.3-0.1,0.6-0.1,0.9,0l3.1,1.9c0.5,0.3,0.9,1,0.9,1.7v7.3c0,0.4-0.2,0.8-0.5,0.9
                  C685.7,343.8,685.6,343.8,685.5,343.8z M685.4,342.9C685.4,343,685.5,343,685.4,342.9c0.1,0,0.1,0,0.1-0.2v-7.3
                  c0-0.4-0.3-0.9-0.5-1l-3.1-1.9c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.2v7.3c0,0.4,0.3,0.9,0.5,1L685.4,342.9z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_10469_">
                  <g id="XMLID_10470_">
                    <rect
                      id="XMLID_10471_"
                      x="684.1"
                      y="332.1"
                      transform="matrix(0.8488 -0.5288 0.5288 0.8488 -72.0774 413.0336)"
                      width="3.7"
                      height="0.8"
                    />
                  </g>
                </g>
                <g id="XMLID_10466_">
                  <g id="XMLID_10467_">
                    <rect
                      id="XMLID_10468_"
                      x="686"
                      y="338"
                      transform="matrix(0.8567 -0.5157 0.5157 0.8567 -76.0114 403.0844)"
                      style="fill: #ffffff"
                      width="3.3"
                      height="0.6"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_3448_">
            <g id="XMLID_5049_">
              <g id="XMLID_5125_">
                <g id="XMLID_5253_">
                  <path
                    id="XMLID_5254_"
                    style="fill: #ffffff"
                    d="M535.5,285.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C535.6,285,535.7,285.2,535.5,285.3z"
                  />
                </g>
                <g id="XMLID_5250_">
                  <path
                    id="XMLID_5251_"
                    style="fill: #ffffff"
                    d="M531.3,287.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C531.4,287.5,531.4,287.6,531.3,287.7z"
                  />
                </g>
                <g id="XMLID_5247_">
                  <path
                    id="XMLID_5248_"
                    style="fill: #ffffff"
                    d="M539.5,287.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C539.7,287.3,539.7,287.5,539.5,287.6z"
                  />
                </g>
                <g id="XMLID_5244_">
                  <path
                    id="XMLID_5246_"
                    style="fill: #ffffff"
                    d="M535.3,290.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C535.4,289.8,535.5,290,535.3,290.1z"
                  />
                </g>
                <g id="XMLID_5241_">
                  <path
                    id="XMLID_5243_"
                    style="fill: #ffffff"
                    d="M543.8,290.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C543.9,289.8,543.9,290,543.8,290.1z"
                  />
                </g>
                <g id="XMLID_5239_">
                  <path
                    id="XMLID_5240_"
                    style="fill: #ffffff"
                    d="M539.5,292.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C539.7,292.2,539.7,292.4,539.5,292.5z"
                  />
                </g>
                <g id="XMLID_5236_">
                  <path
                    id="XMLID_5237_"
                    style="fill: #ffffff"
                    d="M547.8,292.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C547.9,292.1,548,292.3,547.8,292.4z"
                  />
                </g>
                <g id="XMLID_5233_">
                  <path
                    id="XMLID_5234_"
                    style="fill: #ffffff"
                    d="M551.8,294.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C552,294.4,552,294.6,551.8,294.7z"
                  />
                </g>
                <g id="XMLID_5230_">
                  <path
                    id="XMLID_5232_"
                    style="fill: #ffffff"
                    d="M556.1,297.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C556.2,296.9,556.2,297.1,556.1,297.2z"
                  />
                </g>
                <g id="XMLID_5227_">
                  <path
                    id="XMLID_5229_"
                    style="fill: #ffffff"
                    d="M560.1,299.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C560.2,299.2,560.3,299.4,560.1,299.5z"
                  />
                </g>
                <g id="XMLID_5225_">
                  <path
                    id="XMLID_5226_"
                    style="fill: #ffffff"
                    d="M564.1,301.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C564.3,301.5,564.3,301.7,564.1,301.8z"
                  />
                </g>
                <g id="XMLID_5223_">
                  <path
                    id="XMLID_5224_"
                    style="fill: #ffffff"
                    d="M568.4,304.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C568.5,304,568.5,304.2,568.4,304.3z"
                  />
                </g>
                <g id="XMLID_5221_">
                  <path
                    id="XMLID_5222_"
                    style="fill: #ffffff"
                    d="M572.4,306.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C572.5,306.3,572.5,306.5,572.4,306.6z"
                  />
                </g>
                <g id="XMLID_5219_">
                  <path
                    id="XMLID_5220_"
                    style="fill: #ffffff"
                    d="M576.4,308.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C576.5,308.6,576.6,308.8,576.4,308.9z"
                  />
                </g>
                <g id="XMLID_5217_">
                  <path
                    id="XMLID_5218_"
                    style="fill: #ffffff"
                    d="M580.6,311.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C580.8,311.1,580.8,311.3,580.6,311.4z"
                  />
                </g>
                <g id="XMLID_5215_">
                  <path
                    id="XMLID_5216_"
                    style="fill: #ffffff"
                    d="M584.7,313.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C584.8,313.4,584.8,313.6,584.7,313.7z"
                  />
                </g>
                <g id="XMLID_5213_">
                  <path
                    id="XMLID_5214_"
                    style="fill: #ffffff"
                    d="M588.7,316l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C588.8,315.7,588.9,315.9,588.7,316z"
                  />
                </g>
                <g id="XMLID_5211_">
                  <path
                    id="XMLID_5212_"
                    style="fill: #ffffff"
                    d="M592.9,318.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C593.1,318.2,593.1,318.4,592.9,318.5z"
                  />
                </g>
                <g id="XMLID_5209_">
                  <path
                    id="XMLID_5210_"
                    style="fill: #ffffff"
                    d="M597,320.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C597.1,320.5,597.1,320.7,597,320.8z"
                  />
                </g>
                <g id="XMLID_5207_">
                  <path
                    id="XMLID_5208_"
                    style="fill: #ffffff"
                    d="M601,323.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C601.1,322.8,601.2,323,601,323.1z"
                  />
                </g>
                <g id="XMLID_5205_">
                  <path
                    id="XMLID_5206_"
                    style="fill: #ffffff"
                    d="M605.2,325.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C605.4,325.3,605.4,325.5,605.2,325.6z"
                  />
                </g>
                <g id="XMLID_5203_">
                  <path
                    id="XMLID_5204_"
                    style="fill: #ffffff"
                    d="M609.3,327.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C609.4,327.6,609.4,327.8,609.3,327.9z"
                  />
                </g>
                <g id="XMLID_5201_">
                  <path
                    id="XMLID_5202_"
                    style="fill: #ffffff"
                    d="M605,330.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C605.2,330.1,605.2,330.2,605,330.3z"
                  />
                </g>
                <g id="XMLID_5199_">
                  <path
                    id="XMLID_5200_"
                    style="fill: #ffffff"
                    d="M613.3,330.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C613.4,329.9,613.4,330.1,613.3,330.2z"
                  />
                </g>
                <g id="XMLID_5197_">
                  <path
                    id="XMLID_5198_"
                    style="fill: #ffffff"
                    d="M609,332.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C609.2,332.4,609.2,332.6,609,332.7z"
                  />
                </g>
                <g id="XMLID_5195_">
                  <path
                    id="XMLID_5196_"
                    style="fill: #ffffff"
                    d="M617.5,332.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C617.7,332.4,617.7,332.6,617.5,332.7z"
                  />
                </g>
                <g id="XMLID_5193_">
                  <path
                    id="XMLID_5194_"
                    style="fill: #ffffff"
                    d="M613.3,335.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C613.4,334.8,613.5,335,613.3,335.1z"
                  />
                </g>
                <g id="XMLID_5191_">
                  <path
                    id="XMLID_5192_"
                    style="fill: #ffffff"
                    d="M621.5,335l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C621.7,334.7,621.7,334.9,621.5,335z"
                  />
                </g>
                <g id="XMLID_5189_">
                  <path
                    id="XMLID_5190_"
                    style="fill: #ffffff"
                    d="M617.3,337.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C617.5,337.1,617.5,337.3,617.3,337.4z"
                  />
                </g>
                <g id="XMLID_5186_">
                  <path
                    id="XMLID_5187_"
                    style="fill: #ffffff"
                    d="M625.6,337.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C625.7,337,625.7,337.2,625.6,337.3z"
                  />
                </g>
                <g id="XMLID_5183_">
                  <path
                    id="XMLID_5184_"
                    style="fill: #ffffff"
                    d="M621.3,339.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C621.5,339.5,621.5,339.6,621.3,339.8z"
                  />
                </g>
                <g id="XMLID_5180_">
                  <path
                    id="XMLID_5182_"
                    style="fill: #ffffff"
                    d="M629.8,339.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C630,339.5,630,339.7,629.8,339.8z"
                  />
                </g>
                <g id="XMLID_5177_">
                  <path
                    id="XMLID_5179_"
                    style="fill: #ffffff"
                    d="M625.6,342.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C625.7,341.9,625.8,342.1,625.6,342.2z"
                  />
                </g>
                <g id="XMLID_5175_">
                  <path
                    id="XMLID_5176_"
                    style="fill: #ffffff"
                    d="M634.1,337.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C634.2,337,634.2,337.2,634.1,337.3z"
                  />
                </g>
                <g id="XMLID_5172_">
                  <path
                    id="XMLID_5173_"
                    style="fill: #ffffff"
                    d="M638.3,334.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C638.4,334.6,638.5,334.8,638.3,334.9z"
                  />
                </g>
                <g id="XMLID_5169_">
                  <path
                    id="XMLID_5170_"
                    style="fill: #ffffff"
                    d="M642.5,332.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C642.7,332.1,642.7,332.3,642.5,332.4z"
                  />
                </g>
                <g id="XMLID_5166_">
                  <path
                    id="XMLID_5168_"
                    style="fill: #ffffff"
                    d="M646.8,330l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C646.9,329.7,646.9,329.9,646.8,330z"
                  />
                </g>
                <g id="XMLID_5163_">
                  <path
                    id="XMLID_5165_"
                    style="fill: #ffffff"
                    d="M651,327.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C651.1,327.3,651.2,327.4,651,327.5z"
                  />
                </g>
                <g id="XMLID_5161_">
                  <path
                    id="XMLID_5162_"
                    style="fill: #ffffff"
                    d="M655.2,325.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C655.4,324.8,655.4,325,655.2,325.1z"
                  />
                </g>
                <g id="XMLID_5158_">
                  <path
                    id="XMLID_5159_"
                    style="fill: #ffffff"
                    d="M659.5,322.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C659.6,322.4,659.6,322.5,659.5,322.6z"
                  />
                </g>
                <g id="XMLID_5154_">
                  <path
                    id="XMLID_5156_"
                    style="fill: #ffffff"
                    d="M663.7,320.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C663.9,319.9,663.9,320.1,663.7,320.2z"
                  />
                </g>
                <g id="XMLID_5151_">
                  <path
                    id="XMLID_5152_"
                    style="fill: #ffffff"
                    d="M667.9,317.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C668.1,317.5,668.1,317.7,667.9,317.8z"
                  />
                </g>
                <g id="XMLID_5148_">
                  <path
                    id="XMLID_5149_"
                    style="fill: #ffffff"
                    d="M672.2,315.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C672.3,315,672.3,315.2,672.2,315.3z"
                  />
                </g>
                <g id="XMLID_5146_">
                  <path
                    id="XMLID_5147_"
                    style="fill: #ffffff"
                    d="M676.4,312.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C676.6,312.6,676.6,312.8,676.4,312.9z"
                  />
                </g>
                <g id="XMLID_5144_">
                  <path
                    id="XMLID_5145_"
                    style="fill: #ffffff"
                    d="M680.6,310.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C680.8,310.1,680.8,310.3,680.6,310.4z"
                  />
                </g>
                <g id="XMLID_5142_">
                  <path
                    id="XMLID_5143_"
                    style="fill: #ffffff"
                    d="M684.9,308l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C685,307.7,685.1,307.9,684.9,308z"
                  />
                </g>
                <g id="XMLID_5140_">
                  <path
                    id="XMLID_5141_"
                    style="fill: #ffffff"
                    d="M689.1,305.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C689.3,305.2,689.3,305.4,689.1,305.5z"
                  />
                </g>
                <g id="XMLID_5138_">
                  <path
                    id="XMLID_5139_"
                    style="fill: #ffffff"
                    d="M693.3,303.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C693.5,302.8,693.5,303,693.3,303.1z"
                  />
                </g>
                <g id="XMLID_5136_">
                  <path
                    id="XMLID_5137_"
                    style="fill: #ffffff"
                    d="M633.8,342.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C634,341.8,634,342,633.8,342.1z"
                  />
                </g>
                <g id="XMLID_5134_">
                  <path
                    id="XMLID_5135_"
                    style="fill: #ffffff"
                    d="M638.1,339.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C638.2,339.4,638.2,339.5,638.1,339.6z"
                  />
                </g>
                <g id="XMLID_5132_">
                  <path
                    id="XMLID_5133_"
                    style="fill: #ffffff"
                    d="M642.3,337.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C642.5,336.9,642.5,337.1,642.3,337.2z"
                  />
                </g>
                <g id="XMLID_5130_">
                  <path
                    id="XMLID_5131_"
                    style="fill: #ffffff"
                    d="M646.5,334.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C646.7,334.5,646.7,334.6,646.5,334.7z"
                  />
                </g>
                <g id="XMLID_5128_">
                  <path
                    id="XMLID_5129_"
                    style="fill: #ffffff"
                    d="M650.8,332.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C650.9,332,651,332.2,650.8,332.3z"
                  />
                </g>
                <g id="XMLID_5126_">
                  <path
                    id="XMLID_5127_"
                    style="fill: #ffffff"
                    d="M655,329.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C655.2,329.6,655.2,329.8,655,329.9z"
                  />
                </g>
              </g>
              <g id="XMLID_5050_">
                <g id="XMLID_5122_">
                  <path
                    id="XMLID_5123_"
                    style="fill: #ffffff"
                    d="M701.8,298.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C702,297.9,702,298.1,701.8,298.2z"
                  />
                </g>
                <g id="XMLID_5119_">
                  <path
                    id="XMLID_5120_"
                    style="fill: #ffffff"
                    d="M697.6,300.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C697.7,300.4,697.7,300.5,697.6,300.6z"
                  />
                </g>
                <g id="XMLID_5116_">
                  <path
                    id="XMLID_5118_"
                    style="fill: #ffffff"
                    d="M706,295.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C706.2,295.5,706.2,295.7,706,295.8z"
                  />
                </g>
                <g id="XMLID_5113_">
                  <path
                    id="XMLID_5115_"
                    style="fill: #ffffff"
                    d="M710.3,293.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C710.4,293,710.4,293.2,710.3,293.3z"
                  />
                </g>
                <g id="XMLID_5111_">
                  <path
                    id="XMLID_5112_"
                    style="fill: #ffffff"
                    d="M659.2,327.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C659.4,327.2,659.4,327.3,659.2,327.4z"
                  />
                </g>
                <g id="XMLID_5108_">
                  <path
                    id="XMLID_5109_"
                    style="fill: #ffffff"
                    d="M663.5,325l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C663.6,324.7,663.7,324.9,663.5,325z"
                  />
                </g>
                <g id="XMLID_5105_">
                  <path
                    id="XMLID_5106_"
                    style="fill: #ffffff"
                    d="M667.7,322.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C667.9,322.3,667.9,322.4,667.7,322.5z"
                  />
                </g>
                <g id="XMLID_5102_">
                  <path
                    id="XMLID_5104_"
                    style="fill: #ffffff"
                    d="M672,320.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C672.1,319.8,672.1,320,672,320.1z"
                  />
                </g>
                <g id="XMLID_5097_">
                  <path
                    id="XMLID_5098_"
                    style="fill: #ffffff"
                    d="M676.2,317.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C676.3,317.4,676.4,317.6,676.2,317.7z"
                  />
                </g>
                <g id="XMLID_5094_">
                  <path
                    id="XMLID_5095_"
                    style="fill: #ffffff"
                    d="M680.4,315.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C680.6,314.9,680.6,315.1,680.4,315.2z"
                  />
                </g>
                <g id="XMLID_5091_">
                  <path
                    id="XMLID_5092_"
                    style="fill: #ffffff"
                    d="M684.7,312.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C684.8,312.5,684.8,312.7,684.7,312.8z"
                  />
                </g>
                <g id="XMLID_5087_">
                  <path
                    id="XMLID_5090_"
                    style="fill: #ffffff"
                    d="M688.9,310.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C689,310,689.1,310.2,688.9,310.3z"
                  />
                </g>
                <g id="XMLID_5084_">
                  <path
                    id="XMLID_5085_"
                    style="fill: #ffffff"
                    d="M693.1,307.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C693.3,307.6,693.3,307.8,693.1,307.9z"
                  />
                </g>
                <g id="XMLID_5081_">
                  <path
                    id="XMLID_5083_"
                    style="fill: #ffffff"
                    d="M697.4,305.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C697.5,305.1,697.5,305.3,697.4,305.4z"
                  />
                </g>
                <g id="XMLID_5078_">
                  <path
                    id="XMLID_5080_"
                    style="fill: #ffffff"
                    d="M705.8,300.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C706,300.3,706,300.4,705.8,300.5z"
                  />
                </g>
                <g id="XMLID_5076_">
                  <path
                    id="XMLID_5077_"
                    style="fill: #ffffff"
                    d="M701.6,303l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C701.7,302.7,701.8,302.9,701.6,303z"
                  />
                </g>
                <g id="XMLID_5073_">
                  <path
                    id="XMLID_5074_"
                    style="fill: #ffffff"
                    d="M710.1,298.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C710.2,297.8,710.2,298,710.1,298.1z"
                  />
                </g>
                <g id="XMLID_5070_">
                  <path
                    id="XMLID_5071_"
                    style="fill: #ffffff"
                    d="M714.3,295.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C714.4,295.4,714.5,295.6,714.3,295.7z"
                  />
                </g>
                <g id="XMLID_5067_">
                  <path
                    id="XMLID_5069_"
                    style="fill: #ffffff"
                    d="M714.5,290.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C714.7,290.6,714.7,290.8,714.5,290.9z"
                  />
                </g>
                <g id="XMLID_5065_">
                  <path
                    id="XMLID_5066_"
                    style="fill: #ffffff"
                    d="M718.7,288.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C718.9,288.1,718.9,288.3,718.7,288.4z"
                  />
                </g>
                <g id="XMLID_5062_">
                  <path
                    id="XMLID_5064_"
                    style="fill: #ffffff"
                    d="M718.5,293.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C718.7,292.9,718.7,293.1,718.5,293.2z"
                  />
                </g>
                <g id="XMLID_5060_">
                  <path
                    id="XMLID_5061_"
                    style="fill: #ffffff"
                    d="M722.8,290.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C722.9,290.5,722.9,290.6,722.8,290.7z"
                  />
                </g>
                <g id="XMLID_5057_">
                  <path
                    id="XMLID_5058_"
                    style="fill: #ffffff"
                    d="M722.9,286l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C723.1,285.7,723.1,285.9,722.9,286z"
                  />
                </g>
                <g id="XMLID_5054_">
                  <path
                    id="XMLID_5056_"
                    style="fill: #ffffff"
                    d="M727.1,283.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C727.3,283.3,727.3,283.4,727.1,283.5z"
                  />
                </g>
                <g id="XMLID_5051_">
                  <path
                    id="XMLID_5053_"
                    style="fill: #ffffff"
                    d="M726.9,288.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C727.1,288,727.1,288.2,726.9,288.3z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_4984_">
              <g id="XMLID_4989_">
                <g id="XMLID_5028_">
                  <g id="XMLID_5039_">
                    <g id="XMLID_5046_">
                      <path
                        id="XMLID_5047_"
                        style="fill: #ffffff"
                        d="M715.6,251.9C715.6,251.9,715.6,251.8,715.6,251.9v-8.4l-9.3,0.2l-14.1-8
                  c-3.8-2.2-8.4-2.2-12.1,0l-32.7,19.2l-15.6,2.9v8.4c-0.1,1.7,0.6,3.5,2.3,4.5l21,12c3.8,2.2,8.4,2.2,12.1,0l46-26.8
                  C714.8,255.1,715.6,253.5,715.6,251.9L715.6,251.9L715.6,251.9z"
                      />
                    </g>
                    <g id="XMLID_5040_">
                      <path
                        id="XMLID_5042_"
                        d="M661.2,284.9c-2.2,0-4.4-0.6-6.3-1.7l-21-12c-1.8-1-2.8-3-2.6-5l0-8.9l16-3l32.6-19.1
                  c3.9-2.3,8.8-2.3,12.7,0l13.9,7.9l9.7-0.2v9.5l0,0c-0.2,1.7-1.1,3.1-2.6,4l-46,26.8C665.6,284.4,663.4,284.9,661.2,284.9z
                   M632.4,258.3v8c-0.1,1.7,0.7,3.2,2.1,4l21,12c3.6,2.1,8,2.1,11.6,0l46-26.8c1.3-0.7,2.1-2.1,2.1-3.5l0-0.2v-7.7l-8.9,0.2
                  l-14.2-8c-3.6-2.1-8-2.1-11.6,0l-32.7,19.2L632.4,258.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_5030_">
                    <g id="XMLID_5036_">
                      <path
                        id="XMLID_5038_"
                        style="fill: #ffffff"
                        d="M634.2,262.4l21,12c3.8,2.2,8.4,2.2,12.1,0l46-26.8c3.2-1.8,3.2-6.4,0-8.2
                  l-21-12c-3.8-2.2-8.4-2.2-12.1,0l-46,26.8C631,256,631,260.6,634.2,262.4z"
                      />
                    </g>
                    <g id="XMLID_5031_">
                      <path
                        id="XMLID_5032_"
                        d="M661.2,276.5c-2.2,0-4.4-0.6-6.3-1.7l-21-12c-1.7-1-2.6-2.7-2.6-4.6c0-1.9,1-3.6,2.6-4.6l46-26.8
                  c3.9-2.3,8.8-2.3,12.7,0l21,12c1.7,1,2.6,2.7,2.6,4.6c0,1.9-1,3.6-2.6,4.6l-46,26.8C665.6,275.9,663.4,276.5,661.2,276.5z
                   M634.5,261.9l21,12c3.6,2.1,8,2.1,11.6,0l46-26.8c1.3-0.8,2.1-2.1,2.1-3.6c0-1.5-0.8-2.9-2.1-3.6l-21-12
                  c-3.6-2.1-8-2.1-11.6,0l-46,26.8c-1.3,0.8-2.1,2.1-2.1,3.6C632.4,259.8,633.1,261.2,634.5,261.9z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4992_">
                  <g id="XMLID_5024_">
                    <g id="XMLID_5025_">
                      <rect
                        id="XMLID_5027_"
                        x="636"
                        y="263.7"
                        width="1.1"
                        height="8.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_5020_">
                    <g id="XMLID_5021_">
                      <rect
                        id="XMLID_5023_"
                        x="660.5"
                        y="275.8"
                        width="1.1"
                        height="8.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_5007_">
                    <g id="XMLID_5009_">
                      <rect
                        id="XMLID_5010_"
                        x="665.8"
                        y="274.5"
                        width="1.1"
                        height="8.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4995_">
                    <g id="XMLID_4996_">
                      <rect
                        id="XMLID_5006_"
                        x="711.8"
                        y="248"
                        width="1.1"
                        height="8.8"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_4986_">
                <g id="XMLID_4987_">
                  <polygon
                    id="XMLID_4988_"
                    points="668.3,273.7 668.3,282.2 707.7,259.3 707.7,250.9             "
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_4949_">
              <g id="XMLID_4955_">
                <g id="XMLID_4967_">
                  <g id="XMLID_4976_">
                    <g id="XMLID_4981_">
                      <path
                        id="XMLID_4983_"
                        style="fill: #ffffff"
                        d="M723.6,277C723.6,276.9,723.6,276.9,723.6,277v-8.4l-9.3,0.2l-85.5-48.9
                  c-3.8-2.2-8.4-2.2-12.1,0l-73.5,43.2l-15.6,2.9v8.4c-0.1,1.7,0.6,3.5,2.3,4.5l92.4,52.9c3.8,2.2,8.4,2.2,12.1,0l86.8-50.9
                  C722.8,280.1,723.5,278.6,723.6,277L723.6,277L723.6,277z"
                      />
                    </g>
                    <g id="XMLID_4977_">
                      <path
                        id="XMLID_4978_"
                        d="M628.3,334c-2.2,0-4.4-0.6-6.3-1.7l-92.4-52.9c-1.8-1-2.8-3-2.6-5l0-8.9l16-3l73.4-43.1
                  c3.9-2.3,8.8-2.3,12.7,0l85.4,48.8l9.7-0.2v9.5l0,0c-0.2,1.7-1.1,3.1-2.6,4l-86.8,50.9C632.7,333.5,630.5,334,628.3,334z
                   M528,266.5v8c-0.1,1.7,0.7,3.2,2.1,4l92.4,52.9c3.6,2.1,8,2.1,11.6,0l86.8-50.9c1.3-0.7,2.1-2.1,2.1-3.5l0-0.2v-7.7
                  l-8.9,0.2l-85.6-48.9c-3.6-2.1-8-2.1-11.6,0l-73.6,43.2L528,266.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4968_">
                    <g id="XMLID_4974_">
                      <path
                        id="XMLID_4975_"
                        style="fill: #ffffff"
                        d="M529.8,270.6l92.4,52.9c3.8,2.2,8.4,2.2,12.1,0l86.8-50.9
                  c3.2-1.8,3.2-6.4,0-8.2l-92.4-52.9c-3.8-2.2-8.4-2.2-12.1,0l-86.8,50.9C526.7,264.2,526.7,268.8,529.8,270.6z"
                      />
                    </g>
                    <g id="XMLID_4969_">
                      <path
                        id="XMLID_4970_"
                        d="M628.3,325.6c-2.2,0-4.4-0.6-6.3-1.7L529.5,271c-1.7-1-2.6-2.7-2.6-4.6c0-1.9,1-3.6,2.6-4.6
                  l86.8-50.9c3.9-2.3,8.8-2.3,12.7,0l92.4,52.9c1.7,1,2.6,2.7,2.6,4.6c0,1.9-1,3.6-2.6,4.6l-86.8,50.9
                  C632.7,325,630.5,325.6,628.3,325.6z M530.1,270.1l92.4,52.9c3.6,2.1,8,2.1,11.6,0l86.8-50.9c1.3-0.8,2.1-2.1,2.1-3.6
                  c0-1.5-0.8-2.9-2.1-3.6L628.5,212c-3.6-2.1-8-2.1-11.6,0l-86.8,50.9c-1.3,0.8-2.1,2.1-2.1,3.6
                  C528,268,528.8,269.3,530.1,270.1L530.1,270.1z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4956_">
                  <g id="XMLID_4963_">
                    <g id="XMLID_4965_">
                      <rect
                        id="XMLID_4966_"
                        x="627.6"
                        y="324.9"
                        width="1.1"
                        height="8.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4960_">
                    <g id="XMLID_4961_">
                      <rect
                        id="XMLID_4962_"
                        x="632.9"
                        y="323.6"
                        width="1.1"
                        height="8.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4957_">
                    <g id="XMLID_4958_">
                      <rect
                        id="XMLID_4959_"
                        x="718.8"
                        y="273.4"
                        width="1.1"
                        height="8.8"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_4951_">
                <g id="XMLID_4952_">
                  <polygon
                    id="XMLID_4954_"
                    points="635.4,322.8 635.4,331.3 717.6,283.1 717.6,274.7             "
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_4873_">
              <g id="XMLID_4917_">
                <g id="XMLID_4938_">
                  <g id="XMLID_4946_">
                    <polygon
                      id="XMLID_4947_"
                      style="fill: #ffffff"
                      points="704.6,243.7 692.9,236.9 707,228.7 718.8,235.5               "
                    />
                  </g>
                  <g id="XMLID_4939_">
                    <path
                      id="XMLID_4941_"
                      d="M704.6,244.2l-12.6-7.3l15-8.6l12.6,7.3L704.6,244.2z M693.7,236.9l10.9,6.3l13.3-7.7l-10.9-6.3
                L693.7,236.9z"
                    />
                  </g>
                </g>
                <g id="XMLID_4928_">
                  <g id="XMLID_4935_">
                    <polygon
                      id="XMLID_4937_"
                      style="fill: #ffffff"
                      points="704.6,241.4 692.9,234.6 707,226.4 718.8,233.2               "
                    />
                  </g>
                  <g id="XMLID_4929_">
                    <path
                      id="XMLID_4931_"
                      d="M704.6,241.8l-12.6-7.3l15-8.6l12.6,7.3L704.6,241.8z M693.7,234.6l10.9,6.3l13.3-7.7l-10.9-6.3
                L693.7,234.6z"
                    />
                  </g>
                </g>
                <g id="XMLID_4918_">
                  <g id="XMLID_4925_">
                    <polygon
                      id="XMLID_4926_"
                      style="fill: #ffffff"
                      points="704.6,239 692.9,232.2 707,224 718.8,230.8               "
                    />
                  </g>
                  <g id="XMLID_4920_">
                    <path
                      id="XMLID_4921_"
                      d="M704.6,239.5l-12.6-7.3l15-8.6l12.6,7.3L704.6,239.5z M693.7,232.2l10.9,6.3l13.3-7.7l-10.9-6.3
                L693.7,232.2z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_4892_">
                <g id="XMLID_4905_">
                  <g id="XMLID_4907_">
                    <g id="XMLID_4914_">
                      <polygon
                        id="XMLID_4915_"
                        style="fill: #ffffff"
                        points="696.9,225.4 706.3,230.8 715.7,225.5 706.4,220                 "
                      />
                    </g>
                    <g id="XMLID_4908_">
                      <path
                        id="XMLID_4910_"
                        d="M706.3,231.3l-10.3-5.9l10.3-5.8l10.2,6L706.3,231.3z M697.8,225.4l8.6,4.9l8.5-4.8l-8.5-5
                  L697.8,225.4z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4894_">
                  <g id="XMLID_4895_">
                    <g id="XMLID_4902_">
                      <polygon
                        id="XMLID_4904_"
                        style="fill: #ffffff"
                        points="698.5,231.7 704,234.9 709.5,231.8 704.1,228.6                 "
                      />
                    </g>
                    <g id="XMLID_4896_">
                      <path
                        id="XMLID_4898_"
                        d="M704,235.4l-6.4-3.7l6.4-3.6l6.3,3.7L704,235.4z M699.3,231.8l4.7,2.7l4.7-2.6l-4.6-2.7
                  L699.3,231.8z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_4874_">
                <g id="XMLID_4883_">
                  <g id="XMLID_4890_">
                    <path
                      id="XMLID_4891_"
                      style="fill: #ffffff"
                      d="M703.5,220.5c-0.1,0-0.3,0.1-0.2,0.3l0.7,3.7c0,0.4,0.4,0.9,1.3,1.1
                c0.5,0.1,1,0.1,1.5,0c0.9-0.2,1.3-0.7,1.3-1.1l0.7-3.7c0-0.1-0.1-0.3-0.2-0.3H703.5z"
                    />
                  </g>
                  <g id="XMLID_4884_">
                    <path
                      id="XMLID_4885_"
                      d="M706.1,226.1c-0.3,0-0.6,0-0.8-0.1c-1-0.2-1.6-0.8-1.6-1.5l-0.7-3.7c0-0.2,0-0.4,0.2-0.6
                c0.1-0.1,0.3-0.2,0.5-0.2h5c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5l-0.7,3.7c0,0.7-0.6,1.2-1.6,1.5
                C706.6,226,706.3,226.1,706.1,226.1z M703.7,220.9l0.7,3.6c0,0.2,0.3,0.5,1,0.7c0.4,0.1,0.9,0.1,1.3,0c0.7-0.2,1-0.5,1-0.7
                l0-0.1l0.7-3.5H703.7z"
                    />
                  </g>
                </g>
                <g id="XMLID_4875_">
                  <g id="XMLID_4880_">
                    <path
                      id="XMLID_4881_"
                      style="fill: #ffffff"
                      d="M708,219.4c1.1,0.6,1.1,1.7,0,2.3c-1.1,0.6-2.9,0.6-4,0
                c-1.1-0.6-1.1-1.7,0-2.3C705.2,218.7,706.9,218.7,708,219.4z"
                    />
                  </g>
                  <g id="XMLID_4876_">
                    <path
                      id="XMLID_4877_"
                      d="M706.1,222.5c-0.8,0-1.6-0.2-2.2-0.5c-0.7-0.4-1-0.9-1-1.5c0-0.6,0.4-1.1,1-1.5
                c1.2-0.7,3.1-0.7,4.4,0l0,0l0,0c0.7,0.4,1,0.9,1,1.5c0,0.6-0.4,1.1-1,1.5C707.6,222.3,706.8,222.5,706.1,222.5z M706.1,219.3
                c-0.6,0-1.3,0.1-1.8,0.4c-0.4,0.2-0.6,0.5-0.6,0.8c0,0.3,0.2,0.6,0.6,0.8c1,0.6,2.6,0.6,3.5,0c0.4-0.2,0.6-0.5,0.6-0.8
                c0-0.3-0.2-0.6-0.6-0.8l0,0C707.4,219.4,706.7,219.3,706.1,219.3z"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="XMLID_4833_">
              <g id="XMLID_4844_">
                <g id="XMLID_4866_">
                  <g id="XMLID_4871_">
                    <path
                      id="XMLID_4872_"
                      style="fill: #ffffff"
                      d="M703.8,268.5c0,0,0-4.1,0-4.4c0,0,0-0.1-0.1-0.1l-9.1,0.8c0,0,0,0,0,0
                l-0.8-5c0,0-0.1,0-0.1,0l-8.4,4.8c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,4.3,0,4.3l0,0c0,0.3,0.2,0.5,0.4,0.7
                l8.4,4.9c0.7,0.4,1.6,0.4,2.3,0l8.6-4.9C703.7,269.1,703.8,268.8,703.8,268.5L703.8,268.5z"
                    />
                  </g>
                  <g id="XMLID_4867_">
                    <path
                      id="XMLID_4868_"
                      d="M693.7,274.9c-0.5,0-1-0.1-1.4-0.4l-8.4-4.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-3.2l0-1.1
                c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l8.3-4.7c0.2-0.1,0.3-0.1,0.5,0l0.2,0.1l0.8,4.8l8.8-0.8l0.1,0
                c0.2,0.1,0.4,0.2,0.4,0.5l0,4.5c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C694.6,274.8,694.1,274.9,693.7,274.9z M684,264.7l0,0.7
                c0,1.2,0,3,0,3.2c0,0.1,0.1,0.2,0.2,0.4l8.4,4.9c0.6,0.3,1.3,0.3,1.9,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4h0l0-4l-8.8,0.8
                l-0.2-0.1l-0.2-0.1l-0.8-4.7l-8.1,4.6l-0.2,0L684,264.7z"
                    />
                  </g>
                </g>
                <g id="XMLID_4859_">
                  <g id="XMLID_4864_">
                    <path
                      id="XMLID_4865_"
                      d="M703.8,264.1l-9.1,0.8c0,0,0,0,0,0l-1.1,3.1l0,6.5c0.4,0,0.8-0.1,1.2-0.3l8.6-4.9
                c0.3-0.2,0.4-0.5,0.4-0.7l0,0C703.8,268.5,703.8,264.5,703.8,264.1C703.8,264.1,703.8,264.1,703.8,264.1z"
                    />
                  </g>
                  <g id="XMLID_4860_">
                    <path
                      id="XMLID_4861_"
                      d="M693.7,274.9C693.6,274.9,693.6,274.9,693.7,274.9l-0.5,0l0-7.1l1.2-3.6l0.3,0.2l9-0.8l0.1,0
                c0.2,0.1,0.4,0.2,0.4,0.5l0,4.5c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C694.6,274.8,694.1,274.9,693.7,274.9z M694,268.1l0,6
                c0.2,0,0.4-0.1,0.6-0.2l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4l0-4l-8.5,0.8L694,268.1z"
                    />
                  </g>
                </g>
                <g id="XMLID_4852_">
                  <g id="XMLID_4857_">
                    <path
                      id="XMLID_4858_"
                      d="M684.9,265.4c0,0,0.1-0.3,0.3-0.8l-1.5-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,4.3,0,4.3l0,0
                c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5l0-2.3V265.4z"
                    />
                  </g>
                  <g id="XMLID_4853_">
                    <path
                      id="XMLID_4854_"
                      d="M685.3,270.5l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-3.2l0-1.1c0-0.2,0.1-0.3,0.2-0.4
                c0.1-0.1,0.3-0.1,0.4-0.1l1.9,0.5l-0.2,0.4c-0.1,0.3-0.2,0.6-0.3,0.7L685.3,270.5z M684,264.7l0,0.7c0,1.2,0,3,0,3.2
                c0,0.1,0.1,0.2,0.2,0.4l0.2,0.1l0-3.8c0,0,0.1-0.1,0.1-0.4L684,264.7z"
                    />
                  </g>
                </g>
                <g id="XMLID_4845_">
                  <g id="XMLID_4850_">
                    <path
                      id="XMLID_4851_"
                      style="fill: #ffffff"
                      d="M692.5,269.8l-8.5-4.9c-0.6-0.3-0.6-1.2,0-1.6l8.6-4.9
                c0.7-0.4,1.6-0.4,2.2,0l8.5,4.9c0.6,0.3,0.6,1.2,0,1.6l-8.6,4.9C694.1,270.2,693.2,270.2,692.5,269.8z"
                    />
                  </g>
                  <g id="XMLID_4846_">
                    <path
                      id="XMLID_4847_"
                      d="M693.7,270.6c-0.5,0-0.9-0.1-1.3-0.4l-8.5-4.9c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                l8.6-4.9c0.8-0.5,1.8-0.5,2.7,0l8.5,4.9c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-8.6,4.9
                C694.6,270.4,694.1,270.6,693.7,270.6z M693.8,258.5c-0.3,0-0.6,0.1-0.9,0.2l-8.6,4.9c-0.2,0.1-0.2,0.3-0.2,0.4
                c0,0.1,0,0.3,0.2,0.4l8.5,4.9c0.6,0.3,1.3,0.3,1.8,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.4l-8.5-4.9
                C694.4,258.6,694.1,258.5,693.8,258.5z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_4835_">
                <g id="XMLID_4836_">
                  <g id="XMLID_4842_">
                    <polygon
                      id="XMLID_4843_"
                      style="fill: #ffffff"
                      points="691.5,261.3 697.1,264.5 702.5,261.4 697.1,258.2               "
                    />
                  </g>
                  <g id="XMLID_4837_">
                    <path
                      id="XMLID_4839_"
                      d="M697.1,265l-6.4-3.7l6.4-3.6l6.3,3.7L697.1,265z M692.4,261.3l4.7,2.7l4.7-2.6l-4.6-2.7
                L692.4,261.3z"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="XMLID_4040_">
              <g id="XMLID_4587_">
                <g id="XMLID_4700_">
                  <g id="XMLID_4829_">
                    <g id="XMLID_4830_">
                      <path
                        id="XMLID_4832_"
                        d="M626.3,238.7h-1.9V231c0-1.5,0.7-3,1.8-4l8.3-7.1c0.8-0.7,1.8-0.8,2.7-0.4
                  c0.9,0.4,1.5,1.3,1.5,2.3v6.1h-1.9v-6.1c0-0.4-0.3-0.5-0.4-0.6c-0.1,0-0.4-0.1-0.7,0.1l-8.3,7.1c-0.7,0.6-1.2,1.5-1.2,2.5
                  V238.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4825_">
                    <g id="XMLID_4826_">
                      <path
                        id="XMLID_4828_"
                        d="M659.5,286.5l-1-1.7l12-7c1.2-0.7,1.9-2,1.9-3.3l0-30.6h1.9l0,30.6c0,2-1.1,3.9-2.8,5L659.5,286.5
                  z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4821_">
                    <g id="XMLID_4822_">
                      <path
                        id="XMLID_4823_"
                        d="M624.1,268.8l-1-1.7l14.7-8.6c1.2-0.7,1.9-2,1.9-3.3l0-30.6h1.9l0,30.6c0,2-1.1,3.9-2.8,5
                  L624.1,268.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4789_">
                    <g id="XMLID_4809_">
                      <g id="XMLID_4818_">
                        <path
                          id="XMLID_4819_"
                          style="fill: #ffffff"
                          d="M676.3,257.4c0,0,0-1.9,0-2.3c0,0,0-0.1-0.1-0.1l-5.8-1.6c0,0,0,0,0,0
                    l-14.2-8.3c0,0-0.1,0-0.1,0l-5,2.9c0,0,0,0-0.1,0l-1.8-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.5,0,2.2,0,2.2l0,0
                    c0,0.3,0.2,0.6,0.5,0.8l18.3,10.7c0.8,0.5,1.8,0.5,2.6,0l5.2-3C676.1,258,676.3,257.7,676.3,257.4L676.3,257.4z"
                        />
                      </g>
                      <g id="XMLID_4811_">
                        <path
                          id="XMLID_4812_"
                          d="M669.3,262c-0.5,0-1.1-0.1-1.5-0.4l-18.3-10.7c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0-0.1,0-2.3
                    c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.7,0.5l4.9-2.8c0.2-0.1,0.3-0.1,0.5,0l14.2,8.2l5.7,1.6
                    c0.2,0.1,0.4,0.3,0.4,0.5v0.5c0,0.7,0,1.8,0,1.8l0,0c0,0.5-0.3,0.9-0.7,1.1l-5.2,3C670.3,261.8,669.8,262,669.3,262z
                     M649.6,248c0,0.7,0,1.7,0,1.8c0,0.1,0.1,0.3,0.3,0.4l18.3,10.7c0.7,0.4,1.5,0.4,2.2,0l5.2-3c0.2-0.1,0.3-0.3,0.3-0.5l0,0
                    c0-0.1,0-1.1,0-1.8l0-0.3l-5.7-1.6l-14.1-8.2l-4.8,2.8c-0.1,0.1-0.3,0.1-0.4,0L649.6,248z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4800_">
                      <g id="XMLID_4807_">
                        <path
                          id="XMLID_4808_"
                          d="M676.2,255l-5.8-1.6c0,0,0,0,0,0l-1.2-0.7v8.9c0.5,0,1-0.1,1.4-0.3l5.2-3
                    c0.3-0.2,0.5-0.5,0.5-0.8l0,0C676.3,257.4,676.3,255.4,676.2,255C676.3,255,676.2,255,676.2,255z"
                        />
                      </g>
                      <g id="XMLID_4801_">
                        <path
                          id="XMLID_4803_"
                          d="M669.3,262C669.3,262,669.2,262,669.3,262l-0.5,0v-10l1.8,1l5.7,1.6c0.2,0.1,0.4,0.3,0.4,0.5v0.5
                    c0,0.7,0,1.8,0,1.8c0,0.5-0.3,0.9-0.7,1.2l-5.2,3C670.3,261.8,669.8,262,669.3,262z M669.6,253.4v7.7
                    c0.3,0,0.5-0.1,0.8-0.3l5.2-3c0.2-0.1,0.3-0.3,0.3-0.5c0-0.1,0-1.1,0-1.8l0-0.3l-5.7-1.6L669.6,253.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4790_">
                      <g id="XMLID_4797_">
                        <path
                          id="XMLID_4799_"
                          style="fill: #ffffff"
                          d="M668,258.9l-18.4-10.7c-0.7-0.4-0.7-1.4,0-1.7l5.2-3
                    c0.8-0.5,1.7-0.5,2.5,0l18.4,10.7c0.7,0.4,0.7,1.4,0,1.7l-5.2,3C669.8,259.4,668.8,259.4,668,258.9z"
                        />
                      </g>
                      <g id="XMLID_4792_">
                        <path
                          id="XMLID_4793_"
                          d="M669.3,259.7c-0.5,0-1-0.1-1.5-0.4h0l-18.4-10.7c-0.4-0.3-0.7-0.7-0.7-1.2c0-0.5,0.3-1,0.7-1.2
                    l5.2-3c0.9-0.5,2-0.5,2.9,0l18.4,10.7c0.4,0.3,0.7,0.7,0.7,1.2c0,0.5-0.3,1-0.7,1.2l-5.2,3
                    C670.3,259.5,669.8,259.7,669.3,259.7z M668.2,258.6c0.7,0.4,1.5,0.4,2.1,0l5.2-3c0.2-0.1,0.3-0.3,0.3-0.5
                    c0-0.2-0.1-0.4-0.3-0.5l-18.4-10.7c-0.7-0.4-1.5-0.4-2.1,0l-5.2,3c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.1,0.4,0.3,0.5
                    L668.2,258.6L668.2,258.6z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_4742_">
                    <g id="XMLID_4778_">
                      <g id="XMLID_4786_">
                        <path
                          id="XMLID_4787_"
                          style="fill: #ffffff"
                          d="M679.2,239.5l-3,0l-31.9-18.4l-22.2,18.3l-1.7,0l0.1,2.4l0,0
                    c0,0.7,0.4,1.3,1,1.7l32.2,18.6c0.9,0.5,2.1,0.4,2.9-0.2l22-18.1c0.4-0.3,0.6-0.8,0.6-1.3L679.2,239.5
                    C679.2,239.5,679.2,239.5,679.2,239.5z"
                        />
                      </g>
                      <g id="XMLID_4780_">
                        <path
                          id="XMLID_4781_"
                          d="M655.2,262.8c-0.5,0-1-0.1-1.5-0.4l-32.2-18.6c-0.7-0.4-1.1-1-1.2-1.8l0,0l0-0.2L620,239l2,0
                    l22.3-18.4l32,18.5l3.3,0l0,0.4l0.2,2.9c0,0.7-0.3,1.2-0.8,1.6l-22,18.1C656.5,262.6,655.8,262.8,655.2,262.8z M621,241.5
                    L621,241.5l0,0.2c0,0.6,0.3,1.1,0.8,1.3l32.2,18.6c0.8,0.5,1.8,0.4,2.5-0.2l22-18.1c0.3-0.2,0.4-0.6,0.5-1l-0.1-2.5l-2.8,0
                    l-0.1-0.1l-31.6-18.3l-22.1,18.2l-1.4,0L621,241.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4769_">
                      <g id="XMLID_4776_">
                        <path
                          id="XMLID_4777_"
                          d="M656.8,261.8l22-18.1c0.4-0.3,0.6-0.8,0.6-1.3l-0.2-2.9c0,0,0,0,0,0l-3,0l-1.6-0.9l-16.3,10.9
                    l-3.2,6.5v6.5C655.8,262.4,656.3,262.2,656.8,261.8z"
                        />
                      </g>
                      <g id="XMLID_4770_">
                        <path
                          id="XMLID_4771_"
                          d="M654.8,262.8v-7l0-0.1l3.2-6.6l16.6-11.1l1.7,1l3.3,0l0,0.4l0.2,2.9c0,0.7-0.3,1.2-0.8,1.6
                    l-22,18.1c-0.5,0.4-1.2,0.7-1.9,0.7L654.8,262.8z M655.6,256.1v5.9c0.3-0.1,0.7-0.2,0.9-0.4h0l22-18.1
                    c0.3-0.2,0.4-0.6,0.5-1l-0.1-2.5l-2.8,0l-1.5-0.8l-15.9,10.7L655.6,256.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4762_">
                      <g id="XMLID_4766_">
                        <path
                          id="XMLID_4767_"
                          d="M622.5,239.1l-0.3,0.2l-1.7,0l0.1,2.4l0,0c0,0.7,0.4,1.3,1,1.7l0.1-0.1l0,0l3.6,2.3L622.5,239.1z
                    "
                        />
                      </g>
                      <g id="XMLID_4763_">
                        <path
                          id="XMLID_4765_"
                          d="M625.1,245.9l-3.2-2.1l-0.1,0.1l-0.4-0.2c-0.7-0.4-1.1-1-1.2-1.8l0,0l0-0.2l-0.2-2.8l2,0l0.2-0.1
                    l0.5,0.6l-0.4,0.3l-1.4,0l0.1,1.9c0,0.4,0.2,0.8,0.5,1.1l0-0.1l4,2.5L625.1,245.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4752_">
                      <g id="XMLID_4759_">
                        <path
                          id="XMLID_4761_"
                          style="fill: #ffffff"
                          d="M653.7,259.5l-32.2-18.6c-1.3-0.7-1.4-2.5-0.3-3.4l21.7-17.9
                    c0.8-0.6,1.8-0.7,2.7-0.2l32.8,19c1,0.6,1.2,2,0.2,2.8l-22,18.1C655.8,259.9,654.7,260,653.7,259.5z"
                        />
                      </g>
                      <g id="XMLID_4754_">
                        <path
                          id="XMLID_4755_"
                          d="M655,260.2c-0.5,0-1-0.1-1.5-0.4l-32.2-18.6c-0.7-0.4-1.2-1.1-1.2-1.9c-0.1-0.8,0.3-1.6,0.9-2.1
                    l21.7-17.9c0.9-0.7,2.1-0.8,3.1-0.3l32.8,19c0.6,0.3,1,1,1.1,1.7c0.1,0.7-0.2,1.4-0.8,1.8l-22,18.1
                    C656.4,260,655.7,260.2,655,260.2z M644.4,219.5c-0.4,0-0.9,0.1-1.2,0.4l-21.7,17.9c-0.4,0.4-0.6,0.9-0.6,1.4
                    c0,0.5,0.3,1,0.8,1.3l32.2,18.6c0.8,0.5,1.8,0.4,2.5-0.2l22-18.1c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.3-0.8-0.6-1l-32.8-19
                    C645.1,219.5,644.7,219.5,644.4,219.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4743_">
                      <g id="XMLID_4750_">
                        <path
                          id="XMLID_4751_"
                          style="fill: #ffffff"
                          d="M654.1,257.3l-30.7-17.8c-0.3-0.2-0.3-0.5-0.1-0.7l20.7-17.2
                    c0.3-0.2,0.7-0.3,1-0.1l31,17.9c0.2,0.1,0.3,0.5,0.1,0.7l-20.8,17.3C655,257.5,654.5,257.5,654.1,257.3z"
                        />
                      </g>
                      <g id="XMLID_4745_">
                        <path
                          id="XMLID_4746_"
                          d="M654.7,257.9c-0.3,0-0.5-0.1-0.7-0.2l-30.7-17.8c-0.2-0.1-0.4-0.4-0.4-0.7c0-0.3,0.1-0.6,0.3-0.7
                    l20.7-17.2c0.4-0.4,1-0.4,1.5-0.1l31,17.9c0.2,0.1,0.4,0.4,0.4,0.6c0,0.3-0.1,0.5-0.3,0.7l-20.8,17.3
                    C655.3,257.8,655,257.9,654.7,257.9z M623.6,239.1l30.7,17.8c0.2,0.1,0.5,0.1,0.7-0.1l20.8-17.3l-31-17.8
                    c-0.2-0.1-0.4-0.1-0.6,0L623.6,239.1l-0.2,0.4L623.6,239.1z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_4702_">
                    <g id="XMLID_4732_">
                      <g id="XMLID_4739_">
                        <path
                          id="XMLID_4740_"
                          style="fill: #ffffff"
                          d="M676.9,234.1c0,0,0-1.9,0-2.3c0,0,0-0.1-0.1-0.1l-5.8-1.6c0,0,0,0,0,0
                    l-14.2-8.3c0,0-0.1,0-0.1,0l-5,2.9c0,0,0,0-0.1,0l-1.8-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.5,0,2.2,0,2.2l0,0
                    c0,0.3,0.2,0.6,0.5,0.8l18.3,10.7c0.8,0.5,1.8,0.5,2.6,0l5.2-3C676.7,234.7,676.9,234.4,676.9,234.1L676.9,234.1z"
                        />
                      </g>
                      <g id="XMLID_4734_">
                        <path
                          id="XMLID_4735_"
                          d="M669.9,238.7c-0.5,0-1.1-0.1-1.5-0.4l-18.3-10.7c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0-0.1,0-2.3
                    c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.7,0.5l4.9-2.8c0.2-0.1,0.3-0.1,0.5,0l14.2,8.2l5.7,1.6
                    c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,2.3,0,2.3l0,0c0,0.5-0.3,0.9-0.7,1.1l-5.2,3C670.9,238.5,670.4,238.7,669.9,238.7z
                     M650.2,224.7c0,0.7,0,1.7,0,1.8c0,0.1,0.1,0.3,0.3,0.4l18.3,10.7c0.7,0.4,1.5,0.4,2.2,0l5.2-3c0.2-0.1,0.3-0.3,0.3-0.5
                    l0,0c0-0.1,0-1.4,0-2.1l-5.7-1.6l-14.1-8.2l-4.8,2.8c-0.1,0.1-0.3,0.1-0.4,0L650.2,224.7z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4722_">
                      <g id="XMLID_4729_">
                        <path
                          id="XMLID_4731_"
                          d="M676.8,231.7l-5.8-1.6c0,0,0,0,0,0l-1.2-0.7v8.9c0.5,0,1-0.1,1.4-0.3l5.2-3
                    c0.3-0.2,0.5-0.5,0.5-0.8l0,0C676.9,234.1,676.9,232.1,676.8,231.7C676.9,231.7,676.9,231.7,676.8,231.7z"
                        />
                      </g>
                      <g id="XMLID_4724_">
                        <path
                          id="XMLID_4725_"
                          d="M669.9,238.7C669.9,238.7,669.8,238.7,669.9,238.7l-0.5,0v-10l1.8,1l5.7,1.6
                    c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,2.3,0,2.3c0,0.5-0.3,0.9-0.7,1.2l-5.2,3C671,238.5,670.4,238.7,669.9,238.7z M670.2,230.1
                    v7.7c0.3,0,0.5-0.1,0.8-0.3l5.2-3c0.2-0.1,0.3-0.3,0.3-0.5c0-0.1,0-1.4,0-2.1l-5.7-1.6L670.2,230.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4713_">
                      <g id="XMLID_4720_">
                        <path
                          id="XMLID_4721_"
                          d="M651.2,225.5c0,0,0.1-0.4,0.3-0.9l-1.7-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.5,0,2.2,0,2.2l0,0
                    c0,0.3,0.2,0.6,0.5,0.8l1,0.6l0,0V225.5z"
                        />
                      </g>
                      <g id="XMLID_4714_">
                        <path
                          id="XMLID_4715_"
                          d="M651.6,228.5l-1.6-0.9c-0.4-0.2-0.6-0.6-0.7-1.1c0,0,0-0.1,0-2.3c0-0.2,0.1-0.3,0.2-0.4
                    c0.1-0.1,0.3-0.1,0.4-0.1l2.1,0.6l-0.1,0.4c-0.1,0.4-0.2,0.7-0.3,0.8V228.5z M650.2,224.6c0,0.7,0,1.7,0,1.8
                    c0,0.1,0.1,0.3,0.3,0.4l0.3,0.2l0-1.7c0,0,0.1-0.2,0.2-0.4L650.2,224.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4703_">
                      <g id="XMLID_4710_">
                        <path
                          id="XMLID_4711_"
                          style="fill: #ffffff"
                          d="M668.6,235.6l-18.4-10.7c-0.7-0.4-0.7-1.4,0-1.7l5.2-3
                    c0.8-0.5,1.7-0.5,2.5,0l18.4,10.7c0.7,0.4,0.7,1.4,0,1.7l-5.2,3C670.4,236.1,669.4,236.1,668.6,235.6z"
                        />
                      </g>
                      <g id="XMLID_4705_">
                        <path
                          id="XMLID_4706_"
                          d="M669.9,236.4c-0.5,0-1-0.1-1.5-0.4l-18.4-10.7c-0.4-0.3-0.7-0.7-0.7-1.2c0-0.5,0.3-1,0.7-1.2
                    l5.2-3c0.9-0.5,2-0.5,2.9,0l18.4,10.7c0.4,0.3,0.7,0.7,0.7,1.2c0,0.5-0.3,1-0.7,1.2l-5.2,3
                    C670.9,236.3,670.4,236.4,669.9,236.4z M656.8,220.3c-0.4,0-0.7,0.1-1.1,0.3l-5.2,3c-0.2,0.1-0.3,0.3-0.3,0.5
                    c0,0.2,0.1,0.4,0.3,0.5l18.4,10.7c0.6,0.4,1.5,0.4,2.1,0l5.2-3c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5l-18.4-10.7
                    C657.5,220.4,657.1,220.3,656.8,220.3z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_4680_">
                  <g id="XMLID_4691_">
                    <g id="XMLID_4698_">
                      <polygon
                        id="XMLID_4699_"
                        style="fill: #ffffff"
                        points="633.6,238.9 649.6,248 659.9,239.5 644,230.2                 "
                      />
                    </g>
                    <g id="XMLID_4692_">
                      <path
                        id="XMLID_4693_"
                        d="M649.6,248.6l-16.8-9.6l11.1-9.2l16.7,9.7L649.6,248.6z M634.3,238.8l15.2,8.8l9.7-8l-15.2-8.8
                  L634.3,238.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4681_">
                    <g id="XMLID_4688_">
                      <polygon
                        id="XMLID_4689_"
                        style="fill: #ffffff"
                        points="629.4,238.4 645.4,247.6 655.8,239 639.9,229.7                 "
                      />
                    </g>
                    <g id="XMLID_4682_">
                      <path
                        id="XMLID_4684_"
                        d="M645.5,248.1l-16.8-9.6l11.1-9.2l16.7,9.7L645.5,248.1z M630.1,238.3l15.2,8.8l9.7-8l-15.2-8.8
                  L630.1,238.3z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4659_">
                  <g id="XMLID_4670_">
                    <g id="XMLID_4677_">
                      <path
                        id="XMLID_4678_"
                        style="fill: #ffffff"
                        d="M637,232.1l2.9,1.7c0.5,0.3,1,0.2,1.4-0.1l5.1-4.4c0.4-0.3,0.3-0.9-0.1-1.1
                  l-2.8-1.8c-0.5-0.3-1-0.2-1.5,0.1l-5.2,4.4C636.5,231.3,636.6,231.8,637,232.1z"
                      />
                    </g>
                    <g id="XMLID_4671_">
                      <path
                        id="XMLID_4673_"
                        d="M640.5,234.4c-0.3,0-0.6-0.1-0.8-0.2l-2.9-1.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.1-0.7,0.4-0.9
                  l5.2-4.4c0.5-0.5,1.3-0.5,1.9-0.1l2.8,1.8c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.1,0.7-0.4,0.9l-5.1,4.4
                  C641.3,234.2,640.9,234.4,640.5,234.4z M637.2,231.7l2.9,1.7c0.3,0.2,0.7,0.1,1-0.1l5.1-4.4c0.1-0.1,0.1-0.2,0.1-0.2
                  c0,0,0-0.1-0.1-0.2l-2.8-1.8c-0.3-0.2-0.7-0.2-1,0.1l-5.2,4.4c-0.1,0.1-0.1,0.2-0.1,0.2
                  C637.1,231.6,637.1,231.7,637.2,231.7L637.2,231.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4661_">
                    <g id="XMLID_4667_">
                      <path
                        id="XMLID_4669_"
                        style="fill: #ffffff"
                        d="M637,231.3l2.9,1.7c0.5,0.3,1,0.2,1.4-0.1l5.1-4.4c0.4-0.3,0.3-0.9-0.1-1.1
                  l-2.8-1.8c-0.5-0.3-1-0.2-1.5,0.1l-5.2,4.4C636.5,230.5,636.6,231,637,231.3z"
                      />
                    </g>
                    <g id="XMLID_4662_">
                      <path
                        id="XMLID_4663_"
                        d="M640.5,233.6c-0.3,0-0.6-0.1-0.8-0.2l-2.9-1.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.1-0.7,0.4-0.9
                  l5.2-4.4c0.5-0.5,1.3-0.5,1.9-0.1l2.8,1.8c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.1,0.7-0.4,0.9l-5.1,4.4
                  C641.3,233.5,640.9,233.6,640.5,233.6z M637.2,230.9l2.9,1.7c0.3,0.2,0.7,0.1,1-0.1l5.1-4.4c0.1-0.1,0.1-0.2,0.1-0.2
                  c0,0,0-0.1-0.1-0.2l-2.8-1.8c-0.3-0.2-0.7-0.2-1,0.1l-5.2,4.4c-0.1,0.1-0.1,0.2-0.1,0.2
                  C637.1,230.8,637.1,230.9,637.2,230.9L637.2,230.9z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4638_">
                  <g id="XMLID_4649_">
                    <g id="XMLID_4656_">
                      <path
                        id="XMLID_4658_"
                        style="fill: #ffffff"
                        d="M650.6,252.2l2.9,1.7c0.5,0.3,1,0.2,1.4-0.1l1.1-0.8
                  c0.4-0.3,0.3-0.9-0.1-1.1l-2.8-1.8c-0.5-0.3-1-0.2-1.5,0.1l-1.1,0.8C650.2,251.4,650.2,252,650.6,252.2z"
                      />
                    </g>
                    <g id="XMLID_4651_">
                      <path
                        id="XMLID_4652_"
                        d="M654.2,254.5c-0.3,0-0.6-0.1-0.8-0.2l-2.9-1.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.1-0.7,0.4-0.9
                  l1.1-0.8c0.5-0.4,1.3-0.5,1.9-0.1l2.8,1.8c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.1,0.7-0.4,0.9l-1.1,0.8
                  C654.9,254.4,654.6,254.5,654.2,254.5z M652.5,250.4c-0.2,0-0.4,0.1-0.5,0.2l-1.1,0.8c-0.1,0.1-0.1,0.2-0.1,0.2
                  c0,0,0,0.1,0.1,0.2l2.9,1.7c0.3,0.2,0.7,0.1,1-0.1l1.1-0.8c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-2.8-1.8
                  C652.8,250.5,652.6,250.4,652.5,250.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4640_">
                    <g id="XMLID_4647_">
                      <path
                        id="XMLID_4648_"
                        style="fill: #ffffff"
                        d="M650.6,251.4l2.9,1.7c0.5,0.3,1,0.2,1.4-0.1l1.1-0.8
                  c0.4-0.3,0.3-0.9-0.1-1.1l-2.8-1.8c-0.5-0.3-1-0.2-1.5,0.1l-1.1,0.8C650.2,250.6,650.2,251.2,650.6,251.4z"
                      />
                    </g>
                    <g id="XMLID_4641_">
                      <path
                        id="XMLID_4642_"
                        d="M654.2,253.7c-0.3,0-0.6-0.1-0.8-0.2l-2.9-1.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.1-0.7,0.4-0.9
                  l1.1-0.8c0.5-0.4,1.3-0.5,1.9-0.1l2.8,1.8c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.1,0.7-0.4,0.9l-1.1,0.8
                  C654.9,253.6,654.6,253.7,654.2,253.7z M650.9,251.1l2.9,1.7c0.3,0.2,0.7,0.1,1-0.1l1.1-0.8c0.1-0.1,0.1-0.2,0.1-0.2
                  c0,0,0-0.1-0.1-0.2l-2.8-1.8c-0.3-0.2-0.7-0.2-1,0.1l-1.1,0.8c-0.1,0.1-0.1,0.2-0.1,0.2C650.7,250.9,650.8,251,650.9,251.1
                  L650.9,251.1z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4618_">
                  <g id="XMLID_4630_">
                    <g id="XMLID_4636_">
                      <path
                        id="XMLID_4637_"
                        style="fill: #ffffff"
                        d="M648.6,235.9l2.9,1.7c0.5,0.3,1,0.2,1.4-0.1l1.1-0.8
                  c0.4-0.3,0.3-0.9-0.1-1.1l-2.8-1.8c-0.5-0.3-1-0.2-1.5,0.1l-1.1,0.8C648.2,235.1,648.2,235.6,648.6,235.9z"
                      />
                    </g>
                    <g id="XMLID_4631_">
                      <path
                        id="XMLID_4632_"
                        d="M652.2,238.2c-0.3,0-0.6-0.1-0.8-0.2l-2.9-1.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.1-0.7,0.4-0.9
                  l1.1-0.8c0.5-0.4,1.3-0.5,1.9-0.1l2.8,1.8c0.3,0.2,0.5,0.5,0.5,0.8s-0.1,0.7-0.4,0.9l-1.1,0.8
                  C652.9,238.1,652.5,238.2,652.2,238.2z M650.4,234.1c-0.2,0-0.4,0.1-0.5,0.2l-1.1,0.8c-0.1,0.1-0.1,0.2-0.1,0.2
                  c0,0,0,0.1,0.1,0.2l2.9,1.7c0.3,0.2,0.7,0.1,1-0.1l1.1-0.8c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-2.8-1.8
                  C650.7,234.1,650.6,234.1,650.4,234.1z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4620_">
                    <g id="XMLID_4627_">
                      <path
                        id="XMLID_4628_"
                        style="fill: #ffffff"
                        d="M648.6,235.1l2.9,1.7c0.5,0.3,1,0.2,1.4-0.1l1.1-0.8
                  c0.4-0.3,0.3-0.9-0.1-1.1l-2.8-1.8c-0.5-0.3-1-0.2-1.5,0.1l-1.1,0.8C648.2,234.3,648.2,234.9,648.6,235.1z"
                      />
                    </g>
                    <g id="XMLID_4621_">
                      <path
                        id="XMLID_4623_"
                        d="M652.2,237.4c-0.3,0-0.6-0.1-0.8-0.2l-2.9-1.7c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.4,0.1-0.7,0.4-0.9
                  l1.1-0.8c0.5-0.4,1.3-0.5,1.9-0.1l2.8,1.8c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.1,0.7-0.4,0.9l-1.1,0.8
                  C652.9,237.3,652.5,237.4,652.2,237.4z M650.4,233.3c-0.2,0-0.4,0.1-0.5,0.2l-1.1,0.8c-0.1,0.1-0.1,0.2-0.1,0.2
                  c0,0,0,0.1,0.1,0.2l2.9,1.7c0.3,0.2,0.7,0.1,1-0.1l1.1-0.8c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-2.8-1.8
                  C650.7,233.3,650.6,233.3,650.4,233.3z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4609_">
                  <g id="XMLID_4614_">
                    <g id="XMLID_4616_">
                      <rect
                        id="XMLID_4617_"
                        x="656.1"
                        y="243.4"
                        transform="matrix(0.8916 -0.4528 0.4528 0.8916 -38.8779 325.4784)"
                        width="8.7"
                        height="1"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4610_">
                    <g id="XMLID_4612_">
                      <rect
                        id="XMLID_4613_"
                        x="660.8"
                        y="242.9"
                        transform="matrix(0.6553 -0.7554 0.7554 0.6553 45.34 586.1725)"
                        width="8.2"
                        height="1"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4588_">
                  <g id="XMLID_4599_">
                    <g id="XMLID_4606_">
                      <path
                        id="XMLID_4608_"
                        style="fill: #ffffff"
                        d="M670.9,251.4c-0.1,0-0.3,0.1-0.2,0.3l0.7,3.7c0,0.4,0.4,0.9,1.3,1.1
                  c0.5,0.1,1,0.1,1.5,0c0.9-0.2,1.3-0.7,1.3-1.1l0.7-3.7c0-0.1-0.1-0.3-0.2-0.3H670.9z"
                      />
                    </g>
                    <g id="XMLID_4601_">
                      <path
                        id="XMLID_4602_"
                        d="M673.4,257c-0.3,0-0.6,0-0.8-0.1c-1-0.2-1.6-0.8-1.6-1.5l-0.7-3.7c0-0.2,0-0.4,0.2-0.6
                  c0.1-0.1,0.3-0.2,0.5-0.2h5c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5l-0.7,3.7c0,0.7-0.6,1.2-1.6,1.5
                  C674,256.9,673.7,257,673.4,257z M671.1,251.8l0.7,3.6c0,0.2,0.3,0.5,1,0.7c0.4,0.1,0.9,0.1,1.3,0c0.7-0.2,1-0.5,1-0.7
                  l0-0.1l0.7-3.5H671.1z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4590_">
                    <g id="XMLID_4596_">
                      <path
                        id="XMLID_4598_"
                        style="fill: #ffffff"
                        d="M675.4,250.2c1.1,0.6,1.1,1.7,0,2.3c-1.1,0.6-2.9,0.6-4,0
                  c-1.1-0.6-1.1-1.7,0-2.3C672.5,249.6,674.3,249.6,675.4,250.2z"
                      />
                    </g>
                    <g id="XMLID_4591_">
                      <path
                        id="XMLID_4592_"
                        d="M673.4,253.4c-0.8,0-1.6-0.2-2.2-0.5c-0.7-0.4-1-0.9-1-1.5c0-0.6,0.4-1.1,1-1.5
                  c1.2-0.7,3.1-0.7,4.4,0l0,0c0.7,0.4,1,0.9,1,1.5c0,0.6-0.4,1.1-1,1.5C675,253.2,674.2,253.4,673.4,253.4z M673.4,250.2
                  c-0.6,0-1.3,0.1-1.8,0.4c-0.4,0.2-0.6,0.5-0.6,0.8c0,0.3,0.2,0.6,0.6,0.8c1,0.6,2.6,0.6,3.5,0c0.4-0.2,0.6-0.5,0.6-0.8
                  c0-0.3-0.2-0.6-0.6-0.8C674.7,250.3,674.1,250.2,673.4,250.2z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_4200_">
                <g id="XMLID_4530_">
                  <g id="XMLID_4577_">
                    <g id="XMLID_4584_">
                      <path
                        id="XMLID_4586_"
                        style="fill: #ffffff"
                        d="M627.8,281.3L627.8,281.3c-0.3,1.2-0.4,2.4-0.3,3.6l0,0c0,0,2.2,1,3.9,0.2
                  c1.6-0.8,3.1,0.1,3.2,0.2c0.1,0,4.4-0.6,4.5-0.5c0,0.1,1-1.8-0.5-2.5c-1.5-0.7-5-2-5-2l-1.8-0.6l-3.1,0.9
                  C628.2,280.6,627.9,280.9,627.8,281.3z"
                      />
                    </g>
                    <g id="XMLID_4579_">
                      <path
                        id="XMLID_4580_"
                        d="M629.9,285.9c-1.3,0-2.5-0.5-2.6-0.6l-0.2-0.1l0-0.2c-0.1-1.3,0-2.5,0.3-3.7v0
                  c0.1-0.5,0.6-1,1.1-1.1l3.2-0.9l1.9,0.7c0.1,0,3.5,1.4,5,2.1c0.9,0.4,1.2,1.2,1,2.2c-0.1,0.4-0.3,1.1-0.7,1.1l-0.3,0l0,0
                  c-0.5,0-1.6,0.2-2.4,0.3c-1.8,0.2-1.8,0.2-1.9,0.2l-0.1,0c-0.9-0.5-1.9-0.5-2.8-0.1C631,285.8,630.4,285.9,629.9,285.9z
                   M627.9,284.6c0.6,0.2,2.1,0.7,3.3,0.1c1.1-0.5,2.3-0.5,3.5,0.1c0.2,0,0.9-0.1,1.5-0.2c1.4-0.2,2.2-0.3,2.6-0.3
                  c0.1-0.2,0.2-0.7,0.1-1.1c-0.1-0.3-0.3-0.5-0.6-0.6c-1.5-0.7-4.9-2-5-2l-1.7-0.6l-3,0.9c-0.3,0.1-0.4,0.3-0.5,0.5l0,0
                  C627.9,282.5,627.8,283.6,627.9,284.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4567_">
                    <g id="XMLID_4574_">
                      <path
                        id="XMLID_4576_"
                        style="fill: #ffffff"
                        d="M628.1,277.8l0.3,2.3c0,0,0.9,1.3,2.6,0.9c1.7-0.5,0.5-4.2,0.5-4.2
                  L628.1,277.8z"
                      />
                    </g>
                    <g id="XMLID_4569_">
                      <path
                        id="XMLID_4570_"
                        d="M630.3,281.4c-1.1,0-1.9-0.7-2.2-1.2l0-0.1l-0.4-2.7l4.2-1.3l0.1,0.4c0.1,0.3,0.8,2.7,0.1,4
                  c-0.2,0.4-0.5,0.6-0.9,0.7C630.8,281.4,630.5,281.4,630.3,281.4z M628.8,279.9c0.2,0.2,0.9,1,2.1,0.6
                  c0.2-0.1,0.3-0.2,0.4-0.3c0.4-0.7,0.1-2.1-0.1-2.9l-2.7,0.8L628.8,279.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4557_">
                    <g id="XMLID_4564_">
                      <path
                        id="XMLID_4566_"
                        d="M635.6,259.3l-8,3c-0.3,1.1-0.5,2.5-0.3,3.8c0.5,3.5,0.4,8.5,0.1,11.8c0,0,1.8,2.1,4.5,0.2
                  c0,0,1.9-13.6,3.5-18.3C635.5,259.6,635.6,259.5,635.6,259.3z"
                      />
                    </g>
                    <g id="XMLID_4558_">
                      <path
                        id="XMLID_4560_"
                        d="M629.7,279.3c-1.6,0-2.6-1.1-2.6-1.2L627,278l0-0.2c0.3-3.2,0.4-8.2-0.1-11.7
                  c-0.2-1.2-0.1-2.6,0.3-4l0.1-0.2l8.9-3.3l-0.3,0.9c0,0.1-0.1,0.2-0.1,0.4c-1.6,4.6-3.5,18.1-3.5,18.2l0,0.2l-0.1,0.1
                  C631.2,279.1,630.4,279.3,629.7,279.3z M627.9,277.7c0.4,0.4,1.7,1.4,3.7,0.1c0.2-1.7,1.9-13.2,3.4-17.9l-7,2.6
                  c-0.3,1.2-0.4,2.4-0.2,3.5C628.3,269.5,628.1,274.5,627.9,277.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4542_">
                    <g id="XMLID_4552_">
                      <g id="XMLID_4554_">
                        <rect
                          id="XMLID_4555_"
                          x="635.7"
                          y="281.6"
                          transform="matrix(0.3101 -0.9507 0.9507 0.3101 170.9145 799.502)"
                          width="1.2"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4548_">
                      <g id="XMLID_4549_">
                        <rect
                          id="XMLID_4551_"
                          x="634.2"
                          y="281.1"
                          transform="matrix(0.3107 -0.9505 0.9505 0.3107 169.9418 797.4093)"
                          width="1.2"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4543_">
                      <g id="XMLID_4545_">
                        <rect
                          id="XMLID_4546_"
                          x="632.9"
                          y="280.5"
                          transform="matrix(0.311 -0.9504 0.9504 0.311 169.5213 795.6143)"
                          width="1.2"
                          height="0.8"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_4531_">
                    <g id="XMLID_4539_">
                      <path
                        id="XMLID_4540_"
                        style="fill: #ffffff"
                        d="M639,284.8c0,0.1,0.5-0.9,0.3-1.7c-3.1,0.9-10.5,0.5-11.8,0.4
                  c0,0.5,0,0.9,0,1.4c0,0,2.2,1,3.9,0.2c1.6-0.8,3.1,0.1,3.2,0.2C634.6,285.4,638.9,284.7,639,284.8z"
                      />
                    </g>
                    <g id="XMLID_4533_">
                      <path
                        id="XMLID_4534_"
                        d="M629.9,285.9c-1.3,0-2.5-0.5-2.6-0.6l-0.2-0.1l0-0.2c0-0.4,0-0.9,0-1.5l0-0.4l0.4,0
                  c0.9,0.1,8.6,0.5,11.7-0.4l0.4-0.1l0.1,0.4c0.3,0.9-0.2,1.9-0.4,2.1l-0.2,0.2l-0.3-0.1c-0.3,0-1.7,0.2-2.6,0.3
                  c-1.8,0.2-1.8,0.2-1.9,0.2l-0.1,0c-0.9-0.5-1.9-0.5-2.8-0.1C631,285.8,630.4,285.9,629.9,285.9z M627.9,284.6
                  c0.6,0.2,2.1,0.7,3.3,0.1c1.1-0.5,2.3-0.5,3.5,0.1c0.2,0,0.9-0.1,1.5-0.2c1.4-0.2,2.2-0.3,2.6-0.3c0.1-0.2,0.2-0.5,0.2-0.8
                  c-3.1,0.7-9.1,0.5-11.1,0.4C627.8,284.2,627.9,284.4,627.9,284.6z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4480_">
                  <g id="XMLID_4526_">
                    <g id="XMLID_4527_">
                      <path
                        id="XMLID_4528_"
                        d="M618.3,299.7l-1-1.6l10.8-6.5c1.3-0.8,2-2.3,1.8-3.8l-1.6-10.6l1.9-0.3l1.6,10.6
                  c0.3,2.3-0.7,4.5-2.7,5.7L618.3,299.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4522_">
                    <g id="XMLID_4523_">
                      <path
                        id="XMLID_4524_"
                        d="M602.9,290.5l-1-1.6l11.2-6.7c1.2-0.7,1.9-2,2-3.3l0.4-10.3l1.9,0.1l-0.4,10.3
                  c-0.1,2-1.2,3.9-2.9,4.9L602.9,290.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4481_">
                    <g id="XMLID_4512_">
                      <g id="XMLID_4519_">
                        <path
                          id="XMLID_4521_"
                          style="fill: #ffffff"
                          d="M635.3,273.5c0,0,0-1.7,0-2.1c0,0,0-0.1-0.1-0.1l-5.2-1.4c0,0,0,0,0,0
                    l-12.7-7.4c0,0-0.1,0-0.1,0l-12.2,7c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,1.9,0,2l0,0c0,0.3,0.2,0.5,0.4,0.7
                    l16.4,9.5c0.7,0.4,1.6,0.4,2.3,0l12.4-7.1C635.2,274,635.3,273.8,635.3,273.5L635.3,273.5z"
                        />
                      </g>
                      <g id="XMLID_4514_">
                        <path
                          id="XMLID_4515_"
                          d="M621.3,282c-0.5,0-1-0.1-1.4-0.4l-16.4-9.5c-0.4-0.2-0.6-0.6-0.6-1c0,0,0-0.1,0-2
                    c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l12.1-6.9c0.2-0.1,0.3-0.1,0.5,0l12.7,7.4l5.1,1.4
                    c0.2,0.1,0.4,0.2,0.4,0.5v0.5c0,0.6,0,1.6,0,1.6l0,0c0,0.4-0.3,0.8-0.7,1.1l-12.4,7.1C622.3,281.9,621.8,282,621.3,282z
                     M603.7,269.5c0,0.6,0,1.4,0,1.5c0,0.1,0.1,0.3,0.2,0.4l16.4,9.5c0.6,0.3,1.3,0.3,1.9,0l12.4-7.1c0.2-0.1,0.2-0.3,0.2-0.4
                    l0,0c0-0.1,0-1,0-1.6l0-0.2l-5.1-1.4l-12.6-7.3l-12.2,7l-0.2,0L603.7,269.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4503_">
                      <g id="XMLID_4510_">
                        <path
                          id="XMLID_4511_"
                          d="M604.6,269.3l-1.3-0.3c0,0-0.1,0-0.1,0.1c0,0.4,0,1.9,0,2l0,0c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5
                    V269.3z"
                        />
                      </g>
                      <g id="XMLID_4504_">
                        <path
                          id="XMLID_4505_"
                          d="M605,273l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-1.5,0-2c0-0.2,0.1-0.3,0.2-0.4
                    c0.1-0.1,0.3-0.1,0.4-0.1l1.6,0.4V273z M603.7,269.5c0,0.6,0,1.4,0,1.5c0,0.1,0.1,0.3,0.2,0.4l0.3,0.2v-1.9L603.7,269.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4493_">
                      <g id="XMLID_4500_">
                        <path
                          id="XMLID_4501_"
                          d="M635.3,271.3l-5.2-1.4c0,0,0,0,0,0l-8.8,6.5v5.3c0.4,0,0.9-0.1,1.2-0.3l12.4-7.1
                    c0.3-0.2,0.4-0.5,0.4-0.7l0,0C635.3,273.4,635.3,271.7,635.3,271.3C635.3,271.3,635.3,271.3,635.3,271.3z"
                        />
                      </g>
                      <g id="XMLID_4494_">
                        <path
                          id="XMLID_4496_"
                          d="M621.3,282C621.3,282,621.2,282,621.3,282l-0.5,0v-5.9l9.2-6.8l0.2,0.1l5.1,1.4
                    c0.2,0.1,0.4,0.2,0.4,0.5v0.5c0,0.6,0,1.6,0,1.6c0,0.5-0.3,0.9-0.7,1.1l-12.4,7.1C622.3,281.9,621.8,282,621.3,282z
                     M621.6,276.5v4.6c0.2,0,0.4-0.1,0.6-0.2l12.4-7.1c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-1,0-1.6l0-0.2l-4.8-1.3L621.6,276.5z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4483_">
                      <g id="XMLID_4490_">
                        <path
                          id="XMLID_4491_"
                          style="fill: #ffffff"
                          d="M620.2,279.2l-16.4-9.6c-0.6-0.3-0.6-1.2,0-1.6l12.5-7.1
                    c0.7-0.4,1.6-0.4,2.3,0l16.4,9.6c0.6,0.3,0.6,1.2,0,1.6l-12.5,7.1C621.7,279.6,620.9,279.6,620.2,279.2z"
                        />
                      </g>
                      <g id="XMLID_4484_">
                        <path
                          id="XMLID_4485_"
                          d="M621.3,280c-0.5,0-0.9-0.1-1.3-0.4l-16.4-9.6c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                    l12.5-7.1c0.8-0.5,1.9-0.5,2.7,0l16.4,9.6c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-12.5,7.1
                    C622.2,279.8,621.8,280,621.3,280z M617.3,261.1c-0.3,0-0.6,0.1-0.9,0.2l-12.5,7.1c-0.2,0.1-0.2,0.3-0.2,0.4
                    c0,0.1,0,0.3,0.2,0.4l16.4,9.6c0.6,0.3,1.3,0.3,1.8,0l12.5-7.1c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.4l-16.4-9.6
                    C618,261.2,617.6,261.1,617.3,261.1z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_4449_">
                  <g id="XMLID_4470_">
                    <g id="XMLID_4477_">
                      <path
                        id="XMLID_4479_"
                        style="fill: #ffffff"
                        d="M628.9,238.4l2.1-1.2c0.8-0.5,1.7-0.7,2.6-0.6l0,0c1.2,0.1,1.9,0.3,2.5,1.3
                  l0.1,0.5l-2,0.6l-0.1,0.3l0.7,0.3c0,0-0.6,1.9-4.3,1.5C627.1,240.7,628.9,238.4,628.9,238.4z"
                      />
                    </g>
                    <g id="XMLID_4472_">
                      <path
                        id="XMLID_4473_"
                        d="M631.5,241.6c-0.3,0-0.7,0-1.1-0.1c-1.3-0.2-2-0.6-2.3-1.3c-0.4-1,0.5-2,0.5-2.1l0.1-0.1l2.1-1.2
                  c0.9-0.5,1.8-0.7,2.8-0.7c1.3,0.1,2.2,0.4,2.9,1.5l0,0.1l0.1,0.9l-1.7,0.5l0.3,0.1l-0.1,0.4
                  C635.1,239.8,634.5,241.6,631.5,241.6z M629.2,238.8c-0.2,0.2-0.5,0.8-0.3,1.2c0.1,0.4,0.7,0.6,1.6,0.7
                  c2.3,0.3,3.3-0.4,3.6-0.9l-0.6-0.2l0.2-0.8l1.9-0.6l0-0.1c-0.5-0.8-1.1-1-2.2-1c-0.8-0.1-1.6,0.1-2.3,0.6L629.2,238.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4460_">
                    <g id="XMLID_4467_">
                      <path
                        id="XMLID_4469_"
                        d="M627,239.2l1.1-0.6c0.2-0.1,0.4-0.1,0.5,0c0.4,0.3,1,0.9,1.4,1.8c0.1,0.2,0,0.5-0.3,0.6l-1.3,0.5
                  c-0.2,0.1-0.5,0-0.6-0.2l-1.1-1.6C626.7,239.7,626.8,239.4,627,239.2z"
                      />
                    </g>
                    <g id="XMLID_4461_">
                      <path
                        id="XMLID_4463_"
                        d="M628.3,242.1c-0.3,0-0.6-0.1-0.8-0.4l-1.1-1.6c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.3,0.2-0.5,0.5-0.6
                  v0l1.1-0.6c0.3-0.2,0.7-0.1,1,0.1c0.4,0.3,1.1,1,1.5,2c0.2,0.4,0,1-0.5,1.2l-1.3,0.5C628.6,242.1,628.5,242.1,628.3,242.1z
                   M627.2,239.6C627.2,239.6,627.2,239.6,627.2,239.6c0,0.1,0,0.1,0,0.1l1.1,1.6c0,0,0.1,0,0.1,0l1.3-0.5c0,0,0.1-0.1,0.1-0.1
                  c-0.3-0.9-0.9-1.4-1.3-1.7c0,0-0.1,0-0.1,0L627.2,239.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4450_">
                    <g id="XMLID_4457_">
                      <path
                        id="XMLID_4458_"
                        style="fill: #ffffff"
                        d="M614.1,238.1C614.3,238.9,613.7,237.1,614.1,238.1c1.1,2.7,2.5,5.7,2.5,5.7
                  s3.2-1.4,5.6-2.2c2.1-0.7,3.9-1.8,4.2-2.1c0.1,0,0.1,0,0.2,0c0.4,0.1,1.7,0.6,1.8,2.2c0,0.1,0,0.1-0.1,0.2
                  c-0.8,0.7-8.2,6.8-13.7,7.9c0,0-3.2-2.6-4.7-7.5C608.3,237.2,613.4,236.2,614.1,238.1z"
                      />
                    </g>
                    <g id="XMLID_4452_">
                      <path
                        id="XMLID_4453_"
                        d="M614.5,250.1l-0.2-0.1c-0.1-0.1-3.3-2.8-4.9-7.7c-1-3.1,0.5-4.8,2-5.3c1-0.4,2.1-0.2,2.7,0.4
                  c0.1,0.1,0.2,0.2,0.3,0.4c0,0,0,0.1,0.1,0.1c0.9,2.1,1.9,4.4,2.3,5.3c0.9-0.4,3.4-1.4,5.3-2.1c2.1-0.7,3.8-1.8,4.1-2
                  c0.2-0.1,0.3-0.1,0.5-0.1c1,0.3,2,1.1,2.1,2.6c0,0.2-0.1,0.4-0.2,0.5c-0.8,0.7-8.3,6.8-13.9,8L614.5,250.1z M612.5,237.6
                  c-0.2,0-0.5,0-0.8,0.1c-1.2,0.4-2.3,1.8-1.5,4.3c1.3,4.2,3.8,6.6,4.5,7.2c5.2-1.2,12.1-6.7,13.2-7.7c-0.1-1.1-1-1.6-1.4-1.7
                  c-0.5,0.3-2.2,1.4-4.3,2.1c-2.3,0.8-5.5,2.2-5.6,2.2l-0.4,0.2l-0.2-0.4c0,0-1.3-2.8-2.4-5.4c-0.1-0.1-0.1-0.2-0.2-0.4
                  c0,0,0,0,0,0C613.4,237.8,613,237.6,612.5,237.6z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4377_">
                  <g id="XMLID_4438_">
                    <g id="XMLID_4446_">
                      <path
                        id="XMLID_4447_"
                        style="fill: #ffffff"
                        d="M643.4,286.2L643.4,286.2c-0.1,1.2,0,2.4,0.2,3.6l0,0c0,0,2.4,0.7,3.9-0.4
                  c1.5-1,3.1-0.4,3.2-0.4c0.1,0,4.3-1.3,4.3-1.2s0.7-2-0.9-2.4c-1.6-0.4-5.2-1.2-5.2-1.2l-1.9-0.3l-2.9,1.4
                  C643.7,285.5,643.4,285.8,643.4,286.2z"
                      />
                    </g>
                    <g id="XMLID_4440_">
                      <path
                        id="XMLID_4441_"
                        d="M645.3,290.5c-0.9,0-1.7-0.2-1.8-0.2l-0.2-0.1l-0.1-0.2c-0.3-1.2-0.4-2.5-0.3-3.7l0,0
                  c0.1-0.6,0.4-1,0.9-1.3l3-1.4l2,0.4c0.1,0,3.7,0.8,5.3,1.2c0.9,0.2,1.4,1,1.4,2c0,0.4-0.1,1.1-0.5,1.2l-0.3,0.1l0,0
                  c-0.5,0.1-1.6,0.4-2.3,0.7c-1.7,0.5-1.7,0.5-1.9,0.5l-0.1,0c-1-0.3-2-0.2-2.7,0.3C647,290.3,646.1,290.5,645.3,290.5z
                   M644,289.5c0.6,0.1,2.2,0.4,3.3-0.4c1-0.7,2.2-0.8,3.4-0.4c0.2-0.1,0.9-0.3,1.5-0.4c1.3-0.4,2.1-0.6,2.5-0.7
                  c0.1-0.3,0.1-0.8-0.1-1.1c-0.1-0.3-0.3-0.4-0.6-0.5c-1.6-0.4-5.2-1.2-5.2-1.2l-1.7-0.3l-2.8,1.3c-0.2,0.1-0.4,0.3-0.4,0.6v0
                  C643.7,287.4,643.8,288.4,644,289.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4429_">
                    <g id="XMLID_4435_">
                      <path
                        id="XMLID_4437_"
                        style="fill: #ffffff"
                        d="M642.9,282.8l0.7,2.2c0,0,1.1,1.2,2.7,0.5c1.6-0.7-0.1-4.2-0.1-4.2
                  L642.9,282.8z"
                      />
                    </g>
                    <g id="XMLID_4430_">
                      <path
                        id="XMLID_4431_"
                        d="M645.2,286.2c-0.9,0-1.6-0.5-2-0.8l-0.1-0.1l-0.8-2.6l3.9-1.9l0.2,0.4c0.1,0.3,1.2,2.5,0.7,3.9
                  c-0.1,0.4-0.4,0.7-0.8,0.9C646,286.1,645.6,286.2,645.2,286.2z M643.9,284.8c0.2,0.2,1,0.8,2.1,0.3c0.2-0.1,0.3-0.2,0.4-0.4
                  c0.3-0.8-0.2-2.1-0.5-2.9l-2.5,1.2L643.9,284.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4418_">
                    <g id="XMLID_4426_">
                      <path
                        id="XMLID_4427_"
                        d="M609.1,265c0,0,4.9,6.4,9.2,7.8c2.6,0.8,7.5,0.8,11.4-1.9c0,0,3.9-2.5,6.1-4.6
                  c0.1-0.1,0.3,0,0.3,0.1c-0.1,1.1,0.3,4.5,1.8,6.9c1.9,2.9,3.6,7,4.7,10.1c0,0,2.5,1.2,4.2-1.7c0,0-4.1-13.1-4.6-18.1
                  c-0.3-3.1-2.1-4.5-3.3-5.2c-0.7-0.4-1.5-0.5-2.3-0.3l-1.2,0.2c-2.2,0.3-4.3,0.9-6.4,1.7l-10,3.7L609.1,265z"
                      />
                    </g>
                    <g id="XMLID_4419_">
                      <path
                        id="XMLID_4422_"
                        d="M643.9,284.1c-0.8,0-1.4-0.3-1.4-0.3l-0.2-0.1l-0.1-0.2c-1.6-4.3-3.2-7.7-4.7-10
                  c-1.4-2.1-1.8-5-1.9-6.5c-2.3,2-5.6,4.1-5.8,4.2c-3.7,2.6-8.7,2.9-11.7,2c-4.4-1.4-9.2-7.7-9.4-8l-0.4-0.6l10.6-1.3l10-3.7
                  c2.1-0.8,4.3-1.3,6.5-1.7l1.2-0.2c0.9-0.1,1.8,0,2.5,0.4c1.5,0.7,3.3,2.3,3.6,5.5c0.5,4.8,4.5,17.8,4.5,18l0.1,0.2l-0.1,0.2
                  C646.2,283.7,644.9,284.1,643.9,284.1z M643,283.1c0.5,0.2,2.2,0.5,3.4-1.5c-0.5-1.6-4.1-13.3-4.5-18
                  c-0.3-2.8-1.8-4.2-3.1-4.8c-0.6-0.3-1.3-0.4-2-0.3l-1.2,0.2c-2.2,0.3-4.3,0.9-6.3,1.6l-10.1,3.7l-9.2,1.2
                  c1.3,1.6,5.2,6,8.6,7.1c2.3,0.7,7,0.8,11-1.9c0,0,3.9-2.5,6.1-4.5c0.2-0.2,0.4-0.2,0.6-0.1c0.2,0.1,0.3,0.3,0.3,0.6
                  c0,1,0.3,4.4,1.8,6.6C639.9,275.4,641.4,278.8,643,283.1z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4412_">
                    <g id="XMLID_4414_">
                      <path
                        id="XMLID_4415_"
                        style="fill: #ffffff"
                        d="M636.3,266.4l-0.8-0.1c0-0.1,0.2-2.1,1.3-3.4l0.6,0.6
                  C636.4,264.5,636.3,266.3,636.3,266.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4395_">
                    <g id="XMLID_4407_">
                      <g id="XMLID_4408_">
                        <rect
                          id="XMLID_4411_"
                          x="651.3"
                          y="285.4"
                          transform="matrix(0.2102 -0.9777 0.9777 0.2102 235.4986 863.0942)"
                          width="1.2"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4402_">
                      <g id="XMLID_4404_">
                        <rect
                          id="XMLID_4405_"
                          x="649.7"
                          y="285.1"
                          transform="matrix(0.2104 -0.9776 0.9776 0.2104 234.3515 861.2504)"
                          width="1.2"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4397_">
                      <g id="XMLID_4398_">
                        <rect
                          id="XMLID_4401_"
                          x="648.3"
                          y="284.7"
                          transform="matrix(0.2104 -0.9776 0.9776 0.2104 233.729 859.5588)"
                          width="1.2"
                          height="0.8"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_4380_">
                    <g id="XMLID_4391_">
                      <path
                        id="XMLID_4394_"
                        style="fill: #ffffff"
                        d="M655,287.9c0,0.1,0.3-0.8,0.1-1.5c-4.1,1.6-11.5,2.3-11.6,2.3
                  c0,0.4,0.1,0.8,0.2,1.1c0,0,2.4,0.7,3.9-0.4c1.5-1,3.1-0.4,3.2-0.4C650.8,289.1,654.9,287.8,655,287.9z"
                      />
                    </g>
                    <g id="XMLID_4381_">
                      <path
                        id="XMLID_4383_"
                        d="M645.2,290.5c-0.9,0-1.7-0.2-1.8-0.2l-0.2-0.1l-0.1-0.2c-0.1-0.3-0.1-0.7-0.2-1.2l-0.1-0.4l0.4,0
                  c0.1,0,7.5-0.7,11.5-2.3l0.4-0.2l0.1,0.4c0.2,0.6,0.1,1.9-0.3,2l-0.4,0.1l0-0.1c-0.5,0.1-1.6,0.4-2.3,0.7
                  c-1.7,0.5-1.7,0.5-1.9,0.5l-0.1,0c-1-0.3-2-0.2-2.7,0.3C647,290.3,646,290.5,645.2,290.5z M644,289.5
                  c0.6,0.1,2.2,0.4,3.3-0.4c1-0.7,2.2-0.8,3.4-0.4c0.2-0.1,0.9-0.3,1.5-0.4c1.3-0.4,2.1-0.6,2.5-0.7c0-0.1,0.1-0.3,0.1-0.6
                  c-3.6,1.3-9.3,2-10.8,2.1C643.9,289.2,643.9,289.4,644,289.5z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4359_">
                  <g id="XMLID_4360_">
                    <g id="XMLID_4372_">
                      <path
                        id="XMLID_4374_"
                        style="fill: #ffffff"
                        d="M627.5,258.1c0,0,2.9,7,2.9,10.8c0,0-2.9,6.3-10,5.7
                  c-3.1-0.2-8.9-3.3-11.4-6.5c-1-1.3,0-6-0.3-7.6l-3.7-16.9c0,0-1.3-4.7,1-7.2c0.3-0.3,0.7-0.5,1.2-0.6
                  c1.1-0.2,4.4,0.7,4.3,0.7c-2.5-0.7,7.3,3.1,7.3,3.1l3.5,2.9l3.4,12.9L627.5,258.1z"
                      />
                    </g>
                    <g id="XMLID_4363_">
                      <path
                        id="XMLID_4365_"
                        d="M621.3,275.1c-0.3,0-0.6,0-0.9,0c-3.1-0.2-9-3.3-11.7-6.7c-0.7-0.9-0.6-3-0.5-5
                  c0.1-1.1,0.1-2.2,0-2.8l-3.7-16.8c-0.1-0.2-1.3-5,1.1-7.6c0.4-0.4,0.9-0.7,1.4-0.7c1-0.1,3.6,0.5,4.3,0.6c0,0,0.1,0,0.1,0
                  c0,0,0,0,0,0l0,0.2c1,0.5,4.9,2,7.4,3l0.1,0.1l3.6,3l3.4,12.9l1.9,2.7c0.1,0.3,3,7.1,3,11v0.1l0,0.1
                  C630.8,269.2,628,275.1,621.3,275.1z M607.5,236.3c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.7,0.2-0.9,0.5c-2.1,2.2-0.9,6.7-0.9,6.8
                  l3.7,16.9c0.1,0.7,0.1,1.7,0,3c-0.1,1.7-0.2,3.7,0.3,4.4c2.5,3.1,8.2,6.1,11.1,6.4c6.4,0.5,9.2-4.6,9.6-5.4
                  c0-3.6-2.7-10-2.9-10.5l-1.9-2.8l-3.3-12.7l-3.4-2.7c-5.4-2.1-7.2-2.8-7.7-3.2C609.8,236.6,608.3,236.3,607.5,236.3z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4311_">
                  <g id="XMLID_4354_">
                    <g id="XMLID_4356_">
                      <rect
                        id="XMLID_4357_"
                        x="643.3"
                        y="241.5"
                        transform="matrix(0.3856 -0.9227 0.9227 0.3856 173.5048 744.6564)"
                        width="5.2"
                        height="1"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4312_">
                    <g id="XMLID_4318_">
                      <g id="XMLID_4339_">
                        <g id="XMLID_4350_">
                          <path
                            id="XMLID_4353_"
                            style="fill: #ffffff"
                            d="M641.1,246.3c0,0,0.9-1.9,2-2.5c0.8-0.5,1.3,0.2,1.5,0.5
                      c0.1,0.1,0.2,0.2,0.3,0.1l2.3-0.9c0.3-0.1,0.6,0,0.7,0.2c0.3,0.5,0.6,1.3-0.1,2.6c-1,2-4.9,2.5-4.9,2.5L641.1,246.3z"
                          />
                        </g>
                        <g id="XMLID_4340_">
                          <path
                            id="XMLID_4341_"
                            d="M642.8,249.2l-2.2-2.8l0.1-0.2c0-0.1,1-2,2.1-2.7c0.7-0.4,1.5-0.2,2,0.5l2.2-0.9
                      c0.5-0.2,1,0,1.2,0.4c0.3,0.5,0.8,1.5,0,3c-1.1,2.1-5,2.7-5.2,2.7L642.8,249.2z M641.6,246.3l1.6,2.1
                      c0.8-0.1,3.6-0.7,4.4-2.2c0.6-1.1,0.3-1.8,0.1-2.2c0-0.1-0.1-0.1-0.2-0.1l-2.3,0.9c-0.3,0.1-0.7,0-0.8-0.3
                      c-0.4-0.7-0.8-0.5-1-0.4C642.6,244.6,641.9,245.7,641.6,246.3z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_4329_">
                        <g id="XMLID_4336_">
                          <path
                            id="XMLID_4337_"
                            d="M639.7,247.3l1.1-0.9c0.2-0.1,0.4-0.2,0.6-0.1c0.4,0.2,1.3,0.7,1.9,1.7c0.2,0.2,0.1,0.6-0.2,0.7
                      l-1.2,0.8c-0.2,0.1-0.5,0.1-0.7-0.1l-1.5-1.4C639.4,247.9,639.5,247.5,639.7,247.3z"
                          />
                        </g>
                        <g id="XMLID_4330_">
                          <path
                            id="XMLID_4332_"
                            d="M641.5,250.1c-0.2,0-0.5-0.1-0.7-0.3l-1.5-1.4c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
                      v0l1.1-0.9c0.3-0.2,0.7-0.3,1-0.1c0.5,0.2,1.4,0.8,2,1.8c0.3,0.4,0.1,1-0.3,1.3l-1.2,0.8
                      C641.9,250.1,641.7,250.1,641.5,250.1z M640,247.6C639.9,247.7,639.9,247.7,640,247.6c0,0.1,0,0.2,0,0.2l1.5,1.4
                      c0,0,0.1,0,0.2,0l1.2-0.8c0.1,0,0.1-0.1,0-0.2c-0.6-0.9-1.3-1.3-1.7-1.5c-0.1,0-0.1,0-0.1,0L640,247.6z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_4319_">
                        <g id="XMLID_4326_">
                          <path
                            id="XMLID_4328_"
                            style="fill: #ffffff"
                            d="M620.3,243c0.4-0.5,1-0.6,1.6-0.5c0.3,0.1,0.6,0.2,0.8,0.4
                      c0.6,0.6,1.7,1.8,2.7,3.5c1.6,2.6,5.1,7.5,5.1,7.5s2.9-2,5.1-3.3c1.9-1.1,3.4-2.6,3.7-2.9c0,0,0.1-0.1,0.2-0.1
                      c0.4,0,1.8,0.3,2.2,1.8c0,0.1,0,0.1,0,0.2c-0.7,0.8-6.3,7.7-11.4,10.1c-0.3,0.2-0.7,0.1-1-0.2c-1.4-1.3-5.8-5.6-7.9-9.3
                      C618.9,246,619.5,244,620.3,243C620.2,243.1,620.3,243.1,620.3,243z"
                          />
                        </g>
                        <g id="XMLID_4321_">
                          <path
                            id="XMLID_4322_"
                            d="M629.9,260.5c-0.3,0-0.7-0.1-0.9-0.4c-1.3-1.2-5.8-5.6-8-9.4c-2-3.6-2.3-6.2-1.1-7.9l0,0
                      c0.5-0.6,1.3-0.8,2-0.6c0.4,0.1,0.7,0.3,1,0.6c0.7,0.7,1.8,1.9,2.8,3.6c1.3,2.2,4,6,4.8,7.2c0.8-0.6,3-2.1,4.8-3
                      c1.9-1.1,3.4-2.5,3.7-2.8c0.1-0.1,0.3-0.2,0.5-0.2c1,0.1,2.2,0.7,2.6,2.1c0,0.2,0,0.4-0.1,0.5c-0.6,0.8-6.4,7.8-11.5,10.2
                      C630.3,260.4,630.1,260.5,629.9,260.5z M620.6,243.3c-1,1.4-0.7,3.7,1.1,7c2.1,3.7,6.5,8,7.8,9.2c0.2,0.1,0.4,0.2,0.6,0.1
                      c4.8-2.3,10.3-8.8,11.1-9.9c-0.3-1.1-1.3-1.3-1.7-1.4c-0.4,0.4-1.9,1.8-3.8,2.9c-2.1,1.2-5,3.2-5,3.2l-0.3,0.2l-0.2-0.3
                      c0,0-3.5-4.9-5.1-7.5c-1-1.6-2-2.8-2.6-3.4c-0.2-0.2-0.4-0.3-0.6-0.3C621.3,242.8,620.9,243,620.6,243.3L620.6,243.3z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_4314_">
                      <g id="XMLID_4315_">
                        <path
                          id="XMLID_4317_"
                          d="M630.8,256.1l-0.8-0.2c0.2-0.7,0-1.8,0-1.8l0.8-0.2C630.9,254,631.1,255.2,630.8,256.1z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_4266_">
                  <g id="XMLID_4272_">
                    <g id="XMLID_4301_">
                      <g id="XMLID_4308_">
                        <path
                          id="XMLID_4309_"
                          style="fill: #ffffff"
                          d="M621.4,269.2l-2.5-12.5c-0.2-0.8-0.7-1.5-1.4-1.9l-15.3-8.8
                    c-0.2-0.1-0.4-0.2-0.6-0.1l0,0c-1.3,0.2-2.1,1.4-2.2,1.6c0,0,0,0,0,0.1l1.8,1.1l0.1-0.1l2,10.6c0.2,0.8,0.6,1.5,1.3,1.9
                    c0,0,0,0,0,0l11.6,4.5c0,0,0,0,0,0l1.1,1.4c0,0,0,0,0,0l1.5,4.6c0,0,0,0,0,0c0.3,0,2.4-0.1,2.5-1.8l0-0.1
                    C621.4,269.6,621.4,269.3,621.4,269.2z"
                        />
                      </g>
                      <g id="XMLID_4303_">
                        <path
                          id="XMLID_4304_"
                          d="M618.6,272l-0.1-0.3l-1.5-4.5l-1-1.3l-11.6-4.5c-0.8-0.5-1.4-1.3-1.5-2.2l-2-10.2l-1.9-1.1
                    l-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.2-0.3,1-1.6,2.6-1.8c0.2-0.1,0.6,0,0.8,0.2l15.3,8.8c0.8,0.5,1.4,1.3,1.6,2.2l2.5,12.5
                    c0,0,0.1,0.4,0,0.7l0,0.1c-0.1,1.6-1.6,2.1-2.9,2.1L618.6,272z M601.5,248l2.1,11.1c0.1,0.7,0.5,1.3,1.1,1.6l11.6,4.5
                    l0.1,0.1l1.2,1.6l1.4,4.3c0.6,0,1.8-0.3,1.8-1.4l0-0.1c0-0.1,0-0.3,0-0.4l-2.5-12.5c-0.1-0.7-0.6-1.3-1.2-1.6l-15.3-8.8
                    c-0.1-0.1-0.2-0.1-0.3-0.1c-0.9,0.1-1.5,0.7-1.8,1.1l1.3,0.7L601.5,248z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4292_">
                      <g id="XMLID_4298_">
                        <path
                          id="XMLID_4300_"
                          d="M621.4,269.9l0-0.1c0-0.1,0-0.4,0-0.5l-2.5-12.5c-0.1-0.4-0.3-0.8-0.5-1.1
                    c-0.7,0.7-1.8,1.1-1.8,1.1s-2.2,1.3-2.4,1.4c-0.2,0.1-3.7,3.6-3.8,4c0,0.1,0,0.5,0.1,1.2l5.7,2.2c0,0,0,0,0,0l1.1,1.4
                    c0,0,0,0,0,0l1.5,4.6c0,0,0,0,0,0C619.2,271.7,621.3,271.6,621.4,269.9z"
                        />
                      </g>
                      <g id="XMLID_4293_">
                        <path
                          id="XMLID_4294_"
                          d="M618.6,272.1l-0.1-0.3l-1.5-4.5l-1-1.3l-5.8-2.3l0-0.2c-0.2-1.2-0.2-1.3-0.1-1.5
                    c0.2-0.5,3.8-4.1,4-4.2c0.1-0.1,1.3-0.7,2.3-1.3l0.1,0c0,0,1-0.4,1.6-1l0.3-0.3l0.3,0.4c0.3,0.4,0.5,0.8,0.6,1.3l2.5,12.5
                    c0,0.1,0.1,0.5,0,0.7l0,0.1c-0.1,1.6-1.6,2.1-2.9,2.1L618.6,272.1z M610.9,263.1l5.6,2.2l0.1,0.1l1.2,1.5l1.4,4.3
                    c0.6,0,1.8-0.3,1.8-1.4l0-0.1c0-0.1,0-0.3,0-0.4l-2.5-12.5c0-0.2-0.1-0.4-0.2-0.6c-0.6,0.5-1.3,0.8-1.5,0.9
                    c-0.6,0.4-2,1.2-2.4,1.4c-0.4,0.3-3.2,3.2-3.6,3.7C610.8,262.4,610.8,262.6,610.9,263.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4282_">
                      <g id="XMLID_4289_">
                        <path
                          id="XMLID_4291_"
                          d="M602.8,246.4l-0.7-0.4c-0.2-0.1-0.4-0.2-0.6-0.1c-1.3,0.2-2.1,1.4-2.2,1.6c0,0,0,0,0,0.1l1,0.6
                    c0.4-0.3,0.4-0.4,0.4-0.4S601.4,247,602.8,246.4L602.8,246.4z"
                        />
                      </g>
                      <g id="XMLID_4284_">
                        <path
                          id="XMLID_4285_"
                          d="M600.3,248.7l-1.3-0.8l-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.2-0.3,1-1.6,2.6-1.8
                    c0.2-0.1,0.6,0,0.8,0.2l1.4,0.8l-0.8,0.3c-1.1,0.5-1.7,1-1.9,1.2c-0.1,0.1-0.2,0.3-0.5,0.5L600.3,248.7z M599.9,247.4
                    l0.4,0.2c0.1-0.1,0.1-0.1,0.1-0.1l0-0.1l0,0c0,0,0.5-0.5,1.5-1c-0.1-0.1-0.2-0.1-0.3-0.1
                    C600.8,246.4,600.2,247,599.9,247.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4273_">
                      <g id="XMLID_4280_">
                        <path
                          id="XMLID_4281_"
                          style="fill: #ffffff"
                          d="M619.7,270.6l-2.6-12.7c-0.2-0.8-0.7-1.5-1.4-1.9l-15.4-8.8
                    c-0.6-0.4-1.4,0.2-1.2,0.9l2.3,12.2c0.2,0.8,0.6,1.5,1.3,1.9l15.6,9.2C619,271.9,619.8,271.3,619.7,270.6z"
                        />
                      </g>
                      <g id="XMLID_4275_">
                        <path
                          id="XMLID_4276_"
                          d="M618.9,272c-0.2,0-0.4-0.1-0.6-0.2l-15.6-9.2c-0.8-0.5-1.4-1.3-1.5-2.2l-2.3-12.2
                    c-0.1-0.5,0.1-1,0.5-1.2c0.4-0.3,0.9-0.3,1.3-0.1l15.4,8.8c0.8,0.5,1.4,1.3,1.6,2.2l2.6,12.7h0c0.1,0.5-0.1,1-0.5,1.3
                    C619.4,272,619.1,272,618.9,272z M600,247.6c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0.2-0.2,0.4l2.3,12.2
                    c0.1,0.7,0.5,1.3,1.1,1.6l15.6,9.2c0.2,0.1,0.4,0,0.4,0c0.1,0,0.2-0.2,0.2-0.4h0l-2.6-12.7c-0.1-0.7-0.6-1.3-1.2-1.6
                    l-15.4-8.8C600.1,247.6,600,247.6,600,247.6z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_4268_">
                    <g id="XMLID_4269_">
                      <path
                        id="XMLID_4271_"
                        d="M617.9,281.7c-0.4,0-0.9-0.1-1.3-0.3c-0.8-0.5-1.4-1.3-1.4-2.3c0-1.3-0.1-2.7-0.4-4l-2.5-11.4
                  c-0.5-2.4-2.1-4.5-4.2-5.7l-0.7-0.4c-0.4-0.2-0.9-0.2-1.3,0.1c-0.4,0.3-0.6,0.7-0.5,1.2l2.3,10.9c0.3,1.4,0.4,2.8,0.4,4.2
                  v0.1c0,0.4,0.3,0.6,0.4,0.6c0.1,0.1,0.4,0.2,0.7,0l1,1.6c-0.8,0.5-1.8,0.5-2.7,0.1c-0.8-0.5-1.4-1.3-1.4-2.3v-0.1
                  c0-1.3-0.1-2.5-0.4-3.8l-2.3-10.9c-0.3-1.2,0.2-2.4,1.2-3.2c1-0.7,2.3-0.8,3.4-0.2l0.7,0.4c2.6,1.5,4.5,4,5.2,6.9l2.5,11.4
                  c0.3,1.4,0.5,2.9,0.5,4.4c0,0.4,0.3,0.6,0.4,0.6c0.1,0.1,0.4,0.2,0.7,0l1,1.6C618.8,281.6,618.4,281.7,617.9,281.7z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4202_">
                  <g id="XMLID_4257_">
                    <g id="XMLID_4264_">
                      <path
                        id="XMLID_4265_"
                        style="fill: #ffffff"
                        d="M611.8,235.8l5.5,2.4c0,0,2.5,0.1,2.1,2.4c0,0-3.6,2.5-7.9-0.8
                  C606.9,236.5,611.8,235.8,611.8,235.8z"
                      />
                    </g>
                    <g id="XMLID_4258_">
                      <path
                        id="XMLID_4259_"
                        d="M616.1,241.9c-1.4,0-3.1-0.4-4.9-1.7c-1.6-1.2-2.3-2.2-2-3.1c0.3-1.3,2.3-1.7,2.6-1.7l0.1,0
                  l5.6,2.4c0.3,0,1.4,0.2,2,0.9c0.4,0.5,0.5,1.2,0.4,2l0,0.2l-0.1,0.1C619.6,241,618.2,241.9,616.1,241.9z M611.7,236.2
                  c-0.5,0.1-1.6,0.4-1.8,1c-0.1,0.3,0,1,1.7,2.3c3.6,2.7,6.6,1.3,7.3,0.9c0.1-0.5,0-0.9-0.2-1.1c-0.5-0.6-1.4-0.6-1.5-0.6
                  l-0.2,0L611.7,236.2z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4247_">
                    <g id="XMLID_4254_">
                      <path
                        id="XMLID_4255_"
                        style="fill: #ffffff"
                        d="M612.1,232.4c0,0,0.4,3.3-0.3,4.5l0,0c1.3,1.8,3.4,2.3,5.4,1.5
                  c0.1-0.1,0.3-0.1,0.4-0.2c0,0,0-0.1,0-0.1c-0.2-0.2-1.3-1.4-0.5-4l-2.5-2.7L612.1,232.4z"
                      />
                    </g>
                    <g id="XMLID_4248_">
                      <path
                        id="XMLID_4250_"
                        d="M615.3,239.2c-1.5,0-3-0.7-3.9-2.1l-0.2-0.2l0.1-0.2c0.6-0.9,0.4-3.4,0.3-4.2l0-0.3l3-1.2l2.9,3
                  l-0.1,0.2c-0.7,2.4,0.2,3.4,0.4,3.6l0.2,0.1l0,0.2c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0.1-0.4,0.2
                  C616.7,239.1,616,239.2,615.3,239.2z M612.3,236.9c1.1,1.5,3,1.9,4.7,1.2c-0.4-0.6-0.9-1.8-0.3-3.8l-2.2-2.3l-1.9,0.7
                  C612.7,233.6,612.8,235.7,612.3,236.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4236_">
                    <g id="XMLID_4245_">
                      <path
                        id="XMLID_4246_"
                        style="fill: #ffffff"
                        d="M615.6,234.7c0,0,0.9,1.4,4.1,0.5c0,0,2.4-4.3,0.6-8.2h-3.8l-4.4,1.9
                  l1.4,4.5L615.6,234.7L615.6,234.7z"
                      />
                    </g>
                    <g id="XMLID_4238_">
                      <path
                        id="XMLID_4240_"
                        d="M617.7,235.9c-1.5,0-2.2-0.7-2.4-0.9l-2.1-1.4l-1.6-5l4.9-2.1h4.1l0.1,0.2
                  c1.8,4.1-0.5,8.4-0.7,8.6l-0.1,0.1l-0.2,0C619,235.8,618.3,235.9,617.7,235.9z M615.9,234.5c0,0.1,0.9,1.1,3.5,0.3
                  c0.4-0.7,1.9-4.2,0.6-7.4h-3.4l-4,1.7l1.3,4L615.9,234.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4222_">
                    <g id="XMLID_4232_">
                      <path
                        id="XMLID_4234_"
                        d="M610.2,225.7c0,0-2.2,1.7-0.4,5.2c1.3,2.5,0.8,3.5,0.8,3.5s0.6,0.8,2.1-0.1c0,0,1.3,2.1,3,0.4
                  c0,0,0.9-3.4-0.3-4.9c0,0,0-0.1,0.1-0.1c0.8,0,4.6,0,6.4-3.7c0,0-0.9-4.1-5.9-4.1C610.9,221.9,610.2,225.7,610.2,225.7z"
                      />
                    </g>
                    <g id="XMLID_4224_">
                      <path
                        id="XMLID_4226_"
                        d="M614.3,235.7c-0.1,0-0.2,0-0.2,0c-0.7-0.1-1.3-0.6-1.5-0.9c-1.5,0.7-2.2-0.2-2.3-0.2l-0.2-0.2
                  l0.1-0.2l0,0c0,0,0.3-0.9-0.8-3.1c-1.6-3.2,0-5.1,0.4-5.6c0.2-0.7,1.4-3.9,6.1-3.9c5.2,0,6.3,4.3,6.3,4.4l0,0.1l-0.1,0.1
                  c-1.6,3.3-4.7,3.9-6.2,3.9c0.8,1.8,0.1,4.6,0,4.7l0,0.1l-0.1,0.1C615.4,235.5,614.8,235.7,614.3,235.7z M612.7,233.7
                  l0.2,0.4c0,0,0.5,0.8,1.2,0.9c0.3,0,0.7-0.1,1.1-0.4c0.2-1,0.6-3.3-0.3-4.4c-0.1-0.1-0.1-0.4-0.1-0.5
                  c0.1-0.2,0.3-0.3,0.5-0.3c0.7,0,4.2,0,5.9-3.3c-0.2-0.7-1.3-3.6-5.4-3.6c-4.6,0-5.3,3.4-5.3,3.4l0,0.2l-0.1,0.1
                  c-0.1,0.1-1.9,1.6-0.3,4.7c1,1.9,1,3,0.9,3.5c0.2,0.1,0.6,0.2,1.4-0.3L612.7,233.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4216_">
                    <g id="XMLID_4218_">
                      <path
                        id="XMLID_4220_"
                        style="fill: #ffffff"
                        d="M612.9,226.9c-0.3-0.6-0.7-0.9-1.1-1.1c-0.7-0.2-1.5,0.1-1.5,0.1l-0.3-0.6
                  c0,0,1-0.5,2-0.2c0.6,0.2,1.1,0.7,1.5,1.4L612.9,226.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4210_">
                    <g id="XMLID_4212_">
                      <path
                        id="XMLID_4214_"
                        d="M616.6,241.6l-0.8-0.2c0.4-1.4-0.5-2.2-0.6-2.3l0.5-0.6C615.9,238.6,617.2,239.8,616.6,241.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4204_">
                    <g id="XMLID_4206_">
                      <path
                        id="XMLID_4208_"
                        d="M610.2,238.5l-0.6-0.6c0,0,1-1.1,2.3-1.2l0.1,0.8C610.9,237.6,610.2,238.5,610.2,238.5z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_4123_">
                <g id="XMLID_4184_">
                  <g id="XMLID_4196_">
                    <path
                      id="XMLID_4198_"
                      style="fill: #ffffff"
                      d="M668.8,269.3c0,0-0.6-1.5-3-1.8l0,0c-0.2-0.1-0.4,0-0.6,0.1l-6.3,3.6
                c-0.6,0.4-1.2,1.4-1.2,2.2v0.7v1.4l-0.1,12.6c0,0.2,0.1,0.4,0.2,0.5l0,0c0,0,0.8,1.1,2.7,1.6c1,0.3,3.3-9.9,5.2-13l-0.1,0.1
                c0.4-0.2,0.7-0.8,0.7-1.3v-3.5l1.8-2.4L668.8,269.3z"
                    />
                  </g>
                  <g id="XMLID_4186_">
                    <path
                      id="XMLID_4190_"
                      d="M660.6,290.7c-0.1,0-0.1,0-0.2,0c-1.9-0.5-2.8-1.6-2.9-1.8c-0.1-0.1-0.3-0.4-0.3-0.8l0.1-14.7
                c0-1,0.6-2.1,1.4-2.6l6.3-3.6c0.4-0.2,0.7-0.2,0.9-0.2c2.6,0.3,3.3,2,3.3,2l0.1,0.2l-2.6,3.3v3.3c0,0.6-0.3,1.3-0.8,1.6
                c-0.9,1.5-1.9,4.6-2.8,7.3C661.8,289.4,661.3,290.7,660.6,290.7z M665.6,267.9c-0.1,0-0.2,0-0.2,0.1l-6.3,3.6
                c-0.5,0.3-0.9,1.1-0.9,1.8v2.1l-0.1,12.6c0,0.1,0,0.2,0,0.2l0,0.1c0,0,0.7,1,2.4,1.5c0.4-0.5,1.3-3.1,1.9-5.1
                c0.7-2.2,1.5-4.6,2.2-6.3l-1.3,0.8l2.1-2.2l0,0c0.2-0.1,0.5-0.6,0.5-1v-3.6l2.4-3.2c-0.2-0.4-0.9-1.1-2.4-1.3l0,0
                L665.6,267.9C665.7,267.9,665.7,267.9,665.6,267.9z"
                    />
                  </g>
                </g>
                <g id="XMLID_4170_">
                  <g id="XMLID_4180_">
                    <path
                      id="XMLID_4182_"
                      style="fill: #ffffff"
                      d="M661.1,273.7c1.4,0.3,5.4-0.8,5.4-0.8l-0.2-0.2v-0.1l1.8-2.4l0.7-0.9
                c0,0-0.5-1.5-2.9-1.8l-0.1,0c-0.2-0.1-0.4,0-0.6,0.1l-6.3,3.6c-0.3,0.2-0.7,0.6-0.9,1C658,272.2,659.6,273.4,661.1,273.7z"
                    />
                  </g>
                  <g id="XMLID_4172_">
                    <path
                      id="XMLID_4174_"
                      d="M661.7,274.1c-0.3,0-0.5,0-0.7-0.1c-1.5-0.3-3.2-1.5-3.3-1.6l-0.2-0.2l0.1-0.3c0.3-0.5,0.6-1,1-1.2
                l6.3-3.6c0.4-0.2,0.7-0.2,0.9-0.2l0.1,0c2.6,0.3,3.2,2,3.3,2l0.1,0.2l-2.5,3.3l0.4,0.5l-0.6,0.2
                C666.2,273.3,663.4,274.1,661.7,274.1z M658.6,272.1c0.5,0.3,1.6,1,2.6,1.2l0,0c1,0.2,3.5-0.3,4.8-0.7v-0.2l2.4-3.2
                c-0.2-0.4-0.9-1.1-2.5-1.3l-0.2,0c-0.1,0-0.2,0-0.3,0.1l-6.3,3.6C658.9,271.7,658.7,271.8,658.6,272.1z"
                    />
                  </g>
                </g>
                <g id="XMLID_4156_">
                  <g id="XMLID_4166_">
                    <path
                      id="XMLID_4168_"
                      d="M667.9,269.2l-6.3,3.6c-0.6,0.4-1.2,1.4-1.2,2.2v0.7v1.4l-0.1,12.6c0,0.5,0.3,0.8,0.7,0.5l7.2-4.1
                c0.4-0.2,0.7-0.8,0.7-1.3l0.1-12.6v-1.4V270C669,269.2,668.5,268.8,667.9,269.2z"
                    />
                  </g>
                  <g id="XMLID_4158_">
                    <path
                      id="XMLID_4160_"
                      d="M660.7,290.7c-0.1,0-0.3,0-0.4-0.1c-0.3-0.2-0.5-0.5-0.5-0.9L660,275c0-1,0.6-2.1,1.4-2.6l6.3-3.6
                c0.4-0.2,0.8-0.3,1.2-0.1c0.4,0.2,0.6,0.7,0.6,1.3v2.1l-0.1,12.6c0,0.7-0.4,1.4-0.9,1.7l-7.2,4.1
                C661.1,290.7,660.9,290.7,660.7,290.7z M668.3,269.4c-0.1,0-0.2,0.1-0.3,0.1l0,0l-6.3,3.6c-0.5,0.3-0.9,1.1-0.9,1.8v2.1
                l-0.1,12.6c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0l7.2-4.1c0.2-0.1,0.5-0.6,0.5-1l0.1-14.7C668.6,269.7,668.5,269.5,668.3,269.4
                C668.4,269.4,668.4,269.4,668.3,269.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_4150_">
                  <g id="XMLID_4152_">
                    <path
                      id="XMLID_4154_"
                      style="fill: #ffffff"
                      d="M661,279.8c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.4-0.3-0.6-0.6l0.8-0.3
                c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.2,0l6.8-3.9c0.3-0.1,0.5-0.5,0.6-0.9l0.8,0.2c-0.2,0.6-0.6,1.2-1,1.5l-6.8,3.9
                C661.3,279.7,661.1,279.8,661,279.8z"
                    />
                  </g>
                </g>
                <g id="XMLID_4136_">
                  <g id="XMLID_4146_">
                    <path
                      id="XMLID_4148_"
                      d="M665.6,279l-1.3,0.7c-0.1,0.1-0.3,0-0.3-0.2v-2.8c0-0.2,0.1-0.4,0.3-0.5l1.3-0.7
                c0.1-0.1,0.3,0,0.3,0.2v2.8C665.9,278.7,665.7,278.9,665.6,279z"
                    />
                  </g>
                  <g id="XMLID_4138_">
                    <path
                      id="XMLID_4140_"
                      style="fill: #ffffff"
                      d="M664.2,280.2c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.3-0.6v-2.8
                c0-0.3,0.2-0.7,0.5-0.9l1.3-0.7c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.3,0.3,0.3,0.6v2.8c0,0.3-0.2,0.7-0.5,0.9v0l-1.3,0.7
                C664.5,280.1,664.3,280.2,664.2,280.2z M665.4,276.1l-0.9,0.5c0,0-0.1,0.1-0.1,0.1v2.4l0.9-0.5c0,0,0.1-0.1,0.1-0.1V276.1z"
                    />
                  </g>
                </g>
                <g id="XMLID_4131_">
                  <g id="XMLID_4133_">
                    <rect
                      id="XMLID_4135_"
                      x="661.1"
                      y="271.7"
                      transform="matrix(0.8676 -0.4973 0.4973 0.8676 -47.2893 366.604)"
                      style="fill: #ffffff"
                      width="7.5"
                      height="0.8"
                    />
                  </g>
                </g>
                <g id="XMLID_4125_">
                  <g id="XMLID_4127_">
                    <rect
                      id="XMLID_4129_"
                      x="661"
                      y="286.2"
                      transform="matrix(0.8675 -0.4974 0.4974 0.8675 -54.5091 368.574)"
                      style="fill: #ffffff"
                      width="7.5"
                      height="0.8"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_4041_">
                <g id="XMLID_4072_">
                  <g id="XMLID_4109_">
                    <g id="XMLID_4119_">
                      <path
                        id="XMLID_4121_"
                        style="fill: #ffffff"
                        d="M675.5,227.4c0,0,0-2.2,0-2.5c0,0,0-0.1-0.1-0.1l-9.1,0.8c0,0,0,0,0,0
                  l-0.8-5c0,0-0.1,0-0.1,0l-8.4,4.8c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,2.4,0,2.5l0,0c0,0.3,0.2,0.5,0.4,0.7
                  l8.4,4.9c0.7,0.4,1.6,0.4,2.3,0l8.6-4.9C675.3,228,675.5,227.7,675.5,227.4L675.5,227.4z"
                      />
                    </g>
                    <g id="XMLID_4111_">
                      <path
                        id="XMLID_4113_"
                        d="M665.3,233.8c-0.5,0-1-0.1-1.4-0.4l-8.4-4.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-1.9l0-0.6
                  c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l8.3-4.7c0.2-0.1,0.3-0.1,0.5,0l0.2,0.1l0.8,4.8l8.8-0.8l0.1,0
                  c0.2,0.1,0.4,0.3,0.4,0.5l0,2.6c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C666.3,233.7,665.8,233.8,665.3,233.8z M655.7,225.5v0.1
                  c0,0.7,0,1.8,0,1.8c0,0.1,0.1,0.3,0.2,0.4l8.4,4.9c0.6,0.3,1.3,0.3,1.9,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4h0l0-2.2l-8.8,0.8
                  l-0.2-0.1l-0.2-0.1l-0.8-4.7l-8.1,4.6l-0.2,0L655.7,225.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4095_">
                    <g id="XMLID_4105_">
                      <path
                        id="XMLID_4107_"
                        d="M675.4,224.8l-9.1,0.8c0,0,0,0,0,0l-1.1,3.1l0,4.7c0.4,0,0.8-0.1,1.2-0.3l8.6-4.9
                  c0.3-0.2,0.4-0.5,0.4-0.7l0,0C675.5,227.4,675.5,225.2,675.4,224.8C675.5,224.8,675.5,224.8,675.4,224.8z"
                      />
                    </g>
                    <g id="XMLID_4097_">
                      <path
                        id="XMLID_4099_"
                        d="M665.3,233.8C665.3,233.8,665.3,233.8,665.3,233.8l-0.5,0l0-5.2l1.2-3.6l0.3,0.2l9.1-0.8l0.1,0
                  c0.2,0.1,0.4,0.2,0.4,0.5l0,2.6c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C666.3,233.7,665.8,233.8,665.3,233.8z M665.7,228.8l0,4.2
                  c0.2,0,0.4-0.1,0.6-0.2l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4l0-2.2l-8.5,0.8L665.7,228.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4083_">
                    <g id="XMLID_4091_">
                      <path
                        id="XMLID_4093_"
                        d="M656.6,226.1c0,0,0.1-0.3,0.3-0.8l-1.5-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,2.4,0,2.5l0,0
                  c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5l0-0.5V226.1z"
                      />
                    </g>
                    <g id="XMLID_4084_">
                      <path
                        id="XMLID_4085_"
                        d="M657,229.3l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-1.9l0-0.6c0-0.2,0.1-0.3,0.2-0.4
                  c0.1-0.1,0.3-0.1,0.4-0.1l1.9,0.5l-0.2,0.4c-0.1,0.3-0.2,0.6-0.3,0.7L657,229.3z M655.7,225.4v0.1c0,0.7,0,1.8,0,1.8
                  c0,0.1,0.1,0.3,0.2,0.4l0.2,0.1l0-2c0,0,0.1-0.1,0.1-0.4L655.7,225.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4073_">
                    <g id="XMLID_4080_">
                      <path
                        id="XMLID_4081_"
                        style="fill: #ffffff"
                        d="M664.2,230.5l-8.5-4.9c-0.6-0.3-0.6-1.2,0-1.6l8.6-4.9
                  c0.7-0.4,1.6-0.4,2.2,0l8.5,4.9c0.6,0.3,0.6,1.2,0,1.6l-8.6,4.9C665.8,231,664.9,231,664.2,230.5z"
                      />
                    </g>
                    <g id="XMLID_4074_">
                      <path
                        id="XMLID_4076_"
                        d="M665.3,231.3c-0.5,0-0.9-0.1-1.3-0.4l-8.5-4.9c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                  l8.6-4.9c0.8-0.5,1.8-0.5,2.7,0l8.5,4.9c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-8.6,4.9
                  C666.2,231.1,665.8,231.3,665.3,231.3z M665.4,219.3c-0.3,0-0.6,0.1-0.9,0.2l-8.6,4.9c-0.2,0.1-0.2,0.3-0.2,0.4
                  c0,0.1,0,0.3,0.2,0.4l8.5,4.9c0.6,0.3,1.3,0.3,1.8,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.4l-8.5-4.9
                  C666.1,219.3,665.8,219.3,665.4,219.3z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4047_">
                  <g id="XMLID_4062_">
                    <g id="XMLID_4069_">
                      <path
                        id="XMLID_4070_"
                        style="fill: #ffffff"
                        d="M674.9,233.3l-21.4-14.8c-0.5-0.3-0.8-1-0.8-1.6l-2-16.3
                  c0-0.6,2.4-1.9,2.9-1.7l22.9,13.2c0.5,0.3,0.8,1,0.8,1.6v17.3C677.3,231.8,675.3,233.6,674.9,233.3z"
                      />
                    </g>
                    <g id="XMLID_4063_">
                      <path
                        id="XMLID_4065_"
                        d="M675,233.8c-0.1,0-0.2,0-0.3-0.1l0,0l-21.4-14.8c-0.6-0.3-1-1.2-1-1.9l-2-16.3
                  c0-0.6,0.8-1.2,1.4-1.5c0.6-0.3,1.6-0.8,2.1-0.5l22.9,13.2c0.6,0.3,1,1.2,1,2v17.3c0,0.7-1.3,2-2,2.4
                  C675.5,233.6,675.3,233.8,675,233.8z M651.1,200.7l2,16.2c0,0.5,0.3,1.1,0.6,1.3l21.3,14.7c0.5-0.1,1.8-1.4,1.8-1.8l0-17.3
                  c0-0.5-0.3-1-0.6-1.2l-22.9-13.2C652.9,199.3,651.3,200.3,651.1,200.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4052_">
                    <g id="XMLID_4059_">
                      <path
                        id="XMLID_4060_"
                        style="fill: #ffffff"
                        d="M674.4,233.3L651.5,220c-0.5-0.3-0.8-1-0.8-1.6v-17.3
                  c0-0.6,0.4-0.9,0.8-0.6l22.9,13.2c0.5,0.3,0.8,1,0.8,1.6v17.3C675.2,233.2,674.9,233.5,674.4,233.3z"
                      />
                    </g>
                    <g id="XMLID_4054_">
                      <path
                        id="XMLID_4055_"
                        d="M674.7,233.8c-0.2,0-0.4-0.1-0.5-0.2l-22.9-13.2c-0.6-0.3-1-1.2-1-2v-17.3c0-0.5,0.2-0.9,0.5-1
                  c0.3-0.2,0.6-0.1,1,0l22.9,13.2c0.6,0.3,1,1.2,1,2v17.3c0,0.5-0.2,0.9-0.5,1C675,233.7,674.9,233.8,674.7,233.8z
                   M674.6,232.9c0.1,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.3v-17.3c0-0.5-0.3-1-0.6-1.2l-22.9-13.2c-0.1,0-0.1,0-0.1,0
                  c0,0-0.1,0.1-0.1,0.3v17.3c0,0.5,0.3,1,0.6,1.2L674.6,232.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_4048_">
                    <g id="XMLID_4050_">
                      <path
                        id="XMLID_4051_"
                        d="M672.8,230.2l-19.7-11.3c-0.2-0.1-0.4-0.5-0.4-0.8v-14.3c0-0.3,0.2-0.5,0.4-0.3l19.7,11.3
                  c0.2,0.1,0.4,0.5,0.4,0.8v14.3C673.2,230.2,673,230.4,672.8,230.2z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_4043_">
                  <g id="XMLID_4044_">
                    <polygon
                      id="XMLID_4046_"
                      style="fill: #ffffff"
                      points="671.5,227.8 669,222.2 667.6,222.2 665.6,215.2 662.5,218 
                661.1,216.1 657.8,217.9 656.8,216.8 654.1,217.8 653.8,217.2 656.9,216.1 657.9,217.1 661.3,215.2 662.6,217 666,214 
                668.1,221.6 669.4,221.6 672.1,227.6               "
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="XMLID_3941_">
              <g id="XMLID_3987_">
                <g id="XMLID_3988_">
                  <g id="XMLID_3998_">
                    <g id="XMLID_4030_">
                      <g id="XMLID_4037_">
                        <path
                          id="XMLID_4039_"
                          style="fill: #ffffff"
                          d="M582.5,286.7l-11.7-6.8c-0.2-0.1-0.3-0.3-0.3-0.4l0,0v0c0,0,0,0,0,0l0-3.6
                    c0-0.1,0.1-0.2,0.2-0.2l5.9,0c0,0,0.1,0,0.1,0l5.8-3.3c0.5-0.3,1.1-0.3,1.5-0.1l5.9,3.5c0,0,0.1,0,0.1,0l5.9,0
                    c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.6,6.7C583.5,286.9,582.8,286.9,582.5,286.7z"
                        />
                      </g>
                      <g id="XMLID_4032_">
                        <path
                          id="XMLID_4033_"
                          d="M583.1,287.3c-0.3,0-0.6-0.1-0.9-0.2l0,0l-11.7-6.8c-0.3-0.2-0.5-0.5-0.5-0.8l0-3.6
                    c0-0.3,0.3-0.6,0.6-0.6l5.9,0l5.7-3.2c0.6-0.3,1.4-0.4,1.9-0.1l5.8,3.4l5.8,0c0.1,0,0.3,0.1,0.4,0.2
                    c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8l-11.6,6.7C583.9,287.2,583.5,287.3,583.1,287.3z M582.7,286.4
                    c0.2,0.1,0.8,0.1,1.1-0.1l11.6-6.7c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3l-5.6,0c-0.1,0-0.2,0-0.3-0.1l-5.9-3.5
                    c-0.2-0.1-0.8-0.1-1.1,0.1l-5.8,3.3c-0.1,0-0.2,0.1-0.3,0.1l-5.7,0l0,3.4c0,0,0,0,0.1,0.1L582.7,286.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_4021_">
                      <g id="XMLID_4027_">
                        <path
                          id="XMLID_4029_"
                          d="M590.6,275.7c0,0,5.2,0,5.2,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.5,6.6
                    c0,0-0.1,0-0.1,0l0-3.7c0,0,0,0,0,0l0,0C583.6,281.6,590.6,275.7,590.6,275.7z"
                        />
                      </g>
                      <g id="XMLID_4022_">
                        <path
                          id="XMLID_4024_"
                          d="M584.1,287.1l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-3.7c-0.2-1.4,4.1-5.2,6.7-7.4l0.1-0.1
                    c0.1,0,0.1,0,5.3,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8L584.1,287.1z M584.4,283
                    l0,3l11-6.3c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3c-1.4,0-4.1,0-4.8,0c-3.1,2.6-6.4,5.8-6.3,6.5L584.4,283L584.4,283z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_3999_">
                      <g id="XMLID_4014_">
                        <path
                          id="XMLID_4020_"
                          style="fill: #ffffff"
                          d="M595.7,275.3l-11.7-6.8c-0.4-0.2-1.1-0.2-1.5,0.1l-11.6,6.7
                    c-0.5,0.3-0.5,0.7-0.1,0.9l11.7,6.8c0.4,0.2,1.1,0.2,1.5-0.1l11.6-6.7C596.1,275.9,596.1,275.5,595.7,275.3z"
                        />
                      </g>
                      <g id="XMLID_4000_">
                        <path
                          id="XMLID_4003_"
                          d="M583.2,283.5c-0.3,0-0.6-0.1-0.9-0.2l-11.7-6.8c-0.3-0.2-0.5-0.4-0.5-0.7c0-0.3,0.2-0.6,0.6-0.9
                    l11.6-6.7c0.6-0.3,1.4-0.4,1.9-0.1l11.7,6.8c0.3,0.2,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.9l-11.6,6.7
                    C583.9,283.4,583.5,283.5,583.2,283.5z M570.9,275.7C570.9,275.7,570.9,275.7,570.9,275.7l11.8,6.8
                    c0.2,0.1,0.8,0.1,1.1-0.1l11.6-6.7c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0l0,0l-11.7-6.8c-0.2-0.1-0.8-0.1-1.1,0.1l-11.6,6.7
                    C571,275.6,570.9,275.7,570.9,275.7z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_3994_">
                    <g id="XMLID_3995_">
                      <rect
                        id="XMLID_3996_"
                        x="571.1"
                        y="278.9"
                        transform="matrix(2.985182e-02 -0.9996 0.9996 2.985182e-02 276.6808 843.6238)"
                        width="3.7"
                        height="0.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3990_">
                    <g id="XMLID_3991_">
                      <rect
                        id="XMLID_3992_"
                        x="591"
                        y="279.5"
                        transform="matrix(1.471350e-02 -0.9999 0.9999 1.471350e-02 304.1538 868.316)"
                        style="fill: #ffffff"
                        width="3.3"
                        height="0.6"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_3942_">
                <g id="XMLID_3952_">
                  <g id="XMLID_3978_">
                    <g id="XMLID_3984_">
                      <path
                        id="XMLID_3986_"
                        style="fill: #ffffff"
                        d="M589.5,265.4l-3.1-1.9c-0.2-0.1-0.4-0.1-0.5,0l0,0l0,0c0,0,0,0,0,0
                  l-3.1,1.8c-0.1,0-0.1,0.1-0.1,0.2l2.9,5.1c0,0,0,0.1,0,0.1v0.6c0,0.5,0.3,1.1,0.7,1.4l-2.8-1.5c0,0,0,0,0.1,0.1l2.9,5.1
                  c0,0.1,0.1,0.1,0.2,0.1l3.1-1.8c0.1-0.1,0.2-0.3,0.2-0.6v-7.3C590.2,266.2,589.8,265.6,589.5,265.4z"
                      />
                    </g>
                    <g id="XMLID_3979_">
                      <path
                        id="XMLID_3981_"
                        d="M586.7,276.9c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.3l-2.9-5.1c0,0,0.1,0.1,0.1,0.1l0.4-0.7
                  l1.5,0.8c0-0.1,0-0.2,0-0.3v-0.5l-2.9-5.1c-0.2-0.3-0.1-0.6,0.2-0.8l3.1-1.8c0.3-0.2,0.6-0.2,0.9,0l3.1,1.9
                  c0.5,0.3,0.9,1,0.9,1.7v7.3c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.9C586.9,276.9,586.8,276.9,586.7,276.9z M584.7,272.3l2.1,3.7
                  l2.9-1.7c0,0,0-0.1,0-0.2v-7.3c0-0.4-0.3-0.9-0.5-1l0,0l-3.1-1.9c0,0-0.1,0-0.1,0l-2.9,1.7l2.8,4.9c0,0.1,0.1,0.2,0.1,0.3
                  v0.6c0,0.4,0.3,0.9,0.5,1l-0.4,0.7L584.7,272.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3966_">
                    <g id="XMLID_3975_">
                      <path
                        id="XMLID_3977_"
                        d="M583.5,270.1c0,0,1.1-6.7,2.3-1l-2.1,2.1c0,0,0.1,0.1,0.1,0.1l2.9,5.1c0.1,0.1,0.2,0.1,0.3,0.1
                  l3.1-1.8c0.1-0.1,0.2-0.3,0.2-0.6v-7.2c0,0,0-0.1-0.1,0l-3.2,1.9c0,0,0,0,0,0L583.5,270.1z"
                      />
                    </g>
                    <g id="XMLID_3967_">
                      <path
                        id="XMLID_3968_"
                        d="M586.8,276.9c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.3l-2.9-5.1l-0.3-0.3l0.8-0.7l-0.8,0.3
                  l0.1-0.7c0.4-2.4,1-3.6,1.7-3.6c0,0,0,0,0,0c0.6,0,0.9,0.6,1.3,2.2l0.6-0.3v0l3.4-2l0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.4v7.2
                  c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.8C587,276.8,586.9,276.9,586.8,276.9z M584.1,271.3l2.7,4.7l2.9-1.7c0,0,0-0.1,0-0.2v-6.6
                  l-2.7,1.6l-1.2,0.5L584.1,271.3z M584.8,267.3c-0.2,0.3-0.5,1.1-0.7,2.1l1.2-0.5C585.1,267.9,584.9,267.5,584.8,267.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3954_">
                    <g id="XMLID_3962_">
                      <path
                        id="XMLID_3965_"
                        style="fill: #ffffff"
                        d="M586.2,276.5l-3.1-1.9c-0.4-0.2-0.7-0.8-0.7-1.4V266c0-0.5,0.3-0.8,0.7-0.5
                  l3.1,1.9c0.4,0.2,0.7,0.8,0.7,1.4v7.3C586.9,276.5,586.5,276.8,586.2,276.5z"
                      />
                    </g>
                    <g id="XMLID_3955_">
                      <path
                        id="XMLID_3956_"
                        d="M586.4,277c-0.2,0-0.3,0-0.5-0.1l0,0l-3.1-1.9c-0.5-0.3-0.9-1-0.9-1.7V266c0-0.4,0.2-0.8,0.5-0.9
                  c0.3-0.1,0.6-0.1,0.9,0l3.1,1.9c0.5,0.3,0.9,1,0.9,1.7v7.3c0,0.4-0.2,0.8-0.5,0.9C586.7,277,586.6,277,586.4,277z
                   M586.4,276.2C586.4,276.2,586.4,276.2,586.4,276.2c0.1,0,0.1,0,0.1-0.2v-7.3c0-0.4-0.3-0.9-0.5-1l-3.1-1.9c0,0-0.1,0-0.1,0
                  c0,0,0,0.1,0,0.2v7.3c0,0.4,0.3,0.9,0.5,1L586.4,276.2z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_3948_">
                  <g id="XMLID_3949_">
                    <rect
                      id="XMLID_3951_"
                      x="585.1"
                      y="265.3"
                      transform="matrix(0.8488 -0.5288 0.5288 0.8488 -51.7535 350.5583)"
                      width="3.7"
                      height="0.8"
                    />
                  </g>
                </g>
                <g id="XMLID_3944_">
                  <g id="XMLID_3945_">
                    <rect
                      id="XMLID_3947_"
                      x="586.9"
                      y="271.3"
                      transform="matrix(0.8567 -0.5157 0.5157 0.8567 -55.7656 342.447)"
                      style="fill: #ffffff"
                      width="3.3"
                      height="0.6"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="XMLID_3450_">
              <g id="XMLID_3932_">
                <g id="XMLID_3938_">
                  <path
                    id="XMLID_3940_"
                    d="M580.4,194.5c0,0-0.7-1-1.8-2.9c0-0.1,1-7.2-0.2-12.4c0,0,0,0,0,0c0.3,0.5,3.7,7.3,4.8,11.8
              c1.2,4.7,1.2,4.7,1.2,4.7s-1.2,3-4.2,6.4l-1.4,1.3c-0.1,0.1-0.3,0.1-0.4,0l-1.3-1.7c-0.1-0.1-0.1-0.2,0-0.3
              c0.3-0.7,1.5-3.9,2.1-4.8C579.9,195.4,580.4,194.5,580.4,194.5z"
                  />
                </g>
                <g id="XMLID_3933_">
                  <path
                    id="XMLID_3934_"
                    d="M578.5,203.9C578.5,203.9,578.5,203.8,578.5,203.9c-0.3,0-0.4-0.1-0.6-0.3l-1.3-1.7
              c-0.1-0.2-0.2-0.4-0.1-0.7c0.3-0.9,1.5-4,2.2-4.9c0.6-0.8,0.9-1.4,1.1-1.8c-0.3-0.4-0.9-1.3-1.7-2.8l-0.1-0.1l0-0.1
              c0-0.1,1-7.1-0.2-12.3l-0.1-0.4l0.7-0.3l0.2,0.3c0.4,0.7,3.7,7.4,4.9,11.9l1.2,4.8l-0.1,0.1c-0.1,0.1-1.3,3.1-4.3,6.5
              l-1.4,1.3C578.9,203.8,578.7,203.9,578.5,203.9z M577.4,201.4l1.1,1.6l1.3-1.2c2.6-2.9,3.8-5.6,4.1-6.1l-1.1-4.6
              c-0.7-2.9-2.4-6.8-3.6-9.3c0.4,4.3-0.1,8.8-0.2,9.7c1,1.8,1.7,2.8,1.7,2.8l0.1,0.2l-0.1,0.2c0,0-0.5,0.9-1.3,2.1
              C579,197.4,578.1,199.6,577.4,201.4z"
                  />
                </g>
              </g>
              <g id="XMLID_3922_">
                <g id="XMLID_3929_">
                  <path
                    id="XMLID_3930_"
                    style="fill: #ffffff"
                    d="M579.2,187.8c0,0,1.4-0.8,2.5-3.2c0,0-0.5-4.9-3.5-5.9
              c-3-1.1-0.5,6.6-0.5,6.6L579.2,187.8z"
                  />
                </g>
                <g id="XMLID_3924_">
                  <path
                    id="XMLID_3925_"
                    d="M579.1,188.4l-1.7-3c-0.3-0.9-1.8-5.7-0.5-6.9c0.3-0.2,0.7-0.5,1.5-0.2c3.2,1.2,3.7,6.1,3.7,6.3
              l0,0.1l0,0.1c-1.1,2.5-2.6,3.4-2.6,3.4L579.1,188.4z M578.1,185.1l1.2,2.1c0.4-0.4,1.3-1.2,1.9-2.7c-0.1-0.7-0.7-4.6-3.2-5.5
              c-0.5-0.2-0.6,0-0.6,0C576.8,179.7,577.4,183,578.1,185.1z"
                  />
                </g>
              </g>
              <g id="XMLID_3918_">
                <g id="XMLID_3919_">
                  <path
                    id="XMLID_3921_"
                    d="M580.5,194.9l-0.2-0.8c0.7-0.2,1.5,0,1.5,0l-0.2,0.8C581.6,194.9,581,194.8,580.5,194.9z"
                  />
                </g>
              </g>
              <g id="XMLID_3907_">
                <g id="XMLID_3914_">
                  <path
                    id="XMLID_3915_"
                    style="fill: #ffffff"
                    d="M579.3,248.3c0,0,0.6,0.3,1.3,2.1c0.6,1.4,2.6,4,3.4,4.6
              c0.8,0.7-0.4,3.4-3.1,2.1c-1.2-0.5-2.9-1.8-3.7-2.2c-1.2-0.6-2-1.6-2.2-2.9c-0.1-0.9-0.2-1.9,0-2.5c0.5-1.3,2.7-1.8,2.7-1.8
              L579.3,248.3z"
                  />
                </g>
                <g id="XMLID_3908_">
                  <path
                    id="XMLID_3909_"
                    d="M582.3,257.8c-0.4,0-0.9-0.1-1.5-0.3c-0.7-0.3-1.5-0.9-2.3-1.3c-0.6-0.4-1.1-0.7-1.4-0.9
              c-1.3-0.6-2.2-1.8-2.4-3.2c-0.2-1.2-0.2-2.1,0.1-2.7c0.6-1.5,2.9-2,3-2.1l0.1,0l1.6,0.6c0.1,0.1,0.8,0.4,1.6,2.3
              c0.5,1.3,2.5,3.8,3.3,4.5c0.5,0.4,0.6,1.3,0.1,2C584.1,257.3,583.3,257.8,582.3,257.8z M577.8,248.1c-0.6,0.2-1.9,0.7-2.3,1.5
              c-0.2,0.4-0.2,1.2,0,2.2c0.2,1.1,0.9,2.1,1.9,2.6c0.4,0.2,0.9,0.5,1.5,0.9c0.7,0.5,1.5,1,2.2,1.3c1.3,0.6,2.2,0.1,2.6-0.5
              c0.3-0.4,0.3-0.8,0.1-1c-0.9-0.7-3-3.4-3.6-4.8c-0.6-1.4-1.1-1.8-1.1-1.8L577.8,248.1z"
                  />
                </g>
              </g>
              <g id="XMLID_3897_">
                <g id="XMLID_3904_">
                  <path
                    id="XMLID_3905_"
                    style="fill: #ffffff"
                    d="M579.3,248.4c0,0,0.5,2,1.1,3c0.5,0.8-0.4,1.6-2.1,0.5c-1.6-1-2-3.5-2-3.5
              l2.7-0.4L579.3,248.4z"
                  />
                </g>
                <g id="XMLID_3898_">
                  <path
                    id="XMLID_3900_"
                    d="M579.8,252.9c-0.5,0-1-0.2-1.7-0.6c-1.8-1.1-2.2-3.6-2.2-3.7l-0.1-0.4l3.3-0.5l0.5,0.6l0,0.1
              c0,0,0.5,1.9,1.1,2.9c0.3,0.5,0.2,1-0.1,1.3C580.4,252.8,580.1,252.9,579.8,252.9z M576.8,248.8c0.2,0.7,0.7,2.1,1.8,2.8
              c0.8,0.5,1.4,0.5,1.5,0.4c0.1-0.1,0-0.2,0-0.3c-0.5-1-1-2.6-1.1-3l-0.1-0.1L576.8,248.8z"
                  />
                </g>
              </g>
              <g id="XMLID_3864_">
                <g id="XMLID_3886_">
                  <g id="XMLID_3894_">
                    <path
                      id="XMLID_3896_"
                      style="fill: #ffffff"
                      d="M556.8,230.5c0,0-0.8,3,0.3,5.5s-1.5,4.1-3.6,1.5c-2.2-2.6-2.2-5.9-2.2-5.9
                l0,0c-0.4-0.5-0.4-2.4,0.1-2.9c0.7-0.6,1.8-1.2,3.3-0.8C557.4,228.6,556.8,230.5,556.8,230.5z"
                    />
                  </g>
                  <g id="XMLID_3887_">
                    <path
                      id="XMLID_3889_"
                      d="M555.8,239.2c-0.8,0-1.7-0.4-2.6-1.5c-2-2.4-2.2-5.4-2.3-6c-0.4-0.8-0.4-2.7,0.2-3.3
                c0.7-0.7,2-1.4,3.7-0.9c1.1,0.3,1.8,0.8,2.2,1.5c0.5,0.8,0.3,1.7,0.3,1.7l0,0c0,0-0.8,2.9,0.2,5.2c0.7,1.5,0.2,2.6-0.6,3.1
                C556.5,239.1,556.2,239.2,555.8,239.2z M553.7,228.2c-0.9,0-1.6,0.4-2.1,0.8c-0.3,0.3-0.3,2,0,2.3l0.1,0.1l0,0.1
                c0,0,0.1,3.2,2.1,5.6c1,1.2,2,1.4,2.6,1c0.5-0.3,0.8-1.1,0.3-2c-1.1-2.5-0.3-5.5-0.3-5.7c0-0.2,0.3-1.6-1.9-2.1
                C554.2,228.2,554,228.2,553.7,228.2z"
                    />
                  </g>
                </g>
                <g id="XMLID_3875_">
                  <g id="XMLID_3883_">
                    <path
                      id="XMLID_3885_"
                      style="fill: #ffffff"
                      d="M556.8,230.5l-0.7,2.1c-0.5,0.5-1.8-0.6-2.1-1.2c-0.1-0.3-0.2-0.6-0.3-1
                c-0.3-1.5,0.9-2.6,0.9-2.6L556.8,230.5z"
                    />
                  </g>
                  <g id="XMLID_3877_">
                    <path
                      id="XMLID_3878_"
                      d="M555.8,233.2c-0.1,0-0.1,0-0.2,0c-0.7-0.1-1.7-0.9-2-1.5c-0.2-0.3-0.3-0.7-0.3-1.1
                c-0.3-1.7,1-2.9,1.1-3l0.3-0.3l2.6,3.1l-0.8,2.5l-0.1,0.1C556.3,233.1,556.1,233.2,555.8,233.2z M554.6,228.5
                c-0.3,0.4-0.7,1.1-0.5,1.9c0.1,0.3,0.2,0.7,0.3,0.9c0.2,0.4,1.1,1,1.4,1l0.6-1.7L554.6,228.5z"
                    />
                  </g>
                </g>
                <g id="XMLID_3866_">
                  <g id="XMLID_3873_">
                    <path
                      id="XMLID_3874_"
                      d="M577.1,206.8c0,0,1.3,8.6-4.4,17l-2.9,2.3l-5.1,1.3l-8,3c-0.1,0-0.3,0-0.4,0l-0.3-0.1
                c-0.7-0.3-1.2-1-1.3-1.7l-0.1-0.5c0-0.2,0-0.4,0.2-0.5c0.9-0.7,4-3,5.9-4.3c2.9-2,4.6-3.1,6.6-3.5c2-0.4-0.8-13.5-0.8-13.5
                l5.3-2.5L577.1,206.8z"
                    />
                  </g>
                  <g id="XMLID_3867_">
                    <path
                      id="XMLID_3868_"
                      d="M556.5,231c-0.1,0-0.3,0-0.4-0.1l-0.3-0.1c-0.8-0.4-1.4-1.1-1.5-2l-0.1-0.5
                c-0.1-0.4,0.1-0.7,0.4-0.9c0.9-0.7,4-3,5.9-4.3c2.7-1.9,4.6-3.1,6.8-3.6c0.8-0.2,0.3-6.2-1.1-13l-0.1-0.3l5.8-2.8l5.7,3.1
                l0,0.2c0,0.1,1.3,8.9-4.5,17.3l-0.1,0.1l-3,2.4l-5.1,1.4l-8,3C556.7,231,556.6,231,556.5,231z M567,206.6
                c0.9,4.2,2.5,13.2,0.4,13.6c-2,0.4-3.8,1.6-6.5,3.5c-1.9,1.3-4.9,3.6-5.8,4.3c0,0,0,0.1,0,0.1l0.1,0.5
                c0.1,0.6,0.5,1.1,1.1,1.4l0.3,0.1c0,0,0.1,0,0.1,0l8-3l5-1.3l2.8-2.3c5.1-7.5,4.5-15.2,4.4-16.5l-5-2.7L567,206.6z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_3855_">
                <g id="XMLID_3862_">
                  <path
                    id="XMLID_3863_"
                    d="M572.9,209.5c0,0,5.6,15.9,5.7,19.2c0.1,3,0.7,16.9,0.8,19.5c0,0.3-0.1,0.5-0.4,0.6l0,0
              c-0.8,0.3-1.6,0.3-2.4,0l-0.1-0.1c-0.2-0.1-0.3-0.3-0.4-0.5c-0.2-1.1-0.9-4.8-2.1-7.8c-1.4-3.6-0.5-9.4-0.5-9.4
              s-2-5.6-4.7-8.2c-2.8-2.5-6.8-9.2-6.8-13.5C562.1,205.1,572.9,209.5,572.9,209.5z"
                  />
                </g>
                <g id="XMLID_3856_">
                  <path
                    id="XMLID_3858_"
                    d="M577.8,249.5c-0.4,0-0.9-0.1-1.3-0.2l-0.1-0.1c-0.3-0.1-0.6-0.4-0.6-0.8c-0.2-1.1-0.8-4.8-2-7.8
              c-1.4-3.5-0.6-8.7-0.5-9.5c-0.3-0.7-2.1-5.7-4.6-7.9c-2.8-2.5-6.9-9.4-6.9-13.8c0-0.8,0.3-1.4,0.9-1.7
              c2.5-1.7,9.7,1.2,10.5,1.5l0.2,0.1l0.1,0.2c0.2,0.7,5.6,16,5.7,19.3c0.1,3,0.7,16.8,0.8,19.5c0,0.4-0.2,0.8-0.6,1
              C578.7,249.4,578.3,249.5,577.8,249.5z M564.8,207.9c-0.7,0-1.3,0.1-1.7,0.4c-0.4,0.2-0.5,0.6-0.5,1.1
              c0,4.1,3.9,10.7,6.6,13.2c2.8,2.6,4.8,8.1,4.9,8.4l0,0.1l0,0.1c0,0.1-0.9,5.7,0.4,9.2c1.2,3.1,1.9,6.8,2.1,7.9
              c0,0.1,0.1,0.1,0.1,0.2l0.1,0.1c0.7,0.3,1.4,0.3,2.1,0c0.1,0,0.1-0.1,0.1-0.2c-0.1-2.7-0.8-16.5-0.8-19.5
              c-0.1-3-5-17.2-5.6-18.9C570.9,209.2,567.2,207.9,564.8,207.9z"
                  />
                </g>
              </g>
              <g id="XMLID_3845_">
                <g id="XMLID_3852_">
                  <path
                    id="XMLID_3853_"
                    style="fill: #ffffff"
                    d="M558.3,183.4c1.6-3,7.7-5.2,7.7-5.2l2.5,0.4l3.8-1.8c0,0,5.4,0.8,6.2,1.9
              c0.8,1.1,1.3,8.8,0.6,11.7c-0.7,2.9-0.2,16.4-0.2,16.4s-2.3,1.4-3.1,1.9c-0.8,0.5-1.7-1.9-1.7-1.9l-0.2,0
              c-0.4,1.7-0.7,2.8-0.7,2.8s-13.8,2.8-12.7-3.7c1.1-6.4-0.3-16.4-0.3-16.4S556.8,186.3,558.3,183.4z"
                  />
                </g>
                <g id="XMLID_3846_">
                  <path
                    id="XMLID_3848_"
                    d="M567.3,210.6c-2.4,0-4.9-0.4-6.2-1.6c-0.8-0.8-1.1-1.8-0.9-3.1c1-5.9-0.1-14.9-0.2-16.1
              c-0.6-0.6-3.5-3.6-1.9-6.5c1.6-3.1,7.6-5.3,7.9-5.4l0.1,0l2.5,0.4l3.8-1.8l0.1,0c0.9,0.1,5.6,0.9,6.5,2.1
              c0.9,1.2,1.4,9.1,0.6,12c-0.7,2.8-0.1,16.2-0.1,16.3l0,0.2l-0.2,0.1c-0.1,0.1-2.3,1.5-3.1,1.9c-0.2,0.1-0.5,0.2-0.7,0.1
              c-0.5-0.2-0.9-0.8-1.2-1.3c-0.3,1.1-0.5,1.8-0.5,1.9l-0.1,0.2l-0.2,0C573.2,210,570.3,210.6,567.3,210.6z M566,178.6
              c-0.8,0.3-6,2.4-7.3,5c-1.4,2.6,1.8,5.6,1.9,5.6l0.1,0.1l0,0.2c0.1,0.4,1.3,10.1,0.3,16.5c-0.2,1,0,1.8,0.7,2.4
              c2.4,2.3,9.9,1.1,11.4,0.9c0.1-0.4,0.4-1.3,0.6-2.5l0.1-0.4l0.8,0.1l0.1,0.2c0.4,0.9,0.9,1.7,1.1,1.7c0.6-0.4,2.3-1.4,2.9-1.8
              c-0.1-1.9-0.5-13.5,0.2-16.3c0.8-3.1,0.2-10.5-0.5-11.3c-0.5-0.6-3.7-1.4-5.9-1.8l-3.8,1.8L566,178.6z"
                  />
                </g>
              </g>
              <g id="XMLID_3835_">
                <g id="XMLID_3842_">
                  <path
                    id="XMLID_3844_"
                    style="fill: #ffffff"
                    d="M568.4,175.6c0,0,0,1-0.6,2.5c0,0,0.7,1.1,1.8,2c1.3,1,3.1,1.2,4.6,0.5l0,0
              l0,0c0,0-0.8-2.6-0.7-4.2C573.7,174.8,568.4,175.6,568.4,175.6z"
                  />
                </g>
                <g id="XMLID_3837_">
                  <path
                    id="XMLID_3838_"
                    d="M572.3,181.3c-1.1,0-2.1-0.3-3-1c-1.2-0.9-1.9-2-1.9-2.1l-0.1-0.2l0.1-0.2c0.6-1.4,0.6-2.3,0.6-2.3
              l0-0.4l0.3-0.1c0.7-0.1,4.3-0.6,5.3,0.4c0.2,0.2,0.4,0.5,0.3,0.8c-0.1,1.5,0.6,4,0.7,4l0.1,0.4l-0.4,0.1
              C573.7,181.2,573,181.3,572.3,181.3z M568.2,178c0.2,0.3,0.8,1,1.6,1.7c1.1,0.8,2.5,1,3.8,0.6c-0.2-0.8-0.7-2.7-0.6-4
              c0-0.1,0-0.1-0.1-0.2c-0.5-0.5-2.7-0.4-4.3-0.2C568.7,176.4,568.6,177.1,568.2,178z"
                  />
                </g>
              </g>
              <g id="XMLID_3826_">
                <g id="XMLID_3833_">
                  <path
                    id="XMLID_3834_"
                    style="fill: #ffffff"
                    d="M568.2,176.8c0,0-2.7,0.5-3.1,2.3c0,0,1.8,3.7,6.8,4.3l0.4-2.5
              c0,0-1.4,0-3-1.1C568.3,179.1,567.8,177.9,568.2,176.8L568.2,176.8z"
                  />
                </g>
                <g id="XMLID_3828_">
                  <path
                    id="XMLID_3829_"
                    d="M572.3,183.8l-0.4,0c-5.1-0.5-7-4.3-7.1-4.5l-0.1-0.1l0-0.1c0.4-2.2,3.3-2.7,3.4-2.7l0.7-0.1
              l-0.2,0.7c-0.3,0.9,0.1,2,0.9,2.5c1.4,1,2.8,1,2.8,1h0l0.5,0L572.3,183.8z M565.6,179.1c0.3,0.6,2.1,3.3,6,3.9l0.2-1.6
              c-0.6-0.1-1.7-0.3-2.8-1.1c-0.9-0.7-1.4-1.8-1.4-2.8C566.9,177.6,565.8,178.1,565.6,179.1z"
                  />
                </g>
              </g>
              <g id="XMLID_3817_">
                <g id="XMLID_3823_">
                  <path
                    id="XMLID_3825_"
                    style="fill: #ffffff"
                    d="M573.2,180.9l1.4,2.1c0,0,1.9-1.5,1-4.2l-2,0.3
              C573.5,179,574,180,573.2,180.9z"
                  />
                </g>
                <g id="XMLID_3818_">
                  <path
                    id="XMLID_3820_"
                    d="M574.5,183.5l-1.8-2.7l0.2-0.2c0.6-0.6,0.3-1.4,0.3-1.4l-0.2-0.5l2.9-0.4l0.1,0.3
              c1,3-1.1,4.7-1.1,4.7L574.5,183.5z M573.7,180.9l0.9,1.4c0.4-0.5,1-1.5,0.6-3.1l-1.2,0.2C574.1,179.7,574.1,180.3,573.7,180.9
              z"
                  />
                </g>
              </g>
              <g id="XMLID_3807_">
                <g id="XMLID_3814_">
                  <path
                    id="XMLID_3816_"
                    d="M576.3,169.3c0,0,0.9,2.1,0.6,5.2c-0.1,1.3-0.5,2.5-1,3.6l-0.3,0.7c-0.1,0.3-0.4,0.4-0.7,0.4
              c-1,0-3.4-0.3-5.4-2.1l-0.7-0.9c-0.3-0.4-0.5-0.9-0.5-1.4l0,0c0,0-0.9-0.2-1.2-1.1c-0.2-0.5-0.1-1,0.1-1.5l0,0
              c0.2-0.3,0.5-0.4,0.8-0.3l0.7-0.9l2.4-3.1L576.3,169.3z"
                  />
                </g>
                <g id="XMLID_3809_">
                  <path
                    id="XMLID_3810_"
                    d="M574.9,179.5C574.9,179.5,574.9,179.5,574.9,179.5c-1,0-3.6-0.3-5.7-2.2l-0.8-0.9
              c-0.3-0.4-0.5-0.8-0.6-1.3c-0.4-0.2-0.9-0.5-1.2-1.2c-0.2-0.6-0.2-1.2,0.1-1.8c0.2-0.4,0.6-0.6,1-0.6l3.1-4l5.7,1.5l0.1,0.2
              c0,0.1,1,2.2,0.6,5.4c-0.1,1.2-0.5,2.5-1.1,3.8l-0.3,0.7C575.7,179.3,575.3,179.5,574.9,179.5z M567.7,172.3
              c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.4-0.2,0.8-0.1,1.1c0.3,0.7,0.9,0.8,0.9,0.8l0.3,0.1v0.3c0,0.4,0.1,0.8,0.4,1.1l0.7,0.9
              c1.9,1.7,4.2,2,5.1,2c0.1,0,0.2-0.1,0.3-0.2l0.3-0.7c0.5-1.2,0.9-2.4,1-3.5c0.3-2.4-0.3-4.2-0.5-4.8l-4.7-1.3l-3.1,4.1
              L567.7,172.3C567.8,172.3,567.8,172.3,567.7,172.3z"
                  />
                </g>
              </g>
              <g id="XMLID_3803_">
                <g id="XMLID_3805_">
                  <path
                    id="XMLID_3806_"
                    d="M574,206.8l-0.8-0.1c1.4-11.1,0-21.7,0-21.8l0.8-0.1C574,184.9,575.4,195.6,574,206.8z"
                  />
                </g>
              </g>
              <g id="XMLID_3799_">
                <g id="XMLID_3801_">
                  <path
                    id="XMLID_3802_"
                    style="fill: #ffffff"
                    d="M570.2,225c0,0-0.4-0.7-0.9-1.2l-0.2-0.2c-0.7-0.7-2.3-2.4-2.9-3.3l0.5-0.4
              c0.6,0.8,2.1,2.5,2.8,3.2l0.2,0.2c0.5,0.5,0.9,1.2,1,1.2L570.2,225z"
                  />
                </g>
              </g>
              <g id="XMLID_3795_">
                <g id="XMLID_3797_">
                  <rect
                    id="XMLID_3798_"
                    x="574"
                    y="209.9"
                    transform="matrix(0.9476 -0.3193 0.3193 0.9476 -38.1671 194.583)"
                    style="fill: #ffffff"
                    width="0.6"
                    height="7.5"
                  />
                </g>
              </g>
              <g id="XMLID_3791_">
                <g id="XMLID_3792_">
                  <path
                    id="XMLID_3794_"
                    style="fill: #ffffff"
                    d="M563.6,215.3l-0.1-0.6c1-0.2,1.7-0.6,2.1-1.3c0.6-1.2,0.1-2.7,0.1-2.7
              l0.6-0.2c0,0.1,0.7,1.8-0.1,3.2C565.7,214.5,564.8,215.1,563.6,215.3z"
                  />
                </g>
              </g>
              <g id="XMLID_3787_">
                <g id="XMLID_3788_">
                  <path
                    id="XMLID_3790_"
                    style="fill: #ffffff"
                    d="M573.8,231.3l-0.5-0.4c0.7-0.8,1.2-1,1.2-1.1l0.3,0.6
              C574.9,230.4,574.4,230.6,573.8,231.3z"
                  />
                </g>
              </g>
              <g id="XMLID_3783_">
                <g id="XMLID_3784_">
                  <path
                    id="XMLID_3786_"
                    style="fill: #ffffff"
                    d="M577.4,247.8c-0.5,0-1.1-0.1-1.7-0.4l0.3-0.6c2,0.9,3.1-0.3,3.1-0.3l0.5,0.4
              C579.5,247,578.8,247.8,577.4,247.8z"
                  />
                </g>
              </g>
              <g id="XMLID_3779_">
                <g id="XMLID_3780_">
                  <path
                    id="XMLID_3782_"
                    style="fill: #ffffff"
                    d="M557.5,230.1c-0.1,0-1.7-0.9-2-3.1l0.6-0.1c0.2,1.9,1.6,2.6,1.7,2.6
              L557.5,230.1z"
                  />
                </g>
              </g>
              <g id="XMLID_3775_">
                <g id="XMLID_3776_">
                  <path
                    id="XMLID_3777_"
                    d="M580.3,254.6v-0.8c1.1,0,1.8-0.9,1.8-1l0.7,0.5C582.7,253.3,581.8,254.6,580.3,254.6z"
                  />
                </g>
              </g>
              <g id="XMLID_3771_">
                <g id="XMLID_3772_">
                  <path
                    id="XMLID_3773_"
                    d="M555.6,234.6c-0.5,0-1.1-0.1-1.7-0.5l0.5-0.7c0.9,0.7,2.1,0.2,2.1,0.2l0.3,0.8
              C556.8,234.4,556.3,234.6,555.6,234.6z"
                  />
                </g>
              </g>
              <g id="XMLID_3766_">
                <g id="XMLID_3768_">
                  <path
                    id="XMLID_3769_"
                    d="M555.5,236.2c-0.4,0-0.9-0.1-1.5-0.3l0.3-0.8c1.5,0.6,2.2-0.1,2.2-0.1l0.6,0.5
              C557.1,235.6,556.6,236.2,555.5,236.2z"
                  />
                </g>
              </g>
              <g id="XMLID_3762_">
                <g id="XMLID_3764_">
                  <path
                    id="XMLID_3765_"
                    d="M581.6,255.5l-0.2-0.8c0.9-0.3,1.6-1.1,1.6-1.1l0.7,0.5C583.6,254.2,582.8,255.2,581.6,255.5z"
                  />
                </g>
              </g>
              <g id="XMLID_3758_">
                <g id="XMLID_3760_">
                  <path
                    id="XMLID_3761_"
                    d="M566.6,180.8l-0.7-0.4c0.7-1.4,2.2-2.1,2.3-2.1l0.3,0.8C568.5,179.1,567.1,179.7,566.6,180.8z"
                  />
                </g>
              </g>
              <g id="XMLID_3754_">
                <g id="XMLID_3755_">
                  <path
                    id="XMLID_3757_"
                    style="fill: #ffffff"
                    d="M568.6,175l-0.6-0.1c0.1-0.8,0.5-1.2,0.6-1.3l0.5,0.4
              C568.9,174.1,568.6,174.4,568.6,175z"
                  />
                </g>
              </g>
              <g id="XMLID_3745_">
                <g id="XMLID_3751_">
                  <path
                    id="XMLID_3753_"
                    d="M579.7,166.7c0-0.6-0.3-1-0.8-1.3c0,0,0-0.1,0-0.1c0.3-0.3,0.5-0.7,0.5-1.1c0-0.8-0.6-1.4-1.4-1.5
              c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.3,0.1-0.4c0-0.8-0.7-1.5-1.5-1.5c-0.2,0-0.3,0-0.5,0.1c0,0-0.1-0.1-0.1-0.1
              c0-0.8-0.7-1.5-1.5-1.5c-0.3,0-0.6,0.1-0.8,0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.7-0.7-1.2-1.4-1.2c-0.5,0-1,0.3-1.2,0.7
              c0,0-0.1,0-0.1,0c-0.3-0.3-0.7-0.5-1.1-0.5c-0.6,0-1.1,0.4-1.3,0.9c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2-0.1-0.5-0.2-0.8-0.2
              c-0.8,0-1.5,0.6-1.5,1.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2-0.1-0.4-0.2-0.7-0.2c-0.8,0-1.5,0.7-1.5,1.5c0,0.3,0.1,0.5,0.2,0.7
              c-0.1,0.1-0.1,0.2-0.2,0.2c0,0-0.1,0-0.1,0c-0.8,0-1.5,0.7-1.5,1.5c0,0.3,0.1,0.5,0.2,0.7c-0.5,0.2-0.9,0.8-0.9,1.4
              c0,0.4,0.1,0.7,0.4,1c-0.4,0.3-0.7,0.7-0.7,1.3c0,0.6,0.4,1.2,1,1.4c0,0,0,0.1,0,0.1c-0.4,0.3-0.7,0.7-0.7,1.2
              c0,0.8,0.7,1.5,1.5,1.5c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.8,0.7,1.5,1.5,1.5c0.1,0,0.3,0,0.4-0.1c0.1,0.8,0.7,1.3,1.5,1.3
              c0.3,0,0.7-0.1,0.9-0.3c0.6,0.1,1.1,0.2,1.4,0.2c0.1,0,0.2-0.1,0.1-0.2c-0.2-0.2-0.5-0.6-0.9-1.3c0-0.1-0.1-0.2-0.1-0.2
              c0-0.1-0.1-0.2-0.1-0.3c-0.3-1-0.1-1.6,0.4-2.2c0.6-0.7,1.9,0.4,1.9,0.4s-0.2-2,2.5-3.1c1.7-0.7,3.2-0.3,4.1,0.2
              c0.2,0.6,0.7,1,1.4,1c0.8,0,1.5-0.7,1.5-1.5c0-0.2,0-0.3-0.1-0.5C579.2,168,579.7,167.4,579.7,166.7z"
                  />
                </g>
                <g id="XMLID_3746_">
                  <path
                    id="XMLID_3748_"
                    d="M565.5,176.3c-0.8,0-1.5-0.5-1.7-1.3c-1.1,0.1-1.9-0.7-2-1.7c-0.9-0.2-1.5-0.9-1.5-1.8
              c0-0.5,0.2-0.9,0.5-1.3c-0.5-0.3-0.8-0.9-0.8-1.5c0-0.5,0.2-1,0.6-1.3c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.6,0.3-1.2,0.8-1.5
              c-0.1-0.2-0.1-0.4-0.1-0.6c0-1,0.8-1.8,1.7-1.8c-0.1-0.2-0.1-0.4-0.1-0.6c0-1,0.8-1.8,1.8-1.8c0.2,0,0.4,0,0.6,0.1
              c0,0,0,0,0,0c0.1-0.9,0.9-1.6,1.8-1.6c0.3,0,0.5,0.1,0.8,0.2c0.1,0,0.1-0.1,0.2-0.1c0.3-0.6,0.9-0.9,1.6-0.9
              c0.4,0,0.8,0.1,1.1,0.4c0.3-0.4,0.8-0.6,1.3-0.6c0.8,0,1.5,0.5,1.7,1.2c0.3-0.1,0.5-0.2,0.8-0.2c0.9,0,1.6,0.7,1.8,1.5
              c1.1-0.2,2.1,0.7,2.1,1.8c0,0.1,0,0.2,0,0.3c0.9,0.1,1.5,0.9,1.5,1.8c0,0.4-0.2,0.8-0.4,1.2c0.4,0.3,0.7,0.8,0.7,1.4
              c0,0.7-0.5,1.4-1.1,1.7c0,0.1,0,0.2,0,0.3c0,1-0.8,1.8-1.8,1.8c-0.7,0-1.4-0.4-1.7-1.1c-0.9-0.5-2.2-0.8-3.7-0.1
              c-2.4,1-2.3,2.7-2.3,2.8l0.1,0.8l-0.6-0.5c0,0-1.1-0.8-1.5-0.4c-0.5,0.6-0.6,1.1-0.4,1.9c0.1,0.1,0.1,0.2,0.1,0.4l0,0
              c0,0,0,0.1,0.1,0.1c0.4,0.7,0.6,1,0.8,1.2c0.1,0.1,0.2,0.3,0.1,0.5c-0.1,0.2-0.3,0.3-0.4,0.3c-0.3,0-0.8-0.1-1.3-0.2
              C566.2,176.2,565.9,176.3,565.5,176.3z M564.3,174.2l0,0.4c0.1,0.6,0.5,1.1,1.2,1.1c0.3,0,0.5-0.1,0.7-0.3l0.1-0.1l0.2,0
              c0.3,0.1,0.6,0.1,0.9,0.2c-0.2-0.3-0.4-0.6-0.6-1c0-0.1-0.1-0.1-0.1-0.2l0-0.1c0-0.1-0.1-0.2-0.1-0.3c-0.4-1-0.2-1.8,0.5-2.6
              c0.5-0.6,1.3-0.3,1.9,0c0.1-0.7,0.7-2,2.6-2.8c1.9-0.8,3.4-0.3,4.4,0.2l0.1,0.1l0,0.1c0.2,0.5,0.6,0.8,1.1,0.8
              c0.6,0,1.2-0.5,1.2-1.2c0-0.1,0-0.2-0.1-0.4l-0.1-0.3l0.4-0.1c0.5-0.1,0.9-0.6,0.9-1.1c0-0.4-0.2-0.8-0.6-1l-0.2-0.1l0-0.5
              l0.1-0.1c0.3-0.2,0.4-0.6,0.4-0.9c0-0.6-0.5-1.1-1.1-1.2l-0.2,0l-0.3-0.4l0-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.6-0.5-1.2-1.2-1.2
              c-0.1,0-0.2,0-0.4,0.1L576,161l-0.4-0.3l0-0.2c0-0.6-0.5-1.2-1.2-1.2c-0.2,0-0.4,0.1-0.6,0.2l-0.1,0.1l-0.2-0.1
              c-0.1,0-0.1,0-0.2-0.1l-0.3-0.1l0-0.2c-0.1-0.5-0.6-0.9-1.1-0.9c-0.4,0-0.8,0.2-1,0.5l-0.1,0.1l-0.3,0l-0.2-0.1
              c-0.2-0.3-0.5-0.4-0.9-0.4c-0.5,0-0.9,0.3-1.1,0.7l-0.1,0.1l-0.1,0c-0.2,0-0.3,0.1-0.5,0.2l-0.1,0.1l-0.1-0.1
              c-0.2-0.1-0.4-0.2-0.6-0.2c-0.6,0-1.1,0.5-1.2,1.1l0,0.2l-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2l-0.2,0.1L565,161
              c-0.8-0.4-1.7,0.2-1.7,1c0,0.2,0.1,0.4,0.2,0.6l0.1,0.2l-0.4,0.6l-0.3,0c-0.6,0-1.2,0.5-1.2,1.2c0,0.2,0.1,0.4,0.2,0.6
              l0.2,0.3l-0.3,0.1c-0.4,0.2-0.7,0.6-0.7,1.1c0,0.3,0.1,0.6,0.3,0.8l0.3,0.3l-0.3,0.2c-0.4,0.2-0.6,0.6-0.6,1
              c0,0.5,0.3,0.9,0.8,1.1l0.2,0.1l0.1,0.5l-0.2,0.1c-0.3,0.2-0.5,0.6-0.5,1c0,0.6,0.5,1.2,1.2,1.2l0.4,0l0,0.4
              c0,0.1,0,0.1,0,0.2c0,0.6,0.5,1.2,1.2,1.2c0.1,0,0.2,0,0.3-0.1L564.3,174.2z"
                  />
                </g>
              </g>
              <g id="XMLID_3741_">
                <g id="XMLID_3742_">
                  <path
                    id="XMLID_3744_"
                    style="fill: #ffffff"
                    d="M567.3,175.4c-0.1-0.1-1.3-1.4-0.9-2.9c0.2-0.7,0.6-1.2,1.1-1.4
              c0.5-0.1,1,0,1.5,0.3l0.1-0.1c0-0.6,0.4-2.2,3-3.1c3.1-1,4.2,0.5,4.7,1.2l0.1,0.1l-0.5,0.4l-0.1-0.1c-0.5-0.6-1.4-1.8-4-1
              c-2.7,0.9-2.6,2.6-2.6,2.7l0,0.2l-0.9,0.4l-0.2-0.1c-0.3-0.3-0.7-0.4-1-0.3c-0.3,0.1-0.6,0.4-0.7,0.9
              c-0.3,1.2,0.7,2.3,0.8,2.3L567.3,175.4z"
                  />
                </g>
              </g>
              <g id="XMLID_3737_">
                <g id="XMLID_3738_">
                  <path
                    id="XMLID_3739_"
                    style="fill: #ffffff"
                    d="M565.5,173c-1.4,0-2.4-0.4-3.1-1.2c-0.9-1-0.7-2.3-0.7-2.4l0.6,0.1
              c0,0-0.1,1.1,0.6,1.9c0.5,0.6,1.4,0.9,2.6,1L565.5,173z"
                  />
                </g>
              </g>
              <g id="XMLID_3732_">
                <g id="XMLID_3734_">
                  <path
                    id="XMLID_3735_"
                    style="fill: #ffffff"
                    d="M577.3,167.9l-0.5-0.4c0.8-1.2,0.8-2.4,0.2-3.7c-1.7-3.3-5.5-3.5-5.5-3.5
              l0-0.6c0.2,0,4.2,0.2,6.1,3.8C578.4,165.4,577.9,166.9,577.3,167.9z"
                  />
                </g>
              </g>
              <g id="XMLID_3728_">
                <g id="XMLID_3730_">
                  <path
                    id="XMLID_3731_"
                    style="fill: #ffffff"
                    d="M573.2,162.9c-3-1.6-5.4-0.1-5.5,0l-0.4-0.5c0.1-0.1,2.8-1.8,6.2,0
              L573.2,162.9z"
                  />
                </g>
              </g>
              <g id="XMLID_3724_">
                <g id="XMLID_3725_">
                  <path
                    id="XMLID_3727_"
                    style="fill: #ffffff"
                    d="M563.1,170.2c0,0-0.4-1,0-1.9c0.2-0.4,0.6-0.7,1.1-0.9l0.2,0.6
              c-0.3,0.1-0.6,0.3-0.7,0.6c-0.3,0.6,0,1.4,0,1.4L563.1,170.2z"
                  />
                </g>
              </g>
              <g id="XMLID_3719_">
                <g id="XMLID_3721_">
                  <path
                    id="XMLID_3722_"
                    style="fill: #ffffff"
                    d="M574.4,175.4c-0.2,0-0.4,0-0.6-0.1l0.2-0.6c0.1,0,0.3,0.1,0.5,0l0.4-0.1
              l-0.3,0.1l-0.4-1.8l0.6-0.1l0.4,1.8c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.5,0.3C574.5,175.4,574.4,175.4,574.4,175.4z"
                  />
                </g>
              </g>
              <g id="XMLID_3715_">
                <g id="XMLID_3717_">
                  <path
                    id="XMLID_3718_"
                    style="fill: #ffffff"
                    d="M572.9,172.5c0.1,0.5-0.1,0.9-0.4,1c-0.3,0.1-0.7-0.3-0.8-0.7
              c-0.1-0.5,0.1-0.9,0.4-1C572.5,171.7,572.8,172,572.9,172.5z"
                  />
                </g>
              </g>
              <g id="XMLID_3711_">
                <g id="XMLID_3713_">
                  <path
                    id="XMLID_3714_"
                    style="fill: #ffffff"
                    d="M576.5,171.9c0.1,0.5-0.1,0.9-0.3,1c-0.3,0.1-0.6-0.3-0.7-0.8
              c-0.1-0.5,0.1-0.9,0.3-1C576.1,171.1,576.4,171.4,576.5,171.9z"
                  />
                </g>
              </g>
              <g id="XMLID_3707_">
                <g id="XMLID_3709_">
                  <path
                    id="XMLID_3710_"
                    style="fill: #ffffff"
                    d="M574.4,177.4c-0.6,0-1.1-0.3-1.1-0.3l0.4-0.5c0,0,0.7,0.5,1.5,0l0.3,0.5
              C575.1,177.3,574.7,177.4,574.4,177.4z"
                  />
                </g>
              </g>
              <g id="XMLID_3532_">
                <g id="XMLID_3697_">
                  <g id="XMLID_3704_">
                    <path
                      id="XMLID_3706_"
                      style="fill: #ffffff"
                      d="M576.6,186.8c0,0-0.9,4.6,0.9,11.6c1.8,7,3.1,7,3.1,7v-7.9
                c0,0-2.1-5.3-0.9-10.6l-0.1-1.2l-1.4-0.6l-1.1,0.6L576.6,186.8z"
                    />
                  </g>
                  <g id="XMLID_3699_">
                    <path
                      id="XMLID_3700_"
                      d="M581.1,205.9l-0.4,0c-0.5,0-1.8-0.7-3.5-7.3c-1.8-7-1-11.6-0.9-11.8l0-0.1l0.5-1.2l1.4-0.8l1.9,0.8
                l0.1,1.5l0,0.1c-1.2,5.1,0.9,10.3,0.9,10.4l0,0.2V205.9z M577,186.9c-0.1,0.5-0.7,4.9,0.9,11.4c1,3.9,1.8,5.5,2.3,6.2v-7
                c-0.3-0.7-2.1-5.6-0.9-10.7l-0.1-0.9l-1-0.4l-0.8,0.4L577,186.9z"
                    />
                  </g>
                </g>
                <g id="XMLID_3687_">
                  <g id="XMLID_3694_">
                    <path
                      id="XMLID_3696_"
                      style="fill: #ffffff"
                      d="M581.3,188.9c0,0-2.7-4.7-4.6-2.1c0,0-0.1,0-0.1,0c0.1-0.4,0.4-1.8,2.5-2.4
                c2.4-0.8,5.4,2.9,5.4,2.9L581.3,188.9z"
                    />
                  </g>
                  <g id="XMLID_3689_">
                    <path
                      id="XMLID_3690_"
                      d="M581.1,189.4l-0.2-0.3c-0.4-0.7-1.7-2.6-2.8-2.7c-0.4,0-0.7,0.2-1,0.6l-0.2,0.3l-0.6-0.2l0-0.4
                c0.1-0.5,0.5-2,2.7-2.8c2.7-0.9,5.7,2.9,5.9,3l0.3,0.4L581.1,189.4z M578,185.6c0,0,0.1,0,0.1,0c1.5,0.1,2.8,1.9,3.3,2.7
                l2.4-1.2c-1-1.1-3.1-2.9-4.6-2.4c-0.7,0.2-1.2,0.5-1.5,0.8C577.8,185.6,577.9,185.6,578,185.6z"
                    />
                  </g>
                </g>
                <g id="XMLID_3678_">
                  <g id="XMLID_3685_">
                    <path
                      id="XMLID_3686_"
                      style="fill: #ffffff"
                      d="M570.5,190c0,0-0.9,4.6,0.9,11.6c1.8,7,3.1,7,3.1,7v-7.9
                c0,0-2.1-5.3-0.9-10.6l-0.1-1.2l-1.4-0.6l-1.1,0.6L570.5,190z"
                    />
                  </g>
                  <g id="XMLID_3679_">
                    <path
                      id="XMLID_3680_"
                      d="M575,209.1l-0.4,0c-0.5,0-1.8-0.7-3.5-7.3c-1.8-7-1-11.6-0.9-11.8l0-0.1l0.5-1.2l1.4-0.8l1.9,0.8
                l0.1,1.5l0,0.1c-1.2,5.1,0.9,10.3,0.9,10.4l0,0.2V209.1z M570.9,190.1c-0.1,0.5-0.7,4.9,0.9,11.4c1,3.9,1.8,5.5,2.3,6.2v-7
                c-0.3-0.7-2.1-5.6-0.9-10.7l-0.1-0.9l-1-0.4l-0.8,0.4L570.9,190.1z"
                    />
                  </g>
                </g>
                <g id="XMLID_3669_">
                  <g id="XMLID_3675_">
                    <path
                      id="XMLID_3676_"
                      style="fill: #ffffff"
                      d="M575.2,192.1c0,0-2.7-4.7-4.6-2.1c0,0-0.1,0-0.1,0c0.1-0.4,0.4-1.8,2.5-2.4
                c2.4-0.8,5.4,2.9,5.4,2.9L575.2,192.1z"
                    />
                  </g>
                  <g id="XMLID_3670_">
                    <path
                      id="XMLID_3671_"
                      d="M575,192.6l-0.2-0.3c-0.4-0.7-1.7-2.6-2.8-2.7c-0.4,0-0.7,0.2-1,0.6l-0.2,0.3l-0.6-0.2l0-0.4
                c0.1-0.5,0.5-2,2.7-2.8c2.7-0.9,5.7,2.9,5.9,3l0.3,0.4L575,192.6z M571.9,188.8c0,0,0.1,0,0.1,0c1.5,0.1,2.8,1.9,3.3,2.7
                l2.4-1.2c-1-1.1-3.1-2.9-4.6-2.4c-0.7,0.2-1.2,0.5-1.5,0.8C571.7,188.8,571.8,188.8,571.9,188.8z"
                    />
                  </g>
                </g>
                <g id="XMLID_3534_">
                  <g id="XMLID_3582_">
                    <g id="XMLID_3621_">
                      <g id="XMLID_3628_">
                        <g id="XMLID_3659_">
                          <g id="XMLID_3666_">
                            <path
                              id="XMLID_3667_"
                              style="fill: #ffffff"
                              d="M572.5,211.7c0,0,3.4,2.3,3.6,2.4c0,0,0.1,0,0.1,0l4.8-14.8c0,0,0,0,0,0
                        l7.1,7.3c0,0,0,0,0-0.1l1.1-16.4c0,0,0,0,0-0.1l1.3-1.1c0,0,0-0.1,0-0.1c-0.3-0.2-3.5-2.4-3.6-2.4l0,0
                        c-0.2-0.1-0.5-0.2-0.8,0l-11.7,5.9c-0.7,0.4-1.2,1.1-1.3,1.9l-1.1,16.6C572.1,211.3,572.2,211.5,572.5,211.7L572.5,211.7
                        z"
                            />
                          </g>
                          <g id="XMLID_3660_">
                            <path
                              id="XMLID_3662_"
                              d="M576.2,214.6c-0.1,0-0.2,0-0.3-0.1c0,0-3.6-2.4-3.7-2.4l0,0c-0.3-0.2-0.5-0.7-0.5-1.1l1.1-16.6
                        c0.1-1,0.7-1.8,1.5-2.3l11.6-5.9c0.4-0.2,0.8-0.2,1.2,0c0,0,0.1,0,2.8,1.9l0.8,0.6c0.1,0.1,0.2,0.2,0.2,0.4
                        c0,0.2-0.1,0.3-0.2,0.4l-1.2,1l-1.1,16.3c0,0.2-0.1,0.3-0.3,0.4l-0.3,0.1l-6.8-7l-4.6,14.3l-0.1,0.1
                        C576.4,214.6,576.3,214.6,576.2,214.6z M572.7,211.4c0,0,2.3,1.6,3.3,2.2l4.7-14.4l0.2-0.1l0.3-0.1l6.7,6.9l1-15.7
                        l0.2-0.2l0.9-0.8l-0.5-0.3c-1-0.7-2.6-1.8-2.7-1.8c-0.1,0-0.2-0.1-0.4,0l-11.6,5.9c-0.6,0.3-1,0.9-1.1,1.6l-1.1,16.6
                        C572.5,211.2,572.6,211.3,572.7,211.4l-0.2,0.3L572.7,211.4z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_3649_">
                          <g id="XMLID_3656_">
                            <path
                              id="XMLID_3657_"
                              style="fill: #ffffff"
                              d="M576.2,214.2l4.8-14.8c0,0,0,0,0,0l-2-2.6l-5.4-3.6
                        c-0.2,0.3-0.4,0.8-0.4,1.2l-1.1,16.6c0,0.3,0.1,0.6,0.4,0.8l0,0C572.5,211.7,575.8,214,576.2,214.2
                        C576.1,214.2,576.2,214.2,576.2,214.2z"
                            />
                          </g>
                          <g id="XMLID_3651_">
                            <path
                              id="XMLID_3652_"
                              d="M576.2,214.6c-0.1,0-0.2,0-0.3-0.1c-3.6-2.4-3.7-2.4-3.7-2.5c-0.4-0.3-0.6-0.7-0.5-1.2
                        l1.1-16.6c0-0.5,0.2-1,0.5-1.4l0.2-0.3l5.9,4l2.3,3l-0.3,0.2l-4.8,14.7l-0.1,0.1C576.4,214.6,576.3,214.6,576.2,214.6z
                         M573.8,193.7c-0.1,0.2-0.1,0.4-0.2,0.6l-1.1,16.6c0,0.2,0.1,0.4,0.2,0.4c0.1,0,2.3,1.6,3.3,2.2l4.6-14.1l-1.8-2.4
                        L573.8,193.7z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_3640_">
                          <g id="XMLID_3647_">
                            <path
                              id="XMLID_3648_"
                              style="fill: #ffffff"
                              d="M587.5,190.1c0,0,1.7-0.4,2,0l1.2-1c0,0,0-0.1,0-0.1
                        c-0.3-0.2-3.5-2.4-3.6-2.4l0,0c-0.2-0.1-0.5-0.2-0.8,0l-2.4,1.1l1.9,1.3L587.5,190.1z"
                            />
                          </g>
                          <g id="XMLID_3641_">
                            <path
                              id="XMLID_3642_"
                              d="M589.4,190.7l-0.3-0.4c-0.1,0-0.9,0-1.5,0.2l-0.2,0l-4.4-2.9l3-1.4c0.4-0.2,0.8-0.2,1.2,0
                        c0,0,0.1,0,2.8,1.9l0.8,0.6c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4L589.4,190.7z M584.7,187.7l2.9,1.9
                        c0.6-0.1,1.4-0.2,1.8-0.1l0.6-0.5l-0.5-0.3c-1-0.7-2.6-1.8-2.7-1.8c-0.1,0-0.2-0.1-0.4,0L584.7,187.7z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_3630_">
                          <g id="XMLID_3637_">
                            <path
                              id="XMLID_3638_"
                              d="M578.1,194.9l11.7-5.9c0.6-0.3,1.3,0.2,1.3,0.9l-1.1,16.7c-0.1,0.8-0.5,1.5-1.3,1.9l-11.7,5.9
                        c-0.6,0.3-1.3-0.2-1.3-0.9l1.1-16.7C576.9,195.9,577.4,195.2,578.1,194.9z"
                            />
                          </g>
                          <g id="XMLID_3631_">
                            <path
                              id="XMLID_3633_"
                              d="M576.6,214.8c-0.3,0-0.5-0.1-0.7-0.2c-0.4-0.3-0.6-0.7-0.6-1.2l1.1-16.7
                        c0.1-0.9,0.6-1.8,1.5-2.2l11.7-5.9c0.4-0.2,0.9-0.2,1.3,0.1c0.4,0.3,0.6,0.7,0.6,1.2l-1.1,16.7c-0.1,0.9-0.6,1.8-1.5,2.2
                        l-11.7,5.9C577,214.7,576.8,214.8,576.6,214.8z M590.2,189.3c-0.1,0-0.1,0-0.2,0.1l-11.7,5.9c-0.6,0.3-1,0.9-1,1.5
                        l-1.1,16.7c0,0.3,0.1,0.4,0.2,0.4c0.1,0,0.3,0.1,0.5,0l11.7-5.9c0.6-0.3,1-0.9,1-1.5l1.1-16.7c0-0.3-0.1-0.4-0.2-0.4
                        C590.4,189.3,590.3,189.3,590.2,189.3z"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_3622_">
                        <g id="XMLID_3624_">
                          <g id="XMLID_3625_">
                            <rect
                              id="XMLID_3627_"
                              x="576.5"
                              y="196.6"
                              transform="matrix(0.8982 -0.4396 0.4396 0.8982 -27.1404 276.6816)"
                              style="fill: #ffffff"
                              width="14.7"
                              height="0.6"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_3584_">
                      <g id="XMLID_3591_">
                        <g id="XMLID_3611_">
                          <g id="XMLID_3618_">
                            <path
                              id="XMLID_3619_"
                              style="fill: #ffffff"
                              d="M578,213.6c0,0,2.5,1.7,2.7,1.8c0,0,0,0,0.1,0l3.2-3.9c0,0,0,0,0,0
                        l5.7-1.7c0,0,0,0,0,0l0.7-10c0,0,0,0,0,0l0.9-0.8c0,0,0-0.1,0-0.1c-0.3-0.2-2.6-1.8-2.7-1.8l0,0c-0.2-0.1-0.4-0.1-0.6,0
                        l-8.6,4.4c-0.6,0.3-0.9,0.8-1,1.4l-0.7,10.2C577.7,213.2,577.9,213.4,578,213.6L578,213.6z"
                            />
                          </g>
                          <g id="XMLID_3613_">
                            <path
                              id="XMLID_3614_"
                              d="M580.8,215.8c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-2.7-1.8-2.7-1.8l0,0c-0.3-0.2-0.4-0.5-0.4-0.9
                        l0.7-10.2c0-0.8,0.5-1.4,1.2-1.8l8.6-4.3c0.3-0.2,0.7-0.1,1,0c0,0,0,0,1.9,1.2l1,0.7l0,0.3c0,0.1-0.1,0.3-0.2,0.4
                        l-0.8,0.7l-0.7,10.1l-0.3,0.2l-5.6,1.7l-3.1,3.8C581,215.8,580.9,215.8,580.8,215.8z M578.3,213.2
                        c0.1,0.1,1.7,1.2,2.4,1.6l3-3.7l0.1,0l5.5-1.6l0.7-10l0.2-0.2l0.6-0.5l-0.4-0.3c-0.8-0.5-1.7-1.2-1.8-1.2
                        c0,0-0.1,0-0.2,0l-8.6,4.3c-0.4,0.2-0.7,0.6-0.7,1.1l-0.7,10.2C578.2,213.1,578.2,213.2,578.3,213.2L578.3,213.2z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_3602_">
                          <g id="XMLID_3609_">
                            <path
                              id="XMLID_3610_"
                              style="fill: #ffffff"
                              d="M580.8,215.4l3.2-3.9c0,0,0,0,0,0l-1.2-6.9l-4-2.7
                        c-0.2,0.3-0.3,0.6-0.3,0.9l-0.7,10.2c0,0.2,0.1,0.5,0.3,0.6l0,0C578,213.6,580.5,215.2,580.8,215.4
                        C580.8,215.4,580.8,215.4,580.8,215.4z"
                            />
                          </g>
                          <g id="XMLID_3603_">
                            <path
                              id="XMLID_3605_"
                              d="M580.8,215.8c-0.1,0-0.2,0-0.3-0.1c0,0-2.7-1.8-2.7-1.8c-0.3-0.2-0.5-0.6-0.5-1l0.7-10.2
                        c0-0.4,0.2-0.8,0.4-1.1l0.2-0.3l4.5,3l1.3,7.4l-0.2,0.1l-3.2,3.9C581,215.8,580.9,215.8,580.8,215.8z M578.9,202.5
                        c0,0.1-0.1,0.2-0.1,0.3l-0.7,10.2c0,0.1,0.1,0.2,0.1,0.2c0,0,1.7,1.2,2.4,1.6l2.8-3.5l-1.1-6.5L578.9,202.5z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_3592_">
                          <g id="XMLID_3599_">
                            <path
                              id="XMLID_3601_"
                              d="M582.1,203.2l8.7-4.4c0.5-0.2,1,0.1,1,0.6l-0.7,10.2c0,0.6-0.4,1.1-0.9,1.4l-8.7,4.4
                        c-0.5,0.2-1-0.1-1-0.6l0.7-10.2C581.2,204,581.5,203.4,582.1,203.2z"
                            />
                          </g>
                          <g id="XMLID_3594_">
                            <path
                              id="XMLID_3595_"
                              d="M581.1,215.9c-0.2,0-0.4-0.1-0.6-0.2c-0.3-0.2-0.5-0.6-0.5-1l0.7-10.2c0-0.7,0.5-1.4,1.2-1.7
                        l8.7-4.4c0.4-0.2,0.8-0.1,1.1,0.1c0.3,0.2,0.5,0.6,0.5,1l-0.7,10.2c0,0.7-0.5,1.4-1.2,1.7l-8.7,4.4
                        C581.5,215.9,581.3,215.9,581.1,215.9z M591,199.2c0,0-0.1,0-0.1,0l-8.7,4.4c-0.4,0.2-0.7,0.6-0.7,1l-0.7,10.2
                        c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0.1,0.3,0l8.7-4.4c0.4-0.2,0.7-0.6,0.7-1l0.7-10.2c0-0.1-0.1-0.2-0.1-0.2
                        C591.2,199.2,591.1,199.2,591,199.2z"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_3585_">
                        <g id="XMLID_3587_">
                          <g id="XMLID_3588_">
                            <rect
                              id="XMLID_3589_"
                              x="580.6"
                              y="209.4"
                              transform="matrix(0.8983 -0.4394 0.4394 0.8983 -32.5255 278.8601)"
                              style="fill: #ffffff"
                              width="10.9"
                              height="0.6"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_3573_">
                    <g id="XMLID_3580_">
                      <path
                        id="XMLID_3581_"
                        style="fill: #ffffff"
                        d="M585.1,206.9c-0.6,1.1-1.6,1.6-2.2,1.3s-0.6-1.5,0-2.6
                  c0.6-1.1,1.6-1.6,2.2-1.3C585.7,204.6,585.7,205.8,585.1,206.9z"
                      />
                    </g>
                    <g id="XMLID_3574_">
                      <path
                        id="XMLID_3576_"
                        d="M583.3,208.7c-0.2,0-0.4-0.1-0.6-0.2c-0.8-0.5-0.9-1.9-0.2-3.1c0.3-0.6,0.8-1.1,1.3-1.3
                  c0.6-0.3,1.1-0.4,1.5-0.1c0.4,0.2,0.7,0.7,0.7,1.4c0,0.6-0.2,1.2-0.5,1.8c-0.3,0.6-0.8,1.1-1.3,1.3
                  C583.9,208.6,583.6,208.7,583.3,208.7z M584.7,204.6c-0.1,0-0.3,0.1-0.5,0.2c-0.4,0.2-0.7,0.6-1,1c-0.5,0.9-0.5,1.8-0.2,2
                  c0.2,0.1,0.4,0.1,0.7-0.1c0.4-0.2,0.7-0.6,1-1l0,0c0.3-0.4,0.4-0.9,0.4-1.3c0-0.3-0.1-0.6-0.3-0.7
                  C584.8,204.6,584.8,204.6,584.7,204.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3564_">
                    <g id="XMLID_3571_">
                      <path
                        id="XMLID_3572_"
                        style="fill: #ffffff"
                        d="M586.9,205.5c-0.5,0.8-1.2,1.3-1.7,1c-0.5-0.3-0.5-1.2,0-2
                  c0.5-0.8,1.2-1.3,1.7-1C587.4,203.8,587.4,204.7,586.9,205.5z"
                      />
                    </g>
                    <g id="XMLID_3565_">
                      <path
                        id="XMLID_3567_"
                        d="M585.5,207c-0.2,0-0.4,0-0.5-0.1c-0.7-0.4-0.8-1.5-0.2-2.6c0.3-0.5,0.6-0.8,1-1.1
                  c0.5-0.3,0.9-0.3,1.3-0.1c0.7,0.4,0.8,1.5,0.2,2.6v0C586.8,206.5,586.1,207,585.5,207z M586.6,203.9c-0.1,0-0.2,0-0.3,0.1
                  c-0.3,0.1-0.5,0.4-0.7,0.8c-0.4,0.7-0.4,1.3-0.2,1.4c0.2,0.1,0.8-0.2,1.2-0.8c0.4-0.7,0.4-1.3,0.2-1.4
                  C586.7,203.9,586.6,203.9,586.6,203.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3554_">
                    <g id="XMLID_3561_">
                      <path
                        id="XMLID_3562_"
                        style="fill: #ffffff"
                        d="M588.2,209.7c-0.5,0.8-1.3,1.3-1.8,1c-0.5-0.3-0.5-1.2,0-2
                  c0.5-0.8,1.3-1.3,1.8-1C588.7,208,588.7,208.9,588.2,209.7z"
                      />
                    </g>
                    <g id="XMLID_3555_">
                      <path
                        id="XMLID_3557_"
                        d="M586.7,211.2c-0.2,0-0.4,0-0.5-0.1c-0.3-0.1-0.4-0.4-0.5-0.7c-0.1-0.5,0-1.2,0.4-1.9
                  c0.6-1,1.7-1.5,2.4-1.1c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.5,0,1.2-0.4,1.9l0,0C588.1,210.7,587.4,211.2,586.7,211.2z
                   M587.9,208.1c-0.3,0-0.8,0.3-1.1,0.9c-0.3,0.4-0.4,0.9-0.3,1.2c0,0.1,0.1,0.2,0.1,0.2c0.2,0.1,0.8-0.1,1.3-0.8v0
                  c0.3-0.4,0.4-0.9,0.3-1.2c0-0.1-0.1-0.2-0.1-0.2C588,208.1,588,208.1,587.9,208.1z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3544_">
                    <g id="XMLID_3551_">
                      <path
                        id="XMLID_3553_"
                        style="fill: #ffffff"
                        d="M587,194.2c-0.5,0.8-1.3,1.3-1.8,1c-0.5-0.3-0.5-1.2,0-2
                  c0.5-0.8,1.3-1.3,1.8-1S587.5,193.4,587,194.2z"
                      />
                    </g>
                    <g id="XMLID_3546_">
                      <path
                        id="XMLID_3547_"
                        d="M585.5,195.7c-0.2,0-0.4,0-0.5-0.1c-0.3-0.1-0.4-0.4-0.5-0.7c-0.1-0.5,0-1.2,0.4-1.9
                  c0.6-1,1.7-1.5,2.4-1.1c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.5,0,1.2-0.4,1.9l0,0C586.9,195.2,586.1,195.7,585.5,195.7z
                   M586.7,192.6c-0.3,0-0.8,0.3-1.1,0.9c-0.3,0.4-0.4,0.9-0.3,1.2c0,0.1,0.1,0.2,0.1,0.2c0.2,0.1,0.8-0.1,1.3-0.8v0
                  c0.3-0.4,0.4-0.9,0.3-1.2c0-0.1-0.1-0.2-0.1-0.2C586.8,192.6,586.7,192.6,586.7,192.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3535_">
                    <g id="XMLID_3542_">
                      <path
                        id="XMLID_3543_"
                        style="fill: #ffffff"
                        d="M585.3,196.2c-0.4,0.6-1,1-1.4,0.8c-0.4-0.2-0.4-0.9,0-1.6
                  c0.4-0.6,1-1,1.4-0.8C585.7,194.8,585.7,195.5,585.3,196.2z"
                      />
                    </g>
                    <g id="XMLID_3536_">
                      <path
                        id="XMLID_3538_"
                        d="M584.1,197.5c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.4,0-1,0.3-1.5
                  c0.5-0.9,1.4-1.3,2-0.9c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.4,0,1-0.3,1.5C585.2,197.1,584.6,197.5,584.1,197.5z M585,195
                  c-0.2,0-0.5,0.2-0.8,0.6c-0.2,0.4-0.3,0.7-0.2,0.9c0,0,0,0.1,0.1,0.1c0.1,0.1,0.6-0.1,0.9-0.6v0c0.2-0.4,0.3-0.7,0.2-0.9
                  C585.1,195.1,585.1,195,585,195C585,195,585,195,585,195z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_3472_">
                <g id="XMLID_3504_">
                  <g id="XMLID_3522_">
                    <g id="XMLID_3529_">
                      <path
                        id="XMLID_3531_"
                        d="M565.6,205.2c0.8,0.4,4.2,1.1,5.3,1.1c0.7,0,1.5,0,2.4-0.1c1.4-0.1,3.4-2.3-0.3-2.8l-1.8-0.2
                  l-0.1-0.2l1.2-0.8l0-0.3l-1.4-0.1c0,0-1.5-0.1-2.3,1.5c-0.9,1.7-1.3,2.1-1.3,2.1L565.6,205.2z"
                      />
                    </g>
                    <g id="XMLID_3524_">
                      <path
                        id="XMLID_3525_"
                        d="M570.8,206.7c-1.2,0-4.6-0.7-5.4-1.1l-2.3-1.2l4.1,0.7c0.2-0.2,0.5-0.8,1.1-1.9
                  c1-1.9,2.7-1.7,2.7-1.7l1.8,0.1l0,0.9l-0.7,0.4l1,0.1c2,0.3,2.5,1,2.5,1.6c0,1-1.2,1.9-2.2,2
                  C572.5,206.6,571.7,206.7,570.8,206.7C570.9,206.7,570.8,206.7,570.8,206.7z M567.9,205.4c1.1,0.2,2.4,0.5,3,0.4
                  c0.8,0,1.6-0.1,2.4-0.1c0.6-0.1,1.4-0.7,1.4-1.1c0-0.3-0.5-0.7-1.7-0.8l-1.9-0.2l-0.5-0.6l0.9-0.6l-0.5,0
                  c-0.1,0-1.2-0.1-1.9,1.3C568.5,204.5,568.1,205.1,567.9,205.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3513_">
                    <g id="XMLID_3520_">
                      <path
                        id="XMLID_3521_"
                        d="M568.9,203.2l-1.4-0.6c0,0-4.1-2.4-5.3-3.3c-1.2-0.9-3-2-4.7-2.2c0,0,5-7.1,5.4-10.1
                  c0.4-3-1.3-4.1-1.3-4.1s0,0,0,0c-1-0.6-2.5-0.6-3.1,0.4c-1.3,2.3-5.2,10-6.6,14c-0.1,0.4,0,0.9,0.4,1.1
                  c2.3,1.4,10,6.6,14.5,7l1.6,0.2C570.3,203.3,568.9,203,568.9,203.2z"
                      />
                    </g>
                    <g id="XMLID_3514_">
                      <path
                        id="XMLID_3515_"
                        d="M568.7,206l-1.8-0.2c-4.1-0.3-10.6-4.4-13.8-6.4c-0.4-0.2-0.7-0.4-0.9-0.6c-0.5-0.3-0.8-1-0.6-1.6
                  c1.3-4,5.3-11.7,6.6-14c0.3-0.5,0.7-0.8,1.3-1c0.8-0.2,1.7-0.1,2.4,0.4c0.1,0.1,1.9,1.3,1.5,4.5c-0.3,2.6-3.9,8-5.1,9.7
                  c1.8,0.4,3.4,1.5,4.2,2.2c1.1,0.9,5.2,3.3,5.2,3.3l1.2,0.5c0.2,0,0.5,0.1,0.7,0.3c0.3,0.4,0.4,1.2-0.8,2.8L568.7,206z
                   M560.2,182.9c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.6,0.2-0.8,0.6c-1.3,2.3-5.2,10-6.5,13.9c-0.1,0.2,0,0.5,0.2,0.6
                  c0.3,0.2,0.6,0.4,1,0.6c2.8,1.8,9.5,6,13.4,6.3l1.4,0.2c0.6-0.8,0.7-1.3,0.7-1.4l-1.6-0.8c-0.2-0.1-4.1-2.4-5.3-3.4
                  c-0.7-0.6-2.6-1.9-4.5-2.1l-0.7-0.1l0.4-0.6c0-0.1,4.9-7,5.3-9.9c0.3-2.7-1.1-3.6-1.1-3.7
                  C561,183.1,560.6,182.9,560.2,182.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3505_">
                    <g id="XMLID_3510_">
                      <path
                        id="XMLID_3511_"
                        d="M557.6,197.1c0,0-1.3,0.2-2.6,1.2L557.6,197.1z"
                      />
                    </g>
                    <g id="XMLID_3507_">
                      <path
                        id="XMLID_3508_"
                        style="fill: #ffffff"
                        d="M555.2,198.5l-0.4-0.5c1.3-1,2.7-1.2,2.7-1.2l0.1,0.6
                  C557.6,197.4,556.4,197.6,555.2,198.5z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_3495_">
                  <g id="XMLID_3501_">
                    <path
                      id="XMLID_3502_"
                      style="fill: #ffffff"
                      d="M555.4,187.7c-0.1,0.2,0,0.3,0.2,0.4c0.5,0.1,1.6,0.4,3.2,2.2
                c1.3,1.4,1.7,2.4,1.9,3c0.1,0.2,0.4,0.3,0.5,0.1c1.1-1.8,4-7.1,0.9-10.3C562.1,182.9,558.4,179.8,555.4,187.7z"
                    />
                  </g>
                  <g id="XMLID_3496_">
                    <path
                      id="XMLID_3497_"
                      d="M560.9,193.8C560.9,193.8,560.8,193.8,560.9,193.8c-0.4,0-0.6-0.2-0.6-0.5
                c-0.1-0.5-0.6-1.4-1.8-2.8c-1.5-1.7-2.5-2-2.9-2c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4-0.1-0.6l0,0
                c1.2-3.2,2.8-5.1,4.5-5.5c1.6-0.4,2.8,0.6,2.8,0.6c3.3,3.4,0.5,8.7-0.9,10.8C561.4,193.7,561.1,193.8,560.9,193.8z
                 M555.8,187.7c0.7,0.1,1.8,0.7,3.2,2.3c1.1,1.3,1.7,2.2,1.9,2.8c1.1-1.8,3.6-6.6,0.8-9.5c0,0-0.9-0.7-2.1-0.4
                C558.3,183.2,556.9,184.8,555.8,187.7z"
                    />
                  </g>
                </g>
                <g id="XMLID_3488_">
                  <g id="XMLID_3492_">
                    <path
                      id="XMLID_3493_"
                      d="M567.9,202.9c-1.1,1.4-0.4,2.4-0.4,2.4L567.9,202.9z"
                    />
                  </g>
                  <g id="XMLID_3489_">
                    <path
                      id="XMLID_3490_"
                      style="fill: #ffffff"
                      d="M567.3,205.5c0,0-0.8-1.2,0.4-2.8l0.5,0.4c-0.9,1.2-0.4,2-0.4,2L567.3,205.5
                z"
                    />
                  </g>
                </g>
                <g id="XMLID_3481_">
                  <g id="XMLID_3485_">
                    <path
                      id="XMLID_3486_"
                      d="M566.7,202.4c-1.1,1.4-0.7,2.6-0.7,2.6L566.7,202.4z"
                    />
                  </g>
                  <g id="XMLID_3482_">
                    <path
                      id="XMLID_3483_"
                      style="fill: #ffffff"
                      d="M565.6,205.1c0-0.1-0.4-1.3,0.8-2.9l0.5,0.4c-1,1.3-0.7,2.3-0.7,2.3
                L565.6,205.1z"
                    />
                  </g>
                </g>
                <g id="XMLID_3473_">
                  <g id="XMLID_3478_">
                    <path
                      id="XMLID_3479_"
                      d="M570.7,202.9c0,0,0.7-0.1,1.3-0.3L570.7,202.9z"
                    />
                  </g>
                  <g id="XMLID_3475_">
                    <path
                      id="XMLID_3476_"
                      style="fill: #ffffff"
                      d="M570.8,203.2l-0.1-0.6c0,0,0.7-0.1,1.3-0.3l0.2,0.6
                C571.5,203.1,570.8,203.2,570.8,203.2z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_3451_">
                <g id="XMLID_3462_">
                  <g id="XMLID_3469_">
                    <path
                      id="XMLID_3471_"
                      style="fill: #ffffff"
                      d="M567.4,190.4c-0.5,0.9-1.4,1.4-1.9,1.1c-0.5-0.3-0.5-1.3,0-2.2
                c0.5-0.9,1.4-1.4,1.9-1.1C568,188.5,568,189.4,567.4,190.4z"
                    />
                  </g>
                  <g id="XMLID_3463_">
                    <path
                      id="XMLID_3465_"
                      d="M565.9,192c-0.2,0-0.4,0-0.6-0.1c-0.4-0.2-0.6-0.7-0.6-1.2c0-0.5,0.2-1,0.5-1.5
                c0.7-1.1,1.7-1.7,2.5-1.3c0.7,0.4,0.8,1.6,0.2,2.8c-0.3,0.5-0.7,0.9-1.1,1.2C566.4,191.9,566.1,192,565.9,192z M567.1,188.5
                c-0.3,0-0.8,0.3-1.2,1c-0.2,0.4-0.3,0.8-0.3,1.1c0,0.3,0.1,0.5,0.2,0.5c0.1,0.1,0.3,0,0.5-0.1c0.3-0.2,0.6-0.5,0.8-0.9l0,0
                c0.5-0.8,0.4-1.5,0.2-1.6C567.2,188.5,567.2,188.5,567.1,188.5z"
                    />
                  </g>
                </g>
                <g id="XMLID_3452_">
                  <g id="XMLID_3459_">
                    <path
                      id="XMLID_3461_"
                      style="fill: #ffffff"
                      d="M567.9,190.6c-0.5,0.9-1.4,1.4-1.9,1.1c-0.5-0.3-0.5-1.3,0-2.2
                c0.5-0.9,1.4-1.4,1.9-1.1C568.4,188.7,568.4,189.7,567.9,190.6z"
                    />
                  </g>
                  <g id="XMLID_3454_">
                    <path
                      id="XMLID_3455_"
                      d="M566.3,192.2c-0.2,0-0.4,0-0.6-0.1c-0.7-0.4-0.8-1.6-0.2-2.8c0.3-0.5,0.7-0.9,1.1-1.2
                c0.5-0.3,1-0.3,1.4-0.1c0.4,0.2,0.6,0.7,0.6,1.2c0,0.5-0.2,1-0.5,1.5l0,0C567.7,191.7,567,192.2,566.3,192.2z M567.5,188.7
                c-0.1,0-0.3,0-0.4,0.1c-0.3,0.2-0.6,0.5-0.8,0.9c-0.5,0.8-0.4,1.5-0.2,1.6c0.2,0.1,0.9-0.2,1.4-1l0,0
                c0.2-0.4,0.3-0.8,0.3-1.1c0-0.3-0.1-0.5-0.2-0.5C567.6,188.7,567.6,188.7,567.5,188.7z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_2865_">
            <g id="XMLID_3328_">
              <g id="XMLID_3346_">
                <g id="XMLID_3365_">
                  <g id="XMLID_3428_">
                    <g id="XMLID_3438_">
                      <g id="XMLID_3445_">
                        <path
                          id="XMLID_3447_"
                          style="fill: #ffffff"
                          d="M595.9,332c0,0,0-2.7,0-3.6c0-0.2-0.1-0.3-0.3-0.4l-8.5-2l-43-24.8l-3.3-2
                    l-6.9,0.9c-0.2,0-0.3,0.2-0.3,0.4l0,3.5c-0.1,0.5,0.1,1.1,0.7,1.4l52.7,30.4c1.1,0.6,2.4,0.6,3.5,0l4.7-2.7
                    C595.7,332.9,595.9,332.4,595.9,332L595.9,332z"
                        />
                      </g>
                      <g id="XMLID_3440_">
                        <path
                          id="XMLID_3441_"
                          d="M588.8,336.8c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.7-0.4-1.1-1.2-0.9-2l0-3.4
                    c0-0.5,0.3-0.9,0.8-0.9l7.1-0.9l3.4,2.1l42.9,24.8l8.4,2c0.4,0.1,0.7,0.5,0.7,0.9c0,0.9,0,3.5,0,3.6l0,0.1
                    c0,0.6-0.4,1.2-1,1.5l-4.7,2.7C590.2,336.6,589.5,336.8,588.8,336.8z M534.2,300.6l0,3.4l0,0c0,0.3,0,0.7,0.4,0.9
                    l52.7,30.4c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.5,0.4-0.7l0-0.1c0-0.3,0-2.5,0-3.4l-8.5-2.1l-43-24.8l-3.1-1.9
                    L534.2,300.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_3429_">
                      <g id="XMLID_3436_">
                        <path
                          id="XMLID_3437_"
                          style="fill: #ffffff"
                          d="M587.1,332l-52.7-30.4c-0.9-0.5-0.9-1.9,0-2.4l4.7-2.7
                    c1.1-0.6,2.4-0.6,3.5,0l52.7,30.4c0.9,0.5,0.9,1.9,0,2.4l-4.7,2.7C589.5,332.6,588.2,332.6,587.1,332z"
                        />
                      </g>
                      <g id="XMLID_3430_">
                        <path
                          id="XMLID_3432_"
                          d="M588.8,333c-0.7,0-1.4-0.2-2-0.5L534.1,302c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l4.7-2.7
                    c1.2-0.7,2.8-0.7,4,0l52.7,30.4c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-4.7,2.7C590.2,332.8,589.5,333,588.8,333z
                     M587.4,331.5c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.4,0.4-0.7s-0.2-0.6-0.4-0.7l-52.7-30.4c-0.9-0.5-2-0.5-3,0
                    l-4.7,2.7c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7L587.4,331.5z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_3407_">
                    <g id="XMLID_3418_">
                      <g id="XMLID_3425_">
                        <path
                          id="XMLID_3426_"
                          style="fill: #ffffff"
                          d="M586.6,344.3c0,0,0-2.7,0-3.6c0-0.2-0.1-0.3-0.3-0.4l-8.5-2l-43-24.8
                    l-3.3-2l-6.9,0.9c-0.2,0-0.3,0.2-0.3,0.4l0,3.5c-0.1,0.5,0.1,1.1,0.7,1.4l52.7,30.4c1.1,0.6,2.4,0.6,3.5,0l4.7-2.7
                    C586.3,345.2,586.5,344.8,586.6,344.3L586.6,344.3z"
                        />
                      </g>
                      <g id="XMLID_3419_">
                        <path
                          id="XMLID_3421_"
                          d="M579.4,349.2c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.7-0.4-1.1-1.2-0.9-2l0-3.4
                    c0-0.5,0.3-0.9,0.8-0.9l7.1-0.9l3.4,2.1l42.9,24.7l8.4,2c0.4,0.1,0.7,0.5,0.7,0.9c0,0.9,0,3.5,0,3.6l0,0.1
                    c0,0.6-0.4,1.2-1,1.5l-4.7,2.7C580.8,349,580.1,349.2,579.4,349.2z M524.8,312.9l0,3.4l0,0c0,0.3,0,0.7,0.4,0.9l52.7,30.4
                    c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.5,0.4-0.7l0-0.1c0-0.3,0-2.5,0-3.4l-8.5-2.1l-43-24.8l-3.1-1.9L524.8,312.9z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_3409_">
                      <g id="XMLID_3415_">
                        <path
                          id="XMLID_3416_"
                          style="fill: #ffffff"
                          d="M577.7,344.3L525,313.9c-0.9-0.5-0.9-1.9,0-2.4l4.7-2.7
                    c1.1-0.6,2.4-0.6,3.5,0l52.7,30.4c0.9,0.5,0.9,1.9,0,2.4l-4.7,2.7C580.1,345,578.8,345,577.7,344.3z"
                        />
                      </g>
                      <g id="XMLID_3410_">
                        <path
                          id="XMLID_3411_"
                          d="M579.4,345.4c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.6-0.4-1-1-1-1.7s0.4-1.3,1-1.7l4.7-2.7
                    c1.2-0.7,2.8-0.7,4,0l52.7,30.4c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-4.7,2.7C580.8,345.2,580.1,345.4,579.4,345.4z
                     M578,343.9c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-52.7-30.4c-0.9-0.5-2-0.5-3,0
                    l-4.7,2.7c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7L578,343.9z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_3387_">
                    <g id="XMLID_3398_">
                      <g id="XMLID_3405_">
                        <path
                          id="XMLID_3406_"
                          style="fill: #ffffff"
                          d="M577.2,356.6c0,0,0-2.7,0-3.6c0-0.2-0.1-0.3-0.3-0.4l-8.5-2l-43-24.8
                    l-3.3-2l-6.9,0.9c-0.2,0-0.3,0.2-0.3,0.4l0,3.5c-0.1,0.5,0.1,1.1,0.7,1.5l52.7,30.4c1.1,0.6,2.4,0.6,3.5,0l4.7-2.7
                    C576.9,357.5,577.1,357.1,577.2,356.6L577.2,356.6z"
                        />
                      </g>
                      <g id="XMLID_3399_">
                        <path
                          id="XMLID_3400_"
                          d="M570,361.5c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.7-0.4-1.1-1.2-0.9-2l0-3.4c0-0.5,0.3-0.9,0.8-0.9
                    l7.1-0.9l3.4,2.1l42.9,24.8l8.4,2c0.4,0.1,0.7,0.5,0.7,0.9c0,3.5,0,3.6,0,3.6l0,0.1c0,0.6-0.4,1.2-1,1.5l-4.7,2.7
                    C571.4,361.3,570.7,361.5,570,361.5z M515.4,325.3l0,3.4l0,0c0,0.3,0,0.7,0.4,0.9l52.7,30.4c0.9,0.5,2,0.5,3,0l4.7-2.7
                    c0.3-0.2,0.4-0.5,0.4-0.7l0,0c0-0.3,0-2.5,0-3.4l-8.5-2.1l-43-24.8l-3.1-1.9L515.4,325.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_3388_">
                      <g id="XMLID_3395_">
                        <path
                          id="XMLID_3396_"
                          style="fill: #ffffff"
                          d="M568.3,356.7l-52.7-30.4c-0.9-0.5-0.9-1.9,0-2.4l4.7-2.7
                    c1.1-0.6,2.4-0.6,3.5,0l52.7,30.4c0.9,0.5,0.9,1.9,0,2.4l-4.7,2.7C570.7,357.3,569.4,357.3,568.3,356.7z"
                        />
                      </g>
                      <g id="XMLID_3389_">
                        <path
                          id="XMLID_3391_"
                          d="M570,357.7c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l4.7-2.7
                    c1.2-0.7,2.8-0.7,4,0l52.7,30.4c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-4.7,2.7C571.4,357.5,570.7,357.7,570,357.7z
                     M568.6,356.2c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.4,0.4-0.7s-0.2-0.6-0.4-0.7l-52.7-30.4c-0.9-0.5-2-0.5-3,0
                    l-4.7,2.7c-0.3,0.2-0.4,0.4-0.4,0.7s0.2,0.6,0.4,0.7L568.6,356.2z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_3367_">
                    <g id="XMLID_3377_">
                      <g id="XMLID_3384_">
                        <path
                          id="XMLID_3385_"
                          style="fill: #ffffff"
                          d="M567.7,368.9c0,0,0-2.7,0-3.6c0-0.2-0.1-0.3-0.3-0.4l-8.5-2l-43-24.8
                    l-3.3-2l-6.9,0.9c-0.2,0-0.3,0.2-0.3,0.4l0,3.5c-0.1,0.5,0.1,1.1,0.7,1.4l52.7,30.4c1.1,0.6,2.4,0.6,3.5,0l4.7-2.7
                    C567.5,369.9,567.7,369.4,567.7,368.9L567.7,368.9z"
                        />
                      </g>
                      <g id="XMLID_3379_">
                        <path
                          id="XMLID_3380_"
                          d="M560.6,373.8c-0.7,0-1.4-0.2-2-0.5l-52.7-30.4c-0.7-0.4-1.1-1.2-0.9-2l0-3.4
                    c0-0.5,0.3-0.9,0.8-0.9l7.1-0.9l3.4,2.1l42.9,24.7l8.4,2c0.4,0.1,0.7,0.5,0.7,0.9c0,3.5,0,3.6,0,3.6l0,0.1
                    c0,0.6-0.4,1.2-1,1.5l-4.7,2.7C562,373.6,561.3,373.8,560.6,373.8z M506,337.6l0,3.4l0,0c0,0.3,0,0.7,0.4,0.9l52.7,30.4
                    c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.5,0.4-0.7l0,0c0-0.3,0-2.5,0-3.4l-8.5-2.1l-43-24.8l-3.1-1.9L506,337.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_3368_">
                      <g id="XMLID_3375_">
                        <path
                          id="XMLID_3376_"
                          style="fill: #ffffff"
                          d="M558.9,369l-52.7-30.4c-0.9-0.5-0.9-1.9,0-2.4l4.7-2.7
                    c1.1-0.6,2.4-0.6,3.5,0l52.7,30.4c0.9,0.5,0.9,1.9,0,2.4l-4.7,2.7C561.3,369.6,559.9,369.6,558.9,369z"
                        />
                      </g>
                      <g id="XMLID_3369_">
                        <path
                          id="XMLID_3370_"
                          d="M560.6,370c-0.7,0-1.4-0.2-2-0.5L505.9,339c-0.6-0.4-1-1-1-1.7s0.4-1.3,1-1.7l4.7-2.7
                    c1.2-0.7,2.8-0.7,4,0l52.7,30.4c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7l-4.7,2.7C562,369.8,561.3,370,560.6,370z
                     M559.1,368.5c0.9,0.5,2,0.5,3,0l4.7-2.7c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7L514,333.9c-0.9-0.5-2-0.5-3,0
                    l-4.7,2.7c-0.3,0.2-0.4,0.4-0.4,0.7s0.2,0.6,0.4,0.7L559.1,368.5z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_3348_">
                  <g id="XMLID_3361_">
                    <g id="XMLID_3362_">
                      <rect
                        id="XMLID_3364_"
                        x="560.2"
                        y="369.4"
                        width="1.1"
                        height="3.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3357_">
                    <g id="XMLID_3358_">
                      <rect
                        id="XMLID_3360_"
                        x="569.5"
                        y="357.1"
                        width="1.1"
                        height="3.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3353_">
                    <g id="XMLID_3354_">
                      <rect
                        id="XMLID_3356_"
                        x="578.9"
                        y="344.8"
                        width="1.1"
                        height="3.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3349_">
                    <g id="XMLID_3350_">
                      <rect
                        id="XMLID_3351_"
                        x="588.5"
                        y="332.5"
                        width="1.1"
                        height="3.8"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_3342_">
                <g id="XMLID_3344_">
                  <polygon
                    id="XMLID_3345_"
                    points="562.4,369 562.4,372.8 567.7,369.5 567.6,366             "
                  />
                </g>
              </g>
              <g id="XMLID_3338_">
                <g id="XMLID_3339_">
                  <polygon
                    id="XMLID_3341_"
                    points="571.8,356.5 571.8,360.3 577.2,357 577,353.5             "
                  />
                </g>
              </g>
              <g id="XMLID_3334_">
                <g id="XMLID_3335_">
                  <polygon
                    id="XMLID_3337_"
                    points="581.2,344.4 581.2,348.2 586.6,344.9 586.4,341.4             "
                  />
                </g>
              </g>
              <g id="XMLID_3329_">
                <g id="XMLID_3331_">
                  <polygon
                    id="XMLID_3332_"
                    points="590.6,332.1 590.6,335.9 596,332.6 595.8,329.1             "
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_2866_">
              <g id="XMLID_2867_">
                <g id="XMLID_3135_">
                  <g id="XMLID_3318_">
                    <g id="XMLID_3325_">
                      <path
                        id="XMLID_3327_"
                        style="fill: #ffffff"
                        d="M540.8,290.2c0,0,0.7-1,1.8-2.9c0-0.1-1-7.2,0.2-12.4c0,0,0,0,0,0
                  c-0.3,0.5-3.7,7.3-4.8,11.8c-1.2,4.7-1.2,4.7-1.2,4.7s1.2,3,4.2,6.4l1.4,1.3c0.1,0.1,0.3,0.1,0.4,0l1.3-1.7
                  c0.1-0.1,0.1-0.2,0-0.3c-0.3-0.7-1.5-3.9-2.1-4.8C541.3,291.1,540.8,290.2,540.8,290.2z"
                      />
                    </g>
                    <g id="XMLID_3320_">
                      <path
                        id="XMLID_3321_"
                        d="M542.7,299.5c-0.2,0-0.3-0.1-0.5-0.2l-1.4-1.3c-3.1-3.4-4.3-6.4-4.4-6.6l-0.1-0.1l1.2-4.8
                  c1.1-4.5,4.5-11.2,4.9-11.9l0.2-0.3l0.7,0.2l-0.1,0.4c-1.2,5.1-0.2,12.2-0.2,12.3l0,0.1l-0.1,0.1c-0.8,1.4-1.4,2.4-1.7,2.8
                  c0.2,0.3,0.5,1,1.1,1.8c0.6,0.9,1.8,4,2.2,4.9c0.1,0.2,0,0.5-0.1,0.7l-1.3,1.7C543.1,299.4,543,299.5,542.7,299.5
                  C542.7,299.5,542.7,299.5,542.7,299.5z M537.3,291.4c0.3,0.6,1.5,3.2,4.1,6.2l1.3,1.1l1.1-1.6c-0.7-1.8-1.6-4-2-4.6
                  c-0.9-1.2-1.3-2.1-1.3-2.1l-0.1-0.2l0.1-0.2c0,0,0.7-1,1.7-2.8c-0.1-0.9-0.7-5.4-0.2-9.7c-1.2,2.5-2.9,6.4-3.6,9.3
                  L537.3,291.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3314_">
                    <g id="XMLID_3315_">
                      <path
                        id="XMLID_3317_"
                        d="M540.7,290.6c-0.5-0.1-1.1,0-1.1,0l-0.2-0.8c0,0,0.8-0.1,1.5,0L540.7,290.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3304_">
                    <g id="XMLID_3311_">
                      <path
                        id="XMLID_3313_"
                        style="fill: #ffffff"
                        d="M541.9,344c0,0-0.6,0.3-1.3,2.1c-0.6,1.4-2.6,4-3.4,4.6
                  c-0.8,0.7,0.4,3.4,3.1,2.1c1.2-0.5,2.9-1.8,3.7-2.2c1.2-0.6,2-1.6,2.2-2.9c0.1-0.9,0.2-1.9,0-2.5c-0.5-1.3-2.7-1.8-2.7-1.8
                  L541.9,344z"
                      />
                    </g>
                    <g id="XMLID_3306_">
                      <path
                        id="XMLID_3307_"
                        d="M538.9,353.5c-1,0-1.8-0.5-2.1-1.2c-0.5-0.8-0.4-1.6,0.1-2c0.8-0.7,2.8-3.2,3.3-4.5
                  c0.8-1.9,1.4-2.2,1.6-2.3l0,0l1.6-0.6l0.1,0c0.1,0,2.4,0.6,3,2.1c0.2,0.6,0.2,1.5,0.1,2.7c-0.2,1.4-1.1,2.6-2.4,3.2
                  c-0.3,0.2-0.9,0.5-1.4,0.9c-0.7,0.5-1.6,1-2.3,1.3C539.8,353.4,539.3,353.5,538.9,353.5z M542.1,344.3
                  c-0.1,0-0.6,0.4-1.1,1.8c-0.6,1.5-2.6,4.1-3.6,4.8c-0.2,0.1-0.2,0.6,0.1,1c0.4,0.6,1.3,1.1,2.6,0.5c0.6-0.3,1.5-0.8,2.2-1.3
                  c0.6-0.4,1.1-0.7,1.5-0.9c1-0.5,1.8-1.5,1.9-2.6c0.1-1,0.1-1.8,0-2.2c-0.3-0.8-1.6-1.4-2.3-1.5L542.1,344.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3295_">
                    <g id="XMLID_3301_">
                      <path
                        id="XMLID_3303_"
                        style="fill: #ffffff"
                        d="M541.9,344.1c0,0-0.5,2-1.1,3c-0.5,0.8,0.4,1.6,2.1,0.5c1.6-1,2-3.5,2-3.5
                  l-2.7-0.4L541.9,344.1z"
                      />
                    </g>
                    <g id="XMLID_3296_">
                      <path
                        id="XMLID_3297_"
                        d="M541.4,348.6c-0.3,0-0.6-0.1-0.8-0.3c-0.3-0.3-0.4-0.9-0.1-1.3c0.5-1,1.1-2.9,1.1-2.9l0-0.1
                  l0.5-0.6l3.3,0.5l-0.1,0.4c0,0.1-0.4,2.6-2.2,3.7C542.5,348.4,541.9,348.6,541.4,348.6z M542.3,344.3c-0.1,0.4-0.6,2-1.1,3
                  c-0.1,0.1-0.1,0.3,0,0.3c0.1,0.1,0.7,0.1,1.5-0.4c1.1-0.7,1.6-2.1,1.8-2.8L542.3,344.3L542.3,344.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3264_">
                    <g id="XMLID_3285_">
                      <g id="XMLID_3292_">
                        <path
                          id="XMLID_3293_"
                          style="fill: #ffffff"
                          d="M564.4,326.2c0,0,0.8,3-0.3,5.5c-1.1,2.4,1.5,4.1,3.6,1.5
                    c2.2-2.6,2.2-5.9,2.2-5.9l0,0c0.4-0.5,0.4-2.4-0.1-2.9c-0.7-0.6-1.8-1.2-3.3-0.8C563.8,324.3,564.4,326.2,564.4,326.2z"
                        />
                      </g>
                      <g id="XMLID_3286_">
                        <path
                          id="XMLID_3288_"
                          d="M565.4,334.9c-0.4,0-0.8-0.1-1.1-0.3c-0.8-0.5-1.3-1.6-0.6-3.1c1-2.2,0.2-5.1,0.2-5.2l0,0
                    c0,0-0.2-0.8,0.3-1.7c0.4-0.7,1.1-1.2,2.2-1.5c1.7-0.4,3,0.3,3.7,0.9c0.6,0.6,0.6,2.5,0.2,3.3c0,0.6-0.2,3.6-2.3,6
                    C567.2,334.5,566.2,334.9,565.4,334.9z M564.8,326.1c0,0.2,0.9,3.2-0.3,5.7c-0.5,1-0.2,1.7,0.3,2c0.6,0.4,1.6,0.2,2.6-1
                    c2-2.5,2.1-5.6,2.1-5.6l0-0.1l0.1-0.1c0.3-0.4,0.3-2.1,0-2.3c-0.6-0.5-1.5-1.1-2.9-0.7C564.5,324.6,564.7,326,564.8,326.1z
                    "
                        />
                      </g>
                    </g>
                    <g id="XMLID_3275_">
                      <g id="XMLID_3282_">
                        <path
                          id="XMLID_3284_"
                          style="fill: #ffffff"
                          d="M564.4,326.2l0.7,2.1c0.5,0.5,1.8-0.6,2.1-1.2c0.1-0.3,0.2-0.6,0.3-1
                    c0.3-1.5-0.9-2.6-0.9-2.6L564.4,326.2z"
                        />
                      </g>
                      <g id="XMLID_3277_">
                        <path
                          id="XMLID_3278_"
                          d="M565.4,328.9c-0.3,0-0.5-0.1-0.6-0.2l-0.1-0.1l-0.8-2.5l2.6-3.1l0.3,0.3c0.1,0,1.4,1.2,1.1,3
                    c-0.1,0.4-0.2,0.8-0.3,1.1c-0.3,0.6-1.2,1.3-2,1.5C565.5,328.9,565.4,328.9,565.4,328.9z M564.8,326.3l0.6,1.8
                    c0.3,0,1.2-0.6,1.4-1c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.8-0.2-1.5-0.5-1.9L564.8,326.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_3265_">
                      <g id="XMLID_3272_">
                        <path
                          id="XMLID_3274_"
                          d="M544.1,302.5c0,0-1.3,8.6,4.4,17l2.9,2.3l5.1,1.3l8,3c0.1,0,0.3,0,0.4,0l0.3-0.1
                    c0.7-0.3,1.2-1,1.3-1.7l0.1-0.5c0-0.2,0-0.4-0.2-0.5c-0.9-0.7-4-3-5.9-4.3c-2.9-2-4.6-3.1-6.6-3.5
                    c-2-0.4,0.8-13.5,0.8-13.5l-5.3-2.5L544.1,302.5z"
                        />
                      </g>
                      <g id="XMLID_3267_">
                        <path
                          id="XMLID_3268_"
                          d="M564.7,326.7c-0.1,0-0.2,0-0.3-0.1l-8-3l-5.1-1.3l-3.1-2.5c-5.8-8.4-4.5-17.2-4.5-17.3l0-0.2
                    l5.7-3.1l5.8,2.8l-0.1,0.3c-1.4,6.7-1.9,12.8-1.1,13c2.1,0.5,4,1.7,6.8,3.6c1.9,1.3,5,3.6,5.9,4.3c0.3,0.2,0.4,0.6,0.4,0.9
                    l-0.1,0.5c-0.2,0.9-0.7,1.6-1.5,2l-0.3,0.1C565,326.6,564.8,326.7,564.7,326.7z M551.6,321.5l5,1.3l8,3c0,0,0.1,0,0.1,0
                    l0.3-0.1c0.6-0.3,1-0.8,1.1-1.4l0.1-0.5c0,0,0-0.1,0-0.1c-0.9-0.7-3.9-3-5.8-4.3c-2.6-1.8-4.5-3-6.5-3.5
                    c-2.1-0.4-0.5-9.4,0.4-13.6l-4.8-2.3l-5,2.7c-0.1,1.3-0.7,9,4.4,16.5L551.6,321.5z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_3254_">
                    <g id="XMLID_3261_">
                      <path
                        id="XMLID_3263_"
                        d="M548.3,305.2c0,0-5.6,15.9-5.7,19.2c-0.1,3-0.7,16.9-0.8,19.5c0,0.3,0.1,0.5,0.4,0.6l0,0
                  c0.8,0.3,1.6,0.3,2.4,0l0.1-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0.2-1.1,0.9-4.8,2.1-7.8c1.4-3.6,0.5-9.4,0.5-9.4s2-5.6,4.7-8.2
                  c2.8-2.5,6.8-9.2,6.8-13.5C559.1,300.8,548.3,305.2,548.3,305.2z"
                      />
                    </g>
                    <g id="XMLID_3256_">
                      <path
                        id="XMLID_3257_"
                        d="M543.4,345.2c-0.5,0-0.9-0.1-1.4-0.3c-0.4-0.2-0.7-0.6-0.6-1c0.1-2.7,0.8-16.5,0.8-19.5
                  c0.1-3.3,5.5-18.7,5.7-19.3l0.1-0.2l0.2-0.1c0.8-0.3,8-3.2,10.5-1.5c0.6,0.4,0.9,1,0.9,1.7c0,4.5-4.1,11.3-6.9,13.8
                  c-2.5,2.3-4.3,7.2-4.6,7.9c0.1,0.8,0.8,6.1-0.5,9.5c-1.2,3-1.9,6.7-2,7.8c-0.1,0.4-0.3,0.7-0.6,0.8l-0.1,0.1
                  C544.3,345.1,543.8,345.2,543.4,345.2z M548.6,305.6c-0.6,1.7-5.5,15.9-5.6,18.9c-0.1,3-0.7,16.8-0.8,19.5
                  c0,0.1,0,0.2,0.1,0.2c0.7,0.3,1.4,0.3,2.1,0l0.1-0.1c0.1,0,0.1-0.1,0.1-0.2c0.2-1.1,0.9-4.8,2.1-7.9
                  c1.4-3.5,0.5-9.2,0.4-9.2l0-0.1l0-0.1c0.1-0.2,2-5.8,4.9-8.4c2.7-2.5,6.7-9.1,6.6-13.2c0-0.5-0.2-0.8-0.5-1.1
                  C556.4,302.8,550.9,304.7,548.6,305.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3244_">
                    <g id="XMLID_3251_">
                      <path
                        id="XMLID_3253_"
                        style="fill: #ffffff"
                        d="M562.9,279.1c-1.6-3-7.7-5.2-7.7-5.2l-2.5,0.4l-3.8-1.8
                  c0,0-5.4,0.8-6.2,1.9c-0.8,1.1-1.3,8.8-0.6,11.7c0.7,2.9,0.2,16.4,0.2,16.4s2.3,1.4,3.1,1.9c0.8,0.5,1.7-1.9,1.7-1.9l0.2,0
                  c0.4,1.7,0.7,2.8,0.7,2.8s13.8,2.8,12.7-3.7c-1.1-6.4,0.3-16.4,0.3-16.4S564.4,282,562.9,279.1z"
                      />
                    </g>
                    <g id="XMLID_3245_">
                      <path
                        id="XMLID_3247_"
                        d="M553.9,306.3c-3,0-5.9-0.6-6.1-0.6l-0.2,0l-0.1-0.2c0,0-0.2-0.7-0.5-1.9c-0.3,0.5-0.7,1.2-1.2,1.3
                  c-0.2,0.1-0.5,0.1-0.7-0.1c-0.8-0.5-3-1.9-3.1-1.9l-0.2-0.1l0-0.2c0-0.1,0.6-13.5-0.1-16.3c-0.7-2.9-0.3-10.8,0.6-12
                  c0.9-1.2,5.6-1.9,6.5-2.1l0.1,0l3.8,1.8l2.5-0.4l0.1,0c0.3,0.1,6.3,2.4,7.9,5.4l0,0c1.6,2.9-1.3,5.9-1.9,6.5
                  c-0.2,1.2-1.2,10.2-0.2,16.1c0.2,1.3-0.1,2.3-0.9,3.1C558.8,305.9,556.3,306.3,553.9,306.3z M548.2,304.9
                  c1.4,0.3,8.9,1.5,11.4-0.9c0.6-0.6,0.8-1.4,0.7-2.4c-1.1-6.4,0.2-16.1,0.3-16.5l0-0.2l0.1-0.1c0,0,3.3-3,1.9-5.6l0,0
                  c-1.4-2.6-6.6-4.7-7.3-5l-2.5,0.4l-3.8-1.8c-2.2,0.3-5.4,1.1-5.9,1.8c-0.7,0.9-1.2,8.3-0.5,11.3c0.7,2.8,0.3,14.4,0.2,16.3
                  c0.6,0.4,2.2,1.4,2.9,1.8c0.2,0,0.7-0.8,1.1-1.7l0.1-0.2l0.8-0.1l0.1,0.4C547.9,303.6,548.1,304.5,548.2,304.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3233_">
                    <g id="XMLID_3241_">
                      <path
                        id="XMLID_3242_"
                        style="fill: #ffffff"
                        d="M552.8,271.3c0,0,0,1,0.6,2.5c0,0-0.7,1.1-1.8,2c-1.3,1-3.1,1.2-4.6,0.5
                  l0,0l0,0c0,0,0.8-2.6,0.7-4.2C547.5,270.5,552.8,271.3,552.8,271.3z"
                      />
                    </g>
                    <g id="XMLID_3235_">
                      <path
                        id="XMLID_3236_"
                        d="M548.9,277c-0.7,0-1.4-0.1-2-0.4l-0.4-0.1l0.1-0.4c0,0,0.8-2.5,0.7-4c0-0.3,0.1-0.6,0.3-0.9
                  c1-1,4.6-0.5,5.3-0.4l0.4,0.1l0,0.4c0,0,0,1,0.6,2.3l0.1,0.2l-0.1,0.2c0,0-0.7,1.1-1.9,2.1C551,276.7,550,277,548.9,277z
                   M547.5,276c1.3,0.4,2.8,0.2,3.8-0.6c0.8-0.6,1.4-1.4,1.6-1.7c-0.4-0.9-0.5-1.7-0.5-2.1c-1.6-0.2-3.8-0.3-4.3,0.2
                  c-0.1,0.1-0.1,0.1-0.1,0.2C548.2,273.3,547.7,275.2,547.5,276z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3223_">
                    <g id="XMLID_3230_">
                      <path
                        id="XMLID_3232_"
                        style="fill: #ffffff"
                        d="M553,272.5c0,0,2.7,0.5,3.1,2.4c0,0-1.8,3.7-6.8,4.3l-0.4-2.5
                  c0,0,1.4,0,3-1.1C552.9,274.8,553.4,273.6,553,272.5L553,272.5z"
                      />
                    </g>
                    <g id="XMLID_3224_">
                      <path
                        id="XMLID_3226_"
                        d="M548.9,279.5l-0.5-3.3l0.5,0c0,0,1.3,0,2.8-1c0.8-0.6,1.2-1.6,0.9-2.5l-0.2-0.7l0.7,0.1
                  c0.1,0,3,0.5,3.4,2.7l0,0.1l-0.1,0.1c-0.1,0.2-2,4-7.1,4.5L548.9,279.5z M549.4,277l0.2,1.6c3.9-0.6,5.6-3.2,6-3.9
                  c-0.3-1-1.4-1.5-2.1-1.7c0.1,1.1-0.4,2.2-1.4,2.8C551.1,276.7,550,276.9,549.4,277z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3212_">
                    <g id="XMLID_3220_">
                      <path
                        id="XMLID_3221_"
                        style="fill: #ffffff"
                        d="M548,276.6l-1.4,2.1c0,0-1.9-1.5-1-4.2l2,0.3
                  C547.7,274.7,547.2,275.7,548,276.6z"
                      />
                    </g>
                    <g id="XMLID_3214_">
                      <path
                        id="XMLID_3215_"
                        d="M546.7,279.2l-0.4-0.3c0,0-2.1-1.7-1.1-4.7l0.1-0.3l2.9,0.4l-0.3,0.5c0,0-0.4,0.8,0.3,1.4l0.2,0.2
                  L546.7,279.2z M546,274.9c-0.4,1.6,0.2,2.6,0.6,3.1l0.9-1.4c-0.4-0.6-0.4-1.1-0.4-1.6L546,274.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3202_">
                    <g id="XMLID_3210_">
                      <path
                        id="XMLID_3211_"
                        style="fill: #ffffff"
                        d="M544.9,265c0,0-0.9,2.1-0.6,5.2c0.1,1.3,0.5,2.5,1,3.6l0.3,0.7
                  c0.1,0.3,0.4,0.4,0.7,0.4c1,0,3.4-0.3,5.4-2.1l0.7-0.9c0.1-0.1,0.2-0.3,0.3-0.5c0.2-0.5,0.5-0.9,0.9-1.2
                  c0.2-0.2,0.5-0.5,0.6-0.9c0.1-0.4,0-0.9-0.2-1.3l0,0c-0.2-0.3-0.5-0.4-0.8-0.3l-0.7-0.9l-2.4-3.1L544.9,265z"
                      />
                    </g>
                    <g id="XMLID_3204_">
                      <path
                        id="XMLID_3205_"
                        d="M546.3,275.2c-0.4,0-0.8-0.3-1-0.7l-0.3-0.7c-0.6-1.3-0.9-2.5-1.1-3.8c-0.4-3.2,0.6-5.3,0.6-5.4
                  l0.1-0.2l5.7-1.5l3.1,4c0.4,0,0.8,0.2,1,0.6c0.3,0.5,0.3,1.1,0.2,1.6c-0.1,0.5-0.4,0.8-0.8,1.1c-0.3,0.2-0.6,0.6-0.7,1
                  c-0.1,0.3-0.2,0.4-0.3,0.6l-0.7,0.9C549.9,275,547.4,275.2,546.3,275.2C546.3,275.2,546.3,275.2,546.3,275.2z M545.3,265.3
                  c-0.2,0.5-0.8,2.3-0.5,4.8c0.1,1.1,0.5,2.3,1,3.5l0.3,0.7c0,0.1,0.2,0.2,0.3,0.2c0.9,0,3.2-0.2,5.1-2l0.7-0.8
                  c0,0,0.1-0.1,0.2-0.3c0.2-0.6,0.6-1.1,1-1.4c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.3,0-0.7-0.1-1c-0.1-0.1-0.2-0.2-0.3-0.1
                  l-0.3,0.1L550,264L545.3,265.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3192_">
                    <g id="XMLID_3199_">
                      <path
                        id="XMLID_3201_"
                        d="M545,262.5c-0.1,0.1-0.2,0.2-0.3,0.2c-1.5,1-1.1,3.3,0.6,3.7c0.3,0.1,0.7,0.1,1.1,0.1
                  c3.8-0.1,5.8-0.2,5.9,1.4c0,0,0,0,0,0l0.7-0.3c0.6-0.2,1.2,0.2,1.3,0.8l0,0c0,0,1.1-0.8,1.1-2.5c0-1.7-1.3-5.7-5.1-5.5
                  c-0.3,0-0.5,0-0.7,0C547.8,260.7,546.3,261.4,545,262.5z"
                      />
                    </g>
                    <g id="XMLID_3193_">
                      <path
                        id="XMLID_3195_"
                        d="M553.9,269.3l-0.1-0.7c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5,0l-0.9,0.3l-0.4-0.2l0-0.2
                  c-0.1-1-1.6-1-5-1l-0.5,0c-0.4,0-0.8,0-1.2-0.1c-1-0.2-1.7-1-1.8-2c-0.2-1,0.3-2,1.1-2.5c0.1-0.1,0.2-0.1,0.3-0.2l0,0
                  c1.4-1.2,3.1-1.9,4.7-2c0.2,0,0.5,0,0.7,0c1.4-0.1,2.6,0.4,3.6,1.3c1.3,1.3,2,3.3,2,4.6c0,1.9-1.2,2.8-1.3,2.8L553.9,269.3z
                   M553.3,267.2c0.3,0,0.5,0.1,0.7,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.2-0.3,0.5-0.9,0.5-1.7c0-1-0.5-2.8-1.7-4
                  c-0.8-0.8-1.8-1.1-3-1.1c-0.3,0-0.5,0-0.7,0c-1.5,0.1-3,0.7-4.3,1.8c-0.1,0.1-0.2,0.2-0.4,0.3c-0.7,0.4-0.8,1.1-0.7,1.7
                  c0.1,0.5,0.5,1.1,1.2,1.3c0.3,0.1,0.7,0.1,1,0.1l0.5,0c3.3,0,5.3-0.1,5.7,1.3l0.2-0.1C553,267.2,553.2,267.2,553.3,267.2z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3187_">
                    <g id="XMLID_3189_">
                      <path
                        id="XMLID_3190_"
                        d="M547.2,302.5c-0.6-9.6,0-21.9,0-22l0.8,0c0,0.1-0.6,12.3,0,21.9L547.2,302.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3183_">
                    <g id="XMLID_3184_">
                      <path
                        id="XMLID_3186_"
                        style="fill: #ffffff"
                        d="M551,320.7l-0.5-0.3c0,0,0.5-0.7,1-1.2l0.2-0.2c0.7-0.7,2.2-2.4,2.8-3.2
                  l0.5,0.4c-0.6,0.9-2.2,2.6-2.9,3.3l-0.2,0.2C551.4,320,551,320.7,551,320.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3178_">
                    <g id="XMLID_3180_">
                      <rect
                        id="XMLID_3181_"
                        x="543.2"
                        y="309.1"
                        transform="matrix(0.3195 -0.9476 0.9476 0.3195 79.0082 728.7725)"
                        style="fill: #ffffff"
                        width="7.5"
                        height="0.6"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3174_">
                    <g id="XMLID_3176_">
                      <path
                        id="XMLID_3177_"
                        style="fill: #ffffff"
                        d="M557.6,311c-1.2-0.2-2.1-0.8-2.6-1.6c-0.8-1.4-0.1-3.1-0.1-3.2l0.6,0.2
                  c0,0-0.6,1.5,0.1,2.7c0.4,0.7,1.1,1.1,2.1,1.3L557.6,311z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3170_">
                    <g id="XMLID_3171_">
                      <path
                        id="XMLID_3173_"
                        style="fill: #ffffff"
                        d="M547.4,327c-0.6-0.7-1-0.9-1-0.9l0.3-0.6c0,0,0.6,0.3,1.2,1.1L547.4,327z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3166_">
                    <g id="XMLID_3167_">
                      <path
                        id="XMLID_3169_"
                        style="fill: #ffffff"
                        d="M543.8,343.5c-1.3,0-2.1-0.7-2.1-0.8l0.5-0.4l-0.2,0.2l0.2-0.2
                  c0,0,1.2,1.1,3.1,0.3l0.3,0.6C544.8,343.4,544.2,343.5,543.8,343.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3162_">
                    <g id="XMLID_3163_">
                      <path
                        id="XMLID_3164_"
                        style="fill: #ffffff"
                        d="M563.7,325.8l-0.3-0.6c0.1,0,1.4-0.8,1.7-2.6l0.6,0.1
                  C565.5,324.9,563.8,325.8,563.7,325.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3157_">
                    <g id="XMLID_3159_">
                      <path
                        id="XMLID_3160_"
                        style="fill: #ffffff"
                        d="M552.9,266.8l-0.6-0.2c0.3-0.9,0.2-1.6-0.1-2.1c-0.5-0.7-1.5-0.9-2.2-1
                  c-2.1-0.2-2.2-1.5-2.2-1.5l0.6,0c0,0,0.1,0.8,1.6,0.9c0.9,0.1,2.1,0.3,2.7,1.2C553.1,264.8,553.2,265.7,552.9,266.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3153_">
                    <g id="XMLID_3155_">
                      <path
                        id="XMLID_3156_"
                        d="M540.9,350.3c-1.5,0-2.4-1.2-2.4-1.3l0.7-0.5c0,0,0.7,1,1.8,1V350.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3149_">
                    <g id="XMLID_3150_">
                      <path
                        id="XMLID_3152_"
                        d="M565.6,330.3c-0.7,0-1.2-0.2-1.2-0.2l0.3-0.8l-0.2,0.4l0.2-0.4c0,0,1.2,0.5,2.1-0.2l0.5,0.7
                  C566.7,330.2,566.1,330.3,565.6,330.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3145_">
                    <g id="XMLID_3146_">
                      <path
                        id="XMLID_3148_"
                        d="M565.7,331.9c-1.1,0-1.6-0.6-1.6-0.7l0.6-0.5c0,0,0.6,0.7,2.2,0.1l0.3,0.8
                  C566.6,331.8,566.1,331.9,565.7,331.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3141_">
                    <g id="XMLID_3142_">
                      <path
                        id="XMLID_3143_"
                        d="M539.6,351.2c-1.2-0.3-2-1.3-2-1.4l0.7-0.5c0,0,0.7,0.8,1.6,1.1L539.6,351.2z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3136_">
                    <g id="XMLID_3138_">
                      <path
                        id="XMLID_3139_"
                        d="M554.6,276.5c-0.6-1.1-1.9-1.7-1.9-1.7l0.3-0.8c0.1,0,1.6,0.7,2.3,2.1L554.6,276.5z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_3039_">
                  <g id="XMLID_3125_">
                    <g id="XMLID_3132_">
                      <polygon
                        id="XMLID_3134_"
                        style="fill: #ffffff"
                        points="539.9,306.8 550.2,300.8 537.8,293.6 527.5,299.6                 "
                      />
                    </g>
                    <g id="XMLID_3127_">
                      <path
                        id="XMLID_3128_"
                        d="M539.9,307.2l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,307.2z M528.3,299.6l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,299.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3116_">
                    <g id="XMLID_3123_">
                      <polygon
                        id="XMLID_3124_"
                        style="fill: #ffffff"
                        points="539.9,304.7 550.2,298.7 537.8,291.6 527.5,297.5                 "
                      />
                    </g>
                    <g id="XMLID_3118_">
                      <path
                        id="XMLID_3119_"
                        d="M539.9,305.2l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,305.2z M528.3,297.5l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,297.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3107_">
                    <g id="XMLID_3114_">
                      <polygon
                        id="XMLID_3115_"
                        style="fill: #ffffff"
                        points="539.9,302.6 550.2,296.7 537.8,289.5 527.5,295.5                 "
                      />
                    </g>
                    <g id="XMLID_3108_">
                      <path
                        id="XMLID_3109_"
                        d="M539.9,303.1l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,303.1z M528.3,295.5l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,295.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3097_">
                    <g id="XMLID_3104_">
                      <polygon
                        id="XMLID_3105_"
                        style="fill: #ffffff"
                        points="539.9,300.6 550.2,294.6 537.8,287.5 527.5,293.4                 "
                      />
                    </g>
                    <g id="XMLID_3098_">
                      <path
                        id="XMLID_3100_"
                        d="M539.9,301.1l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,301.1z M528.3,293.4l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,293.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3088_">
                    <g id="XMLID_3094_">
                      <polygon
                        id="XMLID_3096_"
                        style="fill: #ffffff"
                        points="539.9,298.5 550.2,292.6 537.8,285.4 527.5,291.4                 "
                      />
                    </g>
                    <g id="XMLID_3090_">
                      <path
                        id="XMLID_3091_"
                        d="M539.9,299l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,299z M528.3,291.4l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,291.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3079_">
                    <g id="XMLID_3086_">
                      <polygon
                        id="XMLID_3087_"
                        style="fill: #ffffff"
                        points="539.9,298.5 550.2,292.6 537.8,285.4 527.5,291.4                 "
                      />
                    </g>
                    <g id="XMLID_3080_">
                      <path
                        id="XMLID_3081_"
                        d="M539.9,299l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,299z M528.3,291.4l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,291.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3069_">
                    <g id="XMLID_3076_">
                      <polygon
                        id="XMLID_3077_"
                        style="fill: #ffffff"
                        points="539.9,296.5 550.2,290.5 537.8,283.4 527.5,289.3                 "
                      />
                    </g>
                    <g id="XMLID_3070_">
                      <path
                        id="XMLID_3072_"
                        d="M539.9,296.9l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,296.9z M528.3,289.3l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,289.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3059_">
                    <g id="XMLID_3066_">
                      <polygon
                        id="XMLID_3067_"
                        style="fill: #ffffff"
                        points="539.9,294.4 550.2,288.4 537.8,281.3 527.5,287.3                 "
                      />
                    </g>
                    <g id="XMLID_3060_">
                      <path
                        id="XMLID_3062_"
                        d="M539.9,294.9l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,294.9z M528.3,287.3l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,287.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3050_">
                    <g id="XMLID_3056_">
                      <polygon
                        id="XMLID_3058_"
                        style="fill: #ffffff"
                        points="539.9,292.3 550.2,286.4 537.8,279.2 527.5,285.2                 "
                      />
                    </g>
                    <g id="XMLID_3051_">
                      <path
                        id="XMLID_3053_"
                        d="M539.9,292.8l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,292.8z M528.3,285.2l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,285.2z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3040_">
                    <g id="XMLID_3047_">
                      <polygon
                        id="XMLID_3049_"
                        style="fill: #ffffff"
                        points="539.9,290.3 550.2,284.3 537.8,277.2 527.5,283.1                 "
                      />
                    </g>
                    <g id="XMLID_3042_">
                      <path
                        id="XMLID_3043_"
                        d="M539.9,290.8l-13.2-7.6l11.2-6.4l13.2,7.6L539.9,290.8z M528.3,283.1l11.6,6.7l9.5-5.5l-11.6-6.7
                  L528.3,283.1z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_3001_">
                  <g id="XMLID_3029_">
                    <g id="XMLID_3035_">
                      <path
                        id="XMLID_3037_"
                        style="fill: #ffffff"
                        d="M553.9,301.2c-0.9,0.6-2,0.9-3.1,0.9c-0.7,0-1.5,0-2.4-0.1
                  c-1.4-0.1-3.8-2.4-0.2-2.9l1.8-0.2l0.1-0.2l-0.7-0.7l0-0.3l0.9-0.2c0,0,1.5-0.1,2.3,1.5S553.9,301.2,553.9,301.2
                  L553.9,301.2z"
                      />
                    </g>
                    <g id="XMLID_3030_">
                      <path
                        id="XMLID_3031_"
                        d="M550.9,302.5C550.8,302.5,550.8,302.5,550.9,302.5c-0.9,0-1.8-0.1-2.5-0.1c-1-0.1-2.4-1.1-2.5-2.2
                  c0-0.6,0.4-1.3,2.3-1.6l1.2-0.1l-0.3-0.3l0-0.8l1.2-0.3c0.1,0,1.8-0.2,2.8,1.7c0.8,1.6,1.2,2,1.2,2l0.3,0.4l-0.4,0.3
                  C553.2,302.2,552,302.5,550.9,302.5z M550,298l0.7,0.6l-0.5,0.6l-1.9,0.2c-1,0.1-1.6,0.4-1.6,0.7c0,0.5,1,1.3,1.7,1.3
                  c0.7,0.1,1.5,0.1,2.4,0.1c0.9,0,1.7-0.2,2.5-0.6c-0.2-0.3-0.6-0.9-1.1-1.8c-0.7-1.4-1.9-1.3-1.9-1.3L550,298z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3017_">
                    <g id="XMLID_3026_">
                      <path
                        id="XMLID_3027_"
                        style="fill: #ffffff"
                        d="M553.7,298.3l-1.4,0.6c0,0-0.3,1.5,0.7,2.7l1.3-0.5l1.6-2.2L553.7,298.3z"
                      />
                    </g>
                    <g id="XMLID_3019_">
                      <path
                        id="XMLID_3021_"
                        d="M552.8,302.1l-0.2-0.2c-1.1-1.4-0.8-3-0.8-3.1l0-0.2l1.7-0.8l3,0.9l-2,2.8L552.8,302.1z
                   M552.7,299.2c0,0.4,0,1.2,0.5,1.9l0.9-0.4l1.2-1.6l-1.5-0.4L552.7,299.2z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3007_">
                    <g id="XMLID_3014_">
                      <path
                        id="XMLID_3016_"
                        style="fill: #ffffff"
                        d="M559.6,278.6C559.6,278.6,559.6,278.6,559.6,278.6c1-0.6,2.5-0.6,3.1,0.4
                  c1.3,2.3,5.5,10.5,6.9,14.4c0.1,0.4,0,0.9-0.4,1.1c-2.3,1.4-10.3,6.2-14.8,6.5c0,0-0.6-0.4-0.6-2.8c0,0,4.1-2.4,5.3-3.3
                  c1.2-0.9,3-2,4.7-2.2c0,0-5-7.1-5.4-10.1C557.9,279.7,559.6,278.6,559.6,278.6z"
                      />
                    </g>
                    <g id="XMLID_3008_">
                      <path
                        id="XMLID_3010_"
                        d="M554.2,301.5l-0.1-0.1c-0.2-0.1-0.7-0.7-0.8-3.2l0-0.2l0.2-0.1c0,0,4.1-2.4,5.2-3.3
                  c0.8-0.7,2.5-1.8,4.2-2.2c-1.2-1.7-4.7-7.1-5.1-9.7c-0.4-3.2,1.4-4.4,1.5-4.5l0,0l0.2,0.4l-0.2-0.4c0.7-0.4,1.6-0.6,2.4-0.4
                  c0.6,0.2,1,0.5,1.3,1c1,1.9,5.5,10.3,6.9,14.5c0.2,0.6,0,1.2-0.6,1.6c-2.4,1.4-10.4,6.2-15,6.6L554.2,301.5z M554.1,298.5
                  c0.1,1.3,0.3,1.9,0.4,2.2c4.5-0.5,12.2-5,14.4-6.4c0.2-0.1,0.3-0.4,0.2-0.6c-1.4-4.1-5.8-12.5-6.8-14.4
                  c-0.2-0.4-0.5-0.5-0.8-0.6c-0.5-0.1-1.2,0-1.7,0.3l-0.2-0.3l0.2,0.3c-0.1,0-1.4,1-1.1,3.7c0.3,2.8,5.2,9.8,5.3,9.9l0.4,0.6
                  l-0.7,0.1c-1.9,0.2-3.8,1.5-4.5,2.1C558.1,296.1,555,298,554.1,298.5z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_3003_">
                    <g id="XMLID_3004_">
                      <path
                        id="XMLID_3006_"
                        d="M565.1,293.8c-0.5-0.5-1.5-0.7-1.5-0.7l0.1-0.8c0.1,0,1.2,0.2,2,0.9L565.1,293.8z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_2984_">
                  <g id="XMLID_2997_">
                    <g id="XMLID_2999_">
                      <path
                        id="XMLID_3000_"
                        d="M546.6,271.9c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4l0.3-1.9
                  l0.6,0.1l-0.3,1.8c0.3,0,0.6,0,0.8,0l0.1,0.6C547,271.9,546.8,271.9,546.6,271.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2994_">
                    <g id="XMLID_2995_">
                      <ellipse
                        id="XMLID_2996_"
                        cx="548"
                        cy="269.2"
                        rx="0.6"
                        ry="0.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2990_">
                    <g id="XMLID_2991_">
                      <path
                        id="XMLID_2993_"
                        d="M544.5,269c0,0.5,0.2,0.8,0.4,0.8c0.2,0,0.4-0.4,0.4-0.8c0-0.5-0.2-0.8-0.4-0.8
                  C544.7,268.2,544.5,268.5,544.5,269z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2986_">
                    <g id="XMLID_2987_">
                      <path
                        id="XMLID_2989_"
                        d="M547,273.5c-0.2,0-0.4,0-0.6-0.1l0.3-0.6c0.3,0.1,0.7,0,0.7,0l0.2,0.6
                  C547.5,273.4,547.3,273.5,547,273.5z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_2910_">
                  <g id="XMLID_2918_">
                    <g id="XMLID_2982_">
                      <path
                        id="XMLID_2983_"
                        style="fill: #ffffff"
                        d="M552.6,284.9c0,0.9,0.5,1.6,1.2,1.6c0.7,0,1.2-0.7,1.2-1.6
                  c0-0.9-0.5-1.6-1.2-1.6C553.2,283.4,552.6,284.1,552.6,284.9z"
                      />
                    </g>
                    <g id="XMLID_2919_">
                      <path
                        id="XMLID_2920_"
                        d="M553.8,286.9c-0.9,0-1.6-0.9-1.6-2c0-1.1,0.7-2,1.6-2c0.9,0,1.6,0.9,1.6,2
                  C555.4,286,554.7,286.9,553.8,286.9z M553.8,283.8c-0.4,0-0.8,0.5-0.8,1.2c0,0.6,0.4,1.2,0.8,1.2c0.4,0,0.8-0.5,0.8-1.2
                  C554.6,284.3,554.3,283.8,553.8,283.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2911_">
                    <g id="XMLID_2916_">
                      <path
                        id="XMLID_2917_"
                        style="fill: #ffffff"
                        d="M553.8,287.2c0,0.9,0.5,1.6,1.2,1.6c0.7,0,1.2-0.7,1.2-1.6
                  c0-0.9-0.5-1.6-1.2-1.6C554.3,285.7,553.8,286.4,553.8,287.2z"
                      />
                    </g>
                    <g id="XMLID_2912_">
                      <path
                        id="XMLID_2913_"
                        d="M554.9,289.2c-0.9,0-1.6-0.9-1.6-2c0-1.1,0.7-2,1.6-2c0.9,0,1.6,0.9,1.6,2
                  C556.6,288.3,555.8,289.2,554.9,289.2z M554.9,286.1c-0.4,0-0.8,0.5-0.8,1.2c0,0.6,0.4,1.2,0.8,1.2c0.4,0,0.8-0.5,0.8-1.2
                  C555.7,286.6,555.4,286.1,554.9,286.1z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_2901_">
                  <g id="XMLID_2902_">
                    <g id="XMLID_2903_">
                      <g id="XMLID_2908_">
                        <polygon
                          id="XMLID_2909_"
                          style="fill: #ffffff"
                          points="545.5,279.7 540.8,282.3 536.2,279.7 540.8,277                   "
                        />
                      </g>
                      <g id="XMLID_2904_">
                        <path
                          id="XMLID_2905_"
                          d="M540.8,282.8l-5.4-3.1l5.4-3.2l5.5,3.1L540.8,282.8z M537.1,279.7l3.8,2.1l3.8-2.2l-3.8-2.2
                    L537.1,279.7z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_2894_">
                  <g id="XMLID_2899_">
                    <polygon
                      id="XMLID_2900_"
                      style="fill: #ffffff"
                      points="541.5,286.2 545.5,283.9 539.6,280.5 535.6,282.8               "
                    />
                  </g>
                  <g id="XMLID_2895_">
                    <path
                      id="XMLID_2896_"
                      d="M541.5,286.7l-6.7-3.9l4.9-2.7l6.7,3.9L541.5,286.7z M536.4,282.8l5,2.9l3.2-1.8l-5-2.9
                L536.4,282.8z"
                    />
                  </g>
                </g>
                <g id="XMLID_2879_">
                  <g id="XMLID_2887_">
                    <g id="XMLID_2892_">
                      <path
                        id="XMLID_2893_"
                        style="fill: #ffffff"
                        d="M538.6,274.6c0.1,0,0.3,0.1,0.2,0.3l-0.7,7.4c0,0.4-0.4,0.9-1.3,1.1
                  c-0.5,0.1-1,0.1-1.5,0c-0.9-0.2-1.3-0.7-1.3-1.1l-0.7-7.4c0-0.1,0.1-0.3,0.2-0.3H538.6z"
                      />
                    </g>
                    <g id="XMLID_2888_">
                      <path
                        id="XMLID_2889_"
                        d="M536.1,283.9c-0.3,0-0.6,0-0.8-0.1c-1-0.3-1.6-0.8-1.6-1.5l-0.7-7.4c0-0.2,0-0.4,0.2-0.5
                  c0.1-0.1,0.3-0.2,0.5-0.2h5c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5l-0.7,7.4c0,0.7-0.6,1.2-1.6,1.5
                  C536.6,283.9,536.3,283.9,536.1,283.9z M533.7,275l0.7,7.2c0,0.3,0.3,0.6,1,0.7c0.4,0.1,0.9,0.1,1.3,0c0.7-0.2,1-0.5,1-0.7
                  l0.7-7.3H533.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2880_">
                    <g id="XMLID_2885_">
                      <path
                        id="XMLID_2886_"
                        style="fill: #ffffff"
                        d="M534.1,273.5c-1.1,0.6-1.1,1.7,0,2.3c1.1,0.6,2.9,0.6,4,0
                  c1.1-0.6,1.1-1.7,0-2.3C537,272.8,535.2,272.8,534.1,273.5z"
                      />
                    </g>
                    <g id="XMLID_2881_">
                      <path
                        id="XMLID_2882_"
                        d="M536.1,276.6c-0.8,0-1.6-0.2-2.2-0.5c-0.7-0.4-1-0.9-1-1.5c0-0.6,0.4-1.1,1-1.5h0
                  c1.2-0.7,3.1-0.7,4.4,0c0.7,0.4,1,0.9,1,1.5c0,0.6-0.4,1.1-1,1.5C537.6,276.5,536.9,276.6,536.1,276.6z M534.3,273.8
                  c-0.4,0.2-0.6,0.5-0.6,0.8c0,0.3,0.2,0.6,0.6,0.8c1,0.6,2.6,0.6,3.5,0c0.4-0.2,0.6-0.5,0.6-0.8c0-0.3-0.2-0.6-0.6-0.8
                  C536.9,273.3,535.3,273.3,534.3,273.8L534.3,273.8z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_2868_">
                  <g id="XMLID_2872_">
                    <g id="XMLID_2877_">
                      <path
                        id="XMLID_2878_"
                        style="fill: #ffffff"
                        d="M565.1,284.3l-2.5,1.1c0,0-1.4-1.8-1.8-3.6l2.4-1.3
                  C563.3,280.5,564.4,282.9,565.1,284.3z"
                      />
                    </g>
                    <g id="XMLID_2873_">
                      <path
                        id="XMLID_2874_"
                        d="M562.5,285.8l-0.2-0.2c-0.1-0.1-1.4-1.9-1.8-3.8l-0.1-0.2l2.9-1.5l0.1,0.3c0,0,1.1,2.3,1.8,3.8
                  l0.1,0.3L562.5,285.8z M561.2,281.9c0.4,1.3,1.2,2.6,1.5,3.1l1.9-0.9c-0.5-1.1-1.2-2.6-1.5-3.2L561.2,281.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2869_">
                    <g id="XMLID_2870_">
                      <polygon
                        id="XMLID_2871_"
                        points="563.5,283.8 560.9,282.6 561.1,282 563,282.9 563.3,281.2 564,281.3                 "
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="reseaux"
          @mouseover="hilight('network', true)"
          @mouseleave="hilight('network', false)"
          @click="activate('network')">
        <g id="reseaux-socle">
          <path
            id="XMLID_13723_"
            style="fill: #e5ecf4"
            :class="{ active: active_network, hilighted: hilighted_network }"
            d="M320.6,413.7l143.9,82.3c5.9,3.4,13.1,3.4,18.9,0l135.2-79.2
      c4.9-2.8,4.9-9.9,0-12.8l-143.9-82.3c-5.9-3.4-13.1-3.4-18.9,0L320.6,401C315.7,403.8,315.7,410.9,320.6,413.7z"
          />
        </g>
        <g id="reseaux-illus">
          <g id="XMLID_15518_">
            <g id="XMLID_15594_">
              <g id="XMLID_15604_">
                <g id="XMLID_15612_">
                  <g id="XMLID_15617_">
                    <path
                      id="XMLID_15618_"
                      style="fill: #ffffff"
                      d="M348.5,328.5c0,0,0-2.7,0-3.6c0-0.2,0.1-0.3,0.3-0.4l8.5-2l43-24.8l3.3-2
                l6.9,0.9c0.2,0,0.3,0.2,0.3,0.4l0,3.5c0.1,0.5-0.1,1.1-0.7,1.4l-52.7,30.4c-1.1,0.6-2.4,0.6-3.5,0l-4.7-2.7
                C348.8,329.4,348.6,329,348.5,328.5L348.5,328.5z"
                    />
                  </g>
                  <g id="XMLID_15613_">
                    <path
                      id="XMLID_15614_"
                      d="M355.6,333.4c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.3-0.9-0.9-1-1.5l0-0.1c0,0,0-0.1,0-2.7l0-0.9
                c0-0.4,0.3-0.8,0.7-0.9l8.4-2l42.9-24.8l3.4-2.1l7.1,0.9c0.5,0.1,0.8,0.5,0.8,0.9l0,3.4c0.1,0.8-0.3,1.6-0.9,2l-52.7,30.4
                C357,333.2,356.3,333.4,355.6,333.4z M349.1,328.5L349.1,328.5c0,0.2,0.1,0.5,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0l52.7-30.4
                c0.4-0.2,0.5-0.6,0.4-0.9l0-0.1l0-3.3l-6.6-0.9l-3.1,1.9l-43,24.8l-8.5,2l0,0.8C349.1,326.9,349.1,328.3,349.1,328.5z"
                    />
                  </g>
                </g>
                <g id="XMLID_15605_">
                  <g id="XMLID_15610_">
                    <path
                      id="XMLID_15611_"
                      style="fill: #ffffff"
                      d="M357.4,328.6l52.7-30.4c0.9-0.5,0.9-1.9,0-2.4l-4.7-2.7
                c-1.1-0.6-2.4-0.6-3.5,0l-52.7,30.4c-0.9,0.5-0.9,1.9,0,2.4l4.7,2.7C355,329.2,356.3,329.2,357.4,328.6z"
                    />
                  </g>
                  <g id="XMLID_15606_">
                    <path
                      id="XMLID_15607_"
                      d="M355.6,329.6c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l52.7-30.4
                c1.2-0.7,2.8-0.7,4,0l4.7,2.7c0.6,0.4,1,1,1,1.7s-0.4,1.3-1,1.7l-52.7,30.4C357,329.4,356.3,329.6,355.6,329.6z M403.7,293.1
                c-0.5,0-1,0.1-1.5,0.4l-52.7,30.4c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0l52.7-30.4
                c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-4.7-2.7C404.7,293.2,404.2,293.1,403.7,293.1z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_15601_">
                <g id="XMLID_15602_">
                  <rect
                    id="XMLID_15603_"
                    x="354.8"
                    y="329.1"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15598_">
                <g id="XMLID_15599_">
                  <rect
                    id="XMLID_15600_"
                    x="407"
                    y="299.5"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15595_">
                <g id="XMLID_15596_">
                  <polygon
                    id="XMLID_15597_"
                    points="358.2,328.1 358.2,331.6 404.5,305.2 404.5,301.4             "
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_15569_">
              <g id="XMLID_15579_">
                <g id="XMLID_15587_">
                  <g id="XMLID_15592_">
                    <path
                      id="XMLID_15593_"
                      style="fill: #ffffff"
                      d="M357.9,340.9c0,0,0-2.7,0-3.6c0-0.2,0.1-0.3,0.3-0.4l8.5-2l43-24.8l3.3-2
                l6.9,0.9c0.2,0,0.3,0.2,0.3,0.4l0,3.5c0.1,0.5-0.1,1.1-0.7,1.5l-52.7,30.4c-1.1,0.6-2.4,0.6-3.5,0l-4.7-2.7
                C358.2,341.8,358,341.4,357.9,340.9L357.9,340.9z"
                    />
                  </g>
                  <g id="XMLID_15588_">
                    <path
                      id="XMLID_15589_"
                      d="M365,345.7c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.3-0.9-0.9-1-1.5l0-0.1c0,0,0-0.1,0-2.7l0-0.9
                c0-0.4,0.3-0.8,0.7-0.9l8.4-2l42.9-24.8l3.4-2.1l7.1,0.9c0.5,0.1,0.8,0.5,0.8,0.9l0,3.4c0.1,0.8-0.3,1.6-0.9,2L367,345.2
                C366.4,345.5,365.7,345.7,365,345.7z M358.5,340.8L358.5,340.8c0,0.2,0.1,0.5,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0l52.7-30.4
                c0.4-0.2,0.5-0.6,0.4-0.9l0-0.1l0-3.3l-6.6-0.9l-3.1,1.9l-43,24.8l-8.5,2l0,0.8C358.4,339.3,358.5,340.6,358.5,340.8z"
                    />
                  </g>
                </g>
                <g id="XMLID_15580_">
                  <g id="XMLID_15585_">
                    <path
                      id="XMLID_15586_"
                      style="fill: #ffffff"
                      d="M366.8,340.9l52.7-30.4c0.9-0.5,0.9-1.9,0-2.4l-4.7-2.7
                c-1.1-0.6-2.4-0.6-3.5,0l-52.7,30.4c-0.9,0.5-0.9,1.9,0,2.4l4.7,2.7C364.4,341.5,365.7,341.5,366.8,340.9z"
                    />
                  </g>
                  <g id="XMLID_15581_">
                    <path
                      id="XMLID_15582_"
                      d="M365,341.9c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l52.7-30.4
                c1.2-0.7,2.8-0.7,4,0l4.7,2.7c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7L367,341.4C366.4,341.7,365.7,341.9,365,341.9z
                 M413.1,305.4c-0.5,0-1,0.1-1.5,0.4l-52.7,30.4c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0
                l52.7-30.4c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-4.7-2.7C414.1,305.6,413.6,305.4,413.1,305.4z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_15576_">
                <g id="XMLID_15577_">
                  <rect
                    id="XMLID_15578_"
                    x="364.2"
                    y="341.4"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15573_">
                <g id="XMLID_15574_">
                  <rect
                    id="XMLID_15575_"
                    x="416.4"
                    y="311.9"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15570_">
                <g id="XMLID_15571_">
                  <polygon
                    id="XMLID_15572_"
                    points="367.6,340.4 367.6,343.9 413.9,317.5 413.9,313.8             "
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_15544_">
              <g id="XMLID_15554_">
                <g id="XMLID_15562_">
                  <g id="XMLID_15567_">
                    <path
                      id="XMLID_15568_"
                      style="fill: #ffffff"
                      d="M367.3,353.2c0,0,0-2.7,0-3.6c0-0.2,0.1-0.3,0.3-0.4l8.5-2l43-24.8l3.3-2
                l6.9,0.9c0.2,0,0.3,0.2,0.3,0.4l0,3.5c0.1,0.5-0.1,1.1-0.7,1.4L376.2,357c-1.1,0.6-2.4,0.6-3.5,0l-4.7-2.7
                C367.6,354.1,367.3,353.7,367.3,353.2L367.3,353.2z"
                    />
                  </g>
                  <g id="XMLID_15563_">
                    <path
                      id="XMLID_15564_"
                      d="M374.4,358.1c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.3-0.9-0.9-1-1.5l0-0.1c0,0,0-3.5,0-3.6
                c0-0.4,0.3-0.8,0.7-0.9l8.4-2l42.9-24.8l3.4-2.1l7.1,0.9c0.5,0.1,0.8,0.5,0.8,0.9l0,3.4c0.1,0.8-0.3,1.6-0.9,2l-52.7,30.4
                C375.8,357.9,375.1,358.1,374.4,358.1z M367.8,353.2L367.8,353.2c0,0.2,0.1,0.5,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0l52.7-30.4
                c0.4-0.2,0.5-0.6,0.4-0.9l0-0.1l0-3.3l-6.6-0.9l-3.1,1.9l-43,24.8l-8.5,2C367.8,350.7,367.8,352.9,367.8,353.2z"
                    />
                  </g>
                </g>
                <g id="XMLID_15555_">
                  <g id="XMLID_15560_">
                    <path
                      id="XMLID_15561_"
                      style="fill: #ffffff"
                      d="M376.2,353.2l52.7-30.4c0.9-0.5,0.9-1.9,0-2.4l-4.7-2.7
                c-1.1-0.6-2.4-0.6-3.5,0L368,348.1c-0.9,0.5-0.9,1.9,0,2.4l4.7,2.7C373.8,353.9,375.1,353.9,376.2,353.2z"
                    />
                  </g>
                  <g id="XMLID_15556_">
                    <path
                      id="XMLID_15557_"
                      d="M374.4,354.3c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l52.7-30.4
                c1.2-0.7,2.8-0.7,4,0l4.7,2.7c0.6,0.4,1,1,1,1.7s-0.4,1.3-1,1.7l-52.7,30.4C375.8,354.1,375.1,354.3,374.4,354.3z
                 M422.5,317.7c-0.5,0-1,0.1-1.5,0.4l-52.7,30.4c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0
                l52.7-30.4c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-4.7-2.7C423.5,317.9,423,317.7,422.5,317.7z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_15551_">
                <g id="XMLID_15552_">
                  <rect
                    id="XMLID_15553_"
                    x="373.6"
                    y="353.7"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15548_">
                <g id="XMLID_15549_">
                  <rect
                    id="XMLID_15550_"
                    x="425.8"
                    y="324.2"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15545_">
                <g id="XMLID_15546_">
                  <polygon
                    id="XMLID_15547_"
                    points="377,352.8 377,356.3 423.3,329.8 423.3,326.1             "
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_15519_">
              <g id="XMLID_15529_">
                <g id="XMLID_15537_">
                  <g id="XMLID_15542_">
                    <path
                      id="XMLID_15543_"
                      style="fill: #ffffff"
                      d="M376.7,365.5c0,0,0-2.7,0-3.6c0-0.2,0.1-0.3,0.3-0.4l8.5-2l43-24.8l3.3-2
                l6.9,0.9c0.2,0,0.3,0.2,0.3,0.4l0,3.5c0.1,0.5-0.1,1.1-0.7,1.4l-52.7,30.4c-1.1,0.6-2.4,0.6-3.5,0l-4.7-2.7
                C377,366.4,376.8,366,376.7,365.5L376.7,365.5z"
                    />
                  </g>
                  <g id="XMLID_15538_">
                    <path
                      id="XMLID_15539_"
                      d="M383.8,370.4c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.3-0.9-0.9-1-1.5l0-0.1c0,0,0-3.5,0-3.6
                c0-0.4,0.3-0.8,0.7-0.9l8.4-2l42.9-24.8l3.4-2.1l7.1,0.9c0.5,0.1,0.8,0.5,0.8,0.9l0,3.4c0.1,0.8-0.3,1.6-0.9,2l-52.7,30.4
                C385.2,370.2,384.5,370.4,383.8,370.4z M377.3,365.5L377.3,365.5c0,0.2,0.1,0.5,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0l52.7-30.4
                c0.4-0.2,0.5-0.6,0.4-0.9l0-0.1l0-3.3l-6.6-0.9l-3.1,1.9l-43,24.8l-8.5,2C377.3,363,377.3,365.2,377.3,365.5z"
                    />
                  </g>
                </g>
                <g id="XMLID_15530_">
                  <g id="XMLID_15535_">
                    <path
                      id="XMLID_15536_"
                      style="fill: #ffffff"
                      d="M385.6,365.6l52.7-30.4c0.9-0.5,0.9-1.9,0-2.4l-4.7-2.7
                c-1.1-0.6-2.4-0.6-3.5,0l-52.7,30.4c-0.9,0.5-0.9,1.9,0,2.4l4.7,2.7C383.2,366.2,384.5,366.2,385.6,365.6z"
                    />
                  </g>
                  <g id="XMLID_15531_">
                    <path
                      id="XMLID_15532_"
                      d="M383.8,366.6c-0.7,0-1.4-0.2-2-0.5l-4.7-2.7c-0.6-0.4-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l52.7-30.4
                c1.2-0.7,2.8-0.7,4,0l4.7,2.7c0.6,0.4,1,1,1,1.7c0,0.7-0.4,1.3-1,1.7L385.9,366C385.2,366.4,384.5,366.6,383.8,366.6z
                 M431.9,330.1c-0.5,0-1,0.1-1.5,0.4l-52.7,30.4c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7l4.7,2.7c0.9,0.5,2,0.5,3,0
                l52.7-30.4c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7l-4.7-2.7C432.9,330.2,432.4,330.1,431.9,330.1z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_15526_">
                <g id="XMLID_15527_">
                  <rect
                    id="XMLID_15528_"
                    x="383"
                    y="366"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15523_">
                <g id="XMLID_15524_">
                  <rect
                    id="XMLID_15525_"
                    x="435.2"
                    y="336.5"
                    width="1.1"
                    height="3.8"
                  />
                </g>
              </g>
              <g id="XMLID_15520_">
                <g id="XMLID_15521_">
                  <polygon
                    id="XMLID_15522_"
                    points="386.4,365.1 386.4,368.6 432.8,342.1 432.8,338.4             "
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_13030_">
            <g id="XMLID_13088_">
              <g id="XMLID_13089_">
                <g id="XMLID_13118_">
                  <path
                    id="XMLID_13119_"
                    style="fill: #ffffff"
                    d="M478,297.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C478.1,296.9,478.1,297.1,478,297.2z"
                  />
                </g>
                <g id="XMLID_13116_">
                  <path
                    id="XMLID_13117_"
                    style="fill: #ffffff"
                    d="M482,299.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C482.1,299.3,482.2,299.4,482,299.5z"
                  />
                </g>
                <g id="XMLID_13114_">
                  <path
                    id="XMLID_13115_"
                    style="fill: #ffffff"
                    d="M477.8,302l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C477.9,301.7,477.9,301.9,477.8,302z"
                  />
                </g>
                <g id="XMLID_13112_">
                  <path
                    id="XMLID_13113_"
                    style="fill: #ffffff"
                    d="M486,301.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C486.2,301.6,486.2,301.8,486,301.9z"
                  />
                </g>
                <g id="XMLID_13110_">
                  <path
                    id="XMLID_13111_"
                    style="fill: #ffffff"
                    d="M481.8,304.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C481.9,304,482,304.2,481.8,304.3z"
                  />
                </g>
                <g id="XMLID_13108_">
                  <path
                    id="XMLID_13109_"
                    style="fill: #ffffff"
                    d="M490.3,304.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C490.4,304,490.4,304.2,490.3,304.3z"
                  />
                </g>
                <g id="XMLID_13106_">
                  <path
                    id="XMLID_13107_"
                    style="fill: #ffffff"
                    d="M486,306.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C486.2,306.5,486.2,306.7,486,306.8z"
                  />
                </g>
                <g id="XMLID_13104_">
                  <path
                    id="XMLID_13105_"
                    style="fill: #ffffff"
                    d="M494.5,301.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C494.7,301.6,494.7,301.8,494.5,301.9z"
                  />
                </g>
                <g id="XMLID_13102_">
                  <path
                    id="XMLID_13103_"
                    style="fill: #ffffff"
                    d="M498.7,299.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C498.9,299.1,498.9,299.3,498.7,299.4z"
                  />
                </g>
                <g id="XMLID_13100_">
                  <path
                    id="XMLID_13101_"
                    style="fill: #ffffff"
                    d="M503,297l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C503.1,296.7,503.1,296.9,503,297z"
                  />
                </g>
                <g id="XMLID_13098_">
                  <path
                    id="XMLID_13099_"
                    style="fill: #ffffff"
                    d="M494.3,306.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C494.4,306.4,494.5,306.5,494.3,306.6z"
                  />
                </g>
                <g id="XMLID_13096_">
                  <path
                    id="XMLID_13097_"
                    style="fill: #ffffff"
                    d="M498.5,304.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C498.7,303.9,498.7,304.1,498.5,304.2z"
                  />
                </g>
                <g id="XMLID_13094_">
                  <path
                    id="XMLID_13095_"
                    style="fill: #ffffff"
                    d="M502.8,301.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C502.9,301.5,502.9,301.6,502.8,301.7z"
                  />
                </g>
                <g id="XMLID_13092_">
                  <path
                    id="XMLID_13093_"
                    style="fill: #ffffff"
                    d="M507.2,294.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
              c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C507.4,294.3,507.4,294.5,507.2,294.6z"
                  />
                </g>
                <g id="XMLID_13090_">
                  <path
                    id="XMLID_13091_"
                    style="fill: #ffffff"
                    d="M507,299.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C507.1,299.1,507.2,299.2,507,299.3z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_13031_">
              <g id="XMLID_13059_">
                <g id="XMLID_13081_">
                  <g id="XMLID_13086_">
                    <path
                      id="XMLID_13087_"
                      style="fill: #ffffff"
                      d="M504.7,289.5c0,0,0-1.9,0-2.3c0,0,0-0.1-0.1-0.1l-15.6,4c0,0,0,0,0,0
                l4.5-8.8c0,0-0.1,0-0.1,0l-14.8,8.5c0,0,0,0-0.1,0l-1.8-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.5,0,2.2,0,2.2l0,0
                c0,0.3,0.2,0.6,0.5,0.8l9.5,5.5c0.8,0.5,1.8,0.5,2.6,0l15-8.7C504.6,290.2,504.7,289.9,504.7,289.5L504.7,289.5z"
                    />
                  </g>
                  <g id="XMLID_13082_">
                    <path
                      id="XMLID_13083_"
                      d="M487.9,299.8c-0.5,0-1.1-0.1-1.5-0.4l-9.5-5.5c-0.4-0.2-0.6-0.6-0.7-1.1c0-0.1,0-0.1,0-2.1l0-0.2
                c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.7,0.5l14.7-8.5c0.2-0.1,0.3-0.1,0.5,0l0.3,0.2l-4.2,8.3l14.9-3.8l0.1,0
                c0.2,0.1,0.4,0.3,0.4,0.5v0.5c0,0.7,0,1.8,0,1.8l0,0.1c0,0.5-0.3,0.9-0.7,1.1l-15,8.7C489,299.7,488.4,299.8,487.9,299.8z
                 M477,290.9c0,0.7,0,1.7,0,1.8c0,0.1,0.1,0.3,0.3,0.4l9.5,5.5c0.7,0.4,1.5,0.4,2.2,0l15-8.7c0.2-0.1,0.3-0.3,0.3-0.5h0.4
                l-0.4,0c0,0,0-1.1,0-1.8v-0.1l-15.3,4l-0.2-0.1l-0.3-0.2l4-7.9l-13.7,7.9c-0.1,0.1-0.3,0.1-0.4,0L477,290.9z"
                    />
                  </g>
                </g>
                <g id="XMLID_13074_">
                  <g id="XMLID_13079_">
                    <path
                      id="XMLID_13080_"
                      d="M504.7,287.1l-15.6,4c0,0,0,0,0,0l-1.2,3.5v4.7c0.5,0,1-0.1,1.4-0.3l15-8.7
                c0.3-0.2,0.5-0.5,0.5-0.8l0,0C504.7,289.5,504.7,287.6,504.7,287.1C504.7,287.2,504.7,287.1,504.7,287.1z"
                    />
                  </g>
                  <g id="XMLID_13075_">
                    <path
                      id="XMLID_13076_"
                      d="M487.9,299.8C487.9,299.8,487.9,299.8,487.9,299.8l-0.5,0l0-5.3l1.4-4l0.3,0.2l15.6-4l0.1,0
                c0.2,0.1,0.4,0.3,0.4,0.5v0.5c0,0.7,0,1.8,0,1.8c0,0.5-0.3,1-0.7,1.2l-15,8.7C489,299.7,488.5,299.8,487.9,299.8z
                 M488.3,294.7v4.2c0.3,0,0.5-0.1,0.8-0.3l15-8.7c0.2-0.1,0.3-0.3,0.3-0.5c0,0,0-1.1,0-1.8v-0.1l-15,3.9L488.3,294.7z"
                    />
                  </g>
                </g>
                <g id="XMLID_13067_">
                  <g id="XMLID_13072_">
                    <path
                      id="XMLID_13073_"
                      d="M478.1,291.7c0,0,0.1-0.4,0.3-0.9l-1.7-0.5c-0.1,0-0.1,0-0.1,0.1c0,0.5,0,2.2,0,2.2l0,0
                c0,0.3,0.2,0.6,0.5,0.8l1,0.6l0,0V291.7z"
                    />
                  </g>
                  <g id="XMLID_13068_">
                    <path
                      id="XMLID_13069_"
                      d="M478.5,294.8l-1.6-0.9c-0.4-0.2-0.6-0.6-0.7-1.1c0-0.1,0-0.1,0-2.1l0-0.2c0-0.2,0.1-0.3,0.2-0.4
                c0.1-0.1,0.3-0.1,0.4-0.1l2.1,0.6l-0.1,0.4c-0.1,0.4-0.2,0.7-0.3,0.8V294.8z M477,290.9c0,0.7,0,1.7,0,1.8
                c0,0.1,0.1,0.3,0.3,0.4l0.3,0.2l0-1.7c0,0,0.1-0.2,0.2-0.4L477,290.9z"
                    />
                  </g>
                </g>
                <g id="XMLID_13060_">
                  <g id="XMLID_13065_">
                    <path
                      id="XMLID_13066_"
                      style="fill: #ffffff"
                      d="M486.7,296.7l-9.5-5.5c-0.7-0.4-0.7-1.4,0-1.7l15.1-8.7
                c0.8-0.5,1.7-0.5,2.5,0l9.5,5.5c0.7,0.4,0.7,1.4,0,1.7l-15.1,8.7C488.4,297.2,487.4,297.2,486.7,296.7z"
                    />
                  </g>
                  <g id="XMLID_13061_">
                    <path
                      id="XMLID_13062_"
                      d="M487.9,297.5c-0.5,0-1-0.1-1.5-0.4l-9.5-5.5c-0.4-0.3-0.7-0.7-0.7-1.2c0-0.5,0.3-1,0.7-1.2
                l15.1-8.7c0.9-0.5,2-0.5,2.9,0l9.5,5.5c0.4,0.3,0.7,0.7,0.7,1.2c0,0.5-0.3,1-0.7,1.2l-15.1,8.7
                C488.9,297.4,488.4,297.5,487.9,297.5z M493.4,280.9c-0.4,0-0.7,0.1-1.1,0.3l-15.1,8.7c-0.2,0.1-0.3,0.3-0.3,0.5
                c0,0.2,0.1,0.4,0.3,0.5l9.5,5.5c0.7,0.4,1.5,0.4,2.1,0l15.1-8.7c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5l-9.5-5.5
                C494.2,281,493.8,280.9,493.4,280.9z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_13032_">
                <g id="XMLID_13036_">
                  <g id="XMLID_13052_">
                    <g id="XMLID_13057_">
                      <path
                        id="XMLID_13058_"
                        style="fill: #ffffff"
                        d="M496.7,280.2l-2.6-1.5c-0.1-0.1-0.2-0.1-0.3,0l-3.2,2
                  c-0.1,0-0.1,0.1,0,0.2l3.1,3.3c0,0,0,0,0,0.1v1.1c0,0.3,1.2-0.6,1.5-0.4l0,0c0,0,0,0,0,0l-1.6,1c0,0.1,0.1,0.1,0.2,0l3.2-2
                  c0,0,0.1-0.2,0.1-0.3V281C497.2,280.7,497,280.3,496.7,280.2z"
                      />
                    </g>
                    <g id="XMLID_13053_">
                      <path
                        id="XMLID_13054_"
                        d="M493.8,286.2c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.3l-0.2-0.3l0.2-0.1l0,0l0-0.3v-0.9l-3-3.2
                  c-0.2-0.3-0.1-0.6,0.2-0.7l3.2-2c0.2-0.1,0.5-0.1,0.7,0l2.6,1.5c0.4,0.2,0.6,0.7,0.6,1.2v2.5c0,0.3-0.2,0.5-0.2,0.6
                  l-0.1,0.1l-3.2,2C494,286.2,493.9,286.2,493.8,286.2z M491.2,280.8l2.9,3.1c0,0.1,0.1,0.2,0.1,0.3v0.6c0,0,0.1,0,0.1-0.1
                  c0.5-0.2,0.8-0.4,1.1-0.3l1.4-0.9c0,0,0,0,0,0l0-2.5c0-0.2-0.1-0.4-0.2-0.4l0,0L494,279L491.2,280.8z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_13045_">
                    <g id="XMLID_13050_">
                      <path
                        id="XMLID_13051_"
                        d="M491.5,283.6c0,0,0,0.1,0,0.2l2.2,1.9c0.1,0.1,0.2,0.1,0.3,0.1l3.1-1.9c0,0,0.1-0.2,0.1-0.3v-2.3
                  c0-0.1-0.1-0.1-0.1-0.1l-3.1,2c0,0,0,0,0,0L491.5,283.6z"
                      />
                    </g>
                    <g id="XMLID_13046_">
                      <path
                        id="XMLID_13047_"
                        d="M493.8,286.2c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.2l-2.3-2l0.1-0.7l2.6-0.6l3.1-2
                  c0.2-0.1,0.3-0.1,0.5,0c0.2,0.1,0.3,0.3,0.3,0.4v2.3c0,0.3-0.2,0.5-0.2,0.6l-0.1,0.1l-3.1,1.9
                  C494.1,286.2,493.9,286.2,493.8,286.2z M492.1,283.9l1.7,1.4l2.9-1.8c0,0,0,0,0,0l0-1.7l-2.6,1.6l0,0.1L492.1,283.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_13037_">
                    <g id="XMLID_13043_">
                      <path
                        id="XMLID_13044_"
                        style="fill: #ffffff"
                        d="M493.5,285.8l-2.6-1.5c-0.2-0.1-0.4-0.5-0.4-0.8V281
                  c0-0.3,0.2-0.5,0.4-0.3l2.6,1.5c0.2,0.1,0.4,0.5,0.4,0.8v2.5C493.9,285.8,493.7,286,493.5,285.8z"
                      />
                    </g>
                    <g id="XMLID_13038_">
                      <path
                        id="XMLID_13039_"
                        d="M493.6,286.3c-0.1,0-0.3,0-0.4-0.1l0,0l-2.6-1.5c-0.4-0.2-0.6-0.7-0.6-1.2V281
                  c0-0.3,0.1-0.6,0.4-0.7c0.2-0.1,0.5-0.1,0.7,0l2.6,1.5c0.4,0.2,0.6,0.7,0.6,1.2v2.5c0,0.3-0.1,0.6-0.4,0.7
                  C493.8,286.3,493.7,286.3,493.6,286.3z M493.7,285.5l-0.2,0.4L493.7,285.5L493.7,285.5L493.7,285.5z M490.9,281.2l0,2.3
                  c0,0.2,0.1,0.4,0.2,0.4l2.4,1.4l0-2.3c0-0.2-0.1-0.4-0.2-0.4L490.9,281.2z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_13033_">
                  <g id="XMLID_13034_">
                    <rect
                      id="XMLID_13035_"
                      x="493.9"
                      y="283.2"
                      transform="matrix(0.8567 -0.5159 0.5159 0.8567 -75.2134 296.2766)"
                      style="fill: #ffffff"
                      width="3.3"
                      height="0.6"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_9481_">
            <g id="XMLID_9511_">
              <g id="XMLID_10383_">
                <g id="XMLID_10430_">
                  <g id="XMLID_10461_">
                    <path
                      id="XMLID_10462_"
                      style="fill: #ffffff"
                      d="M620.7,415.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C620.9,415,620.9,415.2,620.7,415.3z"
                    />
                  </g>
                  <g id="XMLID_10459_">
                    <path
                      id="XMLID_10460_"
                      style="fill: #ffffff"
                      d="M616.5,417.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C616.6,417.5,616.6,417.6,616.5,417.7z"
                    />
                  </g>
                  <g id="XMLID_10457_">
                    <path
                      id="XMLID_10458_"
                      style="fill: #ffffff"
                      d="M624.7,417.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C624.9,417.3,624.9,417.5,624.7,417.6z"
                    />
                  </g>
                  <g id="XMLID_10455_">
                    <path
                      id="XMLID_10456_"
                      style="fill: #ffffff"
                      d="M620.5,420.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C620.6,419.8,620.7,420,620.5,420.1z"
                    />
                  </g>
                  <g id="XMLID_10453_">
                    <path
                      id="XMLID_10454_"
                      style="fill: #ffffff"
                      d="M629,420.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
                l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C629.1,419.8,629.2,420,629,420.1z"
                    />
                  </g>
                  <g id="XMLID_10451_">
                    <path
                      id="XMLID_10452_"
                      style="fill: #ffffff"
                      d="M624.7,422.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C624.9,422.2,624.9,422.4,624.7,422.5z"
                    />
                  </g>
                  <g id="XMLID_10449_">
                    <path
                      id="XMLID_10450_"
                      style="fill: #ffffff"
                      d="M633.2,417.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C633.4,417.4,633.4,417.5,633.2,417.6z"
                    />
                  </g>
                  <g id="XMLID_10447_">
                    <path
                      id="XMLID_10448_"
                      style="fill: #ffffff"
                      d="M637.4,415.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C637.6,414.9,637.6,415.1,637.4,415.2z"
                    />
                  </g>
                  <g id="XMLID_10445_">
                    <path
                      id="XMLID_10446_"
                      style="fill: #ffffff"
                      d="M641.7,412.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C641.8,412.5,641.9,412.6,641.7,412.7z"
                    />
                  </g>
                  <g id="XMLID_10443_">
                    <path
                      id="XMLID_10444_"
                      style="fill: #ffffff"
                      d="M645.9,410.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C646.1,410,646.1,410.2,645.9,410.3z"
                    />
                  </g>
                  <g id="XMLID_10441_">
                    <path
                      id="XMLID_10442_"
                      style="fill: #ffffff"
                      d="M650.2,407.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C650.3,407.6,650.3,407.8,650.2,407.9z"
                    />
                  </g>
                  <g id="XMLID_10439_">
                    <path
                      id="XMLID_10440_"
                      style="fill: #ffffff"
                      d="M633,422.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
                l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C633.2,422.1,633.2,422.3,633,422.4z"
                    />
                  </g>
                  <g id="XMLID_10437_">
                    <path
                      id="XMLID_10438_"
                      style="fill: #ffffff"
                      d="M637.2,420l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
                l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C637.4,419.7,637.4,419.9,637.2,420z"
                    />
                  </g>
                  <g id="XMLID_10435_">
                    <path
                      id="XMLID_10436_"
                      style="fill: #ffffff"
                      d="M641.5,417.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C641.6,417.2,641.6,417.4,641.5,417.5z"
                    />
                  </g>
                  <g id="XMLID_10433_">
                    <path
                      id="XMLID_10434_"
                      style="fill: #ffffff"
                      d="M645.7,415.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C645.9,414.8,645.9,415,645.7,415.1z"
                    />
                  </g>
                  <g id="XMLID_10431_">
                    <path
                      id="XMLID_10432_"
                      style="fill: #ffffff"
                      d="M649.9,412.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C650.1,412.3,650.1,412.5,649.9,412.6z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_10343_">
                <g id="XMLID_10345_">
                  <g id="XMLID_10376_">
                    <path
                      id="XMLID_10378_"
                      style="fill: #ffffff"
                      d="M604.1,415.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C604.2,415,604.2,415.2,604.1,415.3z"
                    />
                  </g>
                  <g id="XMLID_10374_">
                    <path
                      id="XMLID_10375_"
                      style="fill: #ffffff"
                      d="M599.8,417.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C600,417.5,600,417.6,599.8,417.7z"
                    />
                  </g>
                  <g id="XMLID_10371_">
                    <path
                      id="XMLID_10372_"
                      style="fill: #ffffff"
                      d="M608.1,417.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C608.2,417.3,608.3,417.5,608.1,417.6z"
                    />
                  </g>
                  <g id="XMLID_10368_">
                    <path
                      id="XMLID_10369_"
                      style="fill: #ffffff"
                      d="M603.9,420.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C604,419.8,604,420,603.9,420.1z"
                    />
                  </g>
                  <g id="XMLID_10365_">
                    <path
                      id="XMLID_10367_"
                      style="fill: #ffffff"
                      d="M612.3,420.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C612.5,419.8,612.5,420,612.3,420.1z"
                    />
                  </g>
                  <g id="XMLID_10362_">
                    <path
                      id="XMLID_10364_"
                      style="fill: #ffffff"
                      d="M608.1,422.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C608.3,422.2,608.3,422.4,608.1,422.5z"
                    />
                  </g>
                  <g id="XMLID_10360_">
                    <path
                      id="XMLID_10361_"
                      style="fill: #ffffff"
                      d="M599.8,412.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C600,412.5,600,412.7,599.8,412.8z"
                    />
                  </g>
                  <g id="XMLID_10357_">
                    <path
                      id="XMLID_10358_"
                      style="fill: #ffffff"
                      d="M598,402l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
                l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C598.1,401.7,598.2,401.9,598,402z"
                    />
                  </g>
                  <g id="XMLID_10354_">
                    <path
                      id="XMLID_10356_"
                      style="fill: #ffffff"
                      d="M603.2,386.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C603.4,385.9,603.4,386,603.2,386.1z"
                    />
                  </g>
                  <g id="XMLID_10352_">
                    <path
                      id="XMLID_10353_"
                      style="fill: #ffffff"
                      d="M599,388.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
                l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C599.1,388.3,599.2,388.5,599,388.6z"
                    />
                  </g>
                  <g id="XMLID_10349_">
                    <path
                      id="XMLID_10350_"
                      style="fill: #ffffff"
                      d="M599,383.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
                l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C599.1,383.3,599.1,383.5,599,383.6z"
                    />
                  </g>
                  <g id="XMLID_10346_">
                    <path
                      id="XMLID_10347_"
                      style="fill: #ffffff"
                      d="M616.4,422.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C616.5,422.1,616.5,422.3,616.4,422.4z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_10321_">
                <g id="XMLID_10323_">
                  <g id="XMLID_10340_">
                    <path
                      id="XMLID_10342_"
                      style="fill: #ffffff"
                      d="M655.4,410.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C655.5,410.6,655.5,410.8,655.4,410.9z"
                    />
                  </g>
                  <g id="XMLID_10338_">
                    <path
                      id="XMLID_10339_"
                      style="fill: #ffffff"
                      d="M659.6,408.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C659.7,408.2,659.8,408.4,659.6,408.5z"
                    />
                  </g>
                  <g id="XMLID_10335_">
                    <path
                      id="XMLID_10337_"
                      style="fill: #ffffff"
                      d="M663.8,406l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
                l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C664,405.8,664,405.9,663.8,406z"
                    />
                  </g>
                  <g id="XMLID_10333_">
                    <path
                      id="XMLID_10334_"
                      style="fill: #ffffff"
                      d="M668.1,403.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C668.2,403.3,668.2,403.5,668.1,403.6z"
                    />
                  </g>
                  <g id="XMLID_10330_">
                    <path
                      id="XMLID_10331_"
                      style="fill: #ffffff"
                      d="M659.4,413.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C659.5,413,659.6,413.1,659.4,413.2z"
                    />
                  </g>
                  <g id="XMLID_10328_">
                    <path
                      id="XMLID_10329_"
                      style="fill: #ffffff"
                      d="M663.6,410.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C663.8,410.5,663.8,410.7,663.6,410.8z"
                    />
                  </g>
                  <g id="XMLID_10325_">
                    <path
                      id="XMLID_10327_"
                      style="fill: #ffffff"
                      d="M667.8,408.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9
                c-0.2-0.1-0.2-0.3,0-0.4l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C668,408.1,668,408.3,667.8,408.4z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_9513_">
                <g id="XMLID_10036_">
                  <g id="XMLID_10126_">
                    <g id="XMLID_10277_">
                      <g id="XMLID_10278_">
                        <g id="XMLID_10284_">
                          <g id="XMLID_10307_">
                            <g id="XMLID_10317_">
                              <path
                                id="XMLID_10319_"
                                style="fill: #ffffff"
                                d="M626.1,415.1L595,397.2c-0.2-0.1-0.3-0.3-0.3-0.4l0,0l0,0c0,0,0,0,0,0
                          l0-3.6c0-0.1,0.1-0.2,0.2-0.2l5.9,0c0,0,0.1,0,0.1,0l23.5-13.7c0.5-0.3,1.1-0.3,1.5-0.1l25.3,14.5c0,0,0.1,0,0.1,0
                          l5.9,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-29.3,17C627.1,415.3,626.5,415.3,626.1,415.1z"
                              />
                            </g>
                            <g id="XMLID_10309_">
                              <path
                                id="XMLID_10311_"
                                d="M626.7,415.7c-0.3,0-0.6-0.1-0.9-0.2l0,0l-31.1-17.9c-0.3-0.2-0.5-0.5-0.5-0.8l0-3.6
                          c0-0.3,0.3-0.6,0.6-0.6l5.9,0l23.4-13.6c0.6-0.3,1.4-0.4,1.9-0.1l25.2,14.5l5.8,0c0.1,0,0.3,0.1,0.4,0.2
                          c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8l-29.3,17C627.5,415.6,627.1,415.7,626.7,415.7z M626.3,414.8
                          c0.2,0.1,0.8,0.1,1.1-0.1l29.3-17c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3l-5.6,0c-0.1,0-0.2,0-0.3-0.1l-25.3-14.5
                          c-0.2-0.1-0.8-0.1-1.1,0.1l-23.5,13.7c-0.1,0-0.2,0.1-0.3,0.1l-5.7,0l0,3.4c0,0,0,0,0.1,0.1L626.3,414.8L626.3,414.8z"
                              />
                            </g>
                          </g>
                          <g id="XMLID_10295_">
                            <g id="XMLID_10303_">
                              <path
                                id="XMLID_10305_"
                                d="M619.1,394.6c0.4-0.1,13.7-6.6,18.9-8.4c0,0,0,0,0,0l13.1,7.6c0,0,0.1,0,0.1,0l5.8,0
                          c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-29.2,17c0,0-0.1,0-0.1,0l0-3.7c0,0,0,0,0,0L619.1,394.6
                          C619.1,394.6,619.1,394.6,619.1,394.6z"
                              />
                            </g>
                            <g id="XMLID_10296_">
                              <path
                                id="XMLID_10297_"
                                d="M627.7,415.5l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-3.6l-8.6-16.6l0-0.4l0.3-0.1l0,0
                          c0.1,0,0.9-0.4,1.9-0.9c3.9-1.8,12.9-6.1,17-7.5l0.2-0.1l0.2,0.1l13,7.5l5.8,0c0.2,0,0.3,0.1,0.4,0.2
                          c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8L627.7,415.5z M619.6,394.8l8.5,16.3l0,0.1l0,3l28.7-16.7
                          c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3l-5.7,0l-0.3-0.1l-12.9-7.5c-4.1,1.5-13,5.6-16.8,7.5
                          C620.4,394.5,619.9,394.7,619.6,394.8z"
                              />
                            </g>
                          </g>
                          <g id="XMLID_10285_">
                            <g id="XMLID_10292_">
                              <path
                                id="XMLID_10293_"
                                style="fill: #ffffff"
                                d="M657,393.3l-31.1-17.9c-0.4-0.2-1.1-0.2-1.5,0.1l-29.3,17
                          c-0.5,0.3-0.5,0.7-0.1,0.9l31.1,17.9c0.4,0.2,1.1,0.2,1.5-0.1l29.3-17C657.4,393.9,657.4,393.5,657,393.3z"
                              />
                            </g>
                            <g id="XMLID_10286_">
                              <path
                                id="XMLID_10288_"
                                d="M626.8,411.8c-0.3,0-0.6-0.1-0.9-0.2l-31.1-17.9c-0.3-0.2-0.5-0.4-0.5-0.7
                          c0-0.3,0.2-0.6,0.6-0.9l29.3-17c0.6-0.3,1.4-0.4,1.9-0.1l31.1,17.9l0,0c0.3,0.2,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.9
                          l-29.3,17C627.5,411.8,627.1,411.8,626.8,411.8z M595.2,393C595.2,393,595.2,393,595.2,393l31.1,17.9
                          c0.2,0.1,0.8,0.1,1.1-0.1l29.3-17c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0l0,0l-31.1-17.9c-0.2-0.1-0.8-0.1-1.1,0.1
                          l-29.3,17C595.2,392.9,595.2,393,595.2,393z"
                              />
                            </g>
                          </g>
                        </g>
                        <g id="XMLID_10279_">
                          <g id="XMLID_10281_">
                            <rect
                              id="XMLID_10282_"
                              x="595.3"
                              y="396.2"
                              transform="matrix(3.009958e-02 -0.9995 0.9995 3.009958e-02 182.7852 981.6135)"
                              width="3.7"
                              height="0.8"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_10237_">
                      <g id="XMLID_10238_">
                        <g id="XMLID_10247_">
                          <g id="XMLID_10267_">
                            <g id="XMLID_10274_">
                              <path
                                id="XMLID_10275_"
                                style="fill: #ffffff"
                                d="M609.7,414.6l-11.7-6.8c-0.2-0.1-0.3-0.3-0.3-0.4l0,0l0,0c0,0,0,0,0,0
                          l0-3.6c0-0.1,0.1-0.2,0.2-0.2l5.9,0c0,0,0.1,0,0.1,0l-2,1.1c0.5-0.3,1.1-0.3,1.5-0.1l5.9,3.5c0,0,0.1,0,0.1,0l5.9,0
                          c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-3.8,2.3C610.8,414.8,610.1,414.9,609.7,414.6z"
                              />
                            </g>
                            <g id="XMLID_10268_">
                              <path
                                id="XMLID_10270_"
                                d="M610.3,415.2c-0.3,0-0.6-0.1-0.9-0.2l0,0l-11.7-6.8c-0.3-0.2-0.5-0.5-0.5-0.8l0-3.6
                          c0-0.3,0.3-0.6,0.6-0.6l5.9,0c0,0-0.1,0-0.1,0l0.4,0.7c0,0,0,0,0,0l-0.5,0.3c0,0,0,0,0.1,0l5.8,3.4l5.8,0
                          c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8l-3.8,2.3
                          C611.1,415.1,610.7,415.2,610.3,415.2z M609.9,414.3c0.2,0.1,0.8,0.1,1.1-0.1l3.8-2.3c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3
                          l-5.6,0c-0.1,0-0.2,0-0.3-0.1l-5.9-3.5c-0.2-0.1-0.7-0.1-1.1,0.1l0,0l-0.4-0.7c0,0,0,0,0,0l0.5-0.3l-4.1,0l0,3.4
                          c0,0,0,0,0.1,0.1L609.9,414.3z"
                              />
                            </g>
                          </g>
                          <g id="XMLID_10257_">
                            <g id="XMLID_10264_">
                              <path
                                id="XMLID_10266_"
                                d="M610.1,408c0,0,5.2,0,5.2,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-3.8,2.2
                          c0,0-0.1,0-0.1,0l0-3.7c0,0,0,0,0,0l0,0C610.8,409.5,610.1,408,610.1,408z"
                              />
                            </g>
                            <g id="XMLID_10259_">
                              <path
                                id="XMLID_10260_"
                                d="M611.3,415l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-3.7c-0.4-1.1-1.1-2.5-1.1-2.6l-0.2-0.4
                          l0.5-0.2c0.1,0,0.1,0,5.3,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8L611.3,415z
                           M611.7,410.8l0,3l3.2-1.9c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3c-1.1,0-3.2,0-4.3,0c0.3,0.5,0.6,1.4,0.9,2.1L611.7,410.8
                          L611.7,410.8z"
                              />
                            </g>
                          </g>
                          <g id="XMLID_10248_">
                            <g id="XMLID_10255_">
                              <path
                                id="XMLID_10256_"
                                style="fill: #ffffff"
                                d="M615.2,407.6l-11.7-6.8c-0.4-0.2-1.1-0.2-1.5,0.1l-3.8,2.3
                          c-0.5,0.3-0.5,0.7-0.1,0.9l11.7,6.8c0.4,0.2,1.1,0.2,1.5-0.1l3.8-2.3C615.5,408.2,615.6,407.8,615.2,407.6z"
                              />
                            </g>
                            <g id="XMLID_10249_">
                              <path
                                id="XMLID_10250_"
                                d="M610.4,411.4c-0.3,0-0.6-0.1-0.9-0.2l-11.7-6.8c-0.3-0.2-0.5-0.4-0.5-0.7
                          c0-0.3,0.2-0.6,0.6-0.9l3.8-2.3c0.6-0.3,1.4-0.4,1.9-0.1l11.7,6.8c0.3,0.2,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.9
                          l-3.8,2.3C611.1,411.3,610.7,411.4,610.4,411.4z M598.1,403.6C598.1,403.6,598.2,403.6,598.1,403.6l11.8,6.8
                          c0.2,0.1,0.8,0.1,1.1-0.1l3.8-2.3c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0l0,0l-11.7-6.8c-0.2-0.1-0.8-0.1-1.1,0.1
                          l-3.8,2.3C598.2,403.5,598.1,403.6,598.1,403.6z"
                              />
                            </g>
                          </g>
                        </g>
                        <g id="XMLID_10243_">
                          <g id="XMLID_10244_">
                            <rect
                              id="XMLID_10245_"
                              x="598.3"
                              y="406.8"
                              transform="matrix(3.009234e-02 -0.9995 0.9995 3.009234e-02 175.1001 994.8273)"
                              width="3.7"
                              height="0.8"
                            />
                          </g>
                        </g>
                        <g id="XMLID_10239_">
                          <g id="XMLID_10240_">
                            <rect
                              id="XMLID_10241_"
                              x="610.5"
                              y="411.8"
                              transform="matrix(1.471350e-02 -0.9999 0.9999 1.471350e-02 191.0216 1018.1206)"
                              style="fill: #ffffff"
                              width="3.3"
                              height="0.6"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_10127_">
                      <g id="XMLID_10129_">
                        <g id="XMLID_10173_">
                          <g id="XMLID_10223_">
                            <g id="XMLID_10233_">
                              <path
                                id="XMLID_10235_"
                                style="fill: #ffffff"
                                d="M618.3,392l-18.3-10.6c-0.2-0.1-0.3-0.3-0.3-0.4l0,0l0,0c0,0,0,0,0,0
                          l0-3.6c0-0.1,0.1-0.2,0.2-0.2l12.3-3.7c0,0,0.1,0,0.1,0l5.8-3.3c0.5-0.3,1.1-0.3,1.5-0.1l12.4,7.3c0,0,0.1,0,0.1,0
                          l5.9,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-18,10.3C619.4,392.1,618.7,392.2,618.3,392z"
                              />
                            </g>
                            <g id="XMLID_10225_">
                              <path
                                id="XMLID_10227_"
                                d="M619,392.5c-0.3,0-0.6-0.1-0.9-0.2l0,0l-18.3-10.6c-0.3-0.2-0.5-0.5-0.5-0.8l0-3.6
                          c0-0.3,0.2-0.5,0.5-0.6l12.3-3.7l5.7-3.2c0.6-0.3,1.4-0.4,1.9-0.1l12.4,7.2l5.8,0c0.1,0,0.3,0.1,0.4,0.2
                          s0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8l-18,10.3C619.8,392.4,619.4,392.5,619,392.5z M618.6,391.6
                          c0.2,0.1,0.8,0.1,1.1-0.1l18-10.3c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3l-5.6,0c-0.1,0-0.2,0-0.3-0.1l-12.4-7.3
                          c-0.2-0.1-0.8-0.1-1.1,0.1l-5.8,3.3c-0.1,0-0.1,0.1-0.2,0.1l-12.1,3.7l0,3.4c0,0,0,0,0.1,0.1L618.6,391.6z"
                              />
                            </g>
                          </g>
                          <g id="XMLID_10201_">
                            <g id="XMLID_10219_">
                              <path
                                id="XMLID_10221_"
                                d="M632.9,377.3c0,0,5.2,0,5.2,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-17.9,10.3
                          c0,0-0.1,0-0.1,0l0-3.7c0,0,0,0,0,0l0,0C619.5,386.9,632.9,377.3,632.9,377.3z"
                              />
                            </g>
                            <g id="XMLID_10207_">
                              <path
                                id="XMLID_10213_"
                                d="M619.9,392.3l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-3.7c-0.1-0.4,0.1-1.3,6.5-6.2
                          c3.3-2.5,6.6-4.9,6.7-4.9l0.1-0.1c0.1,0,0.1,0,5.3,0c0,0,0,0,0,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6
                          c0,0.3-0.2,0.6-0.6,0.8L619.9,392.3z M620.3,388.2l0,3l17.4-10c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3c-1.4,0-4.2,0-4.8,0
                          c-6,4.3-12.3,9.2-12.7,10.3L620.3,388.2L620.3,388.2z"
                              />
                            </g>
                          </g>
                          <g id="XMLID_10176_">
                            <g id="XMLID_10193_">
                              <path
                                id="XMLID_10197_"
                                style="fill: #ffffff"
                                d="M638,376.8l-18.3-10.6c-0.4-0.2-1.1-0.2-1.5,0.1l-18,10.3
                          c-0.5,0.3-0.5,0.7-0.1,0.9l18.3,10.6c0.4,0.2,1.1,0.2,1.5-0.1l18-10.3C638.4,377.5,638.4,377.1,638,376.8z"
                              />
                            </g>
                            <g id="XMLID_10180_">
                              <path
                                id="XMLID_10184_"
                                d="M619,388.7c-0.3,0-0.6-0.1-0.9-0.2l-18.3-10.6c-0.3-0.2-0.5-0.4-0.5-0.7
                          c0-0.3,0.2-0.6,0.6-0.9l18-10.3c0.6-0.3,1.4-0.4,1.9-0.1l18.3,10.6c0.3,0.2,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.9
                          l-18,10.3C619.8,388.6,619.4,388.7,619,388.7z M600.3,377.1C600.3,377.1,600.3,377.1,600.3,377.1l18.3,10.7
                          c0.2,0.1,0.8,0.1,1.1-0.1l18-10.3c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0l0,0l-18.3-10.6c-0.2-0.1-0.8-0.1-1.1,0.1
                          l-18,10.3C600.3,377,600.3,377.1,600.3,377.1z"
                              />
                            </g>
                          </g>
                        </g>
                        <g id="XMLID_10139_">
                          <g id="XMLID_10155_">
                            <rect
                              id="XMLID_10170_"
                              x="600.4"
                              y="380.3"
                              transform="matrix(3.009234e-02 -0.9995 0.9995 3.009234e-02 203.6443 971.287)"
                              width="3.7"
                              height="0.8"
                            />
                          </g>
                        </g>
                        <g id="XMLID_10130_">
                          <g id="XMLID_10131_">
                            <rect
                              id="XMLID_10138_"
                              x="633.3"
                              y="381.1"
                              transform="matrix(1.471350e-02 -0.9999 0.9999 1.471350e-02 244.2774 1010.6605)"
                              style="fill: #ffffff"
                              width="3.3"
                              height="0.6"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_10083_">
                    <g id="XMLID_10084_">
                      <g id="XMLID_10096_">
                        <g id="XMLID_10116_">
                          <g id="XMLID_10123_">
                            <path
                              id="XMLID_10124_"
                              style="fill: #ffffff"
                              d="M653,406.2l-11.7-6.8c-0.2-0.1-0.3-0.3-0.3-0.4l0,0v0c0,0,0,0,0,0
                        l0-3.6c0-0.1,0.1-0.2,0.2-0.2l5.9,0c0,0,0.1,0,0.1,0l5.8-3.3c0.5-0.3,1.1-0.3,1.5-0.1l5.9,3.5c0,0,0.1,0,0.1,0l5.9,0
                        c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.6,6.7C654,406.4,653.4,406.4,653,406.2z"
                            />
                          </g>
                          <g id="XMLID_10117_">
                            <path
                              id="XMLID_10119_"
                              d="M653.6,406.7c-0.3,0-0.6-0.1-0.9-0.2l0,0l-11.7-6.8c-0.3-0.2-0.5-0.5-0.5-0.8l0-3.6
                        c0-0.3,0.3-0.6,0.6-0.6l5.9,0l5.7-3.2c0.6-0.3,1.4-0.4,1.9-0.1l5.8,3.4l5.8,0c0.2,0,0.3,0.1,0.4,0.2
                        c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8l-11.6,6.7C654.4,406.6,654,406.7,653.6,406.7z M653.2,405.8
                        c0.2,0.1,0.8,0.1,1.1-0.1l11.6-6.7c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3l-5.6,0c-0.1,0-0.2,0-0.3-0.1l-5.9-3.5
                        c-0.2-0.1-0.8-0.1-1.1,0.1l-5.8,3.3c-0.1,0-0.2,0.1-0.3,0.1l-5.7,0l0,3.4c0,0,0,0,0.1,0.1L653.2,405.8z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_10106_">
                          <g id="XMLID_10113_">
                            <path
                              id="XMLID_10115_"
                              d="M661.1,395.2c0,0,5.2,0,5.2,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.5,6.6
                        c0,0-0.1,0-0.1,0l0-3.7c0,0,0,0,0,0l0,0C654.1,401.1,661.1,395.2,661.1,395.2z"
                            />
                          </g>
                          <g id="XMLID_10108_">
                            <path
                              id="XMLID_10109_"
                              d="M654.6,406.5l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-3.7c-0.2-1.4,4.1-5.2,6.7-7.4l0.1-0.1
                        c0.1,0,0.1,0,5.3,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8L654.6,406.5z
                         M654.9,402.4l0,3l11-6.3c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3c-1.4,0-4.1,0-4.8,0c-3.1,2.6-6.4,5.8-6.3,6.5L654.9,402.4
                        L654.9,402.4z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_10097_">
                          <g id="XMLID_10104_">
                            <path
                              id="XMLID_10105_"
                              style="fill: #ffffff"
                              d="M666.2,394.7l-11.7-6.8c-0.4-0.2-1.1-0.2-1.5,0.1l-11.6,6.7
                        c-0.5,0.3-0.5,0.7-0.1,0.9l11.7,6.8c0.4,0.2,1.1,0.2,1.5-0.1l11.6-6.7C666.6,395.3,666.6,394.9,666.2,394.7z"
                            />
                          </g>
                          <g id="XMLID_10099_">
                            <path
                              id="XMLID_10100_"
                              d="M653.7,402.9c-0.3,0-0.6-0.1-0.9-0.2l-11.7-6.8c-0.3-0.2-0.5-0.4-0.5-0.7
                        c0-0.3,0.2-0.6,0.6-0.9l11.6-6.7c0.6-0.3,1.4-0.4,1.9-0.1l11.7,6.8c0.3,0.2,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.9
                        l-11.6,6.7C654.4,402.8,654,402.9,653.7,402.9z M641.4,395.1C641.4,395.1,641.4,395.1,641.4,395.1l11.8,6.8
                        c0.2,0.1,0.8,0.1,1.1-0.1l11.6-6.7c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0l0,0l-11.7-6.8c-0.2-0.1-0.8-0.1-1.1,0.1
                        l-11.6,6.7C641.5,395,641.4,395.1,641.4,395.1z"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_10090_">
                        <g id="XMLID_10092_">
                          <rect
                            id="XMLID_10094_"
                            x="641.6"
                            y="398.3"
                            transform="matrix(3.009958e-02 -0.9995 0.9995 3.009958e-02 225.544 1029.8834)"
                            width="3.7"
                            height="0.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_10085_">
                        <g id="XMLID_10087_">
                          <rect
                            id="XMLID_10088_"
                            x="661.6"
                            y="399"
                            transform="matrix(1.471350e-02 -0.9999 0.9999 1.471350e-02 254.2007 1056.5111)"
                            style="fill: #ffffff"
                            width="3.3"
                            height="0.6"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_10037_">
                    <g id="XMLID_10050_">
                      <g id="XMLID_10073_">
                        <g id="XMLID_10080_">
                          <path
                            id="XMLID_10081_"
                            style="fill: #ffffff"
                            d="M660,384.8l-3.1-1.9c-0.2-0.1-0.4-0.1-0.5,0l0,0l0,0c0,0,0,0,0,0
                      l-3.1,1.8c-0.1,0-0.1,0.1-0.1,0.2l2.9,5.1c0,0,0,0.1,0,0.1v0.6c0,0.5,0.3,1.1,0.7,1.4l-2.8-1.5c0,0,0,0,0.1,0.1l2.9,5.1
                      c0,0.1,0.1,0.1,0.2,0.1l3.1-1.8c0.1-0.1,0.2-0.3,0.2-0.6v-7.3C660.7,385.7,660.4,385.1,660,384.8z"
                          />
                        </g>
                        <g id="XMLID_10075_">
                          <path
                            id="XMLID_10076_"
                            d="M657.3,396.3c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.3l-2.9-5.1c0,0,0.1,0.1,0.1,0.1l0.4-0.7
                      l1.5,0.8c0-0.1,0-0.2,0-0.3v-0.5l-2.9-5.1c-0.2-0.3-0.1-0.6,0.2-0.8l3.1-1.8c0.3-0.2,0.6-0.2,0.9,0l3.1,1.9
                      c0.5,0.3,0.9,1,0.9,1.7v7.3c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.9C657.5,396.3,657.4,396.3,657.3,396.3z M655.3,391.7l2.1,3.7
                      l2.9-1.7c0,0,0-0.1,0-0.2v-7.3c0-0.4-0.3-0.9-0.5-1l0,0l-3.1-1.9c0,0-0.1,0-0.1,0l-2.9,1.7l2.8,4.9c0,0.1,0.1,0.2,0.1,0.3
                      v0.6c0,0.4,0.3,0.9,0.5,1l-0.4,0.7L655.3,391.7z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_10062_">
                        <g id="XMLID_10071_">
                          <path
                            id="XMLID_10072_"
                            d="M654,389.6c0,0,1.1-6.7,2.3-1l-2.1,2.1c0,0,0,0.1,0.1,0.1l2.9,5.1c0.1,0.1,0.2,0.1,0.3,0.1
                      l3.1-1.8c0.1-0.1,0.2-0.3,0.2-0.6v-7.2c0,0,0-0.1-0.1,0l-3.2,1.9c0,0,0,0,0,0L654,389.6z"
                          />
                        </g>
                        <g id="XMLID_10064_">
                          <path
                            id="XMLID_10065_"
                            d="M657.3,396.3c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.3l-2.9-5.1l-0.3-0.3l0.8-0.7l-0.8,0.3
                      l0.1-0.7c0.4-2.4,1-3.6,1.7-3.6c0,0,0,0,0,0c0.6,0,0.9,0.6,1.3,2.2l0.6-0.3l3.4-2l0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.4v7.2
                      c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.8C657.5,396.3,657.4,396.3,657.3,396.3z M654.7,390.7l2.7,4.7l2.9-1.7c0,0,0-0.1,0-0.2
                      v-6.6l-2.7,1.6l-1.2,0.5L654.7,390.7z M655.3,386.8c-0.2,0.3-0.5,1.1-0.7,2.1l1.2-0.5
                      C655.6,387.3,655.4,386.9,655.3,386.8z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_10052_">
                        <g id="XMLID_10059_">
                          <path
                            id="XMLID_10061_"
                            style="fill: #ffffff"
                            d="M656.7,396l-3.1-1.9c-0.4-0.2-0.7-0.8-0.7-1.4v-7.3
                      c0-0.5,0.3-0.8,0.7-0.5l3.1,1.9c0.4,0.2,0.7,0.8,0.7,1.4v7.3C657.4,396,657.1,396.2,656.7,396z"
                          />
                        </g>
                        <g id="XMLID_10053_">
                          <path
                            id="XMLID_10054_"
                            d="M657,396.5c-0.2,0-0.3,0-0.5-0.1l0,0l-3.1-1.9c-0.5-0.3-0.9-1-0.9-1.7v-7.3
                      c0-0.4,0.2-0.8,0.5-0.9c0.3-0.1,0.6-0.1,0.9,0l3.1,1.9c0.5,0.3,0.9,1,0.9,1.7v7.3c0,0.4-0.2,0.8-0.5,0.9
                      C657.2,396.4,657.1,396.5,657,396.5z M656.9,395.6C656.9,395.6,656.9,395.7,656.9,395.6c0.1,0,0.1,0,0.1-0.2v-7.3
                      c0-0.4-0.3-0.9-0.5-1l-3.1-1.9c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.2v7.3c0,0.4,0.3,0.9,0.5,1L656.9,395.6z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_10042_">
                      <g id="XMLID_10043_">
                        <rect
                          id="XMLID_10049_"
                          x="655.6"
                          y="384.8"
                          transform="matrix(0.8487 -0.5289 0.5289 0.8487 -104.2446 406.0053)"
                          width="3.7"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_10039_">
                      <g id="XMLID_10040_">
                        <rect
                          id="XMLID_10041_"
                          x="657.4"
                          y="390.7"
                          transform="matrix(0.8566 -0.5159 0.5159 0.8566 -107.2649 396.0692)"
                          style="fill: #ffffff"
                          width="3.3"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_9515_">
                  <g id="XMLID_9564_">
                    <g id="XMLID_10024_">
                      <g id="XMLID_10033_">
                        <path
                          id="XMLID_10035_"
                          style="fill: #ffffff"
                          d="M622.2,374.5l-6.4-0.1l0.8,3.8c0,0,0.3,0.6,0.7,0.8c1,0.6,2.5,0.6,3.5,0
                    c0.4-0.2,0.7-0.8,0.7-0.8L622.2,374.5z"
                        />
                      </g>
                      <g id="XMLID_10026_">
                        <path
                          id="XMLID_10028_"
                          d="M619.1,379.8c-0.7,0-1.4-0.2-1.9-0.5c-0.5-0.3-0.8-0.9-0.8-1l0-0.1l-0.9-4.3l7.4,0.1l-0.9,4.3
                    c0,0.1-0.4,0.7-0.8,1C620.5,379.7,619.8,379.8,619.1,379.8z M617,378c0.1,0.2,0.3,0.5,0.5,0.6c0.8,0.5,2.2,0.5,3.1,0
                    c0.2-0.1,0.4-0.4,0.5-0.6l0.6-3.1l-5.4-0.1L617,378z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_9972_">
                      <g id="XMLID_10010_">
                        <path
                          id="XMLID_10020_"
                          style="fill: #ffffff"
                          d="M622.8,373.2l0-2c0-0.1-0.1-0.1-0.1-0.1H620c-0.6-0.1-1.2-0.1-1.8,0h-2.8
                    c-0.1,0-0.1,0.1-0.1,0.1l0,2c0,0.8,0.7,1.6,2.1,2c1.1,0.3,2.3,0.3,3.4,0C622.2,374.8,622.9,374,622.8,373.2z"
                        />
                      </g>
                      <g id="XMLID_9992_">
                        <path
                          id="XMLID_9994_"
                          d="M619.1,375.9c-0.6,0-1.2-0.1-1.8-0.3c-1.5-0.5-2.4-1.4-2.4-2.4l0-2c0-0.3,0.2-0.5,0.5-0.5h2.8
                    c0.6-0.1,1.2-0.1,1.9,0l2.7,0c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4l0,2c0,1-0.9,1.9-2.4,2.4
                    C620.3,375.8,619.7,375.9,619.1,375.9z M615.7,371.6l0,1.7c0,0.7,0.7,1.3,1.8,1.6c1,0.3,2.1,0.3,3.1,0
                    c1.1-0.3,1.8-1,1.8-1.6l0-1.7H620c-0.6-0.1-1.2-0.1-1.8,0L615.7,371.6z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_9834_">
                      <g id="XMLID_9970_">
                        <path
                          id="XMLID_9971_"
                          style="fill: #ffffff"
                          d="M621.7,369.5c1.5,0.9,1.5,2.2,0,3.1c-1.5,0.9-3.9,0.9-5.4,0
                    c-1.5-0.9-1.5-2.2,0-3.1C617.9,368.7,620.3,368.7,621.7,369.5z"
                        />
                      </g>
                      <g id="XMLID_9835_">
                        <path
                          id="XMLID_9951_"
                          d="M619.1,373.7c-1,0-2.1-0.2-2.9-0.7c-0.9-0.5-1.3-1.2-1.3-1.9c0-0.7,0.5-1.4,1.3-1.9
                    c1.6-0.9,4.2-0.9,5.8,0c0.9,0.5,1.3,1.2,1.3,1.9c0,0.7-0.5,1.4-1.3,1.9C621.1,373.5,620.1,373.7,619.1,373.7z M619.1,369.3
                    c-0.9,0-1.8,0.2-2.5,0.6c-0.6,0.3-0.9,0.8-0.9,1.2c0,0.4,0.3,0.9,0.9,1.2c1.3,0.8,3.6,0.8,5,0c0.6-0.3,0.9-0.8,0.9-1.2
                    c0-0.4-0.3-0.9-0.9-1.2C620.9,369.5,620,369.3,619.1,369.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_9565_">
                      <g id="XMLID_9572_">
                        <path
                          id="XMLID_9813_"
                          d="M616.2,371.7c-0.2,0.1-0.4,0.3-0.5,0.4c0.2,0.2,0.4,0.3,0.6,0.5c1.5,0.9,3.9,0.9,5.4,0
                    c0.2-0.1,0.4-0.3,0.5-0.4c-0.2-0.2-0.4-0.3-0.6-0.5C620.1,370.9,617.7,370.9,616.2,371.7z"
                        />
                      </g>
                      <g id="XMLID_9567_">
                        <path
                          id="XMLID_9568_"
                          d="M619,373.7c-1,0-2.1-0.2-2.9-0.7c-0.3-0.2-0.5-0.3-0.7-0.6l-0.3-0.3l0.3-0.3
                    c0.2-0.2,0.4-0.3,0.6-0.5l0,0c1.6-0.9,4.2-0.9,5.8,0c0.3,0.2,0.5,0.3,0.7,0.6l0.3,0.3l-0.3,0.3c-0.2,0.2-0.4,0.3-0.6,0.5
                    C621.1,373.4,620.1,373.7,619,373.7z M616.4,372.1c0.1,0,0.1,0.1,0.2,0.1c1.4,0.8,3.6,0.8,5,0c0,0,0.1,0,0.1-0.1
                    c-0.1,0-0.1-0.1-0.2-0.1C620.1,371.3,617.8,371.3,616.4,372.1L616.4,372.1C616.4,372.1,616.4,372.1,616.4,372.1z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_9554_">
                    <g id="XMLID_9561_">
                      <path
                        id="XMLID_9562_"
                        style="fill: #ffffff"
                        d="M623.6,364.2c0,3.8-2.1,6.9-4.6,6.9c-2.5,0-4.6-3.1-4.6-6.9
                  c0-3.8,2.1-6.9,4.6-6.9C621.5,357.3,623.6,360.4,623.6,364.2z"
                      />
                    </g>
                    <g id="XMLID_9555_">
                      <path
                        id="XMLID_9557_"
                        d="M619,371.5c-2.8,0-5-3.3-5-7.3s2.3-7.3,5-7.3c2.8,0,5,3.3,5,7.3S621.7,371.5,619,371.5z
                   M619,357.7c-2.3,0-4.2,2.9-4.2,6.5s1.9,6.5,4.2,6.5c2.3,0,4.2-2.9,4.2-6.5S621.3,357.7,619,357.7z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_9545_">
                    <g id="XMLID_9551_">
                      <path
                        id="XMLID_9553_"
                        style="fill: #ffffff"
                        d="M617.1,360.1c1,2,0.8,4.3-0.6,5c-1.4,0.7-3.3-0.4-4.4-2.4
                  c-1-2-0.8-4.3,0.6-5C614.1,357,616,358.1,617.1,360.1z"
                      />
                    </g>
                    <g id="XMLID_9547_">
                      <path
                        id="XMLID_9548_"
                        d="M615.6,365.7c-0.5,0-1.1-0.2-1.6-0.5c-0.9-0.5-1.7-1.4-2.2-2.4c-1.1-2.2-0.8-4.7,0.7-5.5
                  c1.6-0.8,3.8,0.4,4.9,2.6l0,0c1.2,2.3,0.8,4.7-0.7,5.5C616.4,365.6,616,365.7,615.6,365.7z M613.6,357.9
                  c-0.2,0-0.5,0.1-0.7,0.2c-1.1,0.6-1.3,2.6-0.4,4.4c0.5,0.9,1.1,1.7,1.9,2.1c0.7,0.4,1.4,0.5,1.9,0.2
                  c1.1-0.6,1.3-2.6,0.4-4.4C616,358.8,614.7,357.9,613.6,357.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_9531_">
                    <g id="XMLID_9541_">
                      <path
                        id="XMLID_9543_"
                        style="fill: #ffffff"
                        d="M624.1,358.7c-0.6,1.6-2,2.6-3.1,2.1s-1.4-2.1-0.8-3.7
                  c0.6-1.6,2-2.6,3.1-2.1S624.7,357.1,624.1,358.7z"
                      />
                    </g>
                    <g id="XMLID_9533_">
                      <path
                        id="XMLID_9535_"
                        d="M621.5,361.3c-0.2,0-0.5,0-0.7-0.1c-0.6-0.3-1.1-0.9-1.3-1.7c-0.2-0.8-0.1-1.7,0.3-2.5
                  c0.7-1.8,2.3-2.9,3.6-2.4c0.6,0.3,1.1,0.9,1.3,1.7c0.2,0.8,0.1,1.7-0.3,2.5c-0.3,0.8-0.9,1.6-1.5,2
                  C622.5,361.1,622,361.3,621.5,361.3z M622.8,355.3c-0.8,0-1.7,0.8-2.2,2c-0.3,0.7-0.4,1.4-0.2,2c0.1,0.6,0.4,0.9,0.8,1.1
                  c0.4,0.2,0.8,0.1,1.3-0.3c0.5-0.4,0.9-0.9,1.2-1.6l0,0c0.3-0.7,0.4-1.4,0.2-2c-0.1-0.6-0.4-0.9-0.8-1.1
                  C623,355.3,622.9,355.3,622.8,355.3z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_9517_">
                    <g id="XMLID_9527_">
                      <path
                        id="XMLID_9529_"
                        style="fill: #ffffff"
                        d="M625,366.2c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2
                  S625,365.2,625,366.2z"
                      />
                    </g>
                    <g id="XMLID_9519_">
                      <path
                        id="XMLID_9521_"
                        d="M623,368.6c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4
                  C625.4,367.6,624.3,368.6,623,368.6z M623,364.7c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6
                  C624.6,365.4,623.9,364.7,623,364.7z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_246_">
            <g id="XMLID_2665_">
              <g id="XMLID_2704_">
                <g id="XMLID_2863_">
                  <path
                    id="XMLID_2864_"
                    style="fill: #ffffff"
                    d="M382.3,408.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C382.5,408,382.5,408.1,382.3,408.2z"
                  />
                </g>
                <g id="XMLID_2861_">
                  <path
                    id="XMLID_2862_"
                    style="fill: #ffffff"
                    d="M378.1,410.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C378.3,410.4,378.3,410.6,378.1,410.7z"
                  />
                </g>
                <g id="XMLID_2859_">
                  <path
                    id="XMLID_2860_"
                    style="fill: #ffffff"
                    d="M386.4,410.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C386.5,410.3,386.5,410.5,386.4,410.6z"
                  />
                </g>
                <g id="XMLID_2857_">
                  <path
                    id="XMLID_2858_"
                    style="fill: #ffffff"
                    d="M378.3,405.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C378.5,405.6,378.5,405.8,378.3,405.9z"
                  />
                </g>
                <g id="XMLID_2855_">
                  <path
                    id="XMLID_2856_"
                    style="fill: #ffffff"
                    d="M382.3,408.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C382.5,407.9,382.5,408.1,382.3,408.2z"
                  />
                </g>
                <g id="XMLID_2853_">
                  <path
                    id="XMLID_2854_"
                    style="fill: #ffffff"
                    d="M382.1,413l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C382.3,412.7,382.3,412.9,382.1,413z"
                  />
                </g>
                <g id="XMLID_2851_">
                  <path
                    id="XMLID_2852_"
                    style="fill: #ffffff"
                    d="M390.6,413l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C390.8,412.7,390.8,412.9,390.6,413z"
                  />
                </g>
                <g id="XMLID_2849_">
                  <path
                    id="XMLID_2850_"
                    style="fill: #ffffff"
                    d="M386.4,415.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C386.5,415.2,386.6,415.4,386.4,415.5z"
                  />
                </g>
                <g id="XMLID_2847_">
                  <path
                    id="XMLID_2848_"
                    style="fill: #ffffff"
                    d="M402.9,420.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C403.1,419.8,403.1,420,402.9,420.1z"
                  />
                </g>
                <g id="XMLID_2845_">
                  <path
                    id="XMLID_2846_"
                    style="fill: #ffffff"
                    d="M398.7,422.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C398.8,422.3,398.8,422.5,398.7,422.6z"
                  />
                </g>
                <g id="XMLID_2843_">
                  <path
                    id="XMLID_2844_"
                    style="fill: #ffffff"
                    d="M406.9,422.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C407.1,422.2,407.1,422.3,406.9,422.4z"
                  />
                </g>
                <g id="XMLID_2841_">
                  <path
                    id="XMLID_2842_"
                    style="fill: #ffffff"
                    d="M402.7,424.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C402.8,424.6,402.9,424.8,402.7,424.9z"
                  />
                </g>
                <g id="XMLID_2839_">
                  <path
                    id="XMLID_2840_"
                    style="fill: #ffffff"
                    d="M410.9,424.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C411.1,424.5,411.1,424.7,410.9,424.8z"
                  />
                </g>
                <g id="XMLID_2837_">
                  <path
                    id="XMLID_2838_"
                    style="fill: #ffffff"
                    d="M406.7,427.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C406.9,426.9,406.9,427.1,406.7,427.2z"
                  />
                </g>
                <g id="XMLID_2835_">
                  <path
                    id="XMLID_2836_"
                    style="fill: #ffffff"
                    d="M415.2,427.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C415.4,426.9,415.4,427.1,415.2,427.2z"
                  />
                </g>
                <g id="XMLID_2833_">
                  <path
                    id="XMLID_2834_"
                    style="fill: #ffffff"
                    d="M411,429.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C411.1,429.4,411.1,429.6,411,429.7z"
                  />
                </g>
                <g id="XMLID_2831_">
                  <path
                    id="XMLID_2832_"
                    style="fill: #ffffff"
                    d="M419.2,429.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C419.4,429.3,419.4,429.4,419.2,429.5z"
                  />
                </g>
                <g id="XMLID_2829_">
                  <path
                    id="XMLID_2830_"
                    style="fill: #ffffff"
                    d="M415,432l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C415.1,431.7,415.2,431.9,415,432z"
                  />
                </g>
                <g id="XMLID_2827_">
                  <path
                    id="XMLID_2828_"
                    style="fill: #ffffff"
                    d="M423.2,431.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C423.4,431.6,423.4,431.7,423.2,431.9z"
                  />
                </g>
                <g id="XMLID_2825_">
                  <path
                    id="XMLID_2826_"
                    style="fill: #ffffff"
                    d="M419,434.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C419.2,434,419.2,434.2,419,434.3z"
                  />
                </g>
                <g id="XMLID_2823_">
                  <path
                    id="XMLID_2824_"
                    style="fill: #ffffff"
                    d="M427.5,434.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C427.6,434,427.7,434.2,427.5,434.3z"
                  />
                </g>
                <g id="XMLID_2821_">
                  <path
                    id="XMLID_2822_"
                    style="fill: #ffffff"
                    d="M423.3,436.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C423.4,436.5,423.4,436.7,423.3,436.8z"
                  />
                </g>
                <g id="XMLID_2819_">
                  <path
                    id="XMLID_2820_"
                    style="fill: #ffffff"
                    d="M431.5,436.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C431.7,436.3,431.7,436.5,431.5,436.6z"
                  />
                </g>
                <g id="XMLID_2817_">
                  <path
                    id="XMLID_2818_"
                    style="fill: #ffffff"
                    d="M427.3,439.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C427.4,438.8,427.5,439,427.3,439.1z"
                  />
                </g>
                <g id="XMLID_2815_">
                  <path
                    id="XMLID_2816_"
                    style="fill: #ffffff"
                    d="M435.5,438.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C435.7,438.7,435.7,438.8,435.5,438.9z"
                  />
                </g>
                <g id="XMLID_2812_">
                  <path
                    id="XMLID_2814_"
                    style="fill: #ffffff"
                    d="M431.3,441.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C431.5,441.1,431.5,441.3,431.3,441.4z"
                  />
                </g>
                <g id="XMLID_2810_">
                  <path
                    id="XMLID_2811_"
                    style="fill: #ffffff"
                    d="M439.8,441.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C439.9,441.1,440,441.3,439.8,441.4z"
                  />
                </g>
                <g id="XMLID_2808_">
                  <path
                    id="XMLID_2809_"
                    style="fill: #ffffff"
                    d="M435.6,443.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C435.7,443.6,435.7,443.7,435.6,443.9z"
                  />
                </g>
                <g id="XMLID_2806_">
                  <path
                    id="XMLID_2807_"
                    style="fill: #ffffff"
                    d="M443.8,443.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C444,443.4,444,443.6,443.8,443.7z"
                  />
                </g>
                <g id="XMLID_2804_">
                  <path
                    id="XMLID_2805_"
                    style="fill: #ffffff"
                    d="M439.6,446.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C439.7,445.9,439.7,446.1,439.6,446.2z"
                  />
                </g>
                <g id="XMLID_2802_">
                  <path
                    id="XMLID_2803_"
                    style="fill: #ffffff"
                    d="M447.8,446l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C448,445.8,448,445.9,447.8,446z"
                  />
                </g>
                <g id="XMLID_2800_">
                  <path
                    id="XMLID_2801_"
                    style="fill: #ffffff"
                    d="M443.6,448.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C443.7,448.2,443.8,448.4,443.6,448.5z"
                  />
                </g>
                <g id="XMLID_2798_">
                  <path
                    id="XMLID_2799_"
                    style="fill: #ffffff"
                    d="M452.1,448.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C452.2,448.2,452.3,448.4,452.1,448.5z"
                  />
                </g>
                <g id="XMLID_2796_">
                  <path
                    id="XMLID_2797_"
                    style="fill: #ffffff"
                    d="M447.8,450.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C448,450.7,448,450.8,447.8,450.9z"
                  />
                </g>
                <g id="XMLID_2794_">
                  <path
                    id="XMLID_2795_"
                    style="fill: #ffffff"
                    d="M456.1,450.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C456.3,450.5,456.3,450.7,456.1,450.8z"
                  />
                </g>
                <g id="XMLID_2792_">
                  <path
                    id="XMLID_2793_"
                    style="fill: #ffffff"
                    d="M451.9,453.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C452,453,452,453.2,451.9,453.3z"
                  />
                </g>
                <g id="XMLID_2790_">
                  <path
                    id="XMLID_2791_"
                    style="fill: #ffffff"
                    d="M460.1,453.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C460.3,452.9,460.3,453,460.1,453.1z"
                  />
                </g>
                <g id="XMLID_2788_">
                  <path
                    id="XMLID_2789_"
                    style="fill: #ffffff"
                    d="M455.9,455.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C456,455.3,456.1,455.5,455.9,455.6z"
                  />
                </g>
                <g id="XMLID_2786_">
                  <path
                    id="XMLID_2787_"
                    style="fill: #ffffff"
                    d="M464.4,455.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C464.5,455.3,464.6,455.5,464.4,455.6z"
                  />
                </g>
                <g id="XMLID_2784_">
                  <path
                    id="XMLID_2785_"
                    style="fill: #ffffff"
                    d="M460.1,458l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C460.3,457.8,460.3,457.9,460.1,458z"
                  />
                </g>
                <g id="XMLID_2782_">
                  <path
                    id="XMLID_2783_"
                    style="fill: #ffffff"
                    d="M468.4,457.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C468.5,457.6,468.6,457.8,468.4,457.9z"
                  />
                </g>
                <g id="XMLID_2780_">
                  <path
                    id="XMLID_2781_"
                    style="fill: #ffffff"
                    d="M464.2,460.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C464.3,460.1,464.3,460.3,464.2,460.4z"
                  />
                </g>
                <g id="XMLID_2778_">
                  <path
                    id="XMLID_2779_"
                    style="fill: #ffffff"
                    d="M472.4,460.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C472.6,460,472.6,460.1,472.4,460.2z"
                  />
                </g>
                <g id="XMLID_2776_">
                  <path
                    id="XMLID_2777_"
                    style="fill: #ffffff"
                    d="M468.2,462.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C468.3,462.4,468.4,462.6,468.2,462.7z"
                  />
                </g>
                <g id="XMLID_2774_">
                  <path
                    id="XMLID_2775_"
                    style="fill: #ffffff"
                    d="M476.7,462.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C476.8,462.4,476.8,462.6,476.7,462.7z"
                  />
                </g>
                <g id="XMLID_2772_">
                  <path
                    id="XMLID_2773_"
                    style="fill: #ffffff"
                    d="M472.4,465.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C472.6,464.9,472.6,465,472.4,465.1z"
                  />
                </g>
                <g id="XMLID_2770_">
                  <path
                    id="XMLID_2771_"
                    style="fill: #ffffff"
                    d="M480.9,460.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C481.1,460,481.1,460.2,480.9,460.3z"
                  />
                </g>
                <g id="XMLID_2768_">
                  <path
                    id="XMLID_2769_"
                    style="fill: #ffffff"
                    d="M485.1,457.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C485.3,457.5,485.3,457.7,485.1,457.8z"
                  />
                </g>
                <g id="XMLID_2766_">
                  <path
                    id="XMLID_2767_"
                    style="fill: #ffffff"
                    d="M489.4,455.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C489.5,455.1,489.6,455.3,489.4,455.4z"
                  />
                </g>
                <g id="XMLID_2764_">
                  <path
                    id="XMLID_2765_"
                    style="fill: #ffffff"
                    d="M493.6,452.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C493.8,452.6,493.8,452.8,493.6,452.9z"
                  />
                </g>
                <g id="XMLID_2762_">
                  <path
                    id="XMLID_2763_"
                    style="fill: #ffffff"
                    d="M497.8,450.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C498,450.2,498,450.4,497.8,450.5z"
                  />
                </g>
                <g id="XMLID_2760_">
                  <path
                    id="XMLID_2761_"
                    style="fill: #ffffff"
                    d="M502.1,448l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C502.2,447.7,502.3,447.9,502.1,448z"
                  />
                </g>
                <g id="XMLID_2758_">
                  <path
                    id="XMLID_2759_"
                    style="fill: #ffffff"
                    d="M506.3,445.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C506.5,445.3,506.5,445.5,506.3,445.6z"
                  />
                </g>
                <g id="XMLID_2756_">
                  <path
                    id="XMLID_2757_"
                    style="fill: #ffffff"
                    d="M510.5,443.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C510.7,442.9,510.7,443,510.5,443.1z"
                  />
                </g>
                <g id="XMLID_2754_">
                  <path
                    id="XMLID_2755_"
                    style="fill: #ffffff"
                    d="M514.8,440.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C514.9,440.4,515,440.6,514.8,440.7z"
                  />
                </g>
                <g id="XMLID_2752_">
                  <path
                    id="XMLID_2753_"
                    style="fill: #ffffff"
                    d="M519,438.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C519.2,438,519.2,438.1,519,438.2z"
                  />
                </g>
                <g id="XMLID_2750_">
                  <path
                    id="XMLID_2751_"
                    style="fill: #ffffff"
                    d="M523.3,435.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C523.4,435.5,523.4,435.7,523.3,435.8z"
                  />
                </g>
                <g id="XMLID_2748_">
                  <path
                    id="XMLID_2749_"
                    style="fill: #ffffff"
                    d="M527.5,433.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C527.6,433.1,527.7,433.3,527.5,433.4z"
                  />
                </g>
                <g id="XMLID_2746_">
                  <path
                    id="XMLID_2747_"
                    style="fill: #ffffff"
                    d="M531.7,430.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C531.9,430.6,531.9,430.8,531.7,430.9z"
                  />
                </g>
                <g id="XMLID_2744_">
                  <path
                    id="XMLID_2745_"
                    style="fill: #ffffff"
                    d="M536,428.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C536.1,428.2,536.1,428.4,536,428.5z"
                  />
                </g>
                <g id="XMLID_2742_">
                  <path
                    id="XMLID_2743_"
                    style="fill: #ffffff"
                    d="M540.2,426l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C540.3,425.7,540.4,425.9,540.2,426z"
                  />
                </g>
                <g id="XMLID_2740_">
                  <path
                    id="XMLID_2741_"
                    style="fill: #ffffff"
                    d="M480.7,465l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C480.8,464.7,480.9,464.9,480.7,465z"
                  />
                </g>
                <g id="XMLID_2738_">
                  <path
                    id="XMLID_2739_"
                    style="fill: #ffffff"
                    d="M484.9,462.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C485.1,462.3,485.1,462.5,484.9,462.6z"
                  />
                </g>
                <g id="XMLID_2736_">
                  <path
                    id="XMLID_2737_"
                    style="fill: #ffffff"
                    d="M489.2,460.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C489.3,459.8,489.3,460,489.2,460.1z"
                  />
                </g>
                <g id="XMLID_2734_">
                  <path
                    id="XMLID_2735_"
                    style="fill: #ffffff"
                    d="M493.4,457.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C493.5,457.4,493.6,457.6,493.4,457.7z"
                  />
                </g>
                <g id="XMLID_2732_">
                  <path
                    id="XMLID_2733_"
                    style="fill: #ffffff"
                    d="M497.6,455.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C497.8,455,497.8,455.1,497.6,455.2z"
                  />
                </g>
                <g id="XMLID_2730_">
                  <path
                    id="XMLID_2731_"
                    style="fill: #ffffff"
                    d="M501.9,452.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C502,452.5,502,452.7,501.9,452.8z"
                  />
                </g>
                <g id="XMLID_2728_">
                  <path
                    id="XMLID_2729_"
                    style="fill: #ffffff"
                    d="M506.1,450.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C506.3,450.1,506.3,450.2,506.1,450.3z"
                  />
                </g>
                <g id="XMLID_2726_">
                  <path
                    id="XMLID_2727_"
                    style="fill: #ffffff"
                    d="M510.3,447.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C510.5,447.6,510.5,447.8,510.3,447.9z"
                  />
                </g>
                <g id="XMLID_2724_">
                  <path
                    id="XMLID_2725_"
                    style="fill: #ffffff"
                    d="M514.6,445.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C514.7,445.2,514.7,445.4,514.6,445.5z"
                  />
                </g>
                <g id="XMLID_2721_">
                  <path
                    id="XMLID_2723_"
                    style="fill: #ffffff"
                    d="M518.8,443l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C519,442.7,519,442.9,518.8,443z"
                  />
                </g>
                <g id="XMLID_2719_">
                  <path
                    id="XMLID_2720_"
                    style="fill: #ffffff"
                    d="M523,440.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C523.2,440.3,523.2,440.5,523,440.6z"
                  />
                </g>
                <g id="XMLID_2716_">
                  <path
                    id="XMLID_2717_"
                    style="fill: #ffffff"
                    d="M527.3,438.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C527.4,437.8,527.5,438,527.3,438.1z"
                  />
                </g>
                <g id="XMLID_2713_">
                  <path
                    id="XMLID_2714_"
                    style="fill: #ffffff"
                    d="M531.5,435.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C531.7,435.4,531.7,435.6,531.5,435.7z"
                  />
                </g>
                <g id="XMLID_2710_">
                  <path
                    id="XMLID_2712_"
                    style="fill: #ffffff"
                    d="M535.7,433.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C535.9,433,535.9,433.1,535.7,433.2z"
                  />
                </g>
                <g id="XMLID_2708_">
                  <path
                    id="XMLID_2709_"
                    style="fill: #ffffff"
                    d="M540,430.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C540.1,430.5,540.2,430.7,540,430.8z"
                  />
                </g>
                <g id="XMLID_2705_">
                  <path
                    id="XMLID_2706_"
                    style="fill: #ffffff"
                    d="M544.2,428.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C544.4,428.1,544.4,428.2,544.2,428.3z"
                  />
                </g>
              </g>
              <g id="XMLID_2666_">
                <g id="XMLID_2701_">
                  <path
                    id="XMLID_2702_"
                    style="fill: #ffffff"
                    d="M548.6,421.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C548.8,420.9,548.8,421,548.6,421.1z"
                  />
                </g>
                <g id="XMLID_2698_">
                  <path
                    id="XMLID_2699_"
                    style="fill: #ffffff"
                    d="M544.4,423.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C544.6,423.3,544.6,423.5,544.4,423.6z"
                  />
                </g>
                <g id="XMLID_2695_">
                  <path
                    id="XMLID_2697_"
                    style="fill: #ffffff"
                    d="M552.9,418.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C553,418.4,553.1,418.6,552.9,418.7z"
                  />
                </g>
                <g id="XMLID_2693_">
                  <path
                    id="XMLID_2694_"
                    style="fill: #ffffff"
                    d="M557.1,416.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C557.3,416,557.3,416.1,557.1,416.3z"
                  />
                </g>
                <g id="XMLID_2690_">
                  <path
                    id="XMLID_2691_"
                    style="fill: #ffffff"
                    d="M561.4,413.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C561.5,413.5,561.5,413.7,561.4,413.8z"
                  />
                </g>
                <g id="XMLID_2687_">
                  <path
                    id="XMLID_2689_"
                    style="fill: #ffffff"
                    d="M565.6,411.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C565.7,411.1,565.8,411.3,565.6,411.4z"
                  />
                </g>
                <g id="XMLID_2684_">
                  <path
                    id="XMLID_2686_"
                    style="fill: #ffffff"
                    d="M569.8,408.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C570,408.6,570,408.8,569.8,408.9z"
                  />
                </g>
                <g id="XMLID_2682_">
                  <path
                    id="XMLID_2683_"
                    style="fill: #ffffff"
                    d="M552.7,423.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C552.8,423.2,552.8,423.4,552.7,423.5z"
                  />
                </g>
                <g id="XMLID_2679_">
                  <path
                    id="XMLID_2680_"
                    style="fill: #ffffff"
                    d="M548.4,425.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C548.6,425.6,548.6,425.8,548.4,425.9z"
                  />
                </g>
                <g id="XMLID_2677_">
                  <path
                    id="XMLID_2678_"
                    style="fill: #ffffff"
                    d="M556.9,421l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C557.1,420.7,557.1,420.9,556.9,421z"
                  />
                </g>
                <g id="XMLID_2674_">
                  <path
                    id="XMLID_2675_"
                    style="fill: #ffffff"
                    d="M561.1,418.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C561.3,418.3,561.3,418.5,561.1,418.6z"
                  />
                </g>
                <g id="XMLID_2671_">
                  <path
                    id="XMLID_2672_"
                    style="fill: #ffffff"
                    d="M565.4,416.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C565.5,415.8,565.5,416,565.4,416.1z"
                  />
                </g>
                <g id="XMLID_2668_">
                  <path
                    id="XMLID_2669_"
                    style="fill: #ffffff"
                    d="M569.6,413.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C569.8,413.4,569.8,413.6,569.6,413.7z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_2595_">
              <g id="XMLID_2597_">
                <g id="XMLID_2663_">
                  <path
                    id="XMLID_2664_"
                    style="fill: #ffffff"
                    d="M384.2,440.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C384.3,439.9,384.4,440,384.2,440.1z"
                  />
                </g>
                <g id="XMLID_2660_">
                  <path
                    id="XMLID_2661_"
                    style="fill: #ffffff"
                    d="M388.4,442.6l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C388.6,442.3,388.6,442.5,388.4,442.6z"
                  />
                </g>
                <g id="XMLID_2657_">
                  <path
                    id="XMLID_2658_"
                    style="fill: #ffffff"
                    d="M384.2,445l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C384.4,444.8,384.4,444.9,384.2,445z"
                  />
                </g>
                <g id="XMLID_2654_">
                  <path
                    id="XMLID_2655_"
                    style="fill: #ffffff"
                    d="M392.5,444.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C392.6,444.6,392.6,444.8,392.5,444.9z"
                  />
                </g>
                <g id="XMLID_2651_">
                  <path
                    id="XMLID_2652_"
                    style="fill: #ffffff"
                    d="M388.2,447.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C388.4,447.1,388.4,447.3,388.2,447.4z"
                  />
                </g>
                <g id="XMLID_2648_">
                  <path
                    id="XMLID_2650_"
                    style="fill: #ffffff"
                    d="M396.5,447.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C396.6,447,396.7,447.1,396.5,447.2z"
                  />
                </g>
                <g id="XMLID_2646_">
                  <path
                    id="XMLID_2647_"
                    style="fill: #ffffff"
                    d="M392.3,449.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C392.4,449.4,392.4,449.6,392.3,449.7z"
                  />
                </g>
                <g id="XMLID_2643_">
                  <path
                    id="XMLID_2644_"
                    style="fill: #ffffff"
                    d="M400.7,449.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C400.9,449.4,400.9,449.6,400.7,449.7z"
                  />
                </g>
                <g id="XMLID_2640_">
                  <path
                    id="XMLID_2641_"
                    style="fill: #ffffff"
                    d="M396.5,452.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C396.7,451.9,396.7,452,396.5,452.1z"
                  />
                </g>
                <g id="XMLID_2637_">
                  <path
                    id="XMLID_2638_"
                    style="fill: #ffffff"
                    d="M404.8,452l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C404.9,451.7,404.9,451.9,404.8,452z"
                  />
                </g>
                <g id="XMLID_2634_">
                  <path
                    id="XMLID_2636_"
                    style="fill: #ffffff"
                    d="M400.5,454.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C400.7,454.2,400.7,454.4,400.5,454.5z"
                  />
                </g>
                <g id="XMLID_2631_">
                  <path
                    id="XMLID_2633_"
                    style="fill: #ffffff"
                    d="M408.8,454.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C408.9,454.1,409,454.2,408.8,454.3z"
                  />
                </g>
                <g id="XMLID_2629_">
                  <path
                    id="XMLID_2630_"
                    style="fill: #ffffff"
                    d="M404.5,456.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C404.7,456.5,404.7,456.7,404.5,456.8z"
                  />
                </g>
                <g id="XMLID_2626_">
                  <path
                    id="XMLID_2627_"
                    style="fill: #ffffff"
                    d="M413,456.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C413.2,456.5,413.2,456.7,413,456.8z"
                  />
                </g>
                <g id="XMLID_2623_">
                  <path
                    id="XMLID_2624_"
                    style="fill: #ffffff"
                    d="M408.8,459.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C409,459,409,459.1,408.8,459.2z"
                  />
                </g>
                <g id="XMLID_2620_">
                  <path
                    id="XMLID_2622_"
                    style="fill: #ffffff"
                    d="M417.3,454.4l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C417.4,454.1,417.4,454.3,417.3,454.4z"
                  />
                </g>
                <g id="XMLID_2617_">
                  <path
                    id="XMLID_2619_"
                    style="fill: #ffffff"
                    d="M421.5,451.9l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C421.7,451.6,421.7,451.8,421.5,451.9z"
                  />
                </g>
                <g id="XMLID_2615_">
                  <path
                    id="XMLID_2616_"
                    style="fill: #ffffff"
                    d="M425.7,449.5l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C425.9,449.2,425.9,449.4,425.7,449.5z"
                  />
                </g>
                <g id="XMLID_2612_">
                  <path
                    id="XMLID_2613_"
                    style="fill: #ffffff"
                    d="M430,447l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C430.1,446.7,430.2,446.9,430,447z"
                  />
                </g>
                <g id="XMLID_2609_">
                  <path
                    id="XMLID_2610_"
                    style="fill: #ffffff"
                    d="M417.1,459.1l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C417.2,458.8,417.2,459,417.1,459.1z"
                  />
                </g>
                <g id="XMLID_2606_">
                  <path
                    id="XMLID_2608_"
                    style="fill: #ffffff"
                    d="M421.3,456.7l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C421.4,456.4,421.5,456.6,421.3,456.7z"
                  />
                </g>
                <g id="XMLID_2604_">
                  <path
                    id="XMLID_2605_"
                    style="fill: #ffffff"
                    d="M425.5,454.2l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C425.7,453.9,425.7,454.1,425.5,454.2z"
                  />
                </g>
                <g id="XMLID_2601_">
                  <path
                    id="XMLID_2602_"
                    style="fill: #ffffff"
                    d="M429.8,451.8l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C429.9,451.5,429.9,451.7,429.8,451.8z"
                  />
                </g>
                <g id="XMLID_2598_">
                  <path
                    id="XMLID_2600_"
                    style="fill: #ffffff"
                    d="M434,449.3l-1.7,1c-0.2,0.1-0.5,0.1-0.7,0l-1.6-0.9c-0.2-0.1-0.2-0.3,0-0.4
              l1.7-1c0.2-0.1,0.5-0.1,0.7,0l1.6,0.9C434.2,449.1,434.2,449.2,434,449.3z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_247_">
              <g id="XMLID_2499_">
                <g id="XMLID_2549_">
                  <g id="XMLID_2555_">
                    <g id="XMLID_2574_">
                      <g id="XMLID_2586_">
                        <g id="XMLID_2593_">
                          <path
                            id="XMLID_2594_"
                            style="fill: #ffffff"
                            d="M465.2,418.3C465.2,418.3,465.2,418.2,465.2,418.3v-8.4l-9.3,0.2l-14.1-8
                      c-3.8-2.2-8.4-2.2-12.1,0l-32.7,19.2l-15.6,2.9v8.4c-0.1,1.7,0.6,3.5,2.3,4.5l21,12c3.8,2.2,8.4,2.2,12.1,0l46-26.8
                      C464.4,421.5,465.2,419.9,465.2,418.3L465.2,418.3L465.2,418.3z"
                          />
                        </g>
                        <g id="XMLID_2587_">
                          <path
                            id="XMLID_2588_"
                            d="M410.8,451.3c-2.2,0-4.4-0.6-6.3-1.7l-21-12c-1.8-1-2.8-3-2.6-5l0-8.9l16-3l32.6-19.1
                      c3.9-2.3,8.8-2.3,12.7,0l13.9,7.9l9.7-0.2v9.5l0,0c-0.2,1.7-1.1,3.1-2.6,4l-46,26.8C415.2,450.8,413,451.3,410.8,451.3z
                       M382,424.7v8c-0.1,1.7,0.7,3.2,2.1,4l21,12c3.6,2.1,8,2.1,11.6,0l46-26.8c1.3-0.7,2.1-2.1,2.1-3.5l0-0.2v-7.7l-8.9,0.2
                      l-14.2-8c-3.6-2.1-8-2.1-11.6,0l-32.7,19.2L382,424.7z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2576_">
                        <g id="XMLID_2583_">
                          <path
                            id="XMLID_2584_"
                            style="fill: #ffffff"
                            d="M383.8,428.8l21,12c3.8,2.2,8.4,2.2,12.1,0l46-26.8
                      c3.2-1.8,3.2-6.4,0-8.2l-21-12c-3.8-2.2-8.4-2.2-12.1,0l-46,26.8C380.6,422.4,380.6,427,383.8,428.8z"
                          />
                        </g>
                        <g id="XMLID_2577_">
                          <path
                            id="XMLID_2579_"
                            d="M410.8,442.9c-2.2,0-4.4-0.6-6.3-1.7l-21-12c-1.7-1-2.6-2.7-2.6-4.6c0-1.9,1-3.6,2.6-4.6
                      l46-26.8c3.9-2.3,8.8-2.3,12.7,0l21,12c1.7,1,2.6,2.7,2.6,4.6c0,1.9-1,3.6-2.6,4.6l-46,26.8
                      C415.2,442.3,413,442.9,410.8,442.9z M384.1,428.3l21,12c3.6,2.1,8,2.1,11.6,0l46-26.8c1.3-0.8,2.1-2.1,2.1-3.6
                      c0-1.5-0.8-2.9-2.1-3.6l-21-12c-3.6-2.1-8-2.1-11.6,0l-46,26.8c-1.3,0.8-2.1,2.1-2.1,3.6
                      C382,426.2,382.7,427.5,384.1,428.3z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_2556_">
                      <g id="XMLID_2570_">
                        <g id="XMLID_2572_">
                          <rect
                            id="XMLID_2573_"
                            x="385.6"
                            y="430"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2566_">
                        <g id="XMLID_2567_">
                          <rect
                            id="XMLID_2569_"
                            x="410.1"
                            y="442.1"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2562_">
                        <g id="XMLID_2563_">
                          <rect
                            id="XMLID_2565_"
                            x="415.4"
                            y="440.9"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2558_">
                        <g id="XMLID_2559_">
                          <rect
                            id="XMLID_2560_"
                            x="460.4"
                            y="414.7"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_2550_">
                    <g id="XMLID_2552_">
                      <polygon
                        id="XMLID_2553_"
                        points="417.9,440.1 417.9,448.6 459.2,424.5 459.2,416                 "
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_2501_">
                  <g id="XMLID_2506_">
                    <g id="XMLID_2526_">
                      <g id="XMLID_2538_">
                        <g id="XMLID_2546_">
                          <path
                            id="XMLID_2547_"
                            style="fill: #ffffff"
                            d="M570.5,399.6C570.5,399.5,570.5,399.5,570.5,399.6v-8.4l-9.3,0.2
                      l-85.5-48.9c-3.8-2.2-8.4-2.2-12.1,0l-73.5,43.2l-15.6,2.9v8.4c-0.1,1.7,0.6,3.5,2.3,4.5l92.4,52.9
                      c3.8,2.2,8.4,2.2,12.1,0l86.8-50.9C569.7,402.7,570.5,401.2,570.5,399.6L570.5,399.6L570.5,399.6z"
                          />
                        </g>
                        <g id="XMLID_2540_">
                          <path
                            id="XMLID_2541_"
                            d="M475.3,456.7c-2.2,0-4.4-0.6-6.3-1.7l-92.4-52.9c-1.8-1-2.8-3-2.6-5l0-8.9l16-3l73.4-43.1
                      c3.9-2.3,8.8-2.3,12.7,0l85.4,48.8l9.7-0.2v9.5l0,0c-0.2,1.7-1.1,3.1-2.6,4L481.6,455
                      C479.7,456.1,477.5,456.7,475.3,456.7z M375,389.1v8c-0.1,1.7,0.7,3.2,2.1,4l92.4,52.9c3.6,2.1,8,2.1,11.6,0l86.8-50.9
                      c1.3-0.7,2.1-2.1,2.1-3.5l0-0.2v-7.7l-8.9,0.2L475.5,343c-3.6-2.1-8-2.1-11.6,0l-73.6,43.2L375,389.1z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2528_">
                        <g id="XMLID_2535_">
                          <path
                            id="XMLID_2537_"
                            style="fill: #ffffff"
                            d="M376.8,393.2l92.4,52.9c3.8,2.2,8.4,2.2,12.1,0l86.8-50.9
                      c3.2-1.8,3.2-6.4,0-8.2l-92.4-52.9c-3.8-2.2-8.4-2.2-12.1,0L376.8,385C373.6,386.8,373.6,391.4,376.8,393.2z"
                          />
                        </g>
                        <g id="XMLID_2529_">
                          <path
                            id="XMLID_2531_"
                            d="M475.3,448.2c-2.2,0-4.4-0.6-6.3-1.7l-92.4-52.9c-1.7-1-2.6-2.7-2.6-4.6c0-1.9,1-3.6,2.6-4.6
                      l86.8-50.9c3.9-2.3,8.8-2.3,12.7,0l92.4,52.9c1.7,1,2.6,2.7,2.6,4.6c0,1.9-1,3.6-2.6,4.6l-86.8,50.9
                      C479.7,447.7,477.5,448.2,475.3,448.2z M377.1,392.7l92.4,52.9c3.6,2.1,8,2.1,11.6,0l86.8-50.9c1.3-0.8,2.1-2.1,2.1-3.6
                      c0-1.5-0.8-2.9-2.1-3.6l-92.4-52.9c-3.6-2.1-8-2.1-11.6,0l-86.8,50.9c-1.3,0.8-2.1,2.1-2.1,3.6
                      C375,390.6,375.8,392,377.1,392.7L377.1,392.7z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_2508_">
                      <g id="XMLID_2522_">
                        <g id="XMLID_2523_">
                          <rect
                            id="XMLID_2525_"
                            x="378.6"
                            y="394.5"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2517_">
                        <g id="XMLID_2519_">
                          <rect
                            id="XMLID_2520_"
                            x="474.5"
                            y="447.5"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2513_">
                        <g id="XMLID_2515_">
                          <rect
                            id="XMLID_2516_"
                            x="479.9"
                            y="446.2"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2509_">
                        <g id="XMLID_2511_">
                          <rect
                            id="XMLID_2512_"
                            x="565.7"
                            y="396"
                            width="1.1"
                            height="8.8"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_2502_">
                    <g id="XMLID_2504_">
                      <polygon
                        id="XMLID_2505_"
                        points="482.4,445.5 482.4,453.9 564.6,405.8 564.6,397.3                 "
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_2414_">
                <g id="XMLID_2457_">
                  <g id="XMLID_2459_">
                    <g id="XMLID_2469_">
                      <g id="XMLID_2490_">
                        <g id="XMLID_2497_">
                          <path
                            id="XMLID_2498_"
                            style="fill: #ffffff"
                            d="M400.2,409.3l-11.7-6.8c-0.2-0.1-0.3-0.3-0.3-0.4l0,0l0,0c0,0,0,0,0,0
                      l0-3.6c0-0.1,0.1-0.2,0.2-0.2l5.9,0c0,0,0.1,0,0.1,0l5.8-3.3c0.5-0.3,1.1-0.3,1.5-0.1l5.9,3.5c0,0,0.1,0,0.1,0l5.9,0
                      c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.6,6.7C401.3,409.5,400.6,409.5,400.2,409.3z"
                          />
                        </g>
                        <g id="XMLID_2491_">
                          <path
                            id="XMLID_2492_"
                            d="M400.9,409.8c-0.3,0-0.6-0.1-0.9-0.2l0,0l-11.7-6.8c-0.3-0.2-0.5-0.5-0.5-0.8l0-3.6
                      c0-0.3,0.3-0.6,0.6-0.6l5.9,0l5.7-3.2c0.6-0.3,1.4-0.4,1.9-0.1l5.8,3.4l5.8,0c0.2,0,0.3,0.1,0.4,0.2
                      c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8l-11.6,6.7C401.6,409.7,401.2,409.8,400.9,409.8z M400.4,408.9
                      c0.2,0.1,0.8,0.1,1.1-0.1l11.6-6.7c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3l-5.6,0c-0.1,0-0.2,0-0.3-0.1l-5.9-3.5
                      c-0.2-0.1-0.8-0.1-1.1,0.1l-5.8,3.3c-0.1,0-0.2,0.1-0.3,0.1l-5.7,0l0,3.4c0,0,0,0,0.1,0.1L400.4,408.9z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2480_">
                        <g id="XMLID_2487_">
                          <path
                            id="XMLID_2488_"
                            d="M408.4,398.3c0,0,5.2,0,5.2,0c0.1,0,0.2,0.1,0.2,0.2l0,3.6c0,0.2-0.1,0.3-0.4,0.5l-11.5,6.6
                      c0,0-0.1,0-0.1,0l0-3.7c0,0,0,0,0,0l0,0C401.3,404.2,408.4,398.3,408.4,398.3z"
                          />
                        </g>
                        <g id="XMLID_2481_">
                          <path
                            id="XMLID_2483_"
                            d="M401.8,409.6l-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-3.7c-0.2-1.4,4.1-5.2,6.7-7.4l0.1-0.1
                      c0.1,0,0.1,0,5.3,0c0,0,0,0,0,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0,3.6c0,0.3-0.2,0.6-0.6,0.8L401.8,409.6z
                       M402.2,405.5l0,3l11-6.3c0.1-0.1,0.2-0.1,0.2-0.1l0-3.3c-1.4,0-4.1,0-4.8,0c-3.1,2.6-6.4,5.8-6.3,6.5L402.2,405.5
                      L402.2,405.5z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_2470_">
                        <g id="XMLID_2477_">
                          <path
                            id="XMLID_2478_"
                            style="fill: #ffffff"
                            d="M413.5,397.8l-11.7-6.8c-0.4-0.2-1.1-0.2-1.5,0.1l-11.6,6.7
                      c-0.5,0.3-0.5,0.7-0.1,0.9l11.7,6.8c0.4,0.2,1.1,0.2,1.5-0.1l11.6-6.7C413.8,398.4,413.9,398,413.5,397.8z"
                          />
                        </g>
                        <g id="XMLID_2471_">
                          <path
                            id="XMLID_2473_"
                            d="M400.9,406c-0.3,0-0.6-0.1-0.9-0.2l-11.7-6.8c-0.3-0.2-0.5-0.4-0.5-0.7c0-0.3,0.2-0.6,0.6-0.9
                      l11.6-6.7c0.6-0.3,1.4-0.4,1.9-0.1l11.7,6.8c0.3,0.2,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.9l-11.6,6.7
                      C401.7,405.9,401.3,406,400.9,406z M388.7,398.2C388.7,398.2,388.7,398.2,388.7,398.2l11.8,6.8c0.2,0.1,0.8,0.1,1.1-0.1
                      l11.6-6.7c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0l0,0l-11.7-6.8c-0.2-0.1-0.8-0.1-1.1,0.1l-11.6,6.7
                      C388.7,398.1,388.7,398.2,388.7,398.2z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_2464_">
                      <g id="XMLID_2466_">
                        <rect
                          id="XMLID_2467_"
                          x="388.8"
                          y="401.4"
                          transform="matrix(3.009958e-02 -0.9995 0.9995 3.009958e-02 -22.7066 780.2357)"
                          width="3.7"
                          height="0.8"
                        />
                      </g>
                    </g>
                    <g id="XMLID_2460_">
                      <g id="XMLID_2462_">
                        <rect
                          id="XMLID_2463_"
                          x="408.8"
                          y="402"
                          transform="matrix(1.471379e-02 -0.9999 0.9999 1.471379e-02 2.06 806.8235)"
                          style="fill: #ffffff"
                          width="3.3"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_2416_">
                  <g id="XMLID_2426_">
                    <g id="XMLID_2448_">
                      <g id="XMLID_2455_">
                        <path
                          id="XMLID_2456_"
                          style="fill: #ffffff"
                          d="M401.8,388.3l-3.1-1.9c-0.2-0.1-0.4-0.1-0.5,0l0,0h0c0,0,0,0,0,0l-3.1,1.8
                    c-0.1,0-0.1,0.1-0.1,0.2l2.9,5.1c0,0,0,0.1,0,0.1v0.6c0,0.5,0.3,1.1,0.7,1.4l-2.8-1.5c0,0,0,0,0.1,0.1l2.9,5.1
                    c0,0.1,0.1,0.1,0.2,0.1l3.1-1.8c0.1-0.1,0.2-0.3,0.2-0.6v-7.3C402.5,389.1,402.1,388.5,401.8,388.3z"
                        />
                      </g>
                      <g id="XMLID_2449_">
                        <path
                          id="XMLID_2450_"
                          d="M399,399.8c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.3l-2.9-5.1c0,0,0.1,0.1,0.1,0.1l0.4-0.7
                    l1.5,0.8c0-0.1,0-0.2,0-0.3v-0.5l-2.9-5.1c-0.2-0.3-0.1-0.6,0.2-0.8l3.1-1.8c0.3-0.2,0.6-0.2,0.9,0l3.1,1.9
                    c0.5,0.3,0.9,1,0.9,1.7v7.3c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.9C399.2,399.8,399.1,399.8,399,399.8z M397,395.2l2.1,3.7
                    l2.9-1.7c0,0,0-0.1,0-0.2v-7.3c0-0.4-0.3-0.9-0.5-1l0,0l-3.1-1.9c0,0-0.1,0-0.1,0l-2.9,1.7l2.8,4.9c0,0.1,0.1,0.2,0.1,0.3
                    v0.6c0,0.4,0.3,0.9,0.5,1l-0.4,0.7L397,395.2z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_2437_">
                      <g id="XMLID_2445_">
                        <path
                          id="XMLID_2446_"
                          d="M395.8,393.1c0,0,1.1-6.7,2.3-1l-2.1,2.1c0,0,0,0.1,0.1,0.1l2.9,5.1c0.1,0.1,0.2,0.1,0.3,0.1
                    l3.1-1.8c0.1-0.1,0.2-0.3,0.2-0.6v-7.2c0,0,0-0.1-0.1,0l-3.2,1.9c0,0,0,0,0,0L395.8,393.1z"
                        />
                      </g>
                      <g id="XMLID_2438_">
                        <path
                          id="XMLID_2440_"
                          d="M399.1,399.8c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.3l-2.9-5.1l-0.3-0.3l0.7-0.7l-0.8,0.3
                    l0.1-0.7c0.4-2.4,1-3.6,1.7-3.6c0,0,0,0,0,0c0.6,0,0.9,0.6,1.3,2.2l0.6-0.3l3.4-2l0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.4v7.2
                    c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.8C399.3,399.8,399.2,399.8,399.1,399.8z M396.4,394.2l2.7,4.7l2.9-1.7c0,0,0-0.1,0-0.2v-6.6
                    l-2.7,1.6l-1.2,0.5L396.4,394.2z M397.1,390.3c-0.2,0.3-0.5,1.1-0.7,2.1l1.2-0.5C397.4,390.8,397.2,390.4,397.1,390.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_2427_">
                      <g id="XMLID_2434_">
                        <path
                          id="XMLID_2435_"
                          style="fill: #ffffff"
                          d="M398.5,399.5l-3.1-1.9c-0.4-0.2-0.7-0.8-0.7-1.4v-7.3
                    c0-0.5,0.3-0.8,0.7-0.5l3.1,1.9c0.4,0.2,0.7,0.8,0.7,1.4v7.3C399.2,399.4,398.8,399.7,398.5,399.5z"
                        />
                      </g>
                      <g id="XMLID_2428_">
                        <path
                          id="XMLID_2430_"
                          d="M398.7,400c-0.2,0-0.3,0-0.5-0.1l0,0l-3.1-1.9c-0.5-0.3-0.9-1-0.9-1.7v-7.3
                    c0-0.4,0.2-0.8,0.5-0.9c0.3-0.1,0.6-0.1,0.9,0l3.1,1.9c0.5,0.3,0.9,1,0.9,1.7v7.3c0,0.4-0.2,0.8-0.5,0.9
                    C399,399.9,398.9,400,398.7,400z M398.7,399.1C398.7,399.1,398.7,399.1,398.7,399.1c0.1,0,0.1,0,0.1-0.2v-7.3
                    c0-0.4-0.3-0.9-0.5-1l-3.1-1.9c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.2v7.3c0,0.4,0.3,0.9,0.5,1L398.7,399.1z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_2421_">
                    <g id="XMLID_2423_">
                      <rect
                        id="XMLID_2424_"
                        x="397.4"
                        y="388.3"
                        transform="matrix(0.8488 -0.5287 0.5287 0.8488 -145.1248 269.854)"
                        width="3.7"
                        height="0.8"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2417_">
                    <g id="XMLID_2419_">
                      <rect
                        id="XMLID_2420_"
                        x="399.2"
                        y="394.2"
                        transform="matrix(0.8567 -0.5159 0.5159 0.8567 -146.0708 263.3468)"
                        style="fill: #ffffff"
                        width="3.3"
                        height="0.6"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="XMLID_2364_">
                <g id="XMLID_2375_">
                  <g id="XMLID_2404_">
                    <g id="XMLID_2411_">
                      <path
                        id="XMLID_2413_"
                        style="fill: #ffffff"
                        d="M552.5,394c0,0,0-8.4,0-8.7c0,0,0-0.1-0.1-0.1l-9.1,0.8c0,0,0,0,0,0l-0.8-5
                  c0,0-0.1,0-0.1,0l-8.4,4.8c0,0,0,0-0.1,0l-1.6-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,8.6,0,8.6l0,0c0,0.3,0.2,0.5,0.4,0.7l8.4,4.9
                  c0.7,0.4,1.6,0.4,2.3,0l8.6-4.9C552.3,394.6,552.5,394.3,552.5,394L552.5,394z"
                      />
                    </g>
                    <g id="XMLID_2406_">
                      <path
                        id="XMLID_2407_"
                        d="M542.3,400.4c-0.5,0-1-0.1-1.4-0.4l-8.4-4.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-6.3l0-2.4
                  c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l8.3-4.7c0.2-0.1,0.3-0.1,0.5,0l0.2,0.1l0.8,4.8l8.8-0.8l0.1,0
                  c0.2,0.1,0.4,0.2,0.4,0.5l0,8.8c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C543.2,400.3,542.8,400.4,542.3,400.4z M532.7,385.9l0,1.9
                  c0,2.4,0,6.1,0,6.3c0,0.1,0.1,0.2,0.2,0.3l8.4,4.9c0.6,0.3,1.3,0.3,1.9,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4h0l0-8.3l-8.8,0.8
                  l-0.2-0.1l-0.2-0.1l-0.8-4.7l-8.1,4.6l-0.2,0L532.7,385.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2395_">
                    <g id="XMLID_2402_">
                      <path
                        id="XMLID_2403_"
                        d="M552.4,385.2l-9.1,0.8c0,0,0,0,0,0l-1.1,3.1l0,10.9c0.4,0,0.8-0.1,1.2-0.3l8.6-4.9
                  c0.3-0.2,0.4-0.5,0.4-0.7l0,0C552.5,394,552.5,385.7,552.4,385.2C552.5,385.3,552.4,385.3,552.4,385.2z"
                      />
                    </g>
                    <g id="XMLID_2396_">
                      <path
                        id="XMLID_2397_"
                        d="M542.3,400.4C542.3,400.4,542.2,400.4,542.3,400.4l-0.5,0l0-11.4l1.2-3.6l0.3,0.2l9-0.8l0.1,0
                  c0.2,0.1,0.4,0.2,0.4,0.5l0,8.8c0,0.4-0.3,0.8-0.6,1l-8.6,4.9C543.3,400.3,542.8,400.4,542.3,400.4z M542.6,389.2l0,10.3
                  c0.2,0,0.4-0.1,0.6-0.2l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4l0-8.3l-8.5,0.8L542.6,389.2z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2385_">
                    <g id="XMLID_2392_">
                      <path
                        id="XMLID_2393_"
                        d="M533.5,386.5c0,0,0.1-0.3,0.3-0.8l-1.5-0.4c0,0-0.1,0-0.1,0.1c0,0.4,0,8.6,0,8.6l0,0
                  c0,0.3,0.2,0.5,0.4,0.7l0.9,0.5l0-6.7V386.5z"
                      />
                    </g>
                    <g id="XMLID_2387_">
                      <path
                        id="XMLID_2388_"
                        d="M534,396l-1.5-0.9c-0.4-0.2-0.6-0.6-0.6-1c0-0.1,0-0.1,0-6.3l0-2.4c0-0.2,0.1-0.3,0.2-0.4
                  c0.1-0.1,0.3-0.1,0.4-0.1l1.9,0.5l-0.1,0.4c-0.1,0.3-0.2,0.6-0.3,0.7L534,396z M532.7,385.9l0,1.9c0,2.4,0,6.1,0,6.3
                  c0,0.1,0.1,0.2,0.2,0.3l0.2,0.1l0-8.1c0,0,0.1-0.1,0.1-0.4L532.7,385.9z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_2376_">
                    <g id="XMLID_2383_">
                      <path
                        id="XMLID_2384_"
                        style="fill: #ffffff"
                        d="M541.2,391l-8.5-4.9c-0.6-0.3-0.6-1.2,0-1.6l8.6-4.9c0.7-0.4,1.6-0.4,2.2,0
                  l8.5,4.9c0.6,0.3,0.6,1.2,0,1.6l-8.6,4.9C542.7,391.4,541.9,391.4,541.2,391z"
                      />
                    </g>
                    <g id="XMLID_2378_">
                      <path
                        id="XMLID_2379_"
                        d="M542.3,391.7c-0.5,0-0.9-0.1-1.3-0.4l-8.5-4.9c-0.4-0.2-0.7-0.7-0.7-1.1c0-0.5,0.2-0.9,0.7-1.1
                  l8.6-4.9c0.8-0.5,1.8-0.5,2.7,0l8.5,4.9c0.4,0.2,0.7,0.7,0.7,1.1c0,0.5-0.2,0.9-0.7,1.1l-8.6,4.9
                  C543.2,391.6,542.8,391.7,542.3,391.7z M542.4,379.7c-0.3,0-0.6,0.1-0.9,0.2l-8.6,4.9c-0.2,0.1-0.2,0.3-0.2,0.4
                  c0,0.1,0,0.3,0.2,0.4l8.5,4.9c0.6,0.3,1.3,0.3,1.8,0l8.6-4.9c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.4l-8.5-4.9
                  C543.1,379.8,542.7,379.7,542.4,379.7z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_2365_">
                  <g id="XMLID_2372_">
                    <polygon
                      id="XMLID_2373_"
                      style="fill: #ffffff"
                      points="544.9,385.3 540.9,383.1 546.8,379.7 550.8,381.9               "
                    />
                  </g>
                  <g id="XMLID_2366_">
                    <path
                      id="XMLID_2368_"
                      d="M544.9,385.8l-4.9-2.7l6.7-3.9l4.9,2.7L544.9,385.8z M541.7,383.1l3.2,1.8l5-2.9l-3.2-1.8
                L541.7,383.1z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_2331_">
                <g id="XMLID_2354_">
                  <g id="XMLID_2361_">
                    <path
                      id="XMLID_2362_"
                      style="fill: #ffffff"
                      d="M418,424.5c0,0,0-2.9,0-3.1c0,0,0,0,0-0.1l-6.5,0.6c0,0,0,0,0,0l-0.6-3.6
                c0,0,0,0-0.1,0l-6,3.4c0,0,0,0,0,0l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0c0,0.2,0.1,0.4,0.3,0.5l6,3.5
                c0.5,0.3,1.1,0.3,1.7,0l6.1-3.5C417.9,425,418,424.8,418,424.5L418,424.5z"
                    />
                  </g>
                  <g id="XMLID_2356_">
                    <path
                      id="XMLID_2357_"
                      d="M410.8,429.2c-0.4,0-0.7-0.1-1-0.3l-6-3.5c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.5l0-0.9l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1,0.3l6.1-3.5l0.4,0.2l0.6,3.4l6.2-0.6l0.1,0c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8
                l-6.1,3.5C411.5,429.1,411.1,429.2,410.8,429.2z M404,422v0.2c0,0.9,0,2.3,0,2.4c0,0,0,0.1,0.1,0.2l6,3.5
                c0.4,0.2,0.9,0.2,1.2,0l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2h0l0-2.7l-6.2,0.6l-0.2-0.1l-0.2-0.1l-0.5-3.2l-5.7,3.3l-0.2,0
                L404,422z"
                    />
                  </g>
                </g>
                <g id="XMLID_2346_">
                  <g id="XMLID_2351_">
                    <path
                      id="XMLID_2353_"
                      d="M418,421.3l-6.5,0.6c0,0,0,0,0,0l-0.8,2.2l0,4.7c0.3,0,0.6-0.1,0.9-0.2l6.1-3.5
                c0.2-0.1,0.3-0.3,0.3-0.5l0,0C418,424.5,418,421.6,418,421.3C418,421.4,418,421.4,418,421.3z"
                    />
                  </g>
                  <g id="XMLID_2347_">
                    <path
                      id="XMLID_2348_"
                      d="M410.8,429.2C410.7,429.2,410.7,429.2,410.8,429.2l-0.5,0l0-5.2l0.9-2.7l0.3,0.2l6.5-0.6l0.1,0
                c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.6-0.5,0.8l-6.1,3.5C411.5,429.1,411.1,429.2,410.8,429.2z M411.1,424.2l0,4.1
                c0.1,0,0.2-0.1,0.3-0.1l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2l0-2.7l-5.8,0.5L411.1,424.2z"
                    />
                  </g>
                </g>
                <g id="XMLID_2339_">
                  <g id="XMLID_2344_">
                    <path
                      id="XMLID_2345_"
                      d="M404.5,422.3c0,0,0.1-0.2,0.2-0.6l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0
                c0,0.2,0.1,0.4,0.3,0.5l0.6,0.4l0-1.7V422.3z"
                    />
                  </g>
                  <g id="XMLID_2340_">
                    <path
                      id="XMLID_2341_"
                      d="M404.9,426.1l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.5l0-0.9l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l-0.1,0.4c-0.1,0.2-0.1,0.4-0.2,0.5L404.9,426.1z M404,422v0.2c0,0.9,0,2.3,0,2.4
                c0,0,0,0.1,0.1,0.1l0-2.6c0,0,0,0,0-0.1L404,422z"
                    />
                  </g>
                </g>
                <g id="XMLID_2332_">
                  <g id="XMLID_2337_">
                    <path
                      id="XMLID_2338_"
                      style="fill: #ffffff"
                      d="M410,425.5l-6.1-3.5c-0.4-0.2-0.4-0.9,0-1.1l6.2-3.5c0.5-0.3,1.1-0.3,1.6,0
                l6.1,3.5c0.4,0.2,0.4,0.9,0,1.1l-6.2,3.5C411.1,425.8,410.4,425.8,410,425.5z"
                    />
                  </g>
                  <g id="XMLID_2333_">
                    <path
                      id="XMLID_2334_"
                      d="M410.8,426.1c-0.4,0-0.7-0.1-1-0.3l-6.1-3.5c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9
                l6.2-3.5c0.6-0.4,1.4-0.4,2,0l6.1,3.5c0.3,0.2,0.5,0.5,0.5,0.9c0,0.4-0.2,0.7-0.5,0.9l-6.2,3.5
                C411.5,426,411.1,426.1,410.8,426.1z M410.8,417.5c-0.2,0-0.4,0.1-0.6,0.2l-6.2,3.5c-0.1,0.1-0.1,0.2-0.1,0.2
                c0,0,0,0.1,0.1,0.2l6.1,3.5c0.4,0.2,0.8,0.2,1.2,0l6.2-3.5c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-6.1-3.5
                C411.3,417.6,411.1,417.5,410.8,417.5z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_2302_">
                <g id="XMLID_2324_">
                  <g id="XMLID_2329_">
                    <path
                      id="XMLID_2330_"
                      style="fill: #ffffff"
                      d="M519.9,372.8c0,0,0-2.9,0-3.1c0,0,0,0,0-0.1l-6.5,0.6c0,0,0,0,0,0l-0.6-3.6
                c0,0,0,0-0.1,0l-6,3.4c0,0,0,0,0,0l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0c0,0.2,0.1,0.4,0.3,0.5l6,3.5
                c0.5,0.3,1.1,0.3,1.7,0l6.1-3.5C519.8,373.2,519.9,373,519.9,372.8L519.9,372.8z"
                    />
                  </g>
                  <g id="XMLID_2325_">
                    <path
                      id="XMLID_2326_"
                      d="M512.6,377.4c-0.4,0-0.7-0.1-1-0.3l-6-3.5c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.1l0-1.2l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1,0.3l6.1-3.5l0.4,0.2l0.6,3.4l6.2-0.6l0.1,0c0.2,0.1,0.3,0.2,0.3,0.5l0,3.2c0,0.3-0.2,0.7-0.5,0.8
                l-6.1,3.5C513.4,377.4,513,377.4,512.6,377.4z M505.9,370.2l0,0.5c0,0.9,0,2,0,2.1c0,0,0,0.1,0.1,0.2l6,3.5
                c0.4,0.2,0.9,0.2,1.2,0l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2h0l0-2.7l-6.2,0.6l-0.2-0.1l-0.2-0.1l-0.5-3.2l-5.7,3.3l-0.2,0
                L505.9,370.2z"
                    />
                  </g>
                </g>
                <g id="XMLID_2317_">
                  <g id="XMLID_2322_">
                    <path
                      id="XMLID_2323_"
                      d="M519.9,369.6l-6.5,0.6c0,0,0,0,0,0l-0.8,2.2l0,4.7c0.3,0,0.6-0.1,0.9-0.2l6.1-3.5
                c0.2-0.1,0.3-0.3,0.3-0.5l0,0C519.9,372.7,519.9,369.9,519.9,369.6C519.9,369.6,519.9,369.6,519.9,369.6z"
                    />
                  </g>
                  <g id="XMLID_2318_">
                    <path
                      id="XMLID_2319_"
                      d="M512.6,377.5C512.6,377.5,512.6,377.5,512.6,377.5l-0.5,0l0-5.2l0.9-2.7l0.3,0.2l6.5-0.6l0.1,0
                c0.2,0.1,0.3,0.2,0.3,0.5l0,3.1c0,0.4-0.2,0.7-0.5,0.9l-6.1,3.5C513.4,377.4,513,377.5,512.6,377.5z M513,372.4l0,4.1
                c0.1,0,0.2-0.1,0.3-0.1l6.1-3.5c0.1-0.1,0.1-0.1,0.1-0.2l0-2.7l-5.8,0.5L513,372.4z"
                    />
                  </g>
                </g>
                <g id="XMLID_2310_">
                  <g id="XMLID_2315_">
                    <path
                      id="XMLID_2316_"
                      d="M506.4,370.5c0,0,0.1-0.2,0.2-0.6l-1.1-0.3c0,0-0.1,0-0.1,0.1c0,0.3,0,3.1,0,3.1l0,0
                c0,0.2,0.1,0.4,0.3,0.5l0.6,0.4l0-1.7V370.5z"
                    />
                  </g>
                  <g id="XMLID_2311_">
                    <path
                      id="XMLID_2312_"
                      d="M506.8,374.4l-1.2-0.7c-0.3-0.2-0.5-0.5-0.5-0.8c0,0,0-0.1,0-2.1l0-1.2l0.2-0.2
                c0.1-0.1,0.3-0.1,0.4-0.1l1.5,0.4l-0.1,0.4c-0.1,0.2-0.1,0.4-0.2,0.5L506.8,374.4z M505.9,370.2l0,0.5c0,0.9,0,2,0,2.1
                c0,0,0,0.1,0.1,0.1l0-2.6c0,0,0,0,0-0.1L505.9,370.2z"
                    />
                  </g>
                </g>
                <g id="XMLID_2303_">
                  <g id="XMLID_2308_">
                    <path
                      id="XMLID_2309_"
                      style="fill: #ffffff"
                      d="M511.8,373.7l-6.1-3.5c-0.4-0.2-0.4-0.9,0-1.1l6.2-3.5
                c0.5-0.3,1.1-0.3,1.6,0l6.1,3.5c0.4,0.2,0.4,0.9,0,1.1l-6.2,3.5C512.9,374,512.3,374,511.8,373.7z"
                    />
                  </g>
                  <g id="XMLID_2304_">
                    <path
                      id="XMLID_2305_"
                      d="M512.6,374.3c-0.4,0-0.7-0.1-1-0.3l-6.1-3.5c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9
                l6.2-3.5c0.6-0.4,1.4-0.4,2,0l6.1,3.5c0.3,0.2,0.5,0.5,0.5,0.9c0,0.4-0.2,0.7-0.5,0.9l-6.2,3.5
                C513.3,374.2,513,374.3,512.6,374.3z M512.7,365.7c-0.2,0-0.4,0.1-0.6,0.2l-6.2,3.5c-0.1,0.1-0.1,0.2-0.1,0.2
                c0,0,0,0.1,0.1,0.2l6.1,3.5c0.4,0.2,0.8,0.2,1.2,0l6.2-3.5c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1-0.1-0.2l-6.1-3.5
                C513.1,365.8,512.9,365.7,512.7,365.7z"
                    />
                  </g>
                </g>
              </g>
              <g id="XMLID_248_">
                <g id="XMLID_1773_">
                  <g id="XMLID_1856_">
                    <g id="XMLID_1944_">
                      <g id="XMLID_2031_">
                        <g id="XMLID_2300_">
                          <rect
                            id="XMLID_2301_"
                            x="461.3"
                            y="394.1"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -132.8431 274.4276)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2298_">
                          <rect
                            id="XMLID_2299_"
                            x="462.5"
                            y="394.7"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -132.9944 275.0436)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2296_">
                          <rect
                            id="XMLID_2297_"
                            x="463.6"
                            y="395.4"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -133.1931 275.6713)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2294_">
                          <rect
                            id="XMLID_2295_"
                            x="464.9"
                            y="396.1"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.3573 276.4096)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2292_">
                          <rect
                            id="XMLID_2293_"
                            x="465.9"
                            y="396.8"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.5788 277.0108)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2290_">
                          <rect
                            id="XMLID_2291_"
                            x="467.2"
                            y="397.5"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -133.73 277.6263)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2288_">
                          <rect
                            id="XMLID_2289_"
                            x="468.3"
                            y="398.2"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.941 278.3159)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2286_">
                          <rect
                            id="XMLID_2287_"
                            x="469.5"
                            y="398.8"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -134.0949 279.0031)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2284_">
                          <rect
                            id="XMLID_2285_"
                            x="470.6"
                            y="399.5"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -134.2937 279.6306)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2282_">
                          <rect
                            id="XMLID_2283_"
                            x="471.9"
                            y="400.3"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -134.5686 280.1947)"
                            style="fill: #ffffff"
                            width="6.1"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2280_">
                          <rect
                            id="XMLID_2281_"
                            x="472.6"
                            y="399.4"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.6072 282.0459)"
                            style="fill: #ffffff"
                            width="12.9"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2278_">
                          <rect
                            id="XMLID_2279_"
                            x="473.9"
                            y="400"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -133.7491 282.6706)"
                            style="fill: #ffffff"
                            width="12.9"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2276_">
                          <rect
                            id="XMLID_2277_"
                            x="475.7"
                            y="400.7"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.9461 283.3603)"
                            style="fill: #ffffff"
                            width="11.5"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2274_">
                          <rect
                            id="XMLID_2275_"
                            x="477.6"
                            y="401.3"
                            transform="matrix(0.8738 -0.4863 0.4863 0.8738 -134.3325 285.4781)"
                            style="fill: #ffffff"
                            width="10.4"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2272_">
                          <rect
                            id="XMLID_2273_"
                            x="442.5"
                            y="383.3"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -129.9596 263.9258)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2237_">
                          <rect
                            id="XMLID_2271_"
                            x="443.8"
                            y="384"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -130.1353 264.6621)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2225_">
                          <rect
                            id="XMLID_2230_"
                            x="444.9"
                            y="384.7"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -130.3341 265.2896)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2216_">
                          <rect
                            id="XMLID_2221_"
                            x="446.1"
                            y="385.3"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -130.4759 265.9177)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2206_">
                          <rect
                            id="XMLID_2210_"
                            x="447.2"
                            y="386"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -130.6952 266.5085)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2178_">
                          <rect
                            id="XMLID_2203_"
                            x="448.4"
                            y="386.7"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -130.8689 267.2359)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2171_">
                          <rect
                            id="XMLID_2175_"
                            x="449.5"
                            y="387.4"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -131.0717 267.8824)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2164_">
                          <rect
                            id="XMLID_2167_"
                            x="450.8"
                            y="388"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -131.2114 268.5003)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2158_">
                          <rect
                            id="XMLID_2161_"
                            x="451.9"
                            y="388.7"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -131.4101 269.128)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2150_">
                          <rect
                            id="XMLID_2154_"
                            x="453.1"
                            y="389.4"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -131.5824 269.8749)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2115_">
                          <rect
                            id="XMLID_2147_"
                            x="454.2"
                            y="390.1"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -131.769 270.4422)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2108_">
                          <rect
                            id="XMLID_2112_"
                            x="455.5"
                            y="390.7"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -131.9351 271.1899)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2102_">
                          <rect
                            id="XMLID_2105_"
                            x="456.5"
                            y="391.4"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -132.1545 271.7806)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2095_">
                          <rect
                            id="XMLID_2099_"
                            x="457.8"
                            y="392.1"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -132.3058 272.3975)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2088_">
                          <rect
                            id="XMLID_2092_"
                            x="458.9"
                            y="392.8"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -132.5046 273.0249)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2081_">
                          <rect
                            id="XMLID_2084_"
                            x="460.1"
                            y="393.4"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -132.6707 273.7732)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2074_">
                          <rect
                            id="XMLID_2078_"
                            x="461.2"
                            y="394.1"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -132.8675 274.3906)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2068_">
                          <rect
                            id="XMLID_2071_"
                            x="429.9"
                            y="375"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -127.698 256.225)"
                            style="fill: #ffffff"
                            width="4.3"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2052_">
                          <rect
                            id="XMLID_2065_"
                            x="430.2"
                            y="375.7"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -127.8881 256.8041)"
                            style="fill: #ffffff"
                            width="5.9"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2050_">
                          <rect
                            id="XMLID_2051_"
                            x="430.9"
                            y="376.5"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -128.0981 257.4198)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2048_">
                          <rect
                            id="XMLID_2049_"
                            x="432.1"
                            y="377.1"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -128.2642 258.1645)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2046_">
                          <rect
                            id="XMLID_2047_"
                            x="433.2"
                            y="377.8"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -128.4629 258.7923)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2044_">
                          <rect
                            id="XMLID_2045_"
                            x="434.5"
                            y="378.4"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -128.623 259.4793)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2042_">
                          <rect
                            id="XMLID_2043_"
                            x="435.6"
                            y="379.1"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -128.8218 260.1068)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2040_">
                          <rect
                            id="XMLID_2041_"
                            x="436.9"
                            y="379.8"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -128.9738 260.7852)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2038_">
                          <rect
                            id="XMLID_2039_"
                            x="437.9"
                            y="380.5"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -129.1827 261.3266)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2036_">
                          <rect
                            id="XMLID_2037_"
                            x="439.1"
                            y="381.2"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -129.3583 262.0625)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2034_">
                          <rect
                            id="XMLID_2035_"
                            x="440.2"
                            y="381.9"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -129.5573 262.69)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2032_">
                          <rect
                            id="XMLID_2033_"
                            x="441.5"
                            y="382.5"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -129.6991 263.3188)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                      </g>
                      <g id="XMLID_1945_">
                        <g id="XMLID_2028_">
                          <rect
                            id="XMLID_2029_"
                            x="492.1"
                            y="400.9"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -132.2859 290.1954)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2026_">
                          <rect
                            id="XMLID_2027_"
                            x="493.3"
                            y="401.5"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -132.438 290.8084)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2024_">
                          <rect
                            id="XMLID_2025_"
                            x="494.4"
                            y="402.2"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -132.6367 291.4361)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2022_">
                          <rect
                            id="XMLID_2023_"
                            x="495.7"
                            y="402.9"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -132.7999 292.176)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2020_">
                          <rect
                            id="XMLID_2021_"
                            x="496.7"
                            y="403.6"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -133.01 292.714)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2018_">
                          <rect
                            id="XMLID_2019_"
                            x="498"
                            y="404.3"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.185 293.456)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2016_">
                          <rect
                            id="XMLID_2017_"
                            x="499.1"
                            y="405"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.3837 294.0837)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2014_">
                          <rect
                            id="XMLID_2015_"
                            x="500.3"
                            y="405.6"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.5376 294.771)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2012_">
                          <rect
                            id="XMLID_2013_"
                            x="501.4"
                            y="406.3"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.7364 295.3985)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2010_">
                          <rect
                            id="XMLID_2011_"
                            x="502.7"
                            y="407"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -133.8985 296.0979)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2008_">
                          <rect
                            id="XMLID_2009_"
                            x="503.8"
                            y="407.7"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -134.0952 296.7133)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2006_">
                          <rect
                            id="XMLID_2007_"
                            x="505.1"
                            y="408.3"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -134.2378 297.3349)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2004_">
                          <rect
                            id="XMLID_2005_"
                            x="506.9"
                            y="409"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -134.4341 298.0276)"
                            style="fill: #ffffff"
                            width="5.4"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2002_">
                          <rect
                            id="XMLID_2003_"
                            x="508.8"
                            y="409.8"
                            transform="matrix(0.8691 -0.4945 0.4945 0.8691 -135.9966 306.1232)"
                            style="fill: #ffffff"
                            width="3.3"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_2000_">
                          <rect
                            id="XMLID_2001_"
                            x="473.3"
                            y="390.1"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -129.4032 279.6905)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1998_">
                          <rect
                            id="XMLID_1999_"
                            x="474.6"
                            y="390.8"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -129.578 280.43)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1996_">
                          <rect
                            id="XMLID_1997_"
                            x="475.7"
                            y="391.5"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -129.7768 281.0575)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1994_">
                          <rect
                            id="XMLID_1995_"
                            x="476.9"
                            y="392.1"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -129.9195 281.6824)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1992_">
                          <rect
                            id="XMLID_1993_"
                            x="478"
                            y="392.8"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -130.1389 282.2732)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1990_">
                          <rect
                            id="XMLID_1991_"
                            x="479.2"
                            y="393.5"
                            transform="matrix(0.875 -0.4842 0.4842 0.875 -130.2932 282.9001)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1988_">
                          <rect
                            id="XMLID_1989_"
                            x="480.3"
                            y="394.2"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -130.5144 283.6517)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1986_">
                          <rect
                            id="XMLID_1987_"
                            x="484.4"
                            y="395.1"
                            transform="matrix(0.8777 -0.4792 0.4792 0.8777 -129.8821 281.6158)"
                            style="fill: #ffffff"
                            width="5"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1984_">
                          <rect
                            id="XMLID_1985_"
                            x="485.6"
                            y="395.7"
                            transform="matrix(0.8777 -0.4792 0.4792 0.8777 -130.0393 282.2947)"
                            style="fill: #ffffff"
                            width="4.9"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1982_">
                          <rect
                            id="XMLID_1983_"
                            x="486.1"
                            y="396.6"
                            transform="matrix(0.8794 -0.4761 0.4761 0.8794 -129.9488 280.6559)"
                            style="fill: #ffffff"
                            width="5.6"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1980_">
                          <polygon
                            id="XMLID_1981_"
                            style="fill: #ffffff"
                            points="487.5,399.2 487.3,398.8 492.5,395.9 492.8,396.3                     "
                          />
                        </g>
                        <g id="XMLID_1978_">
                          <rect
                            id="XMLID_1979_"
                            x="487.3"
                            y="398.2"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -131.5972 287.5484)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1976_">
                          <rect
                            id="XMLID_1977_"
                            x="488.5"
                            y="398.9"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -131.7494 288.1623)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1974_">
                          <rect
                            id="XMLID_1975_"
                            x="489.7"
                            y="399.6"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -131.9482 288.7897)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1972_">
                          <rect
                            id="XMLID_1973_"
                            x="490.9"
                            y="400.2"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -132.1134 289.541)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1970_">
                          <rect
                            id="XMLID_1971_"
                            x="492"
                            y="400.9"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -132.3102 290.157)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1968_">
                          <rect
                            id="XMLID_1969_"
                            x="460.7"
                            y="381.8"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -127.1407 271.9928)"
                            style="fill: #ffffff"
                            width="4.3"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1966_">
                          <rect
                            id="XMLID_1967_"
                            x="461"
                            y="382.5"
                            transform="matrix(0.875 -0.4841 0.4841 0.875 -127.3113 272.4599)"
                            style="fill: #ffffff"
                            width="5.9"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1964_">
                          <rect
                            id="XMLID_1965_"
                            x="461.7"
                            y="383.3"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -127.5417 273.1846)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1962_">
                          <rect
                            id="XMLID_1963_"
                            x="462.9"
                            y="383.9"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -127.7069 273.9323)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1960_">
                          <rect
                            id="XMLID_1961_"
                            x="464"
                            y="384.6"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -127.9056 274.5602)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1958_">
                          <rect
                            id="XMLID_1959_"
                            x="465.3"
                            y="385.3"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -128.0657 275.2471)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1956_">
                          <rect
                            id="XMLID_1957_"
                            x="466.4"
                            y="385.9"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -128.2645 275.8747)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1954_">
                          <rect
                            id="XMLID_1955_"
                            x="467.6"
                            y="386.6"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -128.4184 276.5622)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1952_">
                          <rect
                            id="XMLID_1953_"
                            x="468.7"
                            y="387.3"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -128.6263 277.0914)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1950_">
                          <rect
                            id="XMLID_1951_"
                            x="469.9"
                            y="388"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -128.8011 277.8303)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1948_">
                          <rect
                            id="XMLID_1949_"
                            x="471"
                            y="388.7"
                            transform="matrix(0.8749 -0.4844 0.4844 0.8749 -129 278.4579)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                        <g id="XMLID_1946_">
                          <rect
                            id="XMLID_1947_"
                            x="472.3"
                            y="389.3"
                            transform="matrix(0.8749 -0.4843 0.4843 0.8749 -129.1427 279.0836)"
                            style="fill: #ffffff"
                            width="6.8"
                            height="0.5"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_1857_">
                      <g id="XMLID_1942_">
                        <rect
                          id="XMLID_1943_"
                          x="443.7"
                          y="371.8"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -124.2265 263.1304)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1940_">
                        <rect
                          id="XMLID_1941_"
                          x="445"
                          y="372.4"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -124.39 263.8077)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1938_">
                        <rect
                          id="XMLID_1939_"
                          x="446.1"
                          y="373.1"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -124.5888 264.4352)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1936_">
                        <rect
                          id="XMLID_1937_"
                          x="447.3"
                          y="373.8"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -124.7427 265.1227)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1934_">
                        <rect
                          id="XMLID_1935_"
                          x="448.4"
                          y="374.5"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -124.9509 265.6548)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1932_">
                        <rect
                          id="XMLID_1933_"
                          x="449.6"
                          y="375.1"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -125.1256 266.3909)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1930_">
                        <rect
                          id="XMLID_1931_"
                          x="450.7"
                          y="375.8"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -125.3226 267.0089)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1928_">
                        <rect
                          id="XMLID_1929_"
                          x="452"
                          y="376.5"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -125.4654 267.6371)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1926_">
                        <rect
                          id="XMLID_1927_"
                          x="453.1"
                          y="377.2"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -125.6678 268.2836)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1924_">
                        <rect
                          id="XMLID_1925_"
                          x="454.3"
                          y="377.8"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -125.8372 269.0205)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1922_">
                        <rect
                          id="XMLID_1923_"
                          x="455.5"
                          y="378.5"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -126.0247 269.5885)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1920_">
                        <rect
                          id="XMLID_1921_"
                          x="456.7"
                          y="379.2"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -126.1898 270.3355)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1918_">
                        <rect
                          id="XMLID_1919_"
                          x="458.5"
                          y="379.8"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -126.373 270.9526)"
                          style="fill: #ffffff"
                          width="5.4"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1916_">
                        <rect
                          id="XMLID_1917_"
                          x="460.5"
                          y="380.7"
                          transform="matrix(0.8703 -0.4925 0.4925 0.8703 -127.6708 276.9924)"
                          style="fill: #ffffff"
                          width="3.3"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1914_">
                        <rect
                          id="XMLID_1915_"
                          x="425"
                          y="361"
                          transform="matrix(0.875 -0.4841 0.4841 0.875 -121.3224 252.5215)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1912_">
                        <rect
                          id="XMLID_1913_"
                          x="426.2"
                          y="361.6"
                          transform="matrix(0.875 -0.4842 0.4842 0.875 -121.4987 253.263)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1910_">
                        <rect
                          id="XMLID_1911_"
                          x="427.3"
                          y="362.3"
                          transform="matrix(0.875 -0.4842 0.4842 0.875 -121.6974 253.8906)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1908_">
                        <rect
                          id="XMLID_1909_"
                          x="428.6"
                          y="363"
                          transform="matrix(0.875 -0.4841 0.4841 0.875 -121.842 254.5295)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1906_">
                        <rect
                          id="XMLID_1907_"
                          x="429.6"
                          y="363.7"
                          transform="matrix(0.875 -0.4842 0.4842 0.875 -122.0707 255.1682)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1904_">
                        <rect
                          id="XMLID_1905_"
                          x="430.8"
                          y="364.4"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -122.2543 255.948)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1902_">
                        <rect
                          id="XMLID_1903_"
                          x="431.9"
                          y="365.1"
                          transform="matrix(0.875 -0.4842 0.4842 0.875 -122.433 256.4728)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1900_">
                        <rect
                          id="XMLID_1901_"
                          x="433.2"
                          y="365.7"
                          transform="matrix(0.875 -0.4841 0.4841 0.875 -122.5758 257.1027)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1898_">
                        <rect
                          id="XMLID_1899_"
                          x="434.3"
                          y="366.4"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -122.8058 257.8905)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1896_">
                        <rect
                          id="XMLID_1897_"
                          x="435.6"
                          y="367"
                          transform="matrix(0.875 -0.4841 0.4841 0.875 -122.9346 258.4167)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1894_">
                        <rect
                          id="XMLID_1895_"
                          x="436.7"
                          y="367.7"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -123.1535 259.1477)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1892_">
                        <rect
                          id="XMLID_1893_"
                          x="437.9"
                          y="368.4"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -123.3185 259.8925)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1890_">
                        <rect
                          id="XMLID_1891_"
                          x="439"
                          y="369.1"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -123.5267 260.4256)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1888_">
                        <rect
                          id="XMLID_1889_"
                          x="440.2"
                          y="369.8"
                          transform="matrix(0.875 -0.4842 0.4842 0.875 -123.6812 261.0563)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1886_">
                        <rect
                          id="XMLID_1887_"
                          x="441.3"
                          y="370.5"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -123.9001 261.7885)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1884_">
                        <rect
                          id="XMLID_1885_"
                          x="442.6"
                          y="371.1"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -124.0541 262.4757)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1882_">
                        <rect
                          id="XMLID_1883_"
                          x="443.7"
                          y="371.8"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -124.2528 263.1035)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1880_">
                        <rect
                          id="XMLID_1881_"
                          x="412.4"
                          y="352.7"
                          transform="matrix(0.875 -0.4841 0.4841 0.875 -119.0519 244.7825)"
                          style="fill: #ffffff"
                          width="4.3"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1878_">
                        <rect
                          id="XMLID_1879_"
                          x="412.6"
                          y="353.4"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -119.2724 245.5096)"
                          style="fill: #ffffff"
                          width="5.9"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1876_">
                        <rect
                          id="XMLID_1877_"
                          x="413.3"
                          y="354.1"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -119.484 246.1331)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1874_">
                        <rect
                          id="XMLID_1875_"
                          x="414.6"
                          y="354.8"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -119.6475 246.8673)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1872_">
                        <rect
                          id="XMLID_1873_"
                          x="415.7"
                          y="355.5"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -119.8464 247.4949)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1870_">
                        <rect
                          id="XMLID_1871_"
                          x="416.9"
                          y="356.1"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -120.0062 248.1821)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1868_">
                        <rect
                          id="XMLID_1869_"
                          x="418"
                          y="356.8"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -120.2051 248.8096)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1866_">
                        <rect
                          id="XMLID_1867_"
                          x="419.3"
                          y="357.5"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -120.348 249.4414)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1864_">
                        <rect
                          id="XMLID_1865_"
                          x="420.3"
                          y="358.2"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -120.5784 250.0878)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1862_">
                        <rect
                          id="XMLID_1863_"
                          x="421.6"
                          y="358.8"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -120.7418 250.7653)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1860_">
                        <rect
                          id="XMLID_1861_"
                          x="422.7"
                          y="359.5"
                          transform="matrix(0.8749 -0.4844 0.4844 0.8749 -120.9407 251.3929)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1858_">
                        <rect
                          id="XMLID_1859_"
                          x="423.9"
                          y="360.2"
                          transform="matrix(0.8749 -0.4843 0.4843 0.8749 -121.0819 252.0158)"
                          style="fill: #ffffff"
                          width="6.8"
                          height="0.5"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_1774_">
                    <g id="XMLID_1775_">
                      <g id="XMLID_1832_">
                        <g id="XMLID_1849_">
                          <g id="XMLID_1854_">
                            <path
                              id="XMLID_1855_"
                              d="M428.1,366.2l4.2,0.1c0.1,0,0.1,0,0.2-0.1l15.9-9.2c1-0.6,2.5-0.6,3.3-0.1l36.1,20.9
                        c0,0,0,0,0.1,0l12,4c0.2,0.1,0.3,0.2,0.3,0.4l0,3c0.1,0.4-0.2,0.9-0.8,1.3L479.5,398c-1,0.6-2.5,0.6-3.3,0.1l-47.9-27.7
                        c-0.4-0.2-0.6-0.5-0.6-0.8l0-3C427.7,366.4,427.8,366.2,428.1,366.2z"
                            />
                          </g>
                          <g id="XMLID_1850_">
                            <path
                              id="XMLID_1851_"
                              d="M476,398.4L428,370.8c-0.5-0.3-0.8-0.7-0.8-1.2v-3c0-0.2,0.1-0.4,0.2-0.6
                        c0.2-0.1,0.3-0.2,0.6-0.2l4.2,0.1l15.9-9.2c1.1-0.6,2.7-0.7,3.7-0.1l36.1,20.9l12,4c0.3,0.1,0.5,0.4,0.5,0.7l0,3
                        c0.1,0.6-0.2,1.2-1,1.6l-19.9,11.5c-0.6,0.3-1.3,0.5-2.1,0.5C477,398.8,476.4,398.7,476,398.4z M448.6,357.4l-15.9,9.2
                        c-0.1,0.1-0.2,0.1-0.4,0.1l-4.2-0.1l0-0.4l0,3.4c0,0.2,0.2,0.4,0.4,0.5l47.9,27.7c0.7,0.4,2.1,0.4,2.9-0.1l19.9-11.5
                        c0.4-0.3,0.7-0.6,0.6-0.8l0-3.1l-12-4c0,0-0.1,0-0.1-0.1l-36.1-20.9c-0.3-0.2-0.8-0.3-1.2-0.3
                        C449.7,357,449,357.1,448.6,357.4z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1842_">
                          <g id="XMLID_1847_">
                            <path
                              id="XMLID_1848_"
                              style="fill: #ffffff"
                              d="M428.2,367.1l47.9,27.7c0.8,0.5,2.3,0.4,3.3-0.1l19.9-11.5
                        c1-0.6,1.1-1.4,0.2-1.9l-47.9-27.7c-0.8-0.5-2.3-0.4-3.3,0.1l-19.9,11.5C427.5,365.8,427.4,366.6,428.2,367.1z"
                            />
                          </g>
                          <g id="XMLID_1843_">
                            <path
                              id="XMLID_1844_"
                              d="M476,395.1L428,367.5c-0.5-0.3-0.8-0.7-0.8-1.2c0-0.5,0.4-1,1-1.4l19.9-11.5
                        c1.1-0.6,2.7-0.7,3.7-0.1l47.9,27.7c0.5,0.3,0.8,0.7,0.8,1.2c0,0.5-0.4,1-1,1.4L479.6,395c-0.6,0.3-1.3,0.5-2.1,0.5
                        C477,395.5,476.4,395.4,476,395.1z M448.6,354.1l-19.9,11.5c-0.4,0.2-0.6,0.5-0.6,0.7c0,0.2,0.2,0.4,0.4,0.5l47.9,27.7
                        c0.7,0.4,2.1,0.4,2.9-0.1l19.9-11.5c0.4-0.2,0.6-0.5,0.6-0.7c0-0.2-0.2-0.4-0.4-0.5l-47.9-27.7c-0.3-0.2-0.8-0.3-1.2-0.3
                        C449.7,353.6,449,353.8,448.6,354.1z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1839_">
                          <g id="XMLID_1840_">
                            <rect
                              id="XMLID_1841_"
                              x="477.3"
                              y="395.4"
                              style="fill: #ffffff"
                              width="0.6"
                              height="2.8"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1836_">
                          <g id="XMLID_1837_">
                            <rect
                              id="XMLID_1838_"
                              x="497.7"
                              y="384"
                              style="fill: #ffffff"
                              width="0.6"
                              height="2.8"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1833_">
                          <g id="XMLID_1834_">
                            <rect
                              id="XMLID_1835_"
                              x="429.5"
                              y="368.1"
                              style="fill: #ffffff"
                              width="0.6"
                              height="2.8"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_1825_">
                        <g id="XMLID_1830_">
                          <path
                            id="XMLID_1831_"
                            style="fill: #ffffff"
                            d="M407.9,257.5c0-0.1,0-0.1,0-0.2l3.6-2c0.2-0.1,0.5-0.2,0.7,0l107.5,62
                      c0.6,0.3,1.1,1.2,1.1,2v82.2c0,0.4-0.1,0.7-0.3,0.8l-3.7,2.1l-3.7-4.5l1.5-0.6l-102.2-59c-0.6-0.3-1.1-1.2-1.1-2V262
                      L407.9,257.5z"
                          />
                        </g>
                        <g id="XMLID_1826_">
                          <path
                            id="XMLID_1827_"
                            d="M512.3,399.9l1.2-0.5L412,340.8c-0.7-0.4-1.2-1.4-1.2-2.3v-76.3l-3.2-4.4
                      c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.2-0.3l3.5-1.9c0.3-0.3,0.8-0.3,1.2,0l107.5,62c0.7,0.4,1.2,1.4,1.2,2.3v82.2
                      c0,0.5-0.2,0.9-0.5,1.1l-4,2.2L512.3,399.9z M411.7,255.7l-3.3,1.8l3.2,4.3v76.6c0,0.6,0.4,1.4,0.9,1.7l102.8,59.4
                      l-1.7,0.7l3.2,3.9l3.4-1.9c0.1-0.1,0.2-0.2,0.2-0.5v-82.2c0-0.6-0.4-1.4-0.9-1.7l-107.5-62c-0.1,0-0.1-0.1-0.2-0.1
                      C411.8,255.7,411.7,255.7,411.7,255.7z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_1800_">
                        <g id="XMLID_1818_">
                          <g id="XMLID_1823_">
                            <path
                              id="XMLID_1824_"
                              d="M408.5,342.5l107.5,62c0.6,0.3,1.1,0,1.1-0.8v-82.2c0-0.8-0.5-1.7-1.1-2l-107.5-62
                        c-0.6-0.3-1.1,0-1.1,0.8v82.2C407.4,341.3,407.9,342.2,408.5,342.5z"
                            />
                          </g>
                          <g id="XMLID_1819_">
                            <path
                              id="XMLID_1820_"
                              d="M515.7,404.9l-107.5-62c-0.7-0.4-1.2-1.4-1.2-2.3v-82.2c0-0.5,0.2-1,0.6-1.2
                        c0.3-0.2,0.7-0.2,1.1,0.1l107.5,62c0.7,0.4,1.2,1.4,1.2,2.3v82.2c0,0.5-0.2,1-0.6,1.2c-0.1,0.1-0.3,0.1-0.5,0.1
                        C516.2,405.1,515.9,405,515.7,404.9z M408,257.8c-0.1,0-0.2,0.2-0.2,0.5v82.2c0,0.6,0.4,1.4,0.9,1.7l107.5,62
                        c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.2,0.2-0.5v-82.2c0-0.6-0.4-1.4-0.9-1.7l-107.5-62C408.2,257.8,408.1,257.8,408,257.8
                        C408,257.8,408,257.8,408,257.8z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1811_">
                          <g id="XMLID_1816_">
                            <path
                              id="XMLID_1817_"
                              style="fill: #ffffff"
                              d="M408.6,341l107.1,61.9c0.2,0.1,0.3,0,0.3-0.2v-81.5
                        c0-0.2-0.1-0.5-0.3-0.6l-107.1-61.9c-0.2-0.1-0.3,0-0.3,0.2v81.5C408.3,340.6,408.5,340.9,408.6,341z"
                            />
                          </g>
                          <g id="XMLID_1812_">
                            <path
                              id="XMLID_1813_"
                              d="M515.6,403.2l-107.1-61.9l0,0c-0.3-0.2-0.5-0.6-0.5-0.9v-81.5c0-0.3,0.1-0.5,0.3-0.6
                        c0.2-0.1,0.4-0.1,0.6,0L516,320.2c0.3,0.2,0.5,0.6,0.5,0.9v81.5c0,0.3-0.1,0.5-0.3,0.6c-0.1,0-0.2,0.1-0.3,0.1
                        C515.8,403.3,515.7,403.2,515.6,403.2z M408.7,340.4c0,0.1,0.1,0.2,0.1,0.3l106.9,61.7v-81.2c0-0.1-0.1-0.2-0.1-0.3
                        l-106.9-61.7V340.4z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1804_">
                          <g id="XMLID_1809_">
                            <path
                              id="XMLID_1810_"
                              d="M407.4,340.6v-4.7L517,399.2v4.7c0,0.7-0.5,1-1.1,0.7l-107.3-61.9
                        C407.9,342.2,407.4,341.3,407.4,340.6z"
                            />
                          </g>
                          <g id="XMLID_1805_">
                            <path
                              id="XMLID_1806_"
                              d="M515.7,404.8l-107.3-61.9c-0.7-0.4-1.3-1.4-1.3-2.3v-5.4l110.1,63.6l0.2,0.1v4.9
                        c0,0.5-0.2,0.9-0.5,1.1c-0.1,0.1-0.3,0.1-0.5,0.1C516.1,405,515.9,405,515.7,404.8z M407.8,336.6v4
                        c0,0.6,0.4,1.3,0.9,1.6L516,404.2c0.2,0.1,0.3,0.1,0.4,0.1c0.1-0.1,0.1-0.2,0.1-0.4v-4.5L407.8,336.6z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1801_">
                          <g id="XMLID_1802_">
                            <rect
                              id="XMLID_1803_"
                              x="461.9"
                              y="362.5"
                              transform="matrix(0.5003 -0.8658 0.8658 0.5003 -89.871 585.3361)"
                              style="fill: #ffffff"
                              width="0.6"
                              height="16"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_1797_">
                        <g id="XMLID_1798_">
                          <rect
                            id="XMLID_1799_"
                            x="516.7"
                            y="319"
                            transform="matrix(0.8574 -0.5147 0.5147 0.8574 -90.3626 312.6293)"
                            width="4.3"
                            height="0.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_1794_">
                        <g id="XMLID_1795_">
                          <rect
                            id="XMLID_1796_"
                            x="414.3"
                            y="259.5"
                            transform="matrix(0.8574 -0.5147 0.5147 0.8574 -74.3842 251.4017)"
                            width="4.3"
                            height="0.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_1791_">
                        <g id="XMLID_1792_">
                          <rect
                            id="XMLID_1793_"
                            x="516.7"
                            y="396.9"
                            transform="matrix(0.8574 -0.5147 0.5147 0.8574 -130.4561 323.704)"
                            width="4.3"
                            height="0.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_1788_">
                        <g id="XMLID_1789_">
                          <polygon
                            id="XMLID_1790_"
                            style="fill: #ffffff"
                            points="491.2,391 491.5,390.5 489.2,389.2 488.9,389.7                     "
                          />
                        </g>
                      </g>
                      <g id="XMLID_1785_">
                        <g id="XMLID_1786_">
                          <path
                            id="XMLID_1787_"
                            d="M477.8,393.4c0.4,0,0.8-0.1,1.1-0.3l4.2-2.6l-0.4-0.6l-4.2,2.6c-0.4,0.3-1,0.3-1.4,0l-41.3-23.5
                      l-0.4,0.7l41.3,23.5C477.1,393.3,477.4,393.4,477.8,393.4z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_1782_">
                        <g id="XMLID_1783_">
                          <path
                            id="XMLID_1784_"
                            d="M434.3,368.9l0.4-0.7l-3.1-1.7c-0.1-0.1-0.1-0.2-0.1-0.2c0,0,0-0.2,0.1-0.2l4.3-2.5l-0.4-0.7
                      l-4.3,2.5c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.4,0.2,0.7,0.5,0.9L434.3,368.9z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_1779_">
                        <g id="XMLID_1780_">
                          <rect
                            id="XMLID_1781_"
                            x="416.6"
                            y="260.9"
                            transform="matrix(0.8573 -0.5148 0.5148 0.8573 -74.7356 252.8516)"
                            width="4.3"
                            height="0.8"
                          />
                        </g>
                      </g>
                      <g id="XMLID_1776_">
                        <g id="XMLID_1777_">
                          <rect
                            id="XMLID_1778_"
                            x="516.9"
                            y="322.9"
                            transform="matrix(0.8574 -0.5147 0.5147 0.8574 -92.3501 313.2995)"
                            width="4.3"
                            height="0.8"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_1657_">
                  <g id="XMLID_1662_">
                    <g id="XMLID_1768_">
                      <path
                        id="XMLID_1772_"
                        style="fill: #ffffff"
                        d="M484.2,291.2l-3.9-2.2c-0.1-0.1-0.2-0.1-0.3,0l-3.1,1.9
                  c-0.1,0-0.1,0.1,0,0.2l3,3.2c0,0,0,0,0,0.1v1c0,0.3,0.2,0.6,0.4,0.8l1-1.2c0,0,0,0,0,0l-0.2,1.7c0,0.1,0.1,0.1,0.2,0
                  l3.1-1.9c0,0,0.1-0.2,0.1-0.3V292C484.6,291.7,484.4,291.3,484.2,291.2z"
                      />
                      <path
                        id="XMLID_1769_"
                        d="M481.3,297.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.2l-0.1-0.1l0.1-0.7l-0.5,0.6l-0.3-0.2
                  c-0.3-0.2-0.6-0.7-0.6-1.1v-0.9l-2.9-3.2c-0.1-0.2-0.1-0.6,0.2-0.7l3.1-1.9c0.2-0.1,0.4-0.1,0.7,0l3.9,2.2
                  c0.3,0.2,0.6,0.7,0.6,1.1v2.4c0,0.3-0.2,0.5-0.2,0.6l-0.1,0.1l-3.1,1.9C481.5,297.1,481.4,297.1,481.3,297.1z M481.2,294.4
                  l0.3,0.2c0.1,0,0.1,0.1,0.2,0.2l0.1,0.1l-0.1,1.2l2.5-1.6c0,0,0,0,0,0l0-2.4c0-0.2-0.1-0.4-0.2-0.4l-3.8-2.2l-2.7,1.7l2.8,3
                  c0,0.1,0.1,0.2,0.1,0.3v1c0,0.1,0,0.1,0,0.2L481.2,294.4z"
                      />
                    </g>
                    <g id="XMLID_1669_">
                      <path
                        id="XMLID_1767_"
                        d="M479,294.5c0,0,0,0.1,0,0.2l2.2,1.8c0.1,0.1,0.2,0.1,0.3,0.1l3-1.9c0,0,0.1-0.2,0.1-0.3v-2.3
                  c0-0.1-0.1-0.1-0.1-0.1l-3.1,1.9c0,0,0,0,0,0L479,294.5z"
                      />
                      <path
                        id="XMLID_1670_"
                        d="M481.3,297.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.2l-2.3-1.9l0.1-0.7l2.5-0.6l3.1-1.9
                  c0.1-0.1,0.3-0.1,0.5,0c0.2,0.1,0.2,0.2,0.2,0.4v2.3c0,0.3-0.2,0.5-0.2,0.6l-0.1,0.1l-3,1.9
                  C481.6,297,481.4,297.1,481.3,297.1z M479.7,294.8l1.7,1.4l2.8-1.8c0,0,0,0,0,0l0-1.7l-2.5,1.6l0,0.1L479.7,294.8z"
                      />
                    </g>
                    <g id="XMLID_1663_">
                      <path
                        id="XMLID_1668_"
                        style="fill: #ffffff"
                        d="M481,296.7l-3.9-2.2c-0.2-0.1-0.4-0.5-0.4-0.8v-2.4c0-0.3,0.2-0.4,0.4-0.3
                  l3.9,2.2c0.2,0.1,0.4,0.5,0.4,0.8v2.4C481.4,296.7,481.2,296.9,481,296.7z"
                      />
                      <path
                        id="XMLID_1664_"
                        d="M481.1,297.2c-0.1,0-0.2,0-0.4-0.1l-3.9-2.2c-0.3-0.2-0.6-0.7-0.6-1.1v-2.4c0-0.3,0.1-0.5,0.3-0.7
                  c0.2-0.1,0.4-0.1,0.7,0l3.9,2.2c0.3,0.2,0.6,0.7,0.6,1.1v2.4c0,0.3-0.1,0.5-0.3,0.7C481.3,297.1,481.2,297.2,481.1,297.2z
                   M481.2,296.4l-0.2,0.3L481.2,296.4C481.2,296.4,481.2,296.4,481.2,296.4z M477.1,291.4l0,2.3c0,0.2,0.1,0.4,0.2,0.4
                  l3.7,2.1l0-2.3c0-0.2-0.1-0.4-0.2-0.4L477.1,291.4z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_1660_">
                    <rect
                      id="XMLID_1661_"
                      x="478.6"
                      y="290.5"
                      transform="matrix(0.8487 -0.5288 0.5288 0.8487 -81.1704 298.0562)"
                      width="3.6"
                      height="0.8"
                    />
                  </g>
                  <g id="XMLID_1658_">
                    <rect
                      id="XMLID_1659_"
                      x="481.4"
                      y="294.3"
                      transform="matrix(0.8567 -0.5158 0.5158 0.8567 -82.7502 291.3169)"
                      style="fill: #ffffff"
                      width="3.2"
                      height="0.6"
                    />
                  </g>
                </g>
                <g id="XMLID_1635_">
                  <g id="XMLID_1640_">
                    <g id="XMLID_1652_">
                      <path
                        id="XMLID_1656_"
                        style="fill: #ffffff"
                        d="M511.7,307.1l-22.4-13c-0.1-0.1-0.2-0.1-0.3,0l-3.1,1.9
                  c-0.1,0-0.1,0.1,0,0.2l3,3.2c0,0,0,0,0,0.1v1c0,0.3,0.2,0.6,0.4,0.8l1-1.2c0,0,0,0,0,0l18.4,12.5c0,0.1,0.1,0.1,0.2,0
                  l3.1-1.9c0,0,0.1-0.2,0.1-0.3v-2.4C512.1,307.6,511.9,307.3,511.7,307.1z"
                      />
                      <path
                        id="XMLID_1653_"
                        d="M508.8,313c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1l-18.1-12.3l-1,1.2l-0.3-0.2
                  c-0.3-0.2-0.6-0.7-0.6-1.1v-0.9l-2.9-3.2c-0.1-0.2-0.1-0.6,0.2-0.7l3.1-1.9c0.2-0.1,0.4-0.1,0.7,0l22.4,13
                  c0.3,0.2,0.6,0.7,0.6,1.1v2.4c0,0.3-0.2,0.5-0.2,0.6l-0.1,0.1l-3.1,1.9C509,313,508.9,313,508.8,313z M490.6,299.8
                  l18.2,12.4l2.9-1.8c0,0,0,0,0,0l0-2.4c0-0.2-0.1-0.4-0.2-0.4l0,0l-22.4-13l-2.7,1.7l2.8,3c0,0.1,0.1,0.2,0.1,0.3v1
                  c0,0.1,0,0.1,0,0.2l0.9-1.1l0.3,0.2C490.5,299.7,490.6,299.8,490.6,299.8z"
                      />
                    </g>
                    <g id="XMLID_1647_">
                      <path
                        id="XMLID_1651_"
                        d="M491.4,301.7c0,0,15.1,8.9,15.1,9l2.2,1.8c0.1,0.1,0.2,0.1,0.3,0.1l3-1.9c0,0,0.1-0.2,0.1-0.3
                  v-2.3c0-0.1-0.1-0.1-0.1-0.1l-3.1,1.9c0,0,0,0-15.1-8.8L491.4,301.7z"
                      />
                      <path
                        id="XMLID_1648_"
                        d="M508.9,313c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.2l-2.2-1.8c-0.7-0.5-9.3-5.6-15-8.9l-0.9-0.5
                  l3.5-0.8l0.1,0.1c11.3,6.6,14.2,8.3,14.9,8.6l2.9-1.8c0.1-0.1,0.3-0.1,0.5,0c0.2,0.1,0.2,0.2,0.2,0.4v2.3
                  c0,0.3-0.2,0.5-0.2,0.6l-0.1,0.1l-3,1.9C509.1,312.9,509,313,508.9,313z M506.8,310.4l2.1,1.8l2.8-1.8c0,0,0,0,0,0l0-1.7
                  l-2.6,1.6c-0.2,0.1-0.2,0.1-2.3-1.1c-1.9-1.1-5.6-3.3-13.1-7.6l-1.3,0.3C503.6,308.3,506.1,309.9,506.8,310.4L506.8,310.4z"
                      />
                    </g>
                    <g id="XMLID_1641_">
                      <path
                        id="XMLID_1646_"
                        style="fill: #ffffff"
                        d="M508.5,312.6l-22.4-13c-0.2-0.1-0.4-0.5-0.4-0.8v-2.4
                  c0-0.3,0.2-0.4,0.4-0.3l22.4,13c0.2,0.1,0.4,0.5,0.4,0.8v2.4C508.9,312.6,508.7,312.8,508.5,312.6z"
                      />
                      <path
                        id="XMLID_1642_"
                        d="M508.7,313.1c-0.1,0-0.2,0-0.4-0.1l0,0l-22.4-13c-0.3-0.2-0.6-0.7-0.6-1.1v-2.4
                  c0-0.3,0.1-0.5,0.3-0.7c0.2-0.1,0.4-0.1,0.7,0l22.4,13c0.3,0.2,0.6,0.7,0.6,1.1v2.4c0,0.3-0.1,0.5-0.3,0.7
                  C508.9,313.1,508.8,313.1,508.7,313.1z M508.7,312.3l-0.2,0.3L508.7,312.3C508.7,312.3,508.7,312.3,508.7,312.3z
                   M486.1,296.6l0,2.3c0,0.2,0.1,0.4,0.2,0.4l22.2,12.9l0-2.3c0-0.2-0.1-0.4-0.2-0.4L486.1,296.6z"
                      />
                    </g>
                  </g>
                  <g id="XMLID_1638_">
                    <rect
                      id="XMLID_1639_"
                      x="487.6"
                      y="295.7"
                      transform="matrix(0.8487 -0.5288 0.5288 0.8487 -82.5273 303.5892)"
                      width="3.6"
                      height="0.8"
                    />
                  </g>
                  <g id="XMLID_1636_">
                    <rect
                      id="XMLID_1637_"
                      x="508.9"
                      y="310.2"
                      transform="matrix(0.8567 -0.5158 0.5158 0.8567 -87.0169 307.7933)"
                      style="fill: #ffffff"
                      width="3.2"
                      height="0.6"
                    />
                  </g>
                </g>
                <g id="XMLID_1590_">
                  <g id="XMLID_1613_">
                    <g id="XMLID_1618_">
                      <g id="XMLID_1630_">
                        <path
                          id="XMLID_1634_"
                          style="fill: #ffffff"
                          d="M409.9,284.5l-3-1.8c-0.2-0.1-0.3-0.1-0.5,0l0,0l0,0c0,0,0,0,0,0l-3,1.8
                    c-0.1,0-0.1,0.1-0.1,0.2l2.9,5c0,0,0,0,0,0.1v5.4c0,0.5,0.3,1.1,0.7,1.3l-2.7-1.5c0,0,0,0,0.1,0.1l2.8,5
                    c0,0.1,0.1,0.1,0.2,0.1l3-1.8c0.1-0.1,0.2-0.3,0.2-0.5v-11.9C410.6,285.3,410.3,284.7,409.9,284.5z"
                        />
                        <path
                          id="XMLID_1631_"
                          d="M407.3,300.4c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.3l-2.8-5c0,0,0.1,0.1,0.1,0.1l0.4-0.7
                    l1.5,0.8c0-0.1,0-0.2,0-0.3v-5.3l-2.8-4.9c-0.1-0.3-0.1-0.6,0.2-0.7l3-1.8c0.3-0.2,0.6-0.2,0.9,0l3,1.8
                    c0.5,0.3,0.9,1,0.9,1.7v11.9c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.8C407.5,300.4,407.4,300.4,407.3,300.4z M405.3,295.9l2.1,3.6
                    l2.8-1.7c0,0,0-0.1,0-0.2v-11.9c0-0.4-0.3-0.8-0.5-1l0,0l-3-1.8c0,0-0.1,0-0.1,0l0,0l-2.8,1.7l2.7,4.8
                    c0,0.1,0.1,0.2,0.1,0.3v5.4c0,0.4,0.3,0.8,0.5,1l-0.4,0.7L405.3,295.9z"
                        />
                      </g>
                      <g id="XMLID_1624_">
                        <path
                          id="XMLID_1629_"
                          d="M404.1,293.9c0,0,1.1-6.5,2.2-1l-2.1,2c0,0,0,0,0.1,0.1l2.8,4.9c0.1,0.1,0.2,0.1,0.3,0.1l3-1.8
                    c0.1-0.1,0.2-0.3,0.2-0.5v-11.8c0,0,0-0.1-0.1,0l-3.1,1.9c0,0,0,0,0,0L404.1,293.9z"
                        />
                        <path
                          id="XMLID_1625_"
                          d="M407.3,300.4c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.3l-2.9-5l-0.6,1.1l0.4-2.4
                    c0.4-2.3,0.9-3.5,1.6-3.5c0.1,0,0.1,0,0.2,0l1.5-2.9l0.1-0.1l3.4-2l0.2,0.1c0.1,0.1,0.2,0.2,0.2,0.4v11.8
                    c0,0.4-0.1,0.7-0.4,0.9l-3,1.8C407.5,300.4,407.4,300.4,407.3,300.4z M404.8,295l2.6,4.6l2.8-1.7c0,0,0-0.1,0-0.2v-11.2
                    l-2.5,1.5l-1.6,3c0.2,0.4,0.4,1,0.6,1.9l0,0.2L404.8,295z M405.7,291.8l-1.3,2.4l1.5-1.5
                    C405.8,292.4,405.7,292.1,405.7,291.8z"
                        />
                      </g>
                      <g id="XMLID_1619_">
                        <path
                          id="XMLID_1623_"
                          style="fill: #ffffff"
                          d="M406.7,300.1l-3-1.8c-0.4-0.2-0.7-0.8-0.7-1.3v-11.9
                    c0-0.5,0.3-0.7,0.7-0.5l3,1.8c0.4,0.2,0.7,0.8,0.7,1.3v11.9C407.4,300.1,407.1,300.3,406.7,300.1z"
                        />
                        <path
                          id="XMLID_1620_"
                          d="M407,300.6c-0.2,0-0.3,0-0.5-0.1l0,0l-3-1.8c-0.5-0.3-0.9-1-0.9-1.6v-11.9c0-0.4,0.2-0.7,0.4-0.9
                    c0.2-0.1,0.6-0.1,0.8,0l3,1.8c0.5,0.3,0.9,1,0.9,1.6v11.9c0,0.4-0.2,0.7-0.4,0.9C407.3,300.6,407.1,300.6,407,300.6z
                     M406.9,299.8C407,299.8,407,299.8,406.9,299.8L406.9,299.8c0.1,0,0.1,0,0.1-0.2v-11.9c0-0.4-0.3-0.8-0.5-1l-3-1.8
                    c0,0-0.1,0-0.1,0l0,0c0,0,0,0.1,0,0.2V297c0,0.4,0.3,0.8,0.5,1L406.9,299.8z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_1616_">
                      <rect
                        id="XMLID_1617_"
                        x="405.7"
                        y="284.4"
                        transform="matrix(0.8487 -0.5288 0.5288 0.8487 -88.9698 258.5668)"
                        width="3.6"
                        height="0.8"
                      />
                    </g>
                    <g id="XMLID_1614_">
                      <rect
                        id="XMLID_1615_"
                        x="407.5"
                        y="295"
                        transform="matrix(0.8567 -0.5159 0.5159 0.8567 -93.717 253.3508)"
                        style="fill: #ffffff"
                        width="3.2"
                        height="0.6"
                      />
                    </g>
                  </g>
                  <g id="XMLID_1591_">
                    <g id="XMLID_1596_">
                      <g id="XMLID_1608_">
                        <path
                          id="XMLID_1612_"
                          style="fill: #ffffff"
                          d="M410.1,271.8l-3-1.8c-0.2-0.1-0.3-0.1-0.5,0l0,0l0,0c0,0,0,0,0,0l-3,1.8
                    c-0.1,0-0.1,0.1-0.1,0.2l2.9,5c0,0,0,0,0,0.1v0.5c0,0.5,0.3,1.1,0.7,1.3l-2.7-1.5c0,0,0,0,0.1,0.1l2.8,5
                    c0,0.1,0.1,0.1,0.2,0.1l3-1.8c0.1-0.1,0.2-0.3,0.2-0.5v-7.1C410.8,272.6,410.5,272,410.1,271.8z"
                        />
                        <path
                          id="XMLID_1609_"
                          d="M407.4,283c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.3l-2.8-5c0,0,0.1,0.1,0.1,0.1l0.4-0.7l1.5,0.8
                    c0-0.1,0-0.2,0-0.3v-0.5l-2.8-4.9c-0.1-0.3-0.1-0.6,0.2-0.7l3-1.8c0.3-0.2,0.6-0.2,0.9,0l3,1.8c0.5,0.3,0.9,1,0.9,1.7v7.1
                    c0,0.4-0.1,0.7-0.4,0.9l-3.1,1.8C407.6,283,407.5,283,407.4,283z M405.5,278.5l2.1,3.6l2.8-1.7c0,0,0-0.1,0-0.2v-7.1
                    c0-0.4-0.3-0.8-0.5-1l0,0l-3-1.8c0,0-0.1,0-0.1,0l0,0L404,272l2.7,4.8c0,0.1,0.1,0.2,0.1,0.3v0.5c0,0.4,0.3,0.8,0.5,1
                    l-0.4,0.7L405.5,278.5z"
                        />
                      </g>
                      <g id="XMLID_1602_">
                        <path
                          id="XMLID_1607_"
                          d="M404.3,276.4c0,0,1.1-6.5,2.2-1l-2.1,2c0,0,0,0,0.1,0.1l2.8,4.9c0.1,0.1,0.2,0.1,0.3,0.1l3-1.8
                    c0.1-0.1,0.2-0.3,0.2-0.5v-7c0,0,0-0.1-0.1,0l-3.1,1.9c0,0,0,0,0,0L404.3,276.4z"
                        />
                        <path
                          id="XMLID_1603_"
                          d="M407.5,283c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.3l-2.8-4.9l-0.3-0.3l0.3-0.3l0.5-0.5
                    l-0.8,0.4l0.1-0.7c0.4-2.3,0.9-3.5,1.6-3.5c0,0,0,0,0,0c0.5,0,0.9,0.6,1.2,2.1l0.6-0.3l3.3-2l0.2,0.1
                    c0.1,0.1,0.2,0.2,0.2,0.4v7c0,0.4-0.1,0.7-0.4,0.9l-3,1.8C407.7,282.9,407.6,283,407.5,283z M404.9,277.5l2.6,4.6l2.8-1.7
                    c0,0,0-0.1,0-0.2v-6.4l-2.6,1.6l-1.1,0.5L404.9,277.5z M405.5,273.7c-0.2,0.3-0.5,1.1-0.7,2.1l1.2-0.5
                    C405.8,274.2,405.6,273.8,405.5,273.7z"
                        />
                      </g>
                      <g id="XMLID_1597_">
                        <path
                          id="XMLID_1601_"
                          style="fill: #ffffff"
                          d="M406.9,282.7l-3-1.8c-0.4-0.2-0.7-0.8-0.7-1.3v-7.1c0-0.5,0.3-0.7,0.7-0.5
                    l3,1.8c0.4,0.2,0.7,0.8,0.7,1.3v7.1C407.6,282.6,407.3,282.9,406.9,282.7z"
                        />
                        <path
                          id="XMLID_1598_"
                          d="M407.2,283.1c-0.2,0-0.3,0-0.5-0.1l0,0l-3-1.8c-0.5-0.3-0.9-1-0.9-1.6v-7.1
                    c0-0.4,0.2-0.7,0.4-0.9c0.2-0.1,0.6-0.1,0.8,0l3,1.8c0.5,0.3,0.9,1,0.9,1.6v7.1c0,0.4-0.2,0.7-0.4,0.9
                    C407.4,283.1,407.3,283.1,407.2,283.1z M407.1,282.3C407.1,282.3,407.1,282.4,407.1,282.3L407.1,282.3c0.1,0,0.1,0,0.1-0.2
                    V275c0-0.4-0.3-0.8-0.5-1l-3-1.8c0,0-0.1,0-0.1,0l0,0c0,0,0,0.1,0,0.2v7.1c0,0.4,0.3,0.8,0.5,1L407.1,282.3z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_1594_">
                      <rect
                        id="XMLID_1595_"
                        x="405.8"
                        y="271.8"
                        transform="matrix(0.8487 -0.5288 0.5288 0.8487 -82.259 256.7361)"
                        width="3.6"
                        height="0.8"
                      />
                    </g>
                    <g id="XMLID_1592_">
                      <rect
                        id="XMLID_1593_"
                        x="407.6"
                        y="277.6"
                        transform="matrix(0.8567 -0.5159 0.5159 0.8567 -84.686 250.9175)"
                        style="fill: #ffffff"
                        width="3.2"
                        height="0.6"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_870_">
                  <g id="XMLID_1542_">
                    <defs>
                      <path
                        id="XMLID_1544_"
                        d="M484.3,422.7c4.6,2.7,4.6,7,0,9.7c-4.6,2.7-12.2,2.7-16.8,0c-4.6-2.7-4.6-7,0-9.7
                  S479.6,420,484.3,422.7z"
                      />
                    </defs>
                    <clipPath id="XMLID_3_">
                      <use
                        xlink:href="#XMLID_1544_"
                        style="overflow: visible"
                      />
                    </clipPath>
                    <g id="XMLID_1545_" style="clip-path: url(#XMLID_3_)">
                      <g id="XMLID_1588_">
                        <rect
                          id="XMLID_1589_"
                          x="453.4"
                          y="421.9"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -148.1642 290.6834)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1586_">
                        <rect
                          id="XMLID_1587_"
                          x="454.6"
                          y="422.5"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -148.3272 291.3851)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1584_">
                        <rect
                          id="XMLID_1585_"
                          x="455.8"
                          y="423.2"
                          transform="matrix(0.8663 -0.4994 0.4994 0.8663 -148.5132 291.9704)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1582_">
                        <rect
                          id="XMLID_1583_"
                          x="457"
                          y="423.9"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -148.6799 292.7474)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1580_">
                        <rect
                          id="XMLID_1581_"
                          x="458"
                          y="424.6"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -148.9013 293.3602)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1578_">
                        <rect
                          id="XMLID_1579_"
                          x="459.3"
                          y="425.3"
                          transform="matrix(0.8663 -0.4994 0.4994 0.8663 -149.0503 293.9962)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1576_">
                        <rect
                          id="XMLID_1577_"
                          x="460.4"
                          y="426"
                          transform="matrix(0.8663 -0.4994 0.4994 0.8663 -149.2501 294.6466)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1574_">
                        <rect
                          id="XMLID_1575_"
                          x="461.6"
                          y="426.6"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -149.4169 295.4242)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1572_">
                        <rect
                          id="XMLID_1573_"
                          x="462.8"
                          y="427.3"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -149.6166 296.0749)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1570_">
                        <rect
                          id="XMLID_1571_"
                          x="464"
                          y="428"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -149.7759 296.7864)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1568_">
                        <rect
                          id="XMLID_1569_"
                          x="465.1"
                          y="428.6"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -149.9757 297.4368)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1566_">
                        <rect
                          id="XMLID_1567_"
                          x="466.4"
                          y="429.3"
                          transform="matrix(0.8663 -0.4994 0.4994 0.8663 -150.1148 298.0822)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1564_">
                        <rect
                          id="XMLID_1565_"
                          x="467.4"
                          y="430"
                          transform="matrix(0.8663 -0.4994 0.4994 0.8663 -150.3362 298.6947)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1562_">
                        <rect
                          id="XMLID_1563_"
                          x="468.6"
                          y="430.7"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -150.5129 299.4632)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1560_">
                        <rect
                          id="XMLID_1561_"
                          x="469.7"
                          y="431.4"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -150.7127 300.1136)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1558_">
                        <rect
                          id="XMLID_1559_"
                          x="471"
                          y="432"
                          transform="matrix(0.8663 -0.4994 0.4994 0.8663 -150.8519 300.7584)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1556_">
                        <rect
                          id="XMLID_1557_"
                          x="472.1"
                          y="432.7"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -151.0656 301.4761)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1554_">
                        <rect
                          id="XMLID_1555_"
                          x="448.6"
                          y="419.2"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -147.4763 287.9413)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1552_">
                        <rect
                          id="XMLID_1553_"
                          x="449.9"
                          y="419.9"
                          transform="matrix(0.8663 -0.4994 0.4994 0.8663 -147.6253 288.5785)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1550_">
                        <rect
                          id="XMLID_1551_"
                          x="451"
                          y="420.6"
                          transform="matrix(0.8663 -0.4994 0.4994 0.8663 -147.8251 289.2289)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1548_">
                        <rect
                          id="XMLID_1549_"
                          x="452.2"
                          y="421.2"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -147.9919 290.0055)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                      <g id="XMLID_1546_">
                        <rect
                          id="XMLID_1547_"
                          x="453.3"
                          y="421.9"
                          transform="matrix(0.8663 -0.4995 0.4995 0.8663 -148.1917 290.6559)"
                          style="fill: #ffffff"
                          width="31"
                          height="0.5"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_871_">
                    <g id="XMLID_1537_">
                      <path
                        id="XMLID_1541_"
                        d="M473.8,343.3c-0.9,0.9-1.7,1.9-2.1,3.1c-0.4,1.4-0.5,3.3,0.6,5.1c2.1,3.1,4.9,2.3,5.5,2
                  c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.6-0.3-2.6,0.8-3.9c1-1.2,2.3-1.2,2.6-1.1c0.1,0,0.1,0,0.1-0.1c0.4-0.4,2.4-2.4,1-4.7
                  c-1.5-2.5-5.1-3.8-8.1-2.6c-0.1,0-0.1,0.2,0,0.2c0.3,0.1,0.8,0.5,0.3,1.2C474.5,342.6,474.2,343,473.8,343.3z"
                      />
                      <path
                        id="XMLID_1538_"
                        d="M476.3,354.2c-1.2,0-2.9-0.5-4.2-2.5c-1-1.6-1.3-3.4-0.7-5.4c0.4-1.2,1.1-2.3,2.2-3.3l0,0
                  c0.4-0.4,0.7-0.7,1-1c0.1-0.2,0.2-0.3,0.2-0.4c0-0.1-0.1-0.2-0.3-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.4
                  c3-1.2,7,0.1,8.6,2.8c1.5,2.6-0.6,4.8-1.1,5.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.9,0-1.7,0.3-2.3,1c-1,1.3-0.7,3.5-0.7,3.6
                  c0,0.3-0.1,0.5-0.4,0.6C477.9,353.9,477.2,354.2,476.3,354.2z M474.1,343.6c-1,0.9-1.6,1.9-1.9,3c-0.5,1.8-0.3,3.3,0.6,4.7
                  c1.8,2.7,4.1,2.2,4.9,1.9c-0.1-0.6-0.2-2.7,0.9-4.1c0.7-0.9,1.7-1.3,2.9-1.3c0.5-0.5,2.1-2.2,0.9-4.2
                  c-1.3-2.2-4.5-3.4-7.1-2.6c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.3,0,0.7-0.3,1C474.9,342.8,474.5,343.2,474.1,343.6L474.1,343.6z"
                      />
                    </g>
                    <g id="XMLID_1106_">
                      <g id="XMLID_1107_">
                        <g id="XMLID_1477_">
                          <path
                            id="XMLID_1481_"
                            style="fill: #ffffff"
                            d="M468.1,355.8c-0.5,1.8-2.6,9-2.6,14.8c0,0.7,0.1,1.4,0.3,2.1
                      c0.8,2.8,2.3,12.2,2.3,12.2l6.9-7.4l-3.4-13.1l-3.2-8.5C468.3,355.7,468.1,355.7,468.1,355.8z"
                          />
                          <path
                            id="XMLID_1478_"
                            d="M467.8,385.7l-0.1-0.8c0-0.1-1.6-9.4-2.3-12.2c-0.2-0.7-0.3-1.5-0.3-2.2
                      c0.1-5.8,2-12.8,2.7-14.9l0,0c0.1-0.2,0.3-0.4,0.5-0.4c0.2,0,0.4,0.1,0.5,0.3l3.2,8.5l3.4,13.3L467.8,385.7z M468.2,356.6
                      c-0.8,2.6-2.4,8.8-2.4,13.9c0,0.7,0.1,1.3,0.3,2c0.6,2.4,1.8,9.1,2.2,11.5l6.2-6.7l-3.3-12.9L468.2,356.6z"
                          />
                        </g>
                        <g id="XMLID_1472_">
                          <path
                            id="XMLID_1476_"
                            d="M477.4,427.4c0,0-0.8,1.7-0.7,3.7c0,0.5,0.4,0.9,0.9,1.1l1.6,0.5c0.5,0.1,1,0,1.4-0.4l0.7-0.7
                      c0.4-0.4,0.8-0.6,1.3-0.7l0,0c0.4-0.1,0.7-0.2,1-0.4l3.1-2.3c0.4-0.3,0.6-0.9,0.2-1.4c-0.3-0.3-0.7-0.7-1.4-0.9
                      c-1.7-0.4-4.8,0.3-4.8,0.3L477.4,427.4z"
                          />
                          <path
                            id="XMLID_1473_"
                            d="M479.6,433.1c-0.2,0-0.3,0-0.5-0.1l-1.6-0.5c-0.6-0.2-1.1-0.8-1.1-1.4c-0.1-2.1,0.7-3.9,0.7-3.9
                      l0.1-0.2l3.5-1.2c0.2,0,3.2-0.7,5-0.3c0.7,0.2,1.3,0.5,1.7,1c0.2,0.3,0.3,0.6,0.2,1c-0.1,0.4-0.3,0.7-0.6,0.9l-3.1,2.3
                      c-0.3,0.2-0.7,0.4-1.2,0.5c-0.4,0.1-0.8,0.3-1.1,0.6l-0.7,0.7C480.6,432.9,480.1,433.1,479.6,433.1z M477.7,427.7
                      c-0.2,0.4-0.6,1.8-0.6,3.4c0,0.3,0.3,0.6,0.6,0.7l1.6,0.5c0.4,0.1,0.8,0,1-0.3l0.7-0.7c0.4-0.4,0.9-0.7,1.5-0.8
                      c0.3-0.1,0.6-0.2,0.8-0.4l3.1-2.3c0.1-0.1,0.2-0.2,0.3-0.4c0-0.1,0-0.3-0.1-0.4c-0.3-0.4-0.7-0.6-1.2-0.7
                      c-1.6-0.4-4.6,0.3-4.6,0.3L477.7,427.7z"
                          />
                        </g>
                        <g id="XMLID_1467_">
                          <path
                            id="XMLID_1471_"
                            d="M467.9,421.8c0,0-0.8,3.4-1.1,4.5c-0.2,0.5-0.1,1,0.1,1.5l0,0c0.1,0.3,0.4,0.5,0.7,0.5l1.9,0.4
                      c0.5,0.1,1-0.1,1.3-0.5l0,0c0.5-0.8,1.2-1.6,1.9-2.2l2-1.7c0,0,0.9-0.8,0.5-2c-0.2-0.6-1.3-0.8-2.4-0.9
                      c-1.1-0.1-2.1-0.1-2.1-0.1L467.9,421.8z"
                          />
                          <path
                            id="XMLID_1468_"
                            d="M469.8,429.2c-0.1,0-0.2,0-0.4,0l-1.9-0.4c-0.4-0.1-0.8-0.4-0.9-0.8c-0.3-0.6-0.3-1.2-0.1-1.8
                      c0.3-1,1.1-4.4,1.1-4.4l0.1-0.3l3.1-0.5c0.1,0,1.1,0,2.2,0.1c1.6,0.2,2.5,0.6,2.7,1.2c0.5,1.4-0.5,2.4-0.6,2.4l-2,1.7
                      c-0.8,0.7-1.4,1.6-1.9,2.2C470.9,428.9,470.4,429.2,469.8,429.2z M468.2,422.1c-0.2,0.8-0.8,3.4-1,4.2
                      c-0.1,0.4-0.1,0.8,0.1,1.2c0.1,0.2,0.2,0.3,0.4,0.3l1.9,0.4c0.3,0.1,0.7-0.1,0.9-0.4c0.5-0.7,1.1-1.6,2-2.3l2-1.7
                      c0,0,0.7-0.6,0.3-1.6c-0.1-0.2-0.7-0.6-2.1-0.7c-1-0.1-2-0.1-2-0.1L468.2,422.1z"
                          />
                        </g>
                        <g id="XMLID_1462_">
                          <path
                            id="XMLID_1466_"
                            style="fill: #ffffff"
                            d="M486.2,385.7c-1.5-1.4-5.2,1-7.7,3c-0.7-4.6-10.1-0.8-10.1-0.8
                      s1,12.4,0.6,15.7c-0.4,3.3-2,5.4-1.9,12.6c0.1,5.7,0,7.9,0,8.6c0,0.2,0.1,0.3,0.2,0.4c0.6,0.4,2.2,1.1,3.7,0
                      c0.1-0.1,0.2-0.2,0.2-0.3c0.2-1.5,1.8-11.7,2.8-14.1c0.8-1.8,1-3.9,1-5.3c0-0.9,0.2-1.8,0.5-2.7c0.2-0.8,0.5-2.8,0.7-3
                      c0.3,0.5,0.3,1.2,0.3,1.8c0.1,2,0.3,3.7,0.4,4.5c0.3,2.7-0.7,5.2-1,8.6c-0.3,3,0.7,11.5,1,13.6c0,0.3,0.2,0.6,0.5,0.7
                      c0.7,0.4,2.1,0.9,3.6,0.1c0.3-0.2,0.5-0.5,0.5-0.9c0-2.2,0.1-10.3,0.8-12.9c0.8-3,1-6.7,1-6.7s2.4-10.4,2.9-18.2
                      C486.3,389,486.8,386.2,486.2,385.7z"
                          />
                          <path
                            id="XMLID_1463_"
                            d="M479.3,429.9c-0.9,0-1.6-0.3-2.1-0.6c-0.4-0.2-0.6-0.6-0.7-1c-0.3-2.5-1.3-10.7-1-13.7
                      c0.1-1.3,0.3-2.5,0.6-3.6c0.3-1.8,0.6-3.3,0.4-4.9c-0.1-0.7-0.2-2.2-0.4-4.5c-0.1,0.5-0.2,1-0.3,1.3
                      c-0.3,0.8-0.4,1.7-0.4,2.6c-0.1,1.5-0.3,3.7-1,5.4c-1,2.2-2.5,12.1-2.8,14c0,0.2-0.2,0.4-0.3,0.6c-1.7,1.3-3.5,0.5-4.2,0
                      c-0.3-0.2-0.4-0.4-0.4-0.7c0-0.7,0.1-2.9,0-8.6c-0.1-4.8,0.7-7.4,1.2-9.5c0.3-1.1,0.6-2,0.7-3.1
                      c0.4-3.2-0.6-15.5-0.6-15.6l0-0.3l0.3-0.1c0.3-0.1,6.5-2.6,9.3-1c0.6,0.3,1,0.8,1.2,1.4c3.9-2.9,6.5-3.8,7.8-2.6l0,0
                      c0.5,0.5,0.4,1.9,0.2,3.9c0,0.4-0.1,0.8-0.1,1.2c-0.5,7.5-2.7,17.5-2.9,18.2c0,0.4-0.2,3.8-1,6.7c-0.5,2-0.8,7.9-0.8,12.8
                      c0,0.5-0.3,1-0.7,1.2C480.5,429.8,479.9,429.9,479.3,429.9z M476.4,399.3l0.2,0.3c0.3,0.6,0.3,1.2,0.4,1.8l0,0.2
                      c0.1,2.2,0.3,3.7,0.4,4.4c0.2,1.7-0.1,3.4-0.4,5.2c-0.2,1.1-0.4,2.3-0.5,3.5c-0.3,3,0.8,11.8,1,13.5
                      c0,0.2,0.1,0.4,0.3,0.5c0.6,0.4,1.9,0.8,3.2,0.1c0.2-0.1,0.3-0.3,0.3-0.6c0-2.4,0.1-10.4,0.8-13c0.8-2.9,1-6.5,1-6.6
                      l0-0.1c0-0.1,2.4-10.4,2.9-18.1c0-0.3,0.1-0.8,0.1-1.2c0.1-1,0.3-3,0-3.3c-1-0.9-3.6,0.2-7.2,3l-0.5,0.4l-0.1-0.7
                      c-0.1-0.7-0.4-1.2-1-1.5c-2.2-1.3-7.1,0.5-8.3,0.9c0.2,1.9,1,12.4,0.6,15.4c-0.1,1.1-0.4,2.2-0.7,3.2
                      c-0.6,2.2-1.3,4.6-1.2,9.3c0.1,5.7,0,7.9,0,8.6c0,0,0,0,0,0.1c0.5,0.3,1.9,1,3.3,0c0,0,0,0,0-0.1
                      c0.2-1.1,1.8-11.7,2.8-14.2c0.7-1.7,0.9-3.7,1-5.2c0-1,0.2-1.9,0.5-2.8c0.1-0.3,0.2-0.8,0.3-1.4c0.2-1.3,0.3-1.7,0.6-1.8
                      L476.4,399.3z"
                          />
                        </g>
                        <g id="XMLID_1396_">
                          <path
                            id="XMLID_1461_"
                            d="M472.4,353.1c0,0-2.3-1.4-3.7,1.1c-1.4,2.5-1,8.8-0.3,14.6c0.6,5.2-1.3,15.4-1.7,17.5
                      c0,0.2,0.1,0.4,0.3,0.5l2.9,1.4c3.3,1.6,7,2.4,10.7,2.4l0,0c1.3,0,2.6-0.3,3.8-0.9l0.3-0.1c1.4-0.7,2.6-1.9,3.2-3.3l0,0
                      c0,0-3.6-7.8-3.6-13.1c0-5.3,1.8-14.9-1.6-16.7c-3.3-1.8-6.4-0.6-6.4-0.6L472.4,353.1z"
                          />
                          <path
                            id="XMLID_1456_"
                            d="M480.4,391.1c-3.7,0-7.5-0.9-10.9-2.5l-2.9-1.5c-0.4-0.2-0.6-0.6-0.5-1c0.4-2,2.3-12.3,1.7-17.4
                      c-0.7-6.2-1.1-12.2,0.4-14.8c0.5-0.9,1.1-1.4,1.9-1.6c1.2-0.3,2.3,0.3,2.4,0.4l0,0l3.4,2.8c0.8-0.3,3.6-0.9,6.5,0.7
                      c2.8,1.5,2.4,7.4,2,12.7c-0.1,1.6-0.2,3.1-0.2,4.4c0,5.1,3.6,12.8,3.6,12.9l0.1,0.2l-0.1,0.2c-0.7,1.5-1.8,2.8-3.4,3.5
                      l-0.3,0.1C483.2,390.8,481.8,391.1,480.4,391.1z M470.9,353.1c-0.2,0-0.3,0-0.5,0.1c-0.6,0.1-1.1,0.6-1.4,1.2
                      c-1.4,2.4-0.9,8.6-0.3,14.4c0.6,5.2-1.3,15.6-1.7,17.6c0,0.1,0,0.1,0.1,0.1l2.9,1.4c3.3,1.6,6.9,2.4,10.5,2.4
                      c1.2,0,2.5-0.3,3.6-0.8l0.3-0.1c1.3-0.6,2.3-1.7,2.9-3c-0.5-1.2-3.6-8.1-3.6-13.1c0-1.3,0.1-2.8,0.2-4.5
                      c0.3-4.7,0.7-10.6-1.6-11.9c-3.1-1.7-6.1-0.6-6.1-0.6l-0.2,0.1l-3.7-3C472.1,353.4,471.6,353.1,470.9,353.1z"
                          />
                        </g>
                        <g id="XMLID_1390_">
                          <path
                            id="XMLID_1395_"
                            style="fill: #ffffff"
                            d="M473.4,353.5L473.4,353.5c-0.1,0.2,0,0.2,0.2,0.4
                      c0.7,0.7,2.8,2.2,6.5,1.1l0.1-2.7c0,0,0-0.1,0.1-0.1l1.9-0.7c0,0,1.6-2.2,1.2-6.9l-0.5-2l-4.2-0.3l-3.3,3.4l-0.6,3.5
                      C474.7,350.5,474,352.4,473.4,353.5z"
                          />
                          <path
                            id="XMLID_1391_"
                            d="M477.6,355.8c-2.3,0-3.7-1-4.3-1.6c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.5-0.4-0.2-0.8l0,0
                      c0.6-1,1.2-2.9,1.4-4l0.6-3.6l3.5-3.6l4.6,0.3l0.6,2.3c0.4,4.8-1.2,7.1-1.3,7.1l-0.1,0.1l-1.8,0.7l-0.1,2.7l-0.3,0.1
                      C479.2,355.6,478.4,355.8,477.6,355.8z M473.8,353.5C473.9,353.6,473.9,353.6,473.8,353.5c0.7,0.7,2.6,2,5.9,1.1l0.1-2.4
                      c0-0.2,0.1-0.4,0.3-0.4l1.8-0.6c0.3-0.5,1.4-2.6,1.1-6.5l-0.4-1.7l-3.7-0.3l-3.1,3.2l-0.5,3.4
                      C475.1,350.5,474.5,352.4,473.8,353.5z M473.4,353.5l0.3,0.2c0,0,0,0,0,0L473.4,353.5l0.3,0.2L473.4,353.5z"
                          />
                        </g>
                        <g id="XMLID_1346_">
                          <path
                            id="XMLID_1389_"
                            d="M474.1,353.6c-0.4-0.5-1.1-0.6-1.7-0.4c-0.2,0.1-0.5,0.1-0.7,0.4c-1.1,1.2,2.7,4.8,7.6,3.4
                      c0.8-0.2,1.5-0.3,2.3-0.4l0.8-0.1c0,0,0,0,0,0c-0.1-0.2-0.4-0.9-1.4-1.7c-0.3-0.3-0.8-0.2-1.2,0c-0.5,0.3-1.4,0.3-2.8,0.1
                      C475.3,354.7,474.5,354,474.1,353.6z"
                          />
                          <path
                            id="XMLID_1347_"
                            d="M477.1,357.8c-2.7,0-4.9-1.4-5.7-2.6c-0.4-0.7-0.4-1.3,0-1.8c0.2-0.3,0.5-0.4,0.7-0.4
                      c0.1,0,0.1,0,0.2-0.1c0.7-0.3,1.6-0.2,2.1,0.4c0.3,0.4,1.1,1,2.7,1.3c1.7,0.3,2.3,0.1,2.5,0c0.5-0.3,1.2-0.3,1.6,0
                      c1.2,0.9,1.5,1.7,1.6,1.9l0.1,0.2l-0.2,0.3l-0.2,0l-0.8,0.1c-0.8,0.1-1.6,0.2-2.2,0.4
                      C478.6,357.7,477.9,357.8,477.1,357.8z M473,353.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0.2
                      c-0.2,0.2,0,0.7,0.1,0.8c0.9,1.4,3.8,2.9,7.1,2c0.7-0.2,1.5-0.3,2.4-0.4l0.2,0c-0.2-0.3-0.5-0.6-1.1-1.1
                      c-0.2-0.1-0.5-0.1-0.7,0c-0.6,0.4-1.5,0.4-3.1,0.1c-1.8-0.3-2.7-1.1-3.1-1.5l0,0C473.6,353.7,473.3,353.5,473,353.5z"
                          />
                        </g>
                        <g id="XMLID_1343_">
                          <path
                            id="XMLID_1344_"
                            style="fill: #ffffff"
                            d="M473.5,389.3c-2.1-2.8-1.4-8.4-1.3-8.6l0.8,0.1c0,0.1-0.7,5.5,1.2,8.1
                      L473.5,389.3z"
                          />
                        </g>
                        <g id="XMLID_1204_">
                          <g id="XMLID_1338_">
                            <path
                              id="XMLID_1342_"
                              style="fill: #ffffff"
                              d="M495,352.1c-0.5-1-0.7-1.5-1.4-1.1l-0.3,0.4c-0.3,0.4-0.5,0.9-0.6,1.4
                        l0,0c0,0.2-0.1,0.5-0.1,0.8l0,1.2l0.3,0.1l-0.6-0.1c0,0-0.2-1-0.8-1.2c-0.6-0.2-0.7,0.7-0.7,0.7l0,0.7c0.1,1,0.4,2,0.8,3
                        v0l3,0.9c0,0,0.5-1.3,0.9-2.2C496,355.6,495.6,353.2,495,352.1z"
                            />
                            <path
                              id="XMLID_1339_"
                              d="M494.9,359.2l-3.5-1.1l-0.1-0.2c-0.4-1-0.7-2-0.8-3.1l0-0.8c0-0.2,0.1-0.8,0.6-1
                        c0.1-0.1,0.4-0.2,0.7,0c0.2,0.1,0.4,0.2,0.5,0.4c0-0.2,0-0.5,0.1-0.7c0.1-0.6,0.3-1.1,0.7-1.5l0.3-0.4l0.1,0
                        c0.3-0.2,0.6-0.2,0.8-0.1c0.4,0.1,0.7,0.6,1,1.2l0.1,0.2c0.6,1.1,1.1,3.7,0.6,4.8c-0.4,0.8-0.9,2.1-0.9,2.2L494.9,359.2z
                         M491.9,357.5l2.5,0.7c0.2-0.5,0.5-1.3,0.8-1.9c0.4-0.7,0-2.9-0.5-4l-0.1-0.2c-0.2-0.4-0.4-0.8-0.6-0.8c0,0-0.1,0-0.1,0
                        l-0.2,0.3c-0.3,0.4-0.4,0.8-0.5,1.2c0,0.2-0.1,0.4-0.1,0.7l0,0.9l0,0.1l0,0.2l0,0l-0.1,0.5l-0.8-0.2l-0.1-0.2
                        c-0.1-0.3-0.3-0.8-0.6-1c-0.1,0-0.2,0.2-0.2,0.4l0,0.7C491.3,355.7,491.5,356.6,491.9,357.5z"
                            />
                          </g>
                          <g id="XMLID_1270_">
                            <path
                              id="XMLID_1335_"
                              d="M481,361c0,0,1,6.7,5.5,12.8c0.8,1.1,1.9,2.1,3,3l0,0c0.5,0.3,1.1,0.2,1.4-0.3l0.3-0.3
                        c0.7-0.6,1.2-1.4,1.5-2.2l0,0c0.2-0.6,0.3-1.1,0.4-1.7l1.5-12.1c0-0.5-0.3-0.9-0.7-1.1l-2-0.7c-0.3-0.1-0.6,0.1-0.6,0.4
                        c-0.2,1.4-0.6,3.6-1.2,6.1c-0.6,2.5-0.5,7.1-0.5,7.1s-2.6-12.1-5.3-14.3C481.7,355.5,480.4,358.6,481,361z"
                            />
                            <path
                              id="XMLID_1325_"
                              d="M490.1,377.4c-0.3,0-0.6-0.1-0.8-0.3c-1.2-0.9-2.3-1.9-3.1-3.1c-4.4-6-5.5-12.6-5.6-13
                        c-0.3-1.5-0.1-3.5,1.1-4.2c0.6-0.4,1.5-0.5,2.9,0.5c2,1.6,3.8,7.8,4.7,11.5c0.1-1.4,0.2-3,0.5-4.1c0.6-2.7,1-4.8,1.2-6.1
                        c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.2,0.7-0.1l2,0.7c0.6,0.2,1,0.8,1,1.5l-1.5,12.1c0,0.6-0.2,1.2-0.4,1.8
                        c-0.3,0.9-0.9,1.7-1.6,2.4l-0.3,0.3c-0.2,0.3-0.5,0.5-0.9,0.6C490.3,377.4,490.2,377.4,490.1,377.4z M482.7,357.4
                        c-0.2,0-0.4,0-0.5,0.2c-0.7,0.5-1.1,2-0.8,3.4l0,0c0,0.1,1.1,6.7,5.5,12.7c0.8,1,1.7,2,2.9,2.9c0.1,0.1,0.3,0.1,0.5,0.1
                        c0.2,0,0.3-0.1,0.4-0.3l0.1-0.1l0.3-0.3c0.6-0.6,1.1-1.3,1.4-2.1c0.2-0.5,0.3-1.1,0.4-1.6l1.5-12.1
                        c0-0.3-0.2-0.6-0.4-0.7l-2-0.7l-0.1,0.1c-0.2,1.3-0.6,3.4-1.2,6.2c-0.6,2.4-0.5,6.9-0.5,7l0,3.8l-0.8-3.7
                        c0-0.1-2.6-12-5.2-14C483.7,357.7,483.2,357.4,482.7,357.4z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1155_">
                          <g id="XMLID_1162_">
                            <path
                              id="XMLID_1166_"
                              d="M474.1,340.7c-0.9,0.9-1.7,1.9-2.1,3.1c-0.4,1.4-0.5,3.3,0.6,5.1c2.1,3.1,4.9,2.3,5.5,2
                        c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.6-0.3-2.6,0.8-3.9c1-1.2,2.3-1.2,2.6-1.1c0.1,0,0.1,0,0.1-0.1c0.4-0.4,2.4-2.4,1-4.7
                        c-1.5-2.5-5.1-3.8-8.1-2.6c-0.1,0-0.1,0.2,0,0.2c0.3,0.1,0.8,0.5,0.3,1.2C474.8,340,474.4,340.4,474.1,340.7z"
                            />
                            <path
                              id="XMLID_1163_"
                              d="M476.5,351.5c-1.2,0-2.9-0.5-4.2-2.5c-1-1.6-1.3-3.4-0.7-5.4c0.4-1.2,1.1-2.3,2.2-3.3l0,0
                        c0.4-0.4,0.7-0.7,1-1c0.1-0.2,0.2-0.3,0.2-0.4c0-0.1-0.1-0.2-0.3-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.4
                        c3-1.2,7,0.1,8.6,2.8c1.5,2.6-0.6,4.8-1.1,5.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.9-0.1-1.7,0.3-2.3,1c-1,1.3-0.7,3.5-0.7,3.6
                        c0,0.3-0.1,0.5-0.4,0.6C478.1,351.3,477.4,351.5,476.5,351.5z M474.3,340.9c-1,0.9-1.6,1.9-1.9,3
                        c-0.5,1.8-0.3,3.3,0.6,4.7c1.8,2.7,4.1,2.2,4.9,1.9c-0.1-0.6-0.2-2.7,0.9-4.1c0.7-0.9,1.7-1.3,2.9-1.3
                        c0.5-0.5,2.1-2.2,0.9-4.2c-1.3-2.2-4.5-3.4-7.1-2.6c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.3,0,0.7-0.3,1
                        C475.1,340.2,474.8,340.6,474.3,340.9L474.3,340.9z"
                            />
                          </g>
                          <g id="XMLID_1160_">
                            <path
                              id="XMLID_1161_"
                              style="fill: #ffffff"
                              d="M478.8,341.2c-0.5-1.2-2.5-1.1-3.4-1.1l-0.1-0.8
                        c1.2-0.1,3.4-0.1,4.2,1.5L478.8,341.2z"
                            />
                          </g>
                          <g id="XMLID_1158_">
                            <path
                              id="XMLID_1159_"
                              style="fill: #ffffff"
                              d="M477.1,349.7c-2.3,0-2.6-1.9-2.6-1.9l0.8-0.1l-0.4,0.1l0.4-0.1
                        c0,0.2,0.4,1.6,2.5,1.2l0.1,0.8C477.6,349.7,477.4,349.7,477.1,349.7z"
                            />
                          </g>
                          <g id="XMLID_1156_">
                            <path
                              id="XMLID_1157_"
                              style="fill: #ffffff"
                              d="M474.4,351c-2.4-1.5-2.8-3.5-2.8-3.6l0.8-0.2c0,0,0.4,1.8,2.5,3.1
                        L474.4,351z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1145_">
                          <path
                            id="XMLID_1146_"
                            style="fill: #ffffff"
                            d="M476.9,357.8c-2.2,0-3.3-0.8-4.2-1.5c-0.1-0.1-0.2-0.2-0.4-0.2l-0.1-0.1
                      c-0.7-0.5-1-1.4-0.8-2.2l0.8,0.2c-0.1,0.5,0,1.1,0.5,1.4l0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.3c1,0.7,2.2,1.6,4.8,1.3
                      c3-0.3,3.9-0.7,4-0.7l0.3,0.7c0,0-1,0.4-4.2,0.8C477.6,357.8,477.2,357.8,476.9,357.8z"
                          />
                        </g>
                        <g id="XMLID_1124_">
                          <path
                            id="XMLID_1143_"
                            style="fill: #ffffff"
                            d="M483.8,370.2c-0.1-0.2-3.1-6-3.2-9.2c0-1,0-1.8,0.1-2.4l0.8,0.1
                      c-0.1,0.5-0.1,1.3-0.1,2.2c0.1,3,3.1,8.8,3.1,8.8L483.8,370.2z"
                          />
                        </g>
                        <g id="XMLID_1122_">
                          <path
                            id="XMLID_1123_"
                            style="fill: #ffffff"
                            d="M489.3,371.1c0-1.2-0.3-2.1-0.3-2.2l0.8-0.2c0,0,0.3,1.1,0.3,2.3
                      L489.3,371.1z"
                          />
                        </g>
                        <g id="XMLID_1120_">
                          <path
                            id="XMLID_1121_"
                            d="M476.1,400.8c-0.7-2.5-1.3-6.7-1.4-6.7l0.8-0.1c0,0,0.7,4.1,1.3,6.6L476.1,400.8z"
                          />
                        </g>
                        <g id="XMLID_1118_">
                          <rect
                            id="XMLID_1119_"
                            x="492.5"
                            y="359.4"
                            transform="matrix(0.2777 -0.9607 0.9607 0.2777 9.3681 734.1019)"
                            style="fill: #ffffff"
                            width="0.8"
                            height="2.7"
                          />
                        </g>
                        <g id="XMLID_1116_">
                          <path
                            id="XMLID_1117_"
                            style="fill: #ffffff"
                            d="M473.9,423.2c-0.4-0.9-2.1-0.8-2.2-0.8l-0.1-0.8c0.1,0,2.3-0.3,3,1.2
                      L473.9,423.2z"
                          />
                        </g>
                        <g id="XMLID_1114_">
                          <path
                            id="XMLID_1115_"
                            style="fill: #ffffff"
                            d="M485.3,427.9c-0.6-1.4-2.3-1.3-2.3-1.3l-0.1-0.8c0.8-0.1,2.5,0.2,3.1,1.8
                      L485.3,427.9z"
                          />
                        </g>
                        <g id="XMLID_1112_">
                          <path
                            id="XMLID_1113_"
                            d="M484,397c-0.6,0-1.1-0.2-1.6-0.5c-1.7-1.1-1.8-3.9-1.8-4l0.8,0c0,0,0.1,2.5,1.5,3.4
                      c0.7,0.4,1.5,0.4,2.6,0l0.3,0.7C485.1,396.9,484.6,397,484,397z"
                          />
                        </g>
                        <g id="XMLID_1110_">
                          <path
                            id="XMLID_1111_"
                            d="M470.9,408.4c-2.1-0.6-2.7-2.5-2.7-2.6l0.8-0.2c0,0.1,0.5,1.6,2.2,2.1L470.9,408.4z"
                          />
                        </g>
                        <g id="XMLID_1108_">
                          <path
                            id="XMLID_1109_"
                            d="M479.9,412c-3.4-0.3-3.5-2.7-3.5-2.7l0.8,0l-0.4,0l0.4,0c0,0.1,0.1,1.7,2.7,1.9L479.9,412z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_1064_">
                      <g id="XMLID_1083_">
                        <g id="XMLID_1090_">
                          <g id="XMLID_1101_">
                            <path
                              id="XMLID_1105_"
                              style="fill: #ffffff"
                              d="M481.7,398.7c0,0,2.7,1.1,3,1.1c0,0,0,0,0.1,0l2.2-4.4c0,0,0,0,0,0
                        l5-2.8c0,0,0,0,0-0.1l-1.5-9.7c0,0,0,0,0,0l0.7-1c0,0,0-0.1,0-0.1c-0.3-0.1-2.9-1.1-2.9-1.1l0,0c-0.2-0.1-0.4,0-0.6,0.1
                        l-7.3,6c-0.5,0.4-0.7,1-0.6,1.6l1.5,9.8C481.4,398.4,481.5,398.6,481.7,398.7L481.7,398.7z"
                            />
                            <path
                              id="XMLID_1102_"
                              d="M484.7,400.2c-0.1,0-0.1,0-0.2,0l-0.8-0.3c-2.2-0.8-2.2-0.8-2.2-0.8l0,0
                        c-0.3-0.1-0.5-0.4-0.6-0.8l-1.5-9.8c-0.1-0.7,0.2-1.5,0.7-1.9l7.3-6c0.3-0.2,0.6-0.3,0.9-0.2c0,0,0.1,0,2.3,0.9l0.9,0.3
                        l0.1,0.2c0,0.1,0,0.3-0.1,0.4l-0.6,0.8l1.5,9.7l-0.2,0.2l-4.9,2.8l-2.1,4.3C485,400.2,484.9,400.2,484.7,400.2z
                         M481.9,398.3c0.1,0,1.3,0.5,2.2,0.8l0.5,0.2l2.1-4.1l0.1-0.1l4.8-2.7l-1.5-9.6l0.1-0.2l0.5-0.6l-0.3-0.1
                        c-0.8-0.3-2.1-0.8-2.2-0.8c0,0-0.1,0-0.2,0l-7.3,6c-0.4,0.3-0.5,0.8-0.5,1.2l1.5,9.8
                        C481.8,398.2,481.8,398.3,481.9,398.3L481.9,398.3z"
                            />
                          </g>
                          <g id="XMLID_1096_">
                            <path
                              id="XMLID_1100_"
                              style="fill: #ffffff"
                              d="M484.8,399.8l2.2-4.4c0,0,0,0,0,0l-2.6-6.3l-4.4-1.7
                        c-0.1,0.3-0.2,0.6-0.1,0.9l1.5,9.8c0,0.2,0.2,0.4,0.4,0.5l0,0C481.7,398.7,484.5,399.7,484.8,399.8
                        C484.7,399.8,484.7,399.8,484.8,399.8z"
                            />
                            <path
                              id="XMLID_1097_"
                              d="M484.7,400.2c-0.1,0-0.1,0-0.2,0l-0.8-0.3c-2.2-0.8-2.2-0.8-2.2-0.8c-0.3-0.1-0.6-0.4-0.6-0.8
                        l-1.5-9.8c-0.1-0.4,0-0.8,0.1-1.1l0.1-0.4l4.9,1.9l2.8,6.7l-0.2,0.1l-2.2,4.3C485,400.1,484.9,400.2,484.7,400.2z
                         M480.2,388c0,0.1,0,0.2,0,0.3l1.5,9.8c0,0.1,0.1,0.2,0.2,0.2c0,0,1.3,0.5,2.2,0.8l0.5,0.2l1.9-3.9l-2.5-6L480.2,388z"
                            />
                          </g>
                          <g id="XMLID_1091_">
                            <path
                              id="XMLID_1095_"
                              d="M483.3,388l7.3-6c0.4-0.3,1-0.1,1,0.4l1.6,9.8c0.1,0.6-0.1,1.2-0.6,1.5l-7.3,6
                        c-0.4,0.3-1,0.1-1-0.4l-1.6-9.8C482.7,388.9,482.9,388.3,483.3,388z"
                            />
                            <path
                              id="XMLID_1092_"
                              d="M484.9,400.3c-0.1,0-0.3,0-0.4-0.1c-0.4-0.1-0.6-0.4-0.7-0.8l-1.6-9.8
                        c-0.1-0.7,0.2-1.4,0.7-1.9l7.3-6c0.3-0.2,0.7-0.3,1-0.2c0.4,0.1,0.6,0.4,0.7,0.8l1.6,9.8c0.1,0.7-0.2,1.4-0.7,1.9l-7.3,6
                        C485.4,400.2,485.2,400.3,484.9,400.3z M491,382.2c0,0-0.1,0-0.2,0.1l-7.3,6c-0.3,0.3-0.5,0.7-0.4,1.1l1.6,9.8
                        c0,0.1,0.1,0.2,0.2,0.2c0,0,0.2,0,0.3,0l7.3-6c0.3-0.3,0.5-0.7,0.4-1.2l-1.6-9.8C491.3,382.3,491.2,382.2,491,382.2
                        C491.1,382.2,491.1,382.2,491,382.2z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_1084_">
                          <g id="XMLID_1088_">
                            <rect
                              id="XMLID_1089_"
                              x="483.2"
                              y="393"
                              transform="matrix(0.7792 -0.6268 0.6268 0.7792 -138.6344 393.0448)"
                              style="fill: #ffffff"
                              width="10.6"
                              height="0.6"
                            />
                          </g>
                          <g id="XMLID_1085_">
                            <rect
                              id="XMLID_1087_"
                              x="492.2"
                              y="385.1"
                              transform="matrix(0.9828 -0.1848 0.1848 0.9828 -63.2379 97.6805)"
                              style="fill: #ffffff"
                              width="0.6"
                              height="6.1"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_1078_">
                        <path
                          id="XMLID_1082_"
                          style="fill: #ffffff"
                          d="M487,390.8c-0.4,1.1-1.2,1.9-1.8,1.7c-0.7-0.2-0.9-1.3-0.6-2.4
                    c0.4-1.1,1.2-1.9,1.8-1.7C487.1,388.6,487.3,389.7,487,390.8z"
                        />
                        <path
                          id="XMLID_1079_"
                          d="M485.4,392.9c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.6-0.3-0.7-0.7c-0.3-0.6-0.3-1.4-0.1-2.3
                    c0.2-0.6,0.5-1.2,0.9-1.5c0.5-0.4,1-0.6,1.4-0.4c0.5,0.1,0.8,0.6,0.9,1.2c0.1,0.5,0.1,1.2-0.1,1.8l0,0
                    c-0.2,0.6-0.5,1.2-0.9,1.5C486.1,392.8,485.7,392.9,485.4,392.9z M486.2,388.7c-0.2,0-0.4,0.1-0.5,0.3
                    c-0.3,0.3-0.5,0.7-0.7,1.2c-0.2,0.6-0.2,1.3,0,1.7c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.4,0,0.7-0.3c0.3-0.3,0.5-0.7,0.7-1.2
                    l0,0c0.1-0.5,0.2-1,0.1-1.4c-0.1-0.3-0.2-0.5-0.4-0.6C486.3,388.7,486.2,388.7,486.2,388.7z"
                        />
                      </g>
                      <g id="XMLID_1073_">
                        <path
                          id="XMLID_1077_"
                          style="fill: #ffffff"
                          d="M490.6,392.8c-0.3,0.9-0.9,1.5-1.4,1.3c-0.5-0.2-0.7-1-0.4-1.9
                    c0.3-0.9,0.9-1.5,1.4-1.3C490.7,391.1,490.9,391.9,490.6,392.8z"
                        />
                        <path
                          id="XMLID_1074_"
                          d="M489.3,394.5c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.7-0.5-0.8-1c-0.1-0.4-0.1-0.9,0.1-1.4
                    c0.3-1.1,1.2-1.8,1.9-1.6c0.4,0.1,0.7,0.5,0.8,1c0.1,0.4,0.1,0.9-0.1,1.4C490.7,393.9,490,394.5,489.3,394.5z M490,391.3
                    c-0.2,0-0.7,0.4-0.9,1.1c-0.1,0.4-0.1,0.7-0.1,1c0,0.2,0.1,0.4,0.2,0.4c0.2,0.1,0.7-0.3,0.9-1.1c0.1-0.4,0.1-0.7,0.1-1
                    C490.3,391.5,490.2,391.3,490,391.3C490,391.3,490,391.3,490,391.3z"
                        />
                      </g>
                      <g id="XMLID_1065_">
                        <path
                          id="XMLID_1072_"
                          style="fill: #ffffff"
                          d="M489,388.7c-0.4,1.3-1.4,2.1-2.2,1.9c-0.8-0.2-1-1.4-0.6-2.7
                    c0.4-1.3,1.4-2.1,2.2-1.9C489.2,386.2,489.5,387.4,489,388.7z"
                        />
                        <path
                          id="XMLID_1067_"
                          d="M487.1,391.1c-0.1,0-0.2,0-0.4,0c-0.4-0.1-0.6-0.3-0.8-0.7c-0.3-0.6-0.4-1.6,0-2.5
                    c0.5-1.5,1.7-2.4,2.6-2.2c0.4,0.1,0.6,0.3,0.8,0.7c0.3,0.6,0.4,1.6,0,2.5C489,390.2,488,391.1,487.1,391.1z M488.2,386.4
                    c-0.5,0-1.2,0.6-1.5,1.7c-0.2,0.7-0.2,1.4,0,1.9c0.1,0.2,0.2,0.3,0.4,0.3c0.5,0.1,1.3-0.5,1.7-1.7v0c0.2-0.7,0.2-1.4,0-1.9
                    c-0.1-0.2-0.2-0.3-0.4-0.3C488.3,386.4,488.2,386.4,488.2,386.4z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_1052_">
                      <path
                        id="XMLID_1062_"
                        style="fill: #ffffff"
                        d="M480.2,384.3c-0.6-0.9-14.6-21-10-31.5l0.7,0.3
                  c-4.4,10.1,9.7,30.5,9.9,30.7L480.2,384.3z"
                      />
                    </g>
                    <g id="XMLID_1038_">
                      <path
                        id="XMLID_1051_"
                        d="M487.4,382.6c-1.5-5.3-3.7-9-3.7-9l0.7-0.4c0,0,2.3,3.9,3.8,9.2L487.4,382.6z"
                      />
                    </g>
                    <g id="XMLID_1021_">
                      <path
                        id="XMLID_1034_"
                        d="M481,385L481,385c-0.5,0.3-1.1,0.2-1.4-0.3l-0.6-0.9c-0.3-0.5-0.2-1.1,0.3-1.4l0,0
                  c0.5-0.3,1.1-0.2,1.4,0.3l0.6,0.9C481.5,384.1,481.4,384.7,481,385z"
                      />
                      <path
                        id="XMLID_1023_"
                        style="fill: #ffffff"
                        d="M480.4,385.6c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.7-0.3-0.9-0.6l-0.6-0.9
                  c-0.2-0.3-0.3-0.7-0.2-1c0.1-0.4,0.3-0.7,0.6-0.9c0.3-0.2,0.7-0.3,1-0.2c0.4,0.1,0.7,0.3,0.9,0.6l0.6,0.9
                  c0.4,0.6,0.2,1.5-0.4,1.9l0,0C480.9,385.5,480.7,385.6,480.4,385.6z M479.8,382.7c-0.1,0-0.2,0-0.3,0.1
                  c-0.3,0.2-0.4,0.5-0.2,0.8l0.6,0.9c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0,0.3,0,0.4-0.1l0,0c0.3-0.2,0.4-0.5,0.2-0.8l-0.6-0.9
                  c-0.1-0.1-0.2-0.2-0.4-0.3C479.9,382.7,479.9,382.7,479.8,382.7z"
                      />
                    </g>
                    <g id="XMLID_1008_">
                      <path
                        id="XMLID_1020_"
                        d="M473.8,371.6L473.8,371.6c-0.5,0.3-1.1,0.2-1.4-0.3l-0.6-0.9c-0.3-0.5-0.2-1.1,0.3-1.4l0,0
                  c0.5-0.3,1.1-0.2,1.4,0.3l0.6,0.9C474.3,370.7,474.2,371.3,473.8,371.6z"
                      />
                      <path
                        id="XMLID_1017_"
                        style="fill: #ffffff"
                        d="M473.2,372.1c-0.5,0-0.9-0.2-1.2-0.6l-0.6-0.9c-0.4-0.6-0.2-1.5,0.4-1.9
                  c0.6-0.4,1.5-0.2,1.9,0.4l0.6,0.9c0.2,0.3,0.3,0.7,0.2,1c-0.1,0.4-0.3,0.7-0.6,0.9l0,0C473.7,372.1,473.5,372.1,473.2,372.1
                  z M472.6,369.2c-0.1,0-0.2,0-0.3,0.1c-0.3,0.2-0.4,0.5-0.2,0.8l0.6,0.9c0.2,0.3,0.5,0.4,0.8,0.2v0c0.1-0.1,0.2-0.2,0.3-0.4
                  c0-0.2,0-0.3-0.1-0.4l-0.6-0.9C473,369.3,472.8,369.2,472.6,369.2z"
                      />
                    </g>
                    <g id="XMLID_1002_">
                      <path
                        id="XMLID_1007_"
                        style="fill: #ffffff"
                        d="M487.6,382.2L487.6,382.2c-0.5,0.1-1.1-0.2-1.2-0.7l-0.3-1
                  c-0.1-0.5,0.2-1.1,0.7-1.2l0,0c0.5-0.1,1.1,0.2,1.2,0.7l0.3,1C488.5,381.5,488.2,382,487.6,382.2z"
                      />
                      <path
                        id="XMLID_1003_"
                        d="M487.4,382.6c-0.6,0-1.2-0.4-1.3-1l-0.3-1c-0.2-0.7,0.2-1.5,1-1.7c0.7-0.2,1.5,0.2,1.7,1l0.3,1
                  c0.1,0.4,0,0.7-0.1,1c-0.2,0.3-0.5,0.5-0.8,0.6h0C487.6,382.6,487.5,382.6,487.4,382.6z M487.1,379.6c-0.1,0-0.1,0-0.2,0
                  c-0.3,0.1-0.5,0.4-0.4,0.7l0.3,1c0.1,0.3,0.4,0.5,0.7,0.4c0.2,0,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.4l-0.3-1
                  C487.6,379.8,487.3,379.6,487.1,379.6z"
                      />
                    </g>
                    <g id="XMLID_993_">
                      <path
                        id="XMLID_1000_"
                        d="M471.6,366.3L471.6,366.3c-0.5,0.1-1.1-0.2-1.2-0.7l-1.3-5.7c-0.1-0.5,0.2-1.1,0.7-1.2l0,0
                  c0.5-0.1,1.1,0.2,1.2,0.7l1.3,5.7C472.4,365.7,472.1,366.2,471.6,366.3z"
                      />
                      <path
                        id="XMLID_995_"
                        style="fill: #ffffff"
                        d="M471.4,366.8c-0.3,0-0.5-0.1-0.7-0.2c-0.3-0.2-0.5-0.5-0.6-0.9l-1.3-5.7
                  c-0.2-0.7,0.3-1.5,1-1.6c0.4-0.1,0.7,0,1,0.2c0.3,0.2,0.5,0.5,0.6,0.9l1.3,5.7c0.1,0.4,0,0.7-0.2,1s-0.5,0.5-0.9,0.6
                  C471.6,366.7,471.5,366.8,471.4,366.8z M470.1,359.1c0,0-0.1,0-0.1,0c-0.3,0.1-0.5,0.4-0.4,0.7l1.3,5.7
                  c0,0.2,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.4,0.1l0,0c0.2,0,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.4l-1.3-5.7
                  c0-0.2-0.1-0.3-0.3-0.4C470.3,359.2,470.2,359.1,470.1,359.1z"
                      />
                    </g>
                    <g id="XMLID_873_">
                      <g id="XMLID_962_">
                        <g id="XMLID_987_">
                          <path
                            id="XMLID_992_"
                            d="M468.5,391.5l0.3,4.5c0,0.6,0.4,1,0.8,1.1l2.4,0.6c0.4,0.1,0.7-0.3,0.7-0.8v-4.5
                      c0-0.6-0.4-1.1-0.8-1.2l-2.7-0.5C468.8,390.6,468.5,391,468.5,391.5z"
                          />
                          <path
                            id="XMLID_988_"
                            d="M472.2,398.1c-0.1,0-0.1,0-0.2,0l-2.4-0.6c-0.6-0.1-1-0.7-1.1-1.5l-0.3-4.5c0-0.4,0.1-0.8,0.4-1
                      c0.2-0.2,0.5-0.3,0.8-0.2l2.7,0.5c0.7,0.1,1.2,0.8,1.2,1.6v4.5c0,0.4-0.2,0.8-0.4,1C472.6,398,472.4,398.1,472.2,398.1z
                       M468.9,391.5l0.3,4.5c0,0.4,0.2,0.7,0.5,0.7l2.4,0.6c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0.1-0.4v-4.5
                      c0-0.4-0.2-0.8-0.5-0.8l-2.7-0.5c0,0-0.1,0-0.1,0C469,391.2,468.9,391.3,468.9,391.5L468.9,391.5z"
                          />
                        </g>
                        <g id="XMLID_977_">
                          <path
                            id="XMLID_986_"
                            style="fill: #ffffff"
                            d="M467.8,392l0.3,4.5c0,0.6,0.4,1,0.8,1.1l2.4,0.6c0.4,0.1,0.7-0.3,0.7-0.8
                      v-4.5c0-0.6-0.4-1.1-0.8-1.2l-2.7-0.5C468.1,391,467.8,391.5,467.8,392z"
                          />
                          <path
                            id="XMLID_981_"
                            d="M471.4,398.5c-0.1,0-0.1,0-0.2,0l-2.4-0.6c-0.6-0.1-1-0.7-1.1-1.5l-0.3-4.5c0-0.4,0.1-0.8,0.4-1
                      c0.2-0.2,0.5-0.3,0.8-0.2l2.7,0.5c0.7,0.1,1.2,0.8,1.2,1.6v4.5c0,0.4-0.2,0.8-0.4,1C471.8,398.5,471.6,398.5,471.4,398.5z
                       M468.2,391.9l0.3,4.5c0,0.4,0.2,0.7,0.5,0.7l2.4,0.6c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0.1-0.4v-4.5
                      c0-0.4-0.2-0.8-0.5-0.8l-2.7-0.5c0,0-0.1,0-0.1,0C468.2,391.6,468.2,391.7,468.2,391.9L468.2,391.9z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_877_">
                        <path
                          id="XMLID_891_"
                          d="M468,391.5c-0.1-0.1-2-1.7-1.1-4.7l0.7,0.2c-0.8,2.5,0.8,3.8,0.8,3.8L468,391.5z"
                        />
                      </g>
                      <g id="XMLID_874_">
                        <path
                          id="XMLID_875_"
                          d="M472.8,392.3l-0.1-0.8l0.1,0.4l-0.1-0.4c0.1,0,1.5-0.3,1.6-1.6l0.8,0.1
                    C474.9,391.5,473.5,392.2,472.8,392.3z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_431_">
                  <g id="XMLID_609_">
                    <g id="XMLID_694_">
                      <g id="XMLID_865_">
                        <path
                          id="XMLID_869_"
                          style="fill: #ffffff"
                          d="M522.9,369.6l-12.4-7.1c-0.2-0.1-0.3-0.1-0.4,0l0,0l0,0c0,0,0,0-0.1,0
                    l-3.2,1.9c0,0,0,0,0,0l3,5c0,0,0,0,0,0v-1.6c0,0.5,0.3,1.1,0.7,1.3l6.4,3.7c0,0,0,0,0,0l3.2,5.4c0,0,0,0,0,0l3.2-1.9
                    c0.1-0.1,0.2-0.3,0.2-0.5v-4.8C523.6,370.4,523.3,369.9,522.9,369.6z"
                        />
                        <path
                          id="XMLID_866_"
                          d="M520,378.8l-0.2-0.3l-3.2-5.3l-6.3-3.6c0,0-0.1,0-0.1-0.1v0h-0.8h0.4l-0.3,0.2l-3.1-5.2l0.1-0.3
                    l0.2-0.1l3.2-1.9c0.2-0.1,0.5-0.1,0.8,0l12.4,7.1h0c0.5,0.3,0.9,1,0.9,1.7v4.8c0,0.4-0.1,0.7-0.4,0.9L520,378.8z
                     M509.5,367.8h0.8c0,0.4,0.3,0.8,0.5,1l6.5,3.7l0.1,0.1l3,5.1l2.9-1.7c0,0,0-0.1,0-0.2v-4.8c0-0.4-0.3-0.8-0.5-1h0
                    l-12.4-7.1c0,0-0.1,0-0.1,0l0,0l-2.9,1.7l2.1,3.5V367.8z"
                        />
                      </g>
                      <g id="XMLID_711_">
                        <path
                          id="XMLID_864_"
                          d="M516.3,371.1C516.3,371.1,516.3,371.1,516.3,371.1l0.1,1.5l0.5,0.3c0,0,0,0,0,0l3.2,5.3
                    c0,0,0.1,0,0.1,0l3.2-1.9c0.1-0.1,0.2-0.3,0.2-0.5V371c0,0,0,0-0.1,0l-3.1,1.9c0,0,0,0,0,0L516.3,371.1z"
                        />
                        <path
                          id="XMLID_753_"
                          d="M520.3,378.6l-0.4-0.1l-3.2-5.4l-0.6-0.3l-0.1-1.8l0.2-0.4l4.2,1.9l3.2-1.9l0.4,0.2l0,0.3v4.8
                    c0,0.4-0.1,0.7-0.4,0.9L520.3,378.6z M516.8,372.3l0.4,0.2l0.1,0.1l3,5.1l2.9-1.7c0,0,0-0.1,0-0.2v-4.1l-2.5,1.5l-0.1,0.2
                    l-3.8-1.7L516.8,372.3z"
                        />
                      </g>
                      <g id="XMLID_704_">
                        <path
                          id="XMLID_710_"
                          style="fill: #ffffff"
                          d="M519.7,378.2l-12.4-7.1c-0.4-0.2-0.7-0.8-0.7-1.3v-4.8
                    c0-0.5,0.3-0.7,0.7-0.5l12.4,7.1c0.4,0.2,0.7,0.8,0.7,1.3v4.8C520.4,378.2,520.1,378.4,519.7,378.2z"
                        />
                        <path
                          id="XMLID_707_"
                          d="M520,378.7c-0.2,0-0.3,0-0.5-0.1l-12.4-7.1c-0.5-0.3-0.9-1-0.9-1.7v-4.8c0-0.4,0.2-0.7,0.4-0.9
                    c0.2-0.1,0.6-0.1,0.8,0l12.4,7.1c0.5,0.3,0.9,1,0.9,1.7v4.8c0,0.4-0.2,0.7-0.4,0.9C520.2,378.6,520.1,378.7,520,378.7z
                     M507.1,364.7L507.1,364.7c0,0,0,0.1,0,0.2v4.8c0,0.4,0.3,0.8,0.5,1l12.4,7.1c0,0,0.1,0,0.1,0l0,0c0,0,0-0.1,0-0.2v-4.8
                    c0-0.4-0.3-0.8-0.5-1L507.1,364.7C507.1,364.7,507.1,364.7,507.1,364.7z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_626_">
                      <rect
                        id="XMLID_664_"
                        x="508.9"
                        y="364.3"
                        transform="matrix(0.8487 -0.5288 0.5288 0.8487 -115.5777 325.2219)"
                        width="3.6"
                        height="0.8"
                      />
                    </g>
                    <g id="XMLID_612_">
                      <rect
                        id="XMLID_616_"
                        x="520.5"
                        y="374.5"
                        transform="matrix(0.8569 -0.5154 0.5154 0.8569 -118.5181 322.7145)"
                        style="fill: #ffffff"
                        width="3.2"
                        height="0.6"
                      />
                    </g>
                  </g>
                  <g id="XMLID_542_">
                    <g id="XMLID_552_">
                      <g id="XMLID_553_">
                        <g id="XMLID_561_">
                          <g id="XMLID_592_">
                            <path
                              id="XMLID_605_"
                              style="fill: #ffffff"
                              d="M522.8,352.3l-12.4-7.1c-0.2-0.1-0.3-0.1-0.4,0l0,0h0c0,0,0,0-0.1,0
                        l-3.2,1.9c0,0,0,0,0,0l3,5c0,0,0,0,0,0v6.8c0,0.5,0.3,1.1,0.7,1.3l6.4,3.7c0,0,0,0,0,0l3.2,5.4c0,0,0,0,0,0l3.2-1.9
                        c0.1-0.1,0.2-0.3,0.2-0.5v-13.2C523.5,353.1,523.2,352.5,522.8,352.3z"
                            />
                            <path
                              id="XMLID_595_"
                              d="M519.9,369.8l-0.2-0.4l-3.2-5.3l-6.3-3.6c-0.5-0.3-0.9-1-0.9-1.7v-6.7l-3.1-5.3l0.3-0.2l3.2-1.9
                        c0.3-0.1,0.6-0.1,0.8,0l12.4,7.1c0.5,0.3,0.9,1,0.9,1.7v13.2c0,0.4-0.1,0.7-0.4,0.9L519.9,369.8z M507.3,347.1l2.9,4.8
                        l0,0.1v6.8c0,0.4,0.3,0.8,0.5,1l6.5,3.7l0.1,0.1l3,5.1l2.9-1.7c0,0,0-0.1,0-0.2v-13.2c0-0.4-0.3-0.8-0.5-1l-12.4-7.1
                        c0,0-0.1,0-0.1,0l0,0L507.3,347.1z"
                            />
                          </g>
                          <g id="XMLID_575_">
                            <path
                              id="XMLID_588_"
                              d="M516.2,362.1C516.2,362.1,516.2,362.1,516.2,362.1l0.1,1.5l0.5,0.3c0,0,0,0,0,0l3.2,5.3
                        c0,0,0.1,0,0.1,0l3.2-1.9c0.1-0.1,0.2-0.3,0.2-0.5v-13.2c0,0,0,0-0.1,0l-3.1,1.9c0,0,0,0,0,0L516.2,362.1z"
                            />
                            <path
                              id="XMLID_578_"
                              d="M520.2,369.7l-0.4-0.1l-3.2-5.4l-0.6-0.3l-0.1-1.8l0.1-0.1l4.1-6.7l0.1-0.1l3.3-2l0.4,0.2l0,0.3
                        v13.2c0,0.4-0.1,0.7-0.4,0.9L520.2,369.7z M516.7,363.4l0.4,0.2l0.1,0.1l3,5l2.9-1.7c0,0,0-0.1,0-0.2v-12.6l-2.5,1.5
                        l-3.9,6.5L516.7,363.4z"
                            />
                          </g>
                          <g id="XMLID_562_">
                            <path
                              id="XMLID_572_"
                              style="fill: #ffffff"
                              d="M519.6,369.2l-12.4-7.1c-0.4-0.2-0.7-0.8-0.7-1.3v-13.2
                        c0-0.5,0.3-0.7,0.7-0.5l12.4,7.1c0.4,0.2,0.7,0.8,0.7,1.3v13.2C520.3,369.2,520,369.5,519.6,369.2z"
                            />
                            <path
                              id="XMLID_563_"
                              d="M519.9,369.7c-0.2,0-0.3,0-0.5-0.1l-12.4-7.1c-0.5-0.3-0.9-1-0.9-1.7v-13.2
                        c0-0.4,0.2-0.7,0.4-0.9c0.2-0.1,0.6-0.1,0.8,0l12.4,7.1c0.5,0.3,0.9,1,0.9,1.7v13.2c0,0.4-0.2,0.7-0.4,0.9
                        C520.1,369.7,520,369.7,519.9,369.7z M507,347.3L507,347.3c0,0,0,0.1,0,0.2v13.2c0,0.4,0.3,0.8,0.5,1l12.4,7.1
                        c0,0,0.1,0,0.1,0l0,0c0,0,0-0.1,0-0.2v-13.2c0-0.4-0.3-0.8-0.5-1L507,347.3C507,347.3,507,347.3,507,347.3z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_557_">
                          <rect
                            id="XMLID_558_"
                            x="508.8"
                            y="346.9"
                            transform="matrix(0.8487 -0.5288 0.5288 0.8487 -106.4031 322.5371)"
                            width="3.6"
                            height="0.8"
                          />
                        </g>
                        <g id="XMLID_554_">
                          <rect
                            id="XMLID_556_"
                            x="520.3"
                            y="365.6"
                            transform="matrix(0.8567 -0.5158 0.5158 0.8567 -113.9365 321.6384)"
                            style="fill: #ffffff"
                            width="3.2"
                            height="0.6"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_543_">
                      <g id="XMLID_546_">
                        <path
                          id="XMLID_551_"
                          style="fill: #ffffff"
                          d="M516.8,364.1l-6.7-3.9c-0.5-0.3-0.8-1-0.8-1.6V353c0-0.6,0.4-0.9,0.8-0.6
                    l6.7,3.9c0.5,0.3,0.8,1,0.8,1.6v5.6C517.6,364.1,517.2,364.3,516.8,364.1z"
                        />
                        <path
                          id="XMLID_547_"
                          d="M517.1,364.6c-0.2,0-0.4-0.1-0.5-0.2l-6.7-3.9c-0.6-0.3-1-1.2-1-1.9V353c0-0.5,0.2-0.8,0.5-1
                    c0.3-0.2,0.6-0.1,0.9,0l6.7,3.9c0.6,0.3,1,1.2,1,1.9v5.6c0,0.5-0.2,0.8-0.5,1C517.4,364.5,517.2,364.6,517.1,364.6z
                     M517,363.7c0.1,0,0.1,0.1,0.1,0c0,0,0.1-0.1,0.1-0.3v-5.6c0-0.5-0.3-1-0.6-1.2l-6.7-3.9c-0.1,0-0.1-0.1-0.1,0
                    c0,0-0.1,0.1-0.1,0.3v5.6c0,0.5,0.3,1,0.6,1.2L517,363.7z"
                        />
                      </g>
                      <g id="XMLID_544_">
                        <path
                          id="XMLID_545_"
                          d="M513.6,359.3c-0.3,0-0.6-0.2-0.8-0.5l-3.7-6.2l0.7-0.4l3.7,6.2c0,0.1,0.1,0.1,0.1,0.1l3.6-1.9
                    l0.4,0.7l-3.7,1.9C513.8,359.3,513.7,359.3,513.6,359.3z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_432_">
                    <g id="XMLID_469_">
                      <g id="XMLID_472_">
                        <g id="XMLID_487_">
                          <g id="XMLID_536_">
                            <path
                              id="XMLID_541_"
                              style="fill: #ffffff"
                              d="M522.8,334.8l-12.4-7.1c-0.2-0.1-0.3-0.1-0.4,0l0,0h0c0,0,0,0-0.1,0
                        l-3.2,1.9c0,0,0,0,0,0l3,5c0,0,0,0,0,0v6.8c0,0.5,0.3,1.1,0.7,1.3l6.4,3.7c0,0,0,0,0,0l3.2,5.4c0,0,0,0,0,0l3.2-1.9
                        c0.1-0.1,0.2-0.3,0.2-0.5v-13.2C523.5,335.6,523.2,335,522.8,334.8z"
                            />
                            <path
                              id="XMLID_537_"
                              d="M520.1,352.2l-0.3-0.1l-0.1-0.2l-3.2-5.3l-6.3-3.6c-0.5-0.3-0.9-1-0.9-1.7v-6.7l-3.1-5.3
                        l0.3-0.2l3.2-1.9c0.3-0.1,0.6-0.1,0.8,0l12.4,7.1c0.5,0.3,0.9,1,0.9,1.7v13.2c0,0.4-0.1,0.7-0.4,0.9L520.1,352.2z
                         M507.3,329.7l2.9,4.8l0,0.1v6.8c0,0.4,0.3,0.8,0.5,1l6.5,3.7l0.1,0.1l3,5l2.9-1.7c0,0,0-0.1,0-0.2v-13.2
                        c0-0.4-0.3-0.8-0.5-1l-12.4-7.1c0,0-0.1,0-0.1,0l0,0L507.3,329.7z"
                            />
                          </g>
                          <g id="XMLID_511_">
                            <path
                              id="XMLID_535_"
                              d="M516.2,344.6C516.2,344.6,516.2,344.6,516.2,344.6l0.1,1.5l0.5,0.3c0,0,0,0,0,0l3.2,5.3
                        c0,0,0.1,0,0.1,0l3.2-1.9c0.1-0.1,0.2-0.3,0.2-0.5v-13.2c0,0,0,0-0.1,0l-3.1,1.9c0,0,0,0,0,0L516.2,344.6z"
                            />
                            <path
                              id="XMLID_513_"
                              d="M520.2,352.2l-0.4-0.1l-3.2-5.4l-0.6-0.3l-0.1-1.8l0.1-0.1l4.1-6.7l0.1-0.1l3.4-2l0.4,0.2l0,0.3
                        v13.2c0,0.4-0.1,0.7-0.4,0.9L520.2,352.2z M516.7,345.9l0.4,0.2l0.1,0.1l3,5l2.9-1.7c0,0,0-0.1,0-0.2v-12.6l-2.5,1.5
                        l-3.9,6.5L516.7,345.9z"
                            />
                          </g>
                          <g id="XMLID_490_">
                            <path
                              id="XMLID_508_"
                              style="fill: #ffffff"
                              d="M519.6,351.7l-12.4-7.1c-0.4-0.2-0.7-0.8-0.7-1.3V330
                        c0-0.5,0.3-0.7,0.7-0.5l12.4,7.1c0.4,0.2,0.7,0.8,0.7,1.3v13.2C520.3,351.7,520,352,519.6,351.7z"
                            />
                            <path
                              id="XMLID_494_"
                              d="M519.9,352.2c-0.2,0-0.3,0-0.5-0.1l-12.4-7.1c-0.5-0.3-0.9-1-0.9-1.7V330c0-0.4,0.2-0.7,0.4-0.9
                        c0.2-0.1,0.6-0.1,0.8,0l12.4,7.1c0.5,0.3,0.9,1,0.9,1.7v13.2c0,0.4-0.2,0.7-0.4,0.9C520.1,352.2,520,352.2,519.9,352.2z
                         M507,329.8L507,329.8c0,0,0,0.1,0,0.2v13.2c0,0.4,0.3,0.8,0.5,1l12.4,7.1c0,0,0.1,0,0.1,0l0,0c0,0,0-0.1,0-0.2V338
                        c0-0.4-0.3-0.8-0.5-1L507,329.8C507,329.8,507,329.8,507,329.8z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_480_">
                          <rect
                            id="XMLID_483_"
                            x="508.8"
                            y="329.4"
                            transform="matrix(0.8487 -0.5288 0.5288 0.8487 -97.1515 319.8909)"
                            width="3.6"
                            height="0.8"
                          />
                        </g>
                        <g id="XMLID_474_">
                          <rect
                            id="XMLID_476_"
                            x="520.3"
                            y="348.1"
                            transform="matrix(0.8567 -0.5158 0.5158 0.8567 -104.9124 319.1315)"
                            style="fill: #ffffff"
                            width="3.2"
                            height="0.6"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_448_">
                      <g id="XMLID_455_">
                        <path
                          id="XMLID_465_"
                          style="fill: #ffffff"
                          d="M516.5,339.1c-1.3-2.2-2.8-1.1-3.1-0.8c0,0-0.1,0-0.1,0
                    c-0.3-0.6-1.7-3.4-3.1-2.7c-1.7,0.8,0.4,4.5,0.4,4.5l2.7,5.3c0,0,0.1,0.1,0.1,0l2.7-2.2
                    C516.2,343.1,518.2,341.8,516.5,339.1z"
                        />
                        <path
                          id="XMLID_459_"
                          d="M513.5,345.7l-0.2,0c-0.1,0-0.3-0.1-0.3-0.3l-2.7-5.3c-0.1-0.2-1.4-2.6-1-4
                    c0.1-0.4,0.4-0.7,0.8-0.9c0.5-0.3,1.3-0.3,2.4,1c0.4,0.5,0.8,1.1,1,1.6c0.3-0.2,0.8-0.4,1.4-0.3c0.7,0.1,1.3,0.6,1.9,1.5
                    c1.9,3.1-0.5,4.6-0.5,4.6L513.5,345.7z M510.7,335.8c-0.1,0-0.2,0-0.2,0.1c-0.2,0.1-0.3,0.2-0.4,0.5
                    c-0.3,1,0.6,2.8,0.9,3.4l2.5,5l2.4-1.9c0.1-0.1,1.7-1.1,0.3-3.5l0,0c-0.4-0.7-0.9-1-1.3-1.1c-0.6-0.1-1,0.3-1.2,0.4
                    l-0.1,0.1l-0.2,0c-0.1,0-0.3-0.1-0.3-0.3c-0.1-0.2-0.5-1.1-1.1-1.8C511.6,336.2,511.1,335.8,510.7,335.8z"
                        />
                      </g>
                      <g id="XMLID_451_">
                        <rect
                          id="XMLID_452_"
                          x="512.5"
                          y="339.3"
                          transform="matrix(0.8888 -0.4583 0.4583 0.8888 -99.2042 272.9751)"
                          width="0.8"
                          height="3.3"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="XMLID_280_">
                  <g id="XMLID_311_">
                    <g id="XMLID_312_">
                      <g id="XMLID_317_">
                        <g id="XMLID_328_">
                          <path
                            id="XMLID_332_"
                            style="fill: #ffffff"
                            d="M489.3,311.1l-15.3-8.8c-0.2-0.1-0.4-0.1-0.5,0l0,0v0c0,0,0,0-0.1,0
                      l-3.9,2.3c0,0,0,0,0,0l3.7,6.2c0,0,0,0,0,0v8.5c0,0.6,0.4,1.4,0.9,1.6l7.9,4.6c0,0,0,0,0,0l4,6.7c0,0,0,0,0,0l4-2.4
                      c0.2-0.1,0.3-0.3,0.3-0.7v-16.4C490.1,312.1,489.7,311.3,489.3,311.1z"
                          />
                          <path
                            id="XMLID_329_"
                            d="M485.7,332.7l-0.2-0.3l-3.9-6.6l-7.8-4.5c-0.6-0.3-1-1.2-1-2v-8.4l-3.7-6.3l0.1-0.3l0.2-0.1
                      l4-2.4c0.3-0.2,0.6-0.1,0.9,0l15.3,8.8c0.6,0.3,1,1.2,1,2v16.4c0,0.4-0.2,0.8-0.4,1L485.7,332.7z M469.9,304.7l3.6,6
                      l0,0.1v8.5c0,0.5,0.3,1.1,0.7,1.3l8,4.6l0.1,0.1l3.8,6.3l3.7-2.2c0,0,0.1-0.1,0.1-0.3v-16.4c0-0.5-0.3-1.1-0.7-1.3
                      l-15.3-8.8c0,0-0.1-0.1-0.2,0l-0.1,0L469.9,304.7z"
                          />
                        </g>
                        <g id="XMLID_323_">
                          <path
                            id="XMLID_327_"
                            d="M481.1,323.3C481.1,323.3,481.1,323.3,481.1,323.3l0.1,1.8l0.6,0.4c0,0,0,0,0,0l4,6.6
                      c0,0,0.1,0,0.1,0l4-2.3c0.2-0.1,0.3-0.3,0.3-0.7v-16.4c0,0,0-0.1-0.1,0l-3.9,2.3c0,0,0,0,0,0L481.1,323.3z"
                          />
                          <path
                            id="XMLID_324_"
                            d="M486,332.6l-0.4-0.1l-4-6.7l-0.7-0.4l-0.1-2.1l0.1-0.1l5.1-8.3l0.1-0.1l4.1-2.4l0.2,0.1
                      c0.1,0.1,0.2,0.2,0.2,0.4v16.4c0,0.4-0.2,0.8-0.4,1L486,332.6z M481.6,324.9l0.5,0.3l0.1,0.1l3.8,6.3l3.7-2.2
                      c0,0,0.1-0.1,0.1-0.3v-15.7l-3.3,2l-4.9,8.1L481.6,324.9z"
                          />
                        </g>
                        <g id="XMLID_318_">
                          <path
                            id="XMLID_322_"
                            style="fill: #ffffff"
                            d="M485.3,332.1l-15.3-8.8c-0.5-0.3-0.9-1-0.9-1.6v-16.4
                      c0-0.6,0.4-0.9,0.9-0.6l15.3,8.8c0.5,0.3,0.9,1,0.9,1.6v16.4C486.1,332.1,485.8,332.4,485.3,332.1z"
                          />
                          <path
                            id="XMLID_319_"
                            d="M485.6,332.6c-0.2,0-0.4-0.1-0.5-0.2l-15.3-8.8c-0.6-0.3-1-1.2-1-2v-16.4c0-0.5,0.2-0.8,0.5-1
                      c0.3-0.2,0.6-0.1,0.9,0l15.3,8.8c0.6,0.3,1,1.2,1,2v16.4c0,0.5-0.2,0.8-0.5,1C485.9,332.6,485.8,332.6,485.6,332.6z
                       M485.5,331.8c0.1,0,0.1,0.1,0.2,0c0,0,0.1-0.1,0.1-0.4v-16.4c0-0.5-0.3-1.1-0.7-1.3l-15.3-8.8c-0.1,0-0.1-0.1-0.2,0
                      c0,0-0.1,0.1-0.1,0.4v16.4c0,0.5,0.3,1.1,0.7,1.3L485.5,331.8z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_315_">
                        <rect
                          id="XMLID_316_"
                          x="471.9"
                          y="304.5"
                          transform="matrix(0.8487 -0.5288 0.5288 0.8487 -89.5077 296.8385)"
                          width="4.5"
                          height="0.8"
                        />
                      </g>
                      <g id="XMLID_313_">
                        <rect
                          id="XMLID_314_"
                          x="486.2"
                          y="327.7"
                          transform="matrix(0.8567 -0.5158 0.5158 0.8567 -99.2193 298.8012)"
                          style="fill: #ffffff"
                          width="3.9"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_281_">
                    <g id="XMLID_306_">
                      <path
                        id="XMLID_310_"
                        style="fill: #ffffff"
                        d="M473.3,315.6c0,0-1,0.1-0.9,2.4c0.2,2.4,1,3.5,1,3.5l2,0.7
                  c0,0,0,0.1,0.1,0.2c1.3,1.8,3,2.8,4.2,2.4l0.8-0.2l-0.1-5.2c0,0-1.1-0.9-1.6-1.9c-0.5-1,0.2-1.1,0.1-2.2
                  c0-1.2-0.9-3.2-1.8-3.2c-0.9,0-0.5,1-0.3,1.9c0.2,0.9-0.3,1.8-1,2.1s-0.9,0.7-0.9,0.7S474.6,316.2,473.3,315.6z"
                      />
                      <path
                        id="XMLID_307_"
                        d="M479.3,325.2c-1.3,0-2.9-1-4-2.6c0,0,0,0,0-0.1l-2-0.7l-0.1-0.1c0,0-1-1.2-1.1-3.7
                  c-0.1-1.2,0.1-2,0.5-2.5c0.3-0.4,0.6-0.4,0.7-0.4l0.1,0l0.1,0c0.7,0.3,1.2,0.7,1.5,1c0.2-0.1,0.4-0.3,0.7-0.5
                  c0.5-0.3,0.9-0.9,0.8-1.7c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.6-0.3-1.3,0-1.7c0.2-0.2,0.4-0.3,0.8-0.3c1.3,0,2.1,2.4,2.2,3.6
                  c0,0.6-0.1,0.9-0.2,1.2c-0.1,0.3-0.2,0.5,0,0.9c0.5,0.9,1.5,1.8,1.5,1.8l0.1,0.1l0.2,5.6l-1.1,0.3
                  C479.8,325.2,479.5,325.2,479.3,325.2z M473.8,321.2l2,0.7l0.1,0.1c0,0,0,0.1,0.1,0.2c1.1,1.6,2.7,2.6,3.8,2.3l0.5-0.1
                  l-0.1-4.7c-0.3-0.3-1.1-1.1-1.6-1.9c-0.4-0.7-0.2-1.1-0.1-1.5c0.1-0.2,0.2-0.5,0.2-0.9c0-1.2-0.9-2.8-1.4-2.8
                  c-0.1,0-0.1,0-0.1,0c-0.1,0.1,0.1,0.7,0.1,1c0,0.1,0.1,0.3,0.1,0.4c0.2,1-0.3,2.1-1.2,2.6c-0.5,0.3-0.7,0.6-0.7,0.6
                  l-0.3,0.6l-0.4-0.6c0,0-0.3-0.6-1.3-1.1c-0.2,0.1-0.5,0.6-0.4,2C473,319.9,473.6,320.9,473.8,321.2z"
                      />
                    </g>
                    <g id="XMLID_304_">
                      <path
                        id="XMLID_305_"
                        d="M475.2,322.4c-0.4-0.7-0.5-1.8-0.5-1.9l0.8-0.1c0,0,0.1,1,0.4,1.5L475.2,322.4z"
                      />
                    </g>
                    <g id="XMLID_302_">
                      <path
                        id="XMLID_303_"
                        d="M475.2,317.9l-0.8,0c0-0.7,0.1-1.1,0.2-1.3l0.6,0.5C475.3,317.2,475.2,317.6,475.2,317.9z"
                      />
                    </g>
                    <g id="XMLID_297_">
                      <path
                        id="XMLID_301_"
                        style="fill: #ffffff"
                        d="M481.6,321.9l-2.5-1.4c-0.4-0.2-0.7-0.8-0.7-1.4l0,0c0-0.5,0.3-0.8,0.7-0.5
                  l2.5,1.4c0.4,0.2,0.7,0.8,0.7,1.4l0,0C482.3,321.9,482,322.1,481.6,321.9z"
                      />
                      <path
                        id="XMLID_298_"
                        d="M481.9,322.4c-0.2,0-0.3,0-0.5-0.1l-2.5-1.4c-0.5-0.3-0.9-1-0.9-1.7c0-0.4,0.2-0.8,0.4-0.9
                  c0.3-0.1,0.6-0.1,0.9,0l2.5,1.4c0.5,0.3,0.9,1,0.9,1.7c0,0.4-0.2,0.8-0.4,0.9C482.1,322.4,482,322.4,481.9,322.4z
                   M478.8,318.9L478.8,318.9c0,0,0,0.1,0,0.2c0,0.4,0.3,0.9,0.5,1l2.5,1.4c0.1,0,0.1,0,0.1,0l0,0c0,0,0-0.1,0-0.2
                  c0-0.4-0.3-0.9-0.5-1L478.8,318.9C478.8,318.9,478.8,318.9,478.8,318.9z"
                      />
                    </g>
                    <g id="XMLID_292_">
                      <path
                        id="XMLID_296_"
                        style="fill: #ffffff"
                        d="M481.4,323.4l-2.2-1.3c-0.3-0.2-0.6-0.7-0.6-1.1l0,0c0-0.4,0.3-0.6,0.6-0.4
                  l2.2,1.3c0.3,0.2,0.6,0.7,0.6,1.1l0,0C482,323.4,481.7,323.6,481.4,323.4z"
                      />
                      <path
                        id="XMLID_293_"
                        d="M481.6,323.8c-0.1,0-0.3,0-0.4-0.1l-2.2-1.3c-0.4-0.3-0.8-0.9-0.8-1.5c0-0.4,0.1-0.7,0.4-0.8
                  c0.2-0.1,0.5-0.1,0.8,0l2.2,1.3c0.4,0.3,0.8,0.9,0.8,1.5c0,0.4-0.1,0.7-0.4,0.8C481.9,323.8,481.8,323.8,481.6,323.8z
                   M479.1,320.9c0,0,0,0.1,0,0.1c0,0.3,0.2,0.7,0.4,0.8l2.2,1.2c0,0,0-0.1,0-0.1c0-0.3-0.2-0.7-0.4-0.8L479.1,320.9z"
                      />
                    </g>
                    <g id="XMLID_287_">
                      <path
                        id="XMLID_291_"
                        style="fill: #ffffff"
                        d="M481.2,324.8l-1.9-1.1c-0.3-0.2-0.6-0.7-0.6-1.1l0,0c0-0.4,0.3-0.6,0.6-0.4
                  l1.9,1.1c0.3,0.2,0.6,0.7,0.6,1.1l0,0C481.7,324.8,481.5,325,481.2,324.8z"
                      />
                      <path
                        id="XMLID_288_"
                        d="M481.4,325.3c-0.1,0-0.3,0-0.4-0.1L479,324c-0.4-0.3-0.8-0.9-0.8-1.5c0-0.4,0.1-0.7,0.4-0.8
                  c0.2-0.1,0.5-0.1,0.8,0l1.9,1.1c0.4,0.3,0.8,0.9,0.8,1.5c0,0.4-0.1,0.7-0.4,0.8C481.6,325.2,481.5,325.3,481.4,325.3z
                   M479.1,322.5c0,0,0,0.1,0,0.1c0,0.3,0.2,0.7,0.4,0.8l1.9,1.1c0,0,0-0.1,0-0.1c0-0.3-0.2-0.7-0.4-0.8L479.1,322.5z"
                      />
                    </g>
                    <g id="XMLID_282_">
                      <path
                        id="XMLID_286_"
                        style="fill: #ffffff"
                        d="M480.8,326.2l-1.4-0.8c-0.3-0.2-0.6-0.7-0.6-1.1l0,0c0-0.4,0.3-0.6,0.6-0.4
                  l1.4,0.8c0.3,0.2,0.6,0.7,0.6,1.1l0,0C481.4,326.2,481.1,326.4,480.8,326.2z"
                      />
                      <path
                        id="XMLID_283_"
                        d="M481.1,326.6c-0.1,0-0.3,0-0.4-0.1l-1.4-0.8c-0.4-0.3-0.8-0.9-0.8-1.5c0-0.4,0.1-0.7,0.4-0.8
                  c0.2-0.1,0.5-0.1,0.8,0l1.4,0.8c0.4,0.3,0.8,0.9,0.8,1.5c0,0.4-0.1,0.7-0.4,0.8C481.3,326.6,481.2,326.6,481.1,326.6z
                   M479.3,324.2c0,0,0,0.1,0,0.1c0,0.3,0.2,0.7,0.4,0.8l1.3,0.8c0,0,0-0.1,0-0.1c0-0.3-0.2-0.7-0.4-0.8L479.3,324.2z"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID_249_">
                  <g id="XMLID_258_">
                    <g id="XMLID_259_">
                      <g id="XMLID_264_">
                        <g id="XMLID_275_">
                          <path
                            id="XMLID_279_"
                            style="fill: #ffffff"
                            d="M411,311.6l-12.4-7.1c-0.2-0.1-0.3-0.1-0.4,0l0,0l0,0c0,0,0,0-0.1,0
                      l-3.2,1.9c0,0,0,0,0,0l3,5c0,0,0,0,0,0v6.8c0,0.5,0.3,1.1,0.7,1.3l6.4,3.7c0,0,0,0,0,0l3.2,5.4c0,0,0,0,0,0l3.2-1.9
                      c0.1-0.1,0.2-0.3,0.2-0.5v-13.2C411.7,312.4,411.4,311.8,411,311.6z"
                          />
                          <path
                            id="XMLID_276_"
                            d="M408.3,329l-0.3-0.1l-0.1-0.2l-3.2-5.3l-6.3-3.6c-0.5-0.3-0.9-1-0.9-1.7v-6.7l-3.1-5.3l0.3-0.2
                      l3.2-1.9c0.3-0.1,0.6-0.1,0.8,0l12.4,7.1c0.5,0.3,0.9,1,0.9,1.7v13.2c0,0.4-0.1,0.7-0.4,0.9L408.3,329z M395.5,306.5
                      l2.9,4.8l0,0.1v6.8c0,0.4,0.3,0.8,0.5,1l6.5,3.7l0.1,0.1l3,5l2.9-1.7c0,0,0-0.1,0-0.2v-13.2c0-0.4-0.3-0.8-0.5-1
                      l-12.4-7.1c0,0-0.1,0-0.1,0l0,0L395.5,306.5z"
                          />
                        </g>
                        <g id="XMLID_270_">
                          <path
                            id="XMLID_274_"
                            d="M404.4,321.4C404.4,321.4,404.4,321.4,404.4,321.4l0.1,1.5l0.5,0.3c0,0,0,0,0,0l3.2,5.3
                      c0,0,0.1,0,0.1,0l3.2-1.9c0.1-0.1,0.2-0.3,0.2-0.5v-13.2c0,0,0,0-0.1,0l-3.1,1.9c0,0,0,0,0,0L404.4,321.4z"
                          />
                          <path
                            id="XMLID_271_"
                            d="M408.3,329l-0.4-0.1l-3.2-5.4l-0.6-0.3l-0.1-1.8l0.1-0.1l4.1-6.7l0.1-0.1l3.3-2l0.4,0.2l0,0.3
                      v13.2c0,0.4-0.1,0.7-0.4,0.9L408.3,329z M404.9,322.7l0.4,0.2l0.1,0.1l3,5l2.9-1.7c0,0,0-0.1,0-0.2v-12.6l-2.5,1.5
                      l-3.9,6.5L404.9,322.7z"
                          />
                        </g>
                        <g id="XMLID_265_">
                          <path
                            id="XMLID_269_"
                            style="fill: #ffffff"
                            d="M407.8,328.5l-12.4-7.1c-0.4-0.2-0.7-0.8-0.7-1.3v-13.2
                      c0-0.5,0.3-0.7,0.7-0.5l12.4,7.1c0.4,0.2,0.7,0.8,0.7,1.3V328C408.5,328.5,408.2,328.8,407.8,328.5z"
                          />
                          <path
                            id="XMLID_266_"
                            d="M408.1,329c-0.2,0-0.3,0-0.5-0.1l-12.4-7.1c-0.5-0.3-0.9-1-0.9-1.7v-13.2c0-0.4,0.2-0.7,0.4-0.9
                      c0.2-0.1,0.6-0.1,0.8,0l12.4,7.1c0.5,0.3,0.9,1,0.9,1.7V328c0,0.4-0.2,0.7-0.4,0.9C408.3,329,408.2,329,408.1,329z
                       M395.1,306.6L395.1,306.6c0,0,0,0.1,0,0.2v13.2c0,0.4,0.3,0.8,0.5,1l12.4,7.1c0,0,0.1,0,0.1,0l0,0c0,0,0-0.1,0-0.2v-13.2
                      c0-0.4-0.3-0.8-0.5-1L395.1,306.6C395.2,306.6,395.2,306.6,395.1,306.6z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_262_">
                        <rect
                          id="XMLID_263_"
                          x="397"
                          y="306.2"
                          transform="matrix(0.8487 -0.5288 0.5288 0.8487 -101.7965 257.2505)"
                          width="3.6"
                          height="0.8"
                        />
                      </g>
                      <g id="XMLID_260_">
                        <rect
                          id="XMLID_261_"
                          x="408.5"
                          y="324.9"
                          transform="matrix(0.8567 -0.5158 0.5158 0.8567 -108.9658 258.1065)"
                          style="fill: #ffffff"
                          width="3.2"
                          height="0.6"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_250_">
                    <g id="XMLID_253_">
                      <path
                        id="XMLID_257_"
                        style="fill: #ffffff"
                        d="M402.1,313.7l0.8,2.3c0.1,0.2,0.2,0.5,0.4,0.6l1.8,1.3
                  c0.5,0.4,0.7,1.1,0.3,1.3l-1.3,0.7c-0.1,0.1-0.2,0.3-0.2,0.5l0.3,2.2c0.1,0.6-0.4,0.7-0.8,0.2l-1.6-1.9
                  c-0.2-0.2-0.4-0.3-0.5-0.3l-1.6,0c-0.4,0-0.9-0.7-0.8-1.2l0.3-1.9c0-0.2,0-0.5-0.2-0.7l-1.3-2.2c-0.3-0.6-0.2-1.1,0.3-1
                  l1.8,0.7c0.2,0.1,0.3,0,0.4-0.1l0.8-1.4C401.2,312.7,401.9,313.1,402.1,313.7z"
                      />
                      <path
                        id="XMLID_254_"
                        d="M403.9,323.5c-0.3,0-0.6-0.2-0.8-0.4l-1.6-1.9c-0.1-0.1-0.2-0.2-0.2-0.2l-1.6,0
                  c-0.3,0-0.6-0.1-0.8-0.4c-0.3-0.4-0.5-0.8-0.4-1.2l0.3-1.8c0-0.1,0-0.3-0.1-0.4l-1.3-2.2c-0.3-0.5-0.3-1,0-1.3
                  c0.2-0.2,0.5-0.3,0.8-0.2l1.7,0.7l0.8-1.3c0.1-0.2,0.4-0.4,0.7-0.3c0.4,0,0.9,0.5,1.1,1l0.8,2.3c0.1,0.2,0.2,0.3,0.3,0.4
                  l1.8,1.3c0.4,0.3,0.7,0.9,0.6,1.3c0,0.3-0.2,0.5-0.4,0.6l-1.3,0.7c0,0,0,0,0,0.1l0.3,2.2c0.1,0.5-0.2,0.8-0.4,0.9
                  C404.1,323.5,404,323.5,403.9,323.5z M401.3,320.2c0.3,0,0.6,0.2,0.8,0.4l1.6,1.9c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0-0.1
                  l-0.3-2.2c-0.1-0.4,0.1-0.7,0.4-0.9l1.3-0.7c0-0.1-0.1-0.5-0.4-0.7l-1.8-1.3c-0.3-0.2-0.5-0.5-0.6-0.8l-0.8-2.3
                  c-0.1-0.3-0.3-0.4-0.4-0.5l-0.8,1.3c-0.2,0.3-0.6,0.4-0.9,0.3l-1.7-0.7c0,0.1,0,0.2,0.1,0.4l1.3,2.2
                  c0.2,0.3,0.3,0.7,0.2,0.9l-0.3,1.8c0,0.1,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.2,0.2,0.2L401.3,320.2
                  C401.3,320.2,401.3,320.2,401.3,320.2z"
                      />
                    </g>
                    <g id="XMLID_251_">
                      <rect
                        id="XMLID_252_"
                        x="401.4"
                        y="315.7"
                        transform="matrix(0.9471 -0.321 0.321 0.9471 -80.3381 145.7288)"
                        width="0.8"
                        height="1.5"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g id="XMLID_8700_">
                    <g id="XMLID_8791_">
                      <g id="XMLID_8891_">
                        <g id="XMLID_8908_">
                          <g id="XMLID_8927_">
                            <path
                              id="XMLID_8936_"
                              style="fill: #ffffff"
                              d="M460,342.6l-8.6-4.9c-0.1-0.1-0.2-0.1-0.3,0l-3.6,2.2
                        c-0.1,0-0.1,0.1,0,0.2l3.5,3.7c0,0,0,0,0,0.1v1.2c0,0.4,0.2,0.8,0.5,0.9l1.2-1.4c0,0,0,0,0.1,0.1l3.8,4.3
                        c0,0.1,0.1,0.1,0.2,0l3.6-2.3c0,0,0.2-0.2,0.2-0.4v-2.8C460.4,343.1,460.2,342.7,460,342.6z"
                            />
                            <path
                              id="XMLID_8929_"
                              d="M456.6,349.4c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.2l-3.6-4l-1.1,1.3l-0.3-0.2
                        c-0.4-0.2-0.7-0.8-0.7-1.2V344l-3.4-3.7c-0.1-0.2-0.1-0.6,0.2-0.7l3.6-2.2c0.2-0.1,0.5-0.1,0.7,0l8.6,4.9
                        c0.4,0.2,0.7,0.8,0.7,1.2v2.8c0,0.3-0.2,0.6-0.2,0.6l-0.1,0.1l-3.6,2.3C456.8,349.3,456.7,349.4,456.6,349.4z M453,344.4
                        l3.7,4.2l3.4-2.1c0,0,0-0.1,0-0.1l0-2.8c0-0.2-0.2-0.5-0.3-0.6l-8.5-4.9l-3.3,2l3.3,3.6c0,0.1,0.1,0.2,0.1,0.3v1.2
                        c0,0.1,0,0.2,0.1,0.3l1.1-1.3l0.3,0.2C452.9,344.3,452.9,344.3,453,344.4z"
                            />
                          </g>
                          <g id="XMLID_8919_">
                            <path
                              id="XMLID_8926_"
                              d="M453.9,346.5c0,0,0,0.1,0,0.2l2.5,2.1c0.1,0.1,0.2,0.2,0.3,0.1l3.5-2.2c0,0,0.2-0.2,0.2-0.4
                        v-2.7c0-0.1-0.1-0.1-0.1-0.1l-3.6,2.2c0,0,0,0,0,0L453.9,346.5z"
                            />
                            <path
                              id="XMLID_8921_"
                              d="M456.7,349.3c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.2l-2.6-2.2l0.1-0.7l3-0.7l3.6-2.2
                        c0.2-0.1,0.3-0.1,0.5,0c0.2,0.1,0.3,0.2,0.3,0.4v2.7c0,0.3-0.2,0.6-0.2,0.6l-0.1,0.1l-3.5,2.2
                        C456.9,349.3,456.8,349.3,456.7,349.3z M454.6,346.7l2.1,1.8l3.4-2.1c0,0,0-0.1,0-0.1l0-2.1l-3.1,1.9l0,0.1L454.6,346.7z
                        "
                            />
                          </g>
                          <g id="XMLID_8909_">
                            <path
                              id="XMLID_8918_"
                              style="fill: #ffffff"
                              d="M456.2,349l-8.6-4.9c-0.3-0.2-0.5-0.6-0.5-0.9v-2.8
                        c0-0.4,0.2-0.5,0.5-0.4l8.6,4.9c0.3,0.2,0.5,0.6,0.5,0.9v2.8C456.7,349,456.5,349.2,456.2,349z"
                            />
                            <path
                              id="XMLID_8911_"
                              d="M456.4,349.5c-0.1,0-0.3,0-0.4-0.1l-8.6-4.9c-0.4-0.2-0.7-0.8-0.7-1.2v-2.8
                        c0-0.3,0.1-0.6,0.4-0.7c0.2-0.1,0.5-0.1,0.7,0l8.6,4.9c0.4,0.2,0.7,0.8,0.7,1.2v2.8c0,0.3-0.1,0.6-0.4,0.7
                        C456.6,349.4,456.5,349.5,456.4,349.5z M447.6,340.4v2.8c0,0.2,0.2,0.5,0.3,0.6l8.4,4.9v-2.8c0-0.2-0.2-0.5-0.3-0.6
                        L447.6,340.4z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_8900_">
                          <rect
                            id="XMLID_8907_"
                            x="449.5"
                            y="339.5"
                            transform="matrix(0.8487 -0.5288 0.5288 0.8487 -111.437 290.213)"
                            width="4.2"
                            height="0.8"
                          />
                        </g>
                        <g id="XMLID_8897_">
                          <rect
                            id="XMLID_8898_"
                            x="456.8"
                            y="346.2"
                            transform="matrix(0.8567 -0.5159 0.5159 0.8567 -113.0385 286.2513)"
                            style="fill: #ffffff"
                            width="3.7"
                            height="0.6"
                          />
                        </g>
                      </g>
                      <g id="XMLID_8793_">
                        <g id="XMLID_8794_">
                          <g id="XMLID_8815_">
                            <g id="XMLID_8880_">
                              <path
                                id="XMLID_8890_"
                                style="fill: #ffffff"
                                d="M459.6,298.9l-35.4-20.3c-0.2-0.1-0.4-0.1-0.5,0l0,0l0,0c0,0,0,0,0,0
                          l-3.5,2.1c-0.1,0.1-0.1,0.2-0.1,0.2l3.3,5.8c0,0,0,0.1,0,0.1v30.9c0,0.6,0.4,1.3,0.8,1.5l28.7,16.5c0,0,0.1,0,0.1,0.1
                          l3.3,5.8c0,0.1,0.2,0.1,0.2,0.1l3.6-2.1c0.2-0.1,0.3-0.3,0.3-0.6v-38.5C460.4,299.8,460,299.2,459.6,298.9z"
                              />
                              <path
                                id="XMLID_8882_"
                                d="M456.5,342.1c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.3l-3.3-5.8L424,319.6
                          c-0.6-0.3-1-1.1-1-1.9v-30.8l-3.3-5.8c-0.2-0.3-0.1-0.6,0.2-0.8l3.5-2.1c0.3-0.2,0.6-0.2,1,0l35.4,20.3
                          c0.6,0.3,1,1.1,1,1.9V339c0,0.4-0.1,0.8-0.4,1l-3.6,2.1C456.7,342.1,456.6,342.1,456.5,342.1z M420.5,280.9l3.2,5.7
                          c0,0.1,0.1,0.2,0.1,0.3v30.9c0,0.5,0.3,1,0.6,1.2l28.7,16.5c0.1,0.1,0.2,0.1,0.2,0.2l3.2,5.6l3.4-2c0,0,0.1-0.1,0.1-0.3
                          v-38.5c0-0.5-0.3-1-0.6-1.2L424,278.9c-0.1,0-0.1,0-0.1,0L420.5,280.9z"
                              />
                            </g>
                            <g id="XMLID_8851_">
                              <path
                                id="XMLID_8879_"
                                d="M435.5,303.7c0.1,0.5,1.3,17.2,2.5,23.4c0,0,0,0,0,0l14.9,8.5c0,0,0.1,0.1,0.1,0.1l3.3,5.8
                          c0.1,0.1,0.2,0.2,0.3,0.1l3.5-2.1c0.2-0.1,0.3-0.3,0.3-0.6v-38.4c0,0,0-0.1-0.1,0l-3.7,2.2c0,0,0,0,0,0L435.5,303.7
                          C435.5,303.7,435.5,303.7,435.5,303.7z"
                              />
                              <path
                                id="XMLID_8852_"
                                d="M456.5,342.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.3l-3.3-5.7l-15-8.6l-0.1-0.2
                          c-1-4.8-1.9-16.2-2.3-21.1c-0.1-1.3-0.2-2.2-0.2-2.4l0.4-0.1l-0.4,0.1l0-0.2l0.3-0.3l0.1,0l21.1-1l3.8-2.2l0.3,0.1
                          c0.1,0.1,0.2,0.2,0.2,0.4V339c0,0.4-0.1,0.8-0.4,1l-3.5,2.1C456.7,342.1,456.6,342.1,456.5,342.1z M438.4,326.9
                          l14.9,8.5l0.1,0.2l3.2,5.6l3.4-2c0,0,0.1-0.1,0.1-0.3v-37.9l-3.2,1.9l-0.1,0l-20.8,1c0,0.4,0.1,1,0.2,2.1
                          C436.5,310.9,437.4,322.1,438.4,326.9z"
                              />
                            </g>
                            <g id="XMLID_8816_">
                              <path
                                id="XMLID_8840_"
                                style="fill: #ffffff"
                                d="M455.8,341.8l-35.4-20.3c-0.4-0.3-0.8-0.9-0.8-1.5v-38.5
                          c0-0.6,0.4-0.9,0.8-0.6l35.4,20.3c0.4,0.3,0.8,0.9,0.8,1.5v38.5C456.6,341.8,456.3,342.1,455.8,341.8z"
                              />
                              <path
                                id="XMLID_8835_"
                                d="M456.2,342.3c-0.2,0-0.3,0-0.5-0.1l-35.4-20.3c-0.6-0.3-1-1.1-1-1.9v-38.5
                          c0-0.5,0.2-0.8,0.5-1c0.3-0.2,0.6-0.1,0.9,0l35.4,20.3c0.6,0.3,1,1.1,1,1.9v38.5c0,0.5-0.2,0.8-0.5,1
                          C456.4,342.3,456.3,342.3,456.2,342.3z M420.2,281.1C420.2,281.1,420.2,281.1,420.2,281.1c0,0-0.1,0.1-0.1,0.3v38.5
                          c0,0.5,0.3,1,0.6,1.2l35.4,20.3c0.1,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.3v-38.5c0-0.5-0.3-1-0.6-1.2l-35.4-20.3
                          C420.2,281.1,420.2,281.1,420.2,281.1z"
                              />
                            </g>
                          </g>
                          <g id="XMLID_8812_">
                            <rect
                              id="XMLID_8813_"
                              x="422.8"
                              y="280.7"
                              transform="matrix(0.8487 -0.5288 0.5288 0.8487 -84.3767 267.2001)"
                              width="4.2"
                              height="0.8"
                            />
                          </g>
                          <g id="XMLID_8796_">
                            <rect
                              id="XMLID_8804_"
                              x="456.7"
                              y="335.9"
                              transform="matrix(0.8567 -0.5159 0.5159 0.8567 -107.7098 284.7393)"
                              style="fill: #ffffff"
                              width="3.7"
                              height="0.6"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <circle
                    style="stroke: #000000; stroke-miterlimit: 10"
                    cx="438.9"
                    cy="309.9"
                    r="11.7"
                  />
                  <g>
                    <path
                      style="fill: #ffffff"
                      d="M434.9,324.3c-6.8,0-12.4-5.5-12.4-12.4s5.5-12.4,12.4-12.4c6.8,0,12.4,5.5,12.4,12.4
                S441.8,324.3,434.9,324.3z"
                    />
                    <path
                      d="M434.9,300.1c6.5,0,11.9,5.3,11.9,11.9c0,6.5-5.3,11.9-11.9,11.9s-11.9-5.3-11.9-11.9
                C423.1,305.4,428.4,300.1,434.9,300.1 M434.9,299.1c-7.1,0-12.9,5.8-12.9,12.9s5.8,12.9,12.9,12.9s12.9-5.8,12.9-12.9
                S442,299.1,434.9,299.1L434.9,299.1z"
                    />
                  </g>
                  <line
                    style="fill: none; stroke: #000000; stroke-miterlimit: 10"
                    x1="438.9"
                    y1="298.4"
                    x2="432"
                    y2="300.6"
                  />
                  <line
                    style="fill: none; stroke: #000000; stroke-miterlimit: 10"
                    x1="448"
                    y1="317.3"
                    x2="442.4"
                    y2="321.8"
                  />
                  <g>
                    <g>
                      <path
                        style="fill: #b3b3b2"
                        d="M425.3,306.6l0.2-0.6v-1.4c-1.3,1.6-2.1,3.6-2.4,5.8c0.3-0.2,0.6-0.9,0.8-0.9h0.8v-0.8
                  l0.2-0.7l0.1-0.5L425.3,306.6z"
                      />
                      <path
                        style="fill: #b3b3b2"
                        d="M440.3,302.4c-0.6,0.3-0.9,0.2-0.8,0.3c0.1,0.1,0.8,0.6,0.8,0.6s0.3-0.4,2.1-0.6
                  c-0.2-0.2-0.4-0.3-0.7-0.5l-0.2,0.1C441.5,302.4,440.9,302.2,440.3,302.4z"
                      />
                      <path
                        style="fill: #b3b3b2"
                        d="M446.3,310.3c-0.2,0.2,0,0.6-0.4,0.6c-0.4,0-0.6-0.2-0.9,0c-0.3,0.2-0.3,0.3-0.3,0.5
                  c0,0.2,0.4,0.6,0.3,1.4c0,0.8,0.2,1.4-0.2,1.4c-0.4,0-0.9-0.4-1.1-0.6c-0.3-0.3-0.7-0.5-1.1-0.5c-0.4,0-0.5,0.2-1.1,0.1
                  c-0.6-0.1-1.1,0.1-1.1,0.3c0,0.2,0.1,0.4,0.4,0.3c0.3,0,1.1-0.2,1.1,0.3s-1,1.3-1.2,1.9c-0.2,0.6,0.1,1.1-0.4,1.5
                  c-0.5,0.4-1.3,1.3-1.5,1.6c-0.2,0.3-0.4,1.1-0.6,1.3c-0.3,0.2-1.1,1.2-1.5,1.5c-0.5,0.3-0.8,0.5-1.3,0.8
                  c-0.5,0.3-0.8,0.6-1.5,0.7c-0.7,0-0.9,0.2-1.3,0.1c-0.4-0.1-0.8,0.1-0.8-0.6c0-0.5,0.8-1.1,1-1.5c0.2-0.4,0.6-0.9,0.7-1.3
                  c0-0.3,0.5-0.7,0.3-1.1c-0.1-0.4-1.5-1.2-1.8-1.9c-0.2-0.7-0.8-1-0.6-1.4c0.2-0.5,0.7-1.2,0.8-1.3c0.1-0.2,0.4-0.7,0.7-0.9
                  c0.3-0.1,1-0.4,1.2-0.3c0.3,0.2,0.5,0.1,1.2,0.3c0.8,0.2,1.1,0.3,1.7,0.5c0.5,0.1,1.1,0.3,1.1,0.1c0-0.2,0.1-0.7,0-0.8
                  c-0.1-0.1-0.4-0.2-0.8-0.3c-0.4,0-1-0.1-1-0.1h-0.9l0.2-0.5l-0.3-0.3c0,0-0.1,0.3-0.4,0.4c-0.3,0.2-0.5,0.5-0.7,0.4
                  c-0.2-0.1-0.5-0.2-0.5-0.5c0-0.3,0-0.6,0.3-0.6c0.3,0,0.5,0,0.5-0.4c0-0.4,0.9-1.1,0.9-1.4c0-0.3,0.5-0.7,0.7-0.9
                  c0.1-0.2,0.3-0.3,0.5-0.4c0.3-0.1,1.2,0.6,1.3,0.3c0.1-0.3,0.2-1.8,0-2.2c-0.2-0.4-0.1-1,0-1.1c0.1-0.1,0.5-0.2,0.5-0.4
                  c0-0.2-0.3-0.6-0.6-0.9c-0.4-0.3-0.6-0.8-0.9-1c-0.3-0.3-0.5-0.4-0.4-0.7c0.1-0.3,0.2-0.5,0.9-0.5c0.7-0.1,1.8-0.1,2.9,0
                  c0.3,0,0.7,0,1,0c-1.9-1.2-4.1-1.9-6.5-1.9c-2.1,0-4.1,0.5-5.8,1.5l0.5,0l2.1,0.3c0,0,1.8,0.5,2.3,0.3s2-0.8,2-0.8
                  s0.5,1,0.3,1.2c-0.3,0.2-0.9,1-1.4,1c-0.4,0-0.8,0.5-1.2,0.8c-0.4,0.3-0.3,0.4-0.3,0.7s-0.1,0.7-0.4,0.7
                  c-0.3,0-0.8-0.6-0.9-0.7c-0.2,0-1.2,0.6-0.8,0.8c0.4,0.2,1.1,0,1.1,0l-0.5,0.9c0,0,0.4,0.3,0.4,0.5s0,0.2-0.3,0.4
                  c-0.4,0.2-0.6,0.8-0.6,0.8l-1-0.2c0,0-1.9-1.1-2.3-0.9c-0.4,0.1-0.5,0.4-0.5,0.4s-0.4,0.3-0.4,0c0-0.4,0.5-0.6,0.5-0.8
                  c0-0.2-0.2-1.3-0.5-1.4c-0.3-0.1-0.7-0.4-1,0c-0.3,0.4,0,0.7,0,1c0.1,0.3-0.3,0.5-0.3,0.5l-0.4,0.5c0,0-0.3,0.7-0.3,0.8
                  c0,0-0.1,0.5,0,0.7c0.1,0.2,0.9,1.2,1.1,1.6c0.2,0.4,0.3,1.2,0.4,1.6c0.1,0.4,0.1,1.5,0.4,2c0.3,0.5,1,1.1,1,1.6
                  c-0.1,0.5-0.8,2-1.3,2.2c-0.5,0.2-0.3-0.2-0.8,0c-0.5,0.2,0-0.1-0.8,0.5c2.1,3.2,5.8,5.3,9.9,5.3c6.5,0,11.9-5.3,11.9-11.9
                  c0-0.6,0-1.2-0.1-1.7C446.5,310.2,446.4,310.3,446.3,310.3z M435.3,306.1c0.3,0.1,0.2,0.4,0.2,0.6c0,0.2-0.8,0.5-1,0.7
                  c-0.3,0.2-0.6,0.4-0.7,0.4c-0.1,0-0.7-0.3-0.7-0.3c-0.1-0.4,0.7-1,1.1-1.1c0.4-0.1,0.4-0.5,0.5-0.5
                  C434.8,306,435,305.9,435.3,306.1z M434.8,304.2c0,0.1-0.6,0.9-0.8,1c-0.2,0.1-0.3-0.3-0.3-0.3
                  C434,304.5,434.8,304.1,434.8,304.2z M432.7,306.2c0-0.1,0.7-0.2,0.7-0.2c0,0,0.2-0.4,0.2-0.5c0.1,0,0,0.1,0.1,0.4
                  c0.1,0.3-0.1,0.3-0.2,0.6c-0.1,0.2-0.5,0.2-0.7,0.4c-0.3,0.1-0.4,0.1-0.4,0.1C432.4,306.9,432.7,306.4,432.7,306.2z
                   M426.4,307.9c0-0.1,0.3-0.5,0.3-0.4c0,0.1,0.1,0.8,0.1,0.9c0,0.1-0.1,0.5-0.4,0.5C426.2,308.9,426.4,308.3,426.4,307.9z
                   M426.6,309.4c0,0,0.2-0.3,0.4-0.2c0.2,0,0,0.3,0,0.4C426.9,309.7,426.6,309.5,426.6,309.4z M426.8,310l0.2-0.1v0.2
                  L426.8,310z M427,310.4l0.1-0.1l0.1,0.2L427,310.4z M440,320.5c-0.1,0.3-0.4,0.9-1.1,1c-0.6,0.1-0.7,0.1-0.7-0.1
                  c0,0,0.4-0.6,0.8-0.8c0.4-0.2,0.5-0.3,0.7-0.3C439.8,320.1,440.1,320.1,440,320.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M429.2,304.9c0.9,0,1.5-0.7,1.5-1.5c0-0.9-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5
                  C427.7,304.2,428.3,304.9,429.2,304.9z"
                      />
                      <path
                        d="M427,315.3c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2C428.2,315.9,427.6,315.3,427,315.3z"
                      />
                      <path
                        d="M444,316.3c-0.9,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S444.9,316.3,444,316.3z"
                      />
                      <path
                        d="M444.4,304c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1c0,0.4,0.1,0.8,0.3,1.2l-5,4.9c-0.4-0.2-0.9-0.4-1.4-0.4
                  c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.8,2.8,2.9l1.3,4.5c-0.6,0.4-0.9,1-0.9,1.7c0,1.2,0.9,2.1,2.1,2.1
                  c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0l-1.2-4.4c1-0.4,1.8-1.5,1.8-2.7c0-0.6-0.2-1.2-0.6-1.7l5-4.9
                  l0,0c0.3,0.1,0.6,0.2,0.9,0.2C443.4,306.1,444.4,305.2,444.4,304z M437.8,321.7c0,0.7-0.6,1.3-1.3,1.3
                  c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3C437.2,320.4,437.8,320.9,437.8,321.7z M434.1,314.3c-0.9,0-1.7-0.8-1.7-1.7
                  c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7C435.8,313.5,435,314.3,434.1,314.3z M442.3,305.3c-0.7,0-1.3-0.6-1.3-1.3
                  s0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3S443,305.3,442.3,305.3z"
                      />
                      <path
                        d="M439.1,315l-1.4-0.9c-0.2-0.1-0.4-0.1-0.5,0.1c-0.1,0.2-0.1,0.4,0.1,0.5l1.4,0.9c0.1,0,0.1,0.1,0.2,0.1
                  c0.1,0,0.3-0.1,0.3-0.2C439.4,315.3,439.3,315.1,439.1,315z"
                      />
                      <path
                        d="M441.7,316.6l-1.4-0.9c-0.2-0.1-0.4-0.1-0.5,0.1c-0.1,0.2-0.1,0.4,0.1,0.5l1.4,0.9c0.1,0,0.1,0.1,0.2,0.1
                  c0.1,0,0.3-0.1,0.3-0.2C442,316.9,441.9,316.7,441.7,316.6z"
                      />
                      <path
                        d="M430.6,314.3l-1.5,0.8c-0.2,0.1-0.3,0.3-0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0l1.5-0.8
                  c0.2-0.1,0.3-0.3,0.1-0.5C431,314.2,430.7,314.2,430.6,314.3z"
                      />
                      <path
                        d="M431.3,308.1l0.8,1.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.2-0.3,0.1-0.5l-0.8-1.4
                  c-0.1-0.2-0.3-0.2-0.5-0.1C431.2,307.6,431.2,307.9,431.3,308.1z"
                      />
                      <path
                        d="M431.3,306.5l-0.8-1.5c-0.1-0.2-0.3-0.2-0.5-0.1c-0.2,0.1-0.2,0.3-0.1,0.5l0.8,1.5c0.1,0.1,0.2,0.2,0.3,0.2
                  c0.1,0,0.1,0,0.2-0.1C431.3,307,431.4,306.7,431.3,306.5z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="XMLID_192_">
            <g id="XMLID_239_">
              <g id="XMLID_244_">
                <path
                  id="XMLID_245_"
                  style="fill: #ffffff"
                  d="M439.1,253.5c0-0.2,0-0.5,0.1-0.7l-2.3-2.2c-0.1-0.1-0.1-0.2,0-0.2l5.4-3.1
            c0.1-0.1,0.4-0.2,0.4-0.2c1.8-0.9,4.1-0.9,6.6,0.3c7,3.2,12.9,12.9,12.9,20.8c0,3.6-1.3,6.2-3.3,7.5l0,0c0,0-5,2.9-5.8,3.3
            c-0.1,0-0.2,0-0.2-0.1l-1.2-2.4l1.1-0.6c-0.7-0.3-1.4-0.6-2.2-1c-0.2-0.1-0.4-0.2-0.5-0.3l0,0c-0.6,1.2-0.6,3.2-0.4,5.2
            c0,0.4-0.1,0.7-0.3,0.7l-5.4,3.1c-0.1,0-0.2,0-0.2-0.1l-0.6-1.5l0.3-6.5h2.9l-5.4-12.3l0,0
            C439.5,259.9,438.8,256.5,439.1,253.5z"
                />
              </g>
              <g id="XMLID_240_">
                <path
                  id="XMLID_241_"
                  d="M443.8,284c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.3-0.3l-0.6-1.6l0.3-7h2.7l-5.2-11.8
            c-1.5-3.5-2.1-6.9-1.9-9.9l0,0c0-0.2,0-0.3,0.1-0.5l-2.1-2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.3,0.3-0.4l5.4-3.1
            c0.1-0.1,0.4-0.2,0.4-0.2c2-1,4.4-0.9,7,0.3c7,3.2,13.2,13.1,13.2,21.2c0,3.7-1.2,6.4-3.5,7.8c-0.1,0-1.5,0.9-5.3,3l-0.6,0.3
            c-0.1,0.1-0.3,0.1-0.4,0c-0.1,0-0.3-0.1-0.3-0.3l-1.3-2.8l0.7-0.4c-0.5-0.2-1-0.5-1.4-0.7c0,0-0.1-0.1-0.1-0.1
            c-0.3,1-0.4,2.5-0.2,4.6c0.1,0.7-0.2,1-0.6,1.1l-5.4,3.1C444,284,443.9,284,443.8,284z M443.6,281.9l0.4,1.1l5.3-3
            c0,0,0-0.1,0-0.3c-0.2-2.5-0.1-4.4,0.4-5.4l0.2-0.4l0.4,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.7,0.4,1.4,0.7,2.1,1l0.9,0.3l-1.6,0.9
            l0.9,1.8l0.3-0.2c1.6-0.9,5.2-3,5.2-3c2-1.2,3.1-3.8,3.1-7.1c0-7.8-5.9-17.4-12.7-20.4c-2.4-1.1-4.5-1.2-6.3-0.3l0,0
            c0,0-0.3,0.1-0.4,0.2l-5,2.9l2.2,2l0,0.2c0,0.2-0.1,0.5-0.1,0.7c-0.2,2.9,0.4,6.2,1.8,9.5l5.7,12.9h-3.2L443.6,281.9z"
                />
              </g>
            </g>
            <g id="XMLID_232_">
              <g id="XMLID_237_">
                <path
                  id="XMLID_238_"
                  style="fill: #ffffff"
                  d="M441.2,264l-0.3-0.7l0,0c-1.4-3.2-2.1-6.6-1.8-9.7c0-0.2,0-0.5,0.1-0.7
            l-2.3-2.2c-0.1-0.1-0.1-0.2,0-0.2l5.4-3.1c0.1-0.1,0.4-0.2,0.4-0.2c1.8-0.9,4.1-0.9,6.6,0.3c3,1.4,5.9,4,8.1,7.1L441.2,264z"
                />
              </g>
              <g id="XMLID_233_">
                <path
                  id="XMLID_234_"
                  d="M441,264.5l-0.5-1.2c-1.5-3.5-2.1-6.9-1.9-9.9c0-0.2,0-0.3,0.1-0.5l-2.1-2c-0.1-0.1-0.2-0.3-0.2-0.5
            c0-0.2,0.1-0.3,0.3-0.4l5.4-3.1c0.1-0.1,0.4-0.2,0.4-0.2c2-1,4.4-0.9,7,0.3c3,1.3,5.9,3.9,8.3,7.3l0.3,0.4L441,264.5z
             M437.4,250.6l2.2,2l0,0.2c0,0.2-0.1,0.5-0.1,0.7c-0.2,2.9,0.4,6.2,1.8,9.5l0.1,0.3l15.4-9c-2.2-3.1-5-5.4-7.7-6.6
            c-2.4-1.1-4.5-1.2-6.3-0.3l0,0c0,0-0.3,0.1-0.4,0.2L437.4,250.6z"
                />
              </g>
            </g>
            <g id="XMLID_225_">
              <g id="XMLID_230_">
                <path
                  id="XMLID_231_"
                  d="M435.3,266.4L435.3,266.4c-1.4-3.2-2.1-6.6-1.8-9.7c0.5-6,4.8-8.6,10.3-6.1c7,3.2,12.9,12.9,12.9,20.8
            c0,7.4-5.2,10.4-11.6,6.7c-0.2-0.1-0.4-0.2-0.5-0.3l0,0c-0.6,1.2-0.6,3.2-0.4,5.2c0.1,1.1-1.1,0.9-1.6-0.2L435.3,266.4z"
                />
              </g>
              <g id="XMLID_226_">
                <path
                  id="XMLID_227_"
                  d="M443.6,284.1c-0.6,0-1.2-0.5-1.5-1.2l-7.2-16.3c-1.5-3.5-2.1-6.9-1.9-9.9c0.3-3,1.5-5.4,3.5-6.6
            c2-1.2,4.6-1.1,7.3,0.1c7,3.2,13.2,13.1,13.2,21.2c0,3.7-1.3,6.5-3.6,7.9c-2.3,1.3-5.4,1.1-8.6-0.8c0,0-0.1-0.1-0.1-0.1
            c-0.3,1-0.4,2.5-0.2,4.6c0.1,0.7-0.3,1.1-0.6,1.2C443.7,284.1,443.6,284.1,443.6,284.1z M435.7,266.3l7.2,16.3
            c0.2,0.4,0.4,0.6,0.6,0.7c0.1,0,0.1,0,0.1,0c0,0,0-0.1,0-0.3c-0.2-2.5-0.1-4.4,0.4-5.4l0.2-0.4l0.4,0.2
            c0.2,0.1,0.4,0.2,0.5,0.3c3,1.7,5.7,2,7.8,0.8c2.1-1.2,3.2-3.7,3.2-7.2c0-7.8-5.9-17.4-12.7-20.4c-2.5-1.1-4.8-1.2-6.6-0.1
            c-1.8,1-2.9,3.1-3.1,5.9C433.6,259.6,434.2,262.9,435.7,266.3L435.7,266.3z"
                />
              </g>
            </g>
            <g id="XMLID_203_">
              <g id="XMLID_218_">
                <g id="XMLID_223_">
                  <path
                    id="XMLID_224_"
                    d="M449,267.1c0,1.3,0.9,2.8,2,3.4c1.1,0.6,2,0.1,2-1.1s-0.9-2.8-2-3.4C449.9,265.3,449,265.8,449,267.1
              z"
                  />
                </g>
                <g id="XMLID_219_">
                  <path
                    id="XMLID_220_"
                    style="fill: #ffffff"
                    d="M451.9,271.2c-0.3,0-0.7-0.1-1.1-0.3c-1.2-0.7-2.2-2.4-2.2-3.8
              c0-0.8,0.3-1.4,0.8-1.6c0.5-0.3,1.2-0.2,1.8,0.1c1.2,0.7,2.2,2.4,2.2,3.8c0,0.8-0.3,1.4-0.8,1.6
              C452.4,271.2,452.1,271.2,451.9,271.2z M450.1,266.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.5-0.4,0.9c0,1.1,0.8,2.5,1.8,3.1
              c0.4,0.2,0.8,0.3,1,0.1c0.2-0.1,0.4-0.5,0.4-0.9c0-1.1-0.8-2.5-1.8-3.1C450.5,266.2,450.3,266.1,450.1,266.1z"
                  />
                </g>
              </g>
              <g id="XMLID_211_">
                <g id="XMLID_216_">
                  <path
                    id="XMLID_217_"
                    d="M443,263.6c0,1.3,0.9,2.8,2,3.4c1.1,0.6,2,0.1,2-1.1s-0.9-2.8-2-3.4C443.9,261.8,443,262.3,443,263.6
              z"
                  />
                </g>
                <g id="XMLID_212_">
                  <path
                    id="XMLID_213_"
                    style="fill: #ffffff"
                    d="M445.8,267.8c-0.3,0-0.7-0.1-1.1-0.3c-1.2-0.7-2.2-2.4-2.2-3.8
              c0-0.8,0.3-1.4,0.8-1.6c0.5-0.3,1.2-0.2,1.8,0.1c1.2,0.7,2.2,2.4,2.2,3.8c0,0.8-0.3,1.4-0.8,1.6
              C446.4,267.7,446.1,267.8,445.8,267.8z M444.1,262.6c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.5-0.4,0.9c0,1.1,0.8,2.5,1.8,3.1
              c0.4,0.2,0.8,0.3,1,0.1c0.2-0.1,0.4-0.5,0.4-0.9c0-1.1-0.8-2.5-1.8-3.1C444.5,262.7,444.3,262.6,444.1,262.6z"
                  />
                </g>
              </g>
              <g id="XMLID_204_">
                <g id="XMLID_209_">
                  <path
                    id="XMLID_210_"
                    d="M437,260.2c0,1.3,0.9,2.8,2,3.4c1.1,0.6,2,0.1,2-1.1c0-1.3-0.9-2.8-2-3.4
              C437.9,258.4,437,258.9,437,260.2z"
                  />
                </g>
                <g id="XMLID_205_">
                  <path
                    id="XMLID_206_"
                    style="fill: #ffffff"
                    d="M439.9,264.3c-0.3,0-0.7-0.1-1.1-0.3c-1.2-0.7-2.2-2.4-2.2-3.8
              c0-0.8,0.3-1.4,0.8-1.6c0.5-0.3,1.2-0.2,1.8,0.1c1.2,0.7,2.2,2.4,2.2,3.8c0,0.8-0.3,1.4-0.8,1.6
              C440.4,264.3,440.2,264.3,439.9,264.3z M438.1,259.2c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.5-0.4,0.9c0,1.1,0.8,2.5,1.8,3.1
              c0.4,0.2,0.8,0.3,1,0.1c0.2-0.1,0.4-0.5,0.4-0.9c0-1.1-0.8-2.5-1.8-3.1C438.6,259.3,438.3,259.2,438.1,259.2z"
                  />
                </g>
              </g>
            </g>
            <g id="XMLID_198_">
              <g id="XMLID_201_">
                <path
                  id="XMLID_202_"
                  style="fill: #ffffff"
                  d="M462.1,269.7l-5.6,3.2L462.1,269.7z"
                />
              </g>
              <g id="XMLID_199_">
                <rect
                  id="XMLID_200_"
                  x="456"
                  y="270.9"
                  transform="matrix(0.8667 -0.4987 0.4987 0.8667 -74.1254 265.2141)"
                  width="6.5"
                  height="0.8"
                />
              </g>
            </g>
            <g id="XMLID_193_">
              <g id="XMLID_196_">
                <path
                  id="XMLID_197_"
                  style="fill: #ffffff"
                  d="M448.9,247.2l-5.6,3.2L448.9,247.2z"
                />
              </g>
              <g id="XMLID_194_">
                <rect
                  id="XMLID_195_"
                  x="442.8"
                  y="248.5"
                  transform="matrix(0.8666 -0.4989 0.4989 0.8666 -64.6806 255.7583)"
                  width="6.5"
                  height="0.8"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="pilotage-label" 
          @click="activate('pilotage')"
          @mouseover="hilight('pilotage', true)"
          @mouseleave="hilight('pilotage', false)">
        <g>
          <g>
            <path
              style="
                fill: none;
                stroke: #1a1332;
                stroke-width: 3;
                stroke-linecap: round;
                stroke-miterlimit: 10;
              "
              d="M234.9,75.1c0,0,0.3,0,1,0
        "
            />

            <path
              style="
                fill: none;
                stroke: #1a1332;
                stroke-width: 3;
                stroke-linecap: round;
                stroke-miterlimit: 10;
                stroke-dasharray: 1.9905, 5.9716;
              "
              d="
        M241.9,75.4c17.9,1.8,64.2,14,66.7,91.1"
            />
            <path
              style="
                fill: none;
                stroke: #1a1332;
                stroke-width: 3;
                stroke-linecap: round;
                stroke-miterlimit: 10;
              "
              d="M308.6,169.5
        c0,0.3,0,0.7,0,1"
            />
          </g>
        </g>

        <a
          id="pilotage-label-anchor-link"
          :class="{ active: active_pilotage, hilighted: hilighted_pilotage }"
        >
          <g id="pilotage-label-anchor-link">
            <path
              style="fill: #e5ecf4"
              d="M208.8,95.1H24.7c-11,0-20-9-20-20v0c0-11,9-20,20-20h184.1c11,0,20,9,20,20v0
      C228.8,86.1,219.8,95.1,208.8,95.1z"
            />
          </g>
        </a>

        <g>
          <g>
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M25.9,68.1h3.7c2.5,0,4.1,1.4,4.1,3.5s-1.6,3.5-4.1,3.5h-2v5.5h-1.7V68.1z M27.6,69.6v4.1h2
        c1.4,0,2.3-0.7,2.3-2.1c0-1.3-0.9-2-2.3-2H27.6z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M35.5,68.2c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1S35.5,68.8,35.5,68.2z
         M35.8,71.6h1.5v9.1h-1.5V71.6z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M40.6,67.2h1.5v13.5h-1.5V67.2z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M54.4,76.1c0,2.6-2,4.9-4.9,4.9c-2.9,0-4.9-2.3-4.9-4.9c0-2.6,2-4.9,4.9-4.9
        C52.4,71.3,54.4,73.5,54.4,76.1z M52.8,76.1c0-1.9-1.4-3.5-3.3-3.5c-1.9,0-3.3,1.6-3.3,3.5c0,1.9,1.4,3.5,3.3,3.5
        C51.4,79.6,52.8,78,52.8,76.1z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M57.3,73h-1.7v-1.4h1.7v-2.3h1.5v2.3H62V73h-3.2v4.6c0,1.4,0.7,1.8,1.8,1.8c0.6,0,1-0.1,1.4-0.2
        v1.4c-0.4,0.2-0.9,0.3-1.6,0.3c-2,0-3.2-1-3.2-3.2V73z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M63.8,78.5c0-1.4,1-2.4,3-2.7l3-0.5v-0.6c0-1.3-0.9-2-2.2-2c-1.1,0-1.9,0.5-2.5,1.3L64,73
        c0.8-1.1,2.1-1.7,3.7-1.7c2.3,0,3.7,1.4,3.7,3.3v6.1h-1.5v-1.2c-0.7,1-1.8,1.5-3,1.5C65,81,63.8,80,63.8,78.5z M67,79.8
        c1.2,0,2.2-0.6,2.8-1.7v-1.7l-2.7,0.5c-1.3,0.2-1.8,0.8-1.8,1.6C65.3,79.2,66,79.8,67,79.8z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M74.8,80c-0.4-0.3-0.6-0.8-0.6-1.3c0-0.7,0.4-1.3,1.1-1.8c-0.8-0.6-1.3-1.5-1.3-2.5
        c0-1.7,1.4-3.2,3.5-3.2c0.7,0,1.3,0.1,1.7,0.4h3.4v1.3h-2c0.3,0.5,0.4,1,0.4,1.5c0,1.7-1.4,3.2-3.5,3.2c-0.5,0-0.9-0.1-1.3-0.2
        c-0.4,0.3-0.6,0.6-0.6,0.9c0,0.4,0.2,0.6,0.8,0.6H79c2.4,0,3.5,1.2,3.5,2.8c0,1.9-1.8,3.3-4.6,3.3c-2.7,0-4.4-1-4.4-2.9
        C73.4,81.4,73.9,80.7,74.8,80z M77.8,83.9c1.9,0,3.1-0.8,3.1-1.9c0-0.9-0.5-1.5-2.1-1.5h-2.8c-0.8,0.5-1.2,1-1.2,1.7
        C74.9,83.2,76,83.9,77.8,83.9z M79.6,74.5c0-1.1-0.8-2-2.1-2c-1.4,0-2.1,0.9-2.1,2c0,1.1,0.7,2,2.1,2
        C78.8,76.5,79.6,75.6,79.6,74.5z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M88.2,81c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9c0,0.4-0.1,0.7-0.1,1h-7
        v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C91.3,80.3,89.9,81,88.2,81z M85,75h5.4c-0.1-1.3-1-2.4-2.5-2.4
        C86.4,72.6,85.4,73.4,85,75z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M103.3,71.3c1.3,0,2.4,0.5,3.2,1.4v-5.4h1.5v13.5h-1.5v-1c-0.8,0.9-1.9,1.4-3.2,1.4
        c-2.8,0-4.7-2.3-4.7-4.9C98.6,73.5,100.4,71.3,103.3,71.3z M103.5,79.6c1.3,0,2.3-0.6,3-1.7v-3.5c-0.7-1.1-1.8-1.7-3-1.7
        c-1.9,0-3.2,1.5-3.2,3.5C100.2,78.1,101.5,79.6,103.5,79.6z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M115.4,81c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9c0,0.4-0.1,0.7-0.1,1
        h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C118.5,80.3,117.1,81,115.4,81z M112.2,75h5.4c-0.1-1.3-1-2.4-2.5-2.4
        C113.6,72.6,112.5,73.4,112.2,75z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M126.7,78.5c0.6,0.8,1.4,1.3,2.3,1.3c1,0,1.6-0.6,1.6-1.4c0-2.1-4.4-1.3-4.4-4.6
        c0-1.5,1.2-2.6,2.9-2.6c1.3,0,2.4,0.6,3.1,1.5l-1.1,0.9c-0.5-0.7-1.2-1.1-2-1.1c-0.9,0-1.4,0.5-1.4,1.2c0,2.1,4.4,1.4,4.4,4.5
        c0,1.7-1.4,2.8-3.1,2.8c-1.4,0-2.6-0.6-3.4-1.6L126.7,78.5z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M134.2,68.2c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1S134.2,68.8,134.2,68.2z
         M134.5,71.6h1.5v9.1h-1.5V71.6z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M139.8,73h-1.7v-1.4h1.7v-2.3h1.5v2.3h3.2V73h-3.2v4.6c0,1.4,0.7,1.8,1.8,1.8c0.6,0,1-0.1,1.4-0.2
        v1.4c-0.4,0.2-0.9,0.3-1.6,0.3c-2,0-3.2-1-3.2-3.2V73z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M150.9,81c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9c0,0.4-0.1,0.7-0.1,1
        h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C153.9,80.3,152.6,81,150.9,81z M147.6,75h5.4c-0.1-1.3-1-2.4-2.5-2.4
        C149,72.6,148,73.4,147.6,75z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M157.2,78.5c0.6,0.8,1.4,1.3,2.3,1.3c1,0,1.6-0.6,1.6-1.4c0-2.1-4.4-1.3-4.4-4.6
        c0-1.5,1.2-2.6,2.9-2.6c1.3,0,2.4,0.6,3.1,1.5l-1.1,0.9c-0.5-0.7-1.2-1.1-2-1.1c-0.9,0-1.4,0.5-1.4,1.2c0,2.1,4.4,1.4,4.4,4.5
        c0,1.7-1.4,2.8-3.1,2.8c-1.4,0-2.6-0.6-3.4-1.6L157.2,78.5z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M170.5,71.6l1.9,7.5l2.1-7.5h2.1l2.1,7.5l1.9-7.5h1.5l-2.3,9.1h-2.1l-2.2-7.6l-2.2,7.6h-2.1
        l-2.3-9.1H170.5z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M188.3,81c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9c0,0.4-0.1,0.7-0.1,1
        h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C191.3,80.3,189.9,81,188.3,81z M185,75h5.4c-0.1-1.3-1-2.4-2.5-2.4
        C186.4,72.6,185.4,73.4,185,75z"
            />
            <path
              style="fill: #010000"
              
          :class="{ 'active-text': active_pilotage, 'hilighted-text': hilighted_pilotage }"
              d="M199.3,81c-1.3,0-2.4-0.5-3.2-1.4v1h-1.5V67.2h1.5v5.4c0.8-0.9,1.9-1.4,3.2-1.4
        c2.8,0,4.7,2.3,4.7,4.9C204,78.8,202.1,81,199.3,81z M199.1,79.6c1.9,0,3.2-1.5,3.2-3.5c0-1.9-1.3-3.5-3.2-3.5
        c-1.3,0-2.3,0.6-3,1.7v3.5C196.8,79,197.8,79.6,199.1,79.6z"
            />
          </g>
        </g>
      </g>
      <g id="conseil-label"
          @mouseover="hilight('conseil', true)"
          @mouseleave="hilight('conseil', false)"
          @click="activate('conseil')">
        <a id="conseil-label-anchor-link"  :class="{ active: active_conseil, hilighted: hilighted_conseil  }">
        <path
          style="fill: #e5ecf4"
          d="M817,52.2H577.8c-11,0-20-9-20-20v0c0-11,9-20,20-20l239.1,0c11,0,20,9,20,20v0
    C837,43.3,828,52.2,817,52.2z"
        />
        </a>
        <g>
          <g>
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M586.5,34.6l1.4,1c-1.2,1.6-3,2.6-5.3,2.6c-3.9,0-6.6-3.1-6.6-6.7s2.7-6.7,6.6-6.7
        c2.3,0,4.1,1,5.3,2.6l-1.4,1c-0.8-1.2-2.2-2.1-3.9-2.1c-2.8,0-4.9,2.3-4.9,5.1c0,2.8,2,5.1,4.9,5.1
        C584.3,36.6,585.6,35.8,586.5,34.6z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M599.1,33.3c0,2.6-2,4.9-4.9,4.9c-2.9,0-4.9-2.3-4.9-4.9c0-2.6,2-4.9,4.9-4.9
        C597.1,28.4,599.1,30.7,599.1,33.3z M597.5,33.3c0-1.9-1.4-3.5-3.3-3.5c-1.9,0-3.3,1.6-3.3,3.5c0,1.9,1.4,3.5,3.3,3.5
        C596.1,36.8,597.5,35.2,597.5,33.3z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M601.6,28.8h1.5v1c0.7-0.8,1.7-1.4,3-1.4c2,0,3.5,1.4,3.5,3.9v5.5h-1.5v-5.4
        c0-1.6-0.9-2.6-2.3-2.6c-1.3,0-2.1,0.8-2.7,1.7v6.3h-1.5V28.8z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M612.9,35.7c0.6,0.8,1.4,1.3,2.3,1.3c1,0,1.6-0.6,1.6-1.4c0-2.1-4.4-1.3-4.4-4.6
        c0-1.5,1.2-2.6,2.9-2.6c1.3,0,2.4,0.6,3.1,1.5l-1.1,0.9c-0.5-0.7-1.2-1.1-2-1.1c-0.9,0-1.4,0.5-1.4,1.2c0,2.1,4.4,1.4,4.4,4.5
        c0,1.7-1.4,2.8-3.1,2.8c-1.4,0-2.6-0.6-3.4-1.6L612.9,35.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M624.8,38.2c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9c0,0.4-0.1,0.7-0.1,1
        h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C627.9,37.5,626.5,38.2,624.8,38.2z M621.6,32.1h5.4
        c-0.1-1.3-1-2.4-2.5-2.4C623,29.7,622,30.6,621.6,32.1z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M630.8,25.3c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
        C631.3,26.4,630.8,25.9,630.8,25.3z M631.1,28.8h1.5v9.1h-1.5V28.8z"
            />
            <path style="fill: #010000"
            :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }" d="M635.9,24.3h1.5v13.5h-1.5V24.3z" />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M654.8,37.9l-1.4-1.3c-1,1-2.3,1.7-4,1.7c-2.5,0-4.4-1.5-4.4-3.9c0-1.7,1.1-2.9,2.5-3.5
        c-0.8-0.9-1.2-1.7-1.2-2.8c0-1.8,1.4-3.1,3.4-3.1c1.9,0,3.3,1.2,3.3,3c0,1.7-1.2,2.8-2.8,3.3l3.2,3.1c0.5-0.9,1-2,1.3-3.2h1.6
        c-0.4,1.7-1,3.2-1.8,4.3l2.5,2.4H654.8z M649.5,36.8c1.1,0,2.1-0.5,2.9-1.3l-3.8-3.6c0,0-0.1-0.1-0.1-0.1c-1,0.4-1.8,1.3-1.8,2.5
        C646.6,35.8,647.9,36.8,649.5,36.8z M649.1,30.3c1.6-0.4,2.3-1.2,2.3-2.3c0-0.9-0.7-1.7-1.8-1.7c-1.2,0-1.8,0.8-1.8,1.8
        C647.8,29.1,648.4,29.6,649.1,30.3z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M663.3,35.6c0-1.4,1-2.4,3-2.7l3-0.5v-0.6c0-1.3-0.9-2-2.2-2c-1.1,0-1.9,0.5-2.5,1.3l-1.2-0.9
        c0.8-1.1,2.1-1.7,3.7-1.7c2.3,0,3.7,1.4,3.7,3.3v6.1h-1.5v-1.2c-0.7,1-1.8,1.5-3,1.5C664.5,38.2,663.3,37.2,663.3,35.6z
         M666.5,36.9c1.2,0,2.2-0.6,2.8-1.7v-1.7l-2.7,0.5c-1.3,0.2-1.8,0.8-1.8,1.6C664.8,36.3,665.5,36.9,666.5,36.9z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M680.7,35.4l1.2,0.9c-0.8,1.1-2.2,1.9-3.8,1.9c-3,0-4.9-2.3-4.9-4.9c0-2.6,1.9-4.9,4.9-4.9
        c1.6,0,3,0.7,3.8,1.9l-1.2,0.9c-0.6-0.8-1.5-1.4-2.6-1.4c-1.9,0-3.3,1.5-3.3,3.5c0,1.9,1.4,3.5,3.3,3.5
        C679.2,36.8,680.1,36.2,680.7,35.4z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M690.4,35.4l1.2,0.9c-0.8,1.1-2.2,1.9-3.8,1.9c-3,0-4.9-2.3-4.9-4.9c0-2.6,1.9-4.9,4.9-4.9
        c1.6,0,3,0.7,3.8,1.9l-1.2,0.9c-0.6-0.8-1.5-1.4-2.6-1.4c-1.9,0-3.3,1.5-3.3,3.5c0,1.9,1.4,3.5,3.3,3.5
        C688.9,36.8,689.8,36.2,690.4,35.4z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M702.4,33.3c0,2.6-2,4.9-4.9,4.9c-2.9,0-4.9-2.3-4.9-4.9c0-2.6,2-4.9,4.9-4.9
        C700.4,28.4,702.4,30.7,702.4,33.3z M700.8,33.3c0-1.9-1.4-3.5-3.3-3.5c-1.9,0-3.3,1.6-3.3,3.5c0,1.9,1.4,3.5,3.3,3.5
        C699.4,36.8,700.8,35.2,700.8,33.3z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M704.9,28.8h1.5v1c0.7-0.8,1.6-1.4,2.8-1.4c1.3,0,2.4,0.6,3,1.8c0.7-1,1.7-1.8,3.2-1.8
        c1.9,0,3.4,1.3,3.4,3.8v5.7h-1.5v-5.6c0-1.5-0.8-2.4-2.1-2.4c-1.2,0-1.9,0.7-2.6,1.7c0,0.2,0,0.4,0,0.6v5.7h-1.5v-5.6
        c0-1.5-0.8-2.4-2.1-2.4c-1.2,0-2,0.8-2.5,1.7v6.3h-1.5V28.8z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M721.9,28.8h1.5v1c0.8-0.9,1.9-1.4,3.2-1.4c2.8,0,4.7,2.3,4.7,4.9c0,2.6-1.8,4.9-4.7,4.9
        c-1.3,0-2.4-0.5-3.2-1.4v5.4h-1.5V28.8z M726.5,36.8c1.9,0,3.2-1.5,3.2-3.5c0-1.9-1.3-3.5-3.2-3.5c-1.3,0-2.3,0.6-3,1.7V35
        C724.1,36.2,725.2,36.8,726.5,36.8z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M733.1,35.6c0-1.4,1-2.4,3-2.7l3-0.5v-0.6c0-1.3-0.9-2-2.2-2c-1.1,0-1.9,0.5-2.5,1.3l-1.2-0.9
        c0.8-1.1,2.1-1.7,3.7-1.7c2.3,0,3.7,1.4,3.7,3.3v6.1h-1.5v-1.2c-0.7,1-1.8,1.5-3,1.5C734.3,38.2,733.1,37.2,733.1,35.6z
         M736.4,36.9c1.2,0,2.2-0.6,2.8-1.7v-1.7l-2.7,0.5c-1.3,0.2-1.8,0.8-1.8,1.6C734.6,36.3,735.3,36.9,736.4,36.9z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M744.1,37.2c-0.4-0.3-0.6-0.8-0.6-1.3c0-0.7,0.4-1.3,1.1-1.8c-0.8-0.6-1.3-1.5-1.3-2.5
        c0-1.7,1.4-3.2,3.5-3.2c0.7,0,1.3,0.1,1.7,0.4h3.4v1.3h-2c0.3,0.5,0.4,1,0.4,1.5c0,1.7-1.4,3.2-3.5,3.2c-0.5,0-0.9-0.1-1.3-0.2
        c-0.4,0.3-0.6,0.6-0.6,0.9c0,0.4,0.2,0.6,0.8,0.6h2.6c2.4,0,3.5,1.2,3.5,2.8c0,1.9-1.8,3.3-4.6,3.3c-2.7,0-4.4-1-4.4-2.9
        C742.7,38.6,743.2,37.9,744.1,37.2z M747.2,41.1c1.9,0,3.1-0.8,3.1-1.9c0-0.9-0.5-1.5-2.1-1.5h-2.8c-0.8,0.5-1.2,1-1.2,1.7
        C744.2,40.4,745.4,41.1,747.2,41.1z M748.9,31.6c0-1.1-0.8-2-2.1-2c-1.3,0-2.1,0.9-2.1,2c0,1.1,0.7,2,2.1,2
        C748.1,33.6,748.9,32.7,748.9,31.6z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M753.8,28.8h1.5v1c0.7-0.8,1.7-1.4,3-1.4c2,0,3.5,1.4,3.5,3.9v5.5h-1.5v-5.4
        c0-1.6-0.9-2.6-2.3-2.6c-1.3,0-2.1,0.8-2.7,1.7v6.3h-1.5V28.8z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M769.1,38.2c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9c0,0.4-0.1,0.7-0.1,1
        h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C772.2,37.5,770.8,38.2,769.1,38.2z M765.9,32.1h5.4
        c-0.1-1.3-1-2.4-2.5-2.4C767.3,29.7,766.3,30.6,765.9,32.1z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M775.4,28.8h1.5v1c0.7-0.8,1.6-1.4,2.8-1.4c1.3,0,2.4,0.6,3,1.8c0.7-1,1.7-1.8,3.2-1.8
        c1.9,0,3.4,1.3,3.4,3.8v5.7h-1.5v-5.6c0-1.5-0.8-2.4-2.1-2.4c-1.2,0-1.9,0.7-2.6,1.7c0,0.2,0,0.4,0,0.6v5.7h-1.5v-5.6
        c0-1.5-0.8-2.4-2.1-2.4c-1.2,0-2,0.8-2.5,1.7v6.3h-1.5V28.8z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M796.6,38.2c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9c0,0.4-0.1,0.7-0.1,1
        h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C799.6,37.5,798.3,38.2,796.6,38.2z M793.4,32.1h5.4
        c-0.1-1.3-1-2.4-2.5-2.4C794.8,29.7,793.7,30.6,793.4,32.1z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M802.9,28.8h1.5v1c0.7-0.8,1.7-1.4,3-1.4c2,0,3.5,1.4,3.5,3.9v5.5h-1.5v-5.4
        c0-1.6-0.9-2.6-2.3-2.6c-1.3,0-2.1,0.8-2.7,1.7v6.3h-1.5V28.8z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_conseil, 'hilighted-text': hilighted_conseil }"
              d="M814.6,30.2h-1.7v-1.4h1.7v-2.3h1.5v2.3h3.2v1.4h-3.2v4.6c0,1.4,0.7,1.8,1.8,1.8
        c0.6,0,1-0.1,1.4-0.2v1.4c-0.4,0.2-0.9,0.3-1.6,0.3c-2,0-3.2-1-3.2-3.2V30.2z"
            />
          </g>
        </g>
        <path
          style="
            fill: none;
            stroke: #1a1332;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-miterlimit: 10;
            stroke-dasharray: 2, 6;
          "
          d="
    M551.9,32.2c0,0-40.6-16.7-63,20.3"
        />
      </g>
      <g id="reseau-label" 
          @click="activate('network')"
          @mouseover="hilight('network', true)"
          @mouseleave="hilight('network', false)">
        <a
          id="network-label-anchor-link"
          :class="{ active: active_network, hilighted: hilighted_network }"
        >
          <path
            style="fill: #e5ecf4"
            d="M286.7,407.1H103.1c-11,0-20-9-20-20v0c0-11,9-20,20-20h183.5c11,0,20,9,20,20v0
      C306.7,398.2,297.7,407.1,286.7,407.1z"
          />
        </a>
        <g>
          <g>
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M107.7,380.1h2.3l5,12.6h-1.8l-1.5-3.7h-5.8l-1.5,3.7h-1.8L107.7,380.1z M111.2,387.6l-2.3-6
        l-2.3,6H111.2z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M117.4,383.7h1.5v1c0.7-0.8,1.7-1.4,3-1.4c2,0,3.5,1.4,3.5,3.9v5.5H124v-5.4
        c0-1.6-0.9-2.6-2.3-2.6c-1.3,0-2.1,0.8-2.7,1.7v6.3h-1.5V383.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M128.3,380.2c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
        C128.8,381.3,128.3,380.8,128.3,380.2z M128.6,383.7h1.5v9.1h-1.5V383.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M133.4,383.7h1.5v1c0.7-0.8,1.6-1.4,2.8-1.4c1.3,0,2.4,0.6,3,1.8c0.7-1,1.7-1.8,3.2-1.8
        c1.9,0,3.4,1.3,3.4,3.8v5.7h-1.5v-5.6c0-1.5-0.8-2.4-2.1-2.4c-1.2,0-1.9,0.7-2.6,1.7c0,0.2,0,0.4,0,0.6v5.7h-1.5v-5.6
        c0-1.5-0.8-2.4-2.1-2.4c-1.2,0-2,0.8-2.5,1.7v6.3h-1.5V383.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M149.9,390.5c0-1.4,1-2.4,3-2.7l3-0.5v-0.6c0-1.3-0.9-2-2.2-2c-1.1,0-1.9,0.5-2.5,1.3l-1.2-0.9
        c0.8-1.1,2.1-1.7,3.7-1.7c2.3,0,3.7,1.4,3.7,3.3v6.1h-1.5v-1.2c-0.7,1-1.8,1.5-3,1.5C151.1,393.1,149.9,392.1,149.9,390.5z
         M153.1,391.8c1.2,0,2.2-0.6,2.8-1.7v-1.7l-2.7,0.5c-1.3,0.2-1.8,0.8-1.8,1.6C151.4,391.2,152.1,391.8,153.1,391.8z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M161,385.1h-1.7v-1.4h1.7v-2.3h1.5v2.3h3.2v1.4h-3.2v4.6c0,1.4,0.7,1.8,1.8,1.8
        c0.6,0,1-0.1,1.4-0.2v1.4c-0.4,0.2-0.9,0.3-1.6,0.3c-2,0-3.2-1-3.2-3.2V385.1z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M167.8,380.2c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
        S167.8,380.8,167.8,380.2z M168.1,383.7h1.5v9.1h-1.5V383.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M181.9,388.2c0,2.6-2,4.9-4.9,4.9c-2.9,0-4.9-2.3-4.9-4.9c0-2.6,2-4.9,4.9-4.9
        C179.9,383.3,181.9,385.6,181.9,388.2z M180.3,388.2c0-1.9-1.4-3.5-3.3-3.5c-1.9,0-3.3,1.6-3.3,3.5c0,1.9,1.4,3.5,3.3,3.5
        C178.9,391.7,180.3,390.1,180.3,388.2z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M184.4,383.7h1.5v1c0.7-0.8,1.7-1.4,3-1.4c2,0,3.5,1.4,3.5,3.9v5.5h-1.5v-5.4
        c0-1.6-0.9-2.6-2.3-2.6c-1.3,0-2.1,0.8-2.7,1.7v6.3h-1.5V383.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M204.3,383.3c1.3,0,2.4,0.5,3.2,1.4v-5.4h1.5v13.5h-1.5v-1c-0.8,0.9-1.9,1.4-3.2,1.4
        c-2.8,0-4.7-2.3-4.7-4.9C199.6,385.6,201.5,383.3,204.3,383.3z M204.5,391.7c1.3,0,2.3-0.6,3-1.7v-3.5c-0.7-1.1-1.8-1.7-3-1.7
        c-1.9,0-3.2,1.5-3.2,3.5C201.3,390.1,202.5,391.7,204.5,391.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M216.4,393.1c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9
        c0,0.4-0.1,0.7-0.1,1h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C219.5,392.4,218.1,393.1,216.4,393.1z M213.2,387
        h5.4c-0.1-1.3-1-2.4-2.5-2.4C214.6,384.6,213.6,385.4,213.2,387z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M227.6,383.7h1.5v1.1c0.7-0.8,1.5-1.3,2.6-1.3c0.3,0,0.6,0,0.8,0.1v1.6c-0.3-0.1-0.5-0.1-0.9-0.1
        c-1.2,0-2,0.6-2.5,1.5v6.2h-1.5V383.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M238.5,393.1c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9
        c0,0.4-0.1,0.7-0.1,1h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C241.5,392.4,240.2,393.1,238.5,393.1z M235.2,387
        h5.4c-0.1-1.3-1-2.4-2.5-2.4C236.6,384.6,235.6,385.4,235.2,387z M237.4,382.2l1.9-3h1.7l-2.1,3H237.4z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M244.8,390.6c0.6,0.8,1.4,1.3,2.3,1.3c1,0,1.6-0.6,1.6-1.4c0-2.1-4.4-1.3-4.4-4.6
        c0-1.5,1.2-2.6,2.9-2.6c1.3,0,2.4,0.6,3.1,1.5l-1.1,0.9c-0.5-0.7-1.2-1.1-2-1.1c-0.9,0-1.4,0.5-1.4,1.2c0,2.1,4.4,1.4,4.4,4.5
        c0,1.7-1.4,2.8-3.1,2.8c-1.4,0-2.6-0.6-3.4-1.6L244.8,390.6z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M256.7,393.1c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9
        c0,0.4-0.1,0.7-0.1,1h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C259.8,392.4,258.4,393.1,256.7,393.1z M253.5,387
        h5.4c-0.1-1.3-1-2.4-2.5-2.4C254.9,384.6,253.8,385.4,253.5,387z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M262.4,390.5c0-1.4,1-2.4,3-2.7l3-0.5v-0.6c0-1.3-0.9-2-2.2-2c-1.1,0-1.9,0.5-2.5,1.3l-1.2-0.9
        c0.8-1.1,2.1-1.7,3.7-1.7c2.3,0,3.7,1.4,3.7,3.3v6.1h-1.5v-1.2c-0.7,1-1.8,1.5-3,1.5C263.6,393.1,262.4,392.1,262.4,390.5z
         M265.7,391.8c1.2,0,2.2-0.6,2.8-1.7v-1.7l-2.7,0.5c-1.3,0.2-1.8,0.8-1.8,1.6C263.9,391.2,264.6,391.8,265.7,391.8z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M279.4,383.7h1.5v5.1c0,2.7-1.6,4.3-4,4.3c-2.4,0-4-1.6-4-4.3v-5.1h1.5v5.2c0,1.8,1,2.8,2.5,2.8
        c1.5,0,2.5-1,2.5-2.8V383.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_network, 'hilighted-text': hilighted_network }"
              d="M282.6,392.7l3.3-4.7l-3.1-4.4h1.7l2.4,3.3l2.4-3.3h1.8L288,388l3.3,4.7h-1.7l-2.6-3.7l-2.6,3.7
        H282.6z"
            />
          </g>
        </g>
        <path
          style="
            fill: none;
            stroke: #1a1332;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-miterlimit: 10;
            stroke-dasharray: 2, 6;
          "
          d="
    M246,410.7c0,0,35.9,43.2,126,21.5"
        />
      </g>
      <g id="transverse-label"

          @click="activate('transverse')"
          @mouseover="hilight('transverse', true)"
          @mouseleave="hilight('transverse', false)">
        <a id="transverse-label-anchor-link"
        :class="{ active: active_transverse, hilighted: hilighted_transverse }">
          <path
            style="fill: #e5ecf4"
            d="M994.5,236.6H816c-11,0-20-9-20-20v0c0-11,9-20,20-20h178.5c11,0,20,9,20,20v0
      C1014.5,227.6,1005.5,236.6,994.5,236.6z"
          />
        </a>
        <g>
          <g>
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M820.7,209.6h2.3l5,12.6h-1.8l-1.5-3.7h-5.8l-1.5,3.7h-1.8L820.7,209.6z M824.1,217.1l-2.3-6
        l-2.3,6H824.1z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M836.8,219.8l1.2,0.9c-0.8,1.1-2.2,1.9-3.8,1.9c-3,0-4.9-2.3-4.9-4.9c0-2.6,1.9-4.9,4.9-4.9
        c1.6,0,3,0.7,3.8,1.9l-1.2,0.9c-0.6-0.8-1.5-1.4-2.6-1.4c-1.9,0-3.3,1.5-3.3,3.5c0,1.9,1.4,3.5,3.3,3.5
        C835.3,221.1,836.2,220.6,836.8,219.8z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M840.6,214.6h-1.7v-1.4h1.7v-2.3h1.5v2.3h3.2v1.4h-3.2v4.6c0,1.4,0.7,1.8,1.8,1.8
        c0.6,0,1-0.1,1.4-0.2v1.4c-0.4,0.2-0.9,0.3-1.6,0.3c-2,0-3.2-1-3.2-3.2V214.6z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M847.3,209.7c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
        C847.8,210.8,847.3,210.3,847.3,209.7z M847.6,213.2h1.5v9.1h-1.5V213.2z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M852.7,213.2l2.9,7.5l2.9-7.5h1.7l-3.5,9.1h-2l-3.5-9.1H852.7z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M861.7,209.7c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
        C862.2,210.8,861.7,210.3,861.7,209.7z M862,213.2h1.5v9.1H862V213.2z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M867.3,214.6h-1.7v-1.4h1.7v-2.3h1.5v2.3h3.2v1.4h-3.2v4.6c0,1.4,0.7,1.8,1.8,1.8
        c0.6,0,1-0.1,1.4-0.2v1.4c-0.4,0.2-0.9,0.3-1.6,0.3c-2,0-3.2-1-3.2-3.2V214.6z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M878.3,222.6c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9
        c0,0.4-0.1,0.7-0.1,1h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C881.4,221.9,880,222.6,878.3,222.6z M875.1,216.5
        h5.4c-0.1-1.3-1-2.4-2.5-2.4C876.5,214.1,875.4,214.9,875.1,216.5z M877.3,211.7l1.9-3h1.7l-2.1,3H877.3z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M884.6,220.1c0.6,0.8,1.4,1.3,2.3,1.3c1,0,1.6-0.6,1.6-1.4c0-2.1-4.4-1.3-4.4-4.6
        c0-1.5,1.2-2.6,2.9-2.6c1.3,0,2.4,0.6,3.1,1.5l-1.1,0.9c-0.5-0.7-1.2-1.1-2-1.1c-0.9,0-1.4,0.5-1.4,1.2c0,2.1,4.4,1.4,4.4,4.5
        c0,1.7-1.4,2.8-3.1,2.8c-1.4,0-2.6-0.6-3.4-1.6L884.6,220.1z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M897.9,214.6h-1.7v-1.4h1.7v-2.3h1.5v2.3h3.2v1.4h-3.2v4.6c0,1.4,0.7,1.8,1.8,1.8
        c0.6,0,1-0.1,1.4-0.2v1.4c-0.4,0.2-0.9,0.3-1.6,0.3c-2,0-3.2-1-3.2-3.2V214.6z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M904.9,213.2h1.5v1.1c0.7-0.8,1.5-1.3,2.6-1.3c0.3,0,0.6,0,0.8,0.1v1.6c-0.3-0.1-0.5-0.1-0.9-0.1
        c-1.2,0-2,0.6-2.5,1.5v6.2h-1.5V213.2z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M911.2,220c0-1.4,1-2.4,3-2.7l3-0.5v-0.6c0-1.3-0.9-2-2.2-2c-1.1,0-1.9,0.5-2.5,1.3l-1.2-0.9
        c0.8-1.1,2.1-1.7,3.7-1.7c2.3,0,3.7,1.4,3.7,3.3v6.1h-1.5v-1.2c-0.7,1-1.8,1.5-3,1.5C912.4,222.6,911.2,221.6,911.2,220z
         M914.4,221.3c1.2,0,2.2-0.6,2.8-1.7v-1.7l-2.7,0.5c-1.3,0.2-1.8,0.8-1.8,1.6C912.7,220.7,913.4,221.3,914.4,221.3z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M921.8,213.2h1.5v1c0.7-0.8,1.7-1.4,3-1.4c2,0,3.5,1.4,3.5,3.9v5.5h-1.5v-5.4
        c0-1.6-0.9-2.6-2.3-2.6c-1.3,0-2.1,0.8-2.7,1.7v6.3h-1.5V213.2z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M933.1,220.1c0.6,0.8,1.4,1.3,2.3,1.3c1,0,1.6-0.6,1.6-1.4c0-2.1-4.4-1.3-4.4-4.6
        c0-1.5,1.2-2.6,2.9-2.6c1.3,0,2.4,0.6,3.1,1.5l-1.1,0.9c-0.5-0.7-1.2-1.1-2-1.1c-0.9,0-1.4,0.5-1.4,1.2c0,2.1,4.4,1.4,4.4,4.5
        c0,1.7-1.4,2.8-3.1,2.8c-1.4,0-2.6-0.6-3.4-1.6L933.1,220.1z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M941,213.2l2.9,7.5l2.9-7.5h1.7l-3.5,9.1h-2l-3.5-9.1H941z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M954.4,222.6c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9
        c0,0.4-0.1,0.7-0.1,1H951v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C957.4,221.9,956.1,222.6,954.4,222.6z
         M951.2,216.5h5.4c-0.1-1.3-1-2.4-2.5-2.4C952.6,214.1,951.5,214.9,951.2,216.5z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M960.7,213.2h1.5v1.1c0.7-0.8,1.5-1.3,2.6-1.3c0.3,0,0.6,0,0.8,0.1v1.6c-0.3-0.1-0.5-0.1-0.9-0.1
        c-1.2,0-2,0.6-2.5,1.5v6.2h-1.5V213.2z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M967.9,220.1c0.6,0.8,1.4,1.3,2.3,1.3c1,0,1.6-0.6,1.6-1.4c0-2.1-4.4-1.3-4.4-4.6
        c0-1.5,1.2-2.6,2.9-2.6c1.3,0,2.4,0.6,3.1,1.5l-1.1,0.9c-0.5-0.7-1.2-1.1-2-1.1c-0.9,0-1.4,0.5-1.4,1.2c0,2.1,4.4,1.4,4.4,4.5
        c0,1.7-1.4,2.8-3.1,2.8c-1.4,0-2.6-0.6-3.4-1.6L967.9,220.1z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M979.9,222.6c-3.1,0-5-2.3-5-4.9c0-2.8,1.8-4.9,4.6-4.9c2.5,0,4.1,1.7,4.1,3.9
        c0,0.4-0.1,0.7-0.1,1h-7v0c0,2,1.4,3.5,3.4,3.5c1.2,0,2.1-0.5,2.7-1.4l1.1,0.9C982.9,221.9,981.6,222.6,979.9,222.6z
         M976.6,216.5h5.4c-0.1-1.3-1-2.4-2.5-2.4C978,214.1,977,214.9,976.6,216.5z"
            />
            <path
              style="fill: #010000"
              :class="{ 'active-text': active_transverse, 'hilighted-text': hilighted_transverse }"
              d="M986.2,220.1c0.6,0.8,1.4,1.3,2.3,1.3c1,0,1.6-0.6,1.6-1.4c0-2.1-4.4-1.3-4.4-4.6
        c0-1.5,1.2-2.6,2.9-2.6c1.3,0,2.4,0.6,3.1,1.5l-1.1,0.9c-0.5-0.7-1.2-1.1-2-1.1c-0.9,0-1.4,0.5-1.4,1.2c0,2.1,4.4,1.4,4.4,4.5
        c0,1.7-1.4,2.8-3.1,2.8c-1.4,0-2.6-0.6-3.4-1.6L986.2,220.1z"
            />
          </g>
        </g>
        <path
          style="
            fill: none;
            stroke: #1a1332;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-miterlimit: 10;
            stroke-dasharray: 2, 6;
          "
          d="
    M731.3,228.3c0,0,27.6-55,73.4-33"
        />
      </g>
    </svg>
    <div
      id="pilotageTooltip"
      class="tooltip"
      role="tooltip"
      :class="{ 'hidden-tooltip': !show_pilotage }"
    >
      Le Pôle Web pilote exclusivement les dispositifs numériques AGIR, CRM&nbsp;Connect, expertises.ademe.fr et les sites régionaux. Il contribue ainsi à
      la mise en œuvre de la&nbsp;stratégie&nbsp;digitale&nbsp;de&nbsp;l’ADEME.
      <div
        class="arrow"
        data-popper-arrow
        :class="{ 'hidden-tooltip': !show_pilotage }"
      ></div>
    </div>
    <div
      id="networkTooltip"
      class="tooltip"
      role="tooltip"
      :class="{ 'hidden-tooltip': !show_network }"
    >
      Le Pôle Web prend en charge l’animation des réseaux internes d’AGIR et des
      correspondants I*Net.
      <div
        class="arrow"
        data-popper-arrow
        :class="{ 'hidden-tooltip': !show_network }"
      ></div>
    </div>
    <div
      id="conseilTooltip"
      class="tooltip"
      role="tooltip"
      :class="{ 'hidden-tooltip': !show_conseil }"
    >
      À chaque étape d’un projet digital à l’ADEME (cahier des charges,
      développements, tests et exploitation), le Pôle Web vous accompagne :
      référencement SEO, statistiques, études UX et tests utilisateurs,
      accessibilité (RGAA), relation client, chartes éditoriales et graphiques.
      <div
        class="arrow"
        data-popper-arrow
        :class="{ 'hidden-tooltip': !show_conseil }"
      ></div>
    </div>
    <div
      id="transverseTooltip"
      class="tooltip"
      role="tooltip"
      :class="{ 'hidden-tooltip': !show_transverse }"
    >
      Le Pôle Web participe à la vie et au développement de l’Agence
      (communication interne sur le réseau Comm’on, transformation de l’Agence,
      appui au projet Librairie ADEME, etc.).
      <div
        class="arrow"
        data-popper-arrow
        :class="{ 'hidden-tooltip': !show_transverse }"
      ></div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "MainIllustration",
  data() {
    return {
      show_pilotage: false,
      show_network: false,
      show_conseil: false,
      show_transverse: false,
      hilight_pilotage: false,
      hilight_network: false,
      hilight_conseil: false,
      hilight_transverse: false,
      allowedKeys: ["pilotage", "network", "conseil", "transverse"],
    };
  },
  computed: {
    active_pilotage() {
      // return this.show_pilotage && !this.hilight_pilotage;
      return this.show_pilotage;
    },
    active_conseil() {
      return this.show_conseil;
    },
    active_network() {
      return this.show_network;
    },
    active_transverse() {
      return this.show_transverse;
    },
    hilighted_pilotage() {
      return this.hilight_pilotage && !this.show_pilotage;
    },
    hilighted_conseil() {
      return this.hilight_conseil && !this.show_conseil;
    },
    hilighted_network() {
      return this.hilight_network && !this.show_network;
    },
    hilighted_transverse() {
      return this.hilight_transverse && !this.show_transverse;
    },
  },
  mounted() {
    const pilotageTrigger = document.querySelector(
      "#pilotage-label-anchor-link"
    );
    const pilotageTooltip = document.querySelector("#pilotageTooltip");
    createPopper(pilotageTrigger, pilotageTooltip, {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
    const networkTrigger = document.querySelector("#network-label-anchor-link");
    const networkTooltip = document.querySelector("#networkTooltip");
    createPopper(networkTrigger, networkTooltip, {
      placement: "top",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
    const conseilTrigger = document.querySelector("#conseil-label-anchor-link");
    const conseilTooltip = document.querySelector("#conseilTooltip");
    createPopper(conseilTrigger, conseilTooltip, {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
    const transverseTrigger = document.querySelector(
      "#transverse-label-anchor-link"
    );
    const transverseTooltip = document.querySelector("#transverseTooltip");
    createPopper(transverseTrigger, transverseTooltip, {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  },
  methods: {
    isKeyAllowed(key) {
      return this.allowedKeys.indexOf(key) !== -1; 
    },
    activate(key) {
      if (this.isKeyAllowed(key)) {
        // hide all tooltips
        const vm = this;
        vm.allowedKeys.forEach(tooltip => {
          vm[`show_${tooltip}`] = false;
        });
        // Set the new active element
        vm[`show_${key}`] = true;
      }
    },
    hilight(key, value) {
      if (this.isKeyAllowed(key)) {
        this[`hilight_${key}`] = value;
      }
    },
  },
};
</script>

<style>
.wrap {
  /*padding: 30px;*/
  padding: 0px;
  margin: 0 auto;
  margin-top:  40px!important;
  height: auto;
  cursor: pointer;
}

.wrap svg a polygon,
.wrap svg a path {
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.wrap svg a:hover,
.wrap svg a:focus {
  cursor: pointer;
}
/* svg a:hover polygon,
svg a:hover path,
svg a:focus polygon, */
.wrap svg a:hover path {
  /* fill: #3b5c7d; */
  /*opacity: 0.25;*/
}

.wrap svg a:hover .svg-shadow {
  animation: svg-shadow 1s ease-in-out infinite alternate;
}

.wrap .st15 {
  fill: white;
  stroke-width: 0.5px;
  stroke: #406980;
}

@keyframes svg-shadow {
  from {
    /* filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 15px #e60073)
      drop-shadow(0 0 20px #e60073); */
    filter: drop-shadow(0 0 0px #fff) drop-shadow(0 0 0px #e6003a)
      drop-shadow(0 0 0px #e6000c);
  }

  to {
    filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 5px #e60000)
      drop-shadow(0 0 10px #e60000);
  }
}

.wrap svg a.active path {
  fill: #00469B !important;
}

.wrap svg .active {
  fill: #00469B !important;
}
.wrap svg .active-text {
  fill: #fff !important;
}


.wrap svg a.hilighted path {
  fill: #abbacb !important;
}
.wrap svg .hilighted {
  fill: #abbacb !important;
}
.wrap svg .hilighted-text {
  fill: #072252 !important;
}
</style>