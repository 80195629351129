export default [
  {
    image_label: 'AGIR',
    image: 'agir.svg',
    label: 'Et plus précisément ?',
    tabs: [
      {
        label: 'Ma page <br />est déjà<br /> en ligne',
        links_label: 'Selon votre besoin, contactez le référent dédié :',
        links: ['Éditorial', 'Statistiques et indicateurs'],
        email_objects: ['[AGIR] Page déjà en ligne : requête éditoriale', '[AGIR] Page déjà en ligne : requête statistiques et indicateurs'],
        contacts: [0, 1],
      },
      {
        label: 'Je souhaite ajouter<br />une ou des<br />nouvelle(s) page(s)',
        links: ['Pour être accompagné.e sur le pilotage de votre projet'],
        email_objects: ['[AGIR] Requête nouvelle page'],
        contacts: [4],
      },
      {
        label: 'Je souhaite proposer<br />un nouveau service<br />à AGIR',
        links: ['Pour être accompagné.e sur le pilotage de votre projet'],
        email_objects: ['[AGIR] Requête nouveau service'],
        contacts: [3],
      },
      {
        label: 'J\'ai un<br />problème<br />technique<br />',
        links: ['Pour obtenir de l’aide, contactez :'],
        email_objects: ['[AGIR] Assistance problème technique'],
        contacts: [5],
      },
    ],
  },
  {
    image_label: 'Un autre site',
    image: 'autre-site.svg',
    label: 'Et plus précisément ?',
    tabs: [
      {
        label: 'J\'ai un projet<br/>de nouveau site',
        links: ['Pour être accompagné.e sur les nouveaux dispositifs :'],
        email_objects: ['[Site ADEME] Requête projet de nouveau site'],
        contacts: [6],
      },
      {
        label: 'J\'ai un site<br/>en cours de conception',
        links_label: 'Selon votre besoin, contactez le référent dédié :',
        links: [
          'Éditorial (chartes, stratégie et contenus)',
          'Chartes graphiques',
          'Stratégies indicateurs et référencement (SEO)',
          'Accessibilité (RGAA)'
        ],
        email_objects: ['[Site ADEME] Site en cours de conception : requête éditoriale', '[Site ADEME] Site en cours de conception : requête chartes graphiques', '[Site ADEME] Site en cours de conception : requête stratégies indicateurs et SEO', '[Site ADEME] Site en cours de conception : requête accessibilité'],
        contacts: [0, 0, 1, 2],
      },
      {
        label: 'Mon site est déjà<br/>en ligne',
        links_label: 'Pour connaître le contact qui vous aidera au mieux, choisissez votre besoin :',
        links: [
          'Éditorial (chartes, stratégie et contenus)',
          'Chartes graphiques',
          'Stratégies indicateurs et référencement (SEO)',
          'Accessibilité (RGAA)'
        ],
        email_objects: ['[Site ADEME] Site déjà en ligne : requête éditoriale', '[Site ADEME] Site déjà en ligne : requête chartes graphiques', '[Site ADEME] Site déjà en ligne : requête stratégies indicateurs et SEO', '[Site ADEME] Site déjà en ligne : requête accessibilité'],
        contacts: [0, 0, 1, 2],
      },
    ],
  },
  {
    image_label: 'Une autre action',
    image: 'autre-action.svg',
    label: '',
    tabs: [
      {
        label: 'Connect CRM<br/>et e-mailing',
        links: [''],
        email_objects: ['[Action de com] Requête emailing ou CRM Connect'],
        contacts: [7, 8, 9],
      },
      {
        label: 'Statistiques, indicateurs<br/>et référencement (SEO)',
        links: [''],
        email_objects: ['[Action de com] Requête statistiques, indicateurs et SEO'],
        contacts: [1],
      },
      {
        label: '<span>Accessibilité<br/>(RGAA)</span><br/>&nbsp;',
        links: [''],
        email_objects: ['[Action de com] Requête accessibilité'],
        contacts: [2],
      },
      {
        label: '<span>Publications interactives<br/>(Calaméo)</span><br/>&nbsp;',
        links: [''],
        email_objects: ['[Action de com] Requête publications interactives'],
        contacts: [0],
      },
    ],
  },
];