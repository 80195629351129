export default [
  {
    avatar: 'josselin-gesrel.jpg',
    fullname: 'Josselin Gesrel',
    job: 'Chargé de communication digitale',
    email: 'josselin.gesrel@ademe.fr',
    phone: '02 41 20 42 15',
    isOdd: false,
  },
  {
    avatar: 'sophie-even.jpg',
    fullname: 'Sophie Even',
    job: 'Chargée de communication digitale',
    email: 'sophie.even@ademe.fr',
    phone: '02 41 20 41 38',
    isOdd: false,
  },
  {
    avatar: 'estelle-ribot.jpg',
    fullname: 'Estelle Ribot',
    job: 'Cheffe de projet numérique',
    email: 'estelle.ribot@ademe.fr',
    phone: '02 41 20 42 49',
    isOdd: false,
  },
  {
    avatar: 'damien-julienne.jpg',
    fullname: 'Damien Julienne',
    job: 'Coordinateur Pôle Web – Communication digitale',
    email: 'damien.julienne@ademe.fr',
    phone: '02 41 20 42 55',
    isOdd: false,
  },
  {
    avatar: 'christelle-bruyere.jpg',
    fullname: 'Christelle Bruyère',
    job: 'Cheffe de projets AGIR – Communication digitale',
    email: 'christelle.bruyere@ademe.fr',
    phone: '02 41 20 40 04',
    isOdd: false,
  },
  {
    email: 'assistance.agirpourlatransition@ademe.fr',
    isOdd: true,
  },
  {
    email: 'nouveauproduitdigital.agileoffice@ademe.fr',
    isOdd: true,
  },
  {
    avatar: 'marie-rousseau.jpg',
    fullname: 'Marie Rousseau',
    job: 'Cheffe de projet CRM',
    email: 'marie.rousseau@ademe.fr',
    phone: '02 41 20 41 68',
    isOdd: false,
  },
  {
    avatar: 'virginie-isambart.jpg',
    fullname: 'Virginie Isambart',
    job: 'Cheffe de projet adjointe CRM',
    email: 'virginie.isambart@ademe.fr',
    phone: '',
    isOdd: false,
  },
  {
    avatar: 'sara-mamilonne.jpg',
    fullname: 'Sara Mamilonne',
    job: 'Alternante au projet CRM',
    email: 'sara.mamilonne@ademe.fr',
    phone: '02 55 98 12 38',
    isOdd: false,
  },
];