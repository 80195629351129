<template>
  <div v-if="show" class="choose-contact p-5 mb-6 has-text-white">
    <ProcessIllustration />

    <div class="content" v-if="tab.links_label !== ''">
      <h3 class="is-size-6 has-text-left has-text-white">
        {{ links_label }}
      </h3>
    </div>

    <div
      class="columns is-gapless"
      v-if="links.length > 1"
      style="margin: 0 -1.5rem !important"
    >
      <div class="column is-5">
        <aside class="menu">
          <ul class="menu-list">
            <li>
              <a
                @click="showContact(tab.contacts[index], index)"
                v-for="(label, index) in links"
                :key="index"
                :class="{ 'is-active': index === selectedLink }"
              >
                {{ label }}
                <img
                  src="../assets/images/arrow.svg"
                  style="float: right"
                  class="image is-16x16"
                />
              </a>
            </li>
          </ul>
        </aside>
      </div>
      <div class="column mr-5" :class="{ 'is-bloc-active': isBlueBlocActive }">
        <Contact :contact="selectedContact" :object="selectedObject" />
      </div>
    </div>
    <div v-else>
      <div class="content px-5">
        <h3 class="is-size-6 has-text-left has-text-white">
          {{ tab.links[0] }}
        </h3>
      </div>
      <div class="m-5" :class="{ 'is-bloc-active': isBlueBlocActive }">
        <div v-if="tab.contacts.length === 1">
          <Contact
            :contact="contacts[tab.contacts[0]]"
            :object="tab.email_objects[0]"
          />
        </div>
        <div v-else>
          <Contact
            v-for="contactIndex in tab.contacts"
            :key="`contact_${contactIndex}`"
            :contact="contacts[contactIndex]"
            :object="tab.email_objects[0]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessIllustration from "./ProcessIllustration.vue";
import Contact from "./Contact.vue";

export default {
  name: "ChooseContact",
  components: { ProcessIllustration, Contact },
  computed: {
    show() {
      const { selectedTab } = this.$store.state;
      return selectedTab !== -1;
    },
    selectedProject() {
      return this.$store.state.selectedProject;
    },
    tab() {
      const { projects, selectedProject, selectedTab } = this.$store.state;

      const tabs = projects[selectedProject].tabs;
      return tabs[selectedTab];
    },
    links_label() {
      return this.tab.links_label || "";
    },
    links() {
      return this.tab.links;
    },
    contacts() {
      return this.$store.state.contacts;
    },
    selectedLink() {
      return this.$store.state.selectedLink;
    },
    selectedContactIndex() {
      return this.$store.state.selectedContact;
    },
    selectedContact() {
      if (this.selectedContactIndex !== -1) {
        return this.contacts[this.selectedContactIndex];
      }
      return false;
    },
    selectedObject() {
      return this.tab.email_objects[this.selectedLink];
    },
    isBlueBlocActive() {
      return this.selectedContact || this.links.length <= 1;
    },
  },
  methods: {
    showContact(contactIndex, linkIndex) {
      this.$store.commit("LINK_SELECT", linkIndex);
      this.$store.commit("EMAIL_OBJECT_SELECT", linkIndex);
      this.$store.commit("CONTACT_SELECT", contactIndex);
    },
  },
};
</script>

<style scoped>
.choose-contact {
  background: linear-gradient(#00469b, #072352);
}
.menu-list a {
  color: white;
  border-left: 6px solid white;
  border-radius: 0px;
  margin-bottom: 12px;
  font-size: 14px;
  text-align: left;
}
.menu-list a:hover,
.menu-list a.is-active {
  color: #fff;
  background: #00469b;
  border-left: 6px solid #00469b;
}

.is-bloc-active {
  transition: all .3s ease;
  background: #00469b;
}


.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
  opacity: 1;
}
.slide-leave-to {
  transform: translate(-100%, 0);
  opacity:  0;
}
</style>
