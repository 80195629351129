<template>
  <div v-show="showIllustration" class="section container is-max-desktop">
    <div class="content">
      <h3 class="is-size-6 has-text-left has-text-white">
        Votre projet est validé par le processus numérique : le Pôle Web suit
        une démarche précise
      </h3>
    </div>
    <img class="process-image image" src="../assets/images/4steps.svg" />
    <div class="columns">
      <div class="column columns">
        <div class="column is-1 is-size-4 has-text-weight-bold has-text-white">
          1
        </div>
        <div class="column has-text-left is-size-7 pr-1">
          Le projet est accueilli : le Pôle Web décrit son niveau d'implication
          et ses missions.
        </div>
      </div>
      <div class="column columns">
        <div class="column is-1 is-size-4 has-text-weight-bold has-text-white">
          2
        </div>
        <div class="column has-text-left is-size-7 pr-1">
          Le Pôle Web participe à la définition du besoin sur son périmètre de
          compétences.
        </div>
      </div>
      <div class="column columns">
        <div class="column is-1 is-size-4 has-text-weight-bold has-text-white">
          3
        </div>
        <div class="column has-text-left is-size-7 pr-1">
          Le projet est lancé en collaboration avec le Pôle Web, qui s'assure du
          respect des règles définies.
        </div>
      </div>
      <div class="column columns">
        <div class="column is-1 is-size-4 has-text-weight-bold has-text-white">
          4
        </div>
        <div class="column has-text-left is-size-7 pr-1">
          Le projet est en exploitation, le Pôle Web collabore sur l'ensemble de
          ses domaines de compétences.
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "ProcessIllustration",
  computed: {
    showIllustration() {
      return this.$store.state.selectedProject === 1;
    },
  },
};
</script>

<style>
/* TODO : responsive  */
.process-image {
  width: 85%;
  margin: 20px auto 20px;
}
</style>
