<template>
  <div>
    <div v-if="selectedProject !== -1 && tabLabel !== ''">
      <div class="content">
        <h3 class="is-size-6 has-text-left">{{ tabLabel }}</h3>
      </div>
    </div>
    <nav class="level" v-if="selectedProject !== -1">
      <div
        class="level-item has-text-left"
        :class="{ 'is-active': key === selectedTab }"
        v-for="(tab, key) in tabs"
        :key="key"
        @click="selectTab(key)"
        v-html="tab.label"
      ></div>
    </nav>
  </div>
</template>


<script>
export default {
  name: "ChooseTab",
  computed: {
    projects() {
      return this.$store.state.projects;
    },
    selectedProject() {
      return this.$store.state.selectedProject;
    },
    tabs() {
      return this.projects[this.selectedProject].tabs || false;
    },
    tabLabel() {
      return this.projects[this.selectedProject].label;
    },
    selectedTab() {
      return this.$store.state.selectedTab;
    },
  },
  methods: {
    selectTab(index) {
      this.$store.commit("TAB_SELECT", index);
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 769px) {
  .level {
    align-items: flex-end;
    height: calc(106px + 2rem);
  }  
}

.level-item {
  background: #e4edf6;
  color: #000;
  padding: 2rem 3rem;
  border-radius: 0px !important;
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 3px;
  cursor: pointer;
  transition: padding .15s ease-out;
}
.level-item:last-child {
  margin-right: 0px !important;
}
.level-item:hover {
  background: #00469b;
  color: #fff;
  padding-bottom: 3rem;
}
.level-item.is-active {
  background: #00469b;
  color: #fff;
  padding-bottom: 3rem;
}
</style>
