<template>
  <div class="columns is-mobile">
    <div class="column has-text-right">
      <span
        class="switch-label"
        :class="{ active: !modelValue }"
        @click="$emit('update:modelValue', false)"
        >Découvrez nos missions</span
      >
    </div>
    <div class="column is-narrow">
      <div class="field">
        <input
          :checked="modelValue"
          @input="$emit('update:modelValue', $event.target.checked)"
          id="switchLarge"
          type="checkbox"
          name="switchLarge"
          class="switch is-large is-rounded is-outlined"
        />
        <label for="switchLarge"></label>
      </div>
    </div>
    <div class="column has-text-left">
      <span
        class="switch-label"
        :class="{ active: modelValue }"
        @click="$emit('update:modelValue', true)"
        >Trouvez votre contact</span
      >
    </div>
  </div>
</template>


<script>
export default {
  name: "Switcher",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.switch-label {
  color: #6092c7;
  cursor: pointer;
}
.switch-label.active {
  color: #032254;
}
</style>
