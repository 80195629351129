<template>
  <div>
    <section class="hero">
      <Banner></Banner>
      <div class="hero-body">
        <Switcher v-model="isToggled"></Switcher>
        <div v-show="!isToggled">
          <!-- Mobile version (< 768px) -->
          <div class="is-hidden-tablet">
            <MobilePoleView />
          </div>
          <!-- SVG is visible starting from Tablet size -->
          <div class="is-hidden-mobile">
            <MainIllustration />
          </div>
        </div>
        <MultiChoiceView v-show="isToggled" />
      </div>
    </section>
    <div class="footer hero-foot">footer illustration</div>
  </div>
</template>

<script>
// Big schema with bubbles (default view)
import MainIllustration from "./components/MainIllustration.vue";
// default view but in mobile version
import MobilePoleView from "./components/MobilePoleView.vue";
// Multi step view to find contact
import MultiChoiceView from "./components/MultiChoiceView.vue";
// Header of the page
import Banner from "./components/Banner.vue";
// Toggle button to switch views
import Switcher from "./components/Switcher.vue";

export default {
  name: "App",
  components: {
    MainIllustration,
    MobilePoleView,
    MultiChoiceView,
    Banner,
    Switcher,
  },
  data() {
    return {
      isToggled: false, // defines the active view via the switch
    };
  },
};
</script>

<style>
#app {
  font-family: Marianne, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}

.footer {
  /*position: absolute;*/
  position: fixed;
  /*position: relative;*/
  left: 0px;
  bottom: 0px;
  padding: 0 !important;
  /*height: 50px;*/
  height: 40px;
  z-index: -1;
  overflow: hidden;
  width: 100%;
  text-indent: -20000px;
  background-color: transparent !important;
  background-image: linear-gradient(
    to top left,
    #00ad88 49%,
    transparent 50%
  ) !important;
}
.message.is-link .message-header {
  background-color: #00418f !important;
}
</style>
