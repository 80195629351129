import { createStore } from 'vuex';
import projects from './projects.js';
import contacts from './contacts.js';

export default createStore({
  state () {
    return {
      selectedProject: -1,
      selectedTab: -1,
      selectedLink: -1,
      selectedEmailObject: -1,
      selectedContact: -1,
      projects,
      contacts,
    }
  },
  mutations: {
    PROJECT_SELECT(state, index) {
      state.selectedTab = -1;
      state.selectedLink = -1;
      state.selectedEmailObject = -1;
      state.selectedContact = -1;
      state.selectedProject = index;
    },
    TAB_SELECT(state, index) {
      state.selectedLink = -1;
      state.selectedContact = -1;
      state.selectedTab = index;
    },
    EMAIL_OBJECT_SELECT(state, index) {
      state.selectedEmailObject = index;
    },
    LINK_SELECT(state, index) {
      state.selectedLink = index;
    },
    CONTACT_SELECT(state, index) {
      state.selectedContact = index;
    },
  }
});