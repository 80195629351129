<template>
  <div>
    <div class="content mt-5">
      <h3 class="is-size-6 has-text-left">Votre projet concerne :</h3>
    </div>
    <div class="columns is-mobile is-hidden-mobile">
      <div class="column" v-for="(project, index) in projects" :key="index">
        <div
          class="image-wrapper"
          @click="selectProject(index)"
          :class="{ 'is-active': index === selectedProject }"
        >
          <img
            class="image project-image"
            :src="getImgUrl(project.image)"
            :alt="project.image_label"
          />
        </div>
      </div>
    </div>
    <div class="is-hidden-tablet">
      <!-- mobile view -->
      <div
        :key="index"
        class="box box-small"
        @click="selectProject(index)"
        :class="{ 'is-active': index === selectedProject }"
        v-for="(project, index) in projects"
      >
        <div class="columns is-mobile is-centered is-vcentered">
          <div class="column">
            <img class="image is-96x96" :src="getImgUrl(project.image)" />
          </div>
          <div class="column has-text-left">{{ project.image_label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "ChooseProject",
  computed: {
    projects() {
      return this.$store.state.projects;
    },
    selectedProject() {
      return this.$store.state.selectedProject;
    },
  },
  methods: {
    getImgUrl: (image) => require(`@/assets/images/${image}`),
    selectProject(index) {
      this.$store.commit("PROJECT_SELECT", index);
      // Wait vm update to scroll down the page
      // this.$nextTick(() => {
      //   window.scrollTo(
      //     0,
      //     document.body.scrollHeight || document.documentElement.scrollHeight
      //   );
      // });
    },
  },
};
</script>

<style scoped>
.project-image {
  padding-top: 40px;
}
.image-wrapper {
  background: #e4edf6;
  border-radius: 200px;
  width: 200px;
  height: 200px;
  margin: auto;
  transition: all 0.15s ease;
  cursor: pointer;
}
.image-wrapper:hover {
  background: #00469b;
}

.image-wrapper.is-active {
  background: #00469b;
}

.box.is-active {
  background: #83aad3;
}

.box-small {
  margin-bottom: 0.7rem !important;
  padding: 0.8rem !important;
}
</style>