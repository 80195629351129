<template>
  <div>
    <br />
    <article class="message is-link">
      <div class="message-header">
        <p>Pilotage de sites web</p>
      </div>
      <div class="message-body">
        Le Pôle Web pilote exclusivement les dispositifs numériques AGIR, CRM
        Connect, expertises.ademe.fr et les sites régionaux. Il contribue ainsi
        à la mise en œuvre de la stratégie digitale de l’ADEME.
      </div>
    </article>
    <article class="message is-link">
      <div class="message-header">
        <p>Animation de réseaux</p>
      </div>
      <div class="message-body">
        Le Pôle Web prend en charge l’animation des réseaux internes d’AGIR et
        des correspondants I*Net.
      </div>
    </article>
    <article class="message is-link">
      <div class="message-header">
        <p>Conseil & accompagnement</p>
      </div>
      <div class="message-body">
        À chaque étape d’un projet digital à l’ADEME (cahier des charges,
        développements, tests et exploitation), le Pôle Web vous accompagne :
        référencement SEO, statistiques, études UX et tests utilisateurs,
        accessibilité (RGAA), relation client, chartes éditoriales et
        graphiques.
      </div>
    </article>
    <article class="message is-link">
      <div class="message-header">
        <p>Activités transverses</p>
      </div>
      <div class="message-body">
        Le Pôle Web participe à la vie et au développement de l’Agence
        (communication interne sur le réseau Comm’on, transformation de
        l’Agence, appui au projet Librairie ADEME, etc.).
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "MobilePoleView",
};
</script>

<style>
</style>