<template>
  <div v-if="contact">
    <div class="contact columns p-5" v-if="!contact.isOdd">
      <div class="column is-4">
        <figure class="image is-96x96" style="">
          <img class="is-rounded" :src="getImgUrl(contact.avatar)" />
        </figure>
      </div>
      <div class="column">
        <div class="content has-text-left has-text-white">
          <p>
            <small
              ><strong class="has-text-white">{{
                contact.fullname
              }}</strong></small
            >
            <br />
            <small>{{ contact.job }}</small>
            <br />
            <a class="is-email" :href="`mailto:${contact.email}?subject=${object}`">{{
              contact.email
            }}</a>
            <br />
            <small>{{ contact.phone }}</small>
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="p-5">
        <a
          class="is-email"
          :href="`mailto:${contact.email}?subject=${object}`"
        >
          {{ contact.email }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  props: ["contact", "object"],
  methods: {
    getImgUrl: (avatar) => require(`@/assets/images/${avatar}`),
  },
};
</script>

<style>
.contact .image {
  float: right !important;
}
.contact .image img {
  border: 2px solid white;
}
.is-email {
  color: #fff;
  text-decoration: underline;
  overflow-wrap: break-word;
}
.is-email:hover {
  color: #eee;
  text-decoration: underline;
}
.image img.is-rounded {
  height:  100%;
}
</style>
