<template>
  <div class="hero-head" style="background: #00469b">
    <div class="container is-max-desktop p-5">
      <div class="columns">
        <div class="column">
          <p
            class="
              blue-shadow
              is-size-4
              has-text-center has-text-weight-bold has-text-white
            "
          >
            DIGITAL&CO
          </p>
          <p
            data-tooltip="Tooltip content"
            class="
              has-tooltip-arrow has-tooltip-bottom
              is-size-5
              has-text-white
            "
          >
            Le Pôle Web au service des projets numériques de l'ADEME
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Banner",
};
</script>

<style>
.blue-shadow {
  display: inline-block;
  position: relative;
  z-index: 99;
}
.blue-shadow:after {
  content: "";
  position: absolute;
  top: 12px;
  left: -1%;
  background: #032254;
  width: 102%;
  height: 17px;
  z-index: -1;
}
</style>
