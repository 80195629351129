<template>
  <div class="container is-max-desktop">
    <ChooseProject />
    <ChooseTab />
    <ChooseContact />
  </div>
</template>


<script>
import ChooseProject from "./ChooseProject.vue";
import ChooseTab from "./ChooseTab.vue";
import ChooseContact from "./ChooseContact.vue";

export default {
  name: "MultiChoiceView",
  components: { ChooseProject, ChooseTab, ChooseContact },
};
</script>

<style>
</style>
